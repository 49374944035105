import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { WorkOrderLine } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-edit-job-items-status',
    templateUrl: './edit-job-items-status.component.html',
    styleUrls: ['./edit-job-items-status.component.css']
})
export class EditJobItemsStatusComponent implements OnInit {

    jobItems: WorkOrderLine[];
    newStatus: string;
    saving = false;
    workOrderStatuses: any[];

    //
    constructor(public dialogRef: MatDialogRef<EditJobItemsStatusComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private serviceApi: ServiceApiService,
        private settingsApi: SettingsApiService) { }

    ngOnInit(): void {
        this.serviceApi.getWorkOrderStatusesNoAuth().then(workOrderStatuses => this.workOrderStatuses = workOrderStatuses.filter(i => i.isInternal && i.canManuallySetStatus));

        this.jobItems = this.data.allItems;
        if (!this.data.item) {
            for (const item of this.data.allItems) {
                item.selected = true;
            }
        } else {
            for (const item of this.data.allItems) {
                item.selected = false;
            }
            const foundItem = this.data.allItems.find(i => i.id === this.data.item.id);

            if (foundItem) {
                foundItem.selected = true;
            }
        }
    }

    get canUpdate() {
        return this.newStatus && this.jobItems.find(i => i.selected) && !this.saving;
    }

    async save() {
        if(this.newStatus === 'COMPLETE') {
            this.dialogRef.close('COMPLETE');
            return;
        }
        this.saving = true;
        const promises = [];
        for (const item of this.jobItems) {
            if (item.selected) {
                promises.push(this.serviceApi.updateWorkOrderLineStatusNoAuth(item.id, this.newStatus));
            }
        }

        await Promise.all(promises);
        this.dialogRef.close(true);
        this.saving = false;
    }
}
