import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Brand {
    @keyColumn() id: string;
    @column() name: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() isHighEnd?: boolean;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() supportNumber: string;

    selected: boolean;
    deleting: boolean; 
    isDirty = false;
}