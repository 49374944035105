<div aflex bottommargin20>
    <div>
        <h2 class="sub-heading">Schedule Appointment</h2>
    </div>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div style="max-width: 950px" *ngIf="workOrderSummary">

    <div class="info" bottommargin20>
        {{workOrderSummary.number}} - {{workOrderSummary.propertyAddress}}

        <div *ngIf="workOrderSummary.formattedAppointmentDate" topmargin20>
            There is an appointment scheduled for {{workOrderSummary.formattedAppointmentDate}}
            {{workOrderSummary.scheduledStartWindow}}-{{workOrderSummary.scheduledEndWindow}}
    
        </div>
    </div>



    <div flex>
        <div nogrow>

            <mat-form-field appearance="outline">
                <mat-label>Appointment Date</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="apptDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div>
            <button *ngFor="let date of preSelectDates" (click)="setDate(date)"
                [class.selected]="date.date.getDate() == apptDate.getDate() && date.date.getMonth() == apptDate.getMonth() && date.date.getFullYear() == apptDate.getFullYear()"
                mat-button class="circular-button">
                <i class="material-icons">event</i>
                <div class="button-header">{{date.heading}}</div>
                <div>{{date.dayOfWeek}}</div>
            </button>
        </div>
    </div>

    <div topmargin40>
        <div topmargin20 flex>
            <div nogrow>
                <div class="data-label">Start Time</div>
                <app-time-picker [(selectedTime)]="appointmentStartTime"></app-time-picker>
            </div>
            <div nogrow id="end-time-container">
                <div class="data-label">End Time</div>
                <app-time-picker [(selectedTime)]="appointmentEndTime"></app-time-picker>
            </div>
            <div>
                <div class="data-label">Appointment</div>
                <h2 class="sub-heading">
                    {{apptDate | date: 'shortDate'}}
                    {{appointmentStartTime | date: 'shortTime'}} - {{appointmentEndTime | date:
                    'shortTime'}}
                </h2>
            </div>
        </div>
    </div>

    <div topmargin20>

        <button mat-button mat-raised-button large color="primary" [disabled]="savingAppointment || !canSaveAppointment || dispatchContractor"
            pull-right (click)="saveAppointment()">
            <mat-spinner *ngIf="savingAppointment" class="thirty"></mat-spinner>
            Schedule
        </button>
    </div>
</div>