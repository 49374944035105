import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { Entity, Singleton, WorkOrderStatusChange } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class DispatchApiService {

    constructor(
        private api: ApiService
    ) { }

    getTest() {
        return null;
    }

    async sendCompletedStatus(id: string, wonumber: string, policyId: string) {
        return await this.api.postArrayNode(`dispatch/send-completed-status/${id}/${wonumber}/${policyId}`, null);
    }

    async getDispatchUrl() {
        return await this.api.getSingleNode(`dispatch/get-url`);
    }

    async getJobAcceptedInDispatch(workOrderId: string) {
        const result = await this.api.getArrayNode(`dispatch/job-accepted-in-dispatch/${workOrderId}`);
        return result;
    }

    async getContractorsToInitialize() {
        return await this.api.getArrayNode(`DispatchToInitialize`, null, null);
    }

    async getUserDispatchLogs(assignedToId: string, getAll: boolean = false) {
        let results;
        if (getAll) {
            results = await this.api.getArrayNode(`DispatchJobLog`, { assignedToId_eq: assignedToId, noteText_neq: '{{null}}' }, null);
        } else {
            results = await this.api.getArrayNode(`DispatchJobLog`, { assignedToId_eq: assignedToId, acknowledged_eq: '{{null}}', noteText_neq: '{{null}}' }, null);
        }
        for (const item of results) {
            item.noteText = item.noteText?.replaceAll("\uFFFC", "").replaceAll("&amp;", "&").replaceAll("&#39;", "'");
        }
        return results;
    }

    async getLogsAssignedTo() {
        const results = await this.api.getArrayNode(`dispatch/get-logs-assigned`, null, null);
        return results;
    }

    async acknowledgeLog(id: string) {
        const log = await this.api.getSingleNode(`DispatchNoteLog`, { id_eq: id });
        if (log) {
            return await this.api.patchSingleNode(`DispatchNoteLog/${id}`, { acknowledged: true });
        }
        return null;
    }

    async acknowledgeLogUndo(id: string) {
        const log = await this.api.getSingleNode(`DispatchNoteLog`, { id_eq: id });
        if (log) {
            return await this.api.patchSingleNode(`DispatchNoteLog/${id}`, { acknowledged: null });
        }
        return null;
    }

    async activateDispatch(id: string, date: Date) {
        return await this.api.patchNode(`Entity/${id}`, { dispatchActivatedDate: date });
    }

    async intitializeInDispatch(entity: Entity) {
        return await this.api.postSingleNode(`dispatch/send-contractor/${entity.id}`, null);
    }

    async uncancel(id: string, status: string) {
        let newStatus = 'unscheduled';
        if (status=='b849f529-6c42-4bab-9c6e-039ba5d123dc') newStatus = 'scheduled';
        else if (status=='15989149-faeb-481e-b277-761cdccfc548') newStatus = 'complete';
        return this.api.getSingleNode(`dispatch/uncancel/${id}/${newStatus}`);
    }

    async cogentCancelled(id: string) {
        return this.api.getSingleNode(`dispatch/cogent-cancelled/${id}`);
    }

    async updateContactInfo(id: string, number: string, name: string, phoneNumber: string, mobileNumber: string,
        email: string, policyId: string, contractorId: string, holderId: string) {
        return this.api.getSingleNode(`dispatch/update-contact`, {
            id: id, number: number, name: name, phone: phoneNumber, mobile: mobileNumber,
            email: email, policyId: policyId, contractorId: contractorId, holderId: holderId
        });
    }

    resetWorkOrderDispatchExportDate(id: string) {
        return this.api.patchNode(`WorkOrder/${id}`, { dispatchExportDate: null });
    }

    sendWorkOrder(id: string) {
        return this.api.getSingleNode(`dispatch/send-work-order/${id}`);
    }
}