import { Injectable } from '@angular/core';
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { ApiService } from '@cogent/client/api';
import { RepairItem, RepairItemAttribute, RepairItemAttributeValue, RepairItemGroup, RepairItemGroupTrade, RepairItemGroupWorkOrderItem, Trade } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class RepairItemService {
    constructor(private api: ApiService) { }

    // findAvailable(workOrderId: string) {
    //     return this.api.getArray("RepairItem/Available", { workOrderId }, () => new RepairItem());
    // }

    findForGroup(repairItemGroupId: string) {
        return this.api.getArrayDotNet("RepairItem", { repairItemGroupId_eq: repairItemGroupId }, () => new RepairItem());
    }

    findForJobItem(jobItemId: string) {
        return this.api.getArrayDotNet("RepairItem/AvailableForJobItem", { jobItemId }, () => new RepairItem());
    }

    findForJob(jobId: string) {
        return this.api.getArrayDotNet("RepairItem/AvailableForJob", {jobId}, () => new RepairItem());
    }


    getRepairItems() {
        return this.api.getArrayDotNet('RepairItem', { OrderBy: 'Name' }, () => new RepairItem());
    }

    findRepairItemSummariesByName(name: string) {
        return this.api.getArrayDotNet('RepairItemSummary', { Name_like: name }, () => new RepairItem());
    }


    saveRepairItem(repairItem: RepairItem) {
        return this.api.postIdDotNet("RepairItem", repairItem);
    }

    deleteRepairItem(repairItem: RepairItem) {
        return this.api.deleteDotNet("RepairItem/" + repairItem.id);
    }

    undeleteRepairItem(repairItem: RepairItem) {
        return this.api.unDeleteDotNet("RepairItem/" + repairItem.id);
    }

    getRepairItemAttributes() {
        return this.api.getArrayDotNet('RepairItemAttribute', { OrderBy: 'Sort' }, () => new RepairItemAttribute());
    }

    getRepairItemAttributesForItem(repairItemId: string) {
        return this.api.getArrayDotNet('RepairItemAttribute', { RepairItemId_eq: repairItemId, OrderBy: 'Sort' }, () => new RepairItemAttribute());
    }

    saveRepairItemAttribute(repairItemAttribute: RepairItemAttribute) {
        return this.api.postIdDotNet("RepairItemAttribute", repairItemAttribute);
    }

    deleteRepairItemAttribute(repairItemAttribute: RepairItemAttribute) {
        return this.api.deleteDotNet("RepairItemAttribute/" + repairItemAttribute.id);
    }

    undeleteRepairItemAttribute(repairItemAttribute: RepairItemAttribute) {
        return this.api.unDeleteDotNet("RepairItemAttribute/" + repairItemAttribute.id);
    }


    getRepairItemAttributeValues() {
        return this.api.getArrayDotNet('RepairItemAttributeValue', { OrderBy: 'Sort' }, () => new RepairItemAttributeValue());
    }

    getRepairItemAttributeValuesForAttribute(attributeId: string) {
        return this.api.getArrayDotNet('RepairItemAttributeValue', { RepairItemAttributeId_Eq: attributeId, Select: 'Id,Value,AmountDelta,YellowRangePercentDelta,Dependencies', OrderBy: 'Sort' }, () => new RepairItemAttributeValue());
    }

    async getRepairItemValuesAndValueAttributes(repairItemId: string) {
        const repairItemValues = await this.api.getArrayDotNet('RepairItemAttribute', { RepairItemId_eq: repairItemId }, () => new RepairItemAttribute());
        const ids = repairItemValues.map(i => i.id).join(',');
        const repairItemValueAttributes = await this.api.getArrayDotNet('RepairItemAttributeValue', { RepairItemAttributeId_in: ids });
        return { repairItemValues, repairItemValueAttributes };
    }

    saveRepairItemAttributeValue(repairItemAttributeValue: RepairItemAttributeValue) {
        return this.api.postIdDotNet("RepairItemAttributeValue", repairItemAttributeValue);
    }

    deleteRepairItemAttributeValue(repairItemAttributeValue: RepairItemAttributeValue) {
        return this.api.deleteDotNet("RepairItemAttributeValue/" + repairItemAttributeValue.id);
    }

    undeleteRepairItemAttributeValue(repairItemAttributeValue: RepairItemAttributeValue) {
        return this.api.unDeleteDotNet("RepairItemAttributeValue/" + repairItemAttributeValue.id);
    }

    getRepairItemGroups() {
        return this.api.getArrayDotNet('RepairItemGroup', { OrderBy: 'Name', name_neq: 'Temporary' }, () => new RepairItemGroup());
    }

    saveRepairItemGroup(repairItemGroup: RepairItemGroup) {
        return this.api.postIdDotNet("RepairItemGroup", repairItemGroup);
    }

    deleteRepairItemGroup(repairItemGroup: RepairItemGroup) {
        return this.api.deleteDotNet("RepairItemGroup/" + repairItemGroup.id);
    }

    undeleteRepairItemGroup(repairItemGroup: RepairItemGroup) {
        return this.api.unDeleteDotNet("RepairItemGroup/" + repairItemGroup.id);
    }

    getTrades() {
        return this.api.getArrayDotNet("Trade", { OrderBy: 'Name' }, () => new Trade());
    }
    getWorkOrderItems() {
        return this.api.getArrayDotNet("WorkOrderItem", { OrderBy: 'Name' }, () => new WorkOrderItemClient());
    }

    getRepairItemGroupTrades() {
        return this.api.getArrayDotNet('RepairItemGroupTrade', { OrderBy: 'RepairItemGroupId' }, () => new RepairItemGroupTrade());
    }

    saveRepairItemGroupTrade(repairItemGroupTrade: RepairItemGroupTrade) {
        return this.api.postIdDotNet("RepairItemGroupTrade", repairItemGroupTrade);
    }

    deleteRRepairItemGroupTrade(repairItemGroupTrade: RepairItemGroupTrade) {
        return this.api.deleteDotNet("RepairItemGroupTrade/" + repairItemGroupTrade.id);
    }

    undeleteRepairItemGroupTrade(repairItemGroupTrade: RepairItemGroupTrade) {
        return this.api.unDeleteDotNet("RepairItemGroupTrade/" + repairItemGroupTrade.id);
    }



    getRepairItemGroupWorkOrderItems() {
        return this.api.getArrayDotNet('RepairItemGroupWorkOrderItem', { OrderBy: 'RepairItemGroupId' }, () => new RepairItemGroupWorkOrderItem());
    }

    saveRepairItemGroupWorkOrderItem(repairItemGroupWorkOrderItem: RepairItemGroupWorkOrderItem) {
        return this.api.postIdDotNet("RepairItemGroupWorkOrderItem", repairItemGroupWorkOrderItem);
    }

    deleteRRepairItemGroupWorkOrderItem(repairItemGroupWorkOrderItem: RepairItemGroupWorkOrderItem) {
        return this.api.deleteDotNet("RepairItemGroupWorkOrderItem/" + repairItemGroupWorkOrderItem.id);
    }

    undeleteRepairItemGroupWorkOrderItem(repairItemGroupWorkOrderItem: RepairItemGroupWorkOrderItem) {
        return this.api.unDeleteDotNet("RepairItemGroupWorkOrderItem/" + repairItemGroupWorkOrderItem.id);
    }
}
