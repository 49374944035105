export class ContractorKPIModel {
    id: string;
    contractorId: string;
    tradeId: string;
    reportPeriod: Date;
    claimCount: number;
    claimCost: number;
    ratingStarsTotal: number;
    ratingStarsEarned: number;
    recalls: number;
    secondOpinions: number;
    firstCalls: number;
    contractorCost: number;
    purchaseOrderCost: number;
    cashOutCost: number;
    reportMonth: number;
    reportYear: number;
    accTarget: number;
    areaId: string;
    firstCallCompleteTarget: number;
    firstCallsCompleted: number;
    firstVisitResolvedCount: number;
    scoreTarget: number;
    surveyTarget: number;
    contractorRating: number;

    get costPerClaim(): number {
        if (this.claimCount === 0) {
            return 0;
        }

        return this.claimCost / this.claimCount;
    }

    get contractorRatingLetter() {
        if (this.contractorRating === 0) {
            return '';
        }

        if (this.contractorRating >= .96) {
            return 'A+';
        }
        if (this.contractorRating >= .93) {
            return 'A';
        }
        if (this.contractorRating >= .9) {
            return 'A-';
        }
        if (this.contractorRating >= .86) {
            return 'B+';
        }
        if (this.contractorRating >= .83) {
            return 'B';
        }
        if (this.contractorRating >= .8) {
            return 'B-';
        }
        if (this.contractorRating >= .76) {
            return 'C+';
        }
        if (this.contractorRating >= .73) {
            return 'C';
        }
        if (this.contractorRating >= .7) {
            return 'C-';
        }
        if (this.contractorRating >= .66) {
            return 'D+';
        }
        if (this.contractorRating >= .63) {
            return 'D';
        }
        if (this.contractorRating >= .6) {
            return 'D-';
        }

        return 'F';

    }

    get firstCallCompletionPercent(): number {
        if (this.firstCallsCompleted === 0) {
            return 0;
        }

        return this.firstVisitResolvedCount / this.firstCallsCompleted;
    }

    get recallPercent(): number {
        if (this.firstCalls === 0) {
            return 0;
        }

        return this.recalls / this.firstCalls;
    }

    get rating(): number {
        if (this.ratingStarsTotal === 0) {
            return 0;
        }

        return (this.ratingStarsEarned / this.ratingStarsTotal) * 5;
    }

    get averageClaimCost(): number {
        if (this.claimCount === 0) {
            return 0;
        }

        return Number((this.claimCost / this.claimCount).toFixed(2));
    }
}
