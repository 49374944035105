import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class OidcClient {
    @keyColumn() id: string;
    @column() clientId: string;
    @column() clientSecretHash: string;
    @column() clientSecretSalt: string;
    @column() isLocalLoginAllowed: boolean;
    @column() redirectUris: string;
    @column() deletedDate?: Date;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() entityType: string;
}