import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { Address } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-single-address-entry-modal',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule],
    templateUrl: './single-address-entry-modal.component.html',
    styleUrls: ['./single-address-entry-modal.component.css']
})
export class SingleAddressEntryModalComponent {
    address: Address = new Address();
    addressIsRequired = true;
    appearance = 'outline';

    constructor(public dialogRef: MatDialogRef<SingleAddressEntryModalComponent>,) {
        this.address.id = UtilitiesService.newid();
    }

    get canSave() {
        return this.address.address1 && this.address.city && this.address.state && this.address.postalCode;
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.address);
    }
}
