<div class="hotspot-container" [style.top]="containerTop" [style.height]="height">

    <div style="display: table;margin: 0 auto">
        <button mat-button (click)="close()">
            Authorization >

        </button>
        <button mat-button (click)="selectedDiagram = null">
            All Diagrams

            @if(selectedDiagram) {
            >
            }
        </button>
        <button mat-button *ngIf="selectedDiagram" (click)="authorizationRepairItemSelectors = null">
            {{selectedDiagram.name}}
            @if(authorizationRepairItemSelectors) {
            >
            }
        </button>
        <button mat-button *ngIf="authorizationRepairItemSelectors">
            Available Items
        </button>


    </div>

    <div style="display: table;margin: 0 auto">
        <div style="position: relative;" class="fade-in-up" *ngIf="selectedDiagram && !authorizationRepairItemSelectors">
            <img [src]="selectedDiagram.url" (load)="imageLoaded()" [id]="imageId">

            <svg version="1.2" baseProfile="tiny" [id]="svgId">

                <ng-container *ngFor="let hotspot of hotspots">
                    @if(hotspot.circle) {
                    <circle (click)="selectHotspot(hotspot)" style="cursor: pointer;" stroke="red" stroke-width=".5"
                        [attr.fill]="getFill(hotspot)" [attr.cx]="hotspot.circle.x" [attr.cy]="hotspot.circle.y"
                        [attr.r]="hotspot.circle.radius">
                    </circle>
                    }
                    @if(hotspot.polygon) {
                    <polygon (click)="selectHotspot(hotspot)" style="cursor: pointer;" stroke="red" stroke-width=".5"
                        [attr.fill]="getFill(hotspot)" [attr.points]="getPolygonPoints(hotspot.polygon)">
                    </polygon>
                    }
                </ng-container>
            </svg>
            <div class="center" topmargin40>
                <button mat-button mat-raised-button (click)="selectedDiagram = null">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>
            </div>
        </div>

        <div *ngIf="authorizationRepairItemSelectors" class="fade-in-up">
            <div style="max-width: 400px;padding: 40px">
                <h2 class="sub-heading" bottommargin20>Available Items</h2>

                <div class="center" *ngIf="authorizationRepairItemSelectors.length === 0">
                    <app-lottie-player src="https://lottie.host/499a22aa-a183-44ca-850a-0147e7ec18f8/Xh2FEpezVk.json"
                        height="100px" [intermission]="7000"></app-lottie-player>
                    <p class="info-2">No Items Configured</p>
                </div>
                <div *ngFor="let selector of authorizationRepairItemSelectors">
                    <mat-checkbox
                        [(ngModel)]="selector.selected">{{selector.authorizationRepairItem.name}}</mat-checkbox>
                </div>

                <div class="center" topmargin30>
                    <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">Add
                        Items</button>

                        <br>
                        <button mat-button (click)="authorizationRepairItemSelectors = null">
                            <mat-icon>chevron_left</mat-icon> Back
                        </button>
                </div>
            </div>

        </div>
        <div *ngIf="!selectedDiagram" class="fade-in-up" style="margin-top: 60px; padding: 40px">

            <p class="info-2" bottommargin20>Available Diagrams</p>
            <button mat-button mat-raised-button class="diagram-selector" *ngFor="let diagram of diagrams"
                (click)="selectDiagram(diagram)">
                <img [src]="diagram.url">
                <br>
                {{diagram.name}}
            </button>

            <button mat-button mat-raised-button class="diagram-selector" style="min-width: 125px;" (click)="close()">
                <mat-icon style="font-size: 50px;height: 50px;width: 50px;margin: 0">close</mat-icon>
                <br>
                Close
            </button>


        </div>
    </div>

</div>