import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { FavorFundWeight } from "./favor-fund-weight.model";

@dataSource()
export class FavorFundBudget {
    @keyColumn() id: string;
    @column() employeeId: string;
    @column() favorFundWeightId: string;
    @column() yearlyBudget: number;
    @column() year: number;
    @column() type: string;
    @column() startMonth: number;
    @column() amountAllowedOverCap: number;
    @column() allowMonthlyRollover: boolean;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate: Date;
    @column() inactiveDate?: Date;
    @column() deactivatedById: string;

}