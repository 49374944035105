import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorTradeRegionCommitmentSummary {
    @keyColumn() id: string;
    @column() contractorTradeRegionId: string;
    @column() contractorId: string;
    @column() contractor: string;
    @column() tradeId: string;
    @column() trade: string;
    // @column() contractorRegionId: string;
    // @column() contractorRegion: string;
    // @column() areaId: string;
    // @column() area: string;
    @column() serviceProTerritoryId: string;
    @column() serviceProTerritory: string;
    @column() serviceProRegionId: string;
    @column() serviceProRegion: string;
    @column() serviceProManagerAreaId: string;
    @column() serviceProManagerArea: string;
    @column() month: Date;
    @column() commitment: number;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() percentOfRegion: number;
    @column() jobCountForecast: number;
    @column() previousYearJobCount: number;
    @column() previousYearPolicyCount: number;
    @column() currentYearPolicyCount: number;

    isDirty = false;
    parent: any;

    get growthPct() {
        if(this.currentYearPolicyCount && this.previousYearPolicyCount && this.previousYearJobCount) {
            return (this.currentYearPolicyCount - this.previousYearPolicyCount) / this.previousYearPolicyCount;
        }
    }
    
    get forecastedJobCount() {
        
        if(this.currentYearPolicyCount && this.previousYearPolicyCount && this.previousYearJobCount) {
            return this.previousYearJobCount + this.previousYearJobCount * this.growthPct;
        }
    }

    get calculatedCommitment() {        
        if(this.currentYearPolicyCount && this.previousYearPolicyCount && this.previousYearJobCount) {
            let marketShare = this.percentOfRegion;
            if(marketShare > 1) {
                marketShare /= 100;
            }

            const commitmentCount = Math.round(this.forecastedJobCount * marketShare);
            return commitmentCount;
        }
    }
}