import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class Credit {
    @keyColumn() id: string;
    @column() customerId: string;
    @column() policyId: string;
    @column() amount: number;
    @column() amountApplied: number;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() vendorId: string;
    @column() memo: string;
    @readOnlyColumn() creditNumber: bigint;
    @column() voidDate?: Date;
    @column() availabilityDate?: Date;
}