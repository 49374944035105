import { NgModule } from '@angular/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthCallbackComponent, ImpersonateComponent } from './auth-callback';
import { LogOutComponent } from './log-out';

@NgModule({ declarations: [
        LogOutComponent
    ],
    exports: [], imports: [AuthCallbackComponent,
        AuthenticationRoutingModule,
        ImpersonateComponent], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AuthenticationModule { }
