import { Injectable } from '@angular/core';
import { Brand } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';


@Injectable({ providedIn: 'root' })
export class BrandApiService {
    constructor(private api: ApiService) { }

    getAllBrands() {
        return this.api.getArrayDotNet('Brand', { orderBy: 'Name' }, () => new Brand());
    }

    getAllBrands2() {
        return this.api.getArrayNode('Brand', { orderBy: 'Name' }, () => new Brand());
    }

    
}