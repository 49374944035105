import { ApiEndpoints  } from '@cogent/shared/models/common/api-endpoints.model';
import { ContractorInvoiceSummary } from '@upkeeplabs/models/cogent';

export class ContractorInvoiceSummaryClient extends ContractorInvoiceSummary {
    contractorAddress: string;

    // Client side only
    selected = false;
    removing = false;

    get totalAmount(): number {
        return this.partsCost + this.laborCost + this.tax;
    }

    get isOverAutho(): boolean {
        return this.totalAmount > this.authorizationLimit;
    }
    
    get notFound(): boolean {
        return this.workOrderNumber === -1;
    }

    get itemUrl() {
        return `${ApiEndpoints.endPointDotNet}WorkOrderItem/${this.itemId}/Photo`;
    }

    get isInvalid(): boolean {
        return this.tax < 0 || this.laborCost < 0 || this.partsCost < 0 || !this.workPerformed || !this.dateCompleted;
    }


}
