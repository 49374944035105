import { Component } from '@angular/core';
import { AuthorizationV4Component } from '../authorization-v4/authorization-v4.component';

@Component({
  selector: 'app-authorization-v4-wrapper',
  standalone: true,
  imports: [AuthorizationV4Component],
  templateUrl: './authorization-v4-wrapper.component.html',
  styleUrl: './authorization-v4-wrapper.component.scss'
})
export class AuthorizationV4WrapperComponent {

}
