import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PlanStyle {
    @keyColumn() id: string;
    @column() name: string;
    @column() style: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}