
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[myAutoHeight]' })
export class AutoHeightDirective {
    constructor(public el: ElementRef) {
        //el.nativeElement.style.backgroundColor = 'yellow';
    }

    ngAfterViewChecked() {
        if (this.el) {
            var rect = this.el.nativeElement.getBoundingClientRect();
            if (rect.width > 0) {

                var newHeight = window.innerHeight - rect.top -10;

                this.el.nativeElement.style.height = newHeight + 'px';
                this.el.nativeElement.style.overflowY = 'auto';
            }
        }
    }
}

@Directive({ selector: '[myMinAutoHeight]' })
export class AutoMinHeightDirective {
    constructor(public el: ElementRef) {
        //el.nativeElement.style.backgroundColor = 'yellow';
    }

    ngAfterViewChecked() {
        if (this.el) {
            var rect = this.el.nativeElement.getBoundingClientRect();
            if (rect.width > 0) {
                var newHeight = window.innerHeight - rect.top;

                this.el.nativeElement.style.minHeight = newHeight + 'px';
                this.el.nativeElement.style.overflowY = 'auto';
            }
        }
    }
}

