import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderContractorOffer {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() contractorId: string;
    @column() expired: boolean;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
}