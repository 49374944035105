import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderLine {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() line: string;
    @column() itemId: string;
    @column() qandA: string;
    @column() authorizationForm: string;
    @column() authorizationLimit: number;
    @column() dateCompleted: Date;
    @column() resolution: string;
    @column() cancelledDate: Date;
    @column() cancelledSentDate: Date;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() firstVisitResolved: boolean;
    @column() workOrderStatusId: string;
    @column() authorizationAmount: number;
    @column() authorizationLabor: number;
    @column() authorizationExportDate: Date;
    @column() authorizationApprovedDate: Date;
    @column() authorizationDeniedDate: Date;
    @column() authorizationDeniedForm: string;
    // @column() version: timestamp;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedDate?: Date;
    @column() lastModifiedById?: string;
    @column() deletedDate?: Date;
    @column() brandId: string;
    @column() originalWorkOrderLineId: string;
    @column() authorizationAnswers: string;
    @column() authorizationRequestArgs: string;




    calculateAuthorizationLimit = false;
    itemCanBeReplaced: boolean;
    denialDate: Date;

    selected: boolean;
    questionsComplete = false;

    get displayAutho(): number {
        if (!this.authorizationAmount) {
            return this.authorizationLimit;
        }

        return this.authorizationAmount;
    }
}