import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-typing-notification',
    templateUrl: './typing-notification.component.html',
    styleUrls: ['./typing-notification.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class TypingNotificationComponent implements OnInit {

    oneRunning = false;
    twoRunning = false;
    threeRunning = false;

    constructor() { }

    ngOnInit(): void {
        this.doAnimation();

    }

    private doAnimation() {
        setTimeout(() => {
            this.oneRunning = true;
        }, 300);

        setTimeout(() => {
            this.twoRunning = true
        }, 600);

        setTimeout(() => {
            this.threeRunning = true
        }, 900);

        setTimeout(() => {
            // this.oneRunning = false;
            // this.twoRunning = false;
            // this.threeRunning = false;

            // setTimeout(() => {
            //     this.doAnimation();
            // }, 1000);
        }, 5000);
    }

}
