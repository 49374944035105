import { keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class PolicyCountsInServiceProTerritory {
    @keyColumn() id: string;
    @column() count: number;
    @column() serviceProTerritoryId: string;
    @column() reportDate: Date;
    @column() serviceProRegionId: string;
    @column() serviceProManagerAreaId: string;
}