import { MaintenanceServiceCancellationReason } from "./maintenance-service-cancellation-reason.model";
import { MaintenanceServiceCustomerPropertyServiceAppointmentSummary } from "./maintenance-service-customer-property-service-appointment-summary.model";
import { MaintenanceServiceInvoiceItem } from "./maintenance-service-invoice-item.model";
import { MaintenanceServiceInvoice } from "./maintenance-service-invoice.model";
import { PaymentItem } from "./payment-item.model";
import { Payment } from "./payment.model";
import { StripeCard } from "./stripe-card.model";

export class MaintenanceServiceCancellationArgs {
    appointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    invoiceItems: MaintenanceServiceInvoiceItem[];
    invoices: MaintenanceServiceInvoice[];
    cancellationReasons: MaintenanceServiceCancellationReason[];
    selectedCancellationReason: MaintenanceServiceCancellationReason;
    card: StripeCard;
    maintenanceServiceCustomerPropertyServiceId: string;
    maintenanceServiceCustomerPropertyId: string;
    customerId: string;
    // payments: Payment[];
    // paymentItems: PaymentItem[];
    refundToCard: boolean;
    noRefundOnCancellation?: boolean;
    cancellationFee?: number;
    waiveCancellationFee: boolean = false;
    cancellationNote: string;
    promotionAdjustment = 0;

    get amountUsed() {
        return this.appointments.filter(i => i.selected).map(i => i.price).reduce((a, b) => a + b, 0);
    }

    get amountPaid() {
        let amount = 0;
        amount += this.promotionAdjustment;
        for (const item of this.invoiceItems) {
            const invoice = this.invoices.find(i => i.id === item.maintenanceServiceInvoiceId);
            if (invoice.amountPaid > 0) {
                const paidPercent = (invoice.amount / invoice.amountPaid);

                amount += item.amount * paidPercent;
            }
        }

        return amount;
    }

    get cancellationBalance() {
        if (this.noRefundOnCancellation) {
            return 0;
        }
        return this.amountPaid - this.amountUsed - (this.waiveCancellationFee ? 0 : (this.cancellationFee ?? 0));
    }

    get amountToCharge() {
        if (this.cancellationBalance < 0) {
            return -this.cancellationBalance;
        }

        return 0;
    }
}