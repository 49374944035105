import { keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class FirstCallsByTradeAndServiceProTerritory {
    @keyColumn() id: string;
    @column() month: Date;
    @column() tradeId: string;
    @column() tradeName: string;
    @column() itemCount: number;
    @column() serviceProTerritoryId: string;
    @column() serviceProTerritoryName: string;
    @column() serviceProRegionId: string;
    @column() serviceProManagerAreaId: string;
    @column() currentPeriodCount: number;
    @column() todayCount: number;
}