import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { DispatchLogLineItem } from "./dispatch-log.model";

@dataSource()
export class DispatchPushLogSummary {
    @keyColumn() id: string;
    @column() workOrderId?: string;
    @column() status?: string;
    @column() payloadJson: string;
    @column() createdDate?: Date;

    private _logItem: DispatchLogLineItem;
    get logItem(): DispatchLogLineItem {
        if (!this._logItem) {
            try {
                this._logItem = JSON.parse(this.payloadJson);
            } catch { }
        }

        return this._logItem;
    }

}