import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class RepairItemSummary {
    @keyColumn() id: string;
    @column() name: string;
    @column() amount: number;
    @column() laborHours: number;
    @column() yellowRangePercent: number;
    @column() isReplacement: boolean;
    @column() isPurchasable: boolean;
    @column() canNotCover: boolean;
    @column() groupName: string;
    @column() subGroupName: string;
    @column() coveredByDefault: boolean;
    @column() inactive: boolean;
    @column() isLaborOnly: boolean;
    @column() laborAmount: number;
}