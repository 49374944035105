import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PolicyRenewalOffer {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() planId: string;
    @column() basePrice: number;        // This is the base price after all adjustments before options
    @column() basePriceMonthly: number; // This is the base price after all adjustments before options
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() happyHomiversaryDiscount: number;
    @column() preferredCustomerDiscount: number;
    @column() optionPrice: number;
    @column() optionPriceMonthly: number;
}

@dataSource()
export class PolicyRenewalSummary {
    @keyColumn() id: string;
    @column() customerId: string;
    @column() name: string;
    @column() policyNumber: bigint;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() status: string;
    @column() coverageType: string;
    @column() planName: string;
    @column() planVersion: string;
    @column() expirationDate: Date;
    @column() autoRenew: boolean;
    @column() defaultRenewalPlanId: string;
    @column() defaultRenewalPlan: string;
    @column() defaultRenewalPlanVersionId: string;
    @column() defaultRenewalPlanOutOfArea: number;
    @column() basePrice: number;
    @column() basePriceMonthly: number;
    @column() optionPrice: number;
    @column() optionPriceMonthly: number;
    @column() happyHomiversaryDiscount: number;
    @column() preferredCustomerDiscount: number;
    @column() email: string;
    @column() phone: string;
    @column() stripeCardId: string;
    @column() lastUsedStripeCardId: string;
    @column() cardToProcessId: string;
    @column() brand: string;
    @column() last4: number;
    @column() url: string;
    @column() creditCardExpirationMonth: number;
    @column() creditCardExpirationYear: number;
}