
import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
export class WorkOrderAttachment {
    constructor(public name: string = null, public noteId: string = null, public base64: string = null) { }
}



export class WorkOrderAttachmentModel {
    constructor(public name: string = null, public noteId: string = null, public base64: string = null) { }

    get thumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}note/attachment/${this.noteId}/${this.name}/thumbnail`;
    }

    get downloadUrl() {
        return `${ApiEndpoints.endPointDotNet}note/attachment/${this.noteId}/${this.name}?showContent=true`;
    }

    uploading = false;
    failedToUpload = false;
}
