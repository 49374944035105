export enum DateRangeType {
    Today = 'Today',
    Yesterday = 'Yesterday',
    ThisWeek = 'ThisWeek',
    Last7Days = 'Last7Days',
    ThisMonth = 'ThisMonth',
    Last30Days = 'Last30Days',
    LastMonth = 'LastMonth',
    Last60Days = 'Last60Days',
    ThisQuarter = 'ThisQuarter',
    Last90Days = 'Last90Days',
    LastQuarter = 'LastQuarter',
    Last6Months = 'Last6Months',
    ThisYear = 'ThisYear',
    Last12Months = 'Last12Months',
    LastYear = 'LastYear',
    NextMonth = 'NextMonth',
    NextQuarter = 'NextQuarter',
    NextYear = 'NextYear',
    Next30Days = 'Next30Days',
    Future = 'Future',
    Past = 'Past',
    MTD = 'MTD',
    None = 'None',
    Null = 'Null',
}