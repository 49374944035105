import { column, keyColumn } from "@upkeeplabs/linq";

export class PolicyServiceOffering {
    @keyColumn() id: string;
    @column() name: string;
    @column() category: string;
    @column() categorySort: number;
    @column() itemSort: number;
    @column() tradeId: string;
    @column() title: string;
    @column() coveredLanguage: string;
    @column() notCoveredLanguage: string;
    @column() limitsLanguage: string;
    @column() allowTimeSlotSelection: boolean;
    @column() timeSlotDayBuffer?: number;
    @column() notCovered: boolean;
    @column() allowWithTradeId?: string;
    @column() allowServiceBeforeActive?: boolean;
    @column() coveredQuantityQuestion: string;
    @column() planItemId?: string;
    @column() singleItemJobItem?: boolean;

    isService = false;
}

export class GroupedWorkOrderItem {

    summarizedWorkOrderItems: PolicyServiceOffering[];
    hasMoreItems: boolean;

    public static fromWorkOrderItems(workOrderItems: PolicyServiceOffering[]): GroupedWorkOrderItem[] {
        const results: GroupedWorkOrderItem[] = [];

        workOrderItems = workOrderItems.sort((a, b) => a.categorySort - b.categorySort);
        workOrderItems.forEach(workOrderItem => {
            let result = results.filter(i => i.category === workOrderItem.category)[0];
            if (!result) {
                result = new GroupedWorkOrderItem(workOrderItem.category,
                    workOrderItems.filter(i => i.category === workOrderItem.category)); //.sort((a, b) => a.itemSort - b.itemSort));

                results.push(result);
            }
        });

        for (const group of results) {
            // group.workOrderItems = group.workOrderItems.sort((a, b) => { 
            //     if (a.notCovered != b.notCovered){

            //     }
            //     return a.itemSort > b.itemSort ? 1 : -1;
            // });
        }

        return results;
    }

    constructor(public category: string, public workOrderItems: PolicyServiceOffering[]) {
        this.summarizedWorkOrderItems = [];
        for (let i = 0; i < 4; i++) {
            if (workOrderItems[i]) {
                this.summarizedWorkOrderItems.push(workOrderItems[i]);
            }
        }

        this.hasMoreItems = workOrderItems.length > 4;
    }

}
