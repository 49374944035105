
import { ReportWidget } from '@cogent/client/shared/models/user-dash-board.model';

export class DepartmentHomePageConfigurationBody {
    reportWidgets: ReportWidget[];
    sideWidgets: ReportWidget[];
    iconClass: string;
}
export class DepartmentHomePageConfiguration {
    id: string;
    pageKey: string;
    pageTitle: string;
    configuration: string;

    private configurationObjectLocal: DepartmentHomePageConfigurationBody;
    get configurationObject() {

        if (!this.configurationObjectLocal && this.configuration) {

            try {
                this.configurationObjectLocal = JSON.parse(this.configuration);

            } catch (e) { }
        }
        if (!this.configurationObjectLocal) {
            this.configurationObjectLocal = new DepartmentHomePageConfigurationBody();
            this.configurationObjectLocal.reportWidgets = [];
            this.configurationObjectLocal.sideWidgets = [];
        }
        return this.configurationObjectLocal;
    }

    set configurationObject(value: DepartmentHomePageConfigurationBody) {
        this.configurationObjectLocal = value;
        this.configuration = JSON.stringify(value);
    }
}