import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorTradeRegionCommitment {
    @keyColumn() id: string;
    @column() contractorTradeRegionId: string;
    @column() month: Date;
    @column() commitment: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}