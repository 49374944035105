import { Subject } from 'rxjs';

export class ModalArguments {
    constructor(public message: string, public title: string) { }
}

export class UndoArguments {
    constructor(public callback: () => any, public message = 'Undo', public timeout = 7000) {

    }
}

export class EventArguments {
    constructor(public eventName: string, public eventParameters: any, public shouldContinue: Subject<boolean> = null) { }
}

