import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class Payment {
    @keyColumn() id: string;
    @column() amount: number;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() type: string;
    @column() voidDate: Date;
    @column() memo: string;
    @readOnlyColumn() paymentNumber: bigint;
    @column() customerId: string;
    @column() paymentProcessorId: string;
    @column() refundProcessorId: string;
    @column() brand: string;
    @column() last4: string;
    @column() exportDate: Date;
    @column() voidExportDate: Date;
    @column() quickBooksJournalId: string;
    @column() quickBooksVoidedJournalId: string;
    @column() checkNumber: string;
    @column() promotionCodeId: string;
    @column() voidedById: string;
    @column() creditCardFees: number;
    @column() postDate: Date;
    @column() voidPostDate: Date;
    @column() cashOutId: string;
    @column() favorFundExpenseId: string;
    @column() commissionable?: boolean;
}   