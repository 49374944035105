import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DateRangeType } from '@cogent/shared/models/common/date-range-type.model';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';



@Component({
    selector: 'app-date-range-selection',
    templateUrl: './date-range-selection.component.html',
    styleUrls: ['./date-range-selection.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule]
})
export class DateRangeSelectionComponent implements OnInit {

    // rangeTypes = Object.keys(DateRangeType);
    @Input() selectedRange: DateRangeType;
    @Input() disabled: boolean;
    @Output() selectedRangeChange: EventEmitter<DateRangeType> = new EventEmitter();
    @Input() showFutureDates: boolean = true;
    @Input() showNullSelection: boolean;
    private futureDates = ['NextMonth', 'NextQuarter', 'NextYear'];

    constructor() { }

    ngOnInit() {

    }

    convertToWord(rangeType: DateRangeType) {
        return UtilitiesService.convertCamelCaseToWords(rangeType);
    }

    get rangeTypes() {
        if (this.showFutureDates) {
            if (this.showNullSelection) {
                return (Object.keys(DateRangeType) as DateRangeType[]);
            } else {
                return (Object.keys(DateRangeType).filter(i => i !== DateRangeType.Null) as DateRangeType[]);
            }
        }



        if (this.showNullSelection) {
            return (Object.keys(DateRangeType).filter(i => this.futureDates.indexOf(i) === -1) as DateRangeType[]);
        } else {
            return (Object.keys(DateRangeType).filter(i => this.futureDates.indexOf(i) === -1 && i !== DateRangeType.Null) as DateRangeType[]);

        }
    }

    setRange(rangeType: DateRangeType) {
        this.selectedRange = rangeType;
        this.selectedRangeChange.emit(this.selectedRange);
    }
}
