import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CustomForm } from '@cogent/client/shared/models/custom-form.model';
import { CustomFormDisplayComponent } from '@cogent/client/shared/components/functions/custom-form-display/custom-form-display.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';

@Component({
    selector: 'app-fill-out-form',
    templateUrl: './fill-out-form.component.html',
    styleUrls: ['./fill-out-form.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        CustomFormDisplayComponent,
        MatIconModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MaterialSharedModule,
        DragDropModule,
        FormsModule,
    ]
})
export class FillOutFormComponent implements OnInit {
    formComplete = false;
    @ViewChild('formDisplay') formDisplay: CustomFormDisplayComponent;

    selectedForm: CustomForm;
    constructor(public dialogRef: MatDialogRef<FillOutFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private settingsApi: SettingsApiService) { }

    ngOnInit() {
        this.settingsApi.getCustomFormById(this.data.action.customFormId).then(customForm => {
            this.selectedForm = customForm;

            if (this.data.formDefaults && this.selectedForm && this.selectedForm.formItems) {
                for (const formItem of this.selectedForm.formItems) {
                    if (this.data.bindingObject) {
                        formItem.prompt = UtilitiesService.replaceValuesInStringFromObject(formItem.prompt, this.data.bindingObject, 'bindingObject');
                    }
                }

                for (const defaultItem of this.data.formDefaults) {
                    const formItem = this.selectedForm.formItems.find(i => i.id === defaultItem.id);
                    if (formItem && defaultItem.defaultValue && defaultItem.defaultValue !== 'undefined') {
                        if (formItem.type === 'DatePicker') {
                            formItem.answer = new Date(defaultItem.defaultValue);
                        } else if (formItem.type === 'PreferredTimeSlot') {
                            formItem.key1 = defaultItem.defaultValue;
                        } else {
                            formItem.answer = defaultItem.defaultValue;
                        }
                    }
                }
            }
        });
    }

    save() {
        this.dialogRef.close(this.selectedForm.formItems);
    }
}
