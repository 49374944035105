<div style="width: 300px; padding:10px; text-align: center;">
    <mat-form-field appearance="outline">
        <mat-label>Contact Type</mat-label>
        <mat-select [(ngModel)]="contactType" (selectionChange)="save()">
            <mat-option value="Technician">Technician</mat-option>
            <mat-option value="Dispatcher">Dispatcher</mat-option>
            <mat-option value="Accounting">Accounting</mat-option>
            <mat-option value="Manager">Manager</mat-option>
            <mat-option value="Owner">Owner</mat-option>
        </mat-select>
    </mat-form-field>
</div>