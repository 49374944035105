<mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs">
    <mat-tab label="First">
        <form #f="ngForm" (ngSubmit)="findWorkOrder(f)">

            <div flex>
                <div>

                    <mat-form-field style="max-width: 300px;" appearance="outline">
                        <mat-label>Job #</mat-label>
                        <input matInput id="work-order-number" type="number" #numberRef="ngModel" ngModel
                            name="workOrderNumber" required>

                            <span matSuffix style="display: inline-block;width: 69px;">
                                <button  style="display: inline-block;position: absolute;right: -8px; top: -16px" mat-button mat-raised-button color="primary" [disabled]="f.invalid">Find</button>
                            </span>
                    </mat-form-field>
                </div>
                <!-- <div nogrow>
                    
                </div> -->
            </div>

            <p class="rubberBand" *ngIf="notFound">Sorry, that job number could not be found.</p>

            <p class="is-danger" *ngIf="numberRef.dirty && numberRef.errors?.required">This field is required</p>


        </form>
    </mat-tab>
    <mat-tab label="Second">

        <div style="max-width: 800px" *ngIf="workOrderSummary && invoice">


            <div *ngIf="workOrderSummary.cancelledDate" class="warning-box" bottommargin30>
                <mat-icon>warning</mat-icon> <span>This job was cancelled on
                    {{workOrderSummary.cancelledDate | date: 'shortDate'}}</span>
            </div>

            <div aflex>
                <div>
                    <div class="data-label">Payable To</div>
                    <div style="font-size: 24px; color: rgb(200,200,200)">{{workOrderSummary.contractorName}}
                    </div>
                </div>
                <div>
                    <div class="data-label">Job Info</div>
                    <div>{{workOrderSummary.number}} - {{workOrderSummary.createdDate | date: 'shortDate' }}</div>
                    {{workOrderSummary.propertyAddress}}
                </div>
            </div>


            <div flex>
                <div topmargin30>
                    <div class="data-label">Received Date</div>
                    {{invoice.receivedDate | date: 'shortDate'}}
                </div>
                <div topmargin30>
                    <mat-form-field appearance="outline">
                        <mat-label>Job Completion Date</mat-label>
                        <input [min]="workOrderSummary.createdDate" matInput [matDatepicker]="completionDatePicker" [(ngModel)]="completedDate">
                        <mat-datepicker-toggle matSuffix [for]="completionDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #completionDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div topmargin30>
                    <mat-form-field appearance="outline">
                        <mat-label>Invoice #</mat-label>
                        <input matInput [(ngModel)]="invoice.contractorInvoiceNumber">
                    </mat-form-field>
                </div>
            </div>

            <div flex topmargin30>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Work Performed</mat-label>
                        <textarea rows="6" matInput [(ngModel)]="invoice.workPerformed"></textarea>
                    </mat-form-field>
                    <ng-container *ngIf="jobItems && jobItems.length > 1">
                        <mat-checkbox  style="margin-right: 20px" *ngFor="let line of jobItems" [(ngModel)]="line.selected">
                            {{line.itemName}}</mat-checkbox>
                    </ng-container>
                </div>
                <div nogrow class="totals-column" >
                    <mat-form-field appearance="outline">
                        <mat-label>Parts Cost</mat-label>
                        <input matInput type="number" [(ngModel)]="invoice.partsCost">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Labor Cost</mat-label>
                        <input matInput type="number" [(ngModel)]="invoice.laborCost">
                    </mat-form-field>


                    <mat-form-field appearance="outline">
                        <mat-label>Tax</mat-label>
                        <input matInput type="number" [(ngModel)]="invoice.tax">
                    </mat-form-field>

                    <div id="invoice-total">
                        <app-display-total [total]="invoice.amount"></app-display-total>
                        <!-- <div class="data-label">Total</div>
                        <h1>
                            {{invoice.amount | currency}}
                        </h1> -->

                        <div class="data-label" topmargin20>Autho Limit</div>
                        <h1>{{authoAmount | currency}}</h1>
                    </div>
                </div>
            </div>
            <button mat-button mobile-block topmargin30 mat-raised-button color="primary" (click)="saveInvoice()"
                [disabled]="!invoice.canSave || savingInvoice || invoice.amount > workOrderSummary.authorizationLimit">
                <mat-spinner *ngIf="savingInvoice" class="thirty"></mat-spinner>
                Save Invoice
            </button>

        </div>
    </mat-tab>
</mat-tab-group>