import { Address } from "./address.model";
import { Brand } from "./brand.model";
import { ServiceProTerritorySummary } from "./service-pro-territory-summary.model";
import { Trade } from "./trade.model";

export class BecomeAContractorArgs {
    name: string;
    phoneNumber: string;
    email: string;
    companyName: string;
    address: Address;
    brands: Brand[];
    trades: Trade[];
    areas: string[];
    source: string;

    fullyLicensed: boolean;
    hasLiabilityInsurance: boolean;
    hasWorkmansComp: boolean;
    hasAutoInsurance:boolean;
    hasPropertyDamageInsurance: boolean;
}