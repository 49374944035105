import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PolicyAdjustment {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() planPricingAdjustmentId: string;
    @column() adjustmentAmount: number;
    @column() adjustmentAmountRecurring: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;

    adjustmentName: string;
    allowAmountOverride: boolean;
}