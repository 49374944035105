import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ContractorDocument, ContractorDocumentType, Entity } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';

@Component({
    selector: 'app-entity-documents',
    templateUrl: './entity-documents.component.html',
    styleUrls: ['./entity-documents.component.css']
})
export class EntityDocumentsComponent implements OnInit, OnChanges {

    @Input() entity: Entity;
    documents: ContractorDocument[];
    documentTypes: ContractorDocumentType[];
    constructor(private entityApi: EntityApiService, private missionService: MissionService) { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.entity && changes.entity.currentValue) {
            this.refreshDocuments();
        }
    }

    async refreshDocuments() {
        this.documentTypes = await this.entityApi.getEntityDocumentTypes();
        const documents = await this.entityApi.getEntityDocuments(this.entity.id);

        for (const docType of this.documentTypes) {
            let document = documents.filter(i => i.contractorDocumentTypeId === docType.id)[0];
            if (!document) {
                document = new ContractorDocument();
                document.id = UtilitiesService.newid();
                document.contractorDocumentTypeId = docType.id;
                document.entityId = this.entity.id;
                document.modified = true;
                documents.push(document);
            }


        }
        this.documents = documents;
        for (const doc of documents) {
            this.findDocumentFile(doc);
        }
    }

    async findDocumentFile(document: ContractorDocument) {
        const result = await this.entityApi.getEntityDocumentFileExists(document);
        document.hasDocument = result;
    }

    getDocumentTypeName(document: ContractorDocument) {
        const type = this.documentTypes.filter(i => i.id === document.contractorDocumentTypeId)[0];
        if (type) {
            return type.name;
        }
    }

    get baseApiPath() {
        return ApiService.endPointDotNet;
    }

    showUpload(contractorDocument: ContractorDocument) {
        document.getElementById(contractorDocument.id).click();
    }

    markAsModified(document: ContractorDocument) {
        document.modified = true;
    }

    handleFiles(files, document: ContractorDocument) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (const file of files) {

            const reader = new FileReader();

            const setUrl = (url) => {
                document.file = url;
                document.modified = true;
                this.missionService.showSuccessToast('Document Added.  Press save to upload.');
            };
            reader.onload = (() => {
                return function (e) {
                    (this as any).dragTargetActive = false;
                    setUrl(e.target.result);
                };
            })();

            reader.readAsDataURL(file);
        }
    }

    save(): Promise<any> {
        const promises = [];
        const documentsToSave = this.documents.filter(i => i.modified);
        for (const doc of documentsToSave) {
            if (doc.file) {
                promises.push(this.entityApi.saveEntityDocumentFile(doc));
                doc.documentPath = `entity-documents/${doc.entityId}/${doc.contractorDocumentTypeId}`;
            } 
            const copy = JSON.parse(JSON.stringify(doc));
            delete copy.file;

            promises.push(this.entityApi.saveEntityDocument(copy));
            doc.modified = false;
        }
        return Promise.all(promises);
    }
}
