import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatPhoneNumberPipe, HighlightSearch, SafeHtmlPipe } from '../safe-html.pipe';
import { StripHtmlPipe } from '../strip-html-tags.pipe';
import { SpecialCurrencyPipe } from '../special-currency.pipe';


@NgModule({
  declarations: [SafeHtmlPipe, StripHtmlPipe, FormatPhoneNumberPipe, SpecialCurrencyPipe, HighlightSearch],
  imports: [
    CommonModule
  ],
  exports: [SafeHtmlPipe, StripHtmlPipe, FormatPhoneNumberPipe, SpecialCurrencyPipe, HighlightSearch]
})
export class UpkeepPipesModule { }
