import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class MlsOffice {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedDate?: Date;
    @column() lastModifiedById?: string;
    @column() deletedDate?: Date;
    @column() mls: string;
    @column() mlsId: string;
    @column() name: string;
    @column() logoUrl: string;
    @column() brokerId: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() zip: string;
    @column() phone1: string;
    @column() phone2: string;
}