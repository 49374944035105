import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AuthorizationItem {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() itemId: string;
    @column() quantity: number;
    @column() unitPrice: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() number: string;
    @column() description: string;
    @column() beginYellow: number;
    @column() beginRed: number;
    @column() action: string;

      // Client side only

      constructor() {
        this.unitPrice = 0;
        this.quantity = 1;
    }

    get subTotal(): number {
        return this.unitPrice * this.quantity;
    }
    set subTotal(value: number) {
        if (this.quantity != 0) {
            this.unitPrice = value / this.quantity;
        }
    }

    get complete(): boolean {
        return this.description && this.number && this.unitPrice >= 0;
    }

    get isNewItem(): boolean {
        return !this.itemId && this.complete;
    }


    get endRed(): number {
        if (!this.beginYellow || !this.beginRed || this.beginRed < this.beginYellow) {
            return 0;
        }

        return this.beginRed + (this.beginRed - this.beginYellow);
    }

    get beginningGreen(): number {
        if (!this.beginYellow || !this.beginRed || this.beginRed < this.beginYellow) {
            return 0;
        }
        return this.beginYellow - (this.beginRed - this.beginYellow);
    }


    saveNewItem: boolean;
    saved = false;

    // client-side only
    get extendedPrice() {
        return this.quantity * this.unitPrice;
    }

    deleting = false;

    get pct(): number {
        if (!this.endRed) {
            return 0;
        }

        const span = this.endRed - this.beginningGreen;
        let adjustedUnitPrice = this.unitPrice - this.beginningGreen;
        if (adjustedUnitPrice < 0) {
            adjustedUnitPrice = 0;
        }

        let pct = adjustedUnitPrice / span;
        if (pct > 1) {
            pct = 1;
        }

        return pct;
    }
}