import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CommissionableOrder {
    @column() createdById: string;
    @column() createdByName: string;
    @column() previousExpired?: Date;
    @column() createdDate?: Date;
    @column() renewalCall1?: Date;
    @column() id: string;
    @column() renewedWithin?: number;
    @column() policyNumber: number;
    @column() premium: number;
    @column() isCurrent: boolean;
    @column() targetCount: number;
    @column() type: string;
    @column() totalPrice: number;
    @column() basePrice: number;
    @column() commissionable?: boolean;
    @column() isPaid: boolean;
    @column() coverageType: string;
    @column() planName: string;
    @column() holderName: string;
    @column() propertyAddress1: string;
    @column() agentId: string;
    @column() agentName: string;
    @column() closingCompanyId: string;
    @column() closingCompanyName: string;

    get isCommissionable() {
        if (!this.isPaid) return 'N';
        if (this.coverageType == 'Renewal') {
            if (this.renewedWithin <= 0) { //PRIOR TO EXPIRATION
                return (this.commissionable || this.renewalCall1 != null) ? 'Y' : 'N';
            }

            //AFTER EXPIRATION
            if (!this.renewalCall1) return 'N';
        }
        return 'Y';
    }
}