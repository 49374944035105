import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TaskMessage {
    @keyColumn() id: string;
    @column() taskId: string;
    @column() createdDate?: Date;
    @column() to: string;
    @column() from: string;
    @column() subject: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() messageId: string;
    @column() workOrderId: string;
    @column() policyId: string;
    @column() rootMessageId: string;
    @column() fromUserId: string;
    @column() type: string;
    @column() fromNumber: string;
    @column() messageDetails: string;
    @column() direction: string;
    @column() mediaUrl: string;
    @column() entityId: string;
    @column() internalSMSNumber: string;
    @column() externalSMSNumber: string;
    @column() isArchived?: boolean;

    response: string;
}


export class ConversationModel {

    subject: string;
    rootMessageId: string;
    taskMessages: TaskMessage[];
    expanded: boolean;
    showAll: boolean;


    get lastFrom() {
        if (!this.taskMessages || this.taskMessages.length > 0) {
            return this.taskMessages[0].from;
        }

        return null;
    }

    get lastFromUserId() {
        if (!this.taskMessages || this.taskMessages.length > 0) {
            return this.taskMessages[0].fromUserId;
        }

        return null;
    }

    get lastCreatedDate() {
        //createdDate

        if (!this.taskMessages || this.taskMessages.length > 0) {
            return this.taskMessages[0].createdDate;
        }

        return null;
    }

    get taskMessage() {
        return this.taskMessages.filter(i=>!i.fromUserId)[0];
    }

    private _firstMessage;
    get firstMessage() {
        if(!this._firstMessage) {
            this._firstMessage = this.taskMessages[0];
        }

        return this._firstMessage;
    }

    private _additionalMessages;
    get additionalMessages() {
        if(!this._additionalMessages) {
            this._additionalMessages = this.taskMessages.filter(i=>i !== this.firstMessage);
        }
        return this._additionalMessages;
    }

    static fromTaskMessages(taskMessages: TaskMessage[]): ConversationModel[] {

        const results: ConversationModel[] = [];

        taskMessages.forEach(taskMessage => {

            let conversation = results.filter(i => i.rootMessageId === taskMessage.rootMessageId)[0];
            if (conversation == null) {
                conversation = new ConversationModel();

                conversation.subject = taskMessage.subject;
                conversation.rootMessageId = taskMessage.rootMessageId;
                conversation.taskMessages = [taskMessage];
            
                results.push(conversation);
            } else {
                conversation.taskMessages.push(taskMessage);
            }
            taskMessage.response = '';

        });

        return results;
    }
}
