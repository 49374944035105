import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Item {
    @keyColumn() id: string;
    @column() number: string;
    @column() description: string;
    @column() defaultAmount: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() beginYellow: number;
    @column() beginRed: number;
    @column() canRepair: boolean;
    @column() canReplace: boolean;
    @column() canNotCover: boolean;
    @column() replacesWorkOrderItemId: string;
    @column() attributes: string;
    @column() productUrl: string;
    @column() homeDepotItemCode: string;

    score: number;
    safeProductUrl: any; 

    // client-side only
    selected: boolean;
    private _attributesObject: string[];
    get attributesObject(): string[] {
        if(!this.attributes) {
            this.attributes = '[]';
        }

        if (!this._attributesObject && this.attributes) {
            try {
                this._attributesObject = JSON.parse(this.attributes);
            }
            catch (e) {
                this._attributesObject = [];
             }
        }

        return this._attributesObject;
    }
}