<div id="intellisense-menu" *ngIf="intellisenseOpen" [style.width]="intellisenseWidthPx"
  [style.height]="intellisenseHeight" [style.max-height]="intellisenseMaxHeightPx" [style.left]="intellisenseLeft"
  [style.top]="intellisenseTop">

  <button mat-button id="{{field.key}}" *ngFor="let field of filteredQuickNotes"
    [class.selected]="selectedQuickNote === field" class="intellisense-button" (click)="setIntellisenseField(field)">
    <span *ngIf="!field.isMention">{{field.key}} - </span>{{field.note}}
  </button>
</div>


<div id="formatting-menu" *ngIf="formattingOpen" [style.left]="intellisenseLeft" [style.top]="intellisenseTop">
  <div class="arrow_box">

    <div class="toolbar">
      <button mat-button [class.selected]="isBold" (click)="format('bold')">
        <mat-icon>format_bold</mat-icon>
      </button>
      <button mat-button [class.selected]="isItalic" (click)="format('italic')">
        <mat-icon>format_italic</mat-icon>
      </button>
      <button mat-button [class.selected]="isUnderline" (click)="format('underline')">
        <mat-icon>format_underlined</mat-icon>
      </button>
      <button mat-button (click)="format('insertunorderedlist')">
        <mat-icon>format_list_bulleted</mat-icon>
      </button>
      <input type="color" [(ngModel)]="color" (ngModelChange)="format('foreColor',$event)">
  
      <select style="margin-top: 3px" (change)="fontChanged($event)" [(ngModel)]="fontSize">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3" selected="true">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
      </select>
      <button mat-button (click)="format('justifyLeft')">
          <mat-icon>format_align_left</mat-icon>
      </button>
      <button mat-button (click)="format('justifyRight')">
          <mat-icon>format_align_right</mat-icon>
      </button>
      <button mat-button (click)="format('justifyCenter')">
          <mat-icon>format_align_center</mat-icon>
      </button>

      <button mat-button style="border-left: 1px dotted #fff;margin-left: 10px;" (click)="formattingOpen = false">
          <mat-icon>close</mat-icon>
      </button>
  
    </div>
  </div>
</div>

<div style="position: relative;">
  <div class="editor" [id]="id" [attr.contenteditable]="!disabled" [style.max-height]="maxHeight" [style.height]="height" [style.min-height]="minHeight" (input)="valueChange()" (keydown)="respondToKeyDownEvent($event)"
    (blur)="inputBlurred($event)" (drop)="drop($event)" (paste)="onPaste($event)" (keyup)="respondToKeyEvent($event)">
  </div>
  <div class="find-mode" *ngIf="findMode">
    Finding {{findModeDesc}}
</div>
</div>




