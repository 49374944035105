<div class="container fancy-container bottom-right-rounded">
    <div flex>
        <div>
            <h1 class="page-title">
                <span class="big">Billing</span><br><span class="little">Information</span>
            </h1>
        </div>

    </div>
    <!-- <div aflex>
        <div></div>
        <div nogrow>
            <button mat-button (click)="addInvoice()">
                <mat-icon>add</mat-icon> Add Invoice
            </button>
        </div>
    </div> -->

    <div flex>
        <div nogrow class="left-side" >
            <app-contractor-statements [useMaintenanceService]="true" [contractorId]="contractorId"></app-contractor-statements>
            <mat-card topmargin20>
                <div style="position: relative">
                    <mat-card-header>
                        <mat-card-title>
                            <i class="material-icons" mat-card-avatar>pending_actions</i> Pending Invoices
                        </mat-card-title>
                    </mat-card-header>
                </div>
                <div>
                    <ul style="padding: 0;padding-left: 20px;">
                        <li class="pending-invoice" *ngFor="let invoice of pendingInvoices">
                            <h2>{{invoice.date | date: 'shortDate'}} - <span *ngIf="invoice.vendorNumber">{{invoice.vendorNumber}}</span>
                                <span *ngIf="!invoice.vendorNumber">{{invoice.invoiceNumber}}</span>
                            </h2>
                            <div>{{invoice.propertyAddress1}}</div>
                            <div>
                                <span class="data-label">Amount: </span>{{invoice.amount | currency}}
                            </div>
                        </li>
                    </ul>
                </div>
            </mat-card>
        </div>
        <div >
            <!-- <div flex>

                <div flex1 >

                    <mat-card>
                        <div style="position: relative">
                            <mat-card-header>
                                <mat-card-title>
                                    Un-Approved Invoices
                                </mat-card-title>
                            </mat-card-header>
                        </div>
                        <div class="data-label">Unapproved</div>
                        <h1 class="big-data">{{unapprovedSum | currency}}</h1>

                        {{unapprovedCount}} transactions
                        <div aflex>
                            <div></div>
                            <div nogrow>
                                <button mat-button (click)="showUnapprovedTransactions()">
                                    More <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div flex1 topmargin20>

                    <mat-card>
                        <div style="position: relative">
                            <mat-card-header>
                                <mat-card-title>
                                    Un-Paid Invoices
                                </mat-card-title>
                            </mat-card-header>
                        </div>
                        <div class="data-label">Unpaid</div>
                        <h1 class="big-data">{{unpaidSum | currency}}</h1>

                        {{unpaidCount}} transactions
                        <div aflex>
                            <div></div>
                            <div nogrow>
                                <button mat-button (click)="showUnpaidTransactions()">
                                    More <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div> -->
            <mat-card >
                <div style="position: relative">
                    <mat-card-header>
                        <mat-card-title>
                            <i class="material-icons" mat-card-avatar>bar_chart</i> Payments By Month
                        </mat-card-title>
                    </mat-card-header>
                </div>
                <div>
                    <canvas style="height:  300px; max-width: 100%" topmargin30 [id]="paymentsByMonthId"></canvas>
                </div>
            </mat-card>


        </div>
    </div>
</div>   
<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>
<!-- 
<div id="object-detail-panel" *ngIf="showDetail">
    <button mat-button class="close-detail-button agenda" style="position: absolute;" (click)="showDetail = false">
        <mat-icon>close</mat-icon>
    </button>

    <div id="object-detail-panel-inner">

        <div *ngIf="invoices && invoices.length === 0">
            <h2 class="sub-heading" topmargin40>No Invoices Here</h2>
        </div>
        <div class="detail-item" *ngFor="let invoice of invoices" aflex>
            <div nogrow>
                <mat-icon>wysiwyg</mat-icon>
            </div>
            <div>
                <div aflex>
                    <div>
                        <h2><a routerLink="/work-order-detail/{{invoice.workOrderId}}">{{invoice.workOrderNumber}}</a>
                        </h2>
                    </div>
                    <div nogrow>

                        <button mat-button (click)="deleteInvoice(invoice)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>

                <div>{{invoice.amount | currency}}</div>
                <div>{{invoice.propertyAddress}}</div>


            </div>

        </div>
    </div>
</div> -->