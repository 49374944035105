import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesOrderTarget {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() month: number;
    @column() year: number;
    @column() orderTarget: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() channel: string;

    
    // Client-side only
    isDirty = false;
}