import { Component, ViewChild, OnChanges, ElementRef, Input, SimpleChanges, AfterViewChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    templateUrl: './circle-widget-small.component.html',
    selector: 'app-circle-widget-small',
    styleUrls: ['./circle-widget-small.component.css']
})
export class CircleWidgetSmallComponent implements AfterViewInit, OnChanges {

    @Input() percent: number;
    @Input() label: string;
    @Input() number: string;
    @Input() showNumber = true;
    @Input() strokeWidth = 7;
    @Input() strokeCircleWidth = 5;
    @Input() strokeColor = '#58bf74';
    @Input() strokeEndColor = '#58bf74';
    @Input() glowAmount = 0;
    @Input() height = 100;
    @Input() iconClass: string;
    @Input() textColor = '#1C053A';
    outerCircleId = UtilitiesService.newid();
    innerCircleId = UtilitiesService.newid();
    linearId = UtilitiesService.newid();
    showSvg = false;


    ngAfterViewInit(): void {
        this.drawCircle();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.height && changes.height.currentValue) {
            this.drawCircle();
        }
    }

    drawCircle() {
        const outerCircle = document.getElementById(this.outerCircleId);
        const innerCircle = document.getElementById(this.innerCircleId);
        if (outerCircle && innerCircle) {
            outerCircle.setAttribute('r', this.radius.toString());
            innerCircle.setAttribute('r', this.radius.toString());
            outerCircle.setAttribute('cx', this.containerMid.toString());
            outerCircle.setAttribute('cy', this.containerMid.toString());
            innerCircle.setAttribute('cx', this.containerMid.toString());
            innerCircle.setAttribute('cy', this.containerMid.toString());
        } else {
            setTimeout(() => this.drawCircle(), 500);
        }
    }

    get archCircumferenceBound() {
        return this.dashArray - (this.dashArray * (this.percent / 100));
    }

    get containerHeight() {
        return `${this.height + (this.strokeWidth * 2)}px`;
    }

    get containerMid() {
        return (this.height + (this.strokeWidth * 2)) / 2;
    }

    get radius() {
        return this.height / 2;
    }

    get dashArray() {
        return 2 * Math.PI * this.radius;
    }

    get strokeUrl() {
        return `url(#${this.linearId})`;
    }
}
