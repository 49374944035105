import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CreditItemAccounting {
    @column() creditId: string;
    @column() creditItemId: string;
    @column() void: number;
    @column() area: string;
    @column() accountingAreaId: string;
    @column() accountType: string;
    @column() quickbooksAccountId: number;
    @column() debitAmount: number;
    @column() creditAmount: number;
    @column() exportDate: Date;
    @column() postDate: Date;
    @column() quickBooksJournalId: string;
    @column() createdDate: Date;
    @column() memo: string;
    @column() customer: string;
    @column() policyId: string;
}