import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ServiceProTerritorySummary {
    @keyColumn() territoryId: string;
    @column() territoryName: string;
    @column() regionId: string;
    @column() regionName: string;
    @column() areaId: string;
    @column() areaName: string;

    territories: ServiceProTerritorySummary[] = [];
    regions: ServiceProTerritorySummary[] = [];

    parent: ServiceProTerritorySummary;
    
    hasTerritorySelected = false;
    hasSelected = false;
    expanded = false;
}