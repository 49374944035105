import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderLineSummary {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() line: string;
    @column() itemId: string;
    @column() qandA: string;
    @column() authorizationForm: string;
    @column() authorizationLimit: number;
    @column() dateCompleted: Date;
    @column() resolution: string;
    @column() cancelledDate: Date;
    @column() cancelledSentDate: Date;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() firstVisitResolved: boolean;
    @column() workOrderStatusId: string;
    @column() authorizationAmount: number;
    @column() authorizationLabor: number;
    @column() authorizationExportDate: Date;
    @column() authorizationApprovedDate: Date;
    @column() authorizationDeniedDate: Date;
    @column() authorizationDeniedForm: string;
    @column() createdDate: Date;
    @column() createdById: string;
    @column() lastModifiedDate: Date;
    @column() lastModifiedById: string;
    @column() brandId: string;
    @column() itemName: string;
    @column() iconClass: string;
    @column() status: string;
    @column() percentComplete: number;
    @column() policyId: string;
    @column() workOrderNumber: number;
    @column() contractorName: string;
    @column() contractorId: string;
    @column() sLAStatusRedHours: number;
    @column() sLAStatusYellowHours: number;
    @column() sLAHoursType: string;
    @column() iconColor: string;
    @column() sLABasedFromTime: Date;
    @column() timeZoneId: string;
    @column() number: number;
    @column() propertyAddress: string;
    @column() originalWorkOrderLineId: string;
    @column() laborRate: number;
    @column() singleItemJobItem: boolean;
    @column() authorizationRequestArgs: string;
    @column() policyNumber: number;
    @column() itemCanBeReplaced: boolean;
    @column() propertyId: string;
    @column() denialDate: Date;
    @column() claimId: string;
    @column() type: string;
    @column() denailDate: Date;
    @column() statusSort: number;
    @column() propertyPostalCode: string;
    @column() sLARedTime: Date;
    @column() sLAYellowTime: Date;
    @column() sLANoneTime: Date;
    @column() sLAStatus: string;
    @column() minutesToNone: number;
    @column() minutesToYellow: number;
    @column() minutesToRed: number;

    itemUrl: string;
}