import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { EntityCommunicationItem } from "./entity-communication-item.model";

@dataSource()
export class EntityCommunicationSummary {
    @keyColumn() id: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() entityId: string;
    @column() lastMessage: string;
    @column() lastMessageDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() remoteEndpoint: string;
    @column() remoteEntityId: string;
    @column() transactionId: string;
    @column() unreadCount: number;
    @column() remoteName: string;
    @column() localName: string;
    @column() remoteEmail: string;
    @column() localEmail: string;

    items: EntityCommunicationItem[];

    loadingItems = false;

    get initials() {
        if (this.remoteName && this.remoteName.length >= 2) {
            return this.remoteName.substring(0, 2);
        }
        if (this.remoteEndpoint) {
            return this.remoteEndpoint.substring(0, 2);
        }
    }
}