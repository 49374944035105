import { Component, HostListener, OnInit, forwardRef, Input, OnChanges, SimpleChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Entity, UserTextSnippet } from '@upkeeplabs/models/cogent';


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RichTextAndSnippetComponent),
    multi: true
};

const noop = () => { };

@Component({
    selector: 'app-rich-text-and-snippet',
    templateUrl: './rich-text-and-snippet.component.html',
    styleUrls: ['./rich-text-and-snippet.component.css'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class RichTextAndSnippetComponent implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {

    color = '';
    fontSize = 3;
    id = UtilitiesService.newid();
    @Input() quickNotes: UserTextSnippet[];
    @Input() mentionables: Entity[];
    @Output() quickNotesChange: EventEmitter<UserTextSnippet[]> = new EventEmitter();
    @Input() maxHeight: string = 'none';
    @Input() minHeight: string = '100px';
    @Input() height: string = 'unset';
    @Input() pasteIntoDocument = false;
    @Input() disabled = false;

    selectedQuickNote: UserTextSnippet;
    filteredQuickNotes: UserTextSnippet[];
    intellisenseWidth = 400;
    formattingWidth = 500;
    itemHeight = 36.78;
    intellisenseMaxHeight = 288;

    intellisenseOpen = false;
    intellisenseLeft = '';
    intellisenseTop = '';
    intellisenseField: any;
    intellisenseHeight = '';
    html = '';
    isBold = false;
    isUnderline = false;
    isItalic = false;
    formattingOpen = false;
    formattingTimeOut;
    private settingFormatting = false;
    findMode: string;
    findUrl: string;

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    constructor(private searchApi: DataApiService) {

    }

    ngOnInit() { }

    ngAfterViewInit() {
        if (this.html) {
            this.setInitial();
        }
    }

    private setInitial() {
        if (!document.getElementById(this.id)) {
            setTimeout(() => this.setInitial(), 100);
            return;
        }
        if (!this.html) {
            this.html = '';
        }
        document.getElementById(this.id).innerHTML = this.html;
    }

    get value(): any {
        return this.html;
    };

    focus() {
        if (document.getElementById(this.id)) {
            document.getElementById(this.id).focus();
        } else {
            setTimeout(() => this.focus(), 100);
        }
    }

    set value(v: any) {
        if (v !== this.html) {
            this.html = v;
            this.onChangeCallback(v);
        }
    }

    onBlur() {
        this.onTouchedCallback();
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    setFocusToEnd() {
        const el = document.getElementById(this.id);
        el.focus();
        if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }


    writeValue(value: any) {
        if (value !== this.html) {
            this.html = value;
            if (!document.getElementById(this.id)) {
                // setTimeout(() => this.writeValue(value), 100);
            } else {

                if (value) {
                    document.getElementById(this.id).innerHTML = value;
                } else {
                    document.getElementById(this.id).innerHTML = '';
                }
            }
        }
    }

    writeValueWait(value: any) {
        if (!document.getElementById(this.id)) {
            setTimeout(() => this.writeValueWait(value), 100);
        } else {
            this.writeValue(value);
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    format(command, value = null) {
        this.settingFormatting = true;
        document.execCommand(command, false, value);

        setTimeout(() => {
            document.getElementById(this.id).focus();
            this.settingFormatting = false;
        });
    }

    @HostListener('document:selectionchange', ['$event'])
    handleKeyboardDownEvent(event) {
        const selection = document.getSelection();
        let parent = selection.anchorNode;
        let editorFound = false;
        while (parent) {
            if ((parent as any).id === this.id) {
                editorFound = true;
                break;
            }
            parent = parent.parentNode;
        }
        if (!editorFound) {
            this.formattingOpen = false;
            return;
        }

        let node = selection.anchorNode;
        this.isBold = false;
        this.isUnderline = false;
        this.isItalic = false;
        this.fontSize = 3;
        this.color = '#fff';


        clearTimeout(this.formattingTimeOut);
        if (!this.settingFormatting) {
            this.formattingOpen = false;
        }

        this.formattingTimeOut = setTimeout(() => {
            this.formattingOpen = (selection as any).extentOffset !== selection.anchorOffset;
            if (this.formattingOpen) {
                const coordinates = UtilitiesService.getSelectionCoords(window);


                let left = (coordinates.left);
                let top = (document.getElementById(this.id).getBoundingClientRect().top) - 50;

                if (left + this.formattingWidth > window.innerWidth) {
                    left = window.innerWidth - this.formattingWidth;
                }
                const height = 50;

                if (top + height > window.innerHeight) {
                    top = top - height - 20;
                }
                this.intellisenseLeft = left + 'px';
                this.intellisenseTop = top + 'px';
            }


        }, 750);
        while (node) {
            if (node.nodeName === 'I') {
                this.isItalic = true;
            }
            if (node.nodeName === 'U') {
                this.isUnderline = true;
            }
            if (node.nodeName === 'B') {
                this.isBold = true;
            }
            if (node.nodeName === 'FONT') {
                const anyNode = node as any;
                if (anyNode.attributes && anyNode.attributes['size'] && anyNode.attributes['size'].value) {
                    this.fontSize = anyNode.attributes['size'].value;
                }
                if (anyNode.attributes && anyNode.attributes['color'] && anyNode.attributes['color'].value) {
                    this.color = anyNode.attributes['color'].value;
                }
            }
            node = node.parentNode;
        }
    }

    valueChange() {
        this.value = document.getElementById(this.id).innerHTML;
    }

    fontChanged(value) {
        this.settingFormatting = true;
        document.execCommand('fontSize', false, value.srcElement.value);
        // this.settingFormatting = false;
        setTimeout(() => this.settingFormatting = true);
    }

    get intellisenseWidthPx() {
        return this.intellisenseWidth + 'px';
    }

    intellisenseMaxHeightPx() {
        return this.intellisenseMaxHeight + 'px';
    }

    drop(evt) {
        if (!this.pasteIntoDocument) {
            return;
        }


        evt.stopPropagation();
        evt.preventDefault();
        //get x and y coordinates of the dropped item
        const x = evt.clientX;
        const y = evt.clientY;
        //drops are treated as multiple files. Only dealing with single files right now, so assume its the first object you're interested in
        var file = evt.dataTransfer.files[0];
        //don't try to mess with non-image files
        if (file.type.match('image.*')) {
            //then we have an image,

            //we have a file handle, need to read it with file reader!
            var reader = new FileReader();

            // Closure to capture the file information.
            reader.onload = (theFile => {
                //get the data uri
                var dataURI = theFile.target.result;
                //make a new image element with the dataURI as the source
                var img = document.createElement("img");
                img.src = dataURI as any;

                //Insert the image at the carat

                // Try the standards-based way first. This works in FF
                if ((document as any).caretPositionFromPoint) {
                    var pos = (document as any).caretPositionFromPoint(x, y);
                    const range = document.createRange();
                    range.setStart(pos.offsetNode, pos.offset);
                    range.collapse();
                    range.insertNode(img);
                }
                // Next, the WebKit way. This works in Chrome.
                else if ((document as any).caretRangeFromPoint) {
                    const range = (document as any).caretRangeFromPoint(x, y);
                    range.insertNode(img);
                }
                else {
                    //not supporting IE right now.
                }


            });
            //this reads in the file, and the onload event triggers, which adds the image to the div at the carat
            reader.readAsDataURL(file);
        }
    }

    onPaste(evt) {
        if (this.pasteIntoDocument) {
            return;
        }
        const clipboardData = evt.clipboardData || (window as any).clipboardData;
        const text = clipboardData.getData('Text');
        if (!text) {
            evt.preventDefault();
        } else {
            let plantext = clipboardData.getData('text/plain');
            if (typeof plantext !== 'undefined') {
                // plantext = UtilitiesService.replaceAll(plantext, '\n', '\n\n');
                const lines = plantext.split('\n');
                for (const line of lines) {
                    this.format('insertText', line);
                    this.format('insertText', '\n');

                }
                // this.format('insertText', plantext);
                evt.preventDefault();
            }
        }
    }

    respondToKeyDownEvent(event: KeyboardEvent) {
        const field = event.target;
        this.intellisenseField = field;
        if (event.key === 'Escape') {
            this.intellisenseOpen = false;
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
        } else if (event.key === 'ArrowDown' && this.intellisenseOpen) {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();

            let index = this.filteredQuickNotes.indexOf(this.selectedQuickNote) + 1;
            if (index >= this.filteredQuickNotes.length) {
                index = 0;
            }
            this.selectedQuickNote = this.filteredQuickNotes[index];
            this.scrollElementIntoView();
        } else if (event.key === 'ArrowUp' && this.intellisenseOpen) {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();

            let index = this.filteredQuickNotes.indexOf(this.selectedQuickNote) - 1;
            if (index === -1) {
                index = this.filteredQuickNotes.length - 1;
            }
            this.selectedQuickNote = this.filteredQuickNotes[index];
            this.scrollElementIntoView();
        } else if ((event.key === 'Tab' || event.key === 'Enter') && this.intellisenseOpen) {
            if (this.selectedQuickNote) {
                this.setIntellisenseField(this.selectedQuickNote);
            } else {
                this.intellisenseOpen = false;
            }
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
        }

        const selection = document.getSelection();

        let nodeToSearchFor = selection.anchorNode;
        while (nodeToSearchFor) {
            if (nodeToSearchFor.nodeName === 'FONT') {
                const fontElement = nodeToSearchFor as HTMLFontElement;
                if (fontElement.attributes['color'] && fontElement.attributes['color'].nodeValue === '#337ab7') {
                    fontElement.setAttribute('color', 'rgb(51,51,51)');
                }
                break;
            }
            nodeToSearchFor = nodeToSearchFor.parentNode;
            if (nodeToSearchFor as HTMLElement) {
                const element = nodeToSearchFor as HTMLElement;
                if (element.getAttribute && element.getAttribute('contenteditable') === 'true') {
                    break;
                }
            }
        }
        if (selection.anchorNode && selection.anchorNode.parentNode && selection.anchorNode.parentNode.nodeName === 'A') {
            const parentAnchor = selection.anchorNode.parentNode;
            if (event.key === 'Backspace') {
                event.cancelBubble = true;
                event.preventDefault();
                event.stopPropagation();
                event.returnValue = false;
                parentAnchor.parentNode.removeChild(parentAnchor);
                return false;
            } else {
                const text = event.key;
                const aText = (parentAnchor as any).innerText;
                if (text.length === 1) {

                    if (selection.anchorOffset === 0 || selection.anchorOffset === aText.length) {
                        const textElement = document.createTextNode(text);

                        if (selection.anchorOffset === 0) {
                            parentAnchor.parentNode.insertBefore(textElement, parentAnchor);

                        } else {
                            const anchorSibbling = parentAnchor.nextSibling;
                            if (anchorSibbling) {
                                parentAnchor.parentNode.insertBefore(textElement, anchorSibbling);

                            } else {
                                parentAnchor.parentNode.appendChild(textElement);
                            }

                            let index = 0;
                            parentAnchor.parentNode.childNodes.forEach(child => {
                                if (child === textElement) {
                                    return;
                                }
                                index++;
                            });

                            const range = document.createRange();
                            range.selectNodeContents(textElement);
                            range.setStart(textElement, 1);
                            selection.removeAllRanges();
                            range.collapse(true);
                            selection.addRange(range);
                        }
                    }

                    event.cancelBubble = true;
                    event.preventDefault();
                    event.stopPropagation();
                    event.returnValue = false;
                    return false;
                }
            }

        }
    }

    private scrollElementIntoView() {
        if (!this.selectedQuickNote) {
            return;
        }
        const itemToScroll = document.getElementById(this.selectedQuickNote.key);

        if (!itemToScroll) {
            return;
        }

        itemToScroll.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    private getWordStartIndex() {
        if (!this.intellisenseField) {
            return -1;
        }
        const selection = document.getSelection();
        const fieldValue: string = UtilitiesService.replaceAll((selection.anchorNode as any).nodeValue, String.fromCharCode(160), ' ');

        const selectionEnd = selection.anchorOffset;
        if (!fieldValue) {
            return -1;
        }
        const beginning = fieldValue.substring(0, selectionEnd);
        let lastSpace = beginning.lastIndexOf(' ', selectionEnd);
        let lastEnter = beginning.lastIndexOf('\n', selectionEnd);
        let lastNoBreakSpace = beginning.lastIndexOf(String.fromCharCode(160), selectionEnd);


        if (lastSpace === -1 && lastEnter === -1) {
            return 0;
        }

        if (lastSpace > -1) {
            lastSpace++;
        }

        if (lastNoBreakSpace > -1) {
            lastNoBreakSpace++;
        }
        if (lastEnter > -1) {
            lastEnter++;
        }

        return Math.max(lastEnter, lastSpace, lastNoBreakSpace);
    }

    private getWordEndIndex() {
        if (!this.intellisenseField) {
            return -1;
        }
        const selection = document.getSelection();
        const fieldValue: string = (selection.anchorNode as any).nodeValue;
        if (!fieldValue) {
            return -1;
        }
        const selectionEnd = selection.anchorOffset;
        let nextSpace = fieldValue.indexOf(' ', selectionEnd);
        let nextEnter = fieldValue.indexOf('\r', selectionEnd);

        if (nextSpace === -1) {
            nextSpace = selectionEnd;
        }
        if (nextEnter === -1) {
            nextEnter = selectionEnd;
        }

        return nextSpace < nextEnter ? nextSpace : nextEnter;
    }


    private timeoutInterval;
    respondToKeyEvent(event: KeyboardEvent) {
        if (!this.quickNotes) {
            return;
        }

        if (event.key === ' ' || event.key === '}') {
            this.intellisenseOpen = false;
        } else if ((event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Tab' || event.key === 'Enter') && this.intellisenseOpen) {
            return;
        } else if (event.key === 'Escape') {
            this.intellisenseOpen = false;
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
            this.findMode = null;
            return;
        } else if (event.key === 'Enter' || event.key === 'Tab') {

            return;
        }



        const selection = document.getSelection();

        let fieldValue: string = UtilitiesService.replaceAll(selection.anchorNode.nodeValue, String.fromCharCode(160), ' ');

        let wordStartIndex = this.getWordStartIndex();
        const wordEndIndex = this.getWordEndIndex();


        if (!fieldValue) {
            fieldValue = '';
        }

        let lastWord = fieldValue.substring(wordStartIndex, wordEndIndex).toLowerCase();

        if (lastWord.length > 2 && lastWord[0] !== '@' && lastWord[0] !== '#') {
            this.findMode = null;
            this.filteredQuickNotes = this.quickNotes
                .filter(i => (i.key.toLowerCase().indexOf(lastWord) > -1));

            if (this.filteredQuickNotes.length > 0) {
                this.intellisenseOpen = true;
            } else {
                this.intellisenseOpen = false;
            }
        } else if (lastWord[0] === '@' && this.mentionables && this.mentionables.length > 0) {
            this.findMode = null;
            const name = lastWord.substring(1, lastWord.length).toLowerCase();
            this.filteredQuickNotes = this.mentionables.filter(i => {
                return (i.name && i.name.toLowerCase().indexOf(name) > -1)
                    || (i.firstName && i.firstName.toLowerCase().indexOf(name) > -1)
                    || (i.lastName && i.lastName.toLowerCase().indexOf(name) > -1)
                    || (i.name && UtilitiesService.replaceAll(i.name, ' ', '').toLowerCase().indexOf(name) > -1);
            }).map(i => {
                const result: UserTextSnippet = new UserTextSnippet();
                result.note = i.name;
                result.entity = i as any;
                result.isMention = true;
                result.key = i.id;
                return result;
            });
            if (this.filteredQuickNotes.length > 0) {
                this.intellisenseOpen = true;
            } else {
                this.intellisenseOpen = false;
            }
        } else if (lastWord[0] === '#') {
            if (lastWord === '#') {
                this.filteredQuickNotes = [
                    { id: 'Policy', note: 'Find a subscription', key: 'Search', setFindMode: true, url: '/subscription/{{id}}' },
                    { id: 'WorkOrder', note: 'Find a job', key: 'Search', setFindMode: true, url: '/service/claim-detail/{{id}}' },
                    { id: 'Task', note: 'Find a task', key: 'Search', setFindMode: true, url: '/users/tasks/{{id}}' }
                ] as any[];
                this.intellisenseOpen = true;
            } else {
                let searchFor = lastWord.replace('#', '');
                let searchType = this.findMode;

                clearTimeout(this.timeoutInterval);

                if (searchFor) {
                    this.timeoutInterval = setTimeout(() => {

                        this.searchApi.searchForType(searchType, searchFor).then(results => {
                            this.filteredQuickNotes = results.map(i => {
                                const result: UserTextSnippet = new UserTextSnippet();
                                if (searchType === 'Policy') {
                                    result.note = `${i.name} (${i.status}): ${i.address1}`;
                                } else if (searchType === 'Task') {
                                    result.note = `${i.name} - ${i.additionalInfo}`;
                                } else if (searchType === 'WorkOrder') {
                                    result.note = `${i.additionalInfo} ${i.address1}`;
                                }
                                result.searchResult = i;
                                result.key = i.number.toString();
                                result.isSearchResult = true;
                                return result;
                            });
                            if (this.filteredQuickNotes.length > 0) {
                                this.intellisenseOpen = true;
                                this.setIntellisenseDimensions();
                            } else {
                                this.intellisenseOpen = false;
                            }
                        });
                    }, 750);
                }
            }

        } else {
            this.intellisenseOpen = false;
        }
        this.setIntellisenseDimensions();

    }

    private setIntellisenseDimensions() {
        if (this.intellisenseOpen) {
            const coordinates = UtilitiesService.getSelectionCoords(window);

            let left = (coordinates.left);
            let top = (coordinates.top + 20);

            let height = this.filteredQuickNotes.length * this.itemHeight;
            height += 10;

            if (height > this.intellisenseMaxHeight) {
                height = this.intellisenseMaxHeight;
            }

            if (left + this.intellisenseWidth > window.innerWidth) {
                left = window.innerWidth - this.intellisenseWidth;
            }

            if (top + height > window.innerHeight) {
                top = top - height - 20;
            }
            this.selectedQuickNote = this.filteredQuickNotes[0];
            this.intellisenseLeft = left + 'px';
            this.intellisenseTop = top + 'px';
            this.intellisenseHeight = height + 'px';
        }
    }

    get findModeDesc() {
        if (this.findMode === 'Policy') {
            return 'Subscription';
        }
        if (this.findMode === 'WorkOrder') {
            return 'Job';
        }

        return this.findMode;
    }

    setIntellisenseField(field: UserTextSnippet) {
        if (field.setFindMode) {
            this.findMode = field.id;
            this.findUrl = field.url;
            this.intellisenseOpen = false;
            return;
        }
        this.findMode = null;
        let selection = document.getSelection();
        const anchorNode = selection.anchorNode;
        let fieldValue: string = anchorNode.nodeValue;
        const wordStartIndex = this.getWordStartIndex();
        const wordEndIndex = this.getWordEndIndex();

        if (!field.isMention) {
            fieldValue = fieldValue.slice(0, wordStartIndex) + field.note + fieldValue.slice(wordEndIndex, fieldValue.length);
        } else {

            fieldValue = fieldValue.slice(0, wordStartIndex) + fieldValue.slice(wordEndIndex, fieldValue.length);
        }

        if (field.isMention) {
            const originalFieldValue = anchorNode.nodeValue;
            let firstPart = originalFieldValue.slice(0, wordStartIndex);
            let secondPart = originalFieldValue.slice(wordEndIndex, originalFieldValue.length);


            const parent = selection.anchorNode.parentNode;
            const nextSibbling = selection.anchorNode.nextSibling;

            parent.removeChild(selection.anchorNode);

            anchorNode.nodeValue = fieldValue;

            const firstText = document.createTextNode(firstPart);
            const secondText = document.createTextNode(secondPart);

            const anchor = document.createElement('a');
            anchor.innerText = '@' + field.entity.name;
            anchor.setAttribute('data-entity-id', field.entity.id);
            // anchor.setAttribute('href', '#');
            anchor.className = 'mentioned-href';


            let index = 0;
            if (!nextSibbling) {
                parent.appendChild(firstText);
                parent.appendChild(anchor);
                parent.appendChild(secondText);
                if (!secondPart) {
                    secondPart = ' ';
                    const span = document.createElement('span');
                    span.innerHTML = '&nbsp;';
                    parent.appendChild(span);
                }
                index++;
            } else {
                parent.insertBefore(firstText, nextSibbling);
                parent.insertBefore(anchor, nextSibbling);
                secondPart = ' ';
                const span = document.createElement('span');
                span.innerHTML = '&nbsp;';
                parent.insertBefore(span, nextSibbling);
                parent.insertBefore(secondText, nextSibbling);
            }

            parent.childNodes.forEach(item => {
                if (item === anchor) {
                    return;
                }
                index++;
            });

            selection = document.getSelection();
            const range = document.createRange();


            range.selectNodeContents(secondText);
            range.setStart(secondText, 0);

            selection.removeAllRanges();
            range.collapse(true);
            selection.addRange(range);
            this.intellisenseOpen = false;
            this.intellisenseField.dispatchEvent(new Event('input'));

            return;
        }

        if (field.isSearchResult) {
            const originalFieldValue = anchorNode.nodeValue;
            let firstPart = originalFieldValue.slice(0, wordStartIndex);
            let secondPart = originalFieldValue.slice(wordEndIndex, originalFieldValue.length);


            const parent = selection.anchorNode.parentNode;
            const nextSibbling = selection.anchorNode.nextSibling;

            parent.removeChild(selection.anchorNode);

            anchorNode.nodeValue = fieldValue;

            const firstText = document.createTextNode(firstPart);
            const secondText = document.createTextNode(secondPart);

            const anchor = document.createElement('a');

            anchor.innerText = '#' + field.searchResult.number;
            anchor.setAttribute('data-search-id', field.searchResult.id);
            anchor.setAttribute('data-search-type', field.searchResult.recordType);
            // anchor.setAttribute('href', '#');
            anchor.className = 'mentioned-href';
            const anotherUrl = this.findUrl.replace('{{id}}', field.searchResult.id);
            anchor.href = anotherUrl;

            let index = 0;
            if (!nextSibbling) {
                parent.appendChild(firstText);
                parent.appendChild(anchor);
                parent.appendChild(secondText);
                if (!secondPart) {
                    secondPart = ' ';
                    const span = document.createElement('span');
                    span.innerHTML = '&nbsp;';
                    parent.appendChild(span);
                }
                index++;
            } else {
                parent.insertBefore(firstText, nextSibbling);
                parent.insertBefore(anchor, nextSibbling);
                secondPart = ' ';
                const span = document.createElement('span');
                span.innerHTML = '&nbsp;';
                parent.insertBefore(span, nextSibbling);
                parent.insertBefore(secondText, nextSibbling);
            }

            parent.childNodes.forEach(item => {
                if (item === anchor) {
                    return;
                }
                index++;
            });

            selection = document.getSelection();
            const range = document.createRange();


            range.selectNodeContents(secondText);
            range.setStart(secondText, 0);

            selection.removeAllRanges();
            range.collapse(true);
            selection.addRange(range);
            this.intellisenseOpen = false;
            this.intellisenseField.dispatchEvent(new Event('input'));

            return;
        }

        if (fieldValue.indexOf('\n') > -1) {

            const parent = selection.anchorNode.parentNode;
            let anchorNodeIndex = 0;
            parent.childNodes.forEach(item => {
                if (item === selection.anchorNode) {
                    return;
                }
                anchorNodeIndex++;
            });

            const parts = fieldValue.split('\n');

            let lastTextNode = null;
            let lastLength = 0;
            selection.anchorNode.nodeValue = '';
            let anchorSibbling = selection.anchorNode.nextSibling;
            const elementsToInsert = [];
            for (const part of parts) {
                const textNode = document.createTextNode(part);
                if (part) {
                    lastLength = part.length;
                } else {
                    lastLength = 0;
                }
                const br = document.createElement('br');

                elementsToInsert.push(textNode);
                elementsToInsert.push(br);

                lastTextNode = textNode;
            }

            for (const elementToInsert of elementsToInsert) {
                if (anchorSibbling) {
                    // parent.insertBefore(textNode, anchorSibbling);
                    // parent.insertBefore(br, anchorSibbling);
                    parent.insertBefore(elementToInsert, anchorSibbling);
                } else {
                    // parent.appendChild(textNode);
                    // parent.appendChild(br);
                    parent.appendChild(elementToInsert);
                }
            }

            if (lastTextNode) {
                const range = document.createRange();
                range.selectNodeContents(lastTextNode);
                range.setStart(lastTextNode, lastLength);
                selection.removeAllRanges();
                range.collapse(true);
                selection.addRange(range);
            }
        } else {
            anchorNode.nodeValue = fieldValue;
            const range = document.createRange();
            range.selectNodeContents(anchorNode);
            this.intellisenseField.selectionEnd = wordStartIndex + (field.note).length;
            this.intellisenseField.selectionStart = wordStartIndex + (field.note).length;
            selection.removeAllRanges();
            range.setStart(anchorNode, wordStartIndex + (field.note).length);
            range.collapse(true);
            selection.addRange(range);

        }
        this.intellisenseOpen = false;
        this.intellisenseField.dispatchEvent(new Event('input'));
    }

    inputBlurred(event) {
        if (!event.relatedTarget || !event.relatedTarget.classList || !event.relatedTarget.classList.contains('intellisense-button')) {
            this.intellisenseOpen = false;
        }
    }


}
