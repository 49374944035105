import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorDocumentType {
    @keyColumn() id: string;
    @column() name: string;
    @column() goodForDays: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() required: boolean;

    selected: boolean;
}