<a (click)="showEmail()" title="{{emailAddress}}" style="display: inline-block">
  <div aflex>
    <div nogrow>
      <i class="material-icons" *ngIf="!hideIcon">
        email
      </i>
    </div>
    <div class="link-text">
      <span *ngIf="display">{{display}}</span>
      <span *ngIf="!display">{{emailAddress}}</span>
    </div>
  </div>
</a>
