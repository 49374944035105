import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

type float = number;

@dataSource("ActiveZipCode")
export class ZipCode {
    @keyColumn() zip: string;
    @column() type: string;
    @column() decommissioned: boolean;
    @column() primaryCity: string;
    @column() acceptableCities: string;
    @column() unacceptableCities: string;
    @column() state: string;
    @column() stateFullName: string;
    @column() county: string;
    @column() timezone: string;
    @column() areaCodes: string;
    @column() worldRegion: string;
    @column() country: string;
    @column() approximateLatitude: float;
    @column() approximateLongitude: float;
    @column() populationCenterLatitude: float;
    @column() populationCenterLongitude: float;
    @column() polygonOffsetLatitude: float;
    @column() polygonOffsetLongitude: float;
    @column() internalPpointLatitude: float;
    @column() internalPointLongitude: float;
    @column() latitudeMin: float;
    @column() latitudeMax: float;
    @column() longitudeMin: float;
    @column() longitudeMax: float;
    @column() areaLand: bigint;
    @column() areaWater: bigint;
    @column() housingCount: number;
    @column() irsEstimatedHouseholds: number;
    @column() acsEstimatedHouseholds: number;
    @column() populationCount: number;
    @column() irsEstimatedPopulation: number;
    @column() acsEstimatedPopulation: number;
    @column() white: number;
    @column() blackOrAfricanAmerican: number;
    @column() americanIndianOrAlaskanNative: number;
    @column() asian: number;
    @column() nativeHawaiianAndOtherPacificIslander: number;
    @column() otherRace: number;
    @column() twoOrMoreRaces: number;
    @column() totalMalePopulation: number;
    @column() totalFemalePopulation: number;
    @column() popUnder10: number;
    @column() pop10To19: number;
    @column() pop20To29: number;
    @column() pop30To39: number;
    @column() pop40To49: number;
    @column() pop50To59: number;
    @column() pop60To69: number;
    @column() pop70To79: number;
    @column() pop80Plus: number;
    @column() percentPopulationInPoverty: float;
    @column() medianEarningsPastYear: number;
    @column() medianHouseholdIncome: number;
    @column() medianGrossRent: number;
    @column() medianHomeValue: number;
    @column() percentHighSchoolGraduate: float;
    @column() percentBachelorsDegree: float;
    @column() percentGraduateDegree: float;
    @column() coordinates: string;
    coords: Array<Array<Array<number>>>;
}