import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TaskWorkDetail {
    @keyColumn() id: string;
    @column() taskId: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() hoursWorked: number;
    @column() description: string;
    @column() deletedDate: Date;
}