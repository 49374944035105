
<div *ngIf="entitySummary && !collapsed">

    <div>
        <mat-form-field appearance="outline" style="max-width: 300px;">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="entitySummary.name">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline" style="max-width: 300px;">
            <mat-label>Email</mat-label>
            <input matInput type="email" [(ngModel)]="entitySummary.email">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline" style="max-width: 200px;">
            <mat-label>Phone</mat-label>
            <input matInput type="tel" mask="(000) 000-0000" [(ngModel)]="entitySummary.homeNumber">
        </mat-form-field>
    </div>
    <div class="center">
        <button mat-button mat-raised-button class="small-primary" color="primary" (click)="save()">Looks Good</button>
    </div>
</div>

<div *ngIf="entitySummary && collapsed">
    <div class="data-label">Name</div>
    <div>{{entitySummary.name}}</div>
    <div class="data-label" topmargin20>Email</div>
    <div>{{entitySummary.email}}</div>

    <div class="data-label" topmargin20>Phone</div>
    <div>{{entitySummary.homeNumber | formatPhoneNumber}}</div>
</div>