<div aflex>
  <div nogrow>
    <i style="font-size: 46px" class="material-icons">warning</i>
  </div>
  <div>

    <h1>We have a problem.</h1>
    <p>Sorry, it looks like that request didn't go through</p>
  </div>
  <div nogrow *ngIf="showDetailButton">
      <button mat-button (click)="showErrorDetail()">
          View
      </button>
  </div>
</div>
