import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderStatusChipSelectionComponent } from './work-order-status-chip-selection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [WorkOrderStatusChipSelectionComponent],
  exports: [WorkOrderStatusChipSelectionComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatIconModule,
  ]
})
export class WorkOrderStatusChipSelectionModule { }
