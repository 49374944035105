import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MonitoredEmail {
    @keyColumn() id: string;
    @column() serverAddress: string;
    @column() userName: string;
    @column() password: string;
    @column() port: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() name: string;
    @column() deletedDate?: Date;

    plainTextServer: string;
    plainTextUserName: string;
}