import { Component, Input, NgZone, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, OnInit } from '@angular/core';
import { TimeZoneModel } from '@cogent/shared/models/other/time-zone.model';
import { DispatchApiService } from '@cogent/client/shared/services/api/dispatch-api.service';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { HttpClient } from '@angular/common/http';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { TypeAheadBoxComponent } from '@cogent/client/shared/components/misc/type-ahead-box/type-ahead-box.component';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePickerPopupComponent } from '@cogent/client/shared/components/misc/date-picker-popup/date-picker-popup.component';
import { Address, Entity, Language } from '@upkeeplabs/models/cogent';
declare var google: any;

export class Cancellable {
    cancel: boolean;
}

@Component({
    templateUrl: './basic-entity-entry.component.html',
    styleUrls: ['./basic-entity-entry.component.css'],
    selector: 'app-basic-entity-entry',
})
export class BasicEntityEntryComponent implements OnChanges, OnInit {
    @Input() entity: Entity;
    @Output() entityChange: EventEmitter<Entity>;
    @Input() nameLabel = 'Name';
    @Input() defaultAddress: Address;
    @Input() defaultAddressLabel = 'Default';
    @Input() showPhoneNumberEntry = false;
    @Input() nameIsSearchBox = true;
    @Input() showFirstAndLastName = false;
    @Input() recordSubTypeSearch: string = null;
    @Input() hideInactiveBox = false;
    @Input() hidePreferreds = false;
    @Input() hidePaperless = false;
    @Input() teams: Entity[];
    @Input() teamAgents: Entity[];
    @Input() hideTimezoneEntry = false;
    @Input() hideContactMethodsEntry = false;
    @Input() showAccountInputFields = false;
    @Input() hideWebsiteEntry = false;
    @Input() alternateEmailLabel = 'Alternate Email';
    @Input() showNameOnCheck = false;
    @Input() hideAddressEntry = false;


    @Input() canSave: boolean;
    @Output() canSaveChange: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('typeAheadBox') typeAheadBox: TypeAheadBoxComponent;
    @Input() showAlternateNames: boolean;
    @Input() showLicenseInput: boolean;
    @Input() addressRequired: boolean;
    @Input() outline = true;
    @Input() showRequiredMobileNumber: boolean;
    @Input() showRequiredName: boolean;
    @Input() showRequiredEmail: boolean;
    @Input() showRequiredOfficeNumber: boolean;
    @Input() canEditName = true;
    @Input() showSinglePhoneNumberEntry = false;
    @Input() homePhoneRequired = false;
    @Input() showAlternateEmailEntry = false;
    @Output() beforeEntitySelected: EventEmitter<Cancellable> = new EventEmitter();
    @Input() basicEntryOnly = false;
    @Input() showAddressToggle = false;
    @Input() showBirthday = false;

    @Input() overrideAddress = true;
    @Output() fieldChanged: EventEmitter<string> = new EventEmitter();

    addressComplete = false;

    @Input() showDispatchButton = false;

    customItemTemplate: any;
    languages: Language[];
    saving = false;

    searchSelectedEntity: any = null;

    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    timezones: TimeZoneModel[];
    autocomplete;

    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    get showOptIn() {
        return (this.entity.type === "Agent");
    }

    get showAddress() {
        if (this.hideAddressEntry) {
            return false;
        }

        if (!this.showAddressToggle) {
            return true;
        }
        return this.overrideAddress;
    }

    emitFieldChanged(field: string) {
        this.fieldChanged.emit(field);
    }

    get showOnlyNameAndEmail() {
        return this.entity && (this.entity.type === "Department" || this.entity.type === "Team" || this.basicEntryOnly);
    }
    constructor(
        private zone: NgZone,
        private entityApi: EntityApiService,
        private dispatchApi: DispatchApiService,
        private missionService: MissionService,
        private dialogService: DialogsService,
        private dataAccess: DataApiService,
        private matDialog: MatDialog,
        private http: HttpClient,
    ) {
        if (!this.nameLabel) {
            this.nameLabel = 'Name';
        }

        this.entityChange = new EventEmitter<Entity>();

    }

    ngOnInit() {
        this.entityApi.getLanguages().then(languages => this.languages = languages);

        if (!this.hideTimezoneEntry) {
            this.dataAccess.getTimeZones().then(timeZones => {
                this.timezones = timeZones;
            });
        }
    }

    useDefaultAddress() {
        if (!this.defaultAddress) {
            return;
        }

        this.entity.address.address1 = this.defaultAddress.address1;
        this.entity.address.address2 = this.defaultAddress.address2;
        this.entity.address.city = this.defaultAddress.city;
        this.entity.address.state = this.defaultAddress.state;
        this.entity.address.postalCode = this.defaultAddress.postalCode;
    }

    addressCompleteChange(complete: boolean) {
        this.addressComplete = complete;
        this.updateCanSave();
    }

    selectedEntityChanged(selectedEntity) {
        const cancel = new Cancellable();
        this.beforeEntitySelected.emit(cancel);
        if (cancel.cancel) {
            return;
        }

        this.searchSelectedEntity = selectedEntity;

        if (this.searchSelectedEntity) {
            this.entityApi.getFullEntity(this.searchSelectedEntity.id).then(entity => {
                this.entity = entity;
                this.entityChange.emit(entity);
                this.searchSelectedEntity = null;
            });

        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.entity && changes.entity.currentValue) {
            if (!this.entity.timeZoneId) {
                this.entityApi.getCompanyTimeZone().then(timeZone => this.entity.timeZoneId = timeZone.id);
            }
        }
        this.updateCanSave();
    }

    get entitySearchUrl(): string {
        if (this.recordSubTypeSearch) {
            return 'EntitySearch?recordSubType=' + this.recordSubTypeSearch;
        }

        return 'EntitySearch';
    }

    updateCanSave() {
        this.canSave = this._canSave;
        this.canSaveChange.emit(this.canSave);
        setTimeout(() => {
            this.canSave = this._canSave;
            this.canSaveChange.emit(this.canSave);
        });
    }

    get _canSave(): boolean {
        if (!this.entity) {
            return false;
        }
        if (this.addressRequired) {
            if (!this.entity.address.address1 ||
                !this.entity.address.city ||
                !this.entity.address.state ||
                !this.entity.address.postalCode) {
                return false;
            }
        }

        if (this.homePhoneRequired && !UtilitiesService.validatePhoneNumber(this.entity.homeNumber?.number)) {
            return false;
        }

        if (this.entity.type === 'Branch' || this.entity.type === 'Title_Company' || this.entity.type === 'Real_Estate_Company') {
            return (this.entity.address
                && this.entity.address.address1
                && this.entity.address.city
                && this.entity.address.state
                && this.entity.address.postalCode) as any;
        }

        return this.entity.name != null
            && this.entity.name !== '';
    }

    checkZipCode(value) {
        // TODO: re-implement this using the new HTTP Module.
        if (value && value.length === 5) {
            const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + value + '&sensor=true';
            this.http.get(url)
                .subscribe(results => {
                    const result = results[0];

                    if (result) {
                        for (let i = 0; i < result.address_components.length; i++) {
                            const component = result.address_components[i];
                            if (component.types.indexOf('locality') > -1) {
                                this.entity.address.city = component.long_name;
                            }
                            if (component.types.indexOf('administrative_area_level_1') > -1) {
                                this.entity.address.state = component.short_name;
                            }
                        }
                    }
                });
        }
    }

    geolocate() {
        this.initAutocomplete();
        // bias toward ut locations
        const geolocation = {
            lat: 40.2980763,
            lng: -111.7080911
        };
        const circle = new google.maps.Circle({
            center: geolocation,
            radius: 1000,
        });
        this.autocomplete.setBounds(circle.getBounds());
    }

    initAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),
            { types: ['geocode'] });

        this.autocomplete.addListener('place_changed', () => this.fillInAddress());
    }

    reset() {
        this.typeAheadBox.reset();
    }

    fillInAddress() {
        const place = this.autocomplete.getPlace();

        this.zone.run(() => {
            let street = '';
            let route = '';
            for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0];
                if (addressType === 'street_number') {
                    street = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'route') {
                    route = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'locality') {
                    this.entity.address.city = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'administrative_area_level_1') {
                    this.entity.address.state = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'country') {
                    this.entity.address.country = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'postal_code') {
                    this.entity.address.postalCode = place.address_components[i][this.componentForm[addressType]];
                }
            }

            this.entity.address.address1 = street + ' ' + route;
        });
    }

    async dispatchActiveClick() {
        if (!this.entity.contactViaDispatch) {
            this.entity.dispatchActivatedDate = null;
            return;
        }
        const data = {
            title: 'Activation Date'
        };
        const ref = this.matDialog.open(DatePickerPopupComponent, { data });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.entity.dispatchActivatedDate = results;
                this.entityApi.saveEntity2(this.entity);
                this.missionService.showSuccessToast(`Activation date saved`);
            }
        });
    }

    async undoActivation() {
        this.dialogService.confirm('Confirm', `Undo Activation?`).subscribe(async result => {
            if (result) {
                this.entity.dispatchActivatedDate = null;
                this.entityApi.saveEntity2(this.entity);
                this.missionService.showSuccessToast(`${this.entity.name} deactivated`);
            }
        });
    }

    async initializeInDispatch() {
        if (!this.entity.address.address1) {
            this.dialogService.alert('Field Required', 'Address required for Dispatch');
            return;
        }
        if (!this.entity.officeNumber.number) {
            this.dialogService.alert('Field Required', 'Office number required for Dispatch');
            return;
        }
        this.dialogService.confirm('Confirm', `Initialize ${this.entity.name} in Dispatch?`).subscribe(async result => {
            if (result) {
                await this.dispatchApi.intitializeInDispatch(this.entity);

                this.entity.dispatchInitializedDate = new Date();
                this.entity.contactViaDispatch = true;

                this.showDispatchButton = true;
                this.missionService.showSuccessToast(`${this.entity.name} initialized!`);

            }
        });
    }
}
