
<div aflex>
    <h2 class="sub-heading">Add Part</h2>
    <div nogrow>
        <button mat-button [mat-dialog-close]>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div class="center">

    <img style="max-height: 200px;" [src]="data.imageUrls[0]" *ngIf="data.imageUrls && data.imageUrls.length > 0">

    <img style="max-height: 200px" src="../../../assets/images/no-image.png" *ngIf="!data.imageUrls || data.imageUrls.length === 0">
    <h1>{{data.productNumber}}</h1>
    <p>{{data.description}}</p>

</div>

<div aflex>
    <div></div>
    <div nogrow>
        <button mat-button mat-raised-button color="primary" (click)="save()">Add Part</button>
    </div>
</div>
