import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { PlanPricingAdjustmentItem } from "./plan-pricing-adjustment-item.model";

@dataSource()
export class PlanPricingAdjustment {
    @keyColumn() id: string;
    @column() name: string;
    @column() deletedDate?: Date;
    @column() adjustmentType: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() allowAmountOverride: boolean;
    @column() renewalAdjustmentId: string;
    @column() appliesToQuadplex: boolean;
    @column() appliesToTriplex: boolean;
    @column() appliesToDuplex: boolean;
    @column() appliesToSingleFamily: boolean;
    @column() appliesToCondo: boolean;

    // client side only
    items: PlanPricingAdjustmentItem[];
    deletedItems:PlanPricingAdjustmentItem[];


    constructor(id: string = null, name: string = null, adjustmentType: string = null) {
        this.id = id;
        this.name = name;
        this.adjustmentType = adjustmentType;
    }
}