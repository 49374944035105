import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicEntityEntryComponent } from './basic-entity-entry.component';
import { TypeAheadBoxModule } from '@cogent/client/shared/components/misc/type-ahead-box/type-ahead-box.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';


@NgModule({
  declarations: [BasicEntityEntryComponent],
  exports: [BasicEntityEntryComponent],
  imports: [
    CommonModule,
    TypeAheadBoxModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    AddressEditorComponent,
    MatSelectModule,
    MatCheckboxModule,
    NgxMaskDirective,
    MatDialogModule,
    DatePickerWrapperComponent,
  ],
  providers:[
    provideNgxMask(),
  ]
})
export class BasicEntityEntryModule { }
