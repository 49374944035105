import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceDeclinedPayment {
    @keyColumn() id: string;
    @column() maintenanceServiceInvoiceIds: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() message: string;
    @column() amount: number;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() resolvedDate?: Date;
    @column() brand: string;
    @column() last4: string;
}
