export class SmsComposeArgs {
    to: string;
    message: string;
    taskId: string;
    workOrderId: string;
    policyId: string;
    entityId: string;
    overrideSendPreferences: boolean;
    sendToEntityId: string;
    from?: string;
    saveNote?: boolean;
}