import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceContractorScores {
    @column() reportDate: Date;
    @column() contractorId: string;
    //@column() maintenanceServiceName: string;
    @column() score: number;
    @column() possibleScore: number;
    @column() averageRating: number;

    get reportMonth() {
        return this.reportDate.getMonth() + 1;
    }

    get reportYear() {
        return this.reportDate.getFullYear();
    }
}