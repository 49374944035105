<div class="container" topmargin20>
    <div flex>
        <div>
            <h1 class="page-title">
                <span class="big">Billing</span><br><span class="little">Information</span>
            </h1>
        </div>
        <div nogrow>
            <a routerLink="/tax-report" mat-button style="display: inline-block;margin-right: 30px;">
                <mat-icon>payments</mat-icon>
                Billing Report</a>
            <button mat-button mat-raised-button mobile-block large style="margin-right: 20px"
                (click)="showIndividualBilling = !showIndividualBilling">
                <mat-icon>add</mat-icon>
                Create Bill
            </button>
            <div id="individual-bill-backdrop" *ngIf="showIndividualBilling" (click)="showIndividualBilling = false">
            </div>
            <div *ngIf="showIndividualBilling" id="bill-individual-container">
                <app-bill-individual-work-order (contractorInvoiceSaved)="updateBillingInfo()">
                </app-bill-individual-work-order>
            </div>

            <mat-menu #appMenu="matMenu">
                <a mat-menu-item color="primary" routerLink="/billing/csv-import">
                    <mat-icon>table_rows</mat-icon>
                    Import From
                    CSV
                </a>
                <a mat-menu-item color="primary" routerLink="/billing/complete-import">
                    <mat-icon>done_all</mat-icon>
                    Import Completed
                </a>
            </mat-menu>

            <button mat-button mat-raised-button mobile-block color="primary" large [matMenuTriggerFor]="appMenu">
                Batch Import <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

        </div>
    </div>

    <div flex>
        <div nogrow class="left-side" topmargin30>
            <app-contractor-statements [contractorId]="contractorId"></app-contractor-statements>
        </div>
        <div topmargin30>
            <div flex>

                <div flex1 topmargin20>

                    <mat-card>
                        <div style="position: relative">
                            <mat-card-header>
                                <mat-card-title>
                                    Un-Approved Invoices
                                </mat-card-title>
                            </mat-card-header>
                        </div>
                        <div class="data-label">Unapproved</div>
                        <h1 class="big-data">{{unapprovedSum | currency}}</h1>

                        {{unapprovedCount}} transactions
                        <div aflex>
                            <div></div>
                            <div nogrow>
                                <button mat-button (click)="showUnapprovedTransactions()">
                                    More <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div flex1 topmargin20>

                    <mat-card>
                        <div style="position: relative">
                            <mat-card-header>
                                <mat-card-title>
                                    Un-Paid Invoices
                                </mat-card-title>
                            </mat-card-header>
                        </div>
                        <div class="data-label">Unpaid</div>
                        <h1 class="big-data">{{unpaidSum | currency}}</h1>

                        {{unpaidCount}} transactions
                        <div aflex>
                            <div></div>
                            <div nogrow>
                                <button mat-button (click)="showUnpaidTransactions()">
                                    More <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
            <mat-card topmargin30>
                <div style="position: relative">
                    <mat-card-header>
                        <mat-card-title>
                            <i class="material-icons" mat-card-avatar>bar_chart</i> Payments By Month
                        </mat-card-title>
                    </mat-card-header>
                </div>
                <div>
                    <canvas style="height:  300px; max-width: 100%" topmargin30 [id]="paymentsByMonthId"></canvas>
                </div>
            </mat-card>


        </div>
    </div>

</div>

<div id="object-detail-panel" *ngIf="showDetail">
    <button mat-button class="close-detail-button agenda" style="position: absolute;" (click)="showDetail = false">
        <mat-icon>close</mat-icon>
    </button>

    <div id="object-detail-panel-inner">

        <div *ngIf="invoices && invoices.length === 0">
            <h2 class="sub-heading" topmargin40>No Invoices Here</h2>
        </div>
        <div class="detail-item" *ngFor="let invoice of invoices" aflex>
            <div nogrow>
                <mat-icon>wysiwyg</mat-icon>
            </div>
            <div>
                <div aflex>
                    <div>
                        <h2><a routerLink="/work-order-detail/{{invoice.workOrderId}}">{{invoice.workOrderNumber}}</a>
                        </h2>
                    </div>
                    <div nogrow>

                        <button mat-button (click)="deleteInvoice(invoice)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>

                <div>{{invoice.amount | currency}}</div>
                <div>{{invoice.propertyAddress}}</div>


            </div>

        </div>
    </div>
</div>