export class ClaimCostSummary {
    id: string;
    claimId: string;
    contractorId: string;
    tradeId: string;
    areaId: string;
    accDate: Date;
    accMonth: Date;
    claimDate: Date;
    jobNumber: number;
    customer: string;
    address1: string;
    totalCashOuts: number;
    totalContractorInvoices: number;
    totalPurchaseOrders: number;
    totalCost: number;
}
export class ContractorReportItem {
    month: Date;
    areaId: string;
    area: string;
    tradeId: string;
    trade: string;
    contractorId: string;
    contractor: string;
    offers: number;
    commitment: number;
    commitmentPercent: number;
    accepted: number;
    recalls: number;
    recallTarget: number;
    recallPercent: number;
    acc: number;
    accTarget: number;
    stars: number;
    starTarget: number;
    firstCalls: number;
    firstCallCompletes: number;
    firstCallCompleteTarget: number;
    firstCallCompletePercent: number;

    showAccDetail = false;
    accDetail: ClaimCostSummary[]
}