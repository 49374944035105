import { Component, Input, NgZone, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Address } from '@upkeeplabs/models/cogent';
import { AddressApiService } from '@cogent/client/shared/services/api/address-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
declare var google: any;

@Component({
    templateUrl: './address-editor.component.html',
    styleUrls: ['./address-editor.component.css'],
    selector: 'app-address-editor',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, FormsModule, MatOptionModule, MatSlideToggleModule, MaterialSharedModule]
})
export class AddressEditorComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {

    id = UtilitiesService.newid();
    @Input() address: Address;
    @Output() addressChange: EventEmitter<Address> = new EventEmitter();
    @Output() propertySelected: EventEmitter<Address> = new EventEmitter<Address>();
    @Input() showPropertyFields: boolean;
    @Input() disabled: boolean;
    @Input() addressComplete: boolean;
    @Output() addressCompleteChange: EventEmitter<boolean> = new EventEmitter();

    @Input() addressIsRequired: boolean;
    @Input() useOutlineFormField: boolean = true;
    @Input() condenseCityStateZip: boolean = false;
    @Input() initializeAddress = false;
    @Input() loadPropertyMeta = false;
    @Input() showBedroomBathroom = false;

    interval;
    autocomplete;
    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    constructor(
        private zone: NgZone,
        private addressApi: AddressApiService) {

    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        let autoCompleteElement = document.getElementById(this.id);
        this.interval = setInterval(() => {
            if(!autoCompleteElement){
                autoCompleteElement = document.getElementById(this.id);
            }
            if (autoCompleteElement) {
                autoCompleteElement.setAttribute('autocomplete', 'do-not-auto-complete');
            }
        }, 1300);
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    emitCompleteEvent() {
        this.addressCompleteChange.emit((this.address.address1 && this.address.city && this.address.state && this.address.postalCode) as any);
    }

    get appearance() {
        if (this.useOutlineFormField) {
            return 'outline';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.initializeAddress?.currentValue) {
            this.address = new Address();
            this.address.id = UtilitiesService.newid();
            this.addressChange.emit(this.address);
        }
    }

    checkZipCode(value) {
        if (value && value.length === 5) {
            this.addressApi.doPostalCodeLookup(value).then(address => {
                this.address.city = address.city;
                this.address.state = address.state;
            });
        }
    }

    geolocate() {
        if (this.address && this.address.address1) {
            return;
        }

        this.initAutocomplete();
        // bias toward ut locations
        const geolocation = {
            lat: 40.2980763,
            lng: -111.7080911
        };
        const circle = new google.maps.Circle({
            center: geolocation,
            radius: 1000,
        });
        this.autocomplete.setBounds(circle.getBounds());
    }

    initAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(this.id),
            { types: ['geocode'] });

        this.autocomplete.addListener('place_changed', () => this.fillInAddress());
    }

    fillInAddress() {
        const place = this.autocomplete.getPlace();
        if (!place) {
            return;
        }
        this.zone.run(() => {
            let street = '';
            let route = '';
            let hasStreetNumber = false;
            for (let i = 0; i < place.address_components.length; i++) {
                const addressType = place.address_components[i].types[0];
                if (addressType === 'street_number') {
                    street = place.address_components[i][this.componentForm[addressType]];
                    hasStreetNumber = true;
                } else if (addressType === 'route') {
                    route = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'locality') {
                    this.address.city = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'administrative_area_level_1') {
                    this.address.state = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'country') {
                    this.address.country = place.address_components[i][this.componentForm[addressType]];
                } else if (addressType === 'postal_code') {
                    this.address.postalCode = place.address_components[i][this.componentForm[addressType]];
                }
            }

            /* This fixes a bug where sometimes google doesn't return the street number portion of an address even though it shows it in the auto complete results. */
            if (!hasStreetNumber) {
                const parts = this.address.address1.split(" ");
                if (parts && parts.length > 0 && !isNaN(parts[0] as any)) {
                    street = parts[0];
                }
            }

            // Clearing and resetting the address fixes a small rendering bug with the google address suggestion
            this.address.address1 = '';
            setTimeout(() => {
                this.address.address1 = street + ' ' + route;
                this.propertySelected.emit(this.address);
                this.addressCompleteChange.emit(this.address.isFilledOut);

                if (this.loadPropertyMeta) {
                    this.addressApi.getPropertyMeta(this.address.address1, this.address.postalCode).then(item => {
                        this.address.squareFeet = item.sqft;
                        this.address.dwellingType = item.useCode;
                        this.address.latitude = item.lat;
                        this.address.longitude = item.lon;
                        this.address.yearBuilt = item.yearBuilt;
                    });
                }

            });
        });
    }
}
