<mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
    <mat-tab label="First">
        <div aflex>
            <div>

                <h2 class="sub-heading">Complete {{data.maintenanceServiceName}}</h2>
            </div>
            <div nogrow>
                <button mat-button mat-dialog-close>
                    <mat-icon>close</mat-icon>
                </button>
            </div>






        </div>
        <div>



            {{data.propertyAddress1}}<br>
            {{data.propertyCity}}, {{data.propertyState}} {{data.propertyPostalCode}}<br>
            {{data.scheduledDate | date: 'shortDate'}}

        </div>

        <ng-container *ngIf="data.completedDate && !editMode">
            <div class="center" topmargin40>
                <app-check-circle-animated height="100px"></app-check-circle-animated>
                <br>
                <h1>Appointment Completed</h1>
                <p>{{data.completedDate | date: 'shortDate'}}</p>

                <button mat-button (click)="edit()">
                    <mat-icon>edit</mat-icon> Change
                </button>
            </div>

        </ng-container>

        <ng-container *ngIf="!data.completedDate || editMode">
            <div topmargin40>
                <div style="max-width: 200px">
                    <app-date-picker [(date)]="completionDate" placeholder="Completion Date"></app-date-picker>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Notes</mat-label>
                    <textarea matInput rows="3" [(ngModel)]="comments"></textarea>
                </mat-form-field>
            </div>

            <div>
                <h2 class="sub-heading">Photo(s)</h2>

                <!-- <button mat-button class="add-btn">
                        <mat-icon>add</mat-icon><br>
                        Add Photo
                    </button> -->
                <app-authorization-photos label="" [maxHeight]="800" imagePrefix="service" [(attachments)]="photos">
                </app-authorization-photos>
            </div>

            <div aflex topmargin40>
                <div></div>
                <div nogrow>
                    <button mat-button mat-raised-button color="primary" (click)="doComplete()" [disabled]="saving ">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Complete Appointment
                    </button>
                </div>
            </div>
        </ng-container>


    </mat-tab>
    <mat-tab label="Second">
        <div class="center">
            <app-check-circle-animated height="100px"></app-check-circle-animated>
            <br>
            <h1>Appointment Completed</h1>
        </div>
    </mat-tab>
</mat-tab-group>