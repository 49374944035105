import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class HistoryItem {
    @keyColumn() id: string;
    @column() type: string;
    @column() url: string;
    @column() descriptionLine1: string;
    @column() descriptionLine2: string;
    @column() descriptionLine3: string;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;
    @column() lastModifiedBy: string;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() descriptionLine4: string;
}