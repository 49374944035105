import { Component, OnInit } from "@angular/core";
import { AuthService } from '@cogent/client/auth';
//import { truncate } from "fs";

@Component({
    template: '',
    selector: 'app-callback-component',
    standalone: true
})
export class AuthCallbackComponent implements OnInit {
    constructor(private authService: AuthService) { }
    ngOnInit() {
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get("state");
        const code = urlParams.get("code");
        this.authService.processCode(state, code);
    }
}

@Component({
    template: '',
    selector: 'app-impersonate-user',
    standalone: true
})
export class ImpersonateComponent implements OnInit {
    constructor(private authService: AuthService) { }
    ngOnInit() {
        const urlParams = new URLSearchParams(window.location.search);
        const auth = urlParams.get("auth");
        const entityId = urlParams.get("entityId");
        const redirectTo = urlParams.get("redirectTo");

        if (auth && entityId) {
            window.localStorage.removeItem('auth');
            if (redirectTo) { window.localStorage.setItem("redirectTo", redirectTo); }
            this.authService.logIn(null, auth, entityId);
        }
    }
}