import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PurchasingApiService } from '@cogent/client/shared/services/api/purchasing-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { AppliancePartDetail, Brand, PartToOrder, WorkOrderLineSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { BrandApiService } from '@cogent/client/shared/services/api/brand-api.service';
import { OrderPartsAddIndividualComponent } from '../order-parts-add-individual/order-parts-add-individual.component';

export class AppliancePartCategory {
    diagramUrl: string;
    name: string;
    parts: ApplianceApiPart[];
    urlDiagram: any;
}
export class ApplianceApiPart {
    available: boolean;
    description: string;
    imageUrl: string;
    inStock: boolean;
    manufacturer: string;
    manufacturerCode: string;
    name: string;
    productNumber: string;
    price: number;
    quantity: number;
    retailPrice: number;
    selected: boolean;
}
export class ApplianceApiModel {
    applianceType: string;
    manufacturer: string;
    modelNumber: string;
    partCategories: AppliancePartCategory[];
    diagrams: any;
    urlDiagram: any;
    allParts: any[];
}

@Component({
    selector: 'app-order-parts',
    templateUrl: './order-parts.component.html',
    styleUrls: ['./order-parts.component.css']
})
export class OrderPartsComponent implements OnInit {

    foundPart: any;
    selectedIndex: number = 0;
    selectedPart: any;
    loadingPartDetail = false;
    selectedPartCategory: AppliancePartCategory;
    selectedModel: ApplianceApiModel;
    viewDiagram: boolean;
    showMore = false;
    diagramDetails: any[];
    noMatch = false;
    firstNoMatch = false;
    applianceParts: PartToOrder[] = [];
    saving = false;

    partDetail: AppliancePartDetail[];
    workOrderLine: WorkOrderLineSummary;
    imageSelectedIndex: number;
    diagramSizeMode: string = 'actual';
    brands: Brand[];
    useEncompass = true;
    hotspot: any;
    showAllParts = false;


    quantities = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10
    ];
    newPart = new PartToOrder();
    manufacturers = [
        'Whirlpool',
        'GE',
        'Frigidaire',
        'Other'
    ];

    constructor(private purchasingApi: PurchasingApiService,
        private activatedRoute: ActivatedRoute,
        private serviceApi: ServiceApiService,
        private brandApi: BrandApiService,
        private dialog: MatDialog,
        private missionService: MissionService,) { }

    ngOnInit(): void {

        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.serviceApi.getWorkOrderLineSummary2(params.id).then(line => {
                    this.workOrderLine = line;
                });
                this.purchasingApi.getPartsToOrderForLine(params.id).then(items => this.applianceParts = items);
            }
        });
    }

    showCategory(partCategory: any) {
        this.loadingPartDetail = true;

        this.diagramDetails = null;

        this.selectedPartCategory = partCategory;

        if (this.useEncompass) {
            this.purchasingApi.getModelDetailExplodedView(partCategory.mfgCode, partCategory.explodedViewId, partCategory.diagramId, partCategory.modelId, '').then(diagramDetails => {
                this.diagramDetails = diagramDetails.data.parts.filter(i => i.basePN);
                this.loadingPartDetail = false;
                this.hotspot = diagramDetails.data.assembly.HotSpots;
            });
        } else {
            const items = partCategory.products.map(i => { return { productNumber: i.productNumber, manufacturerCode: i.manufacturerCode }; });
            this.purchasingApi.getDiagramDetail(items).then(diagramDetails => {
                this.diagramDetails = diagramDetails;
                this.loadingPartDetail = false;
            });
        }
    }

    cancelEvent(evt: MouseEvent) {
        evt.cancelBubble = true;
    }

    spotClicked(spot, evt: MouseEvent) {
        evt.cancelBubble = true;
        evt.preventDefault();
        const clickedPart = this.diagramDetails.find(i => i.tag === spot.Tag);
        // clickedPart.selected = true;
        if (clickedPart) {
            this.dialog.open(OrderPartsAddIndividualComponent, { data: clickedPart });
        }
    }

    async deletePart(part: PartToOrder) {
        part.deletedDate = new Date();
        await this.save();
        this.missionService.showUndo(new UndoArguments(async () => {
            part.deletedDate = null;
            await this.save();
        }, 'Part Deleted'));
    }

    addingNewToRepair = false;
    async addNewToRepair() {
        this.addingNewToRepair = true;
        const appliancePart = new PartToOrder();
        appliancePart.id = UtilitiesService.newid();
        appliancePart.description = this.newPart.description;
        appliancePart.manufacturer = this.newPart.manufacturer;
        appliancePart.partNumber = this.newPart.partNumber;
        appliancePart.quantity = this.newPart.quantity;
        appliancePart.cost = this.newPart.cost;
        appliancePart.workOrderLineId = this.workOrderLine.id;
        this.applianceParts.push(appliancePart);

        this.newPart = new PartToOrder();
        this.newPart.id = UtilitiesService.newid();
        this.newPart.quantity = 1;
        await this.save();
        this.selectedIndex = 0;
        this.missionService.showSuccessToast('Part Added');
        this.addingNewToRepair = false;
    }



    addingItemsToRepair = false;
    async addItemsToRepair() {
        this.addingItemsToRepair = true;
        for (const item of this.diagramDetails) {
            if (item.selected) {
                const specAndDetails = await this.purchasingApi.getAppliancePartDetails(item.productNumber, this.workOrderLine.propertyPostalCode);
                const partDetail = specAndDetails.vendorDetails.filter(i => i.totalPrice > 0);
                const appliancePart = new PartToOrder();
                appliancePart.id = UtilitiesService.newid();
                appliancePart.description = item.description;
                appliancePart.imageUrl = item.imageUrls ? item.imageUrls[0] : null;
                appliancePart.partNumber = item.productNumber;
                appliancePart.quantity = item.quantity;
                appliancePart.cost = item.partnerPrice;
                appliancePart.manufacturer = item.manufacturer;
                appliancePart.manufacturerCode = item.manufacturerCode;
                appliancePart.workOrderLineId = this.workOrderLine.id;
                const encompassDetail = partDetail.find(i => i.vendor === 'Encompass');
                const marconeDetail = partDetail.find(i => i.vendor === 'Marcone');
                const reliableDetail = partDetail.find(i => i.vendor === 'Reliable');

                appliancePart.encompassBasePN = encompassDetail?.encompassBasePN;
                appliancePart.encompassMfgCode = encompassDetail?.manufacturer;
                appliancePart.marconeMake = marconeDetail?.manufacturer;
                appliancePart.reliableMfgCode = reliableDetail?.manufacturer;
                appliancePart.reliablePartId = reliableDetail?.reliablePartId;

                this.applianceParts.push(appliancePart);
            }
        }
        this.addingItemsToRepair = false;
        this.selectedIndex = 0;
        await this.save();
        this.missionService.showSuccessToast('Part Added');
    }

    get partsSelected() {
        if (this.addingItemsToRepair) {
            return false;
        }
        if (!this.diagramDetails) {
            return false;
        }

        for (const cat of this.diagramDetails) {
            if (cat.selected) {
                return true;
            }
        }

        return false;
    }

    get selectedPartCount() {
        if (!this.diagramDetails) {
            return 0;
        }

        return this.diagramDetails.filter(i => i.selected).length;
    }

    get canAddNew() {
        return this.newPart.manufacturer && this.newPart.partNumber && this.newPart.description;
    }

    addingSingleToRepair = false;

    async addSinglePartToRepair() {
        this.addingSingleToRepair = true;

        const partToOrder = new PartToOrder();
        partToOrder.id = UtilitiesService.newid();
        partToOrder.description = this.selectedPart.description;
        partToOrder.imageUrl = this.selectedPart.images && this.selectedPart.images.length > 0 ? this.selectedPart.images[0] : null;
        partToOrder.partNumber = this.selectedPart.name;
        partToOrder.quantity = 1;
        partToOrder.cost = parseFloat(this.selectedPart.partnerPrice);
        partToOrder.manufacturer = this.selectedPart.manufacturer;
        partToOrder.manufacturerCode = this.selectedPart.manufacturerCode;
        partToOrder.workOrderLineId = this.workOrderLine.id;
        const encompassDetail = this.partDetail.find(i => i.vendor === 'Encompass');
        const marconeDetail = this.partDetail.find(i => i.vendor === 'Marcone');
        const reliableDetail = this.partDetail.find(i => i.vendor === 'Reliable');

        partToOrder.encompassBasePN = encompassDetail?.encompassBasePN;
        partToOrder.encompassMfgCode = encompassDetail?.manufacturer;
        partToOrder.marconeMake = marconeDetail?.manufacturer;
        partToOrder.reliableMfgCode = reliableDetail?.manufacturer;
        partToOrder.reliablePartId = reliableDetail?.reliablePartId;


        this.applianceParts.push(partToOrder);
        await this.save();
        this.selectedIndex = 0;
        this.missionService.showSuccessToast('Part Added');
        this.addingSingleToRepair = false;
    }

    setFirstNotMatch(value: boolean) {
        if (value) {
            this.firstNoMatch = true;
        }
    }

    toggleSelectedPart(part: ApplianceApiPart) {
        if (!part.quantity) {
            part.quantity = 1;
        }
        part.selected = !part.selected;
    }

    addCustom() {
        this.selectedIndex = 3;
        this.noMatch = false;
        this.newPart = new PartToOrder();
        this.newPart.id = UtilitiesService.newid();
        this.newPart.cost = 0;
        this.newPart.quantity = 1;
        if (!this.brands) {
            this.brandApi.getAllBrands2().then(brands => this.brands = brands);
        }
    }

    diagramSizeChanged(value) {
        localStorage.setItem('diagram-size', value);
    }

    get appliancePartsToShow() {
        return this.applianceParts.filter(i => !i.deletedDate);
    }

    async selectedItemChange(partModelOrAdd: any) {
        this.diagramDetails = null;
        if (partModelOrAdd.type === 'product') {
            this.loadingPartDetail = true;
            this.selectedPart = null;

            this.selectedIndex = 1;

            const specAndDetails = await this.purchasingApi.getAppliancePartDetails(partModelOrAdd.name, this.workOrderLine.propertyPostalCode);
            this.partDetail = specAndDetails.vendorDetails.filter(i => i.totalPrice > 0);
            this.partDetail.sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1)
            const detail = specAndDetails.specDetails;

            this.loadingPartDetail = false;
            this.selectedPart = detail;
            this.imageSelectedIndex = 0;

            this.selectedPart.partnerPrice = this.partDetail[0].totalPrice;
            this.firstNoMatch = true;
        } else if (partModelOrAdd.type === 'model') {
            this.loadingPartDetail = true;
            this.selectedModel = null;

            if (this.useEncompass) {
                this.purchasingApi.getModelAssemblies(partModelOrAdd.name, partModelOrAdd.manufacturer).then(detail => {
                    this.selectedModel = detail;
                    this.loadingPartDetail = false;
                });
            } else {
                this.purchasingApi.getReliableModelDetail(partModelOrAdd.name).then(detail => {
                    this.loadingPartDetail = false;
                    this.selectedModel = detail.result.rpmodel;
                });
            }




            this.selectedIndex = 2;
            this.firstNoMatch = true;
        } else if (partModelOrAdd === 'add-new') {
            this.selectedIndex = 3;
        }
    }

    get canSave() {
        return !this.saving && this.applianceParts.length > 0;
    }

    async updatePartQuantity(part: PartToOrder) {
        this.saving = true;
        await this.purchasingApi.savePartsToOrder([part]);
        this.saving = false;
        this.missionService.showSuccessToast('Quantity Updated');
    }

    async save() {
        this.saving = true;

        await this.purchasingApi.savePartsToOrder(this.applianceParts);
        this.saving = false;
        this.missionService.showSuccessToast('Parts Queued To Order');
        // TODO: Navigate back to work order detail
    }

}
