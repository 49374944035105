import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PhoneNumberCustomFunctionMapping {
    @keyColumn() id: string;
    @column() phoneNumber: string;
    @column() customFunctionId: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;

    originalContactFlowId: string;
}