import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionQuestionRendererComponent } from '@cogent/client/shared/components/functions/function-question-renderer/function-question-renderer/function-question-renderer.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
import { TagChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/tag-chip-selection/tag-chip-selection.module';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { HeightDirectivesModule } from '@cogent/client/shared/directives/height-directives/height-directives.module';
import { PhoneLinkModule } from '@cogent/client/shared/components/misc/phone-link/phone-link.module';
import { TypingNotificationComponent } from './typing-notification/typing-notification.component';
import { JobItemSelectionComponent } from '@cogent/client/shared/components/service/job-item-selection/job-item-selection.component';
import { FindUserPolicesByPhoneOrEmailComponent } from '@cogent/client/shared/components/policies/find-user-polices-by-phone-or-email/find-user-polices-by-phone-or-email.component';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { ConfirmJobContactInfoComponent } from '@cogent/client/shared/components/service/confirm-job-contact-info/confirm-job-contact-info.component';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { SearchChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/search-chip-selection/search-chip-selection.module';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { StarRatingComponent } from '@cogent/client/shared/components/misc/star-rating/star-rating.component';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { QuestionWizardComponentV2} from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';
import { PaymentMethodEntryComponent } from '../../accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';


@NgModule({
  declarations: [
    FunctionQuestionRendererComponent,
  ],
  imports: [
    CommonModule,
    MaterialSharedModule,
    RichTextAndSnippetModule,
    DatePickerWrapperComponent,
    SwapableImageModule,
    TagChipSelectionModule,
    AppointmentSelectionComponent,
    HeightDirectivesModule,
    PhoneLinkModule,
    StarRatingComponent,
    JobItemSelectionComponent,
    TypingNotificationComponent,
    FindUserPolicesByPhoneOrEmailComponent,
    PaymentMethodEntryModule,
    PaymentMethodEntryComponent,
    CheckCircleAnimatedComponent,
    ConfirmJobContactInfoComponent,
    UpkeepPipesModule,
    AddressEditorComponent,
    PlanOfferingsModule,
    SearchChipSelectionModule,
    QuestionWizardComponentV2
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [FunctionQuestionRendererComponent]
})
export class FunctionQuestionRendererModule { }
