import { SalesItemCoverageWorkOrderItem } from "@upkeeplabs/models/cogent";
import { WorkOrderItemClient } from "../items/work-order-item-client.model";

export class SalesItemCoverageWorkOrderItemClient extends SalesItemCoverageWorkOrderItem {

    jobItem: WorkOrderItemClient;



    coveredTypeSelectors: WorkOrderItemCoverageItemSelector[];
    coveredLocationSelectors: WorkOrderItemCoverageItemSelector[];
    coveredProblemSelectors: WorkOrderItemCoverageItemSelector[];
    coveredCauseSelectors: WorkOrderItemCoverageItemSelector[];
    coveredRepairTypeSelectors: WorkOrderItemRepairTypeSelector[];
    authorizationRepairItemGroups: AuthorizationItemGroupLimit[];

    isChanged = false;

    getCoveredTypes() {
        return JSON.parse(this.coveredTypes) as WorkOrderItemCoverageItem[];
    }
    getCoveredLocations() {
        return JSON.parse(this.coveredLocations) as WorkOrderItemCoverageItem[];
    }
    getCoveredProblems() {
        return JSON.parse(this.coveredProblems) as WorkOrderItemCoverageItem[];
    }
    getCoveredCauses() {
        return JSON.parse(this.coveredCauses) as WorkOrderItemCoverageItem[];
    }

    getCoveredRepairTypes() {
        return JSON.parse(this.coveredRepairTypes) as WorkOrderItemCoverageItem[];
    }

    getAuthorizationRepairItemGroupLimits() {
        if (this.getAuthorizationRepairItemGroupLimits) {
            return JSON.parse(this.authorizationRepairItemGroupLimits) as AuthorizationItemGroupLimit[];
        }
        return [];
    }


    constructor(id: string = null, salesItemCoverageId: string = null, workOrderItemId: string = null) {
        super();
        this.id = id;
        this.salesItemCoverageId = salesItemCoverageId;
        this.workOrderItemId = workOrderItemId;
        this.coveredTypes = '[]';
        this.coveredLocations = '[]';
        this.coveredProblems = '[]';
        this.coveredCauses = '[]';
        this.coveredRepairTypes = '[]';
        this.authorizationRepairItemGroupLimits = '[]';
    }
}

export class WorkOrderItemCoverageItemSelector {
    id: string;
    name: string;
    limitPerOccurance?: number;
    limitPerPlanPeriod?: number;
    isSelected = false;
}

export class AuthorizationItemGroupLimit {
    id: string;
    name: string;
    isSelected: boolean;
    limitPerOccurance?: number;
    limitPerPlanPeriod?: number;
}

export class WorkOrderItemCoverageItem {
    id: string;
    limitPerOccurance?: number;
    limitPerPlanPeriod?: number;
}

export class WorkOrderItemRepairTypeSelector {
    id: string;
    name: string;
    isSelected = false;
}
