import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServicePricing {
    @keyColumn() id: string;
    @column() maintenanceServiceOfferingId: string;
    @column() maintenanceServiceFrequencyTypeId: string;
    //@column() maintenanceServicePricingInputId: string;
    @column() geographyId: string;
    @column() price: number;
    @column() cost: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() rangeStart: number;
    @column() rangeEnd: number;
    @column() filterOptions: string;
    @column() secondaryUnitPrice: number;
    @column() secondaryUnitCost: number;
    @column() includedSecondaryUnits: number;
    @column() costPerMonth: number;
    @column() dynamicPricingType: string;
    @column() dynamicPricingAddition?: number;
    @column() tertiaryUnitsPrice?: number;
    @column() includedTertiaryUnits?: number;
    @column() tertiaryUnitsCost?: number;


    filterOptionIds: string[];
}