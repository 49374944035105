import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AuthorizationLine {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() repairItemId: string;
    @column() quantity: number;
    @column() unitPrice: number;
    @column() guideline: number;
    @column() isOutOfPocket: boolean;
    @column() isPurchaseOrderNeeded: boolean;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() temporaryItemName: string;
    @column() yellowRangePercent: number;
    @column() laborHours: number;
    @column() laborRate: number;
    @column() workOrderLineId: string;
    @column() limit: number;
    @column() limitPerUnit: number;
}