import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { StripeCard } from "./stripe-card.model";

@dataSource()
export class MaintenanceServiceCustomerPropertyServiceAddOn {
    @keyColumn() id: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() price: number;
    @column() cost: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() description: string;
    @column() meta: string;

    card: StripeCard;
}