import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FeatureUpdate {
    @keyColumn() id: string;
    @column() title: string;
    @column() summary: string;
    @column() url: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() publishedDate: Date;

    // client-side only
    isNew = false;
    body: string;
}