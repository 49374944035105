import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class VerificationCode {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() code: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() destinationAddress: string;
    @column() destinationAddressType?: string;
}