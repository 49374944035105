import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AuthoCallsAnsweredByAuthoAgents {
    @column() callCount: number;
    @column() answeredByAuthoAgent: number;
    @column() date: Date;

    get percent() {
        if (this.callCount !== 0) {
            return this.answeredByAuthoAgent / this.callCount;
        }

        return 0;
    }

    get percent100() {
        return Math.round(this.percent * 100);
    }
}