import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagChipSelectionComponent } from './tag-chip-selection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';


@NgModule({
    declarations: [TagChipSelectionComponent],
    exports: [TagChipSelectionComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
    ]
})
export class TagChipSelectionModule { }
