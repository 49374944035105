export class ChatConfig {
    amazonConnectApiId: string;
    amazonConnectRegion: string;
    amazonConnectContactFlowId: string;
    amazonConnectInstanceId: string;
}

export class ConnectConfig {
    amazonConnectCcpUrl: string;
    amazonConnectLoginUrl: string;
    amazonConnectRegion: string;
}