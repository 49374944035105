<div class="container fancy-container">


    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex"
        (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="First">

            <div class="center">
                <img src="../../../assets/images/circle-register.png" style="height: 100px;">
                <h1 topmargin40>Service Pro Registration</h1>
            </div>

            <p topmargin40 class="info center">
                First, search for your record in our database. We'll send you a registration code you can use to
                complete your registration.
            </p>
            <div id="search-container">
                <form topmargin40>
                    <mat-form-field appearance="outline" style="max-width: 400px">
                        <mat-label>Search For</mat-label>
                        <input matInput name="search-for" [(ngModel)]="searchFor">
                        <span matSuffix
                            style="position: absolute;        top: -29px;        right: -7px;        height: 35px;">
                            <button mat-button style="height: 100%;" [disabled]="!searchFor || searching"
                                mat-raised-button color="primary" (click)="doSearch()">
                                <mat-icon style="display: inline;" *ngIf="!searching">search</mat-icon>
                                <mat-spinner *ngIf="searching" class="thirty"></mat-spinner>
                            </button>
                        </span>
                    </mat-form-field>
                </form>
            </div>

            <div *ngIf="searchResults" class="slide-in-up-small">
                <button mat-button (click)="select(searchResult)" [disabled]="searchResult.sending" class="search-result" *ngFor="let searchResult of searchResults">
                    <div aflex>
                        <div nogrow>
                            <mat-icon>account_circle</mat-icon>
                        </div>
                        <div>
                            <h2>{{searchResult.name}}</h2>
                            <div>{{searchResult.email}}</div>
                        </div>
                        <div nogrow *ngIf="searchResult.sending">
                            <mat-spinner class="thirty"></mat-spinner>
                        </div>
                    </div>
                </button>
            </div>

        </mat-tab>
        <mat-tab label="Second">
            

            <div class="center">
                <img src="../../../assets/images/circle-person.png" style="height: 100px;">
                <h1 topmargin40>Registration Info</h1>
            </div>

            <p topmargin40 class="info center">
                Enter the verification code you received via email, and then provide us with your desired login name and password.
            </p>

            <div style="max-width: 700px; display: table; margin: 0 auto;margin-top: 30px;width: 100%">
                <mat-form-field appearance="outline" style="max-width: 300px">
                    <mat-label>Verification Code</mat-label>
                    <input matInput [(ngModel)]="verificationCode">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Login Email</mat-label>
                    <input matInput [(ngModel)]="loginName">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" [(ngModel)]="password">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                    <mat-label>Password Confirm</mat-label>
                    <input matInput type="password" [(ngModel)]="passwordConfirm">
                    <mat-hint *ngIf="passwordMismatch" class="error" align="end">The password does not match the
                        confirmation</mat-hint>
                </mat-form-field>
    
                <button mat-button mat-raised-button color="primary" large mobile-block [disabled]="!canSave"
                    (click)="save()">
                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                    Save
                </button>
            </div>


        </mat-tab>
        <mat-tab label="Second">
            <div class="center">
                <!-- <img src="../../../assets/images/check-circle.png" style="height: 175px;"> -->
                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin40>Registration Complete</h1>
                <a mat-button mat-raised-button color="primary" large mobile-block topmargin40 routerLink="/">Visit
                    Portal</a>
            </div>
        </mat-tab>
    </mat-tab-group>


</div>