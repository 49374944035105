export class StripeCard {
    accountId: string;
    address_zip: string;
    address_zip_check: string;
    brand: string;
    country: string;
    customer: string;
    cvc_check: string;
    default_for_currency: boolean;
    exp_month: number;
    exp_year: number;
    funding: string;
    id: string;
    last4: string;
    object: string;

    metadata: StripeCardMetaModel;

    // Client-side only
    customerName: string;
    saveCardForLater?: boolean;
    removingDeleted  = false;
    deleting = false;

}

export class StripeCardMetaModel {
    Created: Date;
    EntityId: string;
}