import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceContractorZipCode {
    @column() maintenanceServiceId: string;
    @column() maintenanceServiceName: string;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() zipCode: string;
    @column() lastAssignedDate?: Date;
    @column() autoAssignJobs?: boolean;
}