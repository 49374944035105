<div *ngIf="address" class="address-container">
  <div>
    <mat-form-field appearance="outline">
        <mat-label>Address 1  {{addressIsRequired ? '*' : ''}}</mat-label>
      <input [id]="id" [disabled]="disabled" autocomplete="do-not-auto-complete"  (ngModelChange)="emitCompleteEvent();" autocomplete="false" matInput [(ngModel)]="address.address1" (focus)="geolocate()" type="search">
      <mat-hint align="end">123 W Oak</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
        <mat-label>Address 2</mat-label>
      <input matInput [disabled]="disabled" autocomplete="0" [(ngModel)]="address.address2">
      <mat-hint align="end">Unit 1</mat-hint>
    </mat-form-field>
  </div>
  <div flex>

    <mat-form-field flex3 [class.city-condensed]="condenseCityStateZip" class="city" appearance="outline">
        <mat-label>City  {{addressIsRequired ? '*' : ''}}</mat-label>
      <input matInput [disabled]="disabled" (ngModelChange)="emitCompleteEvent();" [(ngModel)]="address.city">
      <mat-hint align="end">My Town</mat-hint>
    </mat-form-field>
    <mat-form-field class="state" [class.state-condensed]="condenseCityStateZip" flex1 appearance="outline">
        <mat-label>State  {{addressIsRequired ? '*' : ''}}</mat-label>
      <input matInput [disabled]="disabled" (ngModelChange)="emitCompleteEvent();"  [(ngModel)]="address.state">
      <mat-hint align="end">NY</mat-hint>
    </mat-form-field>
    <mat-form-field class="postal-code" [class.postal-code-condensed]="condenseCityStateZip" flex2 appearance="outline">
        <mat-label>Postal Code {{addressIsRequired ? '*' : ''}}</mat-label>
      <input matInput [disabled]="disabled" inputmode="numeric" (ngModelChange)="emitCompleteEvent();" [(ngModel)]="address.postalCode" (ngModelChange)="checkZipCode($event)">
      <mat-hint align="end">11111</mat-hint>
    </mat-form-field>
  </div>

  <div *ngIf="showPropertyFields">
    <div flex margintop30>
      <div flex1>
        <mat-form-field appearance="outline">
            <mat-label>Dwelling Type</mat-label>
          <mat-select [disabled]="disabled" block [(ngModel)]="address.dwellingType">
            <mat-option value="Condominium">Condo</mat-option>
            <mat-option value="SingleFamily">Single Family</mat-option>
            <mat-option value="Duplex">Duplex</mat-option>
            <mat-option value="Triplex">Triplex</mat-option>
            <!-- <mat-option value="Quadruplex">Quadruplex</mat-option> -->
            <mat-option value="Fourplex">Fourplex</mat-option>
            <mat-option value="FivePlex">5 Plex</mat-option>
            <mat-option value="SixPlex">6 Plex</mat-option>
            <mat-option value="Mobile">Mobile</mat-option>
            <mat-option value="Unknown">Unknown</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div flex1>
        <mat-form-field class="sqft" appearance="outline">
            <mat-label>Square Footage</mat-label>
          <input matInput  [disabled]="disabled"type="number" [(ngModel)]="address.squareFeet">
        </mat-form-field>
      </div>
    </div>
    <div flex>
      <div flex1>
        <mat-form-field class="year-built" appearance="outline">
            <mat-label>Year Built</mat-label>
          <input matInput [disabled]="disabled" type="number" [(ngModel)]="address.yearBuilt">
        </mat-form-field>
      </div>
      <div flex1></div>
    </div>

    <div flex *ngIf="showBedroomBathroom">
        <div flex1>
            <mat-form-field class="year-built" appearance="outline">
                <mat-label>Bedrooms</mat-label>
              <input matInput [disabled]="disabled" type="number" [(ngModel)]="address.bedroomCount">
            </mat-form-field>
        </div>
        <div flex1>
            <mat-form-field class="year-built" appearance="outline">
                <mat-label>Bathrooms</mat-label>
              <input matInput [disabled]="disabled" type="number" [(ngModel)]="address.bathroomCount">
            </mat-form-field>
            
        </div>
    </div>
  </div>
</div>
