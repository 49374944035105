export class PolicyStatsModel {
    count: number;
    coverageType: string;
    amount: number;
    day: string;
    date: Date;
}

export class PolicyStatsGroupedByDay {

    public static fromPolicyStats(stats: PolicyStatsModel[]): PolicyStatsGroupedByDay[] {
        const result: PolicyStatsGroupedByDay[] = [];

        stats.forEach(stat => {
            let resultStat = result.filter(i => i.date.getDate() === stat.date.getDate())[0];
            if (!resultStat) {
                resultStat = new PolicyStatsGroupedByDay(stat.day, stat.count, stat.date, stat.amount);
                result.push(resultStat);
            } else {
                resultStat.count += stat.count;
                resultStat.amount += stat.amount;
            }
        });

        result.sort((a, b) => a.date > b.date ? 1 : -1);

        return result;
    }

    constructor(public day: string, public count: number, public date: Date, public amount: number) { }
}

export class PolicyStatsGroupedByType {

    public static fromPolicyStats(stats: PolicyStatsModel[]): PolicyStatsGroupedByType[] {
        const result: PolicyStatsGroupedByType[] = [];

        stats.forEach(stat => {
            let resultStat = result.filter(i => i.type === stat.coverageType)[0];
            if (!resultStat) {
                resultStat = new PolicyStatsGroupedByType(stat.coverageType, stat.count, stat.amount);
                result.push(resultStat);
            } else {
                resultStat.count += stat.count;
                resultStat.amount += stat.amount;
            }
        });

        return result;
    }

    constructor(public type: string, public count: number, public amount: number) { }
}
