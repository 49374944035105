import { SmsComposeModalComponent } from '@cogent/client/shared/components/communication/sms-compose-modal/sms-compose-modal.component';
import { EmailComposeModalComponent } from '@cogent/client/shared/components/communication/email-compose-link/email-compose-modal/email-compose-modal.component';
import { TemplateApiService } from '@cogent/client/shared/services/api/template-api.service';
import { TaskApiService } from '@cogent/client/shared/services/api/task-api.service';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PurchasingApiService } from '@cogent/client/shared/services/api/purchasing-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';
import { EmailApiService } from '@cogent/client/shared/services/api/email-api.service';
import { DocumentApiService } from '@cogent/client/shared/services/api/document-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Injectable, Type } from '@angular/core';
import { AccountingApiService } from '@cogent/client/shared/services/api/accounting-api.service';
import { QueueAction, QueueActionType, QueueQuery, FormAnswerDefault } from '@cogent/client/shared/models/object-queue.model';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { MatDialog } from '@angular/material/dialog';
import { EmailArgs, EmailAddress, EmailAttachment,} from '@cogent/shared/models/other/email-args.model';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { SmsComposeArgs } from '@cogent/shared/models/communication/sms-compose-args.model';
import { Router } from '@angular/router';
import { FormItem, FormItemType } from '@cogent/client/shared/models/custom-form.model';
import { SlyBroadcastMessage} from '@cogent/shared/models/user/sly-broadcast.model';
import { ApiService } from '@cogent/client/api';
import { GlobalCommandClient } from '@cogent/client/shared/models/global-command-client.model';
import { EnvironmentVariablesService } from '@cogent/client/shared/services/environment-variables.service';
import { LazyComponentProviderService } from '@cogent/client/shared/services/lazy-component-provider.service';
import { from, Subject } from 'rxjs';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { EntitySummary, InvoiceSummary, Task, UserNotification } from '@upkeeplabs/models/cogent';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';
import { NoteClient } from '@cogent/client/shared/models/common/note-client.model';
import { PurchaseOrderSummaryClient } from '@cogent/client/shared/models/purchasing/purchase-order-summary-client.model';
import { TaskSummaryClient } from '@cogent/client/shared/models/tasks/task-summary-client.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EventArguments, UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { FillOutFormComponent } from '@cogent/client/shared/components/functions/fill-out-form/fill-out-form.component';
import { TakeQuestionnaireComponent } from '@cogent/client/shared/components/functions/take-questionnaire/take-questionnaire.component';


class TestClass {
    public static testExpression(input: any, expression: string): any {
        let newExpression = expression.replace('this.', 'thisItem.');
        newExpression = newExpression.trim();

        if (newExpression.indexOf('return ') !== 0) {
            newExpression = 'return ' + newExpression;
        }
        const result = new Function('thisItem', newExpression)(input);
        return result;
    }
}


class CommandRunnerInstance {

    private restResults: any = {};
    private lastRestResult: any;
    private questionnaires: any;
    private queueQuery: QueueQuery;
    private refreshQueueAction: () => void;
    private refreshAllQueuesAction: () => void;
    private alterWorkingMessage: (show: boolean, message: string) => void;
    private context: any;

    constructor(private serviceApi: ServiceApiService, private entityApi: EntityApiService, private taskApi: TaskApiService,
        private documentApi: DocumentApiService, private dialog: MatDialog, private emailApi: EmailApiService, private dialogService: DialogsService,
        private missionService: MissionService, private noteApi: NoteApiService, private router: Router, private policyApi: PolicyApiService,
        private templateApi: TemplateApiService, private accountingApi: AccountingApiService, private apiService: ApiService,
        private settingsApi: SettingsApiService, private purchasingApi: PurchasingApiService, private environmentalVariables: EnvironmentVariablesService,
        private lazyComponentProvider: LazyComponentProviderService) { }

    async startDoCommand(actions: QueueAction[], workOrderLineSummary: WorkOrderLineSummaryClient, workOrder: WorkOrderSummaryClient, workOrderSummary: WorkOrderSummaryClient = null, queueQuery: QueueQuery,
        refreshQueueAction: () => void, refreshAllQueuesAction: () => void, alterWorkingMessage: (show: boolean, message: string) => void, policySummary: PolicySummary,
        entitySummary: EntitySummary, taskSummary: TaskSummaryClient, contractorInvoiceSummary: ContractorInvoiceSummaryClient, moveLane: (objetToMove: any) => void,
        purchaseOrder: PurchaseOrderSummaryClient, invoiceSummary: InvoiceSummary, queueObjects: any[] = null, queueType: string = null,
        removeFromQueue: () => void, bindingObject: any = null, refreshObjectCallback: (updatedObject) => void = null) {
        this.questionnaires = {};
        this.refreshQueueAction = refreshQueueAction;
        this.refreshAllQueuesAction = refreshAllQueuesAction;
        this.alterWorkingMessage = alterWorkingMessage;

        this.queueQuery = queueQuery;
        this.doCommand(actions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane,
            purchaseOrder, invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback);
    }

    unsetHandlers() {
        // Not sure if this is necessary
        this.refreshAllQueuesAction = null;
        this.refreshAllQueuesAction = null;
        this.alterWorkingMessage = null;
    }

    private replaceQuestionnaireStringValues(value: string) {
        for (const key of Object.keys(this.questionnaires)) {
            let fullStack = '';
            const questionnaireOrForm = this.questionnaires[key];

            if (questionnaireOrForm.type && questionnaireOrForm.type === 'CUSTOMFORM') {
                for (const formItem of questionnaireOrForm.formItems) {
                    const replacementObject = {};
                    replacementObject[formItem.id] = formItem.formattedAnswer;
                    value = UtilitiesService.replaceValuesInStringFromObject(value, replacementObject, key, true);

                    if (fullStack) {
                        fullStack += '\n';
                    }
                    fullStack += `Q:${formItem.prompt}\nA:${formItem.answer ? formItem.answer : ''}`;
                }
            } else {
                value = UtilitiesService.replaceValuesInStringFromObject(value, questionnaireOrForm, key, true);
                for (const questionnaireKey of Object.keys(questionnaireOrForm)) {
                    if (fullStack) {
                        fullStack += '\n';
                    }
                    fullStack += `Q:${questionnaireKey}\nA:${questionnaireOrForm[questionnaireKey] ? questionnaireOrForm[questionnaireKey] : ''}`;
                }
            }

            fullStack = UtilitiesService.replaceAll(fullStack, '"', '\"');
            value = UtilitiesService.replaceAll(value, `{{${key}-full-stack}}`, fullStack);
            value = UtilitiesService.replaceAll(value, `{{${key}-full-stack-json}}`, JSON.stringify(questionnaireOrForm));

        }

        value = UtilitiesService.replaceValuesInStringFromObject(value, this.environmentalVariables, 'environment');
        value = UtilitiesService.replaceAll(value, `{{baseAPIURL}}`, ApiService.endPointDotNet);
        if (this.lastRestResult) {
            value = UtilitiesService.replaceValuesInStringFromObject(value, this.lastRestResult, 'restResult');
        }

        if (this.context) {


            for (const key of Object.keys(this.context)) {
                const contextValue = this.context[key];
                if (contextValue) {
                    if (UtilitiesService.isObject(contextValue)) {
                        value = UtilitiesService.replaceValuesInStringFromObject(value, contextValue, key);
                    } else {
                        value = UtilitiesService.replaceAll(value, `{{context.${key}}}`, contextValue);
                    }
                }
            }
        }
        return value;
    }

    private setStatus(statusId: string, workOrderLine: WorkOrderLineSummaryClient) {
        return this.serviceApi.setWorkOrderLineStatus(workOrderLine.id, statusId);
    }

    private setTags(action: QueueAction, workOrder: WorkOrderSummaryClient, policySummary: PolicySummary, entitySummary: EntitySummary, taskSummary: TaskSummaryClient,
        contractorInvoiceSummary: ContractorInvoiceSummaryClient, purchaseOrderSummary: PurchaseOrderSummaryClient, invoiceSummary: InvoiceSummary) {
        const promises = [];


        if (action.tagsToAdd) {
            if (workOrder) {
                promises.push(this.serviceApi.addWorkOrderTags(workOrder.id, action.tagsToAdd));
            }
            if (policySummary) {
                promises.push(this.policyApi.addTags(policySummary.id, action.tagsToAdd));
            }
            if (entitySummary) {
                promises.push(this.entityApi.addTags(entitySummary.id, action.tagsToAdd));
            }

            if (taskSummary) {
                promises.push(this.taskApi.addTags(taskSummary.id, action.tagsToAdd));
            }
            if (contractorInvoiceSummary) {
                promises.push(this.accountingApi.addContractorInvoiceTags(contractorInvoiceSummary.id, action.tagsToAdd));
            }
            if (purchaseOrderSummary) {
                promises.push(this.purchasingApi.addPurchaseOrderTags(purchaseOrderSummary.id, action.tagsToAdd));
            }
            if (invoiceSummary) {
                promises.push(this.accountingApi.addInvoiceTags(invoiceSummary.id, action.tagsToAdd));
            }
        }
        if (action.tagsToRemove) {
            if (workOrder) {
                promises.push(this.serviceApi.removeWorkOrderTags(workOrder.id, action.tagsToRemove));
            }
            if (policySummary) {
                promises.push(this.policyApi.removeTags(policySummary.id, action.tagsToRemove));
            }
            if (entitySummary) {
                promises.push(this.entityApi.removeTags(entitySummary.id, action.tagsToRemove));
            }
            if (taskSummary) {

                promises.push(this.taskApi.removeTags(taskSummary.id, action.tagsToRemove));
            }
            if (contractorInvoiceSummary) {
                promises.push(this.accountingApi.removeContractorInvoiceTags(contractorInvoiceSummary.id, action.tagsToRemove));
            }
            if (purchaseOrderSummary) {
                promises.push(this.purchasingApi.removePurchaseOrderTags(purchaseOrderSummary.id, action.tagsToRemove));
            }
            if (invoiceSummary) {
                promises.push(this.accountingApi.removeInvoiceTags(invoiceSummary.id, action.tagsToRemove));
            }
        }


        return Promise.all(promises);
    }

    private refreshSelectedQueue() {
        if (this.refreshQueueAction) {
            this.refreshQueueAction();
        }
    }

    private refreshQueues() {
        if (this.refreshAllQueuesAction) {
            this.refreshAllQueuesAction();
        }
    }

    private async doCommand(actions: QueueAction[], workOrderLineSummary: WorkOrderLineSummaryClient, workOrder: WorkOrderSummaryClient, workOrderSummary: WorkOrderSummaryClient = null, policySummary: PolicySummary,
        entitySummary: EntitySummary, taskSummary: TaskSummaryClient, contractorInvoiceSummary: ContractorInvoiceSummaryClient, moveLane: (objectToMove) => void,
        purchaseOrderSummary: PurchaseOrderSummaryClient, invoiceSummary: InvoiceSummary, queueObjects: any[] = null, sourceObject = null, queueType: string = null,
        removeFromQueue: () => void, bindingObject: any = null, refreshObjectCallback: (updatedObject) => void = null, context: any = null) {

        if (!context) {
            context = { bindingObject };
            this.context = context;
        }

        if (!workOrderSummary && workOrder) {
            workOrderSummary = await this.serviceApi.getWorkOrderSummary(workOrder.id);
        }
        if (!actions) {
            return;
        }

        if (workOrderLineSummary) {
            sourceObject = workOrderLineSummary;
        }
        if (workOrderSummary && !sourceObject) {
            sourceObject = workOrderSummary;
        }
        if (policySummary && !sourceObject) {
            sourceObject = policySummary;
        }

        if (entitySummary && !sourceObject) {
            sourceObject = entitySummary;
        }
        if (taskSummary && !sourceObject) {
            sourceObject = taskSummary;
        }
        if (contractorInvoiceSummary && !sourceObject) {
            sourceObject = contractorInvoiceSummary;
        }
        if (purchaseOrderSummary && !sourceObject) {
            sourceObject = purchaseOrderSummary;
        }
        if (invoiceSummary && !sourceObject) {
            sourceObject = invoiceSummary;
        }
        if (bindingObject && !sourceObject) {
            sourceObject = bindingObject;
        }

        if (!sourceObject) {
            sourceObject = {};
        }


        for (const action of actions) {
            if (action.runOnEveryQueueItem && queueObjects) {
                for (const queueObject of queueObjects) {
                    await this.doCommand([action], queueObject,
                        queueType === 'Service' ? queueObject : null,
                        queueType === 'Service' ? queueObject : null,
                        queueType === 'Policy' ? queueObject : null,
                        queueType === 'Entity' ? queueObject : null,
                        queueType === 'Task' ? queueObject : null,
                        queueType === 'ContractorInvoice' ? queueObject : null,

                        moveLane, purchaseOrderSummary, invoiceSummary, null, queueObject, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                }
                this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary, policySummary,
                    entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                    removeFromQueue, bindingObject, refreshObjectCallback, context
                );
                return;
            }

            if (action.queueAction === QueueActionType.ChangeStatus) {

                if (action.runOnEveryQueueItem && queueObjects) {
                    for (const queueObject of queueObjects) {

                        await this.setStatus(action.newWorkOrderStatusId, queueObject);
                    }
                } else {
                    if (workOrderLineSummary) {
                        await this.setStatus(action.newWorkOrderStatusId, workOrderLineSummary);
                    } else if (workOrderSummary && workOrderSummary.lines) {
                        for (const line of workOrderSummary.lines) {
                            await this.setStatus(action.newWorkOrderStatusId, line);
                        }
                    }
                }

            } else if (action.queueAction === QueueActionType.CreateATask) {
                const tasks = [];
                if (action.createTheTaskForMe) {
                    const loggedInUser = await this.entityApi.getLoggedInUser(false);

                    const myRecord = new SearchResultModel(loggedInUser.name, loggedInUser.id);
                    action.selectedEmployees = [myRecord];
                }

                if (action.selectedEmployees == null || action.selectedEmployees.length === 0 && action.taskQueueId) {
                    action.selectedEmployees = [
                        { id: null, } as any
                    ];
                }

                action.selectedEmployees.forEach(employee => {

                    const task = new Task();
                    task.id = UtilitiesService.newid();
                    task.ownerId = employee.id;
                    task.taskQueueId = action.taskQueueId;

                    if (workOrderSummary) {
                        task.workOrderId = workOrderSummary.id;
                        task.workOrderNumber = workOrderSummary.number;
                        task.policyId = workOrderSummary.policyId;
                        task.policyNumber = workOrderSummary.policyNumber;
                    }
                    if (policySummary) {
                        task.policyId = policySummary.id;
                        task.policyNumber = policySummary.policyNumber;
                    }
                    if (entitySummary) {
                        task.entityId = entitySummary.id;
                    }
                    if (taskSummary) {
                        task.policyId = taskSummary.policyId;
                        task.policyNumber = taskSummary.policyNumber;
                        task.workOrderId = taskSummary.workOrderId;
                        task.workOrderNumber = taskSummary.workOrderNumber;
                    }
                    if (invoiceSummary) {
                        task.policyId = invoiceSummary.policyId;
                    }
                    task.description = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                    task.title = UtilitiesService.replaceValuesInStringFromObject(action.messageSubject, sourceObject);


                    task.description = this.replaceQuestionnaireStringValues(task.description);
                    task.title = this.replaceQuestionnaireStringValues(task.title);

                    tasks.push(this.taskApi.saveTask(task, false));
                });


                await Promise.all(tasks);

            } else if (action.queueAction === QueueActionType.ModifyTags) {
                await this.setTags(action, workOrderSummary, policySummary, entitySummary, taskSummary, contractorInvoiceSummary, purchaseOrderSummary, invoiceSummary);
            } else if (action.queueAction === QueueActionType.MoveLane) {
                moveLane(sourceObject);
            } else if (action.queueAction === QueueActionType.RemoveFromQueue) {
                removeFromQueue();
            } else if (action.queueAction === QueueActionType.HideWorkingMessage) {
                // this.executingCommand = false;
                if (this.alterWorkingMessage) {
                    this.alterWorkingMessage(false, '');
                }
            } else if (action.queueAction === QueueActionType.BroadcastApplicationMessage) {
                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageSubject, sourceObject);
                message = this.replaceQuestionnaireStringValues(message);

                let body = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                body = this.replaceQuestionnaireStringValues(body);

                const shouldContinue = action.waitBeforeContinue ? new Subject<boolean>() : null;
                this.missionService.raiseEvent(new EventArguments('COMMAND_ACTION_EVENT', { messageKey: message, messageBody: body, shouldContinue }));
                if (action.waitBeforeContinue) {
                    if (!await from(shouldContinue)) {
                        return;
                    }
                }

            } else if (action.queueAction === QueueActionType.SetVariable) {
                let doSet = false;
                if (action.conditionalExpression) {
                    const replacementObject = {};
                    // let formItems: FormItem[] = [];
                    for (const key of Object.keys(this.questionnaires)) {
                        const questionnaire = this.questionnaires[key];

                        if (questionnaire.formItems && questionnaire.formItems.length) {
                            questionnaire.formItems.filter(i => i.prompt && i.answer).forEach(item => {
                                replacementObject[item.id] = item.answer;
                            });
                        }
                    }
                    const evalResult = TestClass.testExpression({
                        questionnaires: this.questionnaires, formItems: replacementObject, workOrderSummary, restResults: this.restResults,
                        policySummary, entitySummary, taskSummary, contractorInvoiceSummary, purchaseOrderSummary, invoiceSummary, bindingObject,
                        restResult: this.lastRestResult, context
                    }, action.conditionalExpression);
                    if (evalResult) {
                        doSet = true;
                    }
                } else {
                    doSet = true;
                }

                if (doSet) {
                    let value = UtilitiesService.replaceValuesInStringFromObject(action.setVariableValue, sourceObject);
                    value = this.replaceQuestionnaireStringValues(value);
                    let key = UtilitiesService.replaceValuesInStringFromObject(action.setVariableKey, sourceObject);
                    key = this.replaceQuestionnaireStringValues(key);
                    if (key) {
                        context[key] = value;
                    }
                }
            } else if (action.queueAction === QueueActionType.RefreshObject) {
                let url = '';
                let typeCreator = null;
                if (workOrder) {
                    url = 'WorkOrderSummaryWithTags';
                    typeCreator = () => new WorkOrderSummaryClient();
                } else if (policySummary) {
                    url = 'PolicySummary';
                    typeCreator = () => new PolicySummary();
                } else if (entitySummary) {
                    url = 'EntitySummary';
                    typeCreator = () => new EntitySummary();
                }
                if (taskSummary) {
                    url = 'TaskSummary';
                    typeCreator = () => new TaskSummaryClient();
                } else if (contractorInvoiceSummary) {
                    url = 'ContractorInvoiceSummary';
                    typeCreator = () => new ContractorInvoiceSummaryClient();
                } else if (purchaseOrderSummary) {
                    url = 'PurchaseOrderSummary';
                    typeCreator = () => new PurchaseOrderSummaryClient();
                } else if (invoiceSummary) {
                    url = 'InvoiceSummary';
                    typeCreator = () => new InvoiceSummary();
                }

                if (queueType === 'CustomTable') {
                    url = `UserDefinedTable/${this.queueQuery.customTableName}`;
                }


                sourceObject = await this.apiService.getSingleDotNet(`${url}/${sourceObject.id}`, null, typeCreator);

                if (refreshObjectCallback) {
                    refreshObjectCallback(sourceObject);
                    if (bindingObject) {
                        bindingObject = sourceObject;
                        if (context) {
                            context.bindingObject = bindingObject;
                        }
                    }
                }
            } else if (action.queueAction === QueueActionType.RunGlobalCommand) {
                const globalCommand: GlobalCommandClient = await this.settingsApi.getGlobalCommandById(action.commandToRunId);

                const newActions = globalCommand.actionsObject.concat(actions.slice(actions.indexOf(action) + 1, actions.length));
                this.doCommand(newActions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane,
                    purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);

                return;
            } else if (action.queueAction === QueueActionType.ShowWorkingMessage) {
                let commandMessage = '';

                if (action.messageBody) {
                    commandMessage = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);

                    commandMessage = this.replaceQuestionnaireStringValues(commandMessage);

                } else {
                    commandMessage = '';
                }
                if (this.alterWorkingMessage) {
                    this.alterWorkingMessage(true, commandMessage);
                }
            } else if (action.queueAction === QueueActionType.RunWorkflow) {
                const workflowId = action.questionnaireId;
                const objectType = action.customFormType;
                let objectId = action.jobId;
                objectId = UtilitiesService.replaceValuesInStringFromObject(objectId, sourceObject);
                objectId = this.replaceQuestionnaireStringValues(objectId);

                this.taskApi.startWorkflow(workflowId, objectId, objectType);
            } else if (action.queueAction === QueueActionType.RESTEndpoint) {

                const loggedInUser = await this.entityApi.getLoggedInUser(false);
                let url = action.restEndpointUrl;
                url = UtilitiesService.replaceValuesInStringFromObject(url, sourceObject);
                url = UtilitiesService.replaceAll(url, '{{currentUser}}', loggedInUser.id);
                url = this.replaceQuestionnaireStringValues(url);

                let body = action.restEndpointBody;
                if (body) {

                    body = UtilitiesService.replaceValuesInStringFromObject(body, sourceObject, null, true);

                    body = this.replaceQuestionnaireStringValues(body);

                    body = UtilitiesService.replaceAll(body, '{{currentUser}}', loggedInUser.id);
                    body = UtilitiesService.replaceAll(body, '{{now}}', new Date().toISOString());
                    // I'm not sure what the character is, but it is causing error when it is embedded in JSON
                    body = UtilitiesService.replaceAll(body, '	', '');

                    body = JSON.parse(body);

                }

                const restResult = await this.serviceApi.executeRESTEndpoint(url, body, action.restEndpointAction);
                this.restResults[action.label] = restResult;

                if (restResult && restResult[0]) {
                    this.lastRestResult = restResult[0];
                    if (context) {
                        context.lastRestResult = this.lastRestResult;
                    }
                }


            } else if (action.queueAction === QueueActionType.RefreshQueue) {
                this.refreshSelectedQueue();
            } else if (action.queueAction === QueueActionType.SlyDialMessage) {
                let recipient = UtilitiesService.replaceValuesInStringFromObject(action.messageRecipient, sourceObject);
                let messageId = UtilitiesService.replaceValuesInStringFromObject(action.restEndpointUrl, sourceObject);

                recipient = this.replaceQuestionnaireStringValues(recipient);
                messageId = this.replaceQuestionnaireStringValues(messageId);

                const slyBroadcastMessage = new SlyBroadcastMessage();
                slyBroadcastMessage.audioRecordingName = messageId;
                slyBroadcastMessage.phoneNumbers = recipient.split(',');
                await this.entityApi.sendSlyBroadcast(slyBroadcastMessage);
            } else if (action.queueAction === QueueActionType.SendEmailNotification) {
                const emailArgs = new EmailArgs();
                emailArgs.useSignature = true;
                emailArgs.toAddresses = [];
                emailArgs.ccAddresses = [];
                emailArgs.bccAddresses = [];

                if (workOrderSummary) {
                    emailArgs.policyId = workOrderSummary.policyId;
                    emailArgs.workOrderId = workOrderSummary.id;
                }
                if (policySummary) {
                    emailArgs.policyId = policySummary.id;
                }
                if (entitySummary) {
                    emailArgs.entityId = entitySummary.id;
                }
                if (taskSummary) {
                    emailArgs.policyId = taskSummary.policyId;
                    emailArgs.taskId = taskSummary.id;
                    emailArgs.workOrderId = taskSummary.workOrderId;
                }
                if (action.templateId) {
                    const subjectAndMessage = await this.templateApi.getComposedSubjectAndMessage(action.templateId);
                    action.messageBody = subjectAndMessage.message;
                    if (subjectAndMessage.subject && !action.messageSubject) {
                        action.messageSubject = subjectAndMessage.subject;
                    }
                }
                if (invoiceSummary) {
                    emailArgs.policyId = invoiceSummary.policyId;
                }

                emailArgs.message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                emailArgs.subject = UtilitiesService.replaceValuesInStringFromObject(action.messageSubject, sourceObject);

                emailArgs.message = this.replaceQuestionnaireStringValues(emailArgs.message);
                emailArgs.subject = this.replaceQuestionnaireStringValues(emailArgs.subject);


                let recipient = UtilitiesService.replaceValuesInStringFromObject(action.messageRecipient, sourceObject);

                recipient = this.replaceQuestionnaireStringValues(recipient);

                if (recipient) {
                    const recipients = recipient.split(',');
                    recipients.forEach(r => {
                        if (UtilitiesService.validateEmail(r)) {
                            emailArgs.toAddresses.push(new EmailAddress(r));
                        }
                    });
                }
                const ccRecipientString = UtilitiesService.replaceValuesInStringFromObject(action.messageCCRecipient, sourceObject);

                if (ccRecipientString) {
                    const recipients = ccRecipientString.split(',');
                    recipients.forEach(r => {
                        if (UtilitiesService.validateEmail(r)) {
                            emailArgs.ccAddresses.push(new EmailAddress(r));
                        }
                    });
                }

                const bccRecipientString = UtilitiesService.replaceValuesInStringFromObject(action.messageBCCRecipient, sourceObject);
                if (bccRecipientString) {
                    const recipients = bccRecipientString.split(',');
                    recipients.forEach(r => {
                        if (UtilitiesService.validateEmail(r)) {
                            emailArgs.bccAddresses.push(new EmailAddress(r));
                        }
                    });
                }

                if (action.attachments) {
                    emailArgs.attachments = [];
                    for (const actionAttachment of action.attachments) {
                        const emailAttachment = new EmailAttachment();

                        emailAttachment.fileName = UtilitiesService.replaceValuesInStringFromObject(actionAttachment.name, sourceObject);
                        emailAttachment.fileName = this.replaceQuestionnaireStringValues(emailAttachment.fileName);

                        emailAttachment.fileUrl = UtilitiesService.replaceValuesInStringFromObject(actionAttachment.url, sourceObject);
                        emailAttachment.fileUrl = this.replaceQuestionnaireStringValues(emailAttachment.fileUrl);
                        emailArgs.attachments.push(emailAttachment);
                    }
                }

                let fromAddress = action.fromEmail;
                if (fromAddress) {
                    fromAddress = UtilitiesService.replaceValuesInStringFromObject(fromAddress, sourceObject);
                    fromAddress = this.replaceQuestionnaireStringValues(fromAddress);
                }

                if (action.sendAutomatically) {
                    await this.documentApi.sendHtmlDocument(emailArgs.toAddresses ? emailArgs.toAddresses.map(i => i.email).join(';') : null,
                        emailArgs.subject, emailArgs.message,
                        emailArgs.workOrderId, null, emailArgs.policyId,
                        true,
                        emailArgs.ccAddresses ? emailArgs.ccAddresses.map(i => i.email).join(';') : null,
                        emailArgs.attachments, emailArgs.entityId, fromAddress, bccRecipientString);
                } else {

                    const ref = this.dialog.open(EmailComposeModalComponent, { data: emailArgs });
                    ref.afterClosed().subscribe(result => {
                        if (result) {
                            this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary, policySummary,
                                entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                                removeFromQueue, bindingObject, refreshObjectCallback, context);
                        }
                    });

                    return;
                }
            } else if (action.queueAction === QueueActionType.SendSMSNotification) {
                const args = new SmsComposeArgs();
                if (workOrderSummary) {
                    args.workOrderId = workOrderSummary.id;
                    args.policyId = workOrderSummary.policyId;
                }
                if (policySummary) {
                    args.policyId = policySummary.id;
                }
                if (entitySummary) {
                    args.entityId = entitySummary.id;
                }
                if (taskSummary) {
                    args.policyId = taskSummary.policyId;
                    args.workOrderId = taskSummary.workOrderId;
                    args.taskId = taskSummary.id;
                }
                if (invoiceSummary) {
                    args.policyId = invoiceSummary.policyId;
                }

                if (purchaseOrderSummary) {
                    args.workOrderId = purchaseOrderSummary.workOrderId;
                }

                if (contractorInvoiceSummary) {
                    args.workOrderId = contractorInvoiceSummary.workOrderId;
                }

                args.message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                args.to = UtilitiesService.replaceValuesInStringFromObject(action.messageRecipient, sourceObject);


                args.message = this.replaceQuestionnaireStringValues(args.message);
                args.to = this.replaceQuestionnaireStringValues(args.to);

                if (action.sendAutomatically) {
                    const promises: Promise<any>[] = [];
                    const recipientsString = args.to.split(',');
                    for (const recipient of recipientsString) {

                        if (UtilitiesService.validatePhoneNumber(recipient)) {
                            args.to = recipient;
                            promises.push(this.emailApi.sendSMSMessage(args));
                        }
                    }

                    await Promise.all(promises);
                } else {
                    const ref = this.dialog.open(SmsComposeModalComponent, { data: args });
                    ref.afterClosed().subscribe(result => {
                        if (result) {
                            this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary,
                                policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary,
                                invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                        }
                    });
                    return;
                }
            } else if (action.queueAction === QueueActionType.CreateConnectTask) {
                let contactFlowId = action.messageRecipient;
                let name = action.messageSubject;
                let description = action.messageBody;
                let url = action.restEndpointUrl;

                name = UtilitiesService.replaceValuesInStringFromObject(name, sourceObject);
                name = this.replaceQuestionnaireStringValues(name);

                description = UtilitiesService.replaceValuesInStringFromObject(description, sourceObject);
                description = this.replaceQuestionnaireStringValues(description);

                url = UtilitiesService.replaceValuesInStringFromObject(url, sourceObject);
                url = this.replaceQuestionnaireStringValues(url);

                await this.apiService.postSingleDotNet(`AmazonConnect/create-task`, {
                    name,
                    description,
                    contactFlowId,
                    actions: [url],
                });

            }
            else if (action.queueAction === QueueActionType.AutoPhoneCall) {
                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                let phoneNumber = UtilitiesService.replaceValuesInStringFromObject(action.messageRecipient, sourceObject);
                message = this.replaceQuestionnaireStringValues(message);
                phoneNumber = this.replaceQuestionnaireStringValues(phoneNumber);
                this.emailApi.sendAutoPhoneCall(phoneNumber, message);

            } else if (action.queueAction === QueueActionType.ShowConfirmation) {

                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                let subject = UtilitiesService.replaceValuesInStringFromObject(action.messageSubject, sourceObject);


                subject = this.replaceQuestionnaireStringValues(subject);
                message = this.replaceQuestionnaireStringValues(message);

                this.dialogService.confirm(subject, message).subscribe(results => {
                    if (results) {
                        this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary,
                            policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary,
                            invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                    }
                });
                return;
            } else if (action.queueAction === QueueActionType.ShowAlertDialog) {
                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                let subject = UtilitiesService.replaceValuesInStringFromObject(action.messageSubject, sourceObject);


                subject = this.replaceQuestionnaireStringValues(subject);
                message = this.replaceQuestionnaireStringValues(message);

                this.dialogService.alert(subject, message).subscribe(() => {
                    this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary,
                        policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary,
                        invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                });
                return;
            } else if (action.queueAction === QueueActionType.ShowToastMessage) {
                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);

                message = this.replaceQuestionnaireStringValues(message);
                this.missionService.showToast(message);
            } else if (action.queueAction === QueueActionType.ShowToastSuccessMessage) {
                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);

                message = this.replaceQuestionnaireStringValues(message);
                if (!message || message === 'undefined') {
                    message = '';
                }
                this.missionService.showSuccessToast(message);
            } else if (action.queueAction === QueueActionType.RefreshAllQueues) {
                await this.refreshQueues();
            } else if (action.queueAction === QueueActionType.CreateANote) {
                const note = new NoteClient();

                let message = UtilitiesService.replaceValuesInStringFromObject(action.messageBody, sourceObject);
                let subject = UtilitiesService.replaceValuesInStringFromObject(action.messageSubject, sourceObject);

                subject = this.replaceQuestionnaireStringValues(subject);
                message = this.replaceQuestionnaireStringValues(message);

                note.id = UtilitiesService.newid();
                if (workOrderLineSummary) {
                    note.workOrderLineId = workOrderLineSummary.id;
                }
                if (workOrderSummary) {
                    note.policyId = workOrderSummary.policyId;
                    note.workOrderId = workOrderSummary.id;
                }
                if (policySummary) {
                    note.policyId = policySummary.id;
                }
                if (entitySummary) {
                    note.entityId = entitySummary.id;
                }
                if (taskSummary) {
                    note.taskId = taskSummary.id;
                    note.policyId = taskSummary.policyId;
                    note.workOrderId = taskSummary.workOrderId;
                }
                if (invoiceSummary) {
                    note.policyId = invoiceSummary.policyId;
                }
                if (purchaseOrderSummary) {
                    note.workOrderId = purchaseOrderSummary.workOrderId;
                }
                if (contractorInvoiceSummary) {
                    note.workOrderId = contractorInvoiceSummary.workOrderId;
                }
                if (bindingObject) {
                    if (bindingObject && bindingObject.workOrderId) {
                        note.workOrderId = bindingObject.workOrderId;
                    }
                }
                note.subject = subject;
                note.noteText = message;

                await this.noteApi.saveNote(note);
            } else if (action.queueAction === QueueActionType.NavigateToUrl) {
                let url = action.restEndpointUrl;
                url = UtilitiesService.replaceValuesInStringFromObject(url, sourceObject);

                url = this.replaceQuestionnaireStringValues(url);

                if (action.restEndpointAction === 'BLANK' || url.indexOf('http://') > -1 || url.indexOf('https://') > -1) {
                    window.open(url, '_blank');
                } else {
                    this.router.navigateByUrl(url);
                }

            } else if (action.queueAction === QueueActionType.Delay && action.delayAmount) {
                setTimeout(() => {
                    this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary, policySummary,
                        entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                        removeFromQueue, bindingObject, refreshObjectCallback, context);
                }, action.delayAmount);
                return;
            } else if (action.queueAction === QueueActionType.Undo) {
                let message = action.messageBody;
                message = UtilitiesService.replaceValuesInStringFromObject(message, sourceObject);
                message = this.replaceQuestionnaireStringValues(message);
                this.missionService.showUndo(new UndoArguments(() => {
                    const selectedCommand = this.queueQuery.commands.filter(i => i.id === action.commandToRunId)[0];
                    if (selectedCommand) {
                        this.doCommand(selectedCommand.actions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary,
                            taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                            removeFromQueue, bindingObject, refreshObjectCallback, context);
                    }
                }, message));
            } else if (action.queueAction === QueueActionType.ShowSMSQuestionnaire) {
                let message = action.messageBody;
                message = UtilitiesService.replaceValuesInStringFromObject(message, sourceObject);
                message = this.replaceQuestionnaireStringValues(message);

                let recipient = action.messageRecipient;
                recipient = UtilitiesService.replaceValuesInStringFromObject(recipient, sourceObject);
                recipient = this.replaceQuestionnaireStringValues(recipient);
                let workOrderId: string = null;
                let policyId: string = null;
                if (workOrderSummary != null) {
                    workOrderId = workOrderSummary.id;
                    policyId = workOrderSummary.policyId;
                }
                if (policySummary != null) {
                    policyId = policySummary.id;
                }
                if (taskSummary != null) {
                    policyId = taskSummary.policyId;
                    workOrderId = taskSummary.workOrderId;
                }
                if (contractorInvoiceSummary != null) {
                    policyId = contractorInvoiceSummary.policyId;
                    workOrderId = contractorInvoiceSummary.workOrderId;
                }
                if (invoiceSummary != null) {
                    policyId = invoiceSummary.policyId;
                }

                await this.apiService.postSingleDotNet(`SMSQuestionnaire/start-conversation`, null, {
                    questionnaireId: action.questionnaireId,
                    to: recipient,
                    completeMessage: message,
                    workOrderId,
                    policyId
                });
            } else if (action.queueAction === QueueActionType.ShowQuestionnaire) {
                const dialogRef = this.dialog.open(TakeQuestionnaireComponent, {
                    data: {
                        workOrderSummary,
                        action,
                        policySummary,
                        taskSummary,
                        contractorInvoiceSummary,
                        purchaseOrderSummary,
                        invoiceSummary,
                    },
                });
                dialogRef.afterClosed().subscribe(results => {
                    if (results) {

                        let subject = action.messageSubject;
                        let message = action.messageBody;

                        subject = UtilitiesService.replaceValuesInStringFromObject(subject, sourceObject);
                        message = UtilitiesService.replaceValuesInStringFromObject(message, sourceObject);

                        const replacementObject = {};

                        results.filter(i => i.text && i.answer).forEach(item => {
                            replacementObject[item.text] = item.answer;
                        });

                        this.questionnaires[action.questionnaireId] = replacementObject;

                        subject = this.replaceQuestionnaireStringValues(subject);
                        message = this.replaceQuestionnaireStringValues(message);


                        if (action.questionnaireCommands) {
                            for (const command of action.questionnaireCommands.filter(i => i.evaluationExpression && i.commandId)) {


                                try {
                                    const evalResult = TestClass.testExpression({ questions: replacementObject, workOrderSummary, policySummary, entitySummary, taskSummary, contractorInvoiceSummary, purchaseOrderSummary, invoiceSummary }, command.evaluationExpression);
                                    if (evalResult) {
                                        let commandToRun = this.queueQuery.commands.filter(i => i.id === command.commandId)[0];
                                        if (!commandToRun && this.queueQuery.defaultCommand && this.queueQuery.defaultCommand.id === command.commandId) {
                                            commandToRun = this.queueQuery.defaultCommand;
                                        }
                                        if (commandToRun) {

                                            this.doCommand(commandToRun.actions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary,
                                                taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                                                removeFromQueue, bindingObject, refreshObjectCallback, context);
                                            return;
                                        } else {
                                            console.error('couldn\'t find command to run');
                                            console.error(this.queueQuery.commands);
                                            console.error(command.commandId);
                                        }
                                    }
                                } catch (e) {
                                    console.error('error evaluating expression:');
                                    console.error(command.evaluationExpression);
                                    console.error(e);
                                }
                            }
                        }

                        this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary,
                            policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary,
                            invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                    }
                });
                return;
            } else if (action.queueAction === QueueActionType.SendPushNotification) {
                let message = action.messageBody;
                message = UtilitiesService.replaceValuesInStringFromObject(message, sourceObject);
                message = this.replaceQuestionnaireStringValues(message);

                let url = action.restEndpointUrl;
                url = UtilitiesService.replaceValuesInStringFromObject(url, sourceObject);
                url = this.replaceQuestionnaireStringValues(url);
                if (!action.selectedEmployees) {
                    action.selectedEmployees = [];
                }
                const employeesToSend = action.selectedEmployees.slice();

                let employees = action.messageRecipient;
                if (employees) {
                    employees = UtilitiesService.replaceValuesInStringFromObject(employees, sourceObject);
                    employees = this.replaceQuestionnaireStringValues(employees);
                    const employeesArray = employees.split(',');
                    for (const employeeId of employeesArray) {
                        employeesToSend.push(new SearchResultModel('', employeeId));
                    }
                }
                if (employeesToSend) {
                    for (const employee of employeesToSend) {
                        const notification = new UserNotification(UtilitiesService.newid(), employee.id, null, message, url);
                        await this.entityApi.sendPushNotification(employee.id, notification);
                    }
                }
                if (action.selectedRoles && action.selectedRoles.length > 0) {
                    const notification = new UserNotification(UtilitiesService.newid(), null, null, message, url);
                    for (const role of action.selectedRoles) {
                        await this.entityApi.sendPushNotificationToRoles(role, notification);
                    }
                }
            } else if (action.queueAction === QueueActionType.SendAppPushNotification) {
                let recipientString = action.messageRecipient;
                if (recipientString) {
                    recipientString = UtilitiesService.replaceValuesInStringFromObject(recipientString, sourceObject);
                    recipientString = this.replaceQuestionnaireStringValues(recipientString);
                    const recipientList = recipientString.split(',');
                    let message = action.messageBody;
                    message = UtilitiesService.replaceValuesInStringFromObject(message, sourceObject);
                    message = this.replaceQuestionnaireStringValues(message);
                    let title = action.messageSubject;
                    title = UtilitiesService.replaceValuesInStringFromObject(title, sourceObject);
                    title = this.replaceQuestionnaireStringValues(title);

                    const promises = [];
                    for (const recipient of recipientList) {
                        promises.push(this.entityApi.sendAppPushNotification(recipient, title, message));
                    }

                    await Promise.all(promises);
                }
            } else if (action.queueAction === QueueActionType.ShowForm) {
                const formDefaults: FormAnswerDefault[] = JSON.parse(JSON.stringify(action.formDefaults ? action.formDefaults : []));

                for (const formDefault of formDefaults) {
                    formDefault.defaultValue = UtilitiesService.replaceValuesInStringFromObject(formDefault.defaultValue, sourceObject);
                    formDefault.defaultValue = this.replaceQuestionnaireStringValues(formDefault.defaultValue);

                    if (formDefault.defaultValue && formDefault.defaultValue.replace) {
                        formDefault.defaultValue = formDefault.defaultValue.replace(/{{now}}/, new Date().toLocaleDateString());
                        formDefault.defaultValue = formDefault.defaultValue.replace(/{{[a-zA-Z0-9_-]*}}/, '');
                    }
                }
                const config: any = {
                    data: {
                        workOrderSummary,
                        action,
                        policySummary,
                        taskSummary,
                        formDefaults,
                        contractorInvoiceSummary,
                        purchaseOrderSummary,
                        invoiceSummary,
                        bindingObject,
                    },
                };

                if (window.innerWidth < 700) {
                    config.maxWidth = '100vw';
                    config.maxHeight = '100vh';
                    config.height = '100%';
                    config.width = '100%';
                    // config.panelClass = ['full-screen-modal'];
                    config.panelClass = 'full-screen-modal';
                }

                const dialogRef = this.dialog.open(FillOutFormComponent, config);
                dialogRef.afterClosed().subscribe((results: FormItem[]) => {
                    if (results) {
                        const replacementObject = {};
                        const questionnaireReplacementObject = { type: 'CUSTOMFORM', formItems: results.filter(i => i.isQuestion) };
                        for (const formItem of results) {
                            if (formItem.type === FormItemType.PreferredTimeSlot && formItem.answer) {
                                formItem.answer = JSON.stringify(formItem.answer);
                            }
                        }

                        results.filter(i => i.prompt && i.answer).forEach(item => {
                            replacementObject[item.id] = item.answer;
                        });

                        this.questionnaires[action.customFormId] = questionnaireReplacementObject;


                        if (action.questionnaireCommands) {
                            for (const command of action.questionnaireCommands.filter(i => i.evaluationExpression && i.commandId)) {


                                try {
                                    const evalResult = TestClass.testExpression({ formItems: replacementObject, workOrderSummary, policySummary, entitySummary, taskSummary, contractorInvoiceSummary, purchaseOrderSummary, invoiceSummary }, command.evaluationExpression);
                                    if (evalResult) {
                                        let commandToRun = this.queueQuery.commands.filter(i => i.id === command.commandId)[0];
                                        if (!commandToRun && this.queueQuery.defaultCommand && this.queueQuery.defaultCommand.id === command.commandId) {
                                            commandToRun = this.queueQuery.defaultCommand;
                                        }
                                        if (commandToRun) {
                                            this.doCommand(commandToRun.actions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary,
                                                taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                                                removeFromQueue, bindingObject, refreshObjectCallback, context);
                                            return;
                                        } else {
                                            console.error('couldn\'t find command to run');
                                            console.error(this.queueQuery.commands);
                                            console.error(command.commandId);
                                        }
                                    }
                                } catch (e) {
                                    console.error('error evaluating expression:');
                                    console.error(command.evaluationExpression);
                                    console.error(e);
                                }
                            }
                        }
                        this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary,
                            policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary,
                            invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                    }
                });
                return;

            } else if (action.queueAction === QueueActionType.ShowCustomComponentModal) {
                let type: Type<any> = null;
                if (action.customFormType !== 'dynamic') {
                    type = await this.lazyComponentProvider.getDynamicComponent(action.commandToRunId);
                } else {
                    throw "Error: dynamicComponentGenerator";
                    // type = (await this.dynamicComponentGenerator.generateDynamicComponentFromId(action.commandToRunId, null)).componentType;
                }
                const result = this.dialog.open(type, {
                    data: {
                        workOrderSummary,
                        action,
                        policySummary,
                        taskSummary,
                        contractorInvoiceSummary,
                        purchaseOrderSummary,
                        invoiceSummary,
                        bindingObject,
                        workOrder,
                        workOrderLineSummary,
                        queueObjects
                    },
                });
                result.afterClosed().subscribe(dialogResult => {
                    if (dialogResult) {
                        this.doCommand(actions.slice(actions.indexOf(action) + 1, actions.length), workOrderLineSummary, workOrder, workOrderSummary,
                            policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary,
                            invoiceSummary, queueObjects, null, queueType, removeFromQueue, bindingObject, refreshObjectCallback, context);
                    }
                });
                return;
            } else if (action.queueAction === QueueActionType.RunCommand) {
                if (this.queueQuery.commands) {
                    const selectedCommand = this.queueQuery.commands.filter(i => i.id === action.commandToRunId)[0];
                    if (selectedCommand) {
                        if (action.conditionalExpression) {

                            try {
                                const replacementObject = {};
                                // let formItems: FormItem[] = [];
                                for (const key of Object.keys(this.questionnaires)) {
                                    const questionnaire = this.questionnaires[key];

                                    if (questionnaire.formItems && questionnaire.formItems.length) {
                                        questionnaire.formItems.filter(i => i.prompt && i.answer).forEach(item => {
                                            replacementObject[item.id] = item.answer;
                                        });
                                    }
                                }
                                const evalResult = TestClass.testExpression({
                                    questionnaires: this.questionnaires, formItems: replacementObject, workOrderSummary, restResults: this.restResults,
                                    policySummary, entitySummary, taskSummary, contractorInvoiceSummary, purchaseOrderSummary, invoiceSummary, bindingObject,
                                    restResult: this.lastRestResult, context
                                }, action.conditionalExpression);
                                if (evalResult) {
                                    this.doCommand(selectedCommand.actions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary,
                                        taskSummary, contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType,
                                        removeFromQueue, bindingObject, refreshObjectCallback, context);
                                }
                            } catch (e) {
                                this.missionService.raiseError('error running expression');
                                console.error(e);
                            }
                        } else {
                            this.doCommand(selectedCommand.actions, workOrderLineSummary, workOrder, workOrderSummary, policySummary, entitySummary, taskSummary,
                                contractorInvoiceSummary, moveLane, purchaseOrderSummary, invoiceSummary, queueObjects, null, queueType, removeFromQueue,
                                bindingObject, refreshObjectCallback, context);
                        }
                    }
                }
            } else if (action.queueAction === QueueActionType.DialPhoneNumber) {
                let phoneNumber = UtilitiesService.replaceValuesInStringFromObject(action.messageRecipient, sourceObject);
                phoneNumber = this.replaceQuestionnaireStringValues(phoneNumber);

                if (UtilitiesService.validatePhoneNumber(phoneNumber)) {
                    this.missionService.dialPhoneNumber(phoneNumber);
                }
            } else if (action.queueAction === QueueActionType.Delete) {
                let recordType = '';
                if (workOrderSummary) {
                    recordType = 'WorkOrder';
                } else if (entitySummary) {
                    recordType = 'Entity';
                } else if (taskSummary) {
                    recordType = 'Task';
                } else if (contractorInvoiceSummary) {
                    recordType = 'ContractorInvoice';
                } else if (policySummary) {
                    recordType = 'Policy';
                } else if (purchaseOrderSummary) {
                    recordType = 'PurchaseOrder';
                } else if (invoiceSummary) {
                    recordType = 'Invoice';
                }

                await this.apiService.deleteDotNet(`${recordType}/${sourceObject.id}`);

            } else if (action.queueAction === QueueActionType.UnDelete) {
                let recordType = '';
                if (workOrderSummary) {
                    recordType = 'WorkOrder';
                } else if (entitySummary) {
                    recordType = 'Entity';
                } else if (taskSummary) {
                    recordType = 'Task';
                } else if (contractorInvoiceSummary) {
                    recordType = 'ContractorInvoice';
                } else if (policySummary) {
                    recordType = 'Policy';
                } else if (purchaseOrderSummary) {
                    recordType = 'PurchaseOrder';
                } else if (invoiceSummary) {
                    recordType = 'Invoice';
                }

                await this.apiService.unDeleteDotNet(`${recordType}/${sourceObject.id}`);
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class CommandRunnerService {
    constructor(private serviceApi: ServiceApiService, private entityApi: EntityApiService, private taskApi: TaskApiService,
        private documentApi: DocumentApiService, private dialog: MatDialog, private emailApi: EmailApiService, private dialogService: DialogsService,
        private missionService: MissionService, private noteApi: NoteApiService, private router: Router, private policyApi: PolicyApiService,
        private templateApi: TemplateApiService, private accountingApi: AccountingApiService, private apiService: ApiService,
        private settingsApi: SettingsApiService, private purchasingApi: PurchasingApiService, private environmentalVariables: EnvironmentVariablesService,
        private lazyComponentProviderService: LazyComponentProviderService) { }

    startDoCommand(actions: QueueAction[], workOrderLineSummary: WorkOrderLineSummaryClient, workOrder: WorkOrderSummaryClient, workOrderSummary: WorkOrderSummaryClient = null, queueQuery: QueueQuery,
        refreshQueueAction: () => void, refreshAllQueuesAction: () => void, alterWorkingMessage: (show: boolean, message: string) => void, policySummary: PolicySummary,
        entitySummary: EntitySummary, taskSummary: TaskSummaryClient, contractorInvoiceSummary: ContractorInvoiceSummaryClient, moveLane: (objectToMove) => void, purchaseOrder: PurchaseOrderSummaryClient,
        invoiceSummary: InvoiceSummary, queueObjects: any[] = null, queueType: string = null, removeForQueue: () => void = null, bindingObject: any = null,
        refreshObjectCallback: (updatedObject) => void = null): CommandRunnerInstance {


        const commandRunnerInstance = new CommandRunnerInstance(this.serviceApi, this.entityApi, this.taskApi, this.documentApi,
            this.dialog, this.emailApi, this.dialogService, this.missionService, this.noteApi, this.router, this.policyApi, this.templateApi, this.accountingApi,
            this.apiService, this.settingsApi, this.purchasingApi, this.environmentalVariables, this.lazyComponentProviderService);

        commandRunnerInstance.startDoCommand(actions, workOrderLineSummary, workOrder, workOrderSummary, queueQuery, refreshQueueAction,
            refreshAllQueuesAction, alterWorkingMessage, policySummary, entitySummary, taskSummary, contractorInvoiceSummary, moveLane, purchaseOrder,
            invoiceSummary, queueObjects, queueType, removeForQueue, bindingObject, refreshObjectCallback);

        return commandRunnerInstance;
    }
    //([FromQuery] Guid objectCommandId, [FromQuery]Guid commandId, [FromQuery]Guid objectId, [FromQuery]string objectType)
    runObjectCommandServerSide(objectCommandId: string, commandId: string, objectId: string, objectType: string) {
        return this.apiService.getSingleDotNet(`AnonymousClaimDetail/execute-object-command`, { objectCommandId, commandId, objectId, objectType });
    }
}
