import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class EntityReportDefinition {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() reportKey: string;
    @column() name: string;
    @column() reportParameters: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;

    get reportObject() {
        if (!this.reportParameters) {
            return null;
        }
        return JSON.parse(this.reportParameters);
    }
}