import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoHeightDirective, AutoMinHeightDirective, AutoHeightNoOverflowDirective, AutoHeightFixedDirective } from './height.directive';


@NgModule({
    declarations: [AutoHeightDirective, AutoHeightNoOverflowDirective, AutoMinHeightDirective, AutoHeightFixedDirective],
    exports: [AutoHeightDirective, AutoHeightNoOverflowDirective, AutoMinHeightDirective, AutoHeightFixedDirective],
    imports: [
        CommonModule
    ]
})
export class HeightDirectivesModule { }
