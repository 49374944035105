import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { Address } from "./address.model";

@dataSource()
export class WebQuote {
    @keyColumn() id: string;
    @column() name: string;
    @column() addressId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() selectedPlanId: string;
    @column() email: string;
    @column() promotionCode: string;
    @column() basePrice: number;
    @column() basePriceRecurring: number;
    @column() phone: string;
    @column() quoteDetails: string;
    @column() source: string;
    @column() queryParameters: string;
    @column() coverageType: string;

    address: Address;

    private _planSelection: any[];
    get planSelection() {
        if (!this._planSelection && this.quoteDetails) {
            try {
                this._planSelection = JSON.parse(this.quoteDetails);
            } catch { }
        }

        return this._planSelection;
    }
}