import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Permission {
    @keyColumn() id: string;
    @column() name: string;
    @column() routes: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() widgets: string;
    @column() functionalPermissions: string;

    private widgetObjectPrivate: any;
    private functionalPermissionsPrivate: any;

    get widgetObject(): string[] {
        if (!this.widgetObjectPrivate && this.widgets) {

            try {

                this.widgetObjectPrivate = JSON.parse(this.widgets);
            } catch (e) { }
        }
        if (!this.widgetObjectPrivate) {
            this.widgetObjectPrivate = [];
        }

        return this.widgetObjectPrivate;
    }
    set widgetObject(value: string[]) {
        this.widgetObjectPrivate = value;
    }

    get functionalPermissionsObject(): string[] {
        if (!this.functionalPermissionsPrivate && this.functionalPermissions) {
            try {
                this.functionalPermissionsPrivate = JSON.parse(this.functionalPermissions);
            } catch (e) { }
        }
        if (!this.functionalPermissionsPrivate) {
            this.functionalPermissionsPrivate = [];
        }

        return this.functionalPermissionsPrivate;
    }
    set functionalPermissionsObject(value: string[]) {
        this.functionalPermissionsPrivate = value;
    }
}