import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { TaskMessage, UserNotification } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class UserMessageApiService {
    constructor(private api: ApiService) { }

    getTaskMessage(messageId: string): Promise<TaskMessage> {
        return this.api.getSingleDotNet('TaskMessage/' + messageId + '/message', null, () => new TaskMessage());
    }

    reply(messageId: string, replyMessage: string, replyToAddress: string = null) {
        return this.api.postVoidDotNet('TaskMessage/' + messageId + '/reply', {
            message: replyMessage,
            replyToAddress
        });
    }

    getTwilioNumbers() {
        return this.api.getArrayNode(`users/sms/list-phone-numbers`);
    }

    getRelatedMessages(messageId: string): Promise<TaskMessage[]> {
        return this.api.getArrayDotNet('TaskMessage', {
            rootMessageId_eq: messageId,
            orderBy: 'CreatedDate descending'
        }, () => new TaskMessage());
    }

    getUserNotifications(startDate: Date, endDate: Date, userId: string): Promise<UserNotification[]> {
        return this.api.getArrayDotNet(`UserNotification`, {
            userId_eq: userId,
            createdDate_gte: startDate,
            createdDate_lte: endDate,
            orderBy: 'CreatedDate',
        }, () => new UserNotification());
    }

    getMonitoredEmailAddress(): Promise<string> {
        return this.api.getSingleDotNet(`document/get-monitored-email-address`);
    }
}