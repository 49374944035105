import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorJobNeedingFollowUp {
    @column() contractorName: string;
    @column() email: string;
    @column() status: string;
    @column() line: string;
    @column() itemName: string;
    @column() homeownerName: string;
    @column() propertyAddress: string;
    @column() workOrderId: string;
    @column() workOrderNumber: bigint;
    @column() contractorId: string;
    @column() employeeEmail: string;
    @column() employeeName: string;
}

export class ContractorJobNeedingFollowUpContractorGroup {
    contractorId: string;
    contractorName: string;
    email: string;
    items: ContractorJobNeedingFollowUpStatusGroup[];
    employeeName: string;
    employeeEmail: string;
}

export class ContractorJobNeedingFollowUpStatusGroup {
    status: string;
    items: ContractorJobNeedingFollowUp[]
}
