import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import {
    MatDialog,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
} from '@angular/material/dialog';
import { StripeApiService } from '@cogent/client/shared/services/api/stripe-api.service';
import {PaymentMethodEntryComponent} from "../payment-method-entry/payment-method-entry/payment-method-entry.component";
import {StripeCard} from "@upkeeplabs/models/cogent";
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
    selector: 'app-manage-multiple-payment-methods',
    templateUrl: './manage-multiple-payment-methods.component.html',
    styleUrl: './manage-multiple-payment-methods.component.css'
})
export class ManageMultiplePaymentMethodsComponent implements OnInit {
    results = 'testing'
    refreshingCards = false
    selectedCard: StripeCard

    existingCards: StripeCard[];
    saving = false;
    addingNewCard: boolean

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private stripeApi: StripeApiService,
        private missionService: MissionService,
        private dialogRef: MatDialogRef<ManageMultiplePaymentMethodsComponent>
    ) {}

    @ViewChild('paymentMethodEntry') paymentMethodEntry: PaymentMethodEntryComponent;
    ngOnInit() {
        this.selectedCard = this.data.stripeCards.find(existingCard => {
            if (existingCard.id != this.data.deletingCardId) {
                return existingCard
            }
        })
    }

    addingNewCardEvent(event: boolean) {
        this.addingNewCard = event
    }

    get canSubmit(): boolean {
        return this.data.deletingCardId && this.data.customerId && this.data.selectedCard?.id && this.data.deletingCardId != this.data.selectedCard?.id
    }

    cancel() {
        this.dialogRef.close()
    }

    replaceCard() {
        this.saving = true
        if (this.canSubmit) {
            this.stripeApi.nodeDeleteStripeCard(this.data.stripeId, this.data.deletingCardId, this.data.selectedCard.id).then(response => {
                if (response !== true) {
                    this.missionService.showWarningToast(response?.message);
                } else {
                    this.missionService.showSuccessToast('Default card changed')
                    this.dialogRef.close()
                }
            })
        } else {
            return this.missionService.showWarningToast('Insufficient Information')
        }
        this.saving = false
    }

}
