import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource() export class StripeCardInfo {
    @keyColumn() id: string;
    @column() stripeCardId: string;
    @column() stripeCustomerId: string;
    @column() brand: string;
    @column() expirationMonth: number;
    @column() expirationYear: number;
    @column() last4: number;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
}