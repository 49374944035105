<div style="padding-top: 60px;" class="container fancy-container">


    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">

            <div class="padding">
                <div style="max-width: 400px; width: 100%; display: table; margin: 0 auto;">
                    <h1 bottommargin30 style="text-align: center;"
                        *ngIf="!(noMatch || selectedIndex > 0 || (appliancePartsToShow && appliancePartsToShow.length))">
                        Order Parts</h1>

                    <div style="max-width: 600px;display: table;margin:0 auto;" class="center">

                        <app-gears></app-gears>
                    </div>
                    <app-appliance-part-search [(selectedItem)]="foundPart" (noMatchChange)="setFirstNotMatch($event)"
                        [(noMatch)]="noMatch" (selectedItemChange)="selectedItemChange($event)">
                    </app-appliance-part-search>

                    <div *ngIf="noMatch" class="fade-in-up center">


                        <svg id="Layer_1" data-name="Layer 1" style="height: 200px;" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 581.26713 562.5475">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: none;
                                        stroke: rgb(150, 150, 150);
                                        stroke-linecap: round;
                                        stroke-miterlimit: 10;
                                        stroke-width: 22px;
                                    }

                                    @keyframes fadeInFrown {
                                        0% {
                                            opacity: 0;
                                        }

                                        70% {
                                            opacity: 0;
                                        }

                                        100% {
                                            opacity: 1;
                                        }
                                    }

                                    .frown-fade {
                                        animation: fadeInFrown 2s ease-in-out;
                                    }

                                    .mag-frown {
                                        fill: rgb(150, 150, 150);
                                    }
                                </style>
                            </defs>
                            <path
                                d="M813.65527,258a164.00021,164.00021,0,1,1-115.96545,48.03455A162.92576,162.92576,0,0,1,813.65527,258m0-34c-109.35241,0-198,88.64761-198,198s88.64759,198,198,198,198-88.64758,198-198-88.64758-198-198-198Z"
                                transform="translate(-615.65527 -224)" class="mag-frown" />
                            <rect x="956.61276" y="548.24605" width="26" height="66"
                                transform="translate(-724.00675 697.81761) rotate(-48.47448)" class="mag-frown" />
                            <rect x="1053.75114" y="560.61005" width="69" height="250.04956" rx="18.95455"
                                transform="translate(-762.17186 821.82133) rotate(-48.47448)" class="mag-frown" />
                            <g class="frown-fade">
                                <g>
                                    <circle cx="147.69655" cy="167.79216" r="23.79216" class="mag-frown" />
                                    <circle cx="248.30342" cy="167.79216" r="23.79216" class="mag-frown" />
                                </g>
                                <path class="mag-frown cls-1"
                                    d="M734.96577,475.76c23.21542-9.62392,51.89581-15.31049,82.93484-15.31049,27.35559,0,52.87913,4.417,74.44413,12.05351"
                                    transform="translate(-615.65527 -224)" />
                            </g>
                        </svg>
                        <h1>No Match</h1>
                        <p>Sorry, we couldn't find a part or model that matched your search</p>

                        <button mat-button mat-raised-button (click)="addCustom()">Add A Part</button>
                    </div>

                </div>
                <div *ngIf="appliancePartsToShow && appliancePartsToShow.length > 0">



                    <ul class="parts-list" topmargin40>
                        <li *ngFor="let part of appliancePartsToShow">
                            <div aflex>
                                <div nogrow>
                                    <img [src]="part.imageUrl">
                                    <img *ngIf="!part.imageUrl" src="../../../assets/images/no-image.png">
                                </div>
                                <div>
                                    <h2>{{part.partNumber}}</h2>
                                    <div>
                                        {{part.description}}
                                    </div>
                                    <!-- <div *ngIf="!part.editMode">
                                    {{part.cost | currency}} x {{part.quantity}} = {{part.subtotal | currency}}
                                </div> -->
                                    <div *ngIf="part.editMode">

                                        <div aflex>
                                            <!-- <mat-form-field appearance="outline">
                                            <mat-label>Unit Price</mat-label>
                                            <input matInput type="number" [(ngModel)]="part.cost">
                                        </mat-form-field> -->

                                            <mat-form-field appearance="outline" style="max-width: 150px">
                                                <mat-label>Qty</mat-label>
                                                <mat-select [(ngModel)]="part.quantity" (ngModelChange)="updatePartQuantity(part)">
                                                    <mat-option *ngFor="let qty of quantities" [value]="qty">{{qty}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                                <!-- <div nogrow style="padding-top: 12px;padding-bottom: 10px;">
                                <div class="data-label">
                                    Who Will Provide
                                </div>
                                <div class="slide-toggle-container">
                                    <span class="toggle-side" [class.selected]="!part.companyProvides"
                                        (click)="part.companyProvides = false">Contractor</span>
                                    <span class="toggle-side" [class.selected]="part.companyProvides"
                                        (click)="part.companyProvides = true">Elevate</span>
                                </div>
                            </div> -->
                                <div nogrow>
                                    <button *ngIf="!part.dateOrdered" mat-button (click)="deletePart(part)">
                                        <mat-icon>delete</mat-icon>
                                    </button><br>
                                    <button *ngIf="!part.dateOrdered" mat-button class="toggle-button"
                                        [class.selected]="part.editMode" (click)="part.editMode = !part.editMode">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Second">

            <div class="center" topmargin30 *ngIf="loadingPartDetail">
                <mat-spinner></mat-spinner>
            </div>
            <div class="center" *ngIf="selectedPart">

                <mat-tab-group class="hide-tabs" [(selectedIndex)]="imageSelectedIndex">

                    <mat-tab *ngFor="let image of selectedPart.images">
                        <div class="center">
                            <a [href]="image" target="_blank">
                                <img [src]="image" style="max-height: 200px">
                            </a>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="center img-selector-btns">
                    <button *ngFor="let img of selectedPart.images; let i = index" class="toggle-button"
                        [class.selected]="i === imageSelectedIndex" (click)="imageSelectedIndex = i"
                        mat-button></button>
                </div>
                <!-- <img *ngIf="selectedPart.images && selectedPart.images.length > 0" [src]="selectedPart.images[0]"
                    style="max-height: 200px;"> -->
                <h1 topmargin30>{{selectedPart.productNumber}}</h1>
                <p>{{selectedPart.description}}</p>
                <div aflex>
                    <div>
                        <div class="data-label">Manufacturer</div>
                        {{selectedPart.manufacturer}}
                    </div>
                    <!-- <div>
                        <div class="data-label">Price</div>
                        {{selectedPart.partnerPrice | currency}}
                    </div> -->
                </div>

            </div>

            <table class="table" *ngIf="!loadingPartDetail">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th class="right">Quantity</th>
                        <!-- <th class="right">Price</th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of partDetail">
                        <tr>
                            <td>
                                <button class="vendor-expand" mat-button (click)="item.expanded = !item.expanded">
                                    <mat-icon class="rotate" [class.rotated]="item.expanded">chevron_right</mat-icon>
                                    {{item.vendor}}

                                </button>
                            </td>
                            <td class="right">{{item.qtyAvailable}}</td>
                            <!-- <td class="right">{{item.totalPrice | currency}}</td> -->
                        </tr>

                        <ng-container *ngIf="item.expanded">
                            <tr *ngFor="let location of item.availability">
                                <td style="padding-left: 70px;">
                                    {{location.name}}
                                </td>
                                <td class="right">
                                    {{location.available}}
                                </td>
                                <!-- <td class="right">
                                    {{item.totalPrice | currency}}
                                </td> -->
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>

            <!-- <table class="table">
                    <thead>
                        <th class="center">Store</th>
                        <th class="center">Availability</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let warehouse of selectedPart.warehouses, let i = index">
                            <tr *ngIf="warehouse.warehouseCode && (showMore || i <= 4)">
                                <td>{{i}} - {{warehouse.description}}</td>
                                <td>
                                    <span *ngIf="warehouse.quantity">{{warehouse.quantity}}</span>
                                    <span *ngIf="!warehouse.quantity">Out of Stock</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <button mat-button block (click)="showMore = !showMore">
                    <mat-icon class="rotate" [class.rotated]="showMore">chevron_right</mat-icon>
                    <span *ngIf="showMore">Show Less</span>
                    <span *ngIf="!showMore">Show More</span>
                </button> -->





        </mat-tab>
        <mat-tab label="Third">
            <div class="center" *ngIf="!selectedModel">
                <mat-spinner style="display: inline-block;"></mat-spinner>
            </div>
            <ng-container *ngIf="selectedModel">

                <div class="container" style="overflow: hidden;">
                    <div class="navigation-features">
                        <div>
                            <div class="data-label">Model #</div>
                            <h2 class="sub-heading">{{selectedModel.manufacturer}} {{selectedModel.modelNumber}}</h2>

                        </div>
                    </div>
                </div>

                <div class="part-categories-container">
                    <button *ngFor="let partCategory of selectedModel.diagrams" mat-button
                        class="part-category-button toggle-button" style="text-transform: none;"
                        [class.selected]="selectedPartCategory == partCategory" (click)="showCategory(partCategory)">
                        <img src="{{partCategory.urlDiagram}}"><br>
                        {{partCategory.diagramName}}</button>
                </div>
                <div *ngIf="selectedPartCategory">


                    <!-- <div class="center">
                        <button *ngIf="!loadingPartDetail" mat-button mat-raised-button
                            (click)="viewDiagram = !viewDiagram">
                            <mat-icon>image</mat-icon>
                            <div>
                                View Diagram</div>
                        </button>

                        <mat-spinner *ngIf="loadingPartDetail"></mat-spinner>
                    </div> -->
                    <div *ngIf="diagramDetails">
                        <div class="diagram-viewer-container ">
                            <img [src]="selectedPartCategory.urlDiagram" usemap="#assemblyMap"
                                [class.fit-height]="diagramSizeMode === 'height'"
                                [class.fit-width]="diagramSizeMode === 'width'">
                            <map name="assemblyMap" *ngIf="hotspot">
                                <area class="part" href="#" (click)="spotClicked(spot, $event)"
                                    *ngFor="let spot of hotspot" shape="rect"
                                    coords="{{spot.TopLeft.X}},{{spot.TopLeft.Y}},{{spot.BottomRight.X}},{{spot.BottomRight.Y}}">
                            </map>
                        </div>
                        <div class="padding">
                            <button mat-button mobile-block mat-raised-button (click)="showAllParts = !showAllParts">
                                <mat-icon class="rotate" [class.rotated]="showAllParts">chevron_right</mat-icon> All
                                Parts
                            </button>
                        </div>
                        <div class=" part-category-parts-container fade-in-up" *ngIf="showAllParts" topmargin40>
                            <button mat-button class="toggle-button" style="position: relative"
                                [class.selected]="part.selected" (click)="toggleSelectedPart(part)"
                                *ngFor="let part of diagramDetails; let i = index">
                                <span class="badge part-number" *ngIf="!useEncompass">{{i + 1}}</span>
                                <span class="badge part-number" *ngIf="useEncompass">{{part.tag}}</span>
                                <div class="center">
                                    <img *ngIf="part.imageUrls && part.imageUrls.length > 0" [src]="part.imageUrls[0]"
                                        class="part-image">
                                    <img *ngIf="!part.imageUrls || part.imageUrls.length === 0 " class="part-image"
                                        src="../../../assets/images/no-image.png">
                                    <div>
                                        <h2 style="white-space: normal;">{{part.productNumber}}</h2>
                                        <p style="white-space: normal;line-height: normal;">{{part.description}}</p>
                                        <div aflex>
                                            <div flex1>
                                                <mat-form-field appearance="outline" class="part-qty"
                                                    *ngIf="part.selected">
                                                    <mat-label>Qty</mat-label>
                                                    <mat-select [(ngModel)]="part.quantity"
                                                        (click)="cancelEvent($event)">
                                                        <mat-option *ngFor="let qty of quantities" [value]="qty">{{qty}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div flex1>

                                                <span *ngIf="part.state === 'In Stock'" class="in-stock">In Stock</span>
                                                <span *ngIf="!part.state || part.state !== 'In Stock'"
                                                    class="out-of-stock">{{part.state}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!loadingPartDetail" class="padding">
                        <h2 class="sub-heading" topmargin40>Part Not Found?</h2>
                        <button mat-button mat-raised-button (click)="addCustom()">Add A Part</button>
                    </div>
                </div>
                <div class="diagram-viewer" *ngIf="viewDiagram">
                    <div aflex>
                        <div nogrow> <button mat-button class="close-detail-button" (click)="viewDiagram = false">
                                <mat-icon>close</mat-icon>
                            </button></div>
                        <div style="overflow: auto;height: calc(100vh - 53px)">
                            <!-- <mat-form-field appearance="outline" style="max-width: 150px; transform: translateY(10px)">
                                <mat-select [(ngModel)]="diagramSizeMode" (ngModelChange)="diagramSizeChanged($event)">
                                    <mat-option value="height">Fit Height</mat-option>
                                    <mat-option value="width">Fit Width</mat-option>
                                    <mat-option value="actual">Actual Size</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <!-- <div class="diagram-viewer-container ">
                                <img [src]="selectedPartCategory.urlDiagram" usemap="#assemblyMap"
                                    [class.fit-height]="diagramSizeMode === 'height'"
                                    [class.fit-width]="diagramSizeMode === 'width'">
                                <map name="assemblyMap" *ngIf="hotspot">
                                    <area class="part" alt="Does this work??????" href="#" (click)="spotClicked(spot, $event)"  *ngFor="let spot of hotspot" shape="rect" coords="{{spot.TopLeft.X}},{{spot.TopLeft.Y}},{{spot.BottomRight.X}},{{spot.BottomRight.Y}}">
                                </map>
                            </div> -->
                        </div>
                    </div>
                </div>
            </ng-container>
        </mat-tab>
        <mat-tab>
            <div class="padding">
                <div>
                    <mat-form-field appearance="outline" style="max-width: 200px;">
                        <mat-label>Manufacturer</mat-label>
                        <mat-select [(ngModel)]="newPart.manufacturer">
                            <mat-option *ngFor="let brand of brands" [value]="brand.name">{{brand.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" style="max-width: 300px;">
                        <mat-label>Part Number</mat-label>
                        <input matInput [(ngModel)]="newPart.partNumber">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" style="max-width: 300px;">
                        <mat-label>Model Number</mat-label>
                        <input matInput [(ngModel)]="newPart.modelNumber">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <input matInput [(ngModel)]="newPart.description">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" style="max-width: 150px;">
                        <mat-label>Cost</mat-label>
                        <input matInput type="number" [(ngModel)]="newPart.cost">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" style="max-width: 100px;">
                        <mat-label>Qty</mat-label>
                        <mat-select [(ngModel)]="newPart.quantity">
                            <mat-option *ngFor="let qty of quantities" [value]="qty">{{qty}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="footer" *ngIf="workOrderLine">
    <div class="container">
        <div aflex>
            <div>
                <a *ngIf="selectedIndex === 0" mat-button routerLink="/work-order-detail/{{workOrderLine.workOrderId}}">
                    <mat-icon>chevron_left</mat-icon> Back To Job
                </a>
            </div>
            <div nogrow *ngIf="selectedModel && selectedIndex === 2">
                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button class="add-model-parts-btn" [disabled]="!partsSelected"
                    (click)="addItemsToRepair()" color="primary">
                    <mat-spinner *ngIf="addingItemsToRepair" class="thirty"></mat-spinner>
                    Add Parts
                    <span class="badge">{{selectedPartCount}}</span>
                </button>
            </div>
            <div nogrow *ngIf="selectedIndex === 0">
                <!-- <button mat-button mat-raised-button color="primary" (click)="save()" [disabled]="!canSave">
                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                    Save
                </button> -->
            </div>

            <div nogrow *ngIf="selectedIndex === 1">
                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button [disabled]="addingSingleToRepair" color="primary"
                    (click)="addSinglePartToRepair()">
                    <mat-spinner *ngIf="addingSingleToRepair" class="thirty"></mat-spinner>
                    Add
                    To Repair
                </button>
            </div>
            <div nogrow *ngIf="selectedIndex === 3">
                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mat-raised-button color="primary" [disabled]="!canAddNew || addingNewToRepair"
                    (click)="addNewToRepair()">
                    <mat-spinner *ngIf="addingNewToRepair" class="thirty"></mat-spinner>
                    Add To Repair
                </button>
            </div>
        </div>
    </div>
</div>