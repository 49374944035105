import { PolicyHistoryItem } from "@upkeeplabs/models/cogent";
import { NoteAttachment } from "@cogent/client/shared/models/common/note-client.model";

export class PolicyHistoryItemClient extends PolicyHistoryItem {
    // client-side only
    playingAudio: boolean;
    recordingEndpoint: string;
    attachments: NoteAttachment[]
    showReply: boolean;
    newNoteText: string;
    public description: string;
    children: PolicyHistoryItem[];

    get isHomeInspection() {
        return this.subType === 'Home_Inspection';
    }
    set isHomeInspection(value: boolean) {
        if(value) {
            this.subType = 'Home_Inspection';
        } else {
            delete this.subType;
        }
    }
}

export class GroupedPolicyHistoryItem {
    group: string;
    historyItems: PolicyHistoryItem[];
    expanded = true;
    collapsing = false;
    expanding = false;

    private static datesEqual(date1: Date, date2: Date) {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }

    private static getGroup(historyItem: PolicyHistoryItem): string {
        if (historyItem.pinned) {
            return 'Pinned';
        }
        if (!historyItem.statusDate) {
            return 'Unknown';
        }

        let dateToCheck = new Date();

        if (this.datesEqual(historyItem.statusDate, dateToCheck)) {
            return 'Today';
        }

        dateToCheck.setDate(dateToCheck.getDate() - 1);

        if (this.datesEqual(historyItem.statusDate, dateToCheck)) {
            return 'Yesterday';
        }

        const week = new Date(dateToCheck);
        while (week.getDay() > 0) {
            week.setDate(week.getDate() - 1);
        }

        if (historyItem.statusDate >= week) {
            return 'This week';
        }

        week.setDate(week.getDate() - 7);
        if (historyItem.statusDate >= week) {
            return 'Last week';
        }

        if (historyItem.statusDate.getMonth() === new Date().getMonth()
            && historyItem.statusDate.getFullYear() === new Date().getFullYear()) {
            return 'This Month';
        }

        dateToCheck = new Date();
        dateToCheck.setMonth(new Date().getMonth() - 1);

        if (historyItem.statusDate.getMonth() === dateToCheck.getMonth()
            && historyItem.statusDate.getFullYear() === dateToCheck.getFullYear()) {
            return 'Last Month';
        }

        if (historyItem.statusDate.getFullYear() === new Date().getFullYear()) {
            return 'This Year';
        }

        dateToCheck = new Date();
        dateToCheck.setFullYear(dateToCheck.getFullYear() - 1);
        if (historyItem.statusDate.getFullYear() === dateToCheck.getFullYear()) {
            return 'Last Year';
        }

        return 'Older';
    }

    public static fromHistoryItems(historyItems: PolicyHistoryItem[]):
        GroupedPolicyHistoryItem[] {
        let groupedHistoryItems: GroupedPolicyHistoryItem[] = [];


        historyItems.forEach(historyItem => {
            const group = GroupedPolicyHistoryItem.getGroup(historyItem);

            let groupedHistoryItem =
                groupedHistoryItems.filter(i => i.group === group)[0];
            if (!groupedHistoryItem) {
                groupedHistoryItem = new GroupedPolicyHistoryItem();
                groupedHistoryItem.group = group;
                groupedHistoryItem.historyItems = [];
                groupedHistoryItem.expanded = true;

                groupedHistoryItems.push(groupedHistoryItem);
            }

            if (!historyItem.parentNoteId)
                groupedHistoryItem.historyItems.push(historyItem);
        });

        groupedHistoryItems = groupedHistoryItems.filter(i => i.historyItems && i.historyItems.length > 0);

        groupedHistoryItems = groupedHistoryItems.sort((a, b) => a.historyItems[0].statusDate > b.historyItems[0].statusDate ? -1 : 1);

        const pinnedGroup = groupedHistoryItems.find(i => i.group === 'Pinned');
        if (pinnedGroup) {
            groupedHistoryItems.splice(groupedHistoryItems.indexOf(pinnedGroup), 1);
            groupedHistoryItems.unshift(pinnedGroup);
        }
        groupedHistoryItems.forEach(item => {
            item.historyItems = item.historyItems.sort(
                (a, b) => a.statusDate > b.statusDate ? -1 : 1);
        });
        return groupedHistoryItems;
    }

    toggleGroup() {
        this.expanding = false;
        this.collapsing = false;



        if (this.expanded) {
            this.collapsing = true;
            setTimeout(() => {
                this.expanded = false;
                this.collapsing = false;
            }, 300);
        } else {
            this.expanding = true;
            this.expanded = true;
            setTimeout(() => {
                this.expanded = true;
                this.expanding = false;
            }, 300);
        }
    }
}