export class SMSConversationSummary {
    id: string;
    fromNumber: string;
    entityId: string;
    correspondent: string;
    lastMessageDate: Date;
    lastMessageText: string;
    numberName: string;
    internalSMSNumber: string;
    externalSMSNumber:string;
    isArchived: boolean;
    hide: boolean = true;
}