import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ObjectQueue {
    @keyColumn() id: string;
    @column() name: string;
    @column() query: string;
    @column() groupBy: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() description: string;
    @column() roles: string;
    @column() grouping: string;
    @column() sort: bigint;
    @column() availableToAllRoles: boolean;
    @column() queueType: string;
    @column() hideFromNavigator: boolean;
}