import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ClientMenuItem {
    @keyColumn() id: string;
    @column() url: string;
    @column() title: string;
    @column() iconClass: string;
    @column() deletedDate?: Date;
    // @column() version: timestamp;
    @column() description: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;

    // Client side Only
    parentKey: string;
}