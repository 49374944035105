import { LazyComponentProviderService } from '@cogent/client/shared/services/lazy-component-provider.service';

export function setupLazyComponents() {

    LazyComponentProviderService.componentFactory = async (key) => {
        if (key === 'appointment-scheduler') {
            await import('../../../../../../../apps/src/client/shared/components/service/appointment-scheduler/appointment-scheduler.component');
            const { AppointmentSchedulerComponent } = await import('../../../../../../../apps/src/client/shared/components/service/appointment-scheduler/appointment-scheduler.component');
            return AppointmentSchedulerComponent;
        }
    }
}
