
import { Directive, ElementRef, Input, AfterViewChecked, HostListener } from '@angular/core';

@Directive({ selector: '[appAutoHeight]' })
export class AutoHeightDirective implements AfterViewChecked {
    constructor(public el: ElementRef) {

    }

    ngAfterViewChecked() {
        this.extend();
    }

    @HostListener('window:resize') onWindowResize() {
        this.extend();
    }

    private extend() {
        if (this.el) {
            const rect = this.el.nativeElement.getBoundingClientRect();
            if (rect.width > 0) {

                const newHeight = window.innerHeight - rect.top - 5;

                this.el.nativeElement.style.height = newHeight + 'px';
                this.el.nativeElement.style.overflowY = 'auto';
            }
        }
    }
}

@Directive({ selector: '[appAutoHeightFixed]' })
export class AutoHeightFixedDirective implements AfterViewChecked {
    constructor(public el: ElementRef) {

    }

    ngAfterViewChecked() {
        this.extend();
    }

    @HostListener('window:resize') onWindowResize() {
        this.extend();
    }

    private extend() {
        if (this.el) {
            const rect = this.el.nativeElement.getBoundingClientRect();
            if (rect.width > 0) {

                const newHeight = window.innerHeight - rect.top - 5 - window.scrollY;

                this.el.nativeElement.style.height = newHeight + 'px';
                this.el.nativeElement.style.overflowY = 'auto';
            }
        }
    }
}

@Directive({ selector: '[appAutoHeightNoOverflow]' })
export class AutoHeightNoOverflowDirective implements AfterViewChecked {
    constructor(public el: ElementRef) {
        this.extend();
    }

    ngAfterViewChecked() {
        this.extend();
    }

    @HostListener('window:resize') onWindowResize() {
        this.extend();
    }

    private extend() {
        if (this.el) {
            const rect = this.el.nativeElement.getBoundingClientRect();
            if (rect.width > 0) {

                const newHeight = window.innerHeight - rect.top - 5;

                this.el.nativeElement.style.height = newHeight + 'px';
            }
        }
    }
}

@Directive({ selector: '[appMinAutoHeight]' })
export class AutoMinHeightDirective implements AfterViewChecked {
    constructor(public el: ElementRef) {

    }

    ngAfterViewChecked() {
        if (this.el) {
            const rect = this.el.nativeElement.getBoundingClientRect();
            if (rect.width > 0) {
                const newHeight = window.innerHeight - rect.top;

                this.el.nativeElement.style.minHeight = newHeight + 'px';
                this.el.nativeElement.style.overflowY = 'auto';
            }
        }
    }
}

