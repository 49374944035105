<div [style.height]="height" [style.width]="height">
    <svg style="max-height: 100vh;" id="bounce" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 620 570">
        <defs>
            <linearGradient id="linear-gradient" x1="44.375" y1="191.49997" x2="360.625" y2="191.49997"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#29abe2" />
                <stop offset="1" stop-color="#0071bc" />
            </linearGradient>
        </defs>
        <g>
            <polygon class="elevate-a" points="111.56 345.92 71.11 345.92 138.69 207.46 156.23 244.89 111.56 345.92" />
            <polygon class="elevate-a" points="178.98 199.89 161.44 237.07 143.9 199.89 161.44 163.37 178.98 199.89" />
            <polygon class="elevate-a" points="211.7 345.54 252.15 345.54 184.57 207.08 167.02 244.5 211.7 345.54" />
        </g>
        <path class="gear"
            d="M360.625,208.96136V174.03864l-16.629-4.16211c-3.65515-13.64118-4.49-16.7569-8.14514-30.39814l12.32-11.91888c-9.36664-16.22357-8.09467-14.02045-17.46137-30.244l-16.482,4.71c-9.98609-9.98608-12.267-12.2669-22.253-22.253l4.71-16.48211c-16.22351-9.36664-14.02039-8.09467-30.244-17.46137L254.52161,58.14911c-13.64124-3.65515-16.757-4.49-30.3982-8.1452l-4.162-16.62891H185.03864l-4.162,16.62891c-13.64124,3.65521-16.757,4.49005-30.3982,8.1452L138.55951,45.829c-16.22357,9.3667-14.02045,8.09473-30.244,17.46137l4.71,16.48211c-9.986,9.98609-12.2669,12.26691-22.253,22.253l-16.482-4.71c-9.3667,16.22357-8.09473,14.02045-17.46137,30.244l12.32,11.91888c-3.65515,13.64124-4.49,16.757-8.14514,30.39814l-16.629,4.16211v34.92272l16.629,4.162c3.65515,13.64124,4.49,16.757,8.14514,30.39814l-12.32,11.91894c9.36664,16.22357,8.09467,14.02039,17.46137,30.244l16.48205-4.71,22.253,22.253-4.71,16.48211c16.22351,9.3667,14.02039,8.09473,30.244,17.46137l11.91888-12.32,30.3982,8.14514,4.162,16.62891h34.92272l4.162-16.62891,30.3982-8.14514,11.91888,12.32c16.22357-9.36664,14.02045-8.09467,30.244-17.46137l-4.71-16.48211,22.253-22.253,16.482,4.71c9.3667-16.22357,8.09473-14.02039,17.46137-30.244l-12.32-11.91894c3.65515-13.64118,4.49-16.7569,8.14514-30.39814ZM202.5,288A96.5,96.5,0,1,1,299,191.5,96.5,96.5,0,0,1,202.5,288Z" />
        <path class="gear backward"
            d="M595.40036,412.781l1.95391-34.868-16.37006-5.086c-2.88621-13.82431-3.54541-16.98186-6.43162-30.80623l12.96756-11.21092c-8.44426-16.72222-7.29755-14.45138-15.74187-31.1736l-16.71977,3.78048c-9.41172-10.52915-11.56142-12.934-20.97309-23.46317l5.62475-16.19278c-15.674-10.25966-13.54553-8.86642-29.21963-19.12615L497.901,256.26857c-13.41537-4.41265-16.4795-5.4205-29.89486-9.83321L464.781,229.59964l-34.868-1.95391-5.08591,16.37c-13.82437,2.88626-16.98192,3.54547-30.80629,6.43168l-11.21092-12.96763c-16.72222,8.44433-14.45138,7.29762-31.17354,15.74189l3.78042,16.71981c-10.52909,9.41173-12.934,11.56137-23.46317,20.9731l-16.19272-5.62481c-10.25973,15.6741-8.86648,13.54559-19.12615,29.21969L308.26857,327.099c-4.41265,13.41537-5.4205,16.4795-9.83314,29.8948L281.59964,360.219l-1.95391,34.868,16.37006,5.08591c2.8862,13.82437,3.54541,16.98192,6.43162,30.80623l-12.96757,11.211c8.44427,16.72222,7.29756,14.45132,15.74188,31.17354l16.71977-3.78049,20.97309,23.46318-5.62475,16.19278c15.674,10.25972,13.54553,8.86648,29.21963,19.12615L379.099,516.73143c13.41537,4.41265,16.4795,5.4205,29.89486,9.83315L412.219,543.4003l34.868,1.95391,5.08591-16.37c13.82437-2.8862,16.98192-3.54541,30.80629-6.43162l11.21092,12.96756c16.72222-8.44426,14.45138-7.29755,31.17354-15.74188l-3.78042-16.71982,23.46317-20.97309,16.19272,5.62481c10.25973-15.6741,8.86648-13.54553,19.12614-29.21963L568.73143,445.901c4.41264-13.41531,5.4205-16.47944,9.83314-29.8948ZM433.10088,482.84884a96.5,96.5,0,1,1,101.748-90.94972A96.5,96.5,0,0,1,433.10088,482.84884Z" />

        <!-- <path d="M145.66,448.62c-2.28,0-4.5-.65-6.41-1.87l-27.43-17.59c-2.74-1.75-4.6-4.5-5.25-7.73-.64-3.18-.01-6.46,1.76-9.23l112.46-175.4c-17.96-25.7-18.41-60.77-.91-88.06,14.9-23.24,39.92-37.11,66.94-37.11,.77,0,1.55,.01,2.32,.04,1.26,.04,2.4,.75,2.99,1.87,.59,1.11,.53,2.46-.15,3.52l-29.5,46.01,12.97,41.08,42.75-5.36,29.5-46.01c.65-1.01,1.76-1.61,2.95-1.61,.06,0,.13,0,.19,0,1.26,.07,2.38,.81,2.94,1.94,12.25,24.66,10.41,54.71-4.79,78.41-14.9,23.25-39.93,37.12-66.93,37.12,0,0,0,0,0,0-4.53,0-9.05-.41-13.47-1.21l-112.46,175.4c-2.32,3.62-6.23,5.78-10.46,5.78Zm-7.7-35.81c-2.16,0-4.17,1.12-5.37,3.01-.93,1.45-1.26,3.15-.93,4.79,.32,1.6,1.23,2.95,2.56,3.8,.93,.59,2.01,.91,3.12,.91,2.16,0,4.17-1.12,5.37-3.01,.93-1.45,1.26-3.15,.93-4.79-.32-1.6-1.23-2.95-2.56-3.8-.93-.59-2-.91-3.12-.91Z" class="wrench"></path> -->

        <g style="transform: translate(200px, 160px);">
            <g>
                <path class="cls-1"
                    d="M114.15,255.75c-.48,0-.95-.12-1.37-.36L3.01,192.84V68.03c-.01-1.12,.59-2.15,1.56-2.7L113.75,3.11c.12-.07,.26-.1,.4-.1s.28,.04,.4,.11l109.84,62.59c.56,.32,.92,.92,.92,1.57v124.15c0,.87-.47,1.67-1.22,2.1l-108.56,61.86c-.42,.24-.89,.36-1.37,.36Z" />
                <path class="cls-4"
                    d="M114.15,6.33l108.15,61.63v123.12l-108.15,61.63L6,191.08V68.03s.02-.08,.06-.1L114.15,6.33m0-6.33c-.65,0-1.3,.17-1.88,.5L3.09,62.72c-1.91,1.09-3.09,3.11-3.09,5.31v124.79c0,1.09,.58,2.09,1.53,2.63l109.77,62.55c.88,.5,1.87,.76,2.85,.76s1.97-.25,2.85-.76l108.56-61.86c1.69-.96,2.74-2.76,2.74-4.71V67.27c0-1.73-.93-3.32-2.43-4.18L116.03,.5c-.58-.33-1.23-.5-1.88-.5h0Z" />
            </g>
            <polyline class="cls-2" points="4.3 68.09 112.65 129.52 223.3 66.55" />
            <line class="cls-3" x1="112.65" y1="129.52" x2="113.87" y2="253.55" />
            <path class="cls-2"
                d="M89.02,212.2L22.65,174.38c-.25-.14-.4-.41-.4-.69l.15-27.44c0-.61,.66-.99,1.2-.69l66.08,36.52c.25,.14,.41,.4,.41,.69l.15,28.73c0,.61-.67,1-1.21,.69Z" />
        </g>
    </svg>
</div>