<div id="measureContainer"></div>

<div showxs *ngIf="planGrid">
  <div *ngIf="!mobileCompareVisible">
    <!-- <button mat-button (click)="compareMobile()"><i class="material-icons">view_column</i> Compare</button> -->
    <mat-slide-toggle (ngModelChange)="updateMonthly($event)" [(ngModel)]="useMonthly">Pay Monthly
    </mat-slide-toggle>
    <div *ngFor="let planGridItem of planGrid.plans, let planIndex = index" style="position: relative">
      <div aflex>
        <div [style.background-color]="planGridItem.planColor" class="mobile-square left-square" flex1>
          <h2>{{planGridItem.name}}</h2>
          <p class="plan-description">{{planGridItem.summary}}</p>


          <!-- <div *ngIf="planIndex == 0" style="right: 0; top: 0" class="ribbon"><span>Best Value</span></div> -->

          <hr>
          <button mat-button (click)="planGridItem.itemsExpanded = !planGridItem.itemsExpanded">
            <i class="material-icons transform" [class.rotated]="planGridItem.itemsExpanded">keyboard_arrow_down</i>
            <span *ngIf="!planGridItem.itemsExpanded">More</span>
            <span *ngIf="planGridItem.itemsExpanded">Less</span>

          </button>

        </div>
        <div flex1 class="mobile-square right-square" [class.first-item]="planIndex == 0">
          <p>Plans Starting at</p>
          <h1 [class.monthly-small]="!useMonthly">{{planGridItem.priceRecurring + planGridItem.monthlyProcessingFee | currency:'USD':'symbol':'2.0-2'}}<span
              class="small">/month</span></h1>
          <p [class.yearly-big]="!useMonthly">or {{planGridItem.price | currency:'USD':'symbol':'3.0-2'}}<span class="small"> /year</span></p>
          <button mat-button mat-raised-button block [color]="planIndex == 0 ? 'primary' : ''"
            (click)="selectPlan(planGridItem)">Order</button>
        </div>
      </div>
      <div class="items-container" *ngIf="planGridItem.itemsExpanded">
        
          <a target="_blank" style="text-align: center" block mat-button mat-raised-button [href]="getSampleContractUrl(planGridItem)">Sample Contract</a>
          
        <h3>Standard Items</h3>
        <ul>
          <li *ngFor="let item of planGridItem.standardItems">
            {{item.name}}
          </li>
        </ul>

      </div>
    </div>
  </div>
  <div *ngIf="mobileCompareVisible">
    <button mat-button (click)="compareMobile()" pull-right><i class="material-icons">keyboard_arrow_left</i> Back
    </button>
    <h1 style="margin-top: 0">Compare Plans</h1>
    <button mat-button id="comparePrev" class="compare-prev-button" (click)="prevCompareSlide()"><i
        class="material-icons">keyboard_arrow_left</i></button>
    <button mat-button id="compareNext" class="compare-next-button" (click)="nextCompareSlide()"><i
        class="material-icons">keyboard_arrow_right</i></button>

    <div aflex id="compareContainer" class="compare-container" [style.transform]="mobileSlideTranslate">
      <div *ngFor="let planGridItem of selectedPlans, let planIndex = index" class="compare-column" nogrow
        style="border-left: 3px solid transparent" [style.border-left-color]="planGridItem.planColor">
        <h2 style="color: #fff;padding: 3px; display: block; text-align: center"
          [style.background-color]="planGridItem.planColor">{{planGridItem.name}}
          <a (click)="removePlanComparisonItem(planGridItem)" pull-right>X</a>
        </h2>
        <div style="text-align: center">
          <p style="margin: 0; margin-top: 10px">Plans Starting at</p>
          <h1 style="margin: 0">
            {{planGridItem.priceRecurring + planGridItem.monthlyProcessingFee | currency:'USD':'symbol':'2.0-2'}}<span
              class="small">/mo</span></h1>
          <p>or {{planGridItem.price | currency:'USD':'symbol':'3.0-2'}}<span class="small"> /yr</span></p>
        </div>
        <div class="data-label">Standard Items</div>
        <ul style="padding: 0">
          <li *ngFor="let item of planGridItem.standardItems" style="list-style: none">
            {{item.name}}
          </li>
        </ul>
        <div class="data-label" topmargin20 *ngIf="planGridItem.optionalItems && planGridItem.optionalItems.length">
          Optional Items</div>
        <ul style="padding: 0">
          <li *ngFor="let item of planGridItem.optionalItems" style="list-style: none">
            {{item.name}}
          </li>
        </ul>
      </div>
      <div class="compare-column" nogrow style="border-left: 1px dotted rgba(0,0,0,.3)">
        <h3 style="margin-top: 0">Select Plans to Compare</h3>
        <div *ngFor="let planGridItem of planGrid.plans">
          <mat-checkbox (change)="updateWidthsAndPerserveScroll()" [(ngModel)]="planGridItem.selected">
            {{planGridItem.name}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="planGrid" *ngIf="planGrid" hidexs>
  <div aflex>
    <div flex15 topmargin40>
      <mat-slide-toggle (ngModelChange)="updateMonthly($event)" [(ngModel)]="useMonthly">Show Monthly Prices
      </mat-slide-toggle>
    </div>
    <div class="plan-header" flex1 *ngFor="let planGridItem of planGrid.plans, let iii = index"
      [class.available-box-1]="iii == 0" [class.available-box-2]="iii == 1" [class.available-box-3]="iii == 2"
      [style.border-top-color]="planGridItem.planColor" style="border-top: 7px solid transparent" [class.highlighted]="highlightedPlan && planGridItem.id === highlightedPlan.id">
      <button mat-button (click)="selectPlan(planGridItem)">
        <div class="plan-header-info-container">
          <i class="material-icons selected-plan rubberBand" *ngIf="planGridItem == selectedPlan">check</i>
          <h2>{{planGridItem.name}}</h2>
          <p class="plan-description">{{planGridItem.summary}}</p>
          <h2 *ngIf="!useMonthly">{{planGridItem.price | currency:'USD':'symbol':'3.0-2'}}/<span
              class="data-label">yr</span></h2>
          <h2 *ngIf="useMonthly">
            {{planGridItem.priceRecurring + planGridItem.monthlyProcessingFee | currency:'USD':'symbol':'2.0-2'}}/<span
              class="data-label">mo</span></h2>
          <i class="material-icons">keyboard_arrow_down</i>
        </div>
      </button>

      <!-- <button mat-button style="height: unset"> <i class="material-icons">
          description
        </i>Sample Contract</button> -->
    </div>


  </div>
  <div aflex>
    <div flex15></div>
    <div class="plan-header1" flex1 *ngFor="let planGridItem of planGrid.plans, let iii = index"
      [class.available-box-1]="iii == 0" [class.available-box-2]="iii == 1" [class.available-box-3]="iii == 2"  [class.highlighted]="highlightedPlan && planGridItem.id === highlightedPlan.id">


      <button block mat-button [matMenuTriggerFor]="menu" style="height: unset"> <i class="material-icons">
          description
        </i>Sample Contract</button>

      <mat-menu #menu="matMenu">
        <a target="_blank" mat-menu-item [href]="getSampleContractUrl(planGridItem)">
          <div aflex>
            <div nogrow><i class="material-icons">
                cloud_download
              </i></div>
            <div>Download</div>
          </div>
        </a>

      </mat-menu>
    </div>
  </div>
  <div *ngFor="let category of planGrid.categories; let i=index">
    <div aflex>
      <div class="category" flex15>{{category.categoryName}}</div>
      <div flex1 *ngFor="let planGridItem of planGrid.plans, let iii = index" class="available-box"
        [class.available-box-1]="iii == 0" [class.available-box-2]="iii == 1" [class.available-box-3]="iii == 2" [class.highlighted]="iii === highlightIndex"
        class=" category" flex1></div>
    </div>
    <div *ngFor="let item of category.items; let ii=index" aflex>
      <div flex15 [class.pad-top]="ii == 0">{{item.salesItemName}} <button mat-button (click)="showCoverage(item)"><i
            style="opacity: .3" class="material-icons">search</i></button> </div>
      <div class="available-box" [class.available-box-1]="iii == 0" [class.available-box-2]="iii == 1"
        [class.available-box-3]="iii == 2" flex1 *ngFor="let available of item.planAvailability; let iii=index"
        [class.highlighted]="iii === highlightIndex"
        [class.pad-top]="ii == 0">
        <i *ngIf="available.available" class="material-icons not-selected-checkbox"
          [class.selected-checkbox]="available.plan == selectedPlan">check</i>
      </div>
    </div>
  </div>
  <div aflex>
    <div flex15 class="pad-top plan-footer">Service Fee</div>
    <div *ngFor="let plan of planGrid.plans, let iii = index" flex1 class="available-box pad-top plan-footer"
      [class.available-box-1]="iii == 0" [class.available-box-2]="iii == 1"  [class.highlighted]="iii === highlightIndex" [class.available-box-3]="iii == 2">
      {{plan.serviceFee| currency:'USD':'symbol':'2.0-2'}}
    </div>
  </div>

  <div aflex>
    <div flex15 style="padding: 10px">
      <mat-slide-toggle (ngModelChange)="updateMonthly($event)" [(ngModel)]="useMonthly">Show Monthly Prices
      </mat-slide-toggle>
    </div>
    <div class="plan-header" flex1 *ngFor="let planGridItem of planGrid.plans, let iii = index"
      [class.available-box-1]="iii == 0" [class.available-box-2]="iii == 1" [class.available-box-3]="iii == 2"
      [class.highlighted]="iii === highlightIndex"
      [style.border-bottom-color]="planGridItem.planColor" style="border-bottom: 7px solid transparent; padding: 10px;">
      <button mat-button mat-raised-button (click)="selectPlan(planGridItem)"
        [color]=" planGridItem == selectedPlan ? 'accent' : ''" style="line-height: 36px">
        Select <span *ngIf="!useMonthly">{{planGridItem.price | currency:'USD':'symbol':'3.0-2'}}/<span
            class="data-label">yr</span></span>
        <span
          *ngIf="useMonthly">{{planGridItem.priceRecurring + planGridItem.monthlyProcessingFee | currency:'USD':'symbol':'2.0-2'}}/<span
            class="data-label">mo</span></span>
      </button>
    </div>
  </div>
</div>