<!-- <div style="display:none;"><app-web-socket-handler></app-web-socket-handler></div> -->

<nav class="navbar navbar-default navbar-fixed-top main-menu"
    *ngIf="(isHome || isPolicyView || isContractorHome || isBilling) && !isLogin && !hideMenuBar && !isMaintContractor"
    [class.menu-animation]="isScrolled" style="z-index: 2">
    <div class="container">
        <div class="navbar-header">

            <button mat-button (click)="toggleSideNav()" id="nav-toggle-button" style="color: #fff">
                <i class="material-icons">menu</i>
            </button>
            <a [routerLink]="homeUrl" class="navbar-brand">
                <!-- <img *ngIf="!isScrolled" [src]="companyWhiteUrl" alt="Home Warranty" title="Home Warranty"> -->
                <img [class.white-logo]="!isScrolled" [src]="companyUrl" alt="Home Warranty" title="Home Warranty">
            </a>

            <button style="vertical-align: top;margin-top: 10px" routerLink="/incomplete-authos" mat-button
                *ngIf="incompleteCount">
                <mat-icon>gavel</mat-icon>
                <span class="badge">{{incompleteCount}}</span>
            </button>

        </div>
        <div class="navbar-collapse collapse" id="nav-main " *ngIf="!hideMenuOptions">
            <nav style="overflow: hidden">

                <ul *ngIf="menuItems && menuItems.length > 0" class="nav navbar-nav navbar-right">
                    <li>
                        <button mat-button [matMenuTriggerFor]="menu" style="min-width: 0">
                            <i class="material-icons" style="color:white;"
                                *ngIf="userNotifications && userNotifications.length">notifications</i>
                            <i class="material-icons" style="color:white;"
                                *ngIf="userNotifications && !userNotifications.length">notifications_none</i>
                            <span class="item-count rubberBand" style="color:white;"
                                *ngIf="userNotifications && userNotifications.length">
                                <span class="item-count-inner">
                                    {{userNotifications.length}}</span>
                            </span>
                        </button>

                        <mat-menu #menu="matMenu" class="user-notifications">
                            <div class="notification-item" [class.fadeOutDown]="notification.deleting"
                                *ngFor="let notification of userNotifications">
                                <div>
                                    <button mat-menu-item [routerLink]="'/' + notification.url">
                                        <mat-icon>notifications</mat-icon> {{notification.description}}
                                    </button>
                                </div>
                                <div>
                                    <button mat-button (click)="removeNotificationItem(notification, $event)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <button mat-menu-item (click)="clearNotifications()"
                                *ngIf="userNotifications && userNotifications.length > 0">
                                <mat-icon>delete</mat-icon> Clear All
                            </button>
                            <a mat-menu-item routerLink="/user-notifications">
                                <mat-icon>search</mat-icon> View Past Notifications
                            </a>

                        </mat-menu>
                    </li>
                    <li *ngFor="let menuItem of menuItems" [id]="menuItem.id" [class.active]="menuItem.active  
            || (menuItem.url && menuItem.url === currentRoute)">
                        <a class="page-scroll1" style="cursor: pointer" *ngIf="menuItem.action"
                            (click)="menuItem.action()"><span>{{menuItem.text}}</span></a>
                        <a class="page-scroll1" *ngIf="menuItem.url"
                            [routerLink]="menuItem.url"><span>{{menuItem.text}}</span></a>
                        <a class="page-scroll" style="cursor: pointer" *ngIf="menuItem.documentFragment"
                            (click)="scrollToFragment(menuItem.documentFragment, $event)"><span>{{menuItem.text}}</span></a>
                    </li>
                </ul>





            </nav>

        </div>

    </div>

</nav>
<div
    *ngIf="!isHome && !isLogin && !isPolicyView && !isContractorHome && !isBilling && !hideMenuBar && !isMaintContractor">
    <div id="appHeader" [class.scrolled]="isScrolledCalc">
        <button mat-button (click)="toggleSideNav()">
            <i class="material-icons">menu</i>
        </button>
        <a [routerLink]="homeUrl">
            <img [src]="companyUrl" id="companyLogo" *ngIf="!isHome">
            <img [src]="companyWhiteUrl" id="companyLogo" *ngIf="isHome">
        </a>

        <ng-container *ngIf="!hideMenuOptions">
            <button mat-button [matMenuTriggerFor]="menu" style="min-width: 0">
                <i class="material-icons" *ngIf="userNotifications && userNotifications.length">notifications</i>
                <i class="material-icons" *ngIf="userNotifications && !userNotifications.length">notifications_none</i>
                <span class="item-count rubberBand" *ngIf="userNotifications && userNotifications.length">
                    <span class="item-count-inner">
                        {{userNotifications.length}}</span>
                </span>
            </button>

            <mat-menu #menu="matMenu" class="user-notifications">
                <div class="notification-item" [class.fadeOutDown]="notification.deleting"
                    *ngFor="let notification of userNotifications">
                    <div>
                        <button mat-menu-item [routerLink]="'/' + notification.url">
                            <mat-icon>notifications</mat-icon> {{notification.description}}
                        </button>
                    </div>
                    <div>
                        <button mat-button (click)="removeNotificationItem(notification, $event)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>

                <button mat-menu-item (click)="clearNotifications()"
                    *ngIf="userNotifications && userNotifications.length > 0">
                    <mat-icon>delete</mat-icon> Clear All
                </button>
                <a mat-menu-item routerLink="/user-notifications">
                    <mat-icon>search</mat-icon> View Past Notifications
                </a>

            </mat-menu>
            <ul *ngIf="menuItems && menuItems.length > 0" class="app-menu-items">
                <li *ngFor="let menuItem of menuItems" class="menu-item" [class.active]="menuItem.active
      || (menuItem.url && menuItem.url === currentRoute)">
                    <a class="page-scroll1" style="cursor: pointer" *ngIf="menuItem.action"
                        (click)="menuItem.action()"><span>{{menuItem.text}}</span></a>
                    <a class="page-scroll1" *ngIf="menuItem.url"
                        [routerLink]="menuItem.url"><span>{{menuItem.text}}</span></a>
                    <a class="page-scroll" style="cursor: pointer" *ngIf="menuItem.documentFragment"
                        (click)="scrollToFragment(menuItem.documentFragment, $event)"><span>{{menuItem.text}}</span></a>

                </li>

            </ul>
        </ng-container>
        <button routerLink="/incomplete-authos" mat-button *ngIf="incompleteCount">
            <mat-icon>gavel</mat-icon>
            <span class="badge"
                style="position: absolute; font-size: 8px; bottom: 0;background-color: red;right: 12px;">{{incompleteCount}}</span>
        </button>
        <div class="header-search-container" *ngIf="isContractorPortal && !hideMenuOptions && isLoggedIn">
            <app-work-order-search></app-work-order-search>
        </div>
    </div>


</div>

<div id="side-nav" *ngIf="sideNavOpen">
    <h1>
        <mat-icon>menu</mat-icon> Options
    </h1>
    <div style="display: flex; flex-direction: column; height: calc(100% - 20px)">
        <ng-container *ngIf="!hideMenuOptions">
            <a *ngFor="let menuItem of menuItems" mat-button block (click)="doMenuItem(menuItem, $event)">
                {{menuItem.text}}
            </a>
        </ng-container>


        <!-- <div [class.show]="isHome" class="hide">
      <a mat-button block class="page-scroll" href="#overview" (click)="sideNavOpen = false"><span>Overview</span></a>
      <a mat-button block class="page-scroll" href="#properties" (click)="sideNavOpen = false"><span>Covered
          Properties</span></a>
      <a mat-button block class="page-scroll" href="#service-requests" (click)="sideNavOpen = false"><span>Service
          Requests</span></a>
      <a mat-button block class="page-scroll" href="#payments" (click)="sideNavOpen = false"><span>Payments</span></a>
      <a block class="page-scroll" href="#contact" (click)="sideNavOpen = false"><span>Contact Us</span></a>
      <a mat-button block class="page-scroll" href="/Login"><span><i class="fa fa-lock"></i> Log Out</span></a>
    </div> -->
    </div>

</div>

<div id="back-drop" (click)="sideNavOpen = false" *ngIf="sideNavOpen"></div>

<div id="app-content" [class.entering]="enteringAnimation" [class.maint-services]="isMaintContractor">
    <router-outlet></router-outlet>

    <div id="debug"></div>
</div>
<app-entity-communication [createNew]="createNewSMS" *ngIf="isMaintContractor"></app-entity-communication>
<div style="display: none;" *ngIf="isMaintContractor">
    <app-web-socket-handler></app-web-socket-handler>
</div>

<div *ngIf="allowMaintenanceServiceToggle" class="maint-service-toggle">
    <mat-slide-toggle [(ngModel)]="isMaintContractor"
        (ngModelChange)="updateIsMaintContactor($event)">
        <span *ngIf="!isMaintContractor">Maintenance Service View</span>
        <span *ngIf="isMaintContractor">Warranty View</span>

    </mat-slide-toggle>
</div>