import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneLinkComponent } from './phone-link.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [PhoneLinkComponent],
    exports: [PhoneLinkComponent],
    imports: [
        CommonModule,
        MatIconModule,
    ]
})
export class PhoneLinkModule { }
