import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceCustomerProperty {
    @keyColumn() id: string;
    @column() customerId: string;
    @column() propertyId: string;
    @column() status: string;
    @column() accessCodes: string;
    @column() notes: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() monthlyPaymentDate: number;   
    @column() stripeCardId: string;
    @column() creditCardExpirationDate: Date;
    @column() areaId: string; 
    @column() sendAppointmentReminders: boolean;
    @column() contractTemplateKey: string;
}