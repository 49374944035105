import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CashOutOffer {
    @keyColumn() id: string;
    @column() workOrderLineId: string;
    @column() cashOutId: string;
    @column() type: string;
    @column() amount: number;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() cashOutEmail: string;
    @column() comparableUrl: string;
    @column() nameOnCheck: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() rejectedDate: Date;
    @column() cashOutReasonId: string;
}

export class AcceptCashOutOfferArgs {
    cashOutOfferId: string;
    sendMethod: string;
}