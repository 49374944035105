import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
import { AuthorizationRepairItemGroup, RepairItemSummary, RepairType, WorkOrderItem, WorkOrderItemCause, WorkOrderItemLocation, WorkOrderItemProblem, WorkOrderItemType } from '@upkeeplabs/models/cogent';

export class WorkOrderItemClient extends WorkOrderItem {
    category: string;
    categorySort?: number;
    itemSort: number;
    notCovered: boolean;
    problems: WorkOrderItemProblem[];
    causes: WorkOrderItemCause[];
    installationFee: number;
    canBeReplaced: boolean;

    locations: WorkOrderItemLocation[];
    types: WorkOrderItemType[];
    deletedProblems: WorkOrderItemProblem[] = [];
    deletedLocations: WorkOrderItemLocation[] = [];
    deletedTypes: WorkOrderItemType[] = [];
    availableRepairItems: RepairItemSummary[];
    deletedCauses: WorkOrderItemCause[] = [];
    allowTimeSlotSelectionOverride: string;
    repairTypes: RepairType[];
    authorizationRepairItemGroups: AuthorizationRepairItemGroup[];
    // Client Side Only

    get pictureUrl() {
        return `${ApiEndpoints.endPointDotNet}WorkorderItem/${this.id}/Photo`;
    }

    // planItemId: string;
}

export class GroupedWorkOrderItem {

    summarizedWorkOrderItems: WorkOrderItemClient[];
    hasMoreItems: boolean;
    allItems: WorkOrderItemClient[];

    public static fromWorkOrderItems(workOrderItems: WorkOrderItemClient[]): GroupedWorkOrderItem[] {
        const results: GroupedWorkOrderItem[] = [];

        workOrderItems = workOrderItems.sort((a, b) => a.categorySort - b.categorySort);
        workOrderItems.forEach(workOrderItem => {
            let result = results.filter(i => i.category === workOrderItem.category)[0];
            if (!result) {
                result = new GroupedWorkOrderItem(workOrderItem.category,
                    workOrderItems.filter(i => i.category === workOrderItem.category)); //.sort((a, b) => a.itemSort - b.itemSort));

                results.push(result);
            }
        });

        for (const group of results) {
            // group.workOrderItems = group.workOrderItems.sort((a, b) => { 
            //     if (a.notCovered != b.notCovered){

            //     }
            //     return a.itemSort > b.itemSort ? 1 : -1;
            // });
        }

        return results;
    }

    constructor(public category: string, public workOrderItems: WorkOrderItemClient[]) {
        this.summarizedWorkOrderItems = [];
        this.allItems = workOrderItems;
        for (let i = 0; i < 4; i++) {
            if (workOrderItems[i]) {
                this.summarizedWorkOrderItems.push(workOrderItems[i]);
            }
        }

        this.hasMoreItems = workOrderItems.length > 4;
    }

    get iconUrl(): string {
        return UtilitiesService.getCategoryUrl(this.category);
    }
}

