<mat-dialog-content style="margin: 0">
    <div flex>
        <div>
            <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle  class="sub-heading">Send SMS Message</h2>
        </div>
        <div nogrow>
            <button tabindex="-1" mat-button [mat-dialog-close] cdkFocusInitial><i class="material-icons">
                    close
                </i></button>
        </div>
    </div>

    <button mat-button (click)="addRecipient()"><mat-icon>add</mat-icon> Add Recipient</button>
    <div *ngFor="let recipient of recipients" flex topmargin20>
        <div>
            <mat-form-field style="margin-top: -7px" appearance="outline">
                <mat-label>Mobile Number</mat-label>
                <input type="tel" matInput [(ngModel)]="recipient.phoneNumber"
                mask="(000) 000-0000">
            </mat-form-field>
        </div>
        <div nogrow>
            <button mat-button (click)="removeRecipient(recipient)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <mat-form-field appearance="outline">
        <mat-label>Message</mat-label>
        <textarea matInput rows="4" [(ngModel)]="args.message" ></textarea>
    </mat-form-field>

    <div flex>
        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button color="primary" (click)="send()" [disabled]="!canSend || sending">
                <mat-icon *ngIf="!sending">textsms</mat-icon>
                <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
                Send
            </button>
        </div>
    </div>
</mat-dialog-content>

