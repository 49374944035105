import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class JsonWebKey {
    @keyColumn() id: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() expires: Date;
    @column() kid: string;
    @column() x5t: string;
    @column() kty: string;
    @column() n: string;
    @column() e: string;
    @column() d: string;
    @column() p: string;
    @column() q: string;
    @column() dp: string;
    @column() dq: string;
    @column() qi: string;
    @column() use: string;
}