import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayTotalComponent } from './display-total.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [DisplayTotalComponent],
    imports: [
        CommonModule,
        MatIconModule,
    ],
    exports: [DisplayTotalComponent]
})
export class DisplayTotalModule { }
