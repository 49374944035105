import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceActivityItem {
    @keyColumn() id: string;
    @column() description: string;
    @column() createdDate: Date;
    @column() type: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() createdById: string;
    @column() createdByName: string;
    @column() number: number;
    @column() additionalContent: string;
    @column() additionalContent2: string;
    @column() parentNoteId: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    
    attachments: any[];
    playingAudio?: boolean;
    recordingEndpoint?:string;
    showReply?: boolean;
    newNoteText?:string;
    recordingLocation?:string;
    pinned?: boolean;
    children: MaintenanceServiceActivityItem[];
}