import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CommunicationBatchSummary {
    @keyColumn() id: string;
    @column() name: string;
    @column() configuration: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() sql: string;
    @column() sendSMS: boolean;
    @column() sendEmail: boolean;
    @column() runFunction: boolean;
}