<div id="global-search-backdrop" *ngIf="searchResultsOpen && !inline" (click)="searchResultsOpen = false;closeMenu();">

</div>
<div aflex style="position: relative; z-index: 2;">
    <div [class.white-label]="!darkLabel">
        <!-- <mat-icon id="global-search-search-icon" style="position: absolute; margin-top: 18px;padding-left: 3px;">search</mat-icon> -->
        <form>
            <mat-form-field id="searchContainer" appearance="outline" style="height: 50px;"
                (window:keydown)="handleKeyEvent($event)">
                <mat-label>
                    <mat-icon>search</mat-icon>
                    Search
                </mat-label>
                <input matInput name="search-box" #searchInput type="text" id="search-input" autocomplete="off"
                    [(ngModel)]="searchValue">

                <button mat-button mat-raised-button matSuffix style="height: 35px;margin-top: 5px; margin-bottom: 9px;"
                    color="primary" (click)="executeSearch()" [disabled]="!searchValue || searching">
                    <mat-icon style="display: inline;" *ngIf="!searching">search</mat-icon>
                    <mat-spinner *ngIf="searching" class="thirty"></mat-spinner>
                </button>

            </mat-form-field>
        </form>
    </div>
    <div nogrow>

    </div>
</div>

<div id="searchResultsPanel" [class.inline]="inline" *ngIf="searchValue">
    <!-- <div flex *ngIf="searching" padding>
        <mat-spinner nogrow class="thirty"></mat-spinner>
        <div flex>
            <h3>Searching...</h3>
        </div>
    </div> -->

    <a class="mat-list-item search-result" mat-button [routerLink]="result.url" style="cursor: pointer"
        *ngFor="let result of searchResults" (click)="closeMenu()" [class.active]="result == selectedItem"
        id="searchitem_{{result.id}}">
        <div aflex>
            <div nogrow style="padding-top: 16px">
                <i *ngIf="result.recordSubType !== 'Employee' 
            && result.recordSubType != 'Contractor' 
            && result.recordType !== 'Policy'
            && result.recordType !== 'WorkOrder'" class="material-icons" mat-list-avatar>{{result.icon}}</i>
                <img mat-list-avatar
                    *ngIf="result.recordSubType === 'Employee' || result.recordSubType === 'Contractor'"
                    [src]="getEntityUrl(result)">
                <img mat-list-avatar *ngIf="result.recordType === 'Policy'" [src]="getAddressUrl(result)">
                <img mat-list-avatar *ngIf="result.recordType === 'WorkOrder'" [src]="getWorkOrderItemUrl(result)">
            </div>
            <div>
                <h3 matLine class="list-line">{{result.title}} <span
                        *ngIf="result.recordSubType && result.recordType !== 'WorkOrder'"> -
                        {{result.recordSubType}}</span></h3>
                <p matLine class="list-line">{{result.description}}</p>
                <p matLine *ngIf="result.status" class="list-line">{{result.status}}</p>
            </div>
        </div>
    </a>

    <mat-list-item *ngIf="!searching && searchResults && searchResults.length == 0">
        <i class="material-icons">info_outline</i>
        Sorry, no results found
    </mat-list-item>

</div>