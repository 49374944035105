import { Component, Input, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { EntityCommunication, EntityCommunicationItem, EntityCommunicationSummary } from '@upkeeplabs/models/cogent';
import { SMSApiService } from '@cogent/client/shared/services/api/sms-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { MatDialog } from '@angular/material/dialog';

// export class CommunicationThread {
//     name: string;
//     lastDate: Date;
//     lastMessage: string;
//     communications: EntityCommunication[];

//     private _initials: string;
//     get initials() {
//         if (!this._initials) {
//             const spaceIndex = this.name.indexOf(' ');
//             if (spaceIndex === -1) {
//                 this._initials = this.name.substring(0, 1);
//             } else {
//                 this._initials = this.name.substring(0, 1) + this.name.substring(spaceIndex + 1, spaceIndex + 2);
//             }
//         }

//         return this._initials;
//     }
//     static getDemoData() {
//         const results = [];

//         const thread1 = new CommunicationThread();
//         thread1.name = 'Joe Blow';
//         thread1.communications = [];
//         thread1.lastDate = new Date(2023, 3, 25);
//         thread1.lastMessage = 'Thanks for sending that.  Here is a long message to make sure that is trims properly and that it doesn\'t  wrap, cause that would not work';

//         const message1 = new EntityCommunication();
//         message1.communicator = 'REMOTE';
//         message1.message = 'Thanks for sending that';
//         message1.createdDate = new Date(2023, 3, 25);

//         thread1.communications.unshift(message1);

//         const message2 = new EntityCommunication();
//         message2.communicator = 'ENTITY';
//         message2.message = 'I\'ll be sending over that quote';
//         message2.createdDate = new Date(2023, 3, 25);
//         thread1.communications.unshift(message2);

//         const message3 = new EntityCommunication();
//         message3.communicator = 'REMOTE';
//         message3.message = 'Can you send me a quote to get my pond cleaned?';
//         message3.createdDate = new Date(2023, 3, 24);
//         thread1.communications.unshift(message3);


//         results.push(thread1);

//         return results;
//     }
// }

@Component({
    selector: 'app-entity-communication',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule, UpkeepPipesModule],
    templateUrl: './entity-communication.component.html',
    styleUrls: ['./entity-communication.component.css']
})
export class EntityCommunicationComponent implements OnInit {

    entityCommunications: EntityCommunicationSummary[];
    showComsPanel = false;
    selectedIndex = 0;
    selectedEntityCommunication: EntityCommunicationSummary;
    newMessage: string;
    sending = false;
    id = UtilitiesService.newid();
    textAreaId = UtilitiesService.newid();
    totalUnreadCount: number;
    @Input() createNew: () => void;

    constructor(private smsApi: SMSApiService,
        private missionService: MissionService,
        private ngZone: NgZone,
        private dialog: MatDialog,
        private entityApi: EntityApiService) { }

    ngOnInit(): void {

        this.entityApi.getLoggedInUser().then(async user => {

            this.entityCommunications = await this.smsApi.getEntityCommunications(user.id);
            this.refreshTotalUnread();
        });

        this.missionService.userNotificationReceived.subscribe(async message => {
            const anyMessage: any = message;

            if (anyMessage.data?.messageType === 'ProxyMessage') {
                const entityCommunication = this.entityCommunications.find(i => i.id === anyMessage.data.lastCommunicationItem.entityCommunicationId);
                if (entityCommunication) {
                    entityCommunication.lastMessage = anyMessage.data.lastCommunicationItem.message;
                    entityCommunication.lastModifiedDate = anyMessage.data.lastCommunicationItem.createdDate;
                    if (entityCommunication.items) {
                        this.ngZone.run(() => {
                            anyMessage.data.lastCommunicationItem.newMessage = true;
                            entityCommunication.items.push(anyMessage.data.lastCommunicationItem);
                            this.scrollToBottom();
                            setTimeout(() => anyMessage.data.lastCommunicationItem.newMessage = false, 1000);
                            Notification.requestPermission().then(permission => {
                                if (permission === 'granted') {
                                    const notification = new Notification('Incomming Message', {
                                        body: anyMessage?.data?.message
                                    });

                                    notification.onclick = () => {
                                        window.parent.focus();
                                        notification.close();
                                    };
                                }
                            });
                        });
                    }
                    if (entityCommunication != this.selectedEntityCommunication) {
                        entityCommunication.unreadCount++;
                    } else {
                        await this.smsApi.markCommunicationItemAsRead(anyMessage.data.lastCommunicationItem)
                    }
                    this.refreshTotalUnread();
                }
            }
        });

        this.missionService.receivedBroadcast.subscribe(broadcast => {
            if (broadcast.message === 'StartSMSConversation') {
                const data: EntityCommunicationSummary = broadcast.data;

                let foundCom = this.entityCommunications.find(i => i.remoteEndpoint === data.remoteEndpoint);
                if (!foundCom) {
                    foundCom = UtilitiesService.copyObject(data, null, () => new EntityCommunicationSummary());
                }

                this.showComsPanel = true;
                this.selectEntityCommunication(foundCom);

                this.setTextAreaFocus();
            }
        });
    }

    private refreshTotalUnread() {
        this.entityApi.getLoggedInUser().then(async user => {
            this.smsApi.getUnreadCommunicationItems(user.id).then(count => {
                this.totalUnreadCount = count;
            });
        });
    }

    private setTextAreaFocus() {
        setTimeout(() => document.getElementById(this.textAreaId).focus(), 750);
    }

    toggleComsPanel() {
        this.showComsPanel = !this.showComsPanel;
        this.scrollToBottom();
    }

    closeComsPanel() {
        this.showComsPanel = false;
        this.selectedEntityCommunication = null;
        this.selectedIndex = 0;
    }

    scrollToBottom() {
        setTimeout(() => {
            if (this.id) {
                const el = document.getElementById(this.id);
                if (el) {
                    el.scrollTo({
                        behavior: 'smooth',
                        left: 0,
                        top: el.scrollHeight,
                    });
                }
            }
        }, 200);

        setTimeout(() => {
            if (this.id) {
                const el = document.getElementById(this.id);
                if (el) {
                    el.scrollTo({
                        behavior: 'smooth',
                        left: 0,
                        top: el.scrollHeight,
                    });
                }
            }
        }, 1000);
    }


    async selectEntityCommunication(entityCommunication: EntityCommunicationSummary) {
        this.selectedEntityCommunication = entityCommunication;
        this.selectedIndex = 1;

        if (!entityCommunication.items) {
            entityCommunication.loadingItems = true;

            entityCommunication.items = await this.smsApi.getEntityCommunicationItems(entityCommunication.id);
            entityCommunication.loadingItems = false;
        }
        const promises = [];

        for (const item of entityCommunication.items.filter(i => !i.wasRead)) {
            promises.push(this.smsApi.markCommunicationItemAsRead(item));
        }
        this.scrollToBottom();
        entityCommunication.unreadCount = 0;
        this.setTextAreaFocus();

        await Promise.all(promises);
        this.refreshTotalUnread();
    }

    back() {
        this.selectedIndex = 0;
        delete this.selectedEntityCommunication;
    }

    get canSend() {
        return this.newMessage && !this.sending;
    }

    async new() {

        const user = await this.entityApi.getLoggedInUser();
        const com = new EntityCommunication();
        com.id = UtilitiesService.newid();
        com.remoteEndpoint = '6237340080';
        com.entityId = user.id;

        this.selectedEntityCommunication = com as any;
        this.selectedIndex = 1;
    }

    handleKeyPress(keyEvent: KeyboardEvent) {
        if (keyEvent.key === 'Enter' && !keyEvent.shiftKey && this.newMessage) {
            keyEvent.preventDefault();
            this.sendMessage();
        }
    }

    async sendMessage() {
        if (this.newMessage) {
            this.sending = true;
            const communicationItem = new EntityCommunicationItem();
            communicationItem.createdDate = new Date();
            communicationItem.message = this.newMessage;
            communicationItem.id = UtilitiesService.newid();
            communicationItem.communicator = 'ENTITY';
            communicationItem.newMessage = true;

            await this.smsApi.sendSMSProxyMessage(this.selectedEntityCommunication as any, communicationItem);

            this.newMessage = '';

            if (!this.selectedEntityCommunication.items) {
                this.selectedEntityCommunication.items = [];
            }
            this.selectedEntityCommunication.items.push(communicationItem);
            this.sending = false;
            if (this.entityCommunications.indexOf(this.selectedEntityCommunication) > -1) {
                this.entityCommunications.splice(this.entityCommunications.indexOf(this.selectedEntityCommunication), 1);
            }
            this.entityCommunications.unshift(this.selectedEntityCommunication);
            this.scrollToBottom();
            setTimeout(() => communicationItem.newMessage = false, 1000);
        }
    }
}
