import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { BIReport } from "./b-i-report.model";

@dataSource()
export class BIReportGroup {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate: Date;
    @column() sort: number;

    biReports: BIReport[];
    editMode = false;
    hideGroup = false;
    listId = "";
    isEmpty = false;;
}