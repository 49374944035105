import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Template {
    @keyColumn() id: string;
    @column() templateKey: string;
    @column() templateConfiguration: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() description: string;
    @column() locked: boolean;
    @column() languageId: string;
    @column() private: boolean;

    exists?: boolean;
    save?: boolean;
    isNew = false;

    private templateConfigurationObjectLocal: TemplateConfiguration;

    get templateConfigurationObject(): TemplateConfiguration {
        if (!this.templateConfigurationObjectLocal) {
            if (this.templateConfiguration) {
                this.templateConfigurationObjectLocal = JSON.parse(this.templateConfiguration);
            } else {
                this.templateConfigurationObjectLocal = new TemplateConfiguration();
                this.templateConfigurationObjectLocal.contentItems = [];
            }
        }

        return this.templateConfigurationObjectLocal;
    }
}

export class TemplateConfiguration {
    subject: string;
    layoutFile: string;
    group: string;
    contentItems: TemplateConfigurationContent[];
    from: string; 
    replyTo: string;
    languageId: string;
}

export class TemplateConfigurationContent {
    contentFile: string;
    id: string;
    substitutionTag: string;
    fileText: string;
}