import { Entity } from "./entity.model";
import { Login } from "./login.model";
import { VerificationCode } from "./verification-code.model";

export class CustomerRegistrationArgs {
    loginExists: boolean;
    customerIds: string[];
    registrationCode: number;
    emailAddress: string;
    phoneNumber: string;
    verificationCode: VerificationCode;
    password: string;
    passwordConfirm: string;
    login: Login;
    newCustomer: Entity;
}