import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { EntitySummary } from '@upkeeplabs/models/cogent';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';

@Component({
  selector: 'app-confirm-job-contact-info',
  standalone: true,
  imports: [CommonModule, MaterialSharedModule, UpkeepPipesModule],
  templateUrl: './confirm-job-contact-info.component.html',
  styleUrls: ['./confirm-job-contact-info.component.css']
})
export class ConfirmJobContactInfoComponent implements OnInit, OnChanges {

    @Input() entityId: string;
    @Input() entitySummary: EntitySummary;
    @Output() entitySummaryChange: EventEmitter<EntitySummary> = new EventEmitter();
    collapsed = false;
  constructor(private functionApi: CustomFunctionApiService, private dialogService: DialogsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes && changes.entityId?.currentValue) {
        this.functionApi.getEntitySummary(changes.entityId.currentValue).then(entitySummary=> {
            this.entitySummary = entitySummary;
        });
      }
  }

  save() {
    if(!this.entitySummary.name) {
        this.dialogService.alert('Invalid Entry', 'Please enter the contact name');
        return;
    }

    if(!this.entitySummary.email || !UtilitiesService.validateEmail(this.entitySummary.email)) {
        this.dialogService.alert('Invalid Entry', 'Please enter the contact email');
        return;

    }
    if(!this.entitySummary.homeNumber || !UtilitiesService.validatePhoneNumber(this.entitySummary.homeNumber)) {
        this.dialogService.alert('Invalid Entry', 'Please enter the contact phone number');
        return;
    }
    this.collapsed = true;

    this.entitySummaryChange.emit(this.entitySummary);
  }

}
