import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class EntityKeywordSearch {
    @keyColumn() id: string;
    @column() createdDate: Date;
    @column() address1: string;
    @column() email: string;
    @column() name: string;
    @column() type: string;
    @column() homeNumber: string;
    @column() mobileNumber: string;
    @column() workNumber: string;
    @column() typeIcon: string;
    @column() typeClass: string;
    @column() routerNameLink: string;
    @column() parentId: string;
    @column() teamId: string;
    @column() licenseNumber: string;
    @column() policyCount: number;
    @column() ratingSum: number;

    invoiceClass: string;
    policyClass: string;
    poClass: string;
    woClass: string;
    claimClass: string;
    phoneString: string;
    emailString: string;
}