import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-updates-needed',
  templateUrl: './updates-needed.component.html',
  styleUrls: ['./updates-needed.component.css']
})
export class UpdatesNeededComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

} 
