import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class BI_RenewalPercent {
    @column() coverageType: string;
    @column() expirationMonth: Date;
    @column() availableToRenew: number;
    @column() paidRenewals: number;
    @column() unpaidRenewals: number;
    @column() renewalPercent: number;
    @column() expired: number;
}