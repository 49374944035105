import { Component, Input } from '@angular/core';
import { NoteClient } from '@cogent/client/shared/models/common/note-client.model';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { EntitySummary } from '@upkeeplabs/models/cogent';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";


@Component({
    templateUrl: './claim-summary.html',
    styleUrls: ['./claim-summary.css'],
    selector: 'my-claim-summary',
})
export class ClaimSummaryComponent {

    @Input()
    workOrderSummary: WorkOrderSummaryClient;
    @Input() firstEffectiveDate: Date;

    @Input() notes: NoteClient[]

    @Input() noCards = false;

    @Input() claimSummaryExpanded = false;
    @Input() hideCustomerName = false;
    @Input() noCollapse = false;

    propertyManager: EntitySummary;

    constructor(
        private entityApi: EntityApiService,
        private serviceApi: ServiceApiService
    ) {

    }
    async ngOnInit() {
        if (this.workOrderSummary.propertyManagerId) {
            this.propertyManager = await this.entityApi.getEntitySummary(this.workOrderSummary.propertyManagerId);
        }
        if(this.workOrderSummary.contactName == this.workOrderSummary.homeownerName) {

        }
    }
    getEntityThumbnail(entityId) {
        return this.entityApi.getThumbnailUri(entityId);
    }

    getItemThumbnail(itemId) {
        if (this.workOrderSummary && this.workOrderSummary.lines && this.workOrderSummary.lines.length > 0) {
            return this.serviceApi.getItemThumbnailUrl(this.workOrderSummary.lines[0].itemId);
        }
        return this.serviceApi.getItemThumbnailUrl(itemId);
    }
}
