import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TypeAheadApiService } from '@cogent/client/shared/services/api/type-ahead-api.service';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  templateUrl: './type-ahead-box.component.html',
  styleUrls: ['./type-ahead-box.component.css'],
  selector: 'app-type-ahead-box'
})
export class TypeAheadBoxComponent {
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;
  public dataSource: Observable<any>;

  @Input() searchForText: string;
  @Input() public selectedItem: any;
  @Input() placeholderLabel = 'Search';
  @Input() typeaheadOptionField = 'name';
  @Input() itemTemplate: any;
  @Output() selectedItemChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  searchForTextChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() apiEndpoint: string;
  @Input() apiEndpointParameter = 'searchFor';
  @Input() urlFormatter: (token: string) => string;


  myControl = new UntypedFormControl();
  searching = false;
  allSearchItems: any[];
  filteredItems: any;


  public constructor(private typeAheadApi: TypeAheadApiService) {

    this.filteredItems = this.myControl.valueChanges.pipe(debounceTime(500))
      .subscribe(value => {

        if (typeof value === 'string') {
          this.searchForText = value;
          this.searchForTextChange.emit(this.searchForText);
        }
        if (value && !value.id) {
          this.getData();
        }
      });
  }

  async getData() {


    this.searching = true;
    this.typeAheadApi.getSearchResults(this.apiEndpoint, this.apiEndpointParameter, this.searchForText).subscribe(results => {
      this.allSearchItems = results;
      this.searching = false;
    });
  }

  reset() {
    this.myControl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItem = event.option.value;
    this.selectedItemChange.emit(this.selectedItem);
  }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }
}
