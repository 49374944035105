import { keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class ServiceProReportCard {
    @column() serviceProId: string;
    @column() servicePro: string;
    @column() areaId: string;
    @column() area: string;
    @column() month: Date;
    @column() tradeId: string;
    @column() trade: string;
    @column() jobs: number;
    @column() allJobs: number;
    @column() jobsPercent: number;
    @column() costs: number;
    @column() claims: number;
    @column() acc: number;
    @column() accGrade: number;
    @column() firstCalls: number;
    @column() recalls: number;
    @column() recallPercent: number;
    @column() recallPercentGrade: number;
    @column() offers: number;
    @column() acceptedOffers: number;
    @column() acceptPercent: number;
    @column() acceptPercentGrade: number;
    @column() customerSatPoints: number;
    @column() customerSatSurveys: number;
    @column() customerSatScore: number;
    @column() customerSatScoreGrade: number;
    @column() replacementClaims: number;
    @column() replacementPercent: number;
    @column() replacementPercentGrade: number;
    @column() compositeGrade: number;
    @column() targetACC: number;
    @column() targetRecallPercent: number;
    @column() targetAcceptPercent: number;
    @column() targetCustomerSatScore: number;
    @column() targetReplacementPercent: number;
}