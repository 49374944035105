import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class RepairTypeGuidelinePrice {
    @keyColumn() id: string;
    @column() repairTypeId: string;
    @column() price: number;
    @column() priceVariance: number;
    @column() outOfPocket: number;
    @column() outOfPocketVariance: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() contractorId: string;

    touched?: boolean;
}


@dataSource('RepairTypeGuidelinePriceSummary')
export class RepairTypeGuidelinePriceSummary  {
    @keyColumn() id: string;
    @column() repairTypeId: string;
    @column() price: number;
    @column() priceVariance: number;
    @column() outOfPocket: number;
    @column() outOfPocketVariance: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;
    @column() contractorId: string;
    @column() contractorName: string;

    touched?: boolean;
    editing?: boolean;
}