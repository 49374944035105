import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { EntityCommunicationSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-sms-link-button',
    standalone: true,
    imports: [CommonModule, UpkeepPipesModule , MaterialSharedModule],
    templateUrl: './sms-link-button.component.html',
    styleUrls: ['./sms-link-button.component.css']
})
export class SmsLinkButtonComponent implements OnInit {

    @Input() buttonMode: boolean;
    @Input() number: string;
    @Input() label: string;
    @Input() remoteEntityId: string;
    constructor(private entityApi: EntityApiService,
        private missionService: MissionService) { }

    ngOnInit(): void {
    }


    async start() {

        const com = new EntityCommunicationSummary();

        const user = await this.entityApi.getLoggedInUser();
        com.entityId = user.id;
        com.remoteEndpoint = UtilitiesService.stripPhoneFormatting(this.number);
        com.id = UtilitiesService.newid();
        com.remoteEntityId = this.remoteEntityId;

        this.missionService.raiseReceivedBroadcast({
            message: 'StartSMSConversation',
            data: com,
        });
    }


}
