import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource()
export class AccountAuthorizationRequest {
    @keyColumn() id: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() clientId: string;
    @column() codeChallenge: string;
    @column() codeChallengeMethod: string;
    @column() redirectUri: string;
    @column() responsetype: string;
    @column() scope: string;
    @column() state: string;
    @column() nonce?: string;
    @column() loginId: string;
    @column() authorizationCode: string;
    @column() authorizationCodeExpires: Date;
    @column() accessToken: string;
    @column() idToken: string;
    @column() refreshToken: string;
}