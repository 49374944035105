export class ContractorSurveyResult {
    contractorId: string;
    contractorName: string;
    surveyPossible: number;
    surveyScore: number;
    
    get totalSurveys() {
        return this.surveyPossible / 5;
    }

    get average() {
        if(this.surveyPossible === 0) {
            return 0;
        }

        return this.surveyScore / this.surveyPossible;
    }

    get averageStars() {
        return this.average * 5;
    }
}