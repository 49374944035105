import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkflowInstance {
    @keyColumn() id: string;
    @column() workflowId: string;
    @column() objectId: string;
    @column() objectType: string;
    @column() process: string;
    @column() executionLog: string;
    @column() lastExecutedStepId: string;
    @column() lastExecutedStepTime: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() nextExecutedStepId: string;
    @column() nextExecutedStepTime: Date;
    @column() completionDate: Date;
    @column() environmentJson: string;
    @column() executionEnvironment: string;
    @column() expirationDate: Date;
    @column() type: string;
    @column() currentSMSNumber: string;
    @column() currentTwilioSMSNumber: string;
    @column() timeoutDate: Date;
    @column() twilioCallSid: string;
}