import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ApiService } from '@cogent/client/api';
import { Entity } from '@upkeeplabs/models/cogent';

export class TechnicianAndJobs {
    technician: Entity;
    jobs: WorkOrderSummaryClient[];
    overlapCount = 0;
}

export class TimeSlot {
    constructor(public time: number) { }

    get display() {
        if (this.time > 12) {
            return (this.time - 12) + ' PM';
        }
        if (this.time === 12) {
            return this.time + ' PM';
        }

        return this.time + ' AM';
    }
}

@Component({
    selector: 'app-schedule-view',
    templateUrl: './schedule-view.component.html',
    styleUrls: ['./schedule-view.component.css']
})
export class ScheduleViewComponent implements OnInit, OnChanges {

    @Input() technicians: Entity[];
    @Input() workOrders: WorkOrderSummaryClient[];
    groups: TechnicianAndJobs[];
    @Input() startHour = 5;
    @Input() endHour = 19;
    timeSlots: TimeSlot[] = [];
    timeSlotWidth = 75;
    appointmentHeight = 32;
    @Input() highlightedAppointment: WorkOrderSummaryClient;

    constructor(private api: ApiService) { }

    ngOnInit() {
        let hour = this.startHour;
        while (hour <= this.endHour) {

            this.timeSlots.push(new TimeSlot(hour));
            hour++;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.technicians && changes.technicians.currentValue) || (changes.workOrders && changes.workOrders.currentValue)) {
            this.setGrouping();
        }

        if (changes.workOrders && changes.workOrders.currentValue) {

        }
    }

    refresh() {
        this.setGrouping();
    }

    private setGrouping() {
        if (!this.technicians || !this.workOrders) {
            return;
        }

        this.groups = this.technicians.map(tech => {
            const techAndJobs = new TechnicianAndJobs();

            techAndJobs.technician = tech;
            techAndJobs.jobs = this.workOrders.filter(i => i.technicianId === tech.id).sort((a, b) => a.appointmentStartHour < b.appointmentStartHour ? -1 : 1);

            return techAndJobs;
        });

        this.groups.forEach(group => {
            group.jobs.forEach(job => {

                job.overlapCount = 0;
                const index = group.jobs.indexOf(job);
                const beforeJobs = group.jobs.slice(0, index);

                let overlapCount = 0;

                const startHour = job.appointmentStartHour;
                const endHour = job.appointmentEndHour;
                for (const otherJob of beforeJobs) {
                    const otherStartHour = otherJob.appointmentStartHour;
                    const otherEndHour = otherJob.appointmentEndHour;

                    if ((otherEndHour > startHour && endHour >= otherStartHour) || (endHour > otherStartHour && startHour < otherEndHour)
                    ) {
                        overlapCount = otherJob.overlapCount + 1;
                        if (group.overlapCount < overlapCount) {
                            group.overlapCount = overlapCount;
                        }
                        if (overlapCount > job.overlapCount) {
                            job.overlapCount = overlapCount;
                        }
                    }
                }
            });
        });
    }

    getWidth(job: WorkOrderSummaryClient) {
        const totalTimeSlots = this.endHour - this.startHour + 1;
        const startHour = job.appointmentStartHour;
        const endHour = job.appointmentEndHour;
        const jobTimeSlots = endHour - startHour;

        return `calc(${((jobTimeSlots / totalTimeSlots) * 100)}% - 2px)`;
    }

    getTop(job: WorkOrderSummaryClient, techAndJobs: TechnicianAndJobs) {
        return `${job.overlapCount * this.appointmentHeight}px`;
    }

    getContainerHeight(techAndJobs: TechnicianAndJobs) {
        return `${(techAndJobs.overlapCount + 1) * this.appointmentHeight}px`;
    }

    getTechThumbnail(tech: Entity) {
        return `${ApiService.endPointDotNet}Entity/thumbnail/${tech.id}`;
    }

    getLeft(job: WorkOrderSummaryClient) {
        const totalTimeSlots = this.endHour - this.startHour + 1;
        const startHour = job.appointmentStartHour;
        const startingSlots = startHour - this.startHour;

        return `calc(${((startingSlots / totalTimeSlots) * 100)}% - 0px)`;
    }

    get containerWidth() {
        return `${(this.endHour - this.startHour + 1) * this.timeSlotWidth + 150}px`;
    }

}
