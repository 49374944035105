<div aflex>
    <h2 class="sub-heading">Change Appointment</h2>
    <button nogrow mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div style="max-width: 200px;">
        <mat-form-field appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input matInput [(ngModel)]="date" [matDatepicker]="picker">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="time-slot">
        <button mat-button mat-raised-button class="toggle-button" [class.selected]="isMorning" (click)="setMorning()">
            <img
                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/appointment-range/morning.png">
            <br>
            <h3>Mornings</h3>
            <span class="range">(~ 8am-12pm)</span>

        </button>
        <button mat-button mat-raised-button class="toggle-button" [class.selected]="isMidDay" (click)="setMidDay()">
            <img
                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/appointment-range/mid-day.png">
            <br>
            <h3>Mid-day</h3>
            <span class="range">(~ 10am-2pm)</span>

        </button>
        <button mat-button mat-raised-button class="toggle-button" [class.selected]="isAfternoon"
            (click)="setAfternoon()">
            <img
                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/appointment-range/afternoon.png">
            <br>
            <h3>Afternoon</h3>
            <span class="range">(~ 12pm-5pm)</span>

        </button>
        <button mat-button mat-raised-button class="toggle-button" [class.selected]="isAllDay" (click)="setAllDay()">
            <img
                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/icons/appointment-range/all-day.png">
            <br>
            <h3>All Day</h3>
            <span class="range">(~ 8am-5pm)</span>

        </button>

    </div>

    <div>

        <mat-checkbox [(ngModel)]="notifyCustomer">Notify Customer Of Change</mat-checkbox>
    </div>
</mat-dialog-content>


<mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" (click)="save()" [disabled]="!canSave">Save</button>
</mat-dialog-actions>