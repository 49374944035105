import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '@cogent/client/shared/services/api/contractors.service';
import { ContractorDocumentType } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-contractor-documents',
    templateUrl: './contractor-documents.component.html',
    styleUrls: ['./contractor-documents.component.css']
})
export class ContractorDocumentsComponent implements OnInit {

    documentTypes: ContractorDocumentType[];
    constructor(private contractorApi: ContractorsService) { }

    ngOnInit() {
        this.getTypes();
    }

    async getTypes() {
       this.documentTypes = await this.contractorApi.getContractorDocumentTypes();
    }

    async getDocuments() {
        
    }

}
