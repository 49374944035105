<div class="table" style="max-width: 800px;">
    <div class="table-header">
        <div>Document</div>
        <div nogrow style="width: 200px">Expiration Date</div>
        <div nogrow style="width: 200px">Options</div>
    </div>

    <div class="table-row" *ngFor="let document of documents">
        <div>
            {{getDocumentTypeName(document)}}
        </div>
        <div nogrow style="width: 200px">
            <app-date-picker [outline]="true" [(date)]="document.expirationDate" (dateChange)="markAsModified(document)"></app-date-picker>
        </div>
        <div nogrow style="width: 200px">
            <button mat-button (click)="showUpload(document)">
                <mat-icon>backup</mat-icon>
            </button>

            <a mat-button target="_blank" *ngIf="document.hasDocument" href="{{baseApiPath}}ContractorDocument/{{entity.id}}/document/{{document.contractorDocumentTypeId}}/download">
                <mat-icon>cloud_download</mat-icon>
            </a>

            <input type="file" [id]="document.id"  (change)="handleFiles($event, document)">
        </div>
    </div>
</div>