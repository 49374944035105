import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class EntityRegionPostalCode {
    @keyColumn() id: string;
    @column() postalCode: string;
    @column() entityId: string;
    @column() deletedDate?: Date;
    @column() city: string;
    @column() state: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;

    constructor(id: string = null, postalCode: string = null, entityId: string = null, city: string = null, state: string = null) {
        this.id = id;
        this.postalCode = postalCode;
        this.entityId = entityId;
        this.city = city;
        this.state = state;
    }

    // client-side only
    selected = false;
}