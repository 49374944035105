import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderLineAuthorization {
    @keyColumn() id: string;
    @column() workOrderLineId: string;
    @column() approvedAmount: number;
    @column() outOfPocketAmount: number;
    @column() fullArgs: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() superceededById: string;
    @column() repairTypeId: string;
    @column() approvedDate: Date;
    @column() approvedById: string;

    private _args: any;
    get args(): any {

        if (!this._args) {
            try {
                this._args = JSON.parse(this.fullArgs);
            } catch { }
        }
        return this._args;
    }

    set args(value: any) {
        this._args = value;
        this.fullArgs = JSON.stringify(value);
    }
}