<button mat-button mat-raised-button class="start-com" (click)="toggleComsPanel()">
    <span *ngIf="totalUnreadCount" class="badge total-unread">{{totalUnreadCount}}</span>
    <mat-icon>forum</mat-icon>
</button>

<div *ngIf="showComsPanel" class="coms-panel">
    <div [class.header]="selectedIndex === 1">
        <button mat-button (click)="closeComsPanel()" class="close-detail-button">
            <mat-icon>close</mat-icon>
        </button>
        <div class="thread-header" *ngIf="selectedEntityCommunication">

            <div style="text-align: center;font-weight: bold;">
                <div class="avatar">{{selectedEntityCommunication.initials}}</div><br>
                <div *ngIf="selectedEntityCommunication.remoteName">{{selectedEntityCommunication.remoteName}}</div>
                {{selectedEntityCommunication.remoteEndpoint | formatPhoneNumber}}
            </div>
            <div>
                <button style="position: absolute;right: 0" mat-button (click)="back()">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>
            </div>
        </div>
    </div>
    <div class="coms-inner-panel" [id]="id">
        <mat-tab-group class="hide-tabs full-height-tab" [class.full-height-tab]="selectedEntityCommunication && (!selectedEntityCommunication.items || selectedEntityCommunication.items.length === 0)" [(selectedIndex)]="selectedIndex">
            <mat-tab label="First">
                <div class="center">
                    <button *ngIf="createNew" mat-button (click)="createNew()">
                        <mat-icon>add</mat-icon>
                        New</button>
                </div>
                <button mat-button class="thread" (click)="selectEntityCommunication(entityCommunication)"
                    *ngFor="let entityCommunication of entityCommunications">
                    <span class="badge"
                        *ngIf="entityCommunication.unreadCount">{{entityCommunication.unreadCount}}</span>
                    <div class="msg-container">
                        <div class="avatar">{{entityCommunication.initials}}</div>
                        <div style="overflow: hidden;">
                            <div class="message-header">
                                <div><span
                                        *ngIf="entityCommunication.remoteName">{{entityCommunication.remoteName}}</span>
                                    <span *ngIf="!entityCommunication.remoteName">{{entityCommunication.remoteEndpoint |
                                        formatPhoneNumber}}</span>
                                </div>
                                <div>{{entityCommunication.lastMessageDate | date: 'shortDate'}}</div>
                            </div>
                            <div class="msg-body">
                                {{entityCommunication.lastMessage}}

                            </div>
                        </div>
                    </div>
                </button>
            </mat-tab>
            <mat-tab label="Second">
                <div *ngIf="selectedEntityCommunication" [class.one-hundred-height]="!selectedEntityCommunication.items || selectedEntityCommunication.items.length === 0" >


                    <div *ngIf="!selectedEntityCommunication.items || selectedEntityCommunication.items.length === 0" class="start-conversation">
                        <div></div>
                        <div>
                            <mat-icon>question_answer</mat-icon>
                            <h2 class="sub-heading">Start a Conversation</h2>
                        </div>
                        <div>

                        </div>
  
                    </div>
                    <div class="message-container" [class.outgoing]="communication.communicator === 'ENTITY'"
                        *ngFor="let communication of selectedEntityCommunication.items">
                        <div *ngIf="communication.communicator === 'REMOTE'">

                        </div>
                        <div class="comment-container" [class.new-message]="communication.newMessage">
                            <p class="triangle-border">
                                {{communication.message}}
                            </p>
                        </div>
                        <div *ngIf="communication.communicator === 'ENTITY'">
                            <!-- You said<br> -->

                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div class="new-message-bar" *ngIf="selectedIndex === 1">

        <form>
            <mat-form-field style="margin-bottom: -10px;" appearance="outline">
                <textarea name="newmessage" [id]="textAreaId" matInput [cdkAutosizeMaxRows]="3"
                    (keypress)="handleKeyPress($event)" cdkTextareaAutosize rows="1"
                    [(ngModel)]="newMessage"></textarea>
            </mat-form-field>
            <button mat-button mat-raised-button color="primary" (click)="sendMessage()" [disabled]="!canSend">

                <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
                <mat-icon *ngIf="!sending">send</mat-icon> Send
            </button>
        </form>
    </div>
</div>