import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { RepairItemAttribute } from "./repair-item-attribute.model";

@dataSource()
export class RepairItem {
    @keyColumn() id: string;
    @column() name: string;
    @column() repairItemGroupId: string;
    @column() amount: number;
    @column() laborHours: number;
    @column() yellowRangePercent: number;
    @column() isReplacement: boolean;
    @column() isPurchasable: boolean;
    @column() canNotCover: boolean;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() canRepair: boolean;
    @column() avgRepairHours: number;
    @column() avgReplaceHours: number;
    @column() canReplace: boolean;
    @column() groupName: string;
    @column() alternativeNames: string;
    @column() coveredByDefault: boolean;
    @column() inactive: boolean;
    @column() laborAmount: number;
    @column() isLaborOnly: boolean;
    @column() noMultiItemDiscount: boolean;


    attributes: RepairItemAttribute[];
    subGroupName?: string;
    alternativeNameToShow: string;
    limit: number;
    get displayName() {
        if (this.alternativeNameToShow){
            return this.alternativeNameToShow + " (" + this.name + ")";
        }
        return this.name;
    }
}