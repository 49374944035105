import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomFunctionApiService } from '@cogent/client/shared/services/api/custom-function-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MaintenanceServiceCustomerPropertyService, MaintenanceServiceJobOfferSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { MaintServicesNavBarComponent } from '../../parts/maint-services-nav-bar/maint-services-nav-bar.component';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
declare var google: any;

@Component({
    selector: 'app-accept-maintenance-job',
    templateUrl: './accept-maintenance-job.component.html',
    styleUrls: ['./accept-maintenance-job.component.css']
})
export class AcceptMaintenanceJobComponent implements OnInit {

    offering: MaintenanceServiceJobOfferSummary;
    @ViewChild('gmap') gmapElement: any;
    @ViewChild('maintBar') maintBar: MaintServicesNavBarComponent;

    map: any;
    declining = false;
    accepting = false;
    selectedIndex = 0;
    originalPreferredDay = 0;
    findAnother = false;
    offerNotAvailable = false;
    offerWasAccepted = false;
    acceptedService: MaintenanceServiceCustomerPropertyService;

    // rejectFunctionId: string = '14B456B2-FBA8-4F51-8E5D-D9A6DC123937';
    // acceptJobFunction: string = '05F032E5-ADFD-461D-8261-C1C7B1A506BA';

    constructor(private activatedRoute: ActivatedRoute,
        private functionApi: CustomFunctionApiService,
        private mission: MissionService,
        private serviceApi: ServiceApiService,
        private dialogService: DialogsService,
        private maintApi: MaintenanceServiceApiService) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(async params => {
            const id = params.id;
            const offerSummary = await this.maintApi.getMaintenanceServiceJobOfferSummary(id);
            this.offering = offerSummary;
            
            if(!offerSummary) {
                const wasAccepted = await this.maintApi.getMaintenanceServiceOfferWasAccepted(id);
                if(!wasAccepted) {
                    this.offerNotAvailable = true;
                    return;
                } else {
                    this.acceptedService = wasAccepted;
                    this.offerWasAccepted = true;
                    return;
                }

            }
            if (offerSummary.accepted) {
                this.selectedIndex = 4;
                return;
            }
            if (offerSummary.rejectedDate) {
                this.selectedIndex = 5;
                return;
            }

            this.originalPreferredDay = offerSummary.preferredDay;
            const mapProp = {
                center: new google.maps.LatLng(offerSummary.latitude, offerSummary.longitude),
                zoom: 14,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true
            };
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
            new google.maps.Marker({
                position: new google.maps.LatLng(offerSummary.latitude, offerSummary.longitude),
                map: this.map,
                title: offerSummary.propertyAddress1
            });
        });
    }

    get firstServiceDate() {
        if (!this.offering || isNaN(this.offering.preferredDay) || this.offering.preferredDay == null || this.offering.preferredDay == undefined) {
            return null;
        }
        
        const dt = new Date();

        dt.setDate(dt.getDate() + this.offering.schedulingBufferDays);
        while (dt.getDay() != this.offering.preferredDay) {
            dt.setDate(dt.getDate() + 1);
        }

        return dt;
    }

    get saving() {
        return this.accepting || this.declining;
    }

    showAlernate() {
        this.selectedIndex = 3;
    }

    get canSave() {
        if (this.saving) {
            return false;
        }

        if (this.offering.preferredDay === 0) {
            return true;
        }

        if (!this.offering.preferredDay) {
            return false;
        }

        return true;
    }

    async accept() {
        if (await this.dialogService.confirm('Confirm', 'Accept Job?').toPromise()) {
            this.accepting = true;
            await this.maintApi.acceptJob(this.offering.id, this.offering.preferredDay);

            this.accepting = false;
            this.selectedIndex = 1;
            this.maintBar.refresh();
        }
    }

    get canSendAlternate() {
        return this.offering.preferredDay &&
            this.offering.preferredDay !== this.originalPreferredDay;
    }

    get serviceUrl() {
        return this.serviceApi.getItemThumbnailUrl(this.offering.maintenanceServiceOfferingId);
    }

    async decline() {
        if (await this.dialogService.confirm('Confirm', 'Reject Job?').toPromise()) {
            this.declining = true;
            await this.maintApi.rejectJob(this.offering.id);
            // await this.functionApi.runCustomFunction(this.rejectFunctionId, 'MaintenanceServiceJobOffer', this.offering.id);
            this.declining = false;
            this.selectedIndex = 2;
            this.maintBar.refresh();

            setTimeout(() => {
                this.findAnother = true;
            }, 2000);
        }
    }
}
