<div mat-dialog-content>
    <div aflex>
        <div style="position:absolute; left:0; right:0;"><h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="sub-heading">&nbsp;</h2></div>
        <div nogrow><button mat-button mat-dialog-close style="position: absolute; right: 25px"><mat-icon>close</mat-icon></button></div>
    </div>
    


    <div topmargin20></div>
    <app-custom-form-display #formDisplay [selectedForm]="selectedForm" [previewMode]="true" [(formComplete)]="formComplete">

    </app-custom-form-display>
</div>
<div mat-dialog-actions style="display: block;text-align: right;">
  <button mat-button mat-raised-button color="primary" [disabled]="!formComplete" (click)="save()">Save</button>
</div>
