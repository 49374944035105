import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceAddOnRequestSummary, MaintenanceServiceCustomerPropertyServiceAppointmentSummary, MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceJobOfferSummary } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

import { ApiService } from '@cogent/client/api';
import { MaintServicesCompleteAppointmentComponent } from '../../pages/maint-services-complete-appointment/maint-services-complete-appointment.component';

export class GroupedCustomer {
    name: string;
    email: string;
    propertyAddress1: string;
    propertyAddress2: string;
    propertyCity: string;
    propertyState: string;
    propertyPostalCode: string;
    customerId: string;
    customerPhoneNumber: string;
    maintenanceServiceCustomerPropertyId: string;
    serviceSummaries: MaintenanceServiceCustomerPropertyServiceSummary[];
    status: string;

    static fromCustomers(customers: MaintenanceServiceCustomerPropertyServiceSummary[]) {
        const results: GroupedCustomer[] = [];
        for (const customer of customers) {
            let group = results.find(i => i.customerId === customer.customerId);
            if (!group) {
                group = new GroupedCustomer();
                group.customerId = customer.customerId;
                group.email = customer.customerEmail;
                group.name = customer.customerName;
                group.propertyAddress1 = customer.propertyAddress1;
                group.maintenanceServiceCustomerPropertyId = customer.maintenanceServiceCustomerPropertyId;
                group.propertyAddress2 = customer.propertyAddress2;
                group.propertyCity = customer.propertyCity;
                group.propertyState = customer.propertyState;
                group.propertyPostalCode = customer.propertyPostalCode;
                group.customerPhoneNumber = customer.customerPhoneNumber;
                group.serviceSummaries = [];
                group.status = customer.status;
                results.push(group);
            }
            group.serviceSummaries.push(customer);
        }
        return results;
    }

    private _services: string;
    get services() {
        if (!this._services) {
            this._services = this.serviceSummaries.map(i => i.name).join(', ');
        }

        return this._services;
    }
}

@Component({
    selector: 'app-maint-services-home',
    templateUrl: './maint-services-home.component.html',
    styleUrls: ['./maint-services-home.component.css']
})
export class MaintServicesHomeComponent implements OnInit {
    selectedIndex = 2;
    customers: MaintenanceServiceCustomerPropertyServiceSummary[];
    groupedCustomers: GroupedCustomer[];
    pending: MaintenanceServiceJobOfferSummary[];
    completedJobs: any[];
    upcommingJobs: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    jobsToComplete: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];
    swipeCoord = [0, 0];
    swipeTime = new Date().getTime();
    pendingAddOns: MaintenanceServiceAddOnRequestSummary[];




    constructor(private maintServiceApi: MaintenanceServiceApiService,
        private dialog: MatDialog,
        private activatedRouter: ActivatedRoute) { }

    ngOnInit(): void {
        this.load();

        this.activatedRouter.queryParams.subscribe(params => {
            if (params.selectedIndex) {
                this.selectedIndex = parseInt(params.selectedIndex);
            }
        });
    }

    async load() {
        this.customers = await this.maintServiceApi.getCustomerPropertyServiceForLoggedInContractor();

        this.groupedCustomers = GroupedCustomer.fromCustomers(this.customers);

        const jobs = await this.maintServiceApi.getAppointmentsForLoggedInContractor();
        this.upcommingJobs = jobs.filter(i => i.scheduledDate > new Date() && i.customerPropertyStatus !== 'Suspended' && !i.completedDate);
        this.completedJobs = jobs.filter(i => i.scheduledDate < new Date());
        this.jobsToComplete = jobs.filter(i => i.scheduledDate < new Date() && !i.completedDate);

        this.pendingAddOns = await this.maintServiceApi.getPendingAddOnRequestForLoggedInContractor();
    }

    get pendingCount() {
        return (this.pending?.length ?? 0) + (this.pendingAddOns?.length ?? 0);
    }

    get companyUrl() {
        return ApiService.endPointDotNet + 'entity/company/logo';
    }

    async selectedIndexChange(index: number) {
        if (index === 1) {
            this.customers = await this.maintServiceApi.getCustomerPropertyServiceForLoggedInContractor();
        }
        if (index === 3) {
            const jobs = await this.maintServiceApi.getAppointmentsForLoggedInContractor();
            this.upcommingJobs = jobs.filter(i => i.scheduledDate > new Date() && i.customerPropertyStatus !== 'Suspended' && !i.completedDate);
        }
    }

    get nextJob() {
        if (!this.upcommingJobs) {
            return null;
        }

        return this.upcommingJobs[0];
    }

    downloadAppointments() {
        UtilitiesService.export('appointmentsTable');
    }

    getServiceUrl(serviceSummary: MaintenanceServiceCustomerPropertyServiceSummary | any) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${serviceSummary.maintenanceServiceOfferingId}/photo`;
    }

    exportCustomers() {
        UtilitiesService.export();
    }

    showCompleteDialog(job: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) {
        const ref = this.dialog.open(MaintServicesCompleteAppointmentComponent, { data: job });
        ref.afterClosed().subscribe(results => {
            if (results) {
                //job.completed = true;
                job.completedDate = new Date();

                setTimeout(() => {
                    this.jobsToComplete.splice(this.jobsToComplete.indexOf(job), 1);
                }, 1000);
            }
        });
    }
    @HostListener('touchstart', ['$event']) onSwipeStart($event) {
        this.onSwipe($event, 'start');
    }

    @HostListener('touchend', ['$event']) onSwipeEnd($event) {
        this.onSwipe($event, 'end');
    }

    onSwipe(e: TouchEvent, when: string) {
        this.swipe(e, when);
    }
    swipeLeft() {
        this.selectedIndex++;
        if (this.selectedIndex > 3) {
            this.selectedIndex = 0;
        }
    }

    swipeRight() {
        this.selectedIndex--;
        if (this.selectedIndex < 0) {
            this.selectedIndex = 3;
        }
    }

    swipe(e: TouchEvent, when: string): void {

        const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 //
                && Math.abs(direction[0]) > 30 // Long enough
                && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
                const swipeDir = direction[0] < 0 ? 'next' : 'previous';
                if (swipeDir === 'next') {
                    this.swipeLeft();
                } else {
                    this.swipeRight();
                }
            }
        }
    }

}
