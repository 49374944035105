import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceInvoice, MaintenanceServiceInvoiceItem } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-maint-services-create-invoice',
    templateUrl: './maint-services-create-invoice.component.html',
    styleUrls: ['./maint-services-create-invoice.component.css']
})
export class MaintServicesCreateInvoiceComponent implements OnInit {
    selectedIndex = 0;
    selectedService: MaintenanceServiceCustomerPropertyServiceSummary;
    lineItems: MaintenanceServiceInvoiceItem[] = [];
    allServices: MaintenanceServiceCustomerPropertyServiceSummary[];
    filteredServices: MaintenanceServiceCustomerPropertyServiceSummary[];
    saving = false;
    vendorNumber: string;
    filter: string;
    filterTimeout: any;
    date: Date = new Date();

    constructor(public dialogRef: MatDialogRef<MaintServicesCreateInvoiceComponent>,
        private maintApi: MaintenanceServiceApiService,
        @Inject(MAT_DIALOG_DATA) public data: any,) {

    }
    ngOnInit(): void {
        this.addLineItemIfNeeded();
        this.maintApi.getCustomerPropertyServiceForLoggedInContractor().then(items => {

            this.allServices = items;
            // for (let i = 0; i < 6; i++) {
            //     this.doTestCopy();
            // }
            this.filteredServices = this.allServices;
        });
    }

    private doTestCopy() {
        const copy = UtilitiesService.copyArrayToTypedArray(this.allServices, () => new MaintenanceServiceCustomerPropertyServiceSummary());
        this.allServices = this.allServices.concat(copy);
    }

    adjustFilter() {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            if (!this.filter) {
                this.filteredServices = this.allServices;
            } else {
                const filter = this.filter.toLowerCase();

                this.filteredServices = this.allServices.filter(i => i.name?.toLowerCase().indexOf(filter) > -1
                    || i.propertyAddress1?.toLocaleLowerCase().indexOf(filter) > -1
                    || i.propertyCity?.toLocaleLowerCase().indexOf(filter) > -1
                    || i.propertyPostalCode.toLocaleLowerCase().indexOf(filter) > -1);
            }
        }, 700);
    }

    public static show(dialog: MatDialog) {
        return dialog.open(MaintServicesCreateInvoiceComponent).afterClosed().toPromise();
    }

    selectService(service: MaintenanceServiceCustomerPropertyServiceSummary) {
        this.selectedService = service;
        this.selectedIndex = 1;
        this.lineItems = [];
        this.addLineItemIfNeeded();

        const firstLineItem = this.lineItems[0];
        if (service.costPerMonth) {
            firstLineItem.amount = service.costPerMonth;
        } else if (service.cost) {
            firstLineItem.amount = service.cost;
        }
        firstLineItem.description = service.name;
        this.addLineItemIfNeeded();
    }

    get canSave() {
        return this.total > 0 && !this.saving;
    }

    get total() {
        return this.lineItems.filter(i => i.amount).map(i => i.amount).reduce((a, b) => a + b, 0);
    }


    addLineItemIfNeeded() {
        const found = this.lineItems.find(i => !i.description && !i.amount);
        if (!found) {
            const lineItem = new MaintenanceServiceInvoiceItem();
            lineItem.id = UtilitiesService.newid();
            this.lineItems.push(lineItem);
        }
    }

    async save() {
        this.saving = true;

        const invoice = new MaintenanceServiceInvoice();
        invoice.amount = this.total;
        invoice.date = this.date;
        invoice.dueDate = this.date;
        invoice.id = UtilitiesService.newid();
        invoice.invoiceType = 'AP';
        invoice.maintenanceServiceCustomerPropertyId = this.selectedService.maintenanceServiceCustomerPropertyId;
        invoice.payeeId = this.selectedService.contractorId;
        invoice.vendorNumber = this.vendorNumber;


        const invoiceItems = this.lineItems.filter(i => i.amount);
        for (const i of invoiceItems) {
            i.maintenanceServiceCustomerPropertyServiceId = this.selectedService.id;
            i.maintenanceServiceInvoiceId = invoice.id;
        }

        await this.maintApi.saveInvoiceAndItems(invoice, invoiceItems);

        this.saving = false;
        this.dialogRef.close(true);
    }
}
