import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { FavorFundWeight } from "./favor-fund-weight.model";
import { FavorFundExpense } from "./favor-fund-expense.model";
import { FavorFundExpenseSummary } from "./favor-fund-expense-summary.model";

@dataSource()
export class FavorFundBudgetSummary {
    @keyColumn() id: string;
    @column() employeeId: string;
    @column() employeeName: string;
    @column() favorFundWeightId: string;
    @column() yearlyBudget: number;
    @column() year: number;
    @column() type: string;
    @column() startMonth: number;
    @column() amountAllowedOverCap: number;
    @column() allowMonthlyRollover: boolean;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate?: Date;
    @column() weightName: string;
    @column() totalUsed: number;
    @column() roleId: string;
    @column() roleName: string;
    @column() inactiveDate?: Date;
    @column() deactivatedById: string;
    @column() deactivatedByName: string;

    selected = false;
    heightClass: string;
    weight: FavorFundWeight;
    expenses: FavorFundExpenseSummary[];
    //overCapPercent: number;

    get amtAllowedOverCap() {
        return (this.amountAllowedOverCap / 100 * this.yearlyBudget);
    }

    get expensesTotal() {
        const total = this.expenses.reduce((subtotal, item) => subtotal + item.amount, 0)
        return total;
    }

    inactiveColor(month: number) {
        if (this.inactiveDate && month >= this.inactiveDate.getMonth()) return 'inactive-color';
        return '';
    }

    get totalLeft() {
        return this.yearlyBudget - this.totalUsed;
    }

    get rolloverAmount() {
        if (!this.allowMonthlyRollover) return 0;
        let amt = 0;
        this.weight.items.forEach(element => {
            if (element.month <= new Date().getMonth() && element.month >= this.startMonth) {
                amt += element.totalLeft;
            }
        });
        return amt;
    }

}