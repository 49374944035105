import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MaintenanceServiceAddOnRequestSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
    selector: 'app-maint-service-review-add-ons',
    templateUrl: './maint-service-review-add-ons.component.html',
    styleUrls: ['./maint-service-review-add-ons.component.css']
})
export class MaintServiceReviewAddOnsComponent implements OnInit {

    addOn: MaintenanceServiceAddOnRequestSummary;
    comments: string;
    cost: number;

    constructor(private maintApi: MaintenanceServiceApiService,
        private dialogService: DialogsService,
        private router: Router,
        private missionService: MissionService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params.id) {
                this.maintApi.getMaintenanceServiceAddOnRequestSummaryById(params.id).then(addOn => {
                    this.addOn = addOn;
                    if(addOn.quoteSubmittedDate) {
                        this.comments = addOn.contractorComments;
                        this.cost = addOn.price;
                    }
                });
            }
        });
    }

    async reject() {
        const result = await this.dialogService.confirm('Reject Job', 'Are you sure you want to reject this job?').toPromise();
        if (result) {
            await this.maintApi.rejectAddOnRequest(this.addOn.id);
            this.missionService.showSuccessToast('Add On Rejected');
            this.router.navigate([''], { queryParams: { selectedIndex: 0 } });
        }
    }

    async sendQuote() {
        await this.maintApi.quoteAddOn(this.addOn.id, this.comments, this.cost);
        this.missionService.showSuccessToast('Done')
    }
}
