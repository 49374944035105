import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class InvoiceItem {
    @keyColumn() id: string;
    @column() invoiceId: string;
    @column() description: string;
    @column() quantity: number;
    @column() unitPrice: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;

    // subTotal: number;

    get subTotal() {
        return this.unitPrice * this.quantity;
    }
}