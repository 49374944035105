import { ApiService } from '@cogent/client/api';
import { Injectable } from '@angular/core';
import { AuthService } from '@cogent/client/auth';
import { Address, BundleAndSave, ContractorProposedWorkOrder, CreditItemSummary, CreditSummary, CustomFunction, EntitySummary, GroupedMaintenanceServiceContractorConfigArgs, GroupedProductOfferings, InvoiceToPay, MaintenananceServiceWarrantyItem, MaintenanceService, MaintenanceServiceActivityItem, MaintenanceServiceAddOnRequestSummary, MaintenanceServiceAddServiceArgs, MaintenanceServiceBaseOffering, MaintenanceServiceCancellationArgs, MaintenanceServiceCancellationMetric, MaintenanceServiceChangePricingArgs, MaintenanceServiceContractLanguage, MaintenanceServiceContractor, MaintenanceServiceContractorRegion, MaintenanceServiceContractorRegionExcludedPostalCode, MaintenanceServiceContractorRegionSummary, MaintenanceServiceContractorScores, MaintenanceServiceCustomerPropertyServiceAppointment, MaintenanceServiceCustomerPropertyServiceAppointmentSummary, MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceCustomerPropertySummary, MaintenanceServiceFilter, MaintenanceServiceFrequencyType, MaintenanceServiceInvoice, MaintenanceServiceInvoiceItem, MaintenanceServiceInvoiceSummary, MaintenanceServiceJobOffer, MaintenanceServiceJobOfferSummary, MaintenanceServiceOffering, MaintenanceServiceOfferingAddOn, MaintenanceServiceOfferingSummaryWithPostalCode, MaintenanceServiceOrderArgs, MaintenanceServicePaymentSummary, MaintenanceServicePaymentWithoutLinesSummary, MaintenanceServicePricing, MaintenanceServicePricingInput, MaintenanceServiceRateAppointmentArgs, MaintenanceServiceSalesByMonth, MaintenanceServiceServiceableWarrantyItem, MaintenanceServiceUpdatePaymentDateArgs, NoteSummary, ObjectTag, ObjectTagSummary, PlanPricingAdjustment, PlanPricingAdjustmentItem, PromotionCode, Tag } from '@upkeeplabs/models/cogent';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { TransferMaintenanceService } from '@cogent/shared/models/maintenance-service/transfer-maintenance-service.model';

@Injectable({ providedIn: 'root' })
export class MaintenanceServiceApiService {
    getMaintenanceServicePaymentSummaryById(id: string) {
        return this.api.getSingleNode(`MaintenanceServicePaymentSummary/${id}`, null, () => new MaintenanceServicePaymentSummary());
    }
    saveAppointment(appt: MaintenanceServiceCustomerPropertyServiceAppointment) {
        return this.api.postNode('MaintenanceServiceCustomerPropertyServiceAppointment', appt);
    }
    saveContractLanguage(selectedLanguage: MaintenanceServiceContractLanguage) {
        return this.api.postNode(`MaintenanceServiceContractLanguage`, selectedLanguage);
    }
    async transferService(args: TransferMaintenanceService) {
        const offer = new MaintenanceServiceJobOffer();
        offer.id = UtilitiesService.newid();
        offer.contractorId = args.contractorId;
        offer.maintenanceServiceCustomerPropertyServiceId = args.maintenanceServiceCustomerPropertyServiceId;

        await this.api.postNode(`scheduled-maintenance/transfer-service`, { offer: offer })
        // await this.api.patchNode(`MaintenanceServiceCustomerPropertyService/${args.maintenanceServiceCustomerPropertyServiceId}`, { contractorId: null });
        // await this.api.postNode(`MaintenanceServiceJobOffer`, offer);

    }
    updateHolder(id: string, customerId: any) {
        return this.api.patchNode(`MaintenanceServiceCustomerProperty/${id}`, { customerId })
    }
    createNewOffer(maintenanceServiceCustomerPropertyServiceId: string, contractorId: string) {
        return this.api.postNode(`scheduled-maintenance/create-job-offer`, {
            maintenanceServiceCustomerPropertyServiceId,
            contractorId
        });
    }

    getSalesYTD() {
        return this.api.getArrayNode('scheduled-maintenance/maintenance-service-sales-ytd', null, () => new MaintenanceServiceSalesByMonth());
    }

    getBundleAndSaveDiscount() {
        return this.api.getSingleNode('scheduled-maintenance/current-bundle-and-save', null, () => new BundleAndSave());
    }

    async saveBundleAndSaveDicount(newBundleAndSave: BundleAndSave) {
        const items = await this.api.getArrayNode(`BundleAndSave`, { archivedDate_eq: '{{null}}' }, () => new BundleAndSave());
        for (const item of items) {
            item.archivedDate = new Date();
            await this.api.postNode(`BundleAndSave`, item);
        }
        newBundleAndSave.configuration = JSON.stringify(newBundleAndSave.config);

        await this.api.postNode(`BundleAndSave`, newBundleAndSave);
    }

    getBaseOfferings() {
        return this.api.getArrayNode('scheduled-maintenance/base-hms', null, () => new MaintenanceServiceBaseOffering());
    }

    async getContractLanguage(maintenanceServiceId: string) {
        const items = await this.getAllContractLanguage(maintenanceServiceId);
        return items.filter(i => !i.archived);
    }

    getAllContractLanguage(maintenanceServiceId: string) {
        return this.api.getArrayNode(`MaintenanceServiceContractLanguage`, { maintenanceServiceId_eq: maintenanceServiceId }, () => new MaintenanceServiceContractLanguage())
    }

    getUnpaidInvoices(maintenanceServiceCustomerPropertyId: string) {
        return this.api.getArrayNode(`scheduled-maintenance/get-unpaid-invoices/${maintenanceServiceCustomerPropertyId}`, null, () => new MaintenanceServiceInvoiceSummary());
    }

    getActiveMaintServices() {
        return this.api.getArrayNode('MaintenanceServiceCustomerPropertyServiceSummary', { select: 'id,name', cancellationDate_eq: '{{null}}' });
    }

    getWigGoal() {
        return this.api.getSingleNode('WigGoal');
    }

    getWigCancellationRate() {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        return this.api.getArrayNode('MaintenanceServiceCancellationMetric', { orderby: 'reportDate desc', reportDate_lte: endDate, reportDate_gte: startDate }, () => new MaintenanceServiceCancellationMetric());
    }

    getWigGoalBreakdown() {
        return this.api.getArrayNode('MaintenanceServiceWIGCounts', { orderby: 'type' });
    }
    updateCustomerPropertyStatus(id: string, newStatus: string) {
        return this.api.patchNode(`MaintenanceServiceCustomerProperty/${id}`, { status: newStatus });
    }

    updateInvoiceItemWithAppointment(appointmentId: string, invoiceItemId: string) {
        return this.api.patchNode(`MaintenanceServiceInvoiceItem/${invoiceItemId}`, { maintenanceServiceCustomerPropertyServiceAppointmentId: appointmentId });
    }
    replaceMaintenanceServiceTags(objectId: string, tags: Tag[]) {
        return this.api.patchNode(`scheduled-maintenance/replace-tags/${objectId}`, tags);
    }
    getItemPictureUrl(maintenanceServiceOfferingId: string) {
        return `${ApiService.endPointNode}scheduled-maintenance/item-picture/${maintenanceServiceOfferingId}`;
    }
    deleteInvoice(id: string) {
        return this.api.deleteNode(`MaintenanceServiceInvoice/${id}`);
    }

    getPromotion(code: string) {
        return this.api.getSingleNode(`scheduled-maintenance/promotion-code/${code}`, null, () => new PromotionCode());
    }

    getMaintenanceServiceContractorRegionExcludedPostalCodes(maintenanceServiceContractorRegionId: string) {
        return this.api.getArrayNode(`MaintenanceServiceContractorRegionExcludedPostalCode`, { MaintenanceServiceContractorRegionId_eq: maintenanceServiceContractorRegionId }, () => new MaintenanceServiceContractorRegionExcludedPostalCode());
    }

    saveMaintenanceServiceContractorRegionExcludedPostalCodes(item: MaintenanceServiceContractorRegionExcludedPostalCode) {
        return this.api.postNode('MaintenanceServiceContractorRegionExcludedPostalCode', item);
    }

    getAdjustment(id: string) {
        return this.api.getSingleNode(`scheduled-maintenance/adjustment/${id}`, null, () => new PlanPricingAdjustment());
    }

    getAdjustmentItems(id: string) {
        return this.api.getArrayNode(`scheduled-maintenance/adjustment-items/${id}`, null, () => new PlanPricingAdjustmentItem());
    }

    rescheduleAppointment(appointment: MaintenanceServiceCustomerPropertyServiceAppointment, newDate: Date, notifyCustomer: boolean, scheduledStartWindow: string, scheduledEndWindow: string) {
        return this.api.patchNode(`scheduled-maintenance/reschedule-appointment`, {
            appointment,
            newDate,
            notifyCustomer,
            scheduledStartWindow,
            scheduledEndWindow,
        });
    }

    constructor(
        private api: ApiService,
        private authService: AuthService,
        private entityApi: EntityApiService
    ) { }

    saveAddOn(addOn: MaintenanceServiceOfferingAddOn) {
        return this.api.postNode('MaintenanceServiceOfferingAddOn', addOn);
    }

    getAddOnOfferingsByProperty(maintenanceServiceCustomerPropertyId: string, contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceAddOnRequestSummary`, { contractorId_eq: contractorId, maintenanceServiceCustomerPropertyId_eq: maintenanceServiceCustomerPropertyId, orderby: 'createdDate DESC' },
            () => new MaintenanceServiceAddOnRequestSummary());
    }

    getMaintenanceServiceServiceableWarrantyItems(maintenanceServiceId: string) {
        return this.api.getArrayNode(`MaintenanceServiceServiceableWarrantyItem`, { MaintenanceServiceId_eq: maintenanceServiceId }, () => new MaintenanceServiceServiceableWarrantyItem());
    }

    getTags(maintenanceServiceCustomerPropertyServiceId) {
        return this.api.getArrayNode(`ObjectTagSummary`, { objectId_eq: maintenanceServiceCustomerPropertyServiceId, orderby: 'CreatedDate' }, () => new ObjectTagSummary());
    }

    deleteAddOn(id: string) {
        return this.api.deleteNode(`MaintenanceServiceOfferingAddOn/${id}`);
    }

    rejectJob(id: string) {
        return this.api.patchNode(`scheduled-maintenance/reject-job/${id}`, null);
    }

    updateMaintenanceServiceDynamicPrice(address: Address, offering: MaintenanceServiceOfferingSummaryWithPostalCode) {
        return this.api.postSingleNode('scheduled-maintenance/reprice-dynamic-option', { address, offering }, null, () => new MaintenanceServiceOfferingSummaryWithPostalCode());
    }

    getContractorInvoicesForProperty(propertyId: string) {
        return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`, { invoiceType_eq: 'AP', maintenanceServiceCustomerPropertyId_eq: propertyId, orderby: 'date desc' }, () => new MaintenanceServiceInvoiceSummary());

    }

    getPendingMaintenanceInvoices(contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`, { invoiceType_eq: 'AP', payeeId_eq: contractorId, orderby: 'Date', exportDate_eq: '{{null}}' })
    }

    async saveInvoiceAndItems(invoice: MaintenanceServiceInvoice, items: MaintenanceServiceInvoiceItem[]) {
        await this.api.postNode('MaintenanceServiceInvoice', invoice);
        for (const item of items) {
            await this.api.postNode('MaintenanceServiceInvoiceItem', item);
        }

        return true;
    }

    saveContractorProposedWorkOrder(wo: ContractorProposedWorkOrder) {
        return this.api.postNode('ContractorProposedWorkOrder', wo);
    }


    toggleMaintenanceServiceView(entityId: string, value: boolean) {
        return this.api.patchNode(`entity/${entityId}`, { isMaintenanceServiceContractor: value });
    }

    clearDefaultCard(id: string) {

        return this.api.patchNode(`MaintenanceServiceCustomerProperty/${id}`, {
            stripeCardId: null,
            creditCardExpirationDate: null
        });
    }
    updateDefaultCard(id: string, id1: string, exp_month: number, exp_year: number) {
        const expirationDate = new Date();
        expirationDate.setMonth(exp_month);
        expirationDate.setFullYear(exp_year);
        expirationDate.setDate(1);

        return this.api.patchNode(`MaintenanceServiceCustomerProperty/${id}`, {
            stripeCardId: id1,
            creditCardExpirationDate: expirationDate,
        });
    }

    quoteAddOn(id: string, contractorComments: string, price: number) {
        return this.api.patchNode(`MaintenanceServiceAddOnRequest/${id}`, { contractorComments, price, quoteSubmittedDate: new Date() });
    }

    makeCreditPayment(customerId: string, invoicesToPay: InvoiceToPay[]) {
        return this.api.postNode(`scheduled-maintenance/make-credit-payment/${customerId}`, invoicesToPay);
    }

    getMaintenanceServiceOfferings(): Promise<MaintenanceServiceOffering[]> {
        return this.api.getArrayNode('MaintenanceServiceOffering', null, () => new MaintenanceServiceOffering());
    }

    getMaintenanceServiceAddOnRequestSummaryById(id: string) {
        return this.api.getSingleNode(`MaintenanceServiceAddOnRequestSummary/${id}`, null, () => new MaintenanceServiceAddOnRequestSummary());
    }

    rejectAddOnRequest(id: string) {
        return this.api.patchSingleNode(`MaintenanceServiceAddOnRequest/${id}`, { rejectedDate: new Date() });
    }

    approveAddOnBid(id: string) {
        return this.api.patchSingleNode(`MaintenanceServiceAddOnRequest/${id}`, { approvedDate: new Date() });
    }

    getAddOnsForOffering(maintenanceServiceOfferingId: string) {
        return this.api.getArrayNode(`MaintenanceServiceOfferingAddOn`, { maintenanceServiceOfferingId_eq: maintenanceServiceOfferingId }, () => new MaintenanceServiceOfferingAddOn());
    }

    updatePreferredDay(maintenanceServiceCustomerPropertyServiceId: string, newPreferredDayOfWeek: number) {
        // return this.api.patch2(`MaintenanceServiceCustomerPropertyService/${id}`, { preferredDay });
        return this.api.patchNode(`scheduled-maintenance/update-preferred-date`, { maintenanceServiceCustomerPropertyServiceId, newPreferredDayOfWeek });
    }

    getAppointmentsForServiceAndTimeRange(maintenanceServiceCustomerPropertyServiceId: string, startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`,
            { maintenanceServiceCustomerPropertyServiceId_eq: maintenanceServiceCustomerPropertyServiceId, scheduledDate_gte: startDate, scheduledDate_lte: endDate, orderby: 'ScheduledDate' },
            () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }

    updateContractorOnService(maintenanceServiceCustomerPropertyServiceId: string, contractorId: string) {
        return this.api.patchNode(`scheduled-maintenance/update-contractor-on-service`, { maintenanceServiceCustomerPropertyServiceId, contractorId });
    }

    getServiceCancellationArgs(id: string) {
        return this.api.getSingleNode(`scheduled-maintenance/service-cancellation-args/${id}`, null, () => new MaintenanceServiceCancellationArgs());
    }

    getContractorsForPostalCode(postalCode: string, maintenanceServiceId: string) {
        return this.api.getArrayNode(`scheduled-maintenance/get-contractors`, { postalCode, maintenanceServiceId });
    }

    getOffersForService(maintenanceServiceCustomerPropertyServiceId: string) {
        return this.api.getArrayNode(`MaintenanceServiceJobOfferSummary`,
            { maintenanceServiceCustomerPropertyServiceId_eq: maintenanceServiceCustomerPropertyServiceId, orderby: 'SentDate desc' },
            () => new MaintenanceServiceJobOfferSummary());
    }

    changeServiceFrequency(maintenanceServicePropertyServiceId: string, offering: MaintenanceServiceOfferingSummaryWithPostalCode) {
        return this.api.patchNode(`scheduled-maintenance/change-frequency/${maintenanceServicePropertyServiceId}`, offering);
    }

    updateCustomerOnProperty(maintenanceServicePropertyId: string, customerId: string) {
        return this.api.patchNode(`MaintenanceServiceCustomerProperty/${maintenanceServicePropertyId}`, { customerId });
    }

    getContractorInvoicesToExport(startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`,
            { invoiceType_eq: 'AP', exportDate_eq: '{{null}}', payeeId_neq: '{{null}}', date_gte: startDate, date_lte: endDate },
            () => new MaintenanceServiceInvoiceSummary());
    }

    generateInvoicesForCompletedAppointments() {
        return this.api.postNode('scheduled-maintenance/invoice-completed-appointments');
    }

    exportContractorInvoices(invoices: MaintenanceServiceInvoiceSummary[]) {
        return this.api.postNode(`accounting/export/maintenance-service-invoices`, invoices);
    }

    getContractorSurveyScores(contractorId: string, startDate: Date, endDate: Date) {
        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);

        return this.api.getArrayNode(`MaintenanceServiceContractorScores`, { contractorId_eq: contractorId, reportDate_gte: startDate, reportDate_lte: endDate, orderby: 'ReportDate' }, () => new MaintenanceServiceContractorScores());
    }

    getSurveys(contractorId: string, startDate: Date = null, resultCount = 10) {
        if (!startDate) {
            startDate = new Date();
        }

        return this.api.getArrayNode(
            'MaintenanceServiceCustomerPropertyServiceAppointmentSummary', {
            contractorId_eq: contractorId,
            orderby: 'SurveyDate descending',
            take: resultCount,
            serviceRating_neq: '{{null}}',
            surveyDate_lt: startDate,
        }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary());
    }


    cancelAppointment(id: string, doRefund: boolean) {
        return this.api.patchNode(`scheduled-maintenance/cancel-appointment/${id}?doRefund=${doRefund}`, null);
    }

    uncancelAppointment(id: string) {
        return this.api.patchNode(`scheduled-maintenance/uncancel-appointment/${id}`, null);
    }

    getCreditsForProperty(maintenanceServiceCustomerPropertyId: string) {
        return this.api.getArrayNode('CreditSummary', {
            policyId_eq: maintenanceServiceCustomerPropertyId,

        }, () => new CreditSummary());
    }

    getCreditsForCustomer(customerId: string) {
        return this.api.getArrayNode('CreditSummary', {
            customerId_eq: customerId,
            orderby: 'CreatedDate',
            isAvailable_eq: true,
        }, () => new CreditSummary());
    }

    updateApointmentDateOnAppointment(id: string, date: Date) {
        date.setHours(12);
        return this.api.patchNode(`MaintenanceServiceCustomerPropertyServiceAppointment/${id}`, { scheduledDate: date });
    }

    getContractorByServiceAndPostalCode(postalCode: string, maintenanceServiceId: string) {
        return this.api.getArrayNode(`scheduled-maintenance/contractors-by-service-and-postal-code`, { postalCode, maintenanceServiceId },
            () => new EntitySummary());
    }


    updatePaymentDate(id: string, args: MaintenanceServiceUpdatePaymentDateArgs) {
        return this.api.patchNode(`scheduled-maintenance/update-payment-date/${id}`, args);
    }

    getPropertyNotes(maintenanceServiceCustomerPropertyId: string) {

        return this.api.getArrayNode(`NoteSummary`, { maintenanceServiceCustomerPropertyId_eq: maintenanceServiceCustomerPropertyId }, () => new NoteSummary());
    }

    setCustomerPropertyServicePreferredDay(id: string, day: number) {
        return this.api.patchNode(`scheduled-maintenance/accept-job/${id}`, { preferredDay: day });
    }

    acceptJob(id: string, day: number) {
        return this.api.patchNode(`scheduled-maintenance/accept-job/${id}`, { preferredDay: day });
    }

    saveMaintenanceServiceOffering(offering: MaintenanceServiceOffering) {
        return this.api.postSingleNode('MaintenanceServiceOffering', offering);
    }

    rateAppointment(id: string, args: MaintenanceServiceRateAppointmentArgs) {
        return this.api.postNode(`scheduled-maintenance/rate-service/${id}`, args);
    }

    getPaymentsForProperty(maintenanceServiceCustomerPropertyId: string) {
        return this.api.getArrayNode(`MaintenanceServicePaymentSummary`, { maintenanceServiceCustomerPropertyId_eq: maintenanceServiceCustomerPropertyId, orderby: 'createdDate' }, () => new MaintenanceServicePaymentSummary());
    }

    getPaymentsForPropertyWithoutLines(maintenanceServiceCustomerPropertyId: string) {
        return this.api.getArrayNode(`MaintenanceServicePaymentWithoutLinesSummary`, { maintenanceServiceCustomerPropertyId_eq: maintenanceServiceCustomerPropertyId, orderby: 'createdDate' }, () => new MaintenanceServicePaymentWithoutLinesSummary());

    }

    async updateInvoice(invoice: MaintenanceServiceInvoiceSummary) {
        invoice.amount = invoice.items.map(i => i.amount).reduce((a, b) => a + b);
        await this.api.patchNode(`MaintenanceServiceInvoice/${invoice.id}`, invoice);
        for (const item of invoice.items) {
            await this.api.patchNode(`MaintenanceServiceInvoiceItem/${item.id}`, item);
        }
    }

    getStreetViewUrl300x300(addressId: string): string {
        if (!addressId) {
            return null;
        }
        const url = `${ApiService.endPointNode}address/street-view/${addressId}?height=300&width=300`;
        return url;
    }

    getCogentCustomFunctions() {
        return this.api.getArrayNode(`CustomFunction`, { availableOnCogentMaintenanceService_eq: true }, () => new CustomFunction());
    }

    getMaintenanceServiceJobOfferSummary(id: string) {
        return this.api.getSingleNode(`scheduled-maintenance/get-offer/${id}`, null, () => new MaintenanceServiceJobOfferSummary());
    }

    getMaintenanceServiceOfferWasAccepted(id: string) {
        return this.api.getSingleNode(`scheduled-maintenance/offer-was-accepted/${id}`);
    }

    getInvoicesForProperty(propertyId: string) {
        return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`, { MaintenanceServiceCustomerPropertyId_eq: propertyId, orderby: 'date' }, () => new MaintenanceServiceInvoiceSummary());
    }

    getCreditItemSummariesForProperty(propertyId: string) {
        return this.api.getArrayNode(`CreditItemSummary`, { maintenanceServiceCustomerPropertyId_eq: propertyId }, () => new CreditItemSummary());
    }

    getMaintenanceServiceFilters(maintenanceServiceId: string) {
        return this.api.getArrayNode(`MaintenanceServiceFilter`, { maintenanceServiceId_eq: maintenanceServiceId }, () => new MaintenanceServiceFilter());
    }

    getMaintServiceCustomerPropertyById(id: string) {
        return this.api.getSingleNode(`MaintenanceServiceCustomerPropertySummary/${id}`, null, () => new MaintenanceServiceCustomerPropertySummary());
    }

    getMaintServiceCustomerPropertyByAddressId(id: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertySummary`, { propertyId_eq: id }, () => new MaintenanceServiceCustomerPropertySummary());
    }

    getFrequencyChangeDifferential(maintenanceServiceCustomerPropertyServiceId: string, offering: MaintenanceServiceOfferingSummaryWithPostalCode) {
        return this.api.patchSingleNode(`scheduled-maintenance/get-offering-differential?maintenanceServiceCustomerPropertyServiceId=${maintenanceServiceCustomerPropertyServiceId}`,
            offering);
    }

    getPropertyActivityStream(id: string) {
        return this.api.getArrayNode(`MaintenanceServiceActivityItem`, { maintenanceServiceCustomerPropertyId_eq: id, orderby: 'createdDate desc' }, () => new MaintenanceServiceActivityItem());
    }

    getCustomerPropertyServicesByPropertyAndContractor(customerPropertyId: string, contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceSummary`, { MaintenanceServiceCustomerPropertyId_eq: customerPropertyId, CancellationDate_eq: '{{null}}', contractorId_eq: contractorId }, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    getCancelledCustomerPropertyServicesByPropertyAndContractor(customerPropertyId: string, contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceSummary`, { MaintenanceServiceCustomerPropertyId_eq: customerPropertyId, CancellationDate_neq: '{{null}}', contractorId_eq: contractorId }, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    getMaintenanceServiceInvoicesByPropertyAndContractor(customerPropertyId: string, contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`, { maintenanceServiceCustomerPropertyId_eq: customerPropertyId, payeeId_eq: contractorId, orderby: 'date desc' }, () => new MaintenanceServiceInvoiceSummary());
    }

    saveMaintenanceServicePricing(pricing: MaintenanceServicePricing) {
        return this.api.postSingleNode('MaintenanceServicePricing', pricing);
    }

    async getPendingOffers() {
        const loggedInUser = await this.entityApi.getLoggedInUser();

        return this.api.getArrayNode(`MaintenanceServiceJobOfferSummary`, { contractorId_eq: loggedInUser.id, accepted_eq: '{{null}}', rejectedDate_eq: '{{null}}' }, () => new MaintenanceServiceJobOfferSummary());
    }

    async getCustomerPropertyServiceForLoggedInContractor() {
        const loggedInUser = await this.entityApi.getLoggedInUser();

        return this.api.getArrayNode('MaintenanceServiceCustomerPropertyServiceSummary', { contractorId_eq: loggedInUser.id, cancellationDate_eq: '{{null}}' }, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    async getPendingAddOnRequestForLoggedInContractor() {
        const loggedInUser = await this.entityApi.getLoggedInUser();

        return this.api.getArrayNode(`MaintenanceServiceAddOnRequestSummary`, { contractorId_eq: loggedInUser.id, rejectedDate_eq: '{{null}}', approvedDate_eq: '{{null}}', orderby: 'CreatedDate' }, () => new MaintenanceServiceAddOnRequestSummary());
    }

    getPropertyMeta(address: string, city: string, state: string, postalCode: string): Promise<any> {

        return this.api.getSingleNode(`scheduled-maintenance/property-data`, { address, city, state, postalCode }, null, true);
    }

    async getCustomerPropertyServiceCancellationsForLoggedInContractor() {
        const loggedInUser = await this.entityApi.getLoggedInUser();

        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceSummary`, { contractorId_eq: loggedInUser.id, cancellationDate_neq: '{{null}}', orderby: 'cancellationdate desc' }, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    async getAppointmentsForLoggedInContractor() {
        const dt = new Date();
        dt.setDate(dt.getDate() - 14);
        const loggedInUser = await this.entityApi.getLoggedInUser();

        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 120);

        return this.api.getArrayNode('MaintenanceServiceCustomerPropertyServiceAppointmentSummary', { contractorId_eq: loggedInUser.id, orderby: 'scheduledDate', scheduledDate_lt: endDate, scheduledDate_gt: dt, cancellationDate_eq: '{{null}}', serviceCancellationDate_eq: '{{null}}' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary());
    }

    transferAppointment(appointmentId: string, newContractorId: string) {
        return this.api.patchNode(`MaintenanceServiceCustomerPropertyServiceAppointment/${appointmentId}`, { contractorId: newContractorId });
    }

    getAppointmentSummary(appointmentId: string) {
        return this.api.getSingleNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary/${appointmentId}`, null, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary());
    }

    getCustomerPropertyServiceSummary(maintenanceServiceCustomerPropertyServiceId: string) {
        return this.api.getSingleNode(`MaintenanceServiceCustomerPropertyServiceSummary/${maintenanceServiceCustomerPropertyServiceId}`, null, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    getMainServiceAppointmentSummaryNoAuth(id: string) {
        return this.api.getSingleNode(`scheduled-maintenance/get-appointment-summary/${id}`, null, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary());
    }

    getAppointmentImagesNoAuth(id: string) {
        return this.api.getArrayNode(`scheduled-maintenance/get-appointment-photos/${id}`);
    }

    deleteMaintenanceServicePricing(pricing: MaintenanceServicePricing) {
        return this.api.deleteNode(`MaintenanceServicePricing/${pricing.id}`);
    }

    getMaintenanceServiceContractorRegionSummaryForContractor(contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceContractorRegionSummary`, { ContractorId_eq: contractorId }, () => new MaintenanceServiceContractorRegionSummary());
    }

    async getMaintenanceServiceContractorsByServiceAndArea(maintenanceServiceId: string, productAreaId: string) {
        const one = await this.api.getArrayNode(`MaintenanceServiceContractorRegionSummary`, { contractorRegionId_eq: productAreaId, maintenanceServiceId_eq: maintenanceServiceId }, () => new MaintenanceServiceContractorRegionSummary());
        const two = await this.api.getArrayNode(`MaintenanceServiceContractorRegionSummary`, { contractorRegionParentId_eq: productAreaId, maintenanceServiceId_eq: maintenanceServiceId }, () => new MaintenanceServiceContractorRegionSummary());
        const three = await this.api.getArrayNode(`MaintenanceServiceContractorRegionSummary`, { ContractorRegionGrandParentId_eq: productAreaId, maintenanceServiceId_eq: maintenanceServiceId }, () => new MaintenanceServiceContractorRegionSummary());

        return one.concat(two).concat(three);
    }

    getServiceAreas() {
        return this.api.getArrayNode('Entity', { type_eq: 'ServiceProManagerArea', select: 'id,name,parentId', orderby: 'name' });
    }

    getServiceTerritories() {
        return this.api.getArrayNode('Entity', { type_eq: 'ServiceProTerritory', select: 'id,name,parentId', orderby: 'name' });

    }

    getServiceProManagerRegions() {
        return this.api.getArrayNode('Entity', { type_eq: 'ServiceProRegion', select: 'id,name,parentId', orderby: 'name' });

    }

    getMaintServiceSummaries(loginId: string) {
        if (!loginId) {
            if (this.authService.parsedJwt?.sub) {
                return this.api.getArrayNode(`MaintenanceServiceCustomerPropertySummary`, { customerId_eq: this.authService.parsedJwt.sub }, () => new MaintenanceServiceCustomerPropertySummary())
            }
            loginId = UtilitiesService.newid();
        }
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertySummary`, { loginId_eq: loginId }, () => new MaintenanceServiceCustomerPropertySummary())
    }

    getMaintServiceSummariesByCustomerId(customerId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertySummary`, { customerId_eq: customerId }, () => new MaintenanceServiceCustomerPropertySummary())
    }

    // getAppointmentsForLogin(loginId: string) {
    //     const dt = new Date();

    //     return this.api.getArray2(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`, { loginId_eq: loginId, cancellationDate_eq: '{{null}}' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary());
    // }

    completeAppointment(args) {
        return this.api.postSingleNode(`scheduled-maintenance/complete-appointment`, args);
    }

    getMaintServiceSummaryById(id: string) {
        return this.api.getSingleNode(`MaintenanceServiceCustomerPropertySummary/${id}`, null, () => new MaintenanceServiceCustomerPropertySummary());
    }

    getAddress(id: string) {
        return this.api.getSingleNode(`Address/${id}`, null, () => new Address());
    }

    getMaintServiceCustomerPropertyServiceSummariesForProperty(maintenanceServiceCustomerPropertyId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceSummary`, { maintenanceServiceCustomerPropertyId_eq: maintenanceServiceCustomerPropertyId }, () => new MaintenanceServiceCustomerPropertyServiceSummary())
    }

    getMaintenanceServiceCustomerPropertyServiceSummariesByAddress(addressId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceSummary`, { addressId_eq: addressId }, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    getMaintenanceServiceCustomerPropertyServiceSummariesById(id: string) {
        return this.api.getSingleNode(`MaintenanceServiceCustomerPropertyServiceSummary`, { id_eq: id }, () => new MaintenanceServiceCustomerPropertyServiceSummary());
    }

    getMaintenanceServiceCustomerPropertyServiceAppointmentSummaryForService(maintServiceCustomerPropertyServiceId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`, { maintenanceServiceCustomerPropertyServiceId_eq: maintServiceCustomerPropertyServiceId, orderby: 'scheduledDate' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }

    getMaintenanceServiceWarrantyItems(maintenanceServiceCustomerPropertyServiceId: string) {
        return this.api.getArrayNode(`scheduled-maintenance/warranty-items-for-maintenance-service/${maintenanceServiceCustomerPropertyServiceId}`, null, () => new MaintenananceServiceWarrantyItem());
    }

    getMaintenanceServiceCustomerPropertyServiceAppointmentSummaryForProperty(maintServiceCustomerPropertyId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`, { maintenanceServiceCustomerPropertyId_eq: maintServiceCustomerPropertyId, orderby: 'scheduledDate' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }

    getAppointmentsForProperty(maintenanceServiceCustomerPropertyId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`,
            { MaintenanceServiceCustomerPropertyId_eq: maintenanceServiceCustomerPropertyId, orderby: 'scheduledDate' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }
    getAppointmentsForCustomer(customerId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`,
            { CustomerId_eq: customerId, orderby: 'scheduledDate' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }

    testUpdateProperty(id: string) {
        return this.api.patchNode(`MaintenanceServiceCustomerProperty/${id}`, { lastModifiedDate: new Date() });
    }

    manuallyUpdateSearchIndex(id: string) {
        return this.api.patchNode(`search/update-index/${id}/MaintenanceServiceCustomerProperty`, null);
    }

    updatePricing(args: MaintenanceServiceChangePricingArgs) {
        return this.api.patchNode(`scheduled-maintenance/update-pricing`, args);
    }

    getAppointmentsForLogin(loginId: string) {
        if (!loginId) {
            loginId = UtilitiesService.newid();
        }
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`,
            { LoginId_eq: loginId, orderby: 'scheduledDate', cancellationDate_eq: '{{null}}' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }

    getAppointmentsForContractor(contratorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceCustomerPropertyServiceAppointmentSummary`,
            { ContractorId_eq: contratorId, orderby: 'scheduledDate', cancellationDate_eq: '{{null}}' }, () => new MaintenanceServiceCustomerPropertyServiceAppointmentSummary())
    }

    deleteMaintenanceServiceOffering(id: string) {
        return this.api.deleteNode(`MaintenanceServiceOffering/${id}`);
    }

    getMaintenanceOfferingsForAddress(address: Address) {
        return this.api.postArrayNode('scheduled-maintenance/get-offerings', address, null, () => new GroupedProductOfferings());
    }

    getMaintenanceOfferingForAddressId(addressId: string) {
        return this.api.getArrayNode(`scheduled-maintenance/get-offerings-by-id/${addressId}`, null, () => new GroupedProductOfferings());

    }

    unDeleteMaintenanceServiceOffering(id: string) {
        return this.api.unDeleteNode(`MaintenanceServiceOffering/${id}`);
    }

    cancelMaintenanceService(args: MaintenanceServiceCancellationArgs) {
        return this.api.patchNode(`scheduled-maintenance/cancel-service`, args);
    }

    getMaintenanceServices(): Promise<MaintenanceService[]> {
        return this.api.getArrayNode('MaintenanceService', { orderby: 'name' }, () => new MaintenanceService());
    }

    getMaintenanceServiceContractors(contractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceContractor`, { entityId_eq: contractorId }, () => new MaintenanceServiceContractor());
    }

    getEntityById(id: string) {
        return this.api.getSingleNode(`EntitySummary`, { id_eq: id });
    }

    getMaintenanceServiceContractorRegions(maintenanceServiceContractorId: string) {
        return this.api.getArrayNode(`MaintenanceServiceContractorRegion`, { maintenanceServiceContractorId_eq: maintenanceServiceContractorId }, () => new MaintenanceServiceContractorRegion());
    }

    saveContractorConfig(groups: GroupedMaintenanceServiceContractorConfigArgs[]) {
        return this.api.postSingleNode('scheduled-maintenance/save-contractor-config', groups);
    }

    getMaintenanceServiceById(id: string): Promise<MaintenanceService> {
        return this.api.getSingleNode('MaintenanceService', { id_eq: id }, () => new MaintenanceService());
    }

    getPricingRecords(maintenanceServiceOfferingId: string): Promise<MaintenanceServicePricing[]> {
        return this.api.getArrayNode('MaintenanceServicePricing', { MaintenanceServiceOfferingId_eq: maintenanceServiceOfferingId, orderby: 'RangeStart' }, () => new MaintenanceServicePricing());
    }

    deleteMaintenanceServiceServiceableWarrantyItem(id: string) {
        return this.api.deleteNode(`MaintenanceServiceServiceableWarrantyItem/${id}`);
    }

    saveMaintenanceServiceServiceableWarrantyItem(item: MaintenanceServiceServiceableWarrantyItem) {
        return this.api.postNode(`MaintenanceServiceServiceableWarrantyItem`, item);
    }

    saveMaintenanceService(service: MaintenanceService) {
        return this.api.postSingleNode('MaintenanceService', service);
    }

    deleteMaintenanceService(id: string) {
        return this.api.deleteNode(`MaintenanceService/${id}`);
    }

    unDeleteMaintenanceService(id: string) {
        return this.api.unDeleteNode(`MaintenanceService/${id}`);
    }


    getMaintenanceServiceFrequencyTypes(): Promise<MaintenanceServiceFrequencyType[]> {
        return this.api.getArrayNode('MaintenanceServiceFrequencyType', { orderby: 'name' }, () => new MaintenanceServiceFrequencyType());
    }
    deleteMaintenanceServiceFrequencyType(id: string) {
        return this.api.deleteNode(`MaintenanceServiceFrequencyType/${id}`);
    }
    unDeleteMaintenanceServiceFrequencyType(id: string) {
        return this.api.unDeleteNode(`MaintenanceServiceFrequencyType/${id}`);
    }
    saveMaintenanceServiceFrequencyType(selectedService: MaintenanceServiceFrequencyType) {
        return this.api.postSingleNode('MaintenanceServiceFrequencyType', selectedService);
    }


    saveMaintenanceServicePricingInput(selectedService: MaintenanceServicePricingInput) {
        return this.api.postSingleNode('MaintenanceServicePricingInput', selectedService);
    }
    unDeleteMaintenanceServicePricingInput(id: string) {
        return this.api.unDeleteNode(`MaintenanceServicePricingInput/${id}`);
    }
    deleteMaintenanceServicePricingInput(id: string) {
        return this.api.deleteNode(`MaintenanceServicePricingInput/${id}`);
    }
    getMaintenanceServicePricingInputs() {
        return this.api.getArrayNode('MaintenanceServicePricingInput', { orderby: 'name' }, () => new MaintenanceServicePricingInput());
    }

    order(args: MaintenanceServiceOrderArgs) {
        return this.api.postSingleNode(`scheduled-maintenance/order`, args);
    }

    addServices(args: MaintenanceServiceAddServiceArgs) {
        return this.api.postSingleNode(`scheduled-maintenance/add-services`, args);
    }

    maintServicesAvailable() {
        return this.api.getSingleNode(`scheduled-maintenance/maintenance-services-active`);

    }

    getUnpaidInvoicesForLogin(loginId: string, customerId: string) {
        if (!loginId) {
            return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`, { payerId_eq: customerId, orderby: 'date', status_neq: 'Paid', invoiceType_eq: 'AR' }, () => new MaintenanceServiceInvoiceSummary());
        } else {
            return this.api.getArrayNode(`MaintenanceServiceInvoiceSummary`, { loginId_eq: loginId, orderby: 'date', status_neq: 'Paid', invoiceType_eq: 'AR' }, () => new MaintenanceServiceInvoiceSummary());
        }
    }
}