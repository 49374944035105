import { SalesItemCoverageClient } from "@cogent/client/shared/models/plans/sales-item-coverage-client.model";
import { SalesItem } from "@upkeeplabs/models/cogent";


export class SalesItemClient extends SalesItem {
    categoryName: string;
    salesItemCoverageId?: string;
    tradeCategorySort: number;
    showOnWeb: boolean;

    // Client Side Only
    coverages: SalesItemCoverageClient[];
    showInactive = false;
    url: string;
}