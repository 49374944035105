export class AuthorizationsQueueItem {
    id: string;
    workOrderNumber: number;
    policyNumber: number;
    address1: string;
    city: string;
    state: string;
    postalCode: string;
    contractorName: string;
    description: string;
    createdDate: Date;
    itemName: string;
    workOrderId: string;

    get timeOpen() {
        let miliseconds = new Date().getTime() - this.createdDate.getTime();

        const days = Math.floor(miliseconds / 86400000);
        miliseconds = miliseconds - days * 86400000;
        const hours = Math.floor(miliseconds / 3600000);
        miliseconds = miliseconds - hours * 3600000;

        const minutes = Math.floor(miliseconds / 60000);

        let result = '';
        if (days > 0) {
            if (days === 1) {
                result = days + ' day ';
            } else {
                result = days + ' days ';
            }
        }
        if (hours > 0) {
            if (hours === 1) {
                result += hours + ' hour ';
            } else {
                result += hours + ' hours ';
            }
        }
        if (minutes > 0) {
            if (minutes === 1) {
                result += minutes + ' minute';
            } else {
                result += minutes + ' minutes';
            }
        }
        return result;
    }
}
