import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Tag } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-tag-chip-selection',
    templateUrl: './tag-chip-selection.component.html',
    styleUrls: ['./tag-chip-selection.component.css']
})
export class TagChipSelectionComponent implements OnInit {

    allTags: Tag[] = [];

    @Input() selectedTags: Tag[] = [];
    @Output() selectedTagsChange: EventEmitter<Tag[]> = new EventEmitter();
    @Input() placeholder: string;
    @Input() singleSelect: boolean;
    @Input() viewWorkOrderTags: boolean;
    @Input() viewPolicyTags: boolean;
    @Input() viewEntityTags: boolean;
    @Input() viewTaskTags: boolean;
    @Input() viewContractorInvoiceTags: boolean;
    @Input() viewPurchaseOrderTags: boolean;
    @Input() viewInvoiceTags: boolean;
    @Input() viewCustomTableTags: boolean;
    @Input() viewMaintenanceServiceTags: boolean;
    @Input() defaultIds: string;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new UntypedFormControl();
    filteredTags: Observable<Tag[]>;
    @ViewChild('tagInput') regionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    constructor(private settingsApi: SettingsApiService) {
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
    }

    ngOnInit() {
        this.settingsApi.getTagsByType(this.viewWorkOrderTags, this.viewPolicyTags, this.viewEntityTags, this.viewTaskTags,
            this.viewContractorInvoiceTags, this.viewPurchaseOrderTags, this.viewInvoiceTags,
            this.viewCustomTableTags, this.viewMaintenanceServiceTags).then(tags => {
                this.allTags = tags;
                if (this.defaultIds) {
                    const tagArray: string[] = this.defaultIds.split(',');

                    this.selectedTags = tags.filter(i => tagArray.indexOf(i.id) > -1);
                }
            });
    }


    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.tagCtrl.setValue(null);
        }
    }

    remove(tag: Tag): void {
        const index = this.selectedTags.indexOf(tag);

        if (index >= 0) {
            this.selectedTags.splice(index, 1);
        }

        this.selectedTagsChange.emit(this.selectedTags);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.selectedTags) {
            this.selectedTags = [];
        }
        this.selectedTags.push(event.option.value);
        this.regionInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);

        this.selectedTagsChange.emit(this.selectedTags);
    }

    private _filter(value: any): Tag[] {
        let filterValue = '';
        if (value.name) {
            filterValue = value;
        } else {
            filterValue = value.toLowerCase();
        }

        return this.allTags.filter(tag => tag.name.toLowerCase().indexOf(filterValue) > -1);
    }
}
