import { Component, OnInit } from '@angular/core';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCustomerPropertyServiceSummary } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Component({
    selector: 'app-maint-services-cancellations',
    templateUrl: './maint-services-cancellations.component.html',
    styleUrls: ['./maint-services-cancellations.component.css']
})
export class MaintServicesCancellationsComponent implements OnInit {

    cancellations: MaintenanceServiceCustomerPropertyServiceSummary[];
    constructor(private maintApi: MaintenanceServiceApiService) { }

    ngOnInit(): void {
        this.maintApi.getCustomerPropertyServiceCancellationsForLoggedInContractor().then(cancellations => this.cancellations = cancellations);
    }

    download() {
        UtilitiesService.export();
    }

}
