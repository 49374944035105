import { RepairItem } from "@upkeeplabs/models/cogent";


export class SalesItemCoverageRepairItem {
    id: string;
    salesItemCoverageId: string;
    repairItemId: string;
    deletedDate: Date;
    limit: number;

    repairItemName: string;

    repairItem: RepairItem;
    isChanged = false;
}