import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FavorFundAccounting {
    @column() favorFundExpenseId: string;
    @column() favorFundType: string;
    @column() void: number;
    @column() policyId: string;
    @column() accountingAreaId: string;
    @column() accountType: string;
    @column() quickBooksAccountId: number;
    @column() debitAmount: number;
    @column() creditAmount: number;
    @column() createdDate: Date;
    @column() exportDate: Date;
    @column() postDate: Date;
    @column() memo: string;
    @column() planId: string;
    @column() planName: string;
    @column() coverageType: string;
}