<mat-dialog-content>
    <div flex style="margin-top:-10px; margin-bottom: 10px">
        <div>
            <h2  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="sub-heading">Compose Message</h2>
        </div>
        <div nogrow>
            <button tabindex="-1" mat-button [mat-dialog-close]
                cdkFocusInitial><i class="material-icons">
                    close
                </i></button>
        </div>
    </div>
    <app-email-compose [emailArgs]="emailArgs" (messageSent)="messageSent()"></app-email-compose>
</mat-dialog-content>