<div aflex>
    <div></div>
    <div nogrow>
        <button mat-button style="position: absolute;right: 20px; top: 20px; z-index: 1;" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content style="position: relative; overflow-x: hidden;">

    <div topmargin20 style="min-width: 300px">
        <app-function-custom-form (completed)="formComplete($event)" [customFormQuestions]="customFormQuestions">
        </app-function-custom-form>
    </div>
</div>