import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit, OnChanges {

    @Input() selectedTime: Date;
    @Output() selectedTimeChange: EventEmitter<Date> = new EventEmitter();
    hour: number;
    minute: number;
    isAM: boolean;
    @Input() showPreview: boolean;
    @Input() disabled: boolean;

    constructor() { }

    ngOnInit(): void {
        // this.selectedTime = new Date();
        // this.setLocalVariables();
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedTime) {
            this.selectedTime = changes.selectedTime.currentValue;
            this.setLocalVariables();
        }
    }

    setAM() {
        this.isAM = true;
        this.update();

    }

    setPM() {
        this.isAM = false;
        this.update();
    }

    onKeydown(event, isMinute: boolean) {
        if (event.key === 'ArrowDown') {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
            if (isMinute) {
                this.minuteDown();
            } else {
                this.hourDown();
            }
        } else if (event.key === 'ArrowUp') {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
            if (isMinute) {
                this.minuteUp();
            } else {
                this.hourUp();
            }
        } else if (event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab') {
            // Do nothing
        } else if (event.key !== '0' && event.key !== '1' && event.key !== "2" && event.key !== "3" && event.key !== "4" && event.key !== "5" && event.key !== "6" && event.key !== "7" && event.key !== "8" && event.key !== "9") {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private setLocalVariables() {
        this.hour = this.selectedTime.getHours();
        this.minute = this.selectedTime.getMinutes();

        if (this.hour > 12) {
            this.hour -= 12;
            this.isAM = false;
        } else if (this.hour === 12) {
            this.isAM = false;
        } else {
            this.isAM = true;
        }
        if (this.hour === 0) {
            this.hour = 12;
            this.isAM = true;
        }
    }

    hourDown() {
        if (this.hour === 1) {
            this.hour = 12;
        } else if (this.hour === 12) {
            this.hour--;
            this.isAM = !this.isAM;
        } else {
            this.hour--;
        }
        this.update();
    }

    hourUp() {
        if (this.hour === 12) {
            this.hour = 1;
        } else if (this.hour === 11) {
            this.hour++;
            this.isAM = !this.isAM;
        } else {
            this.hour++;
        }
        this.update();
    }

    minuteUp() {
        if (this.minute >= 45) {
            this.minute = 0;
            this.hourUp();
        } else if (this.minute >= 30) {
            this.minute = 45;
        } else if (this.minute >= 15) {
            this.minute = 30;
        } else {
            this.minute = 15;
        }
        this.update();
    }

    minuteDown() {
        if (this.minute === 0) {
            this.minute = 45;
            this.hourDown();
        } else if (this.minute <= 15) {
            this.minute = 0;
        } else if (this.minute <= 30) {
            this.minute = 15;
        } else if (this.minute <= 45) {
            this.minute = 30;
        } else {
            this.minute = 45;
        }
        this.update();
    }

    update() {
        const date = new Date();
        date.setHours(this.hour);
        if (this.hour === 12 && this.isAM) {
            date.setHours(0);
        }
        date.setMinutes(this.minute);
        if (!this.isAM && this.hour !== 12) {
            date.setHours(date.getHours() + 12);
        }

        this.selectedTimeChange.emit(date);
        this.selectedTime = date;
    }

}
