import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { EntityCommunicationItem } from "./entity-communication-item.model";

@dataSource()
export class EntityCommunication {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() remoteEndpoint: string;
    // @column() message: string;
    // @column() mediaUrls: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() transactionId: string;
    // @column() communicator: string;
    @column() remoteEntityId: string;
    @column() lastMessage: string;
    @column() lastMessageDate: Date;

    items: EntityCommunicationItem[];

    loadingItems = false;

    get initials() {
        if (this.remoteEndpoint) {
            return this.remoteEndpoint.substring(0, 2);
        }
    }
}

export class EntityCommunicationAndItem {
    entityCommunication: EntityCommunication;
    entityCommunicationItem: EntityCommunicationItem;
}