<div class="container fancy-container  bottom-right-rounded">
    <div class="center">
        <h1>
            Recent Cancellations
        </h1>

        <button mat-button (click)="download()">
            <mat-icon>save_alt</mat-icon> Download
        </button>
    </div>


    <a mat-button routerLink="/maintenance-service-customer-view/{{customer.maintenanceServiceCustomerPropertyId}}"
        class="list-button" *ngFor="let customer of cancellations">
        <div aflex>
            <div nogrow>
                <mat-icon>schedule</mat-icon>
            </div>
            <div>
                <h3>{{customer.name}}</h3>
                <!-- <div *ngIf="customer.customerEmail">{{customer.customerEmail}}</div> -->
                <h3>{{customer.customerName}}</h3>
                <div *ngIf="customer.customerEmail">{{customer.customerEmail}}</div>
                <div>
                    {{customer.propertyAddress1}}<br>
                    <div *ngIf="customer.propertyAddress2">{{customer.propertyAddress2}}</div>
                    {{customer.propertyCity}} {{customer.propertyState}} {{customer.propertyPostalCode}}
                </div>
                <div>
                    <span class="data-label">Cancel Date:</span> {{customer.cancellationDate | date: 'shortDate'}}
                </div>
            </div>
        </div>

    </a>

    <table id="reportArea" style="display: none;">
        <tr>
            <td>Service</td>
            <td>Name</td>
            <td>Address 1</td>
            <td>Address 2</td>
            <td>City</td>
            <td>State</td>
            <td>Postal Code</td>
            <td>Cancellation Date</td>
        </tr>
        <tr *ngFor="let cancellation of cancellations">
            <!-- <td>{{cancellation.</td> -->
                <td>{{cancellation.name}}</td>
                <td>{{cancellation.customerName}}</td>
                <td>{{cancellation.propertyAddress1}}</td>
                <td>{{cancellation.propertyAddress2}}</td>
                <td>{{cancellation.propertyCity}}</td>
                <td>{{cancellation.propertyState}}</td>
                <td>{{cancellation.propertyPostalCode}}</td>
                <td>{{cancellation.cancellationDate | date: 'shortDate'}}</td>
        </tr>
    </table>

</div>
<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>