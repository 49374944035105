import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class EntityCommunicationItem {
    @keyColumn() id: string;
    @column() entityCommunicationId: string;
    @column() message: string;
    @column() messageUrls: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() communicator: string;
    @column() wasRead: boolean; 

    newMessage = false;
}