import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-parts-add-individual',
  templateUrl: './order-parts-add-individual.component.html',
  styleUrls: ['./order-parts-add-individual.component.css']
})
export class OrderPartsAddIndividualComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<OrderPartsAddIndividualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }

  save() {
    this.data.selected = true;
    this.data.quantity = 1;
    
    this.dialogRef.close(true);
  }

}
