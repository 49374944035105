import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesItemCoverageWorkOrderItem {
    @keyColumn() id: string;
    @column() salesItemCoverageId: string;
    @column() workOrderItemId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() limitPerOccurance: number;
    @column() limitPerPlanPeriod: number;
    @column() coveredTypes: string;
    @column() coveredLocations: string;
    @column() coveredProblems: string;
    @column() coveredCauses: string;
    @column() coveredRepairTypes: string;
    @column() authorizationRepairItemGroupLimits: string;
}