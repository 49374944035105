<mat-form-field class="example-chip-list" appearance="outline">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let contractor of selectedResults"
        [removable]="removable"
        (removed)="remove(contractor)">
        <ng-container *ngIf="showWorkOrderView">
            {{contractor.number}} <span *ngIf="!hideAdditionalInfo">- {{contractor.additionalInfo}}</span>
        </ng-container>
        <ng-container *ngIf="!showWorkOrderView && !showPolicyView">
            {{contractor.name}}
            
        </ng-container>
        <ng-container *ngIf="showPolicyView">
            {{contractor.number}}
            
        </ng-container>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-row>
      <input
        #contractorInput
        [formControl]="contractorCtrl"
        [matAutocomplete]="auto"
        autocomplete="new-password"
        matInput
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)">
        <mat-spinner class="small" style="margin-left: -23px;" *ngIf="searching"></mat-spinner>
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth"  (optionSelected)="selected($event)">
      <mat-option  [class.work-order-view]="showWorkOrderView || showPolicyView || showEntityView" *ngFor="let contractor of allContractors" [value]="contractor">
          <ng-container *ngIf="!showWorkOrderView && !showPolicyView">

            {{contractor.name}}
          </ng-container>
          <ng-container *ngIf="showWorkOrderView">
            <h3>SR# {{contractor.number}} - {{contractor.additionalInfo}}</h3>
            <div>{{contractor.address1}} {{contractor.city}}, {{contractor.state}} {{contractor.postalCode}}</div>
            <div>{{contractor.status}}</div>
          </ng-container>
          <ng-container *ngIf="showPolicyView">
            <h3>{{contractor.number}} - {{contractor.additionalInfo}}
                <span class="badge">{{contractor.status}}</span>
            </h3>
            <div>{{contractor.name}}</div>
            <div>{{contractor.address1}} {{contractor.city}}, {{contractor.state}} {{contractor.postalCode}}</div>
          </ng-container>

          <ng-container *ngIf="showEntityView">
            <div>{{contractor.email}}</div>
            <div>
                <span *ngIf="contractor.workNumber" title="Work Phone Number">W: {{contractor.workNumber | formatPhoneNumber}}</span>
                <span *ngIf="contractor.mobileNumber" title="Mobile Phone Number">M: {{contractor.mobileNumber | formatPhoneNumber}}</span>
            </div>

          </ng-container>

          
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

