import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorInvoicePaymentSummary {
    @keyColumn() id: string;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() createdDate: Date;
    @column() paymentAmount: number;
    @column() paymentCount: number;
    @column() email: string;
    @column() quickBooksId: string;
    @column() checkNumber: string;
    @column() payablesPaymentMethod: string;
    @column() statementUrl: string;
    @column() type: string;

    quickbooksId: string;
}