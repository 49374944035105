<div>
    <div flex1>
        <div aflex>
            <h2 class="sub-heading">Create Invoice</h2>
            <button nogrow mat-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="hide-tabs">
            <mat-tab label="First">
                <div mat-dialog-content>
                    <mat-form-field appearance="outline">
                        <mat-label>
                            <mat-icon>filter_alt</mat-icon> Filter
                        </mat-label>
                        <input matInput [(ngModel)]="filter" (ngModelChange)="adjustFilter()">
                    </mat-form-field>
                    <button mat-button class="service-select" *ngFor="let service of filteredServices"
                        (click)="selectService(service)">
                        <h2>{{service.customerName}}</h2>
                        <div>{{service.propertyAddress1}}</div>
                        <div>{{service.propertyCity}}, {{service.propertyState}} {{service.propertyPostalCode}}</div>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Second">
                <div mat-dialog-content>
                    <div aflex>
                        <div nogrow>
                            <h1 class="big-data">{{total | currency}}</h1>
                            <div class="data-label">Invoice Total</div>
                        </div>
                        <div></div>
                        <div nogrow>
                            <mat-form-field appearance="outline">
                                <mat-label>Vendor #</mat-label>
                                <input matInput [(ngModel)]="vendorNumber">
                            </mat-form-field>
                            <app-date-picker [(date)]="date"></app-date-picker>
                        </div>
                    </div>


                    <table class="table" style="width: calc(100% - 20px)">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th class="right">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let line of lineItems">
                                <td>
                                    <mat-form-field appearance="outline" class="condensed-form-field">
                                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="5" (ngModelChange)="addLineItemIfNeeded()"
                                            [(ngModel)]="line.description"></textarea>
                                    </mat-form-field>
                                </td>
                                <td style="width: 140px">
                                    <mat-form-field style="width: 100px !important" appearance="outline" class="condensed-form-field">
                                        <input matInput (ngModelChange)="addLineItemIfNeeded()"
                                            [(ngModel)]="line.amount" type="number">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div nogrow mat-dialog-actions *ngIf="selectedIndex === 1">

        <div aflex >
            <div>
                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon> Back
                </button>
            </div>
            <div nogrow>
                <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                    Save
                </button>
            </div>

        </div>
    </div>
</div>