import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentVariablesService {

    constructor() { }

    get documentUrl() {
        return window.document.URL;
    }

    get now() {
        return new Date();
    }
}
