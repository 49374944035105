import { DatePipe } from '@angular/common';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

export enum FormItemType {
    TextBox = 'TextBox',
    NumberBox = 'NumberBox',
    EmailBox = 'EmailBox',
    PhoneBox = 'PhoneBox',
    MultiLineTextBox = 'MultiLineTextBox',
    RadioButtonList = 'RadioButtonList',
    Select = 'Select',
    CheckboxList = 'CheckboxList',
    DatePicker = 'DatePicker',
    SlideToggle = 'SlideToggle',
    Message = 'Message',
    Heading = 'Heading',
    Separator = 'Separator',
    TagEntry = 'TagEntry',
    Slider = 'Slider',
    RichText = 'RichText',
    PreferredTimeSlot = 'PreferredTimeSlot',
    WorkOrderSelection = 'WorkOrderSelection',
    CustomTableSearch = 'CustomTableSearch',
    EmployeeSelection = 'EmployeeSelection',
    ImageUpload = 'ImageUpload',
    RichText2 = 'RichText2'
}

export class FormItem {
    type: FormItemType;
    answers: FormItemAnswer[];
    id: string;
    prompt: string;
    required: boolean;
    answer: any;
    maxWidth: number;
    viewWorkOrderTags: boolean;
    viewPolicyTags: boolean;
    viewEntityTags: boolean;
    viewTaskTags: boolean;
    containerWidth = '100';
    max: number;
    min: number;
    step: number;
    thumbLabel: string;
    tickInterval: number;
    useSnippets: boolean;
    rows = 4;
    key1: string;
    customTableName: string;
    searchForFieldName: string;
    selectMode: boolean;
    answerFormat: string;

    get displayPrompt() {
        if (this.prompt) {

            return this.prompt + (this.required ? '*' : '');
        }

        return this.type + ' field' + (this.required ? '*' : '');
    }

    get isQuestion() {
        return this.type !== FormItemType.Separator && this.type !== FormItemType.Heading
            && this.type !== FormItemType.Message;
    }

    get formattedAnswer() {
        if (this.type === FormItemType.DatePicker && this.answer) {
            if (this.answerFormat) {
                const dp = new DatePipe('en-US');
                return dp.transform(this.answer, this.answerFormat);
            }
            return new Date(this.answer).toUTCString();
        } else if (this.answer && this.answer.replace) {
            return this.answer.replace(/\n/g, '\\n');
        } else if (this.type === FormItemType.Slider && !this.answer) {
            return 0;
        } else {
            return this.answer;
        }
    }

    get rowsOrDefault() {
        if (!this.rows) {
            return 4;
        }

        return this.rows;
    }
    additionalParams: any;
}

export class FormItemAnswer {
    answer: string;
    selected: boolean;
}

export class CustomForm {
    id: string;
    name: string;
    form: string;

    private deserializedForm: FormItem[];

    get formItems(): FormItem[] {
        if (!this.deserializedForm && this.form) {
            try {
                this.deserializedForm = JSON.parse(this.form);

                this.deserializedForm = UtilitiesService.copyArrayToTypedArray(this.deserializedForm, () => new FormItem());
            } catch (e) {
                ;
            }
        }
        return this.deserializedForm;
    }
    set formItems(value: FormItem[]) {
        this.deserializedForm = value;
        this.form = JSON.stringify(this.deserializedForm);
    }

    get questionFormItems() {
        return this.formItems.filter(i => i.isQuestion);
    }
}