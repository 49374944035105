import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceAddOnRequestSummary {
    @keyColumn() id: string;
    @column() requestArgs: string;
    @column() createdDate: Date;
    @column() price: number;
    @column() approvedDate: Date;
    @column() maintenanceServiceCustomerPropertyServiceAppointmentId: string;
    @column() rejectedDate: Date;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() maintenanceServiceOfferingId: string;
    @column() contractorName: string;
    @column() contractorId: string;
    @column() shortSummary: string;
    @column() serviceName: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerPhoneNumber: string;
    @column() quoteSubmittedDate: Date;
    @column() contractorComments: string;
    @column() customerId: string;
    @column() contractorEmail: string;
    @column() contractorNumber: string;
    @column() customerRejectedDate : Date;

    private _args: any;
    get args() {
        if (!this._args) {
            try {
                this._args = JSON.parse(this.requestArgs);
            } catch { }
        }

        return this._args;
    }
}