import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwapableImageComponent } from './swapable-image.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [SwapableImageComponent],
  exports: [SwapableImageComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ]
})
export class SwapableImageModule { }
