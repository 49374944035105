
import { Address, Entity } from '@upkeeplabs/models/cogent';



export class PolicyModel {
    public mlsNumber: string;
    public propertyAddress: Address;
    public planName: string;
    public holder: Entity;
    public serviceFeeAmount: number;
    public totalCost: number;
    public totalRevenue: number;
    public monthlyProcessingFee: number;
    public isMonthly: boolean;
    public agentId: string;
    public accountExecutiveId: string;
    public sellerAgentId: string;
    public closingCompanyId: string;
    public closingOfficerId: string;
    public coverageType: string;
    public estimatedClosingDate: Date;
    public escrowNumber: string;
    public initiatorType: string;
    public effectiveDate: Date;
    public marketingSourceId: string;
    holderId: string;
    coHolderId: string;
    public titleInvoiceAmount: number;
    additionalTitleInvoiceEmails: string;
    createdSource: string;

    constructor(public policyNumber?: number, public id = '', public planId = '') {

    }
}
