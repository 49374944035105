import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PaymentItem {
    @keyColumn() id: string;
    @column() paymentId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() amount: number;
    @column() invoiceId: string;
    @column() creditId: string;
    @column() memo: string;
    @column() maintenanceServiceInvoiceId: string;
}