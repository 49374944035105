import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { WorkOrderAttachment } from '@cogent/shared/models/service/work-order-attachment.model';

@Component({
    selector: 'app-authorization-photos',
    templateUrl: './authorization-photos.component.html',
    styleUrls: ['./authorization-photos.component.css']
})
export class AuthorizationPhotosComponent implements OnInit {

    @Input() attachments: WorkOrderAttachment[] = [];
    @Output() attachmentsChange: EventEmitter<WorkOrderAttachment[]> = new EventEmitter();
    @Input() imagePrefix: string = '';
    @Input() label = 'Repair Photos'
    @Input() hideList = false;
    @Input() maxHeight: number;
    fileUploadId = UtilitiesService.newid();
    loadingPhoto = false;

    constructor() { }

    ngOnInit(): void {
    }

    removeAttachment(attachment: WorkOrderAttachment) {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
        this.attachmentsChange.emit(this.attachments);
    }

    loadIt(base64, fileName) {
        const attachment = new WorkOrderAttachmentModel(fileName, null, base64);
        if (!this.attachments) {
            this.attachments = [];
        }
        this.attachments.push(attachment);
        attachment.name = `${this.imagePrefix ? this.imagePrefix + '_' : ''}${UtilitiesService.newid()}.jpg`;
        this.attachmentsChange.emit(this.attachments);
        this.loadingPhoto = true;
        setTimeout(() => this.loadingPhoto = false);
    }

    handleFileUpload(evt) { this.handleFiles(evt); }
    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            const loadIt = async (base64, fileName) => {
                if (this.maxHeight) {
                    base64 = await UtilitiesService.resizeImage(base64, this.maxHeight, this.maxHeight);
                }
                const attachment = new WorkOrderAttachmentModel(fileName, null, base64);
                if (!this.attachments) {
                    this.attachments = [];
                }
                this.attachments.push(attachment);
                attachment.name = `${this.imagePrefix ? this.imagePrefix + '_' : ''}${UtilitiesService.newid()}.jpg`;
                this.attachmentsChange.emit(this.attachments);
                this.loadingPhoto = true;
                setTimeout(() => this.loadingPhoto = false);
            };

            reader.onload = (fileName => {
                return function (e) {
                    (this as any).dragTargetActive = false;
                    loadIt(e.target.result, fileName);

                };
            })(file.name);

            reader.readAsDataURL(file);
        }
    }

    get filteredPhotos() {
        if (!this.attachments) {
            return null;
        }

        if (!this.imagePrefix) {
            return this.attachments;
        }
        return this.attachments.filter(i => i.name.startsWith(this.imagePrefix));
    }

    takePhoto() {
        document.getElementById(this.fileUploadId).click();
    }

}
