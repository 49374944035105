import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DeferredRevenueMonthEndBalanceByAccountingArea {
    @keyColumn() id: string;
    @column() deferredDate: Date;
    @column() areaId: string;
    @column() area: string;
    @column() balance: number;
    @column() subscriptionCount: number;
    @column() averageDeferred: number;
}

export class DeferredRevenueDateGroup {
    deferredDate: Date;
    areas: DeferredRevenueMonthEndBalanceByAccountingArea[];
    balance: number;
    subscriptionCount: number;
    averageDeferred: number;
}