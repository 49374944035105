import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceAddOnRequest {
    @keyColumn() id: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() requestArgs: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() price: number;
    @column() approvedDate: Date;
    @column() maintenanceServiceCustomerPropertyServiceAppointmentId: string;
    @column() rejectedDate: Date;
    @column() quoteSubmittedDate: Date;
    @column() contractorComments: string;
    @column() customerRejectedDate: Date;
}