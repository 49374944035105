import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CreditItem {
    @keyColumn() id: string;
    @column() creditId: string;
    @column() amount: number;
    @column() invoiceId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() memo: string;
    @column() exportDate: Date;
    @column() voidExportDate: Date;
    @column() quickBooksJournalId: string;
    @column() quickBooksVoidedJournalId: string;
    @column() voidDate: Date;
    @column() cashOutId: string;
    @column() maintenanceServiceInvoiceId: string;
    @column() postDate: Date;
    @column() voidPostDate: Date;
    @column() paymentId: string;
}