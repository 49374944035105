import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceContractorRegion {
    @keyColumn() id: string;
    @column() maintenanceServiceContractorId: string;
    @column() contractorRegionId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() autoAssignJobs?: boolean;
}