import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorInvoicePaymentItem {
    @keyColumn() id: string;
    @column() contractorInvoicePaymentId: string;
    @column() contractorInvoiceId: string;
    @column() amountPaid: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() purchaseOrderId: string;
    @column() maintenanceServiceInvoiceId: string;
}