<div aflex>
    <div class="left-pane-fixed" nogrow>
        <app-date-range-display-and-selector memoryKey="tax-report" [(startDate)]="startDate"
            [(endDate)]="endDate"></app-date-range-display-and-selector>

        <div topmargin30>
            <button mat-button block mat-raised-button color="primary" (click)="load()" [disabled]="!canLoad">
                <mat-spinner *ngIf="loading" class="thirty"></mat-spinner>
                Load</button>
        </div>
    </div>
    <div nogrow style="width: 350px;"></div>
    <div>

        <div *ngIf="contractorInvoices" style="padding-right: 30px;padding-top:30px">

            <div aflex>
                <div>
                    <h1>Invoice Report</h1>
                </div>
                <div nogrow>
                    <button mat-button mat-raised-button (click)="export()">
                        <mat-icon>file_download</mat-icon>
                        Export To Excel</button>
                </div>
            </div>

            <table class="table" id="reportArea">
                <thead>
                    <tr>
                        <th>Job</th>
                        <th>Address</th>
                        <th>Date</th>
                        <th class="right">Parts</th>
                        <th class="right">Labor</th>
                        <th class="right">Tax</th>
                        <th class="right total">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let inv of contractorInvoices">
                        <td>
                            <a routerLink="/work-order-detail/{{inv.workOrderId}}">
                            {{inv.workOrderNumber}}</a></td>
                        <td>{{inv.propertyAddress}}</td>
                        <td>{{inv.createdDate | date: 'shortDate'}}</td>
                        <td class="right">{{inv.partsCost | currency}}</td>
                        <td class="right">{{inv.laborCost | currency}}</td>
                        <td class="right">{{inv.tax | currency}}</td>
                        <td class="right total">{{inv.totalAmount | currency}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="3" class="right">Totals:</th>
                        <th class="right">{{partsCost | currency}}</th>
                        <th class="right">{{laborCost | currency}}</th>
                        <th class="right">{{tax | currency}}</th>
                        <th class="right total">{{totalAmount | currency}}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>