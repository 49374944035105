
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { DateRangeType } from '@cogent/shared/models/common/date-range-type.model';
import { SearchResultModel } from '@cogent/shared/models/common/search-result.model';
import { CustomFunction, MarketingSource, Role, Tag, TaskQueue, Trade, WorkOrderStatus } from '@upkeeplabs/models/cogent';
import { ObjectSummaryField } from '@cogent/shared/models/common/object-summary-field.model';
import { CompanyRegion } from '@cogent/shared/models/common/company-states-and-regions.model';
import { PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';

export class CommandGroup {
    group: string;
    commands: QueueCommand[];
}

export class GroupedItems {
    group: string;
    items: any[];
}

export class QueueQuery {
    selectedRegions: CompanyRegion[];
    selectedTrades: Trade[];
    selectedWorkOrderStatuses: WorkOrderStatus[];
    selectedCallTypes: string[];
    selectedContractors: SearchResultModel[];
    selectedEmployees: SearchResultModel[];
    assignedEmployees: SearchResultModel[];
    selectedItems: WorkOrderItemClient[];
    selectedTags: Tag[];
    selectedSLAStatuses: string[];
    customFunctions: CustomFunction[];

    selectedRegionsExcluded: CompanyRegion[];
    selectedTradesExcluded: Trade[];
    selectedWorkOrderStatusesExcluded: WorkOrderStatus[];
    selectedCallTypesExcluded: string[];
    selectedContractorsExcluded: SearchResultModel[];
    selectedEmployeesExcluded: SearchResultModel[];
    selectedItemsExcluded: WorkOrderItemClient[];
    selectedTagsExcluded: Tag[];
    selectedSLAStatusesExcluded: string[];
    selectedAccountExecutives: SearchResultModel[];
    selectedAccountExecutivesExcluded: SearchResultModel[];
    selectedPlans: PlanClient[];
    selectedPlansExcluded: PlanClient[];
    selectedCoverageTypes: string[];
    selectedCoverageTypesExcluded: string[];
    selectedMarketingSources: MarketingSource[];
    selectedMarketingSourcesExcluded: MarketingSource[];
    selectedSalesRegions: CompanyRegion[];
    selectedSalesRegionsExcluded: CompanyRegion[];
    selectedPolicyStatuses: string[];
    selectedPolicyStatusesExcluded: string[];
    selectedEntityTypes: string[];
    selectedEntityTypesExcluded: string[];
    selectedTaskStatus: string[];
    selectedTaskStatusExcluded: string[];
    selectedTaskQueues: TaskQueue[];
    selectedTaskQueuesExcluded: TaskQueue[];
    selectedContractorInvoiceStatuses: string[];
    selectedContractorInvoiceStatusesExcluded: string[];
    dateState: string;

    resultsFields: ObjectSummaryField[];
    queueActions: QueueAction[];
    commands: QueueCommand[];
    availableToEmployees: SearchResultModel[];
    selectedCreatedBy: SearchResultModel[];
    selectedCreatedByExcluded: SearchResultModel[];

    groupByField: string;
    sortGroupDescending: boolean;

    sortByField: string;
    sortDescending = false;
    showSLAIndicator = false;
    isContractorNull = false;
    whereClause: string;

    commandGroups: CommandGroup[];
    unGroupedCommands: QueueCommand[];
    iconClass: string;
    defaultCommandId: string;
    clickOnQueueItemToInvokeDefaultCommand: boolean;
    defaultCommand: QueueCommand;
    uiCommands: QueueCommand[];
    allowMultiSelect: boolean;
    autoRefresh: boolean;

    createdDateRange: DateRangeType;
    dateRange2: DateRangeType;
    dateRange3: DateRangeType;
    dateRange4: DateRangeType;

    dateRange2IsNotNull: boolean;
    dateRange3IsNotNull: boolean;
    dateRange2IsNull: boolean;
    dateRange3IsNull: boolean;
    dateRange4IsNotNull: boolean;
    dateRange4IsNull: boolean;

    hidePrint: boolean;
    hideExport: boolean;
    hideRefresh: boolean;
    hideFilter: boolean;

    liabilityInsuranceStatus: string;
    workmansCompStatus: string;
    rocLicenseStatus: string;
    additionalInsuranceStatus: string;
    servicerAgreementStatus: string;

    employeeNotSet: boolean;
    employeeNotSetExcluded: boolean;
    aeNotSet: boolean;
    hideExplorerBar: boolean;
    numberOfRecordsToLoad: number;


    customTableName: string;

    selectedCreatedSource: string[];
    selectedCreatedSourceExcluded: string[];
    tagsToShow: Tag[];

}

export class TriggerParameters {
    actions: QueueAction[];
    commands: QueueCommand[];
    selectedRegions: CompanyRegion[];
    selectedTrades: Trade[];
    selectedWorkOrderStatuses: WorkOrderStatus[];
    selectedCallTypes: string[];
    selectedContractors: SearchResultModel[];
    selectedEmployees: SearchResultModel[];
    selectedItems: WorkOrderItemClient[];
    selectedTags: Tag[];
    selectedSLAStatuses: string[];
    selectedRegionsExcluded: CompanyRegion[];
    selectedTradesExcluded: Trade[];
    selectedWorkOrderStatusesExcluded: WorkOrderStatus[];
    selectedCallTypesExcluded: string[];
    selectedContractorsExcluded: SearchResultModel[];
    selectedEmployeesExcluded: SearchResultModel[];
    selectedItemsExcluded: WorkOrderItemClient[];
    selectedTagsExcluded: Tag[];
    selectedSLAStatusesExcluded: string[];
}

export enum QueueActionType {
    AutoPhoneCall = 'AutoPhoneCall',
    BroadcastApplicationMessage = 'BroadcastApplicationMessage',
    ChangeStatus = 'ChangeStatus',
    CreateANote = 'CreateANote',
    CreateATask = 'CreateATask',
    Delay = 'Delay',
    Delete = 'Delete',
    DialPhoneNumber = 'DialPhoneNumber',
    HideWorkingMessage = 'HideWorkingMessage',
    LogDataChange = 'LogDataChange',
    ModifyTags = 'ModifyTags',
    MoveLane = 'MoveLane',
    NavigateToUrl = 'NavigateToUrl',
    RefreshAllQueues = 'RefreshAllQueues',
    RefreshObject = 'RefreshObject',
    RefreshQueue = 'RefreshQueue',
    RemoveFromQueue = 'RemoveFromQueue',
    RESTEndpoint = 'RESTEndpoint',
    RunCommand = 'RunCommand',
    RunGlobalCommand = 'RunGlobalCommand',
    RunStoredProcedure = 'RunStoredProcedure',
    RunWorkflow = 'RunWorkflow',
    SendAppPushNotification = 'SendAppPushNotification',
    SendEmailNotification = 'SendEmailNotification',
    SendPushNotification = 'SendPushNotification',
    SendSMSNotification = 'SendSMSNotification',
    ShowAlertDialog = 'ShowAlertDialog',
    ShowConfirmation = 'ShowConfirmation',
    ShowCustomComponentModal = 'ShowCustomComponentModal',
    ShowForm = 'ShowForm',
    ShowQuestionnaire = 'ShowQuestionnaire',
    ShowToastMessage = 'ShowToastMessage',
    ShowToastSuccessMessage = 'ShowToastSuccessMessage',
    ShowWorkingMessage = 'ShowWorkingMessage',
    SlyDialMessage = 'SlyDialMessage',
    UnDelete = 'UnDelete',
    Undo = 'Undo',
    SetVariable = 'SetVariable',
    CreateConnectTask = 'CreateConnectTask',
    ShowSMSQuestionnaire = 'ShowSMSQuestionnaire',
    // TakeScreenShot = 'TakeScreenShot',


}

export enum NotificationRecipientType {
    Contractor = 'Contractor',
    Homeowner = 'Homeowner',
    Contact = 'Contact',
    CreatedByEmployee = 'CreatedByEmployee',
}


export class QueueCommand {
    label: string;
    actions: QueueAction[];
    group: string;
    iconClass: string;
    id: string;
    hideFromUI: boolean;
    showOnMainToolbar: boolean;
    runOnAllQueueItems: boolean;
    isCallToAction: boolean;
    showOnlyOnMultiSelect: boolean;
    runOnStartup: boolean;
    customFunction?: CustomFunction;
    iconUrl: string;
}

export class QuestionnaireCommand {
    evaluationExpression: string;
    commandId: string;
    evaluationExpressionJson: string;
}

export class FormAnswerDefault {
    id: string;
    defaultValue: string;
    prompt: string;
}

export class QueueActionEmailAttachment {
    name: string;
    url: string;
}

export class QueueAction {
    queueAction: QueueActionType;
    notificationTemplatePath: string;
    // availableWorkOrderStatuses: string[];
    newWorkOrderStatusId: string;
    recipient: NotificationRecipientType;
    messageRecipient: string;
    messageCCRecipient: string;
    messageBCCRecipient: string;
    messageSubject: string;
    messageBody: string;
    label: string;
    selectedEmployees: SearchResultModel[];
    tagsToAdd: Tag[];
    tagsToRemove: Tag[];
    restEndpointUrl: string;
    restEndpointAction: string;
    restEndpointBody: string;
    id: string;
    questionnaireId: string;
    customFormId: string;
    createTheTaskForMe: boolean;
    commandToRunId: string;
    customFormType: string;
    questionnaireCommands: QuestionnaireCommand[];
    delayAmount: number;
    sendAutomatically = false;
    conditionalExpression: string;
    conditionalExpressionJson: string;
    formDefaults: FormAnswerDefault[];
    attachments: QueueActionEmailAttachment[];
    templateId: string;
    jobId: string;
    subscriptionId: string;
    taskId: string;
    runOnEveryQueueItem: boolean;
    taskQueueId: string;
    selectedRoles: Role[];
    waitBeforeContinue: boolean;
    fromEmail: string;

    setVariableKey: string;
    setVariableValue: any;
}


export class ObjectQueue {
    id: string;
    name: string;
    query: string;
    groupBy: string;
    description: string;
    roles: string;
    grouping: string;
    sort: number;
    availableToAllRoles: boolean;
    queueType: string;
    hideFromNavigator: boolean;


    // client-side only
    count: number;
    selected: boolean;
    queueObjects: any[];
    filteredQueueObjects: any[];
    groupedItems: GroupedItems[];
    queueIndex: number;
    noMoreObject: boolean;
    isNew: boolean;

    private queryObject: QueueQuery;
    private roleObjectInternal: Role[];
    cachedGlobalFunctions?: QueueCommand[];

    get queueQuery(): QueueQuery {
        if (this.queryObject) {
            return this.queryObject;
        }
        if (!this.query) {
            this.queryObject = new QueueQuery();
        } else {
            this.queryObject = JSON.parse(this.query);
        }

        return this.queryObject;
    }

    set queueQuery(query: QueueQuery) {
        this.queryObject = null;
        this.query = JSON.stringify(query);
    }

    resetQueueQueryObject() {

        this.queryObject = null;
    }

    get rolesObject(): Role[] {
        if (this.roleObjectInternal) {
            return this.roleObjectInternal;
        }
        if (!this.roles) {
            this.roleObjectInternal = [];
        } else {
            this.roleObjectInternal = JSON.parse(this.roles);
        }

        return this.roleObjectInternal;
    }
    set rolesObject(roles: Role[]) {
        this.roleObjectInternal = null;
        this.roles = JSON.stringify(roles);
    }
}
