import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PlanItem {
    @keyColumn() id: string;
    @column() planId: string;
    @column() salesItemId: string;
    @column() optional: boolean;
    @column() deletedDate?: Date;
    @column() salesItemCoverageId: string;
    @column() price: number;
    @column() priceRecurring: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() isDefault: boolean;
    @column() sort: number;
    @column() showOnWeb: boolean;
    @column() isPrepaidOption: boolean;
    @column() allowServiceBeforeActive: boolean;
    @column() serviceFee?: number;
    @column() otherFee?: number;
}