import {
    Component,
    Input,
    SimpleChanges,
    NgZone,
    Output,
    EventEmitter,
    OnDestroy,
    AfterViewInit,
    OnChanges,
} from '@angular/core';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StripeApiService } from '@cogent/client/shared/services/api/stripe-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { StripeCard, StripeTransactionCharge } from '@upkeeplabs/models/cogent';
import { ManageMultiplePaymentMethodsComponent } from "@cogent/client/shared/components/accounting/manage-multiple-payment-methods/manage-multiple-payment-methods.component";
import { MatDialog } from '@angular/material/dialog'
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';


declare var elements: any;
declare var stripe: any;
declare var Stripe: any;
declare var window: any;

@Component({
    templateUrl: './payment-method-entry.component.html',
    styleUrls: ['./payment-method-entry.component.css'],
    selector: 'app-payment-method-entry',
    standalone: true,
    imports: [
        CommonModule,
        MaterialSharedModule,
      ],
})
export class PaymentMethodEntryComponent implements OnDestroy,
    AfterViewInit, OnChanges {
    @Input() customerId: string;
    @Input() selectedCard: StripeCard;
    @Input() paymentAmount: number = 0;
    @Input() paymentDescription: string = 'Payment';
    @Output()
    selectedCardChange: EventEmitter<StripeCard> = new EventEmitter();
    @Input() buttonText = 'Next';
    @Input() newCardButtonText: string = 'Save Card to Wallet';
    @Input() newCustomerMode = false;
    @Input() cardGetUrl: string;
    @Input() hideManualCreditCardEntry = false;
    @Input() dontAutoSelectCard = false;
    @Input() allowOneTimeUse: boolean;
    @Input() deletingCardId: string;
    @Input() allowDelete = false
    saveCardForLater: boolean = true;

    @Output() newCardCreated: EventEmitter<void> = new EventEmitter();
    mobileCardIndex = 0;

    card: any;
    existingCards: StripeCard[];
    @Input() addingNewCard = false;
    @Output() addingNewCardChange: EventEmitter<boolean> = new EventEmitter();
    @Input() hideSaveCardToWalletButton = false;
    canSubmit = false;
    @Input() savingPaymentMethod = false;
    @Output() savingPaymentMethodChange: EventEmitter<boolean> = new EventEmitter();
    @Input() canDelete = true;

    @Input() defaultSelectedCardId: string;
    refreshingCards = false;
    initialized: boolean;
    stripeInitialized = false;
    private swipeAdjustment = 0;

    constructor(
        private stripeRepository: StripeApiService,
        private zone: NgZone,
        private dialog: DialogsService,
        private sanitization: DomSanitizer,
        private entityApi: EntityApiService,
        private matDialog: MatDialog,
    ) { }

    private refreshExistingCards() {
        if (this.newCustomerMode) {

            if (!this.existingCards) {
                this.existingCards = [];
            }
            this.initializeStripe();

            return;
        }
        this.refreshingCards = true;

        (this.cardGetUrl ? this.stripeRepository.getStripeCardsFromUrl(this.cardGetUrl) : this.stripeRepository.getCustomerStripeCards(this.customerId))
            .then(cards => {
                if (this.deletingCardId) {
                    this.existingCards = cards.filter(item => item.id !== this.deletingCardId)
                } else {
                    this.existingCards = cards;
                }

                cards.forEach(card => {
                    card.saveCardForLater = true;
                });

                this.refreshingCards = false;
                if (cards.length === 0 && this.selectedCard) {
                    cards.push(this.selectedCard as any);
                }
                if (cards.length === 0) {
                    this.addingNewCard = true;
                    this.addingNewCardChange.emit(this.addingNewCard);
                    this.initializeStripe();
                } else {
                    let currentCard = this.existingCards[0];
                    if (this.defaultSelectedCardId) {
                        currentCard = this.existingCards.find(i => i.id === this.defaultSelectedCardId);
                        if (!currentCard) {
                            currentCard = this.existingCards[0];
                        }
                    }
                    if (!this.dontAutoSelectCard) {
                        this.selectCard(currentCard);
                    }
                }
            });
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.customerId && changes.customerId.currentValue) {
            this.initialized = false;
            this.refreshExistingCards();
            // setTimeout(() => this.initializeStripe());
        } else if (changes.customerId && !changes.customerId.currentValue) {
            this.initialized = false;
            // setTimeout(() => this.initializeStripe());
        }
        if (changes.newCustomerMode && changes.newCustomerMode.currentValue) {
            this.addingNewCard = true;
            this.addingNewCardChange.emit(this.addingNewCard);
        }
    }

    showAddNewCard() {
        this.addingNewCard = true;
        this.addingNewCardChange.emit(this.addingNewCard);
        setTimeout(() => this.initializeStripe())
    }

    ngOnDestroy() {
        if (this.card && this.card.unmount) {
            this.card.unmount();
        }
        if (this.card && this.card.destroy) {
            this.card.destroy();
        }
    }

    get saveButtonText() {
        return this.saveCardForLater ? 'Save To Wallet' : 'Save';
    }

    selectCard(card: StripeCard) {
        this.selectedCard = card;
        this.selectedCardChange.emit(card);
        if (!this.existingCards && card) {
            this.existingCards = [];
            this.existingCards.push(card);
        }
        this.mobileCardIndex = this.existingCards.indexOf(card);
    }

    nextMobileCard() {
        if (this.mobileCardIndex < this.existingCards.length - 1) {
            this.mobileCardIndex += 1;
        } else {
            this.mobileCardIndex = 0;
        }
        this.selectCard(this.existingCards[this.mobileCardIndex]);
    }

    prevMobileCard() {
        if (this.mobileCardIndex > 0) {
            this.mobileCardIndex -= 1;
        } else {
            this.mobileCardIndex = this.existingCards.length - 1;
        }
        this.selectCard(this.existingCards[this.mobileCardIndex]);
    }

    private initializeStripe() {
        const _thisItem = this;
        if (this.initialized) {
            return;
        }

        const t = this;
        if (!document.getElementById('card-element')) {
            setTimeout(() => t.initializeStripe(), 100);
            return;
        }

        this.canSubmit = false;
        if (this.card) {
            this.card.unmount();
            this.card.destroy();
        }
        const style = {
            base: {
                fontSize: '14px',
                lineHeight: '20px',
            }
        };
        if (!(window as any).elements) {
            this.entityApi.getStripePublicKey().then(result => {
                if (!this.stripeInitialized) {
                    window.stripe = Stripe(result.key);
                    window.elements = window.stripe.elements();
                    this.initializeStripe();
                    this.stripeInitialized = true;
                }
            });
            return;
        }

        this.card = elements.create('card', { style });

        this.card.mount('#card-element');

        const zone = this.zone;

        this.card.addEventListener('change', event => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }

            zone.run(() => {
                _thisItem.canSubmit = event.complete;
            });

        });

        this.initialized = true;

        const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: this.paymentDescription,
                amount: Math.round(this.paymentAmount * 100),
            },
        });
        const e = stripe.elements();
        const prButton = e.create('paymentRequestButton', {
            paymentRequest,
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(result => {
            if (result) {
                prButton.mount('#payment-request-button');
            } else {
                if (document.getElementById('payment-request-button')) {
                    document.getElementById('payment-request-button').style.display = 'none';
                }
            }
        });

        const thisItem = this;
        paymentRequest.on('token', ev => {
            thisItem.selectedCard = ev;
            ev.complete('success');
            thisItem.selectedCardChange.emit(thisItem.selectedCard);
        });
    }

    ngAfterViewInit() {
        // this.initializeStripe();
        this.setupSwipeDetect();
    }

    private setupSwipeDetect() {
        if (document.getElementById('mobile-card-container')) {
            UtilitiesService.swipeDetect(document.getElementById('mobile-card-container'), direction => {
                this.swipeAdjustment = 0;
                if (direction === 'left') {
                    this.nextMobileCard();
                }

                if (direction === 'right') {
                    this.prevMobileCard();
                }
                setTimeout(() => this.swipeAdjustment = 0, 100);
            }, distX => {
                const maxThreshold = 100;
                if (distX < -maxThreshold) {
                    distX = -maxThreshold;
                }
                if (distX > maxThreshold) {
                    distX = maxThreshold;
                }
                this.swipeAdjustment = -distX;
            });
        } else {
            setTimeout(() => this.setupSwipeDetect(), 500);
        }
    }

    closeAddingNewCard() {
        this.addingNewCard = false;
        this.addingNewCardChange.emit(this.addingNewCard);
        this.card?.unmount()
        this.initialized = false
        this.setupSwipeDetect();
    }

    get mobileCardContainerTransform() {
        if (this.mobileCardIndex === 0) {
            return 'none';
        }
        return this.sanitization.bypassSecurityTrustStyle(`translateX(calc( ${this.mobileCardIndex * -270}px))`);
    }

    savePaymentMethod() {
        this.savingPaymentMethod = true;
        this.savingPaymentMethodChange.emit(this.savingPaymentMethod);
        window.stripe.createToken(this.card).then(result => {

            if (result.error) {
                const errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
                this.savingPaymentMethod = false;
            } else {
                this.stripeTokenHandler(result.token);
            }
            this.savingPaymentMethodChange.emit(this.savingPaymentMethod);
        });
    }

    deleteCard(card: StripeCard) {
        this.dialog
            .confirm(
                'Confirm Delete',
                'Are you sure you want to delete this payment method. <br>It cannot be undone')
            .subscribe(results => {
                if (results) {
                    (card as any).deleting = true;

                    this.stripeRepository.nodeDeleteStripeCard(card.customer, card.id)
                        .then(result => {
                            if (result == true) {
                                (card as any).removingDeleted = true;
                                setTimeout(() => this.existingCards.splice(
                                    this.existingCards.indexOf(card), 1),
                                    500);

                                if (card.id === this.defaultSelectedCardId || !this.defaultSelectedCardId) {
                                    this.selectCard(null);

                                }
                            }
                            else {
                                // this.managePaymentEvent.emit(true);

                                (card as any).deleting = false;

                                if (this.card) {
                                    this.card.destroy()
                                    this.card = null
                                }

                                const dialogRef = this.matDialog.open(ManageMultiplePaymentMethodsComponent, {
                                    data: {
                                        stripeCards: this.existingCards,
                                        stripeId: card.customer,
                                        selectedCard: this.selectedCard,
                                        customerId: this.customerId,
                                        activeHMSAddresses: result.hmsAddresses,
                                        message: result.message,
                                        deletingCardId: result.deletingCardId
                                    }
                                });

                                dialogRef.afterClosed().subscribe(results => {
                                    this.initialized = false
                                    this.refreshExistingCards()
                                    this.initializeStripe()
                                })

                            }
                        });
                }
            });
    }

    makePayment(card: StripeCard) {
        const charge = new StripeTransactionCharge();
        charge.stripeCardId = card.id;
        charge.stripeCustomerId = card.customer;
        charge.amount = 101.35;

        this.stripeRepository.createCharge(this.customerId, charge as any)
            .then(result => { });
    }

    stripeTokenHandler(token) {
        if (this.customerId) {

            this.stripeRepository.saveCustomerCreditCard(this.customerId, token.id)
                .then(result => {
                    if (result) {
                        if (result.errors && result.errors.length > 0) {
                            this.dialog.alert('Could Not Add', `Sorry, we could not add your card.<br>Message: ${result.errors[0].message}.`);
                            this.addingNewCard = false;
                            this.setupSwipeDetect();
                            this.addingNewCardChange.emit(this.addingNewCard);
                            this.savingPaymentMethod = false;
                            this.savingPaymentMethodChange.emit(this.savingPaymentMethod);
                            this.card.clear();
                            return;
                        }

                        // this.refreshExistingCards();
                        result.saveCardForLater = this.saveCardForLater;
                        this.saveCardForLater = true;
                        this.selectCard(result);

                        this.savingPaymentMethod = false;
                        this.savingPaymentMethodChange.emit(this.savingPaymentMethod);
                        this.addingNewCard = false;
                        this.setupSwipeDetect();
                        this.addingNewCardChange.emit(this.addingNewCard);
                        this.card.clear();
                        this.newCardCreated.emit();
                        this.existingCards.push(result);

                        setTimeout(() => {
                            this.nextMobileCard();
                        }, 100);
                    } else {
                        this.dialog.alert('Could Not Add', 'Sorry, we could not add that payment method.');
                        this.addingNewCard = false;
                        this.setupSwipeDetect();
                        this.addingNewCardChange.emit(this.addingNewCard);
                        this.savingPaymentMethod = false;
                        this.savingPaymentMethodChange.emit(this.savingPaymentMethod);
                        this.card.clear();
                    }
                    this.savingPaymentMethod = false;
                });
        } else {

            this.stripeRepository.addTempCardFromToken(token.id).then(result => {
                this.selectCard(result);
                this.savingPaymentMethod = false;
                this.savingPaymentMethodChange.emit(this.savingPaymentMethod);
                this.addingNewCard = false;
                this.setupSwipeDetect();
                this.addingNewCardChange.emit(this.addingNewCard);
                this.card.clear();
                this.newCardCreated.emit();
            });
        }
    }
}
