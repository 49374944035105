import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceJobOffer {
    @keyColumn() id: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() contractorId: string;
    @column() sentDate: Date;
    @column() accepted: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() rejectedDate: Date;
}