import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderStatusChange {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() deletedDate?: Date;
    @column() oldStatus: string;
    @column() newStatus: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() oldStatusDate?: Date;
    @column() oldStatusSLA: string;
    @column() oldStatusId: string;
    @column() newStatusId: string;
    @column() workOrderLineId: string;

    itemName: string;
}