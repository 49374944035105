export class TimeZoneModel {
    public id: string;
    public displayName: string;
    public standardName: string;
    public daylightName: string;
    public baseUtcOffset: string;
    public supportsDaylightSavingTime: boolean;
    Id: string;
    DisplayName: string;
}
