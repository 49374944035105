import { Injectable, Component, Type, ComponentFactoryResolver } from "@angular/core";
import { from, Subject } from "rxjs";
import { ModalArguments, UndoArguments, EventArguments } from "./mission-service-args";
import { CogentStyleConfiguration } from '@cogent/client/shared/models/cogent-style-configuration.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AmazonContact, Entity, UserNotification } from "@upkeeplabs/models/cogent";
import { PhoneCall } from '@cogent/shared/models/common/phone-call.model';
import { NoteClient } from "@cogent/client/shared/models/common/note-client.model";


export class ApiParameters {
    constructor(
        public method: string,
        public url: string,
        public parameters: any) { }
}

export class ComponentRequestArgs {
    callback: (component: Component) => void;
    componentType: string;
}
export class ShellMenuItemModel {
    constructor(public text: string, public iconClass: string, public action: () => any, public url: string = null, public documentFragment: string = null, public key: string = null) { }
    active: boolean;
    id: string = UtilitiesService.newid();
}
export class PubSubMessage {
    type: string;
    messageBody: any;
}

export class NoteUIParams {
    component: Type<any>;
    componentFactoryResolver: ComponentFactoryResolver;
    data: any;
}

export class ExecuteFunctionArgs {
    functionJson: string;
    objectInScope: any;
    name?: string;
    iconClass?: string;
    wizardStyle?: boolean;
    pictureUrl?: string;
    fixedSize?: boolean;
    height?: string;
    description?: string;
    changeWorkingMessage?: (string) => void;
    refreshKey?: string;
    refreshAction?: () => void;
    moveLane?: (objectToMove: any) => void;
    removeFromQueue?: (objectToRemove: any) => void;
    closeObjectDetail?: (objectToMove: any) => void;
    refreshAllFunction?: () => void;
    useFunctionBackdrop?: boolean;

}

export class LottieInfoToast {
    message: string;
    url: string;
    duration?: number;
    loop =true;
}

@Injectable({ providedIn: 'root' })
export class MissionService {

    private noteAddedSource = new Subject<NoteClient>();
    private afterNoteAddedSource = new Subject<NoteClient>();
    private loggedInSource = new Subject<any>();
    private notificationSource = new Subject<string>();
    private toastSource = new Subject<string>();
    private toastSuccessSource = new Subject<string>();
    private lottieInfoSource = new Subject<LottieInfoToast>();
    private toastWarningSource = new Subject<string>();
    private apiErrorSource = new Subject<ApiParameters>();
    private modalSource = new Subject<ModalArguments>();
    private undoSource = new Subject<UndoArguments>();
    private eventSource = new Subject<EventArguments>();
    private openNoteSource = new Subject<any>();
    private phoneCallReceivedSource = new Subject<PhoneCall>();
    private userNotificationReceivedSource = new Subject<UserNotification>();
    private phoneNumberDialedSource = new Subject<string>();
    private globalMessageSource = new Subject<any>();
    private outboundPhoneCallReceivedSource = new Subject<AmazonContact>();
    private errorSource = new Subject<string>();
    private cogentStyleChangeSource = new Subject<CogentStyleConfiguration>();
    private dynamicComponentRequestedSource = new Subject<ComponentRequestArgs>();
    private overrideMenuItemsSource = new Subject<ShellMenuItemModel[]>();
    private subscriptionSource = new Subject<PubSubMessage>();
    private customNoteUISource = new Subject<NoteUIParams>();
    private addMentionSource = new Subject<Entity>();
    private removeMentionSource = new Subject<Entity>();
    private broadcastToTabsSource = new Subject<any>();
    private receivedTabsBroadcastSource = new Subject<any>();
    private executeFunctionSource = new Subject<ExecuteFunctionArgs>();

    noteAddMention = this.addMentionSource.asObservable();
    noteRemoveMention = this.removeMentionSource.asObservable();
    noteAdded = this.noteAddedSource.asObservable();
    afterNoteAdded = this.afterNoteAddedSource.asObservable();
    loggedIn = this.loggedInSource.asObservable();
    notification = this.notificationSource.asObservable();
    apiError = this.apiErrorSource.asObservable();
    error = this.errorSource.asObservable();
    toast = this.toastSource.asObservable();
    modal = this.modalSource.asObservable();
    undo = this.undoSource.asObservable();
    event = this.eventSource.asObservable();
    openNoteNeeded = this.openNoteSource.asObservable();
    toastSuccess = this.toastSuccessSource.asObservable();
    lottieInfoToast = this.lottieInfoSource.asObservable();
    toastWarning = this.toastWarningSource.asObservable();
    customNoteUI = this.customNoteUISource.asObservable();
    broadcastToTabs = this.broadcastToTabsSource.asObservable();
    receivedBroadcast = this.receivedTabsBroadcastSource.asObservable();
    functionToExecute = this.executeFunctionSource.asObservable();


    cogentStyleChange = this.cogentStyleChangeSource.asObservable();
    phoneCallReceived = this.phoneCallReceivedSource.asObservable();
    userNotificationReceived = this.userNotificationReceivedSource.asObservable();
    phoneNumberDialed = this.phoneNumberDialedSource.asObservable();
    globalMessageReceived = this.globalMessageSource.asObservable();
    outboundPhoneCallReceived = this.outboundPhoneCallReceivedSource.asObservable();
    cogentStyleConfiguration: CogentStyleConfiguration;
    dynamicComponentRequested = this.dynamicComponentRequestedSource.asObservable();
    overrideMenuItems = this.overrideMenuItemsSource.asObservable();
    subscription = this.subscriptionSource.asObservable();

    constructor() {
    }

    getSubject<T>(): Subject<T> {
        return new Subject<T>();
    }

    getFrom() {
        return from;
    }

    raiseExecuteFunction(args: ExecuteFunctionArgs) {
        this.executeFunctionSource.next(args);
    }

    requestDynamicComponent(type: string, callback: (component: Component) => void) {
        this.dynamicComponentRequestedSource.next({ componentType: type, callback });
    }

    raiseCogentStyleChange(cogentStyle: CogentStyleConfiguration) {
        this.cogentStyleConfiguration = cogentStyle;
        this.cogentStyleChangeSource.next(cogentStyle);
    }

    doLoggedIn(authenticated) {
        this.loggedInSource.next(authenticated);
    }

    newid() {
        return UtilitiesService.newid();
    }

    dialPhoneNumber(phoneNumber: string) {
        this.phoneNumberDialedSource.next(phoneNumber);
    }

    addNote(note: NoteClient) {
        this.noteAddedSource.next(note);
    }

    raiseAfterNoteAdded(note: NoteClient) {
        this.afterNoteAddedSource.next(note);
    }

    raiseGlobalMessage(message: any) {
        this.globalMessageSource.next(message);
    }

    raiseUserNotificationReceived(userNotification: UserNotification) {
        this.userNotificationReceivedSource.next(userNotification);
    }

    raiseBroadcastToTabs(data: any) {
        this.broadcastToTabsSource.next(data);
    }

    raiseReceivedBroadcast(data: any) {
        this.receivedTabsBroadcastSource.next(data);
    }

    raiseOpenNoteNeeded(noteText: string = null) {
        this.openNoteSource.next(noteText);
    }

    raiseCustomNoteSource(ui: NoteUIParams) {
        this.customNoteUISource.next(ui);
    }

    raiseNoteMentionAdded(entity: Entity) {
        this.addMentionSource.next(entity);
    }
    raiseNoteMentionRemoved(entity: Entity) {
        this.removeMentionSource.next(entity);
    }

    raisePhoneCallReceived(phoneCall: PhoneCall) {
        this.phoneCallReceivedSource.next(phoneCall);
    }

    raiseOutboundCallConnected(amazonContact: AmazonContact) {
        this.outboundPhoneCallReceivedSource.next(amazonContact);
    }

    raiseError(message: string) {
        this.errorSource.next(message);
    }

    sendNotification(event: string) {
        this.notificationSource.next(event);
    }

    showToast(message: string) {
        this.toastSource.next(message);
    }

    showSuccessToast(message: string) {
        this.toastSuccessSource.next(message);
    }

    showLottieToast(message: LottieInfoToast) {
        this.lottieInfoSource.next(message);
    }

    showLottieInfoDone(message: string) {
        this.showLottieToast({
            message,
            url: 'https://lottie.host/fccee11d-e1a8-491e-9d05-276e0324faa6/rjywfpUeJj.json',
            loop: false
        });
    }

    showLottieInfoToast(message: string) {
        this.showLottieToast({
            message,
            url: 'https://lottie.host/6391adf5-8c52-424a-9ce5-657ec17f0e3b/NNHnxPHIQL.json',
            loop: true,
        });
    }

    showWarningToast(message: string) {
        this.toastWarningSource.next(message);
    }

    showModal(message: string, title: string) {
        this.modalSource.next(new ModalArguments(message, title));
    }

    showUndo(undoArgs: UndoArguments) {
        this.undoSource.next(undoArgs);
    }

    showDesktopNotification(title: string, body: string, click: () => void, icon: string) {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission();
        } else {
            const notification = new Notification(title, { icon, body, });
            notification.onclick = () => click();
        }
    }

    raiseApiError(parameters: ApiParameters) { this.apiErrorSource.next(parameters); }

    raiseEvent(eventArgs: EventArguments) { this.eventSource.next(eventArgs); }

    showOverrideMenuItems(menuItems: ShellMenuItemModel[]) {
        this.overrideMenuItemsSource.next(menuItems);
    }

    publish(message: PubSubMessage) {
        this.subscriptionSource.next(message);
    }

    stateValues: any = {};
}
