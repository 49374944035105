export class PromiseObserverService {

    private _promiseCount = 0;
    private interval: any;

    constructor(private callback: () => void) { }


    addPromise(promise: Promise<any>): Promise<any> {
        this._promiseCount++;
        promise.then(any => {
            this._promiseCount--;
        }, () => this._promiseCount--);

        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.complete();
        }, 50);

        return promise;
    }

    get activePromiseCount(): number { return this._promiseCount; }

    complete() {
        if (this._promiseCount === 0) {
            clearInterval(this.interval);
            this.callback();
        }
    }
}
