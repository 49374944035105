import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CallCampaignItemCall {
    @keyColumn() id: string;
    @column() callCampaignItemId: string;
    @column() numberCalled: string;
    @column() dispositionId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() callId: string;
    @column() noteId: string;
}