import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { TemplateApiService } from '@cogent/client/shared/services/api/template-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { DocumentApiService } from '@cogent/client/shared/services/api/document-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { EmailArgs, EmailAttachment } from '@cogent/shared/models/other/email-args.model';
import { EmailApiService } from '@cogent/client/shared/services/api/email-api.service';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module'

@Component({
    selector: 'app-email-compose',
    templateUrl: './email-compose.component.html',
    styleUrls: ['./email-compose.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule,
        FormsModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        DragDropModule,
        RichTextAndSnippetModule
    ]
})
export class EmailComposeComponent implements OnInit, OnChanges {

    @Input() emailArgs: EmailArgs;
    @Output() messageSent: EventEmitter<boolean> = new EventEmitter();

    separatorKeysCodes = [ENTER, COMMA, SPACE];
    body = '';
    emailAddresses: EmailAddressInfo[];
    myCompanyAddresses: EmailAddressInfo[];
    toEmailAddresses: string[] = [];
    ccEmailAddresses: string[] = [];
    bccEmailAddresses: string[] = [];
    replyTo: string;
    showAttachTo = false;
    isEmailSent: boolean;
    attachments: Attachment[] = [];
    subject: string;
    workOrderId: string;
    policyId: string;
    taskId: string;

    sending = false;

    constructor(
        emailApi: EmailApiService,
        private templateApi: TemplateApiService,
        private settingsApi: SettingsApiService,
        private documentApi: DocumentApiService,
        private entityApi: EntityApiService
    ) {

        this.myCompanyAddresses = [];

        emailApi.getEmployeeList().then(employees => {
            employees.forEach(employee => {
                this.myCompanyAddresses.push({ Email: employee.email, Name: employee.name, Title: '' });
            });
        });
    }

    ngOnInit() {
    }

    get canSend() {
        return (this.toEmailAddresses.length > 0 || this.ccEmailAddresses.length > 0 || this.bccEmailAddresses.length > 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.emailArgs && changes.emailArgs.currentValue) {
            this.taskId = this.emailArgs.taskId;
            this.workOrderId = this.emailArgs.workOrderId;
            this.policyId = this.emailArgs.policyId;

            this.emailAddresses = [];
            if (this.emailArgs && this.emailArgs.suggestedAddresses) {
                this.emailArgs.suggestedAddresses.forEach(suggestion => {
                    this.emailAddresses.push({ Email: suggestion.email, Name: suggestion.name, Title: suggestion.title });
                });
            }
            this.subject = this.emailArgs.subject;
            if (this.emailArgs.message) {
                this.body = this.emailArgs.message;
            }

            if (this.emailArgs.toAddresses) {
                this.toEmailAddresses = [];
                this.emailArgs.toAddresses.forEach(address => {
                    const addressesSplit = address.email.split(',');
                    for (const emailAddress of addressesSplit) {
                        this.toEmailAddresses.push(emailAddress);
                    }
                });
            }

            if (this.emailArgs.ccAddresses) {
                this.ccEmailAddresses = [];
                this.emailArgs.ccAddresses.forEach(address => {
                    const addressesSplit = address.email.split(',');
                    for (const emailAddress of addressesSplit) {
                        this.ccEmailAddresses.push(emailAddress);
                    }
                });
            }

            if (this.emailArgs.bccAddresses) {
                this.bccEmailAddresses = [];
                this.emailArgs.bccAddresses.forEach(address => {
                    const addressesSplit = address.email.split(',');
                    for (const emailAddress of addressesSplit) {
                        this.bccEmailAddresses.push(emailAddress);
                    }
                });
            }

            if (this.emailArgs.attachments) {
                this.attachments = this.emailArgs.attachments.map(i => {
                    return {
                        name: i.fileName,
                        data: i.fileContents,
                        type: i.contentType,
                        fileUrl: i.fileUrl,
                    };
                });
            }

            if (this.emailArgs.useSignature) {
                this.templateApi.getTemplateEnvelopeHtml('EMAIL-SIGNATUREv3').then(signature => {
                    if (signature) {

                        this.entityApi.getLoggedInUser(true).then(fullUser => {

                            this.settingsApi.getCompanyInfo().then(company => {
                                signature = signature.replace('{{name}}', fullUser.displayName);
                                signature = signature.replace('{{firstName}}', fullUser.firstName);
                                signature = signature.replace('{{lastName}}', fullUser.lastName);
                                signature = signature.replace('{{lastInitial}}', fullUser.lastName[0]);
                                signature = signature.replace('{{title}}', fullUser.title);
                                signature = signature.replace('{{companyName}}', company.displayName);
                                signature = signature.replace('{{companyNumber}}', company.officeNumber.number);
                                signature = signature.replace('{{companyWebsite}}', company.website);
                                signature = signature.replace('{{email}}', fullUser.email);
                                this.body += '<br>' + signature;
                            });


                        });
                    }
                });
            }
        }
    }

    private validateEmail(email) {
        return UtilitiesService.validateEmail(email);
    }

    add(list: string[], event: MatChipInputEvent): void {
        const input = event.input;
        const validEmail = this.addEmail(list, event.value);

        if (input && validEmail) {
            input.value = '';
        }
    }

    addEmail(list: string[], emailAddressesValue: string): boolean {
        const emailAddresses = (emailAddressesValue || '').replace(';', ' ').replace(',', ' ').trim().split(' ');

        let validEmail = false;
        for (let i = 0; i < emailAddresses.length; i++) {
            const email = (emailAddresses[i] || '').trim();
            if (this.validateEmail(email)) {
                if (list.indexOf(email) === -1) {
                    list.push(email);
                }
                validEmail = true;
            }
        }
        return validEmail;
    }

    remove(emailAddress: string, emailAddressList: string[]) {
        const index = emailAddressList.indexOf(emailAddress);
        emailAddressList.splice(index, 1);
    }

    filterEmails(filter: string) {
        if (this.myCompanyAddresses) {
            const emailsAddresses = this.myCompanyAddresses.concat(this.emailAddresses || []);
            if (filter) {
                return emailsAddresses.filter(l => (l.Email && l.Email.toLowerCase().includes(filter.toLowerCase()))
                    || l.Name.toLowerCase().includes(filter.toLowerCase())
                    || l.Title.toLowerCase().includes(filter.toLowerCase())).slice();
            }
        }
        return this.emailAddresses;
    }

    send() {
        this.sending = true;
        let to = '';
        let cc = '';
        let bcc = '';
        if (this.toEmailAddresses) {
            this.toEmailAddresses.forEach(email => {
                if (to === '') {
                    to = email;
                } else {
                    to += ';' + email;
                }
            });
        }

        if (this.ccEmailAddresses) {
            this.ccEmailAddresses.forEach(email => {
                if (cc === '') {
                    cc = email;
                } else {
                    cc += ';' + email;
                }
            });
        }

        if (this.bccEmailAddresses) {
            this.bccEmailAddresses.forEach(email => {
                if (bcc === '') {
                    bcc = email;
                } else {
                    bcc += ';' + email;
                }
            });
        }

        const attachments: EmailAttachment[] = [];

        this.attachments.forEach(attachment => {
            const serviceAttachment = new EmailAttachment();
            if (!attachment.type) {
                serviceAttachment.fileName = attachment.name;
                if (attachment.data) {
                    const commaLoc = attachment.data.indexOf(',');
                    serviceAttachment.fileContents = attachment.data.substring(commaLoc + 1, attachment.data.length);
                    const semiLoc = attachment.data.indexOf(';');
                    serviceAttachment.contentType = attachment.data.substring(5, semiLoc);
                }
                if (attachment.fileUrl) {
                    serviceAttachment.fileUrl = attachment.fileUrl;
                }
            } else {
                serviceAttachment.fileName = attachment.name;
                serviceAttachment.fileContents = attachment.data;
                serviceAttachment.contentType = attachment.type;
                serviceAttachment.fileUrl = attachment.fileUrl;
            }
            attachments.push(serviceAttachment);
        });


        this.documentApi.sendHtmlDocument(to, this.subject, this.body,
            this.workOrderId, this.taskId, this.policyId,
            true, cc, attachments, this.emailArgs.entityId, this.emailArgs.replyToAddress, bcc).then(() => {
                this.sending = false;
                this.messageSent.emit(true);
            });
    }

    drop(event) {
        event.stopPropagation();
        event.preventDefault();
        const dt = event.dataTransfer;
        const files = dt.files;
        this.handleFiles(files);
    }

    dragenter(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    dragover(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    handleFiles(files: FileList) {
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                this.addFile(files[i]);
            }
        }
    }

    addFile(file: File) {
        const reader = new FileReader();
        reader.onload = (evt) => {
            this.attachments.push({ name: file.name, data: (evt.target as any).result, type: null, fileUrl: null });
        };
        reader.readAsDataURL(file);

    }

    deleteAttachment(attachment) {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
    }
}
export interface Attachment {
    name: string;
    data: string;
    type: string;
    fileUrl: string;
}
export interface EmailAddressInfo {
    Title: string;
    Name: string;
    Email: string;
}
