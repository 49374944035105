import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class RepairItemAttributeValue {
    @keyColumn() id: string;
    @column() repairItemAttributeId: string;
    @column() value: string;
    @column() amountDelta: number;
    @column() sort: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() yellowRangePercentDelta: number;
    @column() laborHoursDelta: number;
    @column() dependencies: string;
    @column() laborAmountDelta: number;
    hidden = false;
}