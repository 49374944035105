import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class RepairItemGroupTrade {
    @keyColumn() id: string;
    @column() repairItemGroupId: string;
    @column() tradeId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}