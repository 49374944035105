<button mat-button *ngIf="!hideButton">
  <i class="material-icons" *ngIf="stateLabel == 'Open'">
    signal_cellular_alt
  </i>

  <i class="material-icons" *ngIf="stateLabel != 'Open'">
      signal_cellular_off
  </i>

</button>
