
export class QuestionAttribute {
    key: string;
    value: any;
}

export class Question {
    text: string;
    type: string;
    answers: Answer[];
    id: string;
    nextQuestionId: string;
    internalNote: string;
    answer: any;
    continueOriginalId: string;
    questionAttributes: QuestionAttribute[];
    originalType: string;
    instanceId: string;
    lastQuestionOfInstance: boolean;
    answerId: string;
    meta: any;
    safeUrl: any;
    originalText: string;
    hidden = false;
}

export class Answer {
    text: string;
    id: string;
    nextQuestionId: string;
    internalNote: string;
    parentQuestionId: string;
    geometry: AnswerGeometry;
    selected: boolean;
    continueOriginalId: string;
    meta: any;
    hidden: boolean;
    brandId: string;
}

export class QuestionParserResult {
    questions: Question[];
    questionStack: any;
    allAnswers: Answer[];
    validationWarnings: any;
}

export class AnswerGeometry {
    x: number;
    y: number;
}
