import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { MaintenanceServiceOfferingAddOn } from "./maintenance-service-offering-add-on.model";

@dataSource()
export class MaintenanceServiceCustomerPropertyServiceSummary {
    @keyColumn() id: string;
    @column() price: number;
    @column() contractorName: string;
    @column() contractorId: string;
    @column() effectiveDate: Date;
    @column() expirationDate: Date;
    @column() cancellationDate: Date;
    @column() maintenanceServiceOfferingId: string;
    @column() shortSummary: string;
    @column() name: string;
    @column() contractLanguage: string;
    @column() color: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() maintenanceServiceId: string;
    @column() frequencyName: string;
    @column() visitsPerYear: number;
    @column() preferredDay: number;
    @column() preferredWeek: number;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerPhoneNumber: string;
    @column() latitude: number;
    @column() longitude: number;
    @column() cost: number;
    @column() preferredDayName: string;
    @column() customerId: string;
    @column() contractorEmail: string;
    @column() contactViaEmail: boolean;
    @column() contactViaSMSText: boolean;
    @column() contractorNumber: string;
    @column() addressId: string;
    @column() schedulingBufferDays: number;
    @column() startDate: Date;
    @column() pricePerMonth: number;
    @column() createdDate: Date;
    @column() stripeCardId: string;
    @column() maintenanceServicePricingId: string;
    @column() appointmentLines: string;
    @column() isOneTime: boolean;
    @column() addOnFunctionId: string;
    @column() costPerMonth: number;
    @column() status: string;
    @column() lastModifiedById: string;
    @column() allowChangeFrequency: boolean;
    @column() initialMonthPrice: number;
    @column() noRefundOnCancellation?: boolean;
    @column() lottieAnimationUrl: string;
    @column() countGrouping: string;
    @column() cancellationFee?: number;
    @column() cancellationFeeMonthsInEffect?: number;
    @column() firstAppointment?: Date;
    @column() pastDueMaintInvoices?: number;
    @column() maintInvoiceMinimumDueDate?: Date;
    @column() hasPastDueMaintenanceServiceInvoices: boolean;
    @column() promotionCodeId: string;
    @column() cancellationReasonId: string;
    @column() cancellationReason: string;
    @column() cancellationNote: string;

    flipped = false;


    private _appointments: MaintenanceServiceCustomerPropertyServiceAptShortSummary[];
    get appointments() {
        if (!this._appointments) {
            try {
                this._appointments = JSON.parse(this.appointmentLines);
            } catch { }
        }

        return this._appointments;
    }

    setLines() {
        this._appointments = this.appointments;
    }

    addOns: MaintenanceServiceOfferingAddOn[];
}

export class MaintenanceServiceCustomerPropertyServiceAptShortSummary {
    scheduledDate: Date;
}