<div style="position:absolute; top:0; bottom:0; left:0; right:0; z-index:10;" (click)="showDropDown=false" *ngIf="showDropDown">
</div>
<div style="position:relative;" [class.dropped]="showDropDown">
    <mat-form-field appearance="outline">
        <mat-label>Service Pro Territories</mat-label>
        <input matInput [(ngModel)]="selectedName">
    </mat-form-field>
    <div style="position:absolute; top:0;bottom:0;left:0;right:0;" (click)="showDropDown = !showDropDown"></div>
    <div style="position:absolute; right:5px; top:1px; background-color: white;" (click)="showDropDown = !showDropDown">
        <mat-icon class="rotate" [class.rotated]="showDropDown">chevron_right</mat-icon>
    </div>
    <div style="position:absolute; top:30px; left:2px; right:2px; background-color:white; border: 1px solid  gray; z-index: 100; max-height:300px; overflow-y:auto;"
        *ngIf="showDropDown">
        <div *ngFor="let area of areas" style="margin-left:5px;">
            <mat-checkbox [(ngModel)]="area.selected"
                (change)="selectChildren(area, area.selected)">{{area.name}}</mat-checkbox>
            <a (click)="area.expanded = !area.expanded; $event.stopPropagation();" style="cursor: pointer;">
                <mat-icon class="rotate" [class.rotated]="area.expanded">chevron_right</mat-icon>
            </a>
            <div style="overflow:hidden;" *ngIf="area.expanded && area.children && area.children.length > 0">
                <div *ngFor="let region of area.children" class="indent slide-in-down">
                    <mat-checkbox [(ngModel)]="region.selected"
                        (change)="selectChildren(region, region.selected)">{{region.name}}</mat-checkbox>
                    <a (click)="region.expanded = !region.expanded; $event.stopPropagation();" style="cursor: pointer;">
                        <mat-icon class="rotate" [class.rotated]="region.expanded">chevron_right</mat-icon>
                    </a>
                    <div style="overflow:hidden;"
                        *ngIf="region.expanded && region.children && region.children.length > 0">
                        <div *ngFor="let territory of region.children" class="indent slide-in-down">
                            <mat-checkbox [(ngModel)]="territory.selected"
                                (change)="selectChildren(territory, territory.selected)">{{territory.name}}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>