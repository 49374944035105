import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { AppointmentTimeSlot } from "./appointment-time-slot.model";
import { Entity } from "./entity.model";
import { PolicyServiceOffering } from "./policy-service-offering.model";
import { PolicySummaryV2 } from "./policy-summary.model";
import { Question, QuestionParserResult } from "./question-parser.model";
import { SMSQuestionnaire } from "./s-m-s-questionnaire.model";
import { WorkOrderLineSummary } from "./work-order-line-summary.model";
import { WorkOrder } from "./work-order.model";

@dataSource()
export class SMSClaimSubmission {
    @keyColumn() id: string;
    @column() phoneNumber: string;
    @column() policyId: string;
    @column() sessionState: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() completionDate: Date;
    @column() fromNumber: string;

    private _sessionStateObject: SMSClaimSubmissionSessionState;

    get SessionStateObject() {
        if(!this._sessionStateObject) {
            try {
                this._sessionStateObject = JSON.parse(this.sessionState);
            }catch {
                this._sessionStateObject = new SMSClaimSubmissionSessionState();
            }
        }

        return this._sessionStateObject;
    }
}



export enum SMSClaimSubmisionStep
{
    FindProperty,
    SelectItemGroup,
    SelectItem,
    Questionnaire,
    Photos,
    ServiceFee,
    PreferredTimeSlot1,
    PreferredTimeSlot2,
    PreferredTimeSlot3,
    ReviewContactInformation,
    EditContactName,
    EditContactEmail,
    EditContactPhone,
    EditContactMobile,
    TermsAndConditions,
    Review,
}

export class SMSClaimSubmissionSessionState {
    currentStep: SMSClaimSubmisionStep;
    from: string;
    policyId: string;
    selectedCard: any;
    policySummary: PolicySummaryV2;
    contact: Entity;
    workOrder: WorkOrder;
    photos: any[];
    lines: WorkOrderLineSummary[] = [];
    matchedPolicies: MatchedPolicy[];
    lastQuestion: Question;
    policyServiceOfferings: PolicyServiceOffering[];
    selectedItem: PolicyServiceOffering;
    smsQuestionnaire: SMSQuestionnaire;
    serviceFeeAmount: number;
    questionParserResult: QuestionParserResult;
    noteAttachmentUrl: string[];
    cards: any[];
    appointmentTimeSlots: AppointmentTimeSlot[];
    selectedItemGroup: string;
    timeSlots: TimeSlot[];
    selectedTimeSlots: TimeSlot[];
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactMobile: string;
    editingCreditCard: boolean;
}

export class MatchedPolicy {
    id: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
}

export enum TimeSlotRange {
    Morning,
    MidDay,
    Afternoon,
    AllDay
}

export class TimeSlot {
    date: string;
    range: TimeSlotRange;
    id: string;
}