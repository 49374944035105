
import { Singleton, WorkflowInstance } from '@upkeeplabs/models/cogent';
import { IStepExecutor, typeMap, FunctionCell, ExecutorInputParams, ExecutorOutputParams, CustomFormQuestions, CellPort, PortItem, IQuestionRenderer, ISMSQuestion, functionToolbars } from './function-runner.model';

export class ExecutedStep {
    id: string;
    type: string;
    result: any;
    date: Date;
    nodeInstanceId: string;
}

export class SubFunctionStack {
    callingBlockId: string;
    resumeBlockId: string;
    firstSubFunctionBlockId: string;
    inputArgs: any;
}

export class FunctionRunner {
    cells: FunctionCell[];
    outputValues = {};
    lastCustomFormResult: any;
    showActivityIndicator: boolean;
    activityMessage: string;
    showCustomForm: (questions: CustomFormQuestions) => void;
    setupFunctionQuestionRender: (runner: FunctionRunner) => Promise<void>;
    parent?: FunctionRunner;
    getSingleton: (functionRunner: FunctionRunner) => Promise<Singleton>;
    questionRenderer: IQuestionRenderer;
    workflowInstanceId: string;
    envVariables: any = {};
    executedSteps: ExecutedStep[] = [];
    nodeInstanceId: string;
    process: string;
    subFunctionStackTrace: SubFunctionStack[] = [];
    lastExecutedStep: FunctionCell;
    changeWorkingMessage?: (string) => void;
    transferRunner?: (newRunner: FunctionRunner) => void;
    changeLanes?: (objectToMove: any) => void;
    closeObjectDetail?: (objectToMove: any) => void;
    removeFromQueue?: (objectToRemove: any) => void;
    static instanceCount = 0;
    instanceNumber = 0;
    onTransfer?: (runner: FunctionRunner) => void;
    private endWhenNoLastStep = false;

    constructor(public executors: IStepExecutor[],
        public dependencies: any,
        public objectInScope,
        public callback: (result, final: boolean) => void,
        public executionContext: "node" | "angular" | "sms" | "voice" | "dispatch") {
        FunctionRunner.instanceCount++;
        this.instanceNumber = FunctionRunner.instanceCount;
    }

    async runProcess(process: string, findFirstStep = false) {

        this.process = process;
        this.cells = (JSON.parse(process)).cells;
        const start = this.cells.find(i => i.type == typeMap.start);
        this.envVariables.executionContext = this.executionContext;

        if (start) {

            await this.executeAndAdvance(start);

        } else {
            if (findFirstStep) {
                this.endWhenNoLastStep = true;
                const links = this.cells.filter(i => i.type === 'standard.Link');
                const nonLinkCells = this.cells.filter(i => i.type !== 'standard.Link');
                for (const cell of nonLinkCells) {
                    const foundDestinationLink = links.find(i => i.target.id === cell.id);
                    if (!foundDestinationLink) {
                        await this.executeAndAdvance(cell);
                        return;
                    }
                }
                if (nonLinkCells.length === 0) {
                    this.callback(null, true);
                }
            } else {
                console.error('no entry point specified');
            }
        }
    }

    async resumeProcess(workflowInstance: WorkflowInstance, summary: any = null, answer: string = null, mediaUrls: string[] = null, smsTo: string = null, smsFrom: string = null) {
        this.workflowInstanceId = workflowInstance.id;
        if (workflowInstance.environmentJson) {
            try {
                this.envVariables = JSON.parse(workflowInstance.environmentJson);
                if (this.envVariables.envVariables) {
                    this.envVariables = this.envVariables.envVariables;
                }
            } catch (e) { }
        }
        this.envVariables.workflowInstanceId = this.workflowInstanceId;
        this.envVariables.executionContext = this.executionContext;
        this.envVariables.smsTo = smsTo;
        this.envVariables.smsFrom = smsFrom;
        this.envVariables.smsLastResponse = answer;
        this.envVariables.smsMediaUrls = mediaUrls;
        if (summary) {
            this.envVariables.summary = summary;
        }
        const process = JSON.parse(workflowInstance.process);
        this.cells = process.cells ? process.cells : process;

        if (workflowInstance.environmentJson) {
            this.outputValues = JSON.parse(workflowInstance.environmentJson)?.outputValues;
        }
        if (!this.outputValues) {
            this.outputValues = {};
        }

        try {
            const step = workflowInstance.nextExecutedStepId ? this.cells.find(i => i.id === workflowInstance.nextExecutedStepId) : this.cells.find(i => i?.type == typeMap.start);
            if ((!answer && (!mediaUrls || mediaUrls.length == 0)) || step.type === typeMap.start) {
                await this.executeAndAdvance(step);
            } else {
                const executor = this.getExecutor(step);
                const smsQuestion: ISMSQuestion = (executor as any).respondToAnswer ? executor as any : null;
                if (smsQuestion) {
                    const inputParams = await this.getStepInputParams(step);
                    const result = await smsQuestion.respondToAnswer(answer, inputParams);
                    if (!result.quit) {
                        this.executedSteps.push({
                            date: new Date(),
                            id: step.id,
                            type: step.type,
                            result: result,
                            nodeInstanceId: this.nodeInstanceId,
                        });

                        await this.processExecutedOutput(step, result);
                    } else {
                        await this.processExecutedOutput(step, result);
                    }
                }

            }
        } catch (e) {
            throw e;
        }
    }

    async getNextStepToExecute(step: FunctionCell, result: ExecutorOutputParams) {

        const transmissionOutPorts = step.ports?.items?.filter(i => i.group === 'transmissionOut');

        let transmissionOut = transmissionOutPorts.find(i => i.attrs?.label?.text === result.result);
        if (step.type === typeMap.aiChat && result.result === true) {
            transmissionOut = transmissionOutPorts[0];
        }

        if (transmissionOut) {
            const connectionLine = this.cells.find(i => i.type === 'standard.Link' && i.source?.port === transmissionOut.id);
            if (!connectionLine) {
                transmissionOut = null;
            }
        }

        if (result.outputPort) {

            transmissionOut = result.outputPort;
        }

        if (!transmissionOut) {
            transmissionOut = transmissionOutPorts?.find(i => i.attrs?.label?.text === '_Default');

            if (!transmissionOut) {
                if (transmissionOutPorts.length === 1) {
                    transmissionOut = transmissionOutPorts[0];
                }
            }
        }

        if (transmissionOut) {
            const connectionLine = this.cells.find(i => i.type === 'standard.Link' && i.source?.port === transmissionOut.id);
            if (connectionLine) {
                const destinationCell = this.cells.find(i => i.id === connectionLine.target.id);
                return destinationCell;
            }
        }

    }

    async processExecutedOutput(step: FunctionCell, result: ExecutorOutputParams) {

        if (!result) {
            console.error('no result set');
            console.error(step);
            return;
        }
        if (result.quit) {
            this.callback(true, false);
            return;
        }
        if (result.nextFunction) {

            await this.executeAndAdvance(result.nextFunction);
            return;
        }
        if (!this.outputValues) {
            this.outputValues = {};
        }

        this.outputValues[step.id] = result.result;
        const destinationCell = await this.getNextStepToExecute(step, result);

        if (destinationCell) {
            await this.executeAndAdvance(destinationCell);
        } else {

            if (this.endWhenNoLastStep) {
                // TODO: give this a meaninful value
                const results = [];
                for (const val in this.outputValues) {
                    const cell = this.cells.find(i => i.id === val);
                    results.push({
                        question: cell.attrs.label.text,
                        answer: this.outputValues[val]
                    })
                }
                this.callback(results, true);
            } else {
                console.error('cannot find next step to execute ')
            }
        }
    }


    async executeAndAdvance(step: FunctionCell) {
        if (step.type === typeMap.endProcess) {
            if (this.callback) {
                const result = await this.executeStep(step);
                this.callback(result.result, true);
            }
            return;
        }

        const result = await this.executeStep(step);
        this.executedSteps.push({
            date: new Date(),
            id: step.id,
            type: step.type,
            result: result,
            nodeInstanceId: this.nodeInstanceId,
        });

        await this.processExecutedOutput(step, result);
        this.lastExecutedStep = step;
    }

    async getInputPortValue(port: PortItem, step: FunctionCell) {
        const connectionLine = this.cells.find(i => i.type === 'standard.Link' && i.source && i.target && i.target.port === port.id);
        if (connectionLine) {
            const inputSource = this.cells.find(i => i.id === connectionLine.source.id);
            inputSource.noResetLoop = true;
            const inputResult = this.outputValues[inputSource.id] != undefined ? this.outputValues[inputSource.id] : (await this.executeStep(inputSource)).result;
            delete inputSource.noResetLoop;
            if (inputResult === '___unset___') {
                return undefined;
            }

            return inputResult;
        } else {
            const input = step.custom?.inputs?.find(i => i.id === port.id);
            if (input) {
                return input.value;
            } else {
                return null;
            }
        }
    }

    private static replaceAllRegEx(target: string, search: string, replacement: string) {
        if (!target) {
            return '';
        }
        if (!target.replace) {
            return target;
        }
        return target.replace(new RegExp(search, 'g'), replacement);
    }

    private static replaceAll(target: string, search: string, replacement: string) {
        if (!target) {
            return '';
        }
        if (!replacement) {
            replacement = '';
        }
        return target.split(search).join(replacement);
    }

    static replaceValuesInStringFromObject(source: string, values: any, prefix = '', escapeDoubleQuotes = false): string {
        let result = String(source);

        if (values) {
            for (const key of Object.keys(values)) {
                const searchFor = prefix ? `${prefix}.${key}` : key;
                let replaceValue = values[key];
                if (replaceValue && replaceValue.getDate && replaceValue.toISOString) {
                    replaceValue = replaceValue.toISOString();
                }

                if (escapeDoubleQuotes && replaceValue) {
                    replaceValue = this.replaceAllRegEx(replaceValue, '"', '\\"');
                }
                result = this.replaceAll(result, `{{${searchFor}}}`, replaceValue);
            }

            // Seems like this is the wrong way, but I can't find another way to iterate over getters
            if (values.__proto__) {
                for (const key of Object.keys(values.__proto__)) {
                    const searchFor = prefix ? `${prefix}.${key}` : key;

                    let replaceValue = values[key];
                    if (replaceValue && replaceValue.getDate && replaceValue.toISOString) {
                        replaceValue = replaceValue.toISOString();
                    }
                    if (escapeDoubleQuotes && replaceValue) {
                        replaceValue = this.replaceAllRegEx(replaceValue, '"', '\\"');
                    }
                    result = this.replaceAll(result, `{{${searchFor}}}`, replaceValue);
                }
                if (values.__proto__) {
                    for (const keyItem of Reflect.ownKeys(values.__proto__)) {
                        const key = keyItem.toString();
                        const searchFor = prefix ? `${prefix}.${key}` : key;

                        let replaceValue = values[key];
                        if (escapeDoubleQuotes && replaceValue) {
                            replaceValue = this.replaceAllRegEx(replaceValue, '"', '\\"');
                        }

                        result = this.replaceAll(result, `{{${searchFor}}}`, replaceValue);
                    }
                }
            }
        }
        return result;
    }

    async getStepInputParams(step: FunctionCell): Promise<ExecutorInputParams> {
        const params = new ExecutorInputParams();
        const inputArgs = [];
        if (step.ports && step.ports.items) {
            const inPorts = step.ports.items.filter(i => i.group === 'in');

            let index = 0;
            for (const inPort of inPorts) {
                let value = await this.getInputPortValue(inPort, step);
                const tb = functionToolbars.find(i => i.url === step.type);
                let boolConversion = false;
                if (tb && tb.inputPortTypes && tb.inputPortTypes[index] === 'boolean') {
                    boolConversion = true;
                }
                if (typeof (value) === "string" && value.includes("{{") && value.includes("}}")) {
                    value = FunctionRunner.replaceValuesInStringFromObject(value, this.objectInScope);
                    for (const sessionObjKey in this.envVariables) {
                        const sesssionObj = this.envVariables[sessionObjKey];

                        if (sesssionObj) {
                            value = FunctionRunner.replaceValuesInStringFromObject(value, sesssionObj, sessionObjKey);
                        }
                    }
                }
                if (typeof (value) === 'string' && value.includes('{{singleton.') && this.getSingleton) {
                    const singleton = await this.getSingleton(this);
                    value = FunctionRunner.replaceValuesInStringFromObject(value, singleton, 'singleton');
                }

                if (boolConversion && !value) {
                    value = false;
                }
                if (boolConversion && typeof value === 'string') {
                    if (value.toLowerCase() === 'false') {
                        value = false;
                    } else if (value.toLowerCase() === 'true') {
                        value = true;
                    }
                }
                inputArgs.push(value);
                index++;
            }
        }
        params.currentCell = step;
        params.inputs = inputArgs;
        params.dependencies = this.dependencies;
        params.objectInScope = this.objectInScope;
        params.currentRunner = this;

        return params;
    }

    private deleteStepAndDependencies(step: FunctionCell, processed: string[]) {
        delete this.outputValues[step.id];

        processed.push(step.id);
        const childSteps = step.ports.items.filter(i => i.group === 'in');

        for (const child of childSteps) {
            const connectingLine = this.cells.find(i => i.target?.port === child.id);
            if (connectingLine) {
                const childCell = this.cells.find(i => i.id === connectingLine.source.id);

                if (childCell) {
                    const transmissionIn = childCell.ports.items.find(i => i.group === 'transmissionIn');
                    if (!transmissionIn) {
                        this.deleteStepAndDependencies(childCell, processed);
                    }
                }
            }
        }
    }

    async executeStep(step: FunctionCell): Promise<ExecutorOutputParams> {


        const executor = this.getExecutor(step);
        let result: ExecutorOutputParams = null;
        this.deleteStepAndDependencies(step, []);

        if (executor) {
            const params = await this.getStepInputParams(step);

            try {
                result = await executor.executeStep(params);
            } catch (error) {
                console.error(error);
            }
            if (!result) {
                console.error('no exection result');
                console.error(params);
                console.error(executor.type);
                throw 'no execution result';
            }

        } else {
            console.error('Executor not found');
        }
        return result;
    }

    getExecutor(step: FunctionCell): IStepExecutor {
        const executor = this.executors.find(i => i.type === step.type);
        if (executor) {
            return executor;
        }

        throw new Error(`Not Implemented: ${step.type}`);
    }
}
