import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class OidcState {
    @keyColumn() id: string;
    @column() issuer: string;
    @column() clientId: string;
    @column() redirectUri: string;
    @column() responseType: string;
    @column() scope: string;
    @column() state: string;
    @column() codeChallenge: string;
    @column() codeChallengeMethod: string;
    @column() nonce: string;
    @column() deletedDate?: Date;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() anonymousEntityId: string;
    @column() impersonationEntityId: string;
}