<mat-form-field class="example-chip-list" *ngIf="!selectMode" appearance="outline">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let result of selectedResults" [removable]="removable"
            (removed)="remove(result)">
            {{result.value}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input #searchInput matInput [formControl]="searchCtrl" [matAutocomplete]="auto" autocomplete="new-password"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let result of allItems" [value]="result">
            {{result.value}}
        </mat-option>
    </mat-autocomplete>
    <mat-spinner class="small" matSuffix *ngIf="searching"></mat-spinner>
</mat-form-field>

<mat-form-field *ngIf="selectMode" appearance="outline">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-select [(ngModel)]="selectedItem" (ngModelChange)="selectChange($event)">
        <mat-option *ngFor="let result of allItems" [value]="result">{{result.value}}</mat-option>
    </mat-select>
</mat-form-field>