import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { RichTextAndSnippetComponent } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet/rich-text-and-snippet.component';
import { Entity } from '@upkeeplabs/models/cogent';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { NoteClient } from '@cogent/client/shared/models/common/note-client.model';

@Component({
    selector: 'app-add-note',
    templateUrl: './add-note.component.html',
    styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {

    note: NoteClient;
    saving = false;
    selectedIndex = 0;
    mentionableFilter = '';

    mentionables: Entity[] = [];
    entities: Entity[];
    @ViewChild('richText') richText: RichTextAndSnippetComponent;

    constructor(public dialogRef: MatDialogRef<AddNoteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private missionService: MissionService,
        private entityApi: EntityApiService,
        private serviceApi: ServiceApiService) {
    }

    async ngOnInit() {
        this.note = new NoteClient();
        this.note.id = UtilitiesService.newid();
        this.note.showOnContractorPortal = true;
        this.note.workOrderId = this.data.id;
        this.note.type = '';
        this.entities = await this.serviceApi.getAssignedEmployees(this.data.id);
        await this.loadMentionables();
    }

    get canSave() {
        return this.note.noteText && !this.saving;
    }

    async loadMentionables(){
        for (const entity of this.entities) {
            const permissions = await this.entityApi.getUserPermissions(entity.id);
            for (const permission of permissions) {
                if (permission.functionalPermissionsObject.indexOf('CONTRACTOR-CAN-TAG') > -1) {
                    this.mentionables.push(entity);
                }
            }
        };
    }

    get filteredMentionables() { 
        if (!this.mentionableFilter) {
            return this.mentionables;
        }

        return this.mentionables.filter(i => i.name?.toLowerCase().indexOf(this.mentionableFilter.toLocaleLowerCase()) > -1);
    }

    showTag() {

        for (const entity of this.mentionables) {
            if (this.note.noteText.indexOf(`data-entity-id="${entity.id}"`) > -1) {
                entity.selected = true;
            } else {
                entity.selected = false;
            }
        }
        this.selectedIndex = 1;
        this.mentionableFilter = '';
    }

    applyTags() {
        let noteText = this.note.noteText;
        for (const entity of this.mentionables.filter(i => i.selected)) {
            if (noteText.indexOf(`data-entity-id="${entity.id}"`) === -1) {
                noteText += ` <a data-entity-id="${entity.id}" class="mentioned-href">@${entity.name}</a>`;
            }
        }
        for (const entity of this.mentionables.filter(i => !i.selected)) {
            noteText = UtilitiesService.replaceAll(noteText, `<a data-entity-id="${entity.id}" class="mentioned-href">@${entity.name}</a>`, '');
        }
        this.selectedIndex = 0;
        setTimeout(() => {
            this.note.noteText = noteText;

        }, 500);
    }

    async save() {
        this.saving = true;
        this.note.type = "Contractor Generated";
        if (this.data.contractorIdFromPrarm) {
            this.note.createdById = this.data.contractorIdFromPrarm;
        } 
        await this.serviceApi.saveWorkOrderNoteNoAuth(this.note);
        this.saving = false;
        this.dialogRef.close(true);
        this.missionService.showSuccessToast('Note Added');
    }



}
