import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SecurityFilteredLog {
    @keyColumn() id: string;
    @column() objectId: string;
    @column() objectType: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() objectJson: string;
}