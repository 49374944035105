import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { RolePermission } from "./role-permission.model";

@dataSource()
export class Role {
    @keyColumn() id: string;
    @column() name: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;

    // client-side only
    permissions: RolePermission[];
    permissionsToDelete: RolePermission[];
}