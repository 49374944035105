import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceFilter {
    @keyColumn() id: string;
    @column() maintenanceServiceId: string;
    @column() name: string;
    @column() description: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    selected: boolean;
    filterOptions: MaintenanceServiceFilterOption[];
    selectedOption: MaintenanceServiceFilterOption;
}

export class MaintenanceServiceFilterOption {
    name: string;
    id: string;
    description: string;
    pictureUrl: string;
    invertOnSelection: boolean;
}