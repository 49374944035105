<div flex style="height: 100%;">
    <div nogrow style=" min-width:300px; overflow-y:auto; " *ngIf="!hideSelector">
        <div class="title">Zip Codes</div>
        <mat-checkbox [(ngModel)]="hideUnselected">Hide Unselected</mat-checkbox>

        <div *ngIf="states">
            <ng-container *ngFor="let state of states">
                <div *ngIf="!hideUnselected || state.numberSelected > 0" style="margin-top:-7px;">
                    <mat-icon class="rotate" [class.rotated]="!state.expanded"
                        (click)="state.expanded = !state.expanded">
                        expand_more</mat-icon>
                    <mat-checkbox style="height:10px;" [(ngModel)]="state.isChecked"
                        (change)="state.onSelectionChanged()" (mouseover)="hover(state, true)"
                        (mouseout)="hover(state, false)"
                        [indeterminate]="state.numberSelected > 0 && state.numberSelected != state.total">
                        {{state.name}}
                    </mat-checkbox> <span class="quantity-indicator">( {{state.numberSelected}} /
                        {{state.total}} )</span>
                    <div style="overflow:hidden;">
                        <div *ngIf="state.expanded" class="expand">
                            <ng-container *ngFor="let county of state.children">
                                <div *ngIf="!hideUnselected || county.numberSelected > 0" style="margin-top:-7px;">
                                    <mat-icon class="rotate" [class.rotated]="!county.expanded"
                                        (click)="county.expanded = !county.expanded">
                                        expand_more</mat-icon>

                                    <mat-checkbox (mouseover)="hover(county, true)" (mouseout)="hover(county, false)"
                                        [(ngModel)]="county.isChecked" (change)="county.onSelectionChanged()"
                                        [indeterminate]="county.numberSelected > 0 && county.numberSelected != county.total">
                                        {{county.name}} </mat-checkbox> <span class="quantity-indicator">(
                                        {{county.numberSelected}}
                                        / {{county.total}} )</span>
                                    <div style="overflow:hidden;">

                                        <div *ngIf="county.expanded" class="expand">

                                            <ng-container *ngFor="let city of county.children">
                                                <div *ngIf="!hideUnselected || city.numberSelected > 0"
                                                    style="margin-top:-7px;">
                                                    <mat-icon class="rotate" [class.rotated]="!city.expanded"
                                                        (click)="city.expanded = !city.expanded">
                                                        expand_more</mat-icon>

                                                    <mat-checkbox [(ngModel)]="city.isChecked"
                                                        (mouseover)="hover(city, true)" (mouseout)="hover(city, false)"
                                                        (change)="city.onSelectionChanged()"
                                                        [indeterminate]="city.numberSelected > 0 && city.numberSelected != city.total">
                                                        {{city.name}} </mat-checkbox> <span class="quantity-indicator">(
                                                        {{city.numberSelected}}
                                                        / {{city.total}} )</span>
                                                    <div style="overflow:hidden;">

                                                        <div *ngIf="city.expanded" class="expand">
                                                            <ng-container *ngFor="let zip of city.children">
                                                                <div *ngIf="!hideUnselected || zip.isChecked"
                                                                    class="exoand">

                                                                    <mat-checkbox [(ngModel)]="zip.isChecked"
                                                                        (mouseover)="hover(zip, true)"
                                                                        (mouseout)="hover(zip, false)"
                                                                        (change)="zip.onSelectionChanged()"
                                                                        style="margin-left:25px;">
                                                                        {{zip.name}}
                                                                    </mat-checkbox>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div flex1 id="map"></div>
</div>