<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.68 120">
    <defs>
        <style>
            .cls-1 {
                fill: #3fa9f5;
            }
        </style>
    </defs>
    <circle class="cls-1" [class.running]="oneRunning" cx="30.83" cy="30.83" r="30.83" />
    <circle class="cls-1" [class.running]="twoRunning" cx="115.34" cy="33.17" r="30.83" />
    <circle class="cls-1"  [class.running]="threeRunning" cx="199.85" cy="33.17" r="30.83" />
</svg>