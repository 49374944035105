import { Injectable } from '@angular/core';
import { CustomTable } from '@cogent/shared/models/common/custom-table.model';
import { ApiService } from '@cogent/client/api';

@Injectable({ providedIn: 'root' })
export class CustomTableApi {

    constructor(private api: ApiService) { }

    getCustomTables(): Promise<CustomTable[]> {
        return this.api.getArrayDotNet(`UserDefinedTable/__CustomTable`, { orderby: 'data.name' }, () => new CustomTable());
    }

    getCustomTableNames(): Promise<CustomTable[]> {
        return this.api.getArrayDotNet(`UserDefinedTable/__CustomTable`, { orderby: 'data.name', select: 'id,name' }, () => new CustomTable());
    }

    getCustomTableByName(name: string): Promise<CustomTable> {
        return this.api.getSingleDotNet(`UserDefinedTable/__CustomTable`, { name_eq: name }, () => new CustomTable());
    }

    getCustomTableData(name: string) {
        return this.api.getArrayDotNet(`UserDefinedTable/${name}`);
    }



    saveCustomTable(customTable: CustomTable) {
        if (customTable.fields) {
            for (const field of customTable.fields) {
                field.isDate = field.dataType === 'date';
            }
        }
        return this.api.postSingleDotNet(`UserDefinedTable/__CustomTable`, customTable);
    }

    saveCustomTableRecord(customTable: string, record: any) {
        return this.api.postSingleDotNet(`UserDefinedTable/${customTable}`, record);
    }

    deleteCustomTable(id: string) {
        return this.api.deleteDotNet(`UserDefinedTable/__CustomTable/${id}`);
    }

    deleteCustomTableRecord(tableName: string, id: string) {
        return this.api.deleteDotNet(`UserDefinedTable/${tableName}/${id}`);
    }

    unDeleteCustomTableRecord(tableName: string, id: string) {
        return this.api.unDeleteDotNet(`UserDefinedTable/${tableName}/${id}`);
    }

    unDeleteCustomTable(id: string) {
        return this.api.unDeleteDotNet(`UserDefinedTable/__CustomTable/${id}`);
    }

    searchCustomTableObject(type: string, searchFor: string, searchForField: string, idField, additionalParams: string) {
        return this.api.getArrayDotNet(`UserDefinedTable/${type}?${searchForField}_like=${searchFor}&select=id,${searchForField},${idField}&${additionalParams}`);
    }

    getCustomTableObjectById(type: string, id: string) {
        return this.api.getSingleDotNet(`UserDefinedTable/${type}/${id}`);
    }
}