<div class="container fancy-container bottom-right-rounded">
    <div flex>
        <div>
            <h1 class="page-title">
                <span class="big">Add-On</span><br><span class="little">Request</span>
            </h1>
        </div>
    </div>

    <ng-container *ngIf="addOn">
        <div flex>
            <div>

                <h2 class="sub-heading" bottommargin20>Property</h2>

                <h3 style="margin-bottom: 0;">{{addOn.customerName}}</h3>
                <a routerLink="/maintenance-service-customer-view/{{addOn.maintenanceServiceCustomerPropertyId}}">
                    <div>{{addOn.propertyAddress1}}</div>
                    <div *ngIf="addOn.propertyAddress2">{{addOn.propertyAddress2}}</div>
                    <div>
                        {{addOn.propertyCity}}, {{addOn.propertyState}} {{addOn.propertyPostalCode}}
                    </div>
                </a>
                <app-sms-link-button [number]="addOn.customerPhoneNumber"
                    [remoteEntityId]="addOn.customerId"></app-sms-link-button>
            </div>
            <div>

                <h2 class="sub-heading" bottommargin20>Request</h2>
                <app-add-on-request-args-viewer [addOn]="addOn"></app-add-on-request-args-viewer>
            </div>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <mat-label>Comments</mat-label>
                <textarea rows="4" matInput [(ngModel)]="comments"></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline" style="max-width: 250px;">
                <mat-label>Cost</mat-label>
                <input type="number" matInput [(ngModel)]="cost">
            </mat-form-field>
            <div class="button-block">
                <button mat-button mat-raised-button (click)="sendQuote()" color="primary">Send Quote</button>&nbsp;
                <button mat-button mat-raised-button (click)="reject()">Reject Offer</button>&nbsp;
                <app-sms-link-button [buttonMode]="true" [remoteEntityId]="addOn.customerId"
                    label="Text {{addOn.customerPhoneNumber | formatPhoneNumber}}"
                    [number]="addOn.customerPhoneNumber"></app-sms-link-button>
            </div>
        </div>
    </ng-container>
</div>
<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>