import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Address } from '@upkeeplabs/models/cogent';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';

@Component({
    selector: 'app-plan-offerings',
    templateUrl: './plan-offerings.component.html',
    styleUrls: ['./plan-offerings.component.css']
})
export class PlanOfferingsComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() plans: PlanClient[];
    @Input() selectedPlan: PlanClient;
    @Output() selectedPlanChange: EventEmitter<PlanClient> = new EventEmitter();
    @Input() isMonthly: boolean;
    @Output() isMonthlyChange: EventEmitter<boolean> = new EventEmitter();
    @Input() titleBig = 'Plan';
    @Input() titleSmall = 'Selection';
    @Input() hideMonthlySelection: boolean;
    @Input() hideTitle = false;
    @Input() callToActionText = 'SELECT';
    @Input() callToActionIconClass: string;
    @Input() address: Address;
    @Input() coverageType: any;
    @Input() scaleSmall = false;
    @Input() newConstruction = false;
    @Input() dontSetBestValue = false;
    @Input() removeOneTimeServices = false;
    @Input() showNewPlanGrid = false;

    hasScrollers = false;

    id = UtilitiesService.newid();

    comparePlansVisible = false;
    highlightedPlan: PlanClient;
    planIndex = 0;
    closingDetails = false;
    swipeAdjustment: number;


    constructor(private sanitization: DomSanitizer,
        private planApi: PlanApiService) { }


    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.plans && changes.plans.currentValue) {
            this.setupAfterPlans();
        }

        if (changes.address && changes.address.currentValue) {
            this.doPlanLoad();
        }

        if (changes.coverageType && changes.coverageType.currentValue) {
            this.doPlanLoad();
        }
    }

    private setupAfterPlans() {
        setTimeout(() => {
            this.setScrollers();

            this.setBestvalueScroll();
            for (const plan of this.plans) {
                if (plan.description.endsWith('<br></div>')){
                    plan.description = plan.description.replace('<br></div>','</div>');
                }
                if (!plan.planColor) {
                    plan.planColor = '#ef7b00';
                }
            }
        }, 100);
    }

    doPlanLoad() {
        if (!this.address || !this.coverageType) {
            return;
        }

        this.planApi.getOfferedPlans(this.coverageType,
            this.address.dwellingType as any,
            this.address.postalCode,
            this.address.squareFeet,
            new Date().getFullYear() - this.address.yearBuilt,
            null,
            this.address.latitude,
            this.address.longitude, null, this.newConstruction).then(plans => {
                this.plans = plans.filter(i => i.showOnWeb);
                this.setupAfterPlans();
            });
    }



    ngAfterViewInit(): void {
        setTimeout(() => {
            UtilitiesService.swipeDetect(document, direction => {
                this.swipeAdjustment = 0;
                setTimeout(() => this.swipeAdjustment = 0, 200);
                if (direction === 'left' && this.planIndex < this.plans.length - 1) {
                    this.planIndex++;
                }

                if (direction === 'right' && this.planIndex > 0) {
                    this.planIndex--;
                }
            }, distX => {
                const maxThreshold = 200;
                if (distX < -maxThreshold) {
                    distX = -maxThreshold;
                }
                if (distX > maxThreshold) {
                    distX = maxThreshold;
                }
                this.swipeAdjustment = distX;
            });
        }, 500);
    }

    getCardFronBackground(plan) {

        if (!plan.planStyleObj) {
            plan.planStyleObj = JSON.parse(plan.planStyle);

        }

        return plan.planStyleObj.cardFrontBackgroundImage;
    }

    private setBestvalueScroll() {

        if (this.dontSetBestValue) {
            return;
        }
        const bestPlan = this.plans.sort((a, b) => a.price > b.price ? 1 : -1)[this.plans.length - 1];
        if (!bestPlan) {
            return;
        }

        setTimeout(() => {
            bestPlan.bestValue = true;

            const bestValuePlan = this.plans.find(i => i.bestValue);

            const bestValueIndex = this.plans.indexOf(bestValuePlan);
            const scrollLeft = bestValueIndex * 310;
            document.getElementById(this.id).scrollTo({ left: scrollLeft, top: 0, behavior: 'smooth' });
        });
    }

    scrollContainer;
    get isLeftScrolled() {
        if (!this.scrollContainer) {
            this.scrollContainer = document.getElementById(this.id);
        }


        return this.scrollContainer.scrollLeft === 0;
    }

    get isRightScrolled() {
        if (!this.scrollContainer) {
            this.scrollContainer = document.getElementById(this.id);
        }

        return this.scrollContainer.scrollLeft + this.scrollContainer.offsetWidth === this.scrollContainer.scrollWidth;
    }

    scrollLeft() {
        const el = document.getElementById(this.id);
        el.scrollTo({ left: el.scrollLeft - 200, top: 0, behavior: 'smooth' });
    }

    scrollRight() {
        const el = document.getElementById(this.id);
        el.scrollTo({ left: el.scrollLeft + 200, top: 0, behavior: 'smooth' });
    }

    updateScrollStatus() {

    }

    @HostListener('window:resize') onWindowResize() {
        this.setScrollers();
    }

    setScrollers() {
        const container = document.getElementById(this.id);
        if (container) {
            this.hasScrollers = container.clientWidth < container.scrollWidth;
        }


    }

    get mobileTransform() {
        if (!this.swipeAdjustment) {
            return null;
        }
        const style = `translateX(${-(273 * this.planIndex) + this.swipeAdjustment}px)`;
        return this.sanitization.bypassSecurityTrustStyle(style);
    }

    updateIsMonthly(value: boolean) {
        this.isMonthly = value;
        this.isMonthlyChange.emit(value);
    }

    getBackground(background: string) {
        return this.sanitization.bypassSecurityTrustStyle(background);
    }

    closeButtonClicked() {
        this.comparePlansVisible = false;
    }

    reset() {
        document.getElementById('plan-selection-container1').classList.remove('no-display');
    }

    showCompareClicked() {
        this.comparePlansVisible = true;
        this.highlightedPlan = null;
        if (this.plans.length < 4) {
            if (document.getElementById('plan-0')) {
                document.getElementById('plan-0').classList.add('collapse-left');
            }
            if (document.getElementById('plan-2')) {
                document.getElementById('plan-2').classList.add('collapse-right');
            }
            if (document.getElementById('plan-1')) {
                document.getElementById('plan-1').classList.add('collapse');
            }
        } else {
            if (document.getElementById('plan-0')) {
                document.getElementById('plan-0').classList.add('collapse-left');
            }
            if (document.getElementById('plan-1')) {
                document.getElementById('plan-1').classList.add('collapse-left');
            }
            if (document.getElementById('plan-2')) {
                document.getElementById('plan-2').classList.add('collapse-right');
            }
            if (document.getElementById('plan-3')) {
                document.getElementById('plan-3').classList.add('collapse-right');
            }
        }
    }

    selectedPlanChanged(selectedPlan: PlanClient) {
        this.selectedPlan = selectedPlan;
        this.selectedPlanChange.emit(selectedPlan);
    }

    getSampleContractUrl(item: any) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }

    orderPlan(plan: PlanClient, index: number) {
        setTimeout(() => {
            this.selectedPlan = plan;
            this.selectedPlanChange.emit(plan);
        }, 10);
    }

}
