import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PurchasingApiService } from '@cogent/client/shared/services/api/purchasing-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { TechnicianContact } from '@cogent/shared/models/authorizations/technician-contact.model';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { AppliancePartDetail, Entity, ModelPlateAnalysisResult, PartToOrder, PolicyServiceOffering, ProblemCause, ReplacementReason, WorkOrderItemLocation, WorkOrderItemProblem, WorkOrderItemType, WorkOrderLineSummary } from '@upkeeplabs/models/cogent';
import { AuthorizationRequestArgs } from '@cogent/shared/models/authorizations/authorization-request-args.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { IndexedDbWrapperService } from '@cogent/client/shared/services/indexed-db-wrapper.service';
import { AppliancePartSearchComponent } from '@cogent/client/shared/components/autho/appliance-part-search/appliance-part-search/appliance-part-search.component';
import { RichTextAndSnippetComponent } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet/rich-text-and-snippet.component';
import { AuthorizationPhotosComponent } from '../../parts/authorization-photos/authorization-photos.component';
import { ApplianceApiModel, ApplianceApiPart, AppliancePartCategory } from '../order-parts/order-parts.component';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { AuthorizationRequestResult } from '@cogent/shared/models/authorizations/authorization-request-result.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";

@Component({
    selector: 'app-appliance-autho',
    templateUrl: './appliance-autho.component.html',
    styleUrls: ['./appliance-autho.component.css']
})
export class ApplianceAuthoComponent implements OnInit, AfterViewInit {

    id: string;
    workOrderSummary: WorkOrderSummaryClient;
    selectedIndex = 0;
    partOrModelNumber: string;
    foundPart: any;
    foundPart2: any;
    noMatch = false;
    firstNoMatch = false;
    workOrderLine: WorkOrderLineSummary;
    selectedPartCategory: AppliancePartCategory;

    diagramDetails: any[];
    loadingPartDetail = false;
    showWorkOrderSummary = false;
    partDetail: AppliancePartDetail[];
    mentionableFilter = '';
    selectedNoteIndex = 0;

    mentionables: Entity[] = [];
    imageSelectedIndex = 0;
    useEncompass = true;
    selectedModel: ApplianceApiModel;
    normalWearAndTear: string;
    mfgWarranty: string;
    repairReplace: string;
    hotspot: any;
    showAllParts = false;
    viewDiagram: boolean;
    diagramSizeMode: string = 'actual';
    quantities = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10
    ];

    allPartsFilter: string;
    problems: WorkOrderItemProblem[];
    itemTypes: WorkOrderItemType[];
    itemLocations: WorkOrderItemLocation[];
    filteredWorkOrderProblems: WorkOrderItemProblem[];
    causes: ProblemCause[];
    coverageLanguage: PolicyServiceOffering;
    selectedPart: any;
    authorizing = false;
    authorizationResult: AuthorizationRequestResult;
    authoComplete = false;
    replacementReasons: ReplacementReason[];
    minEstimatedRepairDate = new Date();
    maxEstimatedRepairDate: Date;
    showModelPlate = true;
    analyzingModelPlate = false;
    showTakePictureOfFront = false;
    showTakePictureOfControls = false;
    analysisResult: ModelPlateAnalysisResult;
    excludedTerms: string[];
    @ViewChild('firstApplianceSearch') firstApplianceSearch: AppliancePartSearchComponent;
    @ViewChild('modelPlatePhotos') modelPlatePhotos: AuthorizationPhotosComponent;
    @ViewChild('frontPhotos') frontPhotos: AuthorizationPhotosComponent;
    @ViewChild('unitControlsPhotos') unitControlsPhotos: AuthorizationPhotosComponent;

    selectModelNumber = false;

    ITEM_SELECTION_STEP = 0;
    INITIAL_PICTURES_STEP = 1;
    FAILED_PART_STEP = 2;
    PROBLEM_CAUSE_STEP = 3;
    UNIT_LOCATION_STEP = 4;
    UNIT_TYPE_STEP = 5;
    PRE_EXISTING_CONDITIONS_STEP = 6;
    UNDER_WARRANTY_STEP = 7;
    REPAIR_REPLACE_STEP = 8;
    ADDITIONAL_PARTS_STEP = 9;
    REVIEW_STEP = 10;
    COMPLETE_STEP = 11;
    addingAdditionalPart = false;
    movingForward = false;
    companyProvidesParts = false;
    firstEffectiveDate: Date;
    partFilter = '';
    dragTargetActive = false;
    @ViewChild('richText') richText: RichTextAndSnippetComponent;
    allPartsVisible = false;


    constructor(private activatedRoute: ActivatedRoute,
        private purchasingApi: PurchasingApiService,
        private policyApi: PolicyApiService,
        private entityApi: EntityApiService,
        private missionService: MissionService,
        private dialogSerivce: DialogsService,
        private indexedDbService: IndexedDbWrapperService,
        private settingsApi: SettingsApiService,
        private serviceApi: ServiceApiService) { }

    async ngOnInit() {
        this.minEstimatedRepairDate = UtilitiesService.dayBegin(this.minEstimatedRepairDate);
        const baseDate = new Date(this.minEstimatedRepairDate);
        baseDate.setDate(baseDate.getDate() + 90);
        this.maxEstimatedRepairDate = baseDate;


        this.activatedRoute.params.subscribe(param => {
            if (param.id) {
                this.id = param.id;

                this.serviceApi.getWorkOrderSummary(this.id).then(wos => {
                    this.workOrderSummary = wos;
                    this.serviceApi.getAssignedEmployees(wos.id).then(e => this.mentionables = e);

                    if (this.workOrderSummary.lines.length === 1) {
                        this.selectLine(this.workOrderSummary.lines[0]);
                    }

                    this.activatedRoute.queryParams.subscribe(qparams => {
                        if (qparams.lineId) {
                            const selectedLine = this.workOrderSummary.lines.find(i => i.id === qparams.lineId);
                            if (selectedLine) {
                                this.selectLine(selectedLine);
                            }
                        }
                    });

                    for (const line of this.workOrderSummary.lines) {
                        line.itemUrl = `${ApiService.endPointNode}scheduled-maintenance/item-photo/${line.itemId}`;
                    }
                    this.serviceApi.getAnonymousFirstEffectiveDate(this.workOrderSummary.policyId).then(firstEffectiveDate => {
                        this.firstEffectiveDate = firstEffectiveDate;
                    });
                });
            }
        });
        this.replacementReasons = await this.serviceApi.getReplacementReasons();
        this.user = await this.entityApi.getLoggedInUser();
    }

    ngAfterViewInit() {
        this.setupPasteHandler();
    }

    user: Entity;
    allAuthoArgs: any = {};

    setCoveredByMfgWarranty(value) {
        this.authoArgs.coveredByMfgWarranty = value;
    }

    showTag() {

        for (const entity of this.mentionables) {
            if (this.authoArgs.additionalNotes?.indexOf(`data-entity-id="${entity.id}"`) > -1) {
                entity.selected = true;
            } else {
                entity.selected = false;
            }
        }
        this.selectedNoteIndex = 1;
        this.mentionableFilter = '';
    }

    get filteredMentionables() {
        if (!this.mentionableFilter) {
            return this.mentionables;
        }

        return this.mentionables.filter(i => i.name?.toLowerCase().indexOf(this.mentionableFilter.toLocaleLowerCase()) > -1);
    }

    get selectedModelAllParts() {
        if (!this.selectedModel) {
            return [];
        }

        if (!this.allPartsFilter) {
            return this.selectedModel.allParts;
        }

        const lower = this.allPartsFilter.toLowerCase();

        return this.selectedModel.allParts.filter(i => i.partNumber?.toLowerCase().indexOf(lower) > -1
            || i.partDescription?.toLowerCase().indexOf(lower) > -1);
    }


    applyTags() {
        let noteText = this.authoArgs.additionalNotes;
        if (!noteText) {
            noteText = '';
        }
        for (const entity of this.mentionables.filter(i => i.selected)) {
            if (noteText.indexOf(`data-entity-id="${entity.id}"`) === -1) {
                noteText += ` <a data-entity-id="${entity.id}" class="mentioned-href">@${entity.name}</a>`;
            }
        }
        for (const entity of this.mentionables.filter(i => !i.selected)) {
            noteText = UtilitiesService.replaceAll(noteText, `<a data-entity-id="${entity.id}" class="mentioned-href">@${entity.name}</a>`, '');
        }
        this.selectedNoteIndex = 0;
        setTimeout(() => {
            this.authoArgs.additionalNotes = noteText;

        }, 500);
    }

    showAllParts1() {
        this.selectedPartCategory = null;
        this.allPartsVisible = true;
    }

    get authoArgs(): AuthorizationRequestArgs {
        if (!this.workOrderLine || !this.user) {
            const args = new AuthorizationRequestArgs();

            args.photos = [];
            args.authorizationSource = 'App';
            args.authorizationLines = [];
            args.problemIds = [];
            args.partsToOrder = [];
            args.technicianContact = new TechnicianContact();

            return args;
        }
        if (!this.allAuthoArgs[this.workOrderLine.id]) {
            const args = new AuthorizationRequestArgs();

            args.photos = [];
            args.authorizationSource = 'App';
            args.authorizationLines = [];
            args.problemIds = [];
            args.technicianContact = new TechnicianContact();

            if (localStorage.getItem('technician-contact-information')) {
                try {
                    args.technicianContact = JSON.parse(localStorage.getItem('technician-contact-information'));
                } catch (e) {
                    args.technicianContact = new TechnicianContact();
                }
            } else {
                args.technicianContact = new TechnicianContact();
                args.technicianContact.name = this.user.name;
                args.technicianContact.email = this.user.email;
                args.technicianContact.smsNumber = this.user.mobileNumberNumber;
                args.technicianContact.phoneNumber = this.user.mobileNumberNumber;
            }
            if (this.user.defaultLaborRate) {
                args.laborHours = 0;

            }

            this.companyProvidesParts = this.user.companyProvidesParts;

            if (this.companyProvidesParts) {
                args.laborHours = 1;
            }

            this.settingsApi.getTrade(this.workOrderSummary.tradeId).then(trade => {
                args.laborRate = trade.laborRate;
                this.entityApi.getContractorTrade(this.workOrderSummary.contractorId, this.workOrderSummary.tradeId).then(ct => {
                    if (ct.laborRate) {
                        args.laborRate = ct.laborRate;
                    }
                });
            });

            this.allAuthoArgs[this.workOrderLine.id] = args;
        }

        const args: AuthorizationRequestArgs = this.allAuthoArgs[this.workOrderLine.id];
        if (!args.partsToOrder) {
            args.partsToOrder = [];
        }

        return args;
    }

    private setupPasteHandler() {

        if (!document.getElementById('outer-content')) {
            setTimeout(() => this.setupPasteHandler(), 100);
            return;
        }

        document.getElementById('outer-content').onpaste = (event: any) => {
            var items = (event.clipboardData || event.originalEvent.clipboardData).items;

            for (const index in items) {
                var item = items[index];
                if (item.kind === 'file') {
                    var blob = item.getAsFile();
                    var reader = new FileReader();
                    const loadIt = (base64, fileName) => {
                        if (this.showModelPlate) {
                            this.modelPlatePhotos.loadIt(base64, fileName);
                        }
                        if (this.showTakePictureOfFront) {
                            this.frontPhotos.loadIt(base64, fileName);
                        }
                        if (this.showTakePictureOfControls) {
                            this.unitControlsPhotos.loadIt(base64, fileName);
                        }
                    };
                    reader.onload = function (event) {
                        const base64 = event.target.result;
                        let fileName = '';
                        if (base64 && base64.toString().indexOf('image/png')) {
                            fileName = 'screen-shot.png';
                        }
                        if (fileName) {
                            loadIt(base64, fileName);
                        }
                    }; // data url!
                    reader.readAsDataURL(blob);
                }
            }
        };
    }

    imageDrop(e, authoPhotos: AuthorizationPhotosComponent) {
        e.stopPropagation();
        e.preventDefault();

        const dt = e.dataTransfer;
        const files = dt.files;
        authoPhotos.handleFiles(files);
        // this.handleFiles(files);
        this.dragTargetActive = false;
    }

    imageDragEnter(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    imageDragover(e) {
        e.stopPropagation();
        e.preventDefault();
        this.dragTargetActive = true;
    }

    imageDragleave(e) {
        e.stopPropagation();
        e.preventDefault();
        this.dragTargetActive = false;
    }

    get totalRepair() {
        if (!this.authoArgs.partsToOrder) {
            return 0;
        }

        return this.authoArgs.partsToOrder.filter(i => !i.companyProvides && !i.notCovered).map(i => i.subtotal).reduce((a, b) => a + b, 0) + this.laborTotal + this.tripFeeAllowance;
    }

    get filteredDiagramDetails() {
        const filter = this.partFilter.toLowerCase();
        return this.diagramDetails.filter(i => i.productNumber?.toLowerCase().indexOf(filter) > -1 || i.description?.toLowerCase().indexOf(filter) > -1)
    }

    get laborTotal() {
        if (!this.authoArgs.laborRate) {
            return this.authoArgs.laborTotal;
        }

        return this.authoArgs.calculatedLabor;
    }

    setFirstNotMatch(value: boolean) {
        if (value) {
            this.firstNoMatch = true;
        }
    }

    get tripFeeAllowance() {
        if (!this.workOrderSummary || !this.workOrderSummary.tripFeeAllowance) {
            return 0;
        }

        return this.workOrderSummary.tripFeeAllowance / this.workOrderSummary.lines.length;
    }

    setModelNumberFromLine(line: string) {
        this.authoArgs.modelNumber = line;
        this.analysisResult.modelNumber = line;
        if (!this.pictureOfUnitFront) {
            this.showTakePictureOfFront = true;
        }
        this.selectModelNumber = false;
    }

    confirmModelNumber() {
        if (!this.pictureOfUnitFront) {
            this.showTakePictureOfFront = true;
        }
        this.selectModelNumber = false;
    }

    rejectModelNumber() {
        this.analysisResult.calculatedModelNumber = this.analysisResult.modelNumber;
        this.analysisResult.modelNumber = null
    }

    selectLineAndAdvance() {
        this.selectLine(this.workOrderLine);
    }

    get probableModelNumbers() {
        if (!this.analysisResult) {
            return null;
        }

        const letters = /[a-zA-Z]/;
        const numbers = /[0-9]/
        return this.analysisResult.pages[0].lines.filter(i => letters.test(i.text) && numbers.test(i.text)).map(i => i.text);
    }

    modelPlateAdded(attachments: WorkOrderAttachmentModel[]) {


        this.showModelPlate = false;
        this.analyzingModelPlate = true;
        const attachment = attachments.find(i => i.name.startsWith('modelplate_'))
        this.serviceApi.analyzeModelPlate(attachment).then((result: ModelPlateAnalysisResult) => {
            this.analyzingModelPlate = false;
            if (result.modelNumber) {
                this.authoArgs.modelNumber = result.modelNumber;
            }

            if (result.serialNumber) {
                this.authoArgs.serialNumber = result.serialNumber;
            }

            this.analysisResult = result;
            this.selectModelNumber = true;
        }, () => {
            this.analyzingModelPlate = false;
            if (!this.pictureOfUnitFront) {
                this.showTakePictureOfFront = true;
            }
        });
    }

    goToModelPlate() {
        this.showModelPlate = true;
        this.showTakePictureOfControls = false;
        this.showTakePictureOfFront = false;
    }

    goToPictureOfFront() {
        this.showModelPlate = false;
        this.showTakePictureOfControls = false;
        this.showTakePictureOfFront = true;
    }

    goToPictureOfControls() {
        this.showModelPlate = false;
        this.showTakePictureOfControls = true;
        this.showTakePictureOfFront = false;
    }

    resetModelPlate() {
        this.dialogSerivce.confirm('Confirm', 'Are you sure you want to reset').subscribe(results => {
            if (results) {
                this.authoArgs.photos.splice(this.authoArgs.photos.indexOf(this.pictureOfModelPlate), 1);
                this.showModelPlate = true;
                this.showTakePictureOfFront = false;
                this.showTakePictureOfControls = false;
                this.selectModelNumber = false;
            }
        });
    }

    resetFrontOfUnit() {
        this.dialogSerivce.confirm('Confirm', 'Are you sure you want to reset').subscribe(results => {
            if (results) {
                this.authoArgs.photos.splice(this.authoArgs.photos.indexOf(this.pictureOfUnitFront), 1);
                this.showModelPlate = false;
                this.showTakePictureOfFront = true;
                this.showTakePictureOfControls = false;
            }
        });
    }

    resetUnitControls() {
        this.dialogSerivce.confirm('Confirm', 'Are you sure you want to reset').subscribe(results => {
            if (results) {
                this.authoArgs.photos.splice(this.authoArgs.photos.indexOf(this.pictureOfUnitControls), 1);
                this.showModelPlate = false;
                this.showTakePictureOfFront = false;
                this.showTakePictureOfControls = true;
            }
        });
    }

    findModel() {
        this.firstApplianceSearch.doAutomaticSearch(this.analysisResult.modelNumber?.trim());
    }

    unitPhotoAdded(picture) {
        this.showTakePictureOfFront = false;
        if (!this.pictureOfUnitControls) {
            this.showTakePictureOfControls = true;
        }
    }

    get showPreexisting() {
        if (!this.workOrderSummary) {
            return false;
        }
        if (this.workOrderSummary.coverageType !== 'RealEstate' && this.workOrderSummary.coverageType !== 'Sellers') {
            return false;
        }
        if (this.workOrderSummary.effectiveDate == null) {
            return true;
        }

        const dt = new Date(this.workOrderSummary.effectiveDate);
        dt.setDate(dt.getDate() + 30);

        return dt > new Date();
    }

    controlAdding = false;
    controlsPhotoAdded(picture) {
        this.showTakePictureOfControls = false;

        this.controlAdding = true;
        setTimeout(() => {
            this.next();
            this.controlAdding = false;
        }, 1000);
    }

    get pictureOfModelPlate() {
        return this.authoArgs?.photos.find(i => i.name.indexOf('modelplate') === 0);
    }

    get pictureOfUnitFront() {
        return this.authoArgs?.photos.find(i => i.name.indexOf('unitfront') === 0);

    }

    get pictureOfUnitControls() {
        return this.authoArgs?.photos.find(i => i.name.indexOf('unitcontrols') === 0);
    }

    get canAuthorize() {
        if (this.authorizing) {
            return false;
        }

        if (!this.authoArgs.technicianContact.name) {
            return false;
        }

        if (!UtilitiesService.validatePhoneNumber(this.authoArgs.technicianContact.phoneNumber)) {
            return false;
        }

        return true;
    }

    get hasPicturesTaken() {
        return this.pictureOfModelPlate && this.pictureOfUnitControls && this.pictureOfUnitFront && !this.controlAdding;
    }

    back() {
        this.movingForward = false;
        this.selectedIndex--;

        if (this.selectedIndex === this.PROBLEM_CAUSE_STEP && this.causes.length === 0) {
            this.back();
        }

        if (this.selectedIndex === this.UNIT_LOCATION_STEP && this.itemLocations.length === 0) {
            this.back();
        }
        if (this.selectedIndex === this.UNIT_TYPE_STEP && this.itemTypes.length === 0) {
            this.back();
        }
        if (this.selectedIndex === this.ADDITIONAL_PARTS_STEP && this.authoArgs.recommendation === 'Replace') {
            this.back();
        }
        if (this.selectedIndex === this.PRE_EXISTING_CONDITIONS_STEP && !this.showPreexisting) {
            this.back();
        }
    }

    async next() {
        this.movingForward = true;
        this.selectedIndex++;
        if (this.selectedIndex === this.PROBLEM_CAUSE_STEP && this.causes.length === 0) {
            this.next();
        }
        if (this.selectedIndex === this.UNIT_LOCATION_STEP && this.itemLocations.length === 0) {
            this.next();
        }
        if (this.selectedIndex === this.UNIT_TYPE_STEP && this.itemTypes.length === 0) {
            this.next();
        }
        if (this.selectedIndex === this.ADDITIONAL_PARTS_STEP && this.authoArgs.recommendation === 'Replace') {
            this.next();
        }
        if (this.selectedIndex === this.PRE_EXISTING_CONDITIONS_STEP && !this.showPreexisting) {
            this.next();
        }
        if (this.selectedIndex > this.FAILED_PART_STEP) {
            this.selectedPart = null;
            this.selectedPartCategory = null;
        }
        await this.indexedDbService.put({
            args: this.authoArgs,
            workOrderLine: this.workOrderLine,
            id: this.workOrderLine.id
        });
    }

    removePart(part: PartToOrder) {
        const index = this.authoArgs.partsToOrder.indexOf(part);
        this.authoArgs.partsToOrder.splice(index, 1);

        this.missionService.showUndo(new UndoArguments(() => {
            this.authoArgs.partsToOrder.splice(index, 0, part);
        }, 'Part Removed'));
    }

    get notFirstCause() {
        if (!this.causes || this.causes.length == 0) {
            return false;
        }
        return this.authoArgs.causeId && this.authoArgs.causeId !== this.causes[0].id;
    }

    async selectLine(line: WorkOrderLineSummary) {
        this.workOrderLine = line;

        this.serviceApi.getWorkOrderItemTypes(line.itemId).then(results => {
            this.itemTypes = results;
        });
        this.serviceApi.getWorkOrderItemLocations(line.itemId).then(results => {
            this.itemLocations = results;
        });
        this.serviceApi.getWorkOrderItemCauses(line.itemId).then(results => {
            this.causes = results as any;
        });
        this.policyApi.getWorkOrderItemDetailByWorkOrderLine(line.id).then(contractLanguage => {
            this.coverageLanguage = contractLanguage;
        });
        this.movingForward = true;
        this.selectedIndex = this.INITIAL_PICTURES_STEP;
        this.serviceApi.getApplianceCoveredTermsFromWorkOrderLine(line.id).then(terms => {
            this.excludedTerms = terms.filter(i => !i.isCovered).map(i => i.term.toLowerCase());
        });

        const item = await this.indexedDbService.get(line.id);
        if (item) {
            const args = item.args;
            if (args) {
                this.allAuthoArgs[line.id] = args;
                if (!this.authoArgs.partsToOrder) {
                    this.authoArgs.partsToOrder = [];
                }
            }
        }
    }

    toggleSelectedPart(part: ApplianceApiPart) {
        if (!part.quantity) {
            part.quantity = 1;
        }
        part.selected = !part.selected;
    }

    cancelEvent(evt: MouseEvent) {
        evt.cancelBubble = true;
    }


    async spotClicked2(spot, evt: MouseEvent, clickedPart = null) {
        this.partFilter = '';
        if (evt) {
            evt.cancelBubble = true;
            evt.preventDefault();
        }
        if (spot) {
            clickedPart = this.diagramDetails.find(i => i.tag === spot.Tag);
        }

        if (clickedPart) {
            this.loadingPartDetail = true;
            this.selectedPartCategory = null;
            if (clickedPart.partNumber && !clickedPart.productNumber) {
                clickedPart.productNumber = clickedPart.partNumber;
            }


            const specAndDetails = await this.purchasingApi.getAppliancePartDetails(clickedPart.productNumber, this.workOrderLine.propertyPostalCode);


            this.partDetail = specAndDetails.vendorDetails.filter(i => i.totalPrice > 0);
            this.partDetail.sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1)
            const detail = specAndDetails.specDetails;
            const appliancePart = new PartToOrder();
            appliancePart.id = UtilitiesService.newid();
            appliancePart.description = detail.description;
            appliancePart.imageUrl = detail.imageUrls ? detail.imageUrls[0] : null;
            appliancePart.partNumber = detail.name;
            appliancePart.quantity = detail.quantity;
            appliancePart.cost = detail.partnerPrice;
            appliancePart.companyPrice = detail.partnerPrice;
            appliancePart.manufacturer = detail.manufacturer;
            appliancePart.manufacturerCode = detail.manufacturerCode;
            appliancePart.workOrderLineId = this.workOrderLine.id;
            appliancePart.images = specAndDetails.specDetails.images;
            appliancePart.companyProvides = this.companyProvidesParts;

            const encompassDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Encompass');
            const marconeDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Marcone');
            const reliableDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Reliable');

            appliancePart.encompassBasePN = encompassDetail?.encompassBasePN;
            appliancePart.encompassMfgCode = encompassDetail?.manufacturer;
            appliancePart.marconeMake = marconeDetail?.manufacturer;
            appliancePart.reliableMfgCode = reliableDetail?.manufacturer;
            appliancePart.reliablePartId = reliableDetail?.reliablePartId;
            appliancePart.quantity = 1;

            this.authoArgs.partsToOrder.push(appliancePart);
            this.validatePartToOrder(appliancePart);

            this.loadingPartDetail = false;
            this.imageSelectedIndex = 0;

            detail.partnerPrice = this.partDetail[0].totalPrice;
            this.firstNoMatch = true;
            this.selectedPart = null;
            this.addingAdditionalPart = false;
        }
    }

    private validatePartToOrder(part: PartToOrder) {
        if (!part.description) {
            return;
        }

        const descriptionTerms = part.description.split(' ').map(i => i.toLowerCase());
        for (const descriptionTerm of descriptionTerms) {
            if (this.excludedTerms.indexOf(descriptionTerm) > -1) {
                part.notCovered = true;
                part.notCoveredTerm = descriptionTerm;
            }
        }
        if (part.notCovered) {
            this.dialogSerivce.alert('Not Covered', `It appears as if part number ${part.partNumber} - ${part.description} is not covered. <br>Term: ${part.notCoveredTerm}`);
        }
    }

    async spotClicked(spot, evt: MouseEvent) {
        evt.cancelBubble = true;
        evt.preventDefault();
        const clickedPart = this.diagramDetails.find(i => i.tag === spot.Tag);

        if (clickedPart) {
            this.loadingPartDetail = true;
            this.authoArgs.partsToOrder = [];
            this.selectedPartCategory = null;


            const specAndDetails = await this.purchasingApi.getAppliancePartDetails(clickedPart.productNumber, this.workOrderLine.propertyPostalCode);

            this.partDetail = specAndDetails.vendorDetails.filter(i => i.totalPrice > 0);
            this.partDetail.sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1)
            const detail = specAndDetails.specDetails;

            const appliancePart = new PartToOrder();
            appliancePart.id = UtilitiesService.newid();
            appliancePart.description = detail.description;
            appliancePart.imageUrl = detail.imageUrls ? detail.imageUrls[0] : null;
            appliancePart.partNumber = detail.name;
            appliancePart.quantity = detail.quantity;
            appliancePart.cost = detail.partnerPrice;
            appliancePart.companyPrice = detail.partnerPrice;
            appliancePart.manufacturer = detail.manufacturer;
            appliancePart.manufacturerCode = detail.manufacturerCode;
            appliancePart.workOrderLineId = this.workOrderLine.id;
            appliancePart.images = specAndDetails.specDetails.images;
            appliancePart.companyProvides = this.companyProvidesParts;

            const encompassDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Encompass');
            const marconeDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Marcone');
            const reliableDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Reliable');

            appliancePart.encompassBasePN = encompassDetail?.encompassBasePN;
            appliancePart.encompassMfgCode = encompassDetail?.manufacturer;
            appliancePart.marconeMake = marconeDetail?.manufacturer;
            appliancePart.reliableMfgCode = reliableDetail?.manufacturer;
            appliancePart.reliablePartId = reliableDetail?.reliablePartId;
            appliancePart.quantity = 1;

            this.authoArgs.partsToOrder.push(appliancePart);
            this.validatePartToOrder(appliancePart);

            this.loadingPartDetail = false;
            this.imageSelectedIndex = 0;

            this.firstNoMatch = true;
        }
    }

    changeModel() {
        this.selectedModel = null;
        this.selectedPartCategory = null;
        this.currentVariation = null
        this.showAllParts = false;
    }

    addSelectedPart() {
        var detail = this.selectedPart.specDetails;

        const appliancePart = new PartToOrder();
        appliancePart.id = UtilitiesService.newid();
        appliancePart.description = detail.description;
        appliancePart.imageUrl = detail.imageUrls ? detail.imageUrls[0] : null;
        appliancePart.partNumber = detail.name;
        appliancePart.quantity = detail.quantity;
        appliancePart.cost = detail.partnerPrice;
        appliancePart.companyPrice = detail.partnerPrice;
        appliancePart.manufacturer = detail.manufacturer;
        appliancePart.manufacturerCode = detail.manufacturerCode;
        appliancePart.workOrderLineId = this.workOrderLine.id;
        appliancePart.images = this.selectedPart.specDetails.images;
        appliancePart.companyProvides = this.companyProvidesParts;

        const encompassDetail = this.selectedPart.vendorDetails.find(i => i.vendor === 'Encompass');
        const marconeDetail = this.selectedPart.vendorDetails.find(i => i.vendor === 'Marcone');
        const reliableDetail = this.selectedPart.vendorDetails.find(i => i.vendor === 'Reliable');

        appliancePart.encompassBasePN = encompassDetail?.encompassBasePN;
        appliancePart.encompassMfgCode = encompassDetail?.manufacturer;
        appliancePart.marconeMake = marconeDetail?.manufacturer;
        appliancePart.reliableMfgCode = reliableDetail?.manufacturer;
        appliancePart.reliablePartId = reliableDetail?.reliablePartId;
        appliancePart.quantity = 1;
        this.authoArgs.partsToOrder.push(appliancePart);
        this.validatePartToOrder(appliancePart);
        this.selectedPart = null;
        this.addingAdditionalPart = false;
    }

    showCategory(partCategory: any) {
        this.allPartsVisible = false;
        if (partCategory === this.selectedPartCategory) {
            this.selectedPartCategory = null;
            return;
        }
        this.loadingPartDetail = true;

        this.diagramDetails = null;

        this.selectedPartCategory = partCategory;

        if (this.useEncompass) {
            this.purchasingApi.getModelDetailExplodedView(partCategory.mfgCode, partCategory.explodedViewId, partCategory.diagramId, partCategory.modelId, this.currentVariation).then(diagramDetails => {
                this.diagramDetails = diagramDetails.data.parts.filter(i => i.basePN);
                this.loadingPartDetail = false;
                this.hotspot = diagramDetails.data.assembly.HotSpots;
            });
        } else {
            const items = partCategory.products.map(i => { return { productNumber: i.productNumber, manufacturerCode: i.manufacturerCode }; });
            this.purchasingApi.getDiagramDetail(items).then(diagramDetails => {
                this.diagramDetails = diagramDetails;
                this.loadingPartDetail = false;
            });
        }
    }

    async selectedItemChange2(partModelOrAdd: any) {
        this.diagramDetails = null;
        if (partModelOrAdd.type === 'product') {
            this.loadingPartDetail = true;
            this.selectedPart = null;


            const specAndDetails = await this.purchasingApi.getAppliancePartDetails(partModelOrAdd.name, this.workOrderLine.propertyPostalCode);
            this.partDetail = specAndDetails.vendorDetails.filter(i => i.totalPrice > 0);
            this.partDetail.sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1)


            this.loadingPartDetail = false;
            this.selectedPart = specAndDetails;
            this.imageSelectedIndex = 0;

            this.selectedPart.partnerPrice = this.partDetail[0].totalPrice;
            this.firstNoMatch = true;
        } else if (partModelOrAdd.type === 'model') {
            this.loadingPartDetail = true;
            this.selectedModel = null;
            this.currentVariation = null;

            if (this.useEncompass) {
                this.purchasingApi.getModelAssemblies(partModelOrAdd.name, partModelOrAdd.manufacturer).then(detail => {
                    if (detail.isAlternates) {
                        this.loadingPartDetail = false;
                        this.showVariations = true;
                        this.variations = detail.variations;
                        this.lastPartName = partModelOrAdd.name;
                        this.lastPartMfg = partModelOrAdd.manufacturer;

                    } else {
                        this.selectedModel = detail;
                        this.loadingPartDetail = false;
                        if (this.selectedModel.diagrams.length === 0) {
                            this.showAllParts1();
                        }
                    }
                });
            } else {
                this.purchasingApi.getReliableModelDetail(partModelOrAdd.name).then(detail => {
                    this.loadingPartDetail = false;
                    this.selectedModel = detail.result.rpmodel;
                });
            }
            this.firstNoMatch = true;
        }
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        let hash = window.location.hash;
        if (!hash) {
            this.selectedIndex = this.ITEM_SELECTION_STEP;
            return;
        }

        hash = hash.replace('#', '');
        const stepNumber = parseInt(hash, 10);
        if (!isNaN(stepNumber)) { this.selectedIndex = stepNumber; }
    }

    async onStepChange() {
        window.location.hash = this.selectedIndex.toString();
    }

    showVariations = false;
    variations: string[];
    lastPartName: string;
    lastPartMfg: string;

    async selectedItemChange(partModelOrAdd: any) {
        this.diagramDetails = null;
        if (partModelOrAdd.type === 'product') {
            this.loadingPartDetail = true;
            this.authoArgs.partsToOrder = [];


            const specAndDetails = await this.purchasingApi.getAppliancePartDetails(partModelOrAdd.name, this.workOrderLine.propertyPostalCode);
            this.partDetail = specAndDetails.vendorDetails.filter(i => i.totalPrice > 0);
            this.partDetail.sort((a, b) => a.totalPrice > b.totalPrice ? 1 : -1)
            const detail = specAndDetails.specDetails;

            this.loadingPartDetail = false;

            const appliancePart = new PartToOrder();
            appliancePart.id = UtilitiesService.newid();
            appliancePart.description = detail.description;
            appliancePart.imageUrl = detail.imageUrls ? detail.imageUrls[0] : null;
            appliancePart.partNumber = detail.name;
            appliancePart.quantity = detail.quantity;
            appliancePart.cost = detail.partnerPrice;
            appliancePart.companyPrice = detail.partnerPrice;
            appliancePart.manufacturer = detail.manufacturer;
            appliancePart.manufacturerCode = detail.manufacturerCode;
            appliancePart.workOrderLineId = this.workOrderLine.id;
            appliancePart.images = specAndDetails.specDetails.images;
            appliancePart.companyProvides = this.companyProvidesParts;

            const encompassDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Encompass');
            const marconeDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Marcone');
            const reliableDetail = specAndDetails.vendorDetails.find(i => i.vendor === 'Reliable');

            appliancePart.encompassBasePN = encompassDetail?.encompassBasePN;
            appliancePart.encompassMfgCode = encompassDetail?.manufacturer;
            appliancePart.marconeMake = marconeDetail?.manufacturer;
            appliancePart.reliableMfgCode = reliableDetail?.manufacturer;
            appliancePart.reliablePartId = reliableDetail?.reliablePartId;
            appliancePart.quantity = 1;

            this.authoArgs.partsToOrder.push(appliancePart);

            this.validatePartToOrder(appliancePart);
            this.imageSelectedIndex = 0;

            this.firstNoMatch = true;
        } else if (partModelOrAdd.type === 'model') {
            this.loadingPartDetail = true;
            this.selectedModel = null;
            this.currentVariation = null;

            if (this.useEncompass) {
                this.purchasingApi.getModelAssemblies(partModelOrAdd.name, partModelOrAdd.manufacturer).then(detail => {
                    if (detail.isAlternates) {
                        this.loadingPartDetail = false;
                        this.showVariations = true;
                        this.variations = detail.variations;
                        this.lastPartName = partModelOrAdd.name;
                        this.lastPartMfg = partModelOrAdd.manufacturer;

                    } else {
                        this.selectedModel = detail;
                        this.loadingPartDetail = false;
                        if (this.selectedModel.diagrams.length === 0) {
                            this.showAllParts1();
                        }
                    }
                });
            } else {
                this.purchasingApi.getReliableModelDetail(partModelOrAdd.name).then(detail => {
                    this.loadingPartDetail = false;
                    this.selectedModel = detail.result.rpmodel;
                });
            }
            this.firstNoMatch = true;
        }
    }

    currentVariation: string;
    getModelDetailByVariations(variation: string) {
        this.loadingPartDetail = true;
        this.selectedModel = null;
        this.showVariations = false;
        this.currentVariation = variation;
        this.purchasingApi.getModelAssemblies(this.lastPartName, this.lastPartMfg, variation).then(detail => {
            this.selectedModel = detail;
            this.loadingPartDetail = false;
            if (this.selectedModel.diagrams.length === 0) {
                this.showAllParts1();
            }
        });
    }

    get orderingParts() {
        if (this.authoArgs?.partsToOrder?.find(i => i.companyProvides)) {
            return true;
        }
    }

    lessHours() {
        if (this.authoArgs.laborHours > .5) {
            this.authoArgs.laborHours -= .5;
        } else {
            this.authoArgs.laborHours = 0;
        }
    }

    moreHours() {
        this.authoArgs.laborHours += .5;
    }

    async save() {
        if (this.problems) {
            this.authoArgs.problemIds = this.problems.filter(i => i.selected).map(i => i.id);
        }

        await this.indexedDbService.put({
            args: this.authoArgs,
            workOrderLine: this.workOrderLine,
            id: this.workOrderLine.id
        });
        this.missionService.publish({ type: 'UPDATE-INCOMPLETE-AUTHO-COUNT', messageBody: '' });
        this.missionService.showSuccessToast('Authorization Saved');
    }

    async authorize() {
        this.authoArgs.id = this.workOrderLine.id;
        localStorage.setItem('technician-contact-information', JSON.stringify(this.authoArgs.technicianContact));
        this.authorizing = true;
        this.authoArgs.validateType = true;
        this.authoArgs.validateProblem = true;
        this.authoArgs.validateCause = true;
        this.authoArgs.validatePreExisting = true;
        this.authoArgs.validateMfgWarranty = true;
        this.authoArgs.validateRepairItems = false;
        this.authoArgs.validatePlanTotals = false;
        this.authoArgs.saveAutho = true;

        if (this.problems) {
            this.authoArgs.problemIds = this.problems.filter(i => i.selected).map(i => i.id);
        }

        await this.indexedDbService.put({
            args: this.authoArgs,
            workOrderLine: this.workOrderLine,
            id: this.workOrderLine.id
        });
        this.missionService.publish({ type: 'UPDATE-INCOMPLETE-AUTHO-COUNT', messageBody: '' });
        this.serviceApi.processAuthorizationRequest(this.workOrderLine.id, this.authoArgs).then(async results => {

            this.authoArgs.result = results;
            this.authorizing = false;
            this.next();
            this.authorizationResult = results;
            this.missionService.raiseGlobalMessage('JOB-UPDATED');
            this.authoComplete = true;
            await this.indexedDbService.delete(this.workOrderLine.id);
            this.missionService.publish({ type: 'UPDATE-INCOMPLETE-AUTHO-COUNT', messageBody: '' });

        }, () => {
            this.authorizing = false;
        });
    }
}
