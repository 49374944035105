<svg xmlns="http://www.w3.org/2000/svg" [style.height]="height"  viewBox="0 0 424 424">
    <defs>
      <style>

      </style>
    </defs>
    <g id="circle-outline">
      <g>
        <circle class="circle-outline" cx="212" cy="212" r="202" />

      </g>
    </g>
    <g id="circle">
        <circle class="circle-fill" cx="212" cy="212" r="202" />
    </g>
    <g id="shadow">
      <path class="cls-2-1"
        d="M910.89,445.08l-55.81-47.2L671.33,615.13l78.33,66.25C843.29,659.39,913,575.34,913,475A213.84,213.84,0,0,0,910.89,445.08Z"
        transform="translate(-489 -264)" />
    </g>
    <g id="check">
      <path class="cls-3"
        d="M843.39,383.06l-30.61-26.12a19.88,19.88,0,0,0-28,2.22L658.11,507.6l-68.53-58.46a19,19,0,0,0-26.85,2.12l-27.2,31.89A19.05,19.05,0,0,0,537.66,510l83.65,71.36h0l30.61,26.12h0l1,.87a19.05,19.05,0,0,0,26.85-2.13L707,574.34a19.59,19.59,0,0,0,2.39-3.58L845.61,411.08A19.87,19.87,0,0,0,843.39,383.06Z"
        transform="translate(-489 -264)" />
    </g>
  </svg>
