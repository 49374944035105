import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderPreferredTime {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() date: Date;
    @column() startTime: number;
    @column() endTime: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() dispatchExportDate?: Date;



    dayString: string;
    startHour: number;
    endHour: number;
    startMinute: number;
    startMinuteString: string;
    endMinuteString: string;
    endMinute: number;
    startHourAMPM: string;
    endHourAMPM: string;
    summary: string;
}

@dataSource()
export class WorkOrderPreferredTimeSummary{
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() date: Date;
    @column() startTime: number;
    @column() endTime: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() formattedTimeSlot: string;
    @column() dispatchExportDate?: Date;
    @column() timeZone?: string;
    @column() localDate?: Date;
}