import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { RepairItemAttributeValue } from "./repair-item-attribute-value.model";

@dataSource()
export class RepairItemAttribute {
    @keyColumn() id: string;
    @column() repairItemId: string;
    @column() name: string;
    @column() sort: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    
    values: RepairItemAttributeValue[];
}