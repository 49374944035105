<form action="" style="max-width: 300px">
    <div class="star-container">
        <ng-template ngFor let-star [ngForOf]="stars" let-i="index">
            <div>
                <input #ngxCheckbox class="star star-{{ star }}" id="star-{{ star }}-{{ id }}" type="radio" name="star"
                    (click)="rate(star)" [checked]="value == star" [disabled]="disabled" />
                <label [class.selected]="value >= star" class="star star-{{ star }}" for="star-{{ star }}-{{ id }}"></label>
            </div>
        </ng-template>
    </div>
</form>