import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { WorkOrderLineSummary } from "./work-order-line-summary.model";
import { FavorFundExpense, WorkOrder } from ".";

@dataSource()
export class Claim {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() deletedDate?: Date;
    @column() status: string;
    @column() serviceFee: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() dateClosed: Date;
    @column() payAfterService: boolean;
    @column() stripeCardId: string;
    @column() serviceFeeWaiveReason: string;
    @column() associatedClaimId: string;
    @column() accDate: Date;
    @column() assignedToUserId: string;
    @column() prepaidServiceFeeId: string;
    @column() invoiceForServiceFee: boolean;
    @column() workorderJSON: string;

    lines: any[];
    source: string;
    serviceFeePaymentItemId: string;
    stripeCustomerId: string;
    workOrder: WorkOrder;
    favorFundExpense: FavorFundExpense;
    itemName: string;
}