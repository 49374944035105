<mat-form-field appearance="outline">
    <mat-label>Part Or Model</mat-label>
    <input matInput [id]="id" [(ngModel)]="searchFor" #searchInput [matAutocomplete]="auto" [formControl]="searchCtrl">
    <mat-spinner class="thirty" style="position: absolute;margin-left: -24px;margin-top: -10px;" *ngIf="searching"></mat-spinner>
    <mat-icon matSuffix *ngIf="!searching">search</mat-icon>
    <mat-autocomplete [displayWith]="displayWith" #auto="matAutocomplete" panelClass="search-option" (optionSelected)="selected($event)">
        <ng-container *ngIf="searchResults">
            <mat-optgroup panelClass="search-option" *ngIf="searchResults.products.length" label="Products ({{searchResults.products.length}})">
                <mat-option *ngFor="let product of searchResults.products" [value]="product">
                    <h2>{{product.manufacturer}} {{product.name}}</h2>
                    <div class="description">{{product.description}}</div>
                </mat-option>
            </mat-optgroup>
            <mat-optgroup panelClass="search-option" label="Models ({{searchResults.models.length}})" *ngIf="searchResults.models.length">
                <mat-option *ngFor="let model of searchResults.models" [value]="model">
                    <h2>{{model.manufacturer}} {{model.name}}</h2>
                    <div class="description">{{model.applianceType}}</div>
                </mat-option>
            </mat-optgroup>


            <!-- <mat-option value="add-new">
                <mat-icon>add</mat-icon>
                Not Found
            </mat-option> -->
        </ng-container>
    </mat-autocomplete>
</mat-form-field>