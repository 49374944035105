import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CashOutAccounting {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() customerId: string;
    @column() accountingAreaId: string;
    @column() type: string;
    @column() sendMethod: string;
    @column() subscription: bigint;
    @column() job: bigint;
    @column() memo: string;
    @column() approvedDate: Date;
    @column() exportDate: Date;
    @column() postDate: Date;
    @column() customer: string;
    @column() nameOnCheck: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() createdDate: Date;
    @column() costAccountType: string;
    @column() cashAccountType: string;
    @column() accountsPayableAccountType: string;
    @column() costAccountId: number;
    @column() cashAccountId: number;
    @column() accountsPayableAccountId: number;
    @column() amount: number;
    @column() appliedAmount: number;
    @column() balance: number;
    selected: boolean;
}