import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AmazonConnectCTR {
    @keyColumn() id: string;
    @column() contactId: string;
    @column() body: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() afterContactWorkStart: Date;
    @column() afterContactWorkEnd: Date;
    @column() customerHoldDuration: number;
    @column() longestHoldDuration: number;
    @column() numberOfHolds: number;
    @column() routingProfile: string;
    @column() agentUserName: string;
    @column() agentContactAttempts: number;
    @column() channel: string;
    @column() connectedToSystem: Date;
    @column() customerEndpointAddress: string;
    @column() customerEndpointType: string;
    @column() disconnected: Date;
    @column() initialContactId: string;
    @column() initiationMethod: string;
    @column() nextContactId: string;
    @column() queueEnqueue: Date;
    @column() queueDequeue: Date;
    @column() queueDuration: number;
    @column() queueName: string;
    @column() recordingLocation: string;
    @column() recordingType: string;
    @column() recordingStatus: string;
    @column() systemEndpointAddress: string;
    @column() systemEndpointType: string;
    @column() previousContactId: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() surveyScore: number;

    // Client-side only
    playingAudio = false;
    recordingEndpoint: string;
}


@dataSource()
export class AmazonConnectCTRSummary {
    @keyColumn() id: string;
    @column() customerHoldDuration: number;
    @column() longestHoldDuration: number;
    @column() numberOfHolds: number;
    @column() routingProfile: string;
    @column() agentUserName: string;
    @column() agentContactAttempts: number;
    @column() channel: string;
    @column() connectedYear: number;
    @column() connectedMonth: string;
    @column() connectedQuarter: string;
    @column() connectedHour: number;
    @column() customerEndpointType: string;
    @column() duration: number;
    @column() initiationMethod: string;
    @column() queueDuration: number;
    @column() systemEndpointType: string;
    @column() surveyScore: number;
    @column() connectedToSystem: Date;
    @column() areaName: string;
    @column() stateName: string;
    @column() policyId: string;
    @column() areaId: string;
    @column() stateId: string;
    @column() surveyPotential: number;
    @column() recordCount: number;
    @column() firstName: string;
    @column() lastName: string;
    @column() shortName: string;
    @column() queueName: string;
    @column() contactId: string;
    @column() employeeId: string;
    @column() createdDate: Date;
    @column() answeredByAuthoAgent: boolean;
}