import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PlanItemLanguageWorkOrderItem {
    @keyColumn() id: string;
    @column() planItemLanguageId: string;
    @column() workOrderItemId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
}