import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { ServiceRepositoryService } from '@upkeeplabs/service-pros/app/services/service-repository';
import { ItemModel } from '@upkeeplabs/service-pros/app/model/claims/item.model';
import { ActivatedRoute } from '@angular/router';
import { TechnicianContact } from '@upkeeplabs/service-pros/app/model/contractors/technician-contact.model';
import { Note } from '@upkeeplabs/service-pros/app/model/common/note.model';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { RepairItemService } from '@cogent/client/shared/services/api/repair-item.service';
import { QuestionWizardComponentV2 } from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { RepairItem, Tag } from '@upkeeplabs/models/cogent';
import { Question } from '@cogent/shared/models/common/question-parser.model';
import { AuthorizationLine, AuthorizationLineAttribute } from '@cogent/shared/models/authorizations/authorization-line.model';
declare var Camera: any;

@Component({
    selector: 'app-enter-authorization',
    templateUrl: './enter-authorization.component.html',
    styleUrls: ['./enter-authorization.component.css']
})
export class EnterAuthorizationComponent implements OnInit {

    constructor(private serviceRepository: ServiceRepositoryService,
        private serviceApi: ServiceApiService,
        private route: ActivatedRoute,
        private authorizationsApi: AuthorizationsApiService,
        private repairItemApi: RepairItemService,
    ) {
        this.userDefinedItem = new ItemModel();

    }

    availableItems: RepairItem[];
    filteredItems: RepairItem[];
    temporaryRepairItemId = "018763b5-2d03-4d5e-88d8-969eaf16b8df";
    attributeNames: any = {};

    newLine: AuthorizationLine;

    get canAuthorize() {
        if (!this.technicianContact) {
            return false;
        }

        if (!this.technicianContact.name) {
            return false;
        }

        if (!this.technicianContact.contactViaEmail && !this.technicianContact.contactViaPhone && !this.technicianContact.contactViaSMS) {

            return false;
        }

        if (this.technicianContact.contactViaEmail && (!this.technicianContact.email || !UtilitiesService.validateEmail(this.technicianContact.email))) {
            return false;
        }

        if (this.technicianContact.contactViaPhone && (!this.technicianContact.phoneNumber || !UtilitiesService.validatePhoneNumber(this.technicianContact.phoneNumber))) {
            return false;
        }

        if (this.technicianContact.contactViaSMS && (!this.technicianContact.smsNumber || !UtilitiesService.validatePhoneNumber(this.technicianContact.smsNumber))) {
            return false;
        }

        return true;
    }
    tabIndex: any;
    workOrderSummary: WorkOrderSummaryClient;
    @Output() authorizationComplete: EventEmitter<boolean> = new EventEmitter();

    private pWorkOrderId: string;
    get workOrderId() { return this.pWorkOrderId; }
    @Input() set workOrderId(value: string) {
        this.pWorkOrderId = value;

        if (!value) {
            this.workOrderSummary = null;
        } else {
            this.serviceRepository.getWorkOrderSummary(this.workOrderId).then(workOrderSummary => this.workOrderSummary = workOrderSummary);
            this.serviceApi.getWorkOrderTags(this.workOrderId).then(tags => this.tags = tags);
            this.authorizationsApi.getAuthorizationLines(this.workOrderId).then(lines => {
                for (const line of lines) {
                    this.setLineDescription(line, true);
                }
                this.lines = lines;
                this.addNewLine();
            });
            // this.repairItemApi.findAvailable(this.workOrderId).then(items => {
            //     this.availableItems = items;
            // });
            this.isEmbedded = true;
        }
    }

    questionsComplete = false;
    selectedIndex = 0;
    attachments: WorkOrderAttachmentModel[] = [];
    @ViewChild('questionWizard') questionWizard: QuestionWizardComponentV2;
    authorizing = false;
    locked = false;
    wasAutoAuthorized = false;
    isEmbedded = false;
    technicianContact: TechnicianContact;
    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    userDefinedItem: ItemModel;
    tags: Tag[];
    lines: AuthorizationLine[];

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash() {
        const hash = window.location.hash;

        if (this.locked) {
            return;
        }
        if (hash === '#questionnaire') {
            this.selectedIndex = 0;
        } else if (hash === '#items') {
            this.selectedIndex = 1;
        } else if (hash === '#photos') {
            this.selectedIndex = 2;
        } else if (hash === '#review') {
            this.selectedIndex = 3;
        } else {
            this.selectedIndex = 0;
        }
    }

    addNewLine() {
        const newItem = new AuthorizationLine();
        newItem.id = UtilitiesService.newid();
        newItem.workOrderId = this.workOrderId;
        newItem.createdDate = new Date();
        newItem.quantity = 1;
        newItem.unitPrice = 0.00;
        newItem.isChanged = true;
        this.newLine = newItem;
    }

    closeAuthorizationEmbedded() {
        this.authorizationComplete.emit(true);
    }

    onStepChange() {
        if (this.selectedIndex === 0) {
            window.location.hash = 'questionnaire';
        } else if (this.selectedIndex === 1) {
            window.location.hash = 'items';
        } else if (this.selectedIndex === 2) {
            window.location.hash = 'photos';
        } else if (this.selectedIndex === 3) {
            window.location.hash = 'review';
        } else if (this.selectedIndex === 4) {
            window.location.hash = 'complete';
        }
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        document.location.hash = 'questionnaire';
        this.route.params.subscribe(params => {
            if (params.id) {
                this.workOrderId = params.id;
                this.serviceRepository.getWorkOrderSummary(this.workOrderId).then(workOrderSummary => {
                    this.workOrderSummary = workOrderSummary;
                });
            }
        });

        this.repairItemApi.getRepairItemAttributes().then(attributes => {
            for (const attribute of attributes) {
                this.attributeNames[attribute.id] = attribute.name;
            }
        });

        if (localStorage['technician-contact-information']) {
            try {

                this.technicianContact = JSON.parse(localStorage['technician-contact-information']);
            } catch (e) {
                this.technicianContact = new TechnicianContact();
            }
        } else {
            this.technicianContact = new TechnicianContact();
        }
    }

    onQuestionnaireComplete(isComplete: boolean) {
        if (isComplete) {
            window.scrollTo(0, 0);
        }
    }

    goForward() {

        if (this.locked) {
            return;
        }
        if (this.selectedIndex === 0 && !this.questionsComplete) {
            return;
        }

        if (this.selectedIndex === 1 && (!this.lines || this.lines.length === 0)) {
            return;
        }

        if (this.selectedIndex < 3) {
            this.selectedIndex++;
        }
    }

    goBack() {
        if (this.locked) {
            return;
        }

        if (this.selectedIndex > 0) {
            this.selectedIndex--;
        }
    }

    get canAddItem() {
        return (this.newLine && this.newLine.repairItemId);
    }

    get authorizationTotal() {
        if (!this.lines || this.lines.length === 0) {
            return 0;
        }
        return this.lines.map(i => i.quantity * i.unitPrice).reduce((a, b) => a + b);
    }

    get totalOutOfPocket() { return 0; }
    get amountOverAcc() { return 0; }
    get total() { return 0; }

    filterItems(filter: string) {
        if (filter) {
            this.filteredItems = this.availableItems.filter(i => i.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        } else {
            this.filteredItems = null;
        }
    }

    addItem() {
        this.lines.push(this.newLine);
        this.addNewLine();
    }

    showFileUpload() {
        const nav: any = navigator;

        if (nav && nav.camera) {
            nav.camera.getPicture(
                imageData => {

                    const fileName =
                        UtilitiesService.newid().replace('-', '').substring(0, 9) +
                        '.jpg';
                    const attachment = new WorkOrderAttachmentModel(
                        fileName,
                        UtilitiesService.newid(),
                        'data:image/jpeg;base64,' + imageData);
                    this.attachments.push(attachment);


                },
                error => { console.error(error); }, {
                quality: 30,
                destinationType: Camera.DestinationType.DATA_URL,
                correctOrientation: true,
                mediaType: Camera.MediaType.PICTURE,
                encodingType: Camera.EncodingType.JPEG
            });
        } else {
            document.getElementById('fileToUpload1').click();
        }
    }

    saveAuthorization() {
        localStorage.setItem('technician-contact-information', JSON.stringify(this.technicianContact));
        this.authorizing = true;
        this.serviceRepository.saveAuthorization(this.workOrderId, this.questionWizard.questionStack, this.lines, this.technicianContact).then(response => {
            if (this.attachments && this.attachments.length > 0) {
                const note = new Note();
                note.policyId = this.workOrderSummary.policyId;
                note.workOrderId = this.workOrderId;
                note.noteText = 'Authorization Pictures Attached';

                this.serviceRepository.saveAuthorizationAttachments(note, this.attachments).then(() => {
                    this.completeWizard(response);
                });
            } else {
                this.completeWizard(response);
            }

        });
    }

    removeAuthoLine(line: AuthorizationLine) {
        line.deleting = true;
        setTimeout(() => {
            this.lines.splice(this.lines.indexOf(line), 1);
        }, 400);
    }

    private completeWizard(response) {
        this.selectedIndex = 4;
        this.authorizing = false;
        this.locked = true;
        this.wasAutoAuthorized = response.success;
    }

    handleFileUpload(evt) { this.handleFiles(evt); }

    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            const loadIt = (base64, fileName) => {
                const attachment = new WorkOrderAttachmentModel(fileName, null, base64);
                this.attachments.push(attachment);
            };

            reader.onload = ((fileName) => function (e) {
                (this as any).dragTargetActive = false;
                loadIt(e.target.result, fileName);

            })(file.name);

            reader.readAsDataURL(file);
        }
        (document.getElementById('fileToUpload1') as any).value = '';
    }


    removeAttachment(attachment) {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
    }


    async itemChanged(line: AuthorizationLine) {
        if (line.repairItemId === "other-temporary") {
            line.id = UtilitiesService.newid();
            line.attributes = [];
        } else {
            const attributes = await this.repairItemApi.getRepairItemAttributesForItem(line.repairItemId);
            line.id = UtilitiesService.newid();
            if (!line.attributes) {
                line.attributes = [];
            }
            if (line.attributes.length > 0 && !attributes.find(a => a.id === line.attributes[0].attributeId)) {
                line.attributes = [];
            }
            for (const attribute of attributes) {
                if (!line.attributes.find(a => a.attributeId === attribute.id)) {
                    const newAttribute = new AuthorizationLineAttribute();
                    newAttribute.authorizationLineId = line.id;
                    newAttribute.id = UtilitiesService.newid();
                    newAttribute.attributeId = attribute.id;
                    newAttribute.createdDate = new Date();
                    newAttribute.availableValues = await this.repairItemApi.getRepairItemAttributeValuesForAttribute(attribute.id);
                    line.attributes.push(newAttribute);
                    newAttribute.isChanged = true;
                }
            }
            for (const attribute of line.attributes) {
                attribute.authorizationLineId = line.id;
            }
        }
        this.lineChanged(line);
    }

    lineChanged(line: AuthorizationLine, attribute?: AuthorizationLineAttribute) {
        line.isChanged = true;
        if (attribute) {
            attribute.isChanged = true;
        }

        this.setLineDescription(line, true);
    }

    hideUnavailableAttributeValues(line: AuthorizationLine, clearInvaliid = true) {
        for (const attrib of line.attributes) {
            for (const value of attrib.availableValues) {
                if (value.dependencies) {
                    const dependentIds = JSON.parse(value.dependencies);
                    if (dependentIds.length > 0) {
                        value.hidden = true;
                        for (const dependentId of dependentIds) {
                            if (line.attributes.find(a => a.valueId === dependentId)) {
                                value.hidden = false;
                            }
                        }
                    }
                }
            }
        }
        if (clearInvaliid) {
            for (const attrib of line.attributes) {
                if (attrib.valueId) {
                    const value = attrib.availableValues.find(v => v.id === attrib.valueId);
                    if (value.hidden) {
                        attrib.valueId = null;
                        this.lineChanged(line, attrib);
                    }
                }
            }
        }

    }

    setLineDescription(line: AuthorizationLine, updateGuideline = false) {
        this.hideUnavailableAttributeValues(line, updateGuideline);
        if (line.isRed || updateGuideline) {
            let description = "";
            let guideline = 0;
            let yellowRangePercent = 0;
            if (this.availableItems) {
                const repairItem = this.availableItems.find(i => i.id === line.repairItemId);
                if (repairItem) {
                    description = repairItem.name;
                    guideline += repairItem.amount;
                    yellowRangePercent += repairItem.yellowRangePercent;
                    for (const lineAttribute of line.attributes) {
                        const value = lineAttribute.availableValues.find(v => v.id === lineAttribute.valueId);
                        if (value) {
                            description += " (" + value.value + ")";
                            guideline += value.amountDelta;
                            yellowRangePercent += value.yellowRangePercentDelta;
                        }
                    }
                    line.description = description;
                    if (updateGuideline) {
                        line.guideline = guideline;
                        line.yellowRangePercent = yellowRangePercent;
                    }
                } else if (line.repairItemId === this.temporaryRepairItemId) {
                    line.description = line.temporaryItemName;
                }
            }
        }
    }


    async setQuestionChanged(question: Question) {
        if (question && question.originalType === "REPAIR_ITEM") {
            let line = this.lines.find(l => l.id === question.instanceId);
            if (!line) {
                this.addNewLine();
                line = this.newLine;
                line.repairItemId = question.meta.repairItemId;
                await this.itemChanged(line);
                line.id = question.instanceId;
                for (const attrib of line.attributes) {
                    attrib.authorizationLineId = line.id;
                }
                this.lines.push(line);
                this.addNewLine();
            }
            const attribute = line.attributes.find(a => a.attributeId === question.meta.repairItemAttributeId);
            if (attribute) {
                attribute.valueId = question.meta.repairItemAttributeValueId;
                this.hideUnavailableAttributeValues(line);
            } else if (question.text === "Amount") {
                line.unitPrice = question.answer;
            }
            this.setLineDescription(line, true);
        }
    }
}