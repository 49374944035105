<div class="outer-container">
    <div *ngIf="customerId || selectedCard">

        <div *ngIf="customerId" class="form-row" class="hide-me" [class.show-me]="addingNewCard">
            <button mat-button pull-right *ngIf="!newCustomerMode" (click)="closeAddingNewCard()">
                <i class="material-icons">close</i>
            </button>
            <label topmargin20 bottommargin10 for="card-element" class="data-label" data-cy="card-entry">
                Credit or debit card
            </label>
            @if(addingNewCard) {
                <div id="card-element"> </div>
            }

            <div id="card-errors" role="alert"></div>
            <div *ngIf="allowOneTimeUse" topmargin20>
                <mat-checkbox [(ngModel)]="saveCardForLater">Use This Card For Future
                    Transactions</mat-checkbox>
            </div>
            <button topmargin20 mat-button mat-raised-button class="small-primary" *ngIf="!hideSaveCardToWalletButton"
                [disabled]="!canSubmit || savingPaymentMethod" color="primary" (click)="savePaymentMethod()">
                <mat-spinner *ngIf="savingPaymentMethod" class="thirty"></mat-spinner>
                {{newCardButtonText}}
            </button>
        </div>




        <div *ngIf="!addingNewCard" style="position: relative;">

            <button mat-button id="left-mobile-card-button" (click)="prevMobileCard()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button mat-button (click)="nextMobileCard()" id="right-mobile-card-button">
                <mat-icon>chevron_right</mat-icon>
            </button>
            <div id="mobile-card-container" [style.transform]="mobileCardContainerTransform"
                (swipeleft)="nextMobileCard()" (swiperight)="prevMobileCard()">
                <div *ngFor="let card of existingCards; index as i" class="button-div button-div-mobile"
                    [class.slideOutRight]="card.removingDeleted">

                        <button [class.current-index]="i == mobileCardIndex" class="list-item-button" mat-button
                            (click)="selectCard(card)">
                            <div class="card-layout" [class.mc]="card.brand == 'MasterCard'"
                                [class.amex]="card.brand == 'American Express'" [class.discover]="card.brand == 'Discover'">

                                <div flex>
                                    <div style="max-width: 80px">
                                        <img *ngIf="card.brand == 'Visa'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png" mat-list-icon
                                            class="card-icon">
                                        <img *ngIf="card.brand == 'Discover'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png" mat-list-icon
                                            class="card-icon">
                                        <img *ngIf="card.brand == 'American Express'"
                                            src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png" mat-list-icon class="card-icon">
                                        <img *ngIf="card.brand == 'MasterCard'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png"
                                            mat-list-icon class="card-icon">

                                    </div>
                                </div>

                                <div class="card-brand">
                                    <h1>{{card.brand}}</h1>
                                </div>
                                <h4 class="card-number">•••• &nbsp;&nbsp;•••• &nbsp;&nbsp;•••• &nbsp;&nbsp;{{card.last4}}
                                </h4>

                                <div flex>
                                    <div class="credit-card-name">
                                        {{card.customerName}}
                                    </div>
                                    <div>
                                        <p matLine class="expiration-date-field">{{card.exp_month}}/{{card.exp_year}}</p>
                                    </div>
                                </div>
                            </div>
                        </button>

                </div>

            </div>
            <button mat-button block id="add-new-card-mobile" style="white-space: normal;line-height: 12px;"
                *ngIf="!addingNewCard && !refreshingCards" (click)="showAddNewCard()">
                <mat-icon>add_circle_outline</mat-icon>Add a payment method to the wallet
            </button>
        </div>
        <div id="desktop-card-container">
            <mat-list-item *ngIf="refreshingCards">
                <mat-spinner class="thirty" mat-list-icon></mat-spinner>
                <h4 matLine>Loading...</h4>
            </mat-list-item>




            <div *ngIf="!addingNewCard">
                <div class="data-label" topmargin30>Pay with existing credit card</div>
                <div *ngFor="let card of existingCards" class="button-div" [class.slideOutRight]="card.removingDeleted">
                    <div flex>
                        <div>
                            <button [class.selected]="selectedCard && selectedCard.id == card.id"
                                class="list-item-button" mat-button (click)="selectCard(card)">
                                <div class="card-layout" [class.mc]="card.brand == 'MasterCard'"
                                    [class.amex]="card.brand == 'American Express'"
                                    [class.discover]="card.brand == 'Discover'">

                                    <div flex>
                                        <div style="max-width: 80px">
                                            <img *ngIf="card.brand == 'Visa'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png" mat-list-icon
                                                class="card-icon">
                                            <img *ngIf="card.brand == 'Discover'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png"
                                                mat-list-icon class="card-icon">
                                            <img *ngIf="card.brand == 'American Express'"
                                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png" mat-list-icon class="card-icon">
                                            <img *ngIf="card.brand == 'MasterCard'" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png"
                                                mat-list-icon class="card-icon">

                                        </div>
                                    </div>

                                    <div class="card-brand">
                                        <h1>{{card.brand}}</h1>
                                    </div>
                                    <h4 class="card-number">•••• &nbsp;&nbsp;•••• &nbsp;&nbsp;••••
                                        &nbsp;&nbsp;{{card.last4}}</h4>

                                    <div flex>
                                        <div class="credit-card-name">
                                            {{card.customerName}}
                                        </div>
                                        <div>
                                            <p matLine class="expiration-date-field">
                                                {{card.exp_month}}/{{card.exp_year}}</p>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div nogrow style="position: relative; width: 0; margin: 0">
                            <button class="delete-button" *ngIf="canDelete" [disabled]="card.deleting" mat-button
                                (click)="deleteCard(card)">
                                <i *ngIf="!card.deleting" class="material-icons">delete</i>
                                <mat-spinner *ngIf="card.deleting" class="thirty"></mat-spinner>
                            </button>
                        </div> 
                    </div>
                </div>
                <div class="button-div">
                    <button mat-button class="list-item-button add-button" *ngIf="!addingNewCard && !refreshingCards"
                        (click)="showAddNewCard()">
                        <mat-icon>add_circle_outline</mat-icon>
                        <br>
                        Add New
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!customerId && !selectedCard">
        <label topmargin20 bottommargin10 for="card-element" class="data-label">
            Credit or debit card
        </label>
        @if(addingNewCard) {
            <div id="card-element"> </div>
        }
        <div id="card-errors" role="alert"></div>
        <button topmargin20 mat-button mat-raised-button [disabled]="!canSubmit || savingPaymentMethod" color="primary"
            (click)="savePaymentMethod()">
            <mat-spinner *ngIf="savingPaymentMethod" class="thirty"></mat-spinner>
            {{buttonText}}
        </button>
    </div>
</div>
