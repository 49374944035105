import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class BIReport {
    @keyColumn() id: string;
    @column() name: string;
    @column() reportKey: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() iconClass: string;
    @column() sort: number;
    @column() biReportGroupId: string;

    deleting = false;
    editMode = false;
}