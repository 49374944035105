import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodEntryComponent } from './payment-method-entry/payment-method-entry.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { ManageMultiplePaymentMethodsComponent } from "@cogent/client/shared/components/accounting/manage-multiple-payment-methods/manage-multiple-payment-methods.component";

@NgModule({
  declarations: [/*PaymentMethodEntryComponent,*/ ManageMultiplePaymentMethodsComponent],
  imports: [
    CommonModule,
    MaterialSharedModule,
    PaymentMethodEntryComponent
  ],
  exports: [/*PaymentMethodEntryComponent,*/ ManageMultiplePaymentMethodsComponent],
})
export class PaymentMethodEntryModule { }
