import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CreditCardTransaction {
    @keyColumn() id: string;
    @column() postingDate: Date;
    @column() transactionDate: Date;
    @column() supplier: string;
    @column() amount: number;
    @column() reconciled: Date;
    @column() isNotPurchase: boolean = false;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() batchId: string;
}

@dataSource()
export class CreditCardTransactionItem {
    @keyColumn() id: string;
    @column() creditCardTransactionId: string;
    @column() purchaseOrderId: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
}

@dataSource()
export class CreditCardTransactionMatch {
    @keyColumn() id: string;
    @column() postingDate: Date;
    @column() transactionDate: Date;
    @column() supplier: string;
    @column() amount: number;
    @column() purchaseOrderId: string;
}