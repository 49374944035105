import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '@cogent/client/shared/services/api/contractors.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MaintServicesCreateInvoiceComponent } from '../maint-services-create-invoice/maint-services-create-invoice.component';
import { MatDialog } from '@angular/material/dialog';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MaintenanceServiceInvoiceSummary } from '@upkeeplabs/models/cogent';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';
declare var Chart: any;

@Component({
  selector: 'app-maint-services-billing',
  templateUrl: './maint-services-billing.component.html',
  styleUrls: ['./maint-services-billing.component.css']
})
export class MaintServicesBillingComponent implements OnInit {

    contractorId: string;
    paymentsByMonth: any[];
    paymentsByMonthId = UtilitiesService.newid();
    paymentsByMonthChart: any;
    unapprovedCount: number;
    unapprovedSum: number;
    unpaidSum: number;
    unpaidCount: number;
    showIndividualBilling = false;
    showDetail = false;
    invoices: ContractorInvoiceSummaryClient[];
    detailType: string;
    pendingInvoices: MaintenanceServiceInvoiceSummary[];


    constructor(
        private contractorService: ContractorsService,
        private entityApi: EntityApiService,
        private dialog: MatDialog,
        private maintApi: MaintenanceServiceApiService,
        private missionService: MissionService,
    ) { }

    ngOnInit(): void {
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorId = user.id;
            this.refresh();
        });
    }

    refresh() {
        this.loadPaymentsByMonth();
        this.getPendingInvoices();
        // this.loadUnapprovedCount();
        // this.loadUnapprovedSum();
        // this.loadUnpaidCount();
        // this.loadUnpaidSum();
    }

    async addInvoice() {
        const result  = await MaintServicesCreateInvoiceComponent.show(this.dialog);

        if(result) {
            this.getPendingInvoices();
        }
    }

    private async getPendingInvoices() {
        this.pendingInvoices =await this.maintApi.getPendingMaintenanceInvoices(this.contractorId);
    }

    updateBillingInfo() {
        this.showIndividualBilling = false;
        this.refresh();
    }

    async loadPaymentsByMonth() {
        this.paymentsByMonth = await this.contractorService.getMaintServiceContractorPaymentsTotalByMonth(this.contractorId);
        this.setPaymentHistoryChart();
    }

    // async loadUnapprovedCount() {
    //     this.unapprovedCount = await this.contractorService.getUnApprovedInvoiceCount(this.contractorId);
    // }

    // async loadUnapprovedSum() {
    //     this.unapprovedSum = await this.contractorService.getUnApprovedInvoiceSum(this.contractorId);
    // }

    showUnapprovedTransactions() {
        this.showDetail = false;
        this.detailType = 'UNAPPROVED';
        this.invoices = [];
        this.contractorService.getUnapprovedInvoiceList(this.contractorId).then(invoices => {
            this.invoices = invoices;
            this.showDetail = true;
        });
    }

    showUnpaidTransactions() {
        this.detailType = 'UNPAID';
        this.showDetail = false;
        this.invoices = [];
        this.contractorService.getUnPaidInvoiceList(this.contractorId).then(invoices=> {
            this.invoices = invoices;
            this.showDetail = true;
        });
    }

    // async loadUnpaidCount() {
    //     this.unpaidCount = await this.contractorService.getUnPaidInvoiceCount(this.contractorId);
    // }

    // async loadUnpaidSum() {
    //     this.unpaidSum = await this.contractorService.getUnPaidInvoiceSum(this.contractorId);
    // }

    // async deleteInvoice(invoice: ContractorInvoiceSummary) {
    //     const index = this.invoices.indexOf(invoice);


    //     await this.contractorService.deleteContractorInvoice(invoice.id);
    //     this.invoices.splice(index, 1);
    //     if (this.detailType === 'UNPAID') {
    //         this.loadUnpaidCount();
    //         this.loadUnpaidSum();
    //     } else {

    //         this.loadUnapprovedSum();
    //         this.loadUnapprovedCount();
    //     }

    //     this.missionService.showUndo(new UndoArguments(async () => {
    //         await this.contractorService.unDeleteContractorInvoice(invoice.id);
    //         this.invoices.splice(index, 0, invoice);
    //         if (this.detailType === 'UNPAID') {
    //             this.loadUnpaidCount();
    //             this.loadUnpaidSum();
    //         } else {

    //             this.loadUnapprovedSum();
    //             this.loadUnapprovedCount();
    //         }
    //     }, 'Invoice Deleted'));
    // }

    setPaymentHistoryChart() {

        const canvas = document.getElementById(this.paymentsByMonthId) as any;
        if (!canvas) {
            setTimeout(() => this.setPaymentHistoryChart(), 500);
            return;
        }
        const backgroundColors = [
            'rgba(184, 89, 149, .3)',
            'rgba(110,124,185, .1)',
            'rgba(184,52,45, .3)'
        ];
        const borderColors = [
            'rgba(184, 89, 149, 1)',
            'rgba(110,124,185, .5)',
            'rgba(184,52,45, 1)'
        ];

        const MONTHS = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const config = {
            type: 'bar',
            data: {
                labels:
                    this.paymentsByMonth.map(i => MONTHS[i.month - 1] + ' ' + i.year),
                datasets: [
                    {
                        data: this.paymentsByMonth.map(i => i.amountPaid),
                        backgroundColor: backgroundColors[2],
                        borderColor: borderColors[2]
                    },
                ]
            },
            options: {
                responsive: false,
                legend: {
                    position: 'right',
                    display: false,
                },
                title: { display: false, text: 'Chart.js Line Chart' },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: { mode: 'nearest', intersect: true },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: { display: true, labelString: 'Month' },
                            gridLines: { display: false }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel:
                                { display: true, labelString: 'Amount Paid' }
                        }
                    ]
                }
            }
        };

        const ctx = canvas.getContext('2d');
        if (this.paymentsByMonthChart) {
            this.paymentsByMonthChart.destroy();
        }
        this.paymentsByMonthChart = new Chart(ctx, config);
    }
}
