import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { DateRangeDisplayAndSelectorComponent } from '@cogent/client/shared/components/misc/date-range-display-and-selector/date-range-display-and-selector.component';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { RouterModule } from '@angular/router';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';

@Component({
    selector: 'app-tax-report',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule, DateRangeDisplayAndSelectorComponent, RouterModule],
    templateUrl: './tax-report.component.html',
    styleUrls: ['./tax-report.component.css']
})
export class TaxReportComponent {

    startDate: Date;
    endDate: Date;
    contractorInvoices: ContractorInvoiceSummaryClient[];
    loading = false;

    constructor(private contractorApi: ContractorApiService,
        private entitApi: EntityApiService) {

    }


    get canLoad() {
        return this.startDate && this.endDate && !this.loading;
    }

    get partsCost() {
        if (!this.contractorInvoices) {
            return 0;
        }

        return this.contractorInvoices.map(i => i.partsCost).reduce((a, b) => a + b);
    }

    get laborCost() {
        if (!this.contractorInvoices) {
            return 0;
        }

        return this.contractorInvoices.map(i => i.laborCost).reduce((a, b) => a + b);
    }

    get tax() {
        if (!this.contractorInvoices) {
            return 0;
        }

        return this.contractorInvoices.map(i => i.tax).reduce((a, b) => a + b);
    }

    get totalAmount() {
        if (!this.contractorInvoices) {
            return 0;
        }

        return this.contractorInvoices.map(i => i.totalAmount).reduce((a, b) => a + b);
    }

    export() {
        UtilitiesService.export();
    }



    async load() {

        this.contractorInvoices = [];
        this.loading = true;
        const user = await this.entitApi.getLoggedInUser();

        this.contractorInvoices = await this.contractorApi.getContractorInvoices(this.startDate, this.endDate, user.id);
        this.loading = false;
    }
}
