import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateSelectionModelChange, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-maintenance-service-reschedule-appointment',
    standalone: true,
    imports: [CommonModule, MatDatepickerModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule, FormsModule, MatDialogModule, MatButtonModule],
    templateUrl: './maintenance-service-reschedule-appointment.component.html',
    styleUrl: './maintenance-service-reschedule-appointment.component.scss'
})
export class MaintenanceServiceRescheduleAppointmentComponent {

    notifyCustomer: boolean;
    scheduledStartWindow: string;
    scheduledEndWindow: string;
    date: Date;

    constructor(public dialogRef: MatDialogRef<MaintenanceServiceRescheduleAppointmentComponent>,
        @Inject(MAT_DIALOG_DATA) public value: any) {

            this.date = value.date;
            this.scheduledStartWindow = value.scheduledStartWindow;
            this.scheduledEndWindow = value.scheduledEndWindow;
    }

    get canSave() {
        return this.date && this.scheduledStartWindow && this.scheduledEndWindow;
    }

    get isMorning() {
        return this.scheduledStartWindow === '8 AM' && this.scheduledEndWindow === '12 PM';
    }

    setMorning() {
        this.scheduledStartWindow = '8 AM';
        this.scheduledEndWindow = '12 PM';
    }

    get isMidDay() {
        return this.scheduledStartWindow === '10 AM' && this.scheduledEndWindow === '2 PM';
    }

    setMidDay() {
        this.scheduledStartWindow = '10 AM';
        this.scheduledEndWindow = '2 PM';
    }

    get isAfternoon() {
        return this.scheduledStartWindow === '12 PM' && this.scheduledEndWindow === '5 PM';
    }

    setAfternoon() {
        this.scheduledStartWindow = '12 PM';
        this.scheduledEndWindow = '5 PM';
    }

    get isAllDay() {
        return this.scheduledStartWindow === '8 AM' && this.scheduledEndWindow === '5 PM';
    }

    setAllDay() {
        this.scheduledStartWindow = '8 AM';
        this.scheduledEndWindow = '5 PM';
    }





    save() {
        this.dialogRef.close({ newDate: this.date, notifyCustomer: this.notifyCustomer, scheduledStartWindow: this.scheduledStartWindow, scheduledEndWindow: this.scheduledEndWindow });
    }
}
