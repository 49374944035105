import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceContractorRegionSummary {
    @keyColumn() id: string;
    @column() contractorRegionId: string;
    @column() maintenanceServiceContractorId: string;
    @column() contractorName: string;
    @column() contractorRegionName: string;
    @column() maintenanceServiceName: string;
    @column() contractorId: string;
    @column() maintenanceServiceId: string;
    @column() contractorRegionParentId: string;
    @column() contractorRegionGrandParentId: string;
    @column() email: string;
    @column() officeNumber: string;
}