<div aflex *ngIf="!loading">
    <div class="left-pane-fixed" style="width: 250px" nogrow>

        <div class="data-label">Trade</div>
        <button mat-button block *ngFor="let trade of contractorTrades" class="toggle-button"
            [class.selected]="selectedContractorTrade === trade" (click)="selectTrade(trade)">
            {{trade.tradeName}}
        </button>
    </div>
    <div *ngIf="selectedContractorTrade">
        <div aflex topmargin20>
            <div>
                <h2 class="sub-heading">{{selectedContractorTrade.tradeName}}</h2>
            </div>
            <div nogrow>
                <button style="margin-bottom: 5px;margin-right: 15px;" large mat-button mat-raised-button color="primary" (click)="save()" [disabled]="saving">
                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner> Save
                </button>
            </div>
        </div>
        <div aflex>
            <div nogrow style="    max-height: calc(100vh - 140px);
            padding-right: 20px;
            overflow-x: hidden;
            overflow-y: auto;">

                <button mat-button block mat-raised-button (click)="addPostalCodes()">
                    <mat-icon>add</mat-icon> Add 
                </button>
                <ul>
                    <li *ngFor="let zipCode of selectedContractorTrade.zipCodes">
                        {{zipCode.zip}}
                        <button mat-button (click)="removeZip(zipCode)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </li>
                </ul>
            </div>
        
            <div style="margin-left: 0;">
        
                <div  style="height: calc(100vh - 140px);">
                    <app-zip-code-selector-2 [allStates]="states" [allCounties]="counties" [allZipCodes]="selectedContractorTrade.zipCodes"
                        [zipCodes]="selectedContractorTrade.zipCodes" [drawStates]="false" [drawCounties]="false" [hideSelector]="true"
                        [selectedZipCodes]="selectedContractorTrade.availableZipCodes">
                    </app-zip-code-selector-2>
                </div>
        
            </div>
        </div>
    </div>

</div>

<div *ngIf="loading" style="text-align: center;margin-top: 80px">
    <mat-spinner style="display: inline-block;"></mat-spinner>
</div>