import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IndexedDbWrapperService {

    constructor() { }

    private getDb(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const dbOpenRequest = window.indexedDB.open('local-db', 5);
            dbOpenRequest.onupgradeneeded = (e: any) => {
                const db = e.target.result;

                db.createObjectStore('object-store', { keyPath: 'id' });
            };

            dbOpenRequest.onsuccess = e => {
                resolve(dbOpenRequest.result);
            }
        });
    }

    async delete(key: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const db = await this.getDb();
            const transaction = db.transaction(['object-store'], 'readwrite');
            transaction.objectStore('object-store').delete(key);
            transaction.oncomplete = e => {
                resolve(true);
            };

            transaction.onerror = err => {
                reject(err);
            }
        });
    }

    async get(key: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const db = await this.getDb();
            const transaction = db.transaction(['object-store'], 'readonly');
            const objectStore = transaction.objectStore('object-store');

            const request = objectStore.get(key);
            request.onsuccess = e => {
                resolve(request.result);
            };
            request.onerror = e => {
                reject(e);
            }
        });
    }

    getAll(): Promise<any[]> {
        return new Promise(async (resolve, reject) => {
            const db = await this.getDb();
            const transaction = db.transaction(['object-store'], 'readonly');
            const objectStore = transaction.objectStore('object-store');
            const request = objectStore.getAll();
            request.onsuccess = e => {
                resolve(request.result);
            };
            request.onerror = e => {
                reject(e);
            };
        });
    }

    put(item: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const db = await this.getDb();
            const transaction = db.transaction(['object-store'], 'readwrite');
            const objectStore = transaction.objectStore('object-store');
            const request = objectStore.put(item);
            request.onsuccess = e => {
                resolve(true);
            }
            request.onerror = e => {
                reject(e);
            }
        });
    }

}
