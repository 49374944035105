import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PhoneNumber {
    @keyColumn() id: string;
    @column() number: string;
    @column() type: string;
    @column() entityId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;

    constructor(number = '', type = '', id = '', entityId: string = null) {
        this.number = number;
        this.type = type;
        this.id = id;
        this.entityId = entityId;
    }

    get numberTypeEnum() {
        if (this.type === 'Mobile') {
            return NumberType.Mobile;
        } else if (this.type === 'Home') {
            return NumberType.Home;
        }
        if (this.type === 'Work') {
            return NumberType.Work;
        } else if (this.type === 'Fax') {
            return NumberType.Fax;
        }
    }
}

export enum NumberType {
    Home = 0,
    Work = 1,
    Mobile = 2,
    Fax = 3
}