import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { NoteAttachmentModel } from '@cogent/client/shared/models/notes/note-attachment.model';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {

    id = UtilitiesService.newid();

    @Input() files: NoteAttachmentModel[];
    @Input() mimeType: string;
    @Output() filesChange: EventEmitter<NoteAttachmentModel[]> = new EventEmitter();
    dragTargetActive = false;
    private interval;

    showFileUpload() {
        document.getElementById(this.id).click();
    }

    handleFileUpload(evt) {
        this.handleFiles(evt);
    }

    removeFile(file: NoteAttachmentModel) {
        this.files.splice(this.files.indexOf(file), 1);
        this.filesChange.emit(this.files);
    }

    drop(e) {
        e.stopPropagation();
        e.preventDefault();

        const dt = e.dataTransfer;
        const files = dt.files;
        this.handleFiles(files);
        this.dragTargetActive = false;
    }

    dragEnter(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    dragover(e) {
        e.stopPropagation();
        e.preventDefault();
        this.dragTargetActive = true;
        clearInterval(this.interval);
    }

    dragleave(e) {
        e.stopPropagation();
        e.preventDefault();
        clearInterval(this.interval);
        this.interval = setTimeout(() => { this.dragTargetActive = false; }, 500);
    }


    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (const file of files) {
            const reader = new FileReader();

            const loadIt = async (base64, fileName) => {
                const newFile = new NoteAttachmentModel(fileName,null, base64);
                if(!this.files) {
                    this.files = [];
                }
                this.files.push(newFile);
                this.filesChange.emit(this.files);
            };

            reader.onload = ((fileName) => {
                return (e) => {
                    this.dragTargetActive = false;
                    loadIt(e.target.result, fileName);

                };
            })(file.name);

            reader.readAsDataURL(file);
        }
    }
}

// export class UploadedFile {
//     // fileName: string;
//     // fileContents: string;
//     constructor(fileName: string = null, fileContents: string = null) {

//     }
// }
