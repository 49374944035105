import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ConstantContact {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() clientId: string;
    @column() clientSecret: string;
    @column() scope: string;
    @column() redirectUrl: string;
    @column() authorizeEndPoint: string;
    @column() tokenEndPoint: string;
    @column() accessToken: string;
    @column() refreshToken: string;
    @column() apiEndPoint: string;
}