<div id="outer-content">
    <div class="wizard-header" *ngIf="selectedIndex != COMPLETE_STEP">
        <div class="container" aflex>
            <div>


                <h1 class="page-title sans" [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === ITEM_SELECTION_STEP">
                    <span class="big">Item</span>
                    <br>
                    <span class="little">Selection</span>
                </h1>

                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === INITIAL_PICTURES_STEP">
                    <span class="big">General</span><br>
                    <span class="little">Photos</span>
                </h1>

                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === FAILED_PART_STEP">
                    <span class="big">Failed</span><br>
                    <span class="little">Part</span>
                </h1>
                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === PROBLEM_CAUSE_STEP">
                    <span class="big">Problem</span><br>
                    <span class="little">Cause</span>
                </h1>
                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === UNIT_LOCATION_STEP">
                    <span class="big">Unit</span><br>
                    <span class="little">Location</span>
                </h1>
                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === UNIT_TYPE_STEP">
                    <span class="big">Unit</span><br>
                    <span class="little">Type</span>
                </h1>

                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === PRE_EXISTING_CONDITIONS_STEP">
                    <span class="big">Pre-existing</span><br>
                    <span class="little">Conditions</span>
                </h1>

                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === UNDER_WARRANTY_STEP">
                    <span class="big">Under</span><br>
                    <span class="little">Warranty</span>
                </h1>
                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === REPAIR_REPLACE_STEP">
                    <span class="big">Repair</span><br>
                    <span class="little">Replace</span>
                </h1>
                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === ADDITIONAL_PARTS_STEP">
                    <span class="big">Additional</span><br>
                    <span class="little">Parts</span>
                </h1>
                <h1 class="page-title sans " [class.fade-in-up]="movingForward" [class.fade-in-down]="!movingForward"
                    *ngIf="selectedIndex === REVIEW_STEP">
                    <span class="big">Autho</span><br>
                    <span class="little">Review</span>
                </h1>
            </div>
            <div nogrow>
                <button mat-button (click)="back()" *ngIf="selectedIndex > ITEM_SELECTION_STEP">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button (click)="next()" [disabled]="!hasPicturesTaken" mat-button mat-raised-button color="primary"
                    *ngIf="selectedIndex === INITIAL_PICTURES_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="authoArgs.partsToOrder.length === 0  " mat-button mat-raised-button
                    color="primary" *ngIf="selectedIndex === FAILED_PART_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="!authoArgs.causeId" mat-button mat-raised-button color="primary"
                    *ngIf="selectedIndex === PROBLEM_CAUSE_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="!authoArgs.locationId" mat-button mat-raised-button color="primary"
                    *ngIf="selectedIndex === UNIT_LOCATION_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="!authoArgs.itemTypeId" mat-button mat-raised-button color="primary"
                    *ngIf="selectedIndex === UNIT_TYPE_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="!authoArgs.preExistingConditions" mat-button mat-raised-button
                    color="primary" *ngIf="selectedIndex === PRE_EXISTING_CONDITIONS_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="!authoArgs.coveredByMfgWarranty" mat-button mat-raised-button
                    color="primary" *ngIf="selectedIndex === UNDER_WARRANTY_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" [disabled]="!authoArgs.recommendation" mat-button mat-raised-button
                    color="primary" *ngIf="selectedIndex === REPAIR_REPLACE_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
                <button (click)="next()" mat-button mat-raised-button color="primary"
                    *ngIf="selectedIndex === ADDITIONAL_PARTS_STEP">
                    <div aflex>
                        <span>Next</span>
                        <mat-icon nogrow>chevron_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="container top-level-container" [class.last-step]="selectedIndex === COMPLETE_STEP">
        <div style="text-align: right;">
            <button mat-button class="toggle-button" [class.selected]="showWorkOrderSummary"
                (click)="showWorkOrderSummary = !showWorkOrderSummary">
                Details <mat-icon class="rotate" [class.rotated]="showWorkOrderSummary">expand_more</mat-icon>
            </button>
        </div>
        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onStepChange()">
            <mat-tab label="First">

                <div class="center">
                    <p class="info">Which Item Is Being Authorized?</p>
                    <div class="line-container" *ngIf="workOrderSummary">
                        <button mat-button class="line-selection-button" (click)="selectLine(line)"
                            *ngFor="let line of workOrderSummary.lines">

                            <img [src]="line.itemUrl"><br>
                            {{line.itemName}}
                        </button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="center">


                    <div style="display: table;margin: 0 auto">
                        <div class="picture-progress-container">
                            <div [class.current-step]="showModelPlate || analyzingModelPlate || selectModelNumber">
                                <img (click)="goToModelPlate()" *ngIf="!pictureOfModelPlate"
                                    src="../../../assets/images/model-plate.png">
                                <img (click)="resetModelPlate()" *ngIf="pictureOfModelPlate"
                                    [src]="pictureOfModelPlate.base64">
                                <br>
                                Model Plate
                                <mat-icon *ngIf="pictureOfModelPlate">check</mat-icon>
                            </div>
                            <div [class.current-step]="showTakePictureOfFront">
                                <img (click)="goToPictureOfFront()" *ngIf="!pictureOfUnitFront"
                                    src="../../../assets/images/appliance-front.png">
                                <img (click)="resetFrontOfUnit()" *ngIf="pictureOfUnitFront"
                                    [src]="pictureOfUnitFront.base64">

                                <br>
                                Front View
                                <mat-icon *ngIf="pictureOfUnitFront">check</mat-icon>
                            </div>
                            <div [class.current-step]="showTakePictureOfControls">
                                <img (click)="goToPictureOfControls()" *ngIf="!pictureOfUnitControls"
                                    src="../../../assets/images/appliance-controls.png">
                                <img (click)="resetUnitControls()" *ngIf="pictureOfUnitControls"
                                    [src]="pictureOfUnitControls.base64">
                                <br>
                                Controls
                                <mat-icon *ngIf="pictureOfUnitControls">check</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div topmargin40 *ngIf="showModelPlate" (drop)="imageDrop($event, modelPlatePhotos)"
                        (dragenter)="imageDragEnter($event)" (dragover)="imageDragover($event)"
                        (dragleave)="imageDragleave($event)" [class.drag-hover]="dragTargetActive">
                        <p class="info" *ngIf="showModelPlate">Please take a picture of the model plate</p>
                        <app-authorization-photos #modelPlatePhotos [hideList]="false" imagePrefix="modelplate"
                            (attachmentsChange)="modelPlateAdded($event)" [(attachments)]="authoArgs.photos">
                        </app-authorization-photos>

                        <div topmargin40>
                            <button mat-button (click)="showTakePictureOfFront = true;showModelPlate = false;">
                                Skip <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="analyzingModelPlate" topmargin40>
                        <mat-spinner style="display: inline-block"></mat-spinner>
                        <p class="info">Let's see if we can read that model number.</p>
                    </div>
                    <div *ngIf="selectModelNumber" topmargin40 class="fade-in-up">
                        <div *ngIf="analysisResult.modelNumber">
                            <p class="info">We might have found the model number. Is it correct?</p>
                            <h2 class="sub-heading" bottommargin20>{{analysisResult.modelNumber}}</h2>

                            <button mat-button mat-raised-button (click)="confirmModelNumber()">Yes</button>&nbsp;
                            <button mat-button mat-raised-button (click)="rejectModelNumber()">No</button>
                        </div>
                        <div *ngIf="!analysisResult.modelNumber">
                            <p class="info">We couldn't find the model number, but maybe it is one of these:</p>


                            <div class="probable-container">
                                <button mat-button mat-raised-button *ngIf="analysisResult.calculatedModelNumber"
                                    (click)="setModelNumberFromLine(analysisResult.calculatedModelNumber)">
                                    {{analysisResult.calculatedModelNumber}}
                                </button>
                                <button mat-button mat-raised-button *ngFor="let line of probableModelNumbers"
                                    (click)="setModelNumberFromLine(line)">{{line}}</button>

                                <button mat-button mat-raised-button (click)="setModelNumberFromLine('')">Skip
                                    <mat-icon>chevron_right</mat-icon>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="showTakePictureOfFront" (drop)="imageDrop($event, frontPhotos)"
                        (dragenter)="imageDragEnter($event)" (dragover)="imageDragover($event)"
                        (dragleave)="imageDragleave($event)" [class.drag-hover]="dragTargetActive" topmargin40
                        class="fade-in-up">

                        <p class="info">Please take a picture of the front of the unit</p>
                        <app-authorization-photos #frontPhotos imagePrefix="unitfront" [hideList]="true"
                            (attachmentsChange)="unitPhotoAdded($event)" [(attachments)]="authoArgs.photos">
                        </app-authorization-photos>
                        <div topmargin40>
                            <button mat-button
                                (click)="showTakePictureOfControls = true;showTakePictureOfFront = false">
                                Skip <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="showTakePictureOfControls" (drop)="imageDrop($event, unitControlsPhotos)"
                        (dragenter)="imageDragEnter($event)" (dragover)="imageDragover($event)"
                        (dragleave)="imageDragleave($event)" [class.drag-hover]="dragTargetActive" topmargin40
                        class="fade-in-up">

                        <p class="info">Please take a close up picture of the unit controls</p>
                        <app-authorization-photos #unitControlsPhotos [hideList]="true" imagePrefix="unitcontrols"
                            (attachmentsChange)="controlsPhotoAdded($event)" [(attachments)]="authoArgs.photos">
                        </app-authorization-photos>
                        <div topmargin40>
                            <button mat-button (click)="next()">
                                Skip <mat-icon>chevron_right</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Second">
                <div class="center">
                    <div class="info">What is the part that failed?</div>
                    <div class="part-search-container">
                        <div
                            *ngIf="authoArgs.partsToOrder.length === 0  && !selectedPartCategory && !loadingPartDetail && !selectedModel && !showVariations">
                            <div style="width: 100%; max-width: 350px; display: table; margin: 0 auto">
                                <app-appliance-part-search [(selectedItem)]="foundPart" #firstApplianceSearch
                                    (noMatchChange)="setFirstNotMatch($event)" [(noMatch)]="noMatch"
                                    (selectedItemChange)="selectedItemChange($event)">
                                </app-appliance-part-search>

                                <div topmargin40>
                                    <button mat-button (click)="next()">
                                        Skip <mat-icon>chevron_right</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <div *ngIf="analysisResult && analysisResult.modelNumber">

                                <button style="line-height: 19px;padding-top: 10px;padding-bottom: 10px;" mat-button
                                    mat-raised-button (click)="findModel()">Find Model
                                    <br>
                                    {{analysisResult.modelNumber}}
                                </button>
                            </div>
                        </div>

                        <mat-spinner style="display: inline-block;" *ngIf="loadingPartDetail"></mat-spinner>
                        <div *ngIf="showVariations" class="fade-in-up">
                            <div>This model has multiple variations. Here is an example of how to locate your factory
                                suffix.</div>
                            <img style="max-width: calc(100vw - 50px)"
                                src="../../../assets/images/find-variation-example.jpg">

                            <div topmargin30 class="info">Please choose your factory suffix to get the correct list of
                                parts for
                                model {{lastPartName}}</div>

                            <ul class="variation-list">
                                <li *ngFor="let variation of variations">
                                    <button mat-button (click)="getModelDetailByVariations(variation)">
                                        {{lastPartName}} - {{variation}}
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div class="center fade-in-up" *ngIf="authoArgs.partsToOrder.length > 0">
                            <img style="height: 80px;"
                                *ngIf="!authoArgs.partsToOrder[0].images || authoArgs.partsToOrder[0].images.length === 0"
                                src="../../../assets/images/generic-part.svg">
                            <mat-tab-group class="hide-tabs" [(selectedIndex)]="imageSelectedIndex">

                                <mat-tab *ngFor="let image of authoArgs.partsToOrder[0].images">
                                    <div class="center">
                                        <a [href]="image" target="_blank">
                                            <img [src]="image" style="max-height: 200px">
                                        </a>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>

                            <div class="center img-selector-btns">
                                <button *ngFor="let img of authoArgs.partsToOrder[0].images; let i = index"
                                    class="toggle-button" [class.selected]="i === imageSelectedIndex"
                                    (click)="imageSelectedIndex = i" mat-button></button>
                            </div>

                            <h1 topmargin30>{{authoArgs.partsToOrder[0].partNumber}}</h1>
                            <p>{{authoArgs.partsToOrder[0].description}}</p>
                            <div aflex>
                                <div>
                                    <div class="data-label">Manufacturer</div>
                                    {{authoArgs.partsToOrder[0].manufacturer}}
                                </div>

                            </div>

                            <div *ngIf="authoArgs.partsToOrder[0].notCovered" class="not-covered-label">
                                Part Not Covered
                            </div>

                            <div topmargin40>
                                <button mat-button mat-raised-button (click)="authoArgs.partsToOrder = []">
                                    <mat-icon>restart_alt</mat-icon> Restart
                                </button>
                            </div>

                        </div>

                        <ng-container
                            *ngIf="selectedModel && !loadingPartDetail && authoArgs.partsToOrder.length === 0">

                            <div class="container" style="overflow: hidden;">
                                <div class="navigation-features">
                                    <div>
                                        <div class="data-label">Model #</div>
                                        <h2 class="sub-heading">{{selectedModel.manufacturer}}
                                            {{selectedModel.modelNumber}}
                                        </h2>

                                        <button mat-button (click)="changeModel()">
                                            <mat-icon>restart</mat-icon> Change Model
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectedModel.diagrams.length > 0" class="part-categories-container"
                                [class.collapsed]="selectedPartCategory">
                                <button mat-button class="part-category-button toggle-button" style="min-height: 100px;"
                                    [class.selected]="allPartsVisible" (click)="showAllParts1()">
                                    <mat-icon>search</mat-icon><br>
                                    Show All Parts
                                </button>
                                <button *ngFor="let partCategory of selectedModel.diagrams" mat-button
                                    class="part-category-button toggle-button" style="text-transform: none;"
                                    [class.selected]="selectedPartCategory == partCategory"
                                    (click)="showCategory(partCategory)">
                                    <img src="{{partCategory.urlDiagram}}"><br>
                                    {{partCategory.diagramName}}</button>

                            </div>

                            <div *ngIf="selectedPartCategory">
                                <div *ngIf="diagramDetails">
                                    <div class="center">
                                        <button style="line-height: 26px;" mat-button class="toggle-button"
                                            (click)="showAllParts = false" [class.selected]="!showAllParts">
                                            <mat-icon>account_tree</mat-icon>
                                            <br>
                                            Diagram
                                        </button>
                                        <button mat-button style="line-height: 26px;" class="toggle-button"
                                            (click)="showAllParts = true" [class.selected]="showAllParts">
                                            <mat-icon>list</mat-icon><br>
                                            List
                                        </button>
                                    </div>
                                    <div class="diagram-viewer-container fade-in-up" *ngIf="!showAllParts">
                                        <img [src]="selectedPartCategory.urlDiagram" usemap="#assemblyMap"
                                            [class.fit-height]="diagramSizeMode === 'height'"
                                            [class.fit-width]="diagramSizeMode === 'width'">
                                        <map name="assemblyMap" *ngIf="hotspot">
                                            <area class="part" href="#" (click)="spotClicked(spot, $event)"
                                                *ngFor="let spot of hotspot" shape="rect"
                                                coords="{{spot.TopLeft.X}},{{spot.TopLeft.Y}},{{spot.BottomRight.X}},{{spot.BottomRight.Y}}">
                                        </map>
                                    </div>

                                    <div *ngIf="showAllParts" class="fade-in-up">
                                        <mat-form-field style="max-width: 300px" appearance="outline">
                                            <mat-label><mat-icon>filter_alt</mat-icon> Filter</mat-label>
                                            <input matInput [(ngModel)]="partFilter">
                                        </mat-form-field>

                                        <div class=" part-category-parts-container " *ngIf="showAllParts" topmargin40>
                                            <button mat-button class="toggle-button" style="position: relative"
                                                [class.selected]="part.selected"
                                                (click)="spotClicked2(null, null, part)"
                                                *ngFor="let part of filteredDiagramDetails; let i = index">
                                                <span class="badge part-number" *ngIf="!useEncompass">{{i + 1}}</span>
                                                <span class="badge part-number" *ngIf="useEncompass">{{part.tag}}</span>
                                                <div class="center">
                                                    <img *ngIf="part.imageUrls && part.imageUrls.length > 0"
                                                        [src]="part.imageUrls[0]" class="part-image">
                                                    <img *ngIf="!part.imageUrls || part.imageUrls.length === 0 "
                                                        class="part-image" src="../../../assets/images/no-image.png">
                                                    <div>
                                                        <h2 style="white-space: normal;">{{part.productNumber}}</h2>
                                                        <p style="white-space: normal;line-height: normal;">
                                                            {{part.description}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="allPartsVisible && !loadingPartDetail && authoArgs.partsToOrder.length === 0"
                        class="fade-in-up">
                        <mat-form-field style="max-width: 300px" appearance="outline">
                            <mat-label>Filter</mat-label>
                            <input matInput [(ngModel)]="allPartsFilter">
                        </mat-form-field>
                        <div class=" part-category-parts-container " topmargin40>
                            <button mat-button class="toggle-button" style="position: relative"
                                [class.selected]="part.selected" (click)="spotClicked2(null, null, part)"
                                *ngFor="let part of selectedModelAllParts; let i = index">
                                <div class="center">
                                    <img style="max-height: 60px" [src]="part.smallImageURL" *ngIf="part.smallImageURL">
                                    <div>
                                        <h2 style="white-space: normal;">{{part.partNumber}}</h2>
                                        <p style="white-space: normal;line-height: normal;">
                                            {{part.partDescription}}
                                        </p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Third">
                <div class="center">
                    <p class="info">What was the cause of the failure?</p>

                    <div style="display: table;margin: 0 auto">
                        <div topmargin40 class="button-grid" [class.one-column]="causes && causes.length == 1"
                            [class.two-column]="causes && causes.length == 2 "
                            [class.three-column]="causes && causes.length == 3"
                            [class.four-column]="causes && causes.length == 4">
                            <button mat-button mat-raised-button (click)="authoArgs.causeId = cause.id"
                                *ngFor="let cause of causes" class="toggle-button offset"
                                [class.selected]="cause.id === authoArgs.causeId">
                                {{cause.name}}
                            </button>
                        </div>
                    </div>

                    <div *ngIf="notFirstCause" topmargin40 class="fade-in-up">
                        <p class="info">Please provide any relevant photos.</p>
                        <app-authorization-photos imagePrefix="cause" [(attachments)]="authoArgs.photos">
                        </app-authorization-photos>

                        <mat-form-field appearance="outline"
                            style="display: inline-block !important;margin-top: 30px;max-width: 500px">
                            <mat-label>Describe</mat-label>
                            <textarea matInput [(ngModel)]="authoArgs.causeDescription" rows="3"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <div class="center">
                    <p class="info">Where is the unit located</p>
                    <div style="display: table;margin: 0 auto">
                        <div topmargin40 class="button-grid"
                            [class.one-column]="itemLocations && itemLocations.length == 1"
                            [class.two-column]="itemLocations && itemLocations.length == 2 "
                            [class.three-column]="itemLocations && itemLocations.length == 3"
                            [class.four-column]="itemLocations && itemLocations.length == 4">
                            <button mat-button mat-raised-button (click)="authoArgs.locationId = location.id"
                                *ngFor="let location of itemLocations" class="toggle-button offset"
                                [class.selected]="location.id === authoArgs.locationId">
                                {{location.name}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="center">
                    <p class="info">What is the item type</p>
                    <div style="display: table;margin: 0 auto">
                        <div topmargin40 class="button-grid" [class.one-column]="itemTypes && itemTypes.length == 1"
                            [class.two-column]="itemTypes && itemTypes.length == 2 "
                            [class.three-column]="itemTypes && itemTypes.length == 3"
                            [class.four-column]="itemTypes && itemTypes.length == 4">
                            <button mat-button mat-raised-button (click)="authoArgs.itemTypeId = type.id"
                                *ngFor="let type of itemTypes" class="toggle-button offset"
                                [class.selected]="type.id === authoArgs.itemTypeId">
                                {{type.name}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="center" *ngIf="workOrderSummary">
                    <div class="info" bottommargin20>Did this problem predate
                        {{workOrderSummary.effectiveDate |
                        date:
                        'shortDate'}}</div>

                    <button mat-button mat-raised-button class="toggle-button offset"
                        [class.selected]="authoArgs.preExistingConditions === 'No'"
                        (click)="authoArgs.preExistingConditions = 'No'">
                        No
                    </button>
                    <button style="margin-left: 20px;margin-right: 20px;" mat-button mat-raised-button
                        class="toggle-button offset" [class.selected]="authoArgs.preExistingConditions === 'Yes'"
                        (click)="authoArgs.preExistingConditions = 'Yes'">
                        Yes
                    </button>
                    <button mat-button mat-raised-button class="toggle-button offset"
                        [class.selected]="authoArgs.preExistingConditions === 'Unknown'"
                        (click)="authoArgs.preExistingConditions = 'Unknown'">
                        Unknown
                    </button>

                    <div topmargin40
                        *ngIf="authoArgs.preExistingConditions === 'Unknown' ||  authoArgs.preExistingConditions === 'Yes'">
                        <div class="info" bottommargin10>
                            Please provide any photos that show evidence the problem may have existed before
                            {{workOrderSummary.effectiveDate |
                            date:
                            'shortDate'}}.
                        </div>
                        <app-authorization-photos imagePrefix="preexisting" [(attachments)]="authoArgs.photos">
                        </app-authorization-photos>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Third">
                <div class="center">
                    <p class="info">Is the appliance under manufacturer's warranty?</p>

                    <div topmargin40 class="normal-wear-and-tear">
                        <button mat-button mat-raised-button class="toggle-button offset"
                            [class.selected]="authoArgs.coveredByMfgWarranty === 'No'"
                            (click)="setCoveredByMfgWarranty('No')">
                            <mat-icon>check</mat-icon><br>
                            No
                        </button>
                        <button mat-button mat-raised-button class="toggle-button offset"
                            [class.selected]="authoArgs.coveredByMfgWarranty === 'Yes'"
                            (click)="setCoveredByMfgWarranty('Yes')">
                            <mat-icon>warning</mat-icon><br>
                            Yes
                        </button>
                    </div>

                    <div style="max-width: 300px;width: 100%;display: table;margin: 0 auto;" topmargin40
                        *ngIf="authoArgs.coveredByMfgWarranty === 'Yes' || authoArgs.coveredByMfgWarranty === 'Unknown'">

                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput [(ngModel)]="authoArgs.manufacturer">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Age</mat-label>
                            <input matInput [(ngModel)]="authoArgs.ageOfUnit">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Model #</mat-label>
                            <input matInput [(ngModel)]="authoArgs.modelNumber">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Serial #</mat-label>
                            <input matInput [(ngModel)]="authoArgs.serialNumber">
                        </mat-form-field>
                    </div>


                </div>
            </mat-tab>

            <mat-tab>
                <div class="center">
                    <p class="info">In Your Professional Judgement, can this item be repaired or does it need to be
                        replaced?</p>

                    <div topmargin40 class="normal-wear-and-tear">
                        <button mat-button mat-raised-button class="toggle-button offset"
                            [class.selected]="authoArgs.recommendation === 'Repair'"
                            (click)="authoArgs.recommendation = 'Repair'">
                            <mat-icon>build</mat-icon><br>
                            Repair
                        </button>
                        <button mat-button mat-raised-button class="toggle-button offset"
                            [class.selected]="authoArgs.recommendation === 'Replace'"
                            (click)="authoArgs.recommendation = 'Replace'">
                            <mat-icon>loop</mat-icon><br>
                            Replace
                        </button>
                    </div>

                    <div topmargin40 *ngIf="authoArgs.recommendation === 'Repair'">
                        <div class="info" bottommargin20>Can the repair be completed today?</div>
                        <button mat-button mat-raised-button class="toggle-button offset"
                            [class.selected]="authoArgs.canBeRepairedToday === true"
                            (click)="authoArgs.canBeRepairedToday = true">
                            Yes
                        </button>
                        <button style="margin-left: 20px;margin-right: 20px;" mat-button mat-raised-button
                            class="toggle-button offset" [class.selected]="authoArgs.canBeRepairedToday === false"
                            (click)="authoArgs.canBeRepairedToday = false">
                            No
                        </button>

                        <!-- <div topmargin40 style="max-width: 300px; width: 100%; display: table; margin: 0 auto;">
                        <app-date-picker *ngIf="authoArgs.canBeRepairedToday === false" [max]="maxEstimatedRepairDate"
                            [min]="minEstimatedRepairDate" [(date)]="authoArgs.estimatedRepairDate"
                            placeholder="Estimated Repair Date">
                        </app-date-picker>
                    </div> -->

                    </div>
                    <div topmargin40 *ngIf="authoArgs.recommendation === 'Replace'">


                        <div class="info">Replacement Reason</div>

                        <div style="max-width: 400px; width: 100%; display: table; margin: 0 auto;">
                            <button mat-button mat-raised-button style="margin-bottom: 20px;" block
                                *ngFor="let replacementReason of replacementReasons" class="toggle-button offset"
                                [class.selected]="replacementReason.id === authoArgs.reasonForTheReplacementId"
                                (click)="authoArgs.reasonForTheReplacementId = replacementReason.id">
                                {{replacementReason.name}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <div class="center">
                    <div class="data-label">Authorization Total</div>
                    <h1 class="big-data">{{totalRepair | currency}}</h1>
                    <p class="info" topmargin40>Parts needed for the repair</p>
                    <div class="part-list-container">
                        <div *ngFor="let part of authoArgs.partsToOrder">
                            <div aflex>
                                <div nogrow>
                                    <img nogrow *ngIf="part.images && part.images.length > 0" [src]="part.images[0]">
                                    <img *ngIf="!part.images || part.images.length === 0"
                                        src="../../../assets/images/generic-part.svg">
                                </div>
                                <div>
                                    <h3 style="margin: 0">{{part.partNumber}}</h3>
                                    <div>{{part.description}}</div>
                                    <mat-slide-toggle *ngIf="!part.notCovered"
                                        [(ngModel)]="part.companyProvides">Elevate
                                        Provides
                                        Part</mat-slide-toggle>
                                    <div *ngIf="part.notCovered" class="not-covered-label">Not Covered</div>

                                    <div aflex topmargin10>
                                        <div>
                                            <mat-form-field style="max-width: 120px" appearance="outline"
                                                *ngIf="!part.companyProvides">
                                                <mat-label>Cost</mat-label>
                                                <input matInput type="number" [(ngModel)]="part.cost">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" style="max-width: 120px">
                                                <mat-label>Quantity</mat-label>
                                                <mat-select [(ngModel)]="part.quantity">
                                                    <mat-option *ngFor="let qty of quantities"
                                                        [value]="qty">{{qty}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button mat-button class="remove-btn" (click)="removePart(part)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div class="center">
                            <button mat-button mat-raised-button (click)="addingAdditionalPart = true">
                                <mat-icon>add</mat-icon> Add Part
                            </button>
                        </div>
                    </div>
                    <div topmargin40 *ngIf="authoArgs.laborRate">
                        <p class="info">Enter the number of labor hours:</p>
                        <div style="display: table;margin: 0 auto">
                            <div aflex>
                                <div nogrow>
                                    <button mat-button (click)="lessHours()">-</button>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline"
                                        style="display: inline-block !important; width: 102px !important; margin-left: 10px; margin-right: 10px;">
                                        <mat-label>Hours</mat-label>
                                        <input matInput [(ngModel)]="authoArgs.laborHours" type="number">
                                    </mat-form-field>
                                </div>
                                <div nogrow>
                                    <button mat-button (click)="moreHours()">+</button>
                                </div>
                            </div>
                        </div>
                        =
                        {{authoArgs.calculatedLabor | currency}}
                    </div>
                    <div topmargin40 *ngIf="!authoArgs.laborRate">
                        <mat-form-field appearance="outline" style="display: inline-block;">
                            <mat-label>Labor Total</mat-label>
                            <input matInput [(ngModel)]="authoArgs.laborTotal" type="number">
                        </mat-form-field>
                    </div>

                    <div topmargin40 *ngIf="tripFeeAllowance">
                        <p class="info">Trip Fee Allowance</p>
                        <h2 class="sub-heading">{{tripFeeAllowance | currency}}</h2>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="center">
                    <p class="info">Ready to submit for approval?</p>
                    <div style="display: table;margin: 0 auto;width: 100%; max-width: 400px">
                        <mat-form-field appearance="outline">
                            <mat-label>Your Name</mat-label>
                            <input matInput [(ngModel)]="authoArgs.technicianContact.name">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Your Number</mat-label>
                            <input matInput type="tel" [(ngModel)]="authoArgs.technicianContact.phoneNumber"
                                mask="(000) 000-0000">
                        </mat-form-field>

                        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedNoteIndex">
                            <mat-tab label="First">
                                <div class="notes-container">
                                    <div aflex>
                                        <div class="data-label" style="margin-top: 13px">Additional Notes</div>
                                        <div nogrow>
                                            <button mat-button (click)="showTag()">
                                                <mat-icon>tag</mat-icon> Tag
                                            </button>
                                        </div>
                                    </div>

                                    <app-rich-text-and-snippet #richText height="170px" [mentionables]="mentionables"
                                        [quickNotes]="[]"
                                        [(ngModel)]="authoArgs.additionalNotes"></app-rich-text-and-snippet>
                                </div>


                                <div topmargin40></div>
                                <button mat-button (click)="authorize()" [disabled]="!canAuthorize" mat-raised-button
                                    color="primary" large>
                                    <mat-spinner *ngIf="authorizing" class="thirty"></mat-spinner>
                                    Request Approval
                                </button>
                                <div topmargin20 bottommargin20>
                                    - OR -
                                </div>
                                <button mat-button mat-raised-button (click)="save()">
                                    Save
                                </button>
                            </mat-tab>
                            <mat-tab label="Second">
                                <mat-form-field appearance="outline">
                                    <mat-label>Filter</mat-label>
                                    <input matInput [(ngModel)]="mentionableFilter">
                                </mat-form-field>
                                <ul class="mentionables-list">
                                    <li *ngFor="let entity of filteredMentionables">
                                        <mat-checkbox [(ngModel)]="entity.selected">{{entity.name}}</mat-checkbox>
                                    </li>

                                </ul>
                                <button mat-button mobile-block mat-raised-button (click)="applyTags()" color="primary">
                                    Apply Tags
                                </button>
                            </mat-tab>
                        </mat-tab-group>


                    </div>

                </div>
            </mat-tab>
            <mat-tab>
                <div class="padding center" *ngIf="authorizationResult">
                    <div *ngIf="authorizationResult && !authorizationResult.approved">
                        <app-review-circle-animated height="150px">
                        </app-review-circle-animated>
                        <h1 class="sub-heading" topmargin40>Under Review</h1>
                        <p topmargin10 class="info">Don't worry, wer're on it. We just need to review a few things. We
                            will
                            contact you
                            when we've
                            reached a decision or need more information.</p>
                    </div>
                    <div *ngIf="authorizationResult && authorizationResult.approved">
                        <app-check-circle-animated height="150px"
                            *ngIf="authorizationResult && authorizationResult.approved">
                        </app-check-circle-animated>
                        <h1 class="sub-heading" topmargin40>Approved</h1>
                        <p topmargin10 class="info">Please proceed with the repairs</p>

                        <p *ngIf="orderingParts" class="info">
                            We'll order the parts and send you shipping information.
                        </p>
                    </div>


                    <ul *ngIf="authorizationResult && authorizationResult.warnings" class="autho-validation-list">
                        <li *ngFor="let warning of authorizationResult.warnings">
                            <div aflex>
                                <div nogrow>
                                    <mat-icon>warning</mat-icon>
                                </div>
                                <div>
                                    {{warning.message}}
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div topmargin40>
                        <a mat-button mat-raised-button mobile-block
                            routerLink="/work-order-detail/{{workOrderSummary.id}}" style="margin-bottom: 20px;">Back To
                            Job</a>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>


    <div *ngIf="addingAdditionalPart" class="angle-in add-part-container">
        <div class="center">
            <button mat-button style="height: 64px;border-radius: 50%;border: 1px solid #ccc;margin-top: 5px;"
                (click)="addingAdditionalPart = false">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="container center" style="padding: 0">
            <div class="padding" *ngIf="!selectedModel && !selectedPart && !showVariations" style="display: table;
        margin: 0 auto;
        width: 100%;
        max-width: 400px;">
                <app-appliance-part-search [(selectedItem)]="foundPart2" (noMatchChange)="setFirstNotMatch($event)"
                    [(noMatch)]="noMatch" (selectedItemChange)="selectedItemChange2($event)">
                </app-appliance-part-search>
            </div>
            <mat-spinner style="display: inline-block;" *ngIf="loadingPartDetail"></mat-spinner>

            <div *ngIf="showVariations" class="fade-in-up">
                <div>This model has multiple variations. Here is an example of how to locate your factory suffix.</div>
                <img style="max-width: calc(100vw - 50px)" src="../../../assets/images/find-variation-example.jpg">

                <div topmargin30 class="info">Please choose your factory suffix from the list below, so that we can take
                    you to
                    your specific list of parts for model {{lastPartName}}</div>

                <ul class="variation-list">
                    <li *ngFor="let variation of variations">
                        <button mat-button (click)="getModelDetailByVariations(variation)">
                            {{lastPartName}} - {{variation}}
                        </button>
                    </li>
                </ul>
            </div>

            <div class="center fade-in-up" *ngIf="selectedPart">
                <mat-tab-group class="hide-tabs" [(selectedIndex)]="imageSelectedIndex">
                    <img src="../../../assets/images/generic-part.svg"
                        *ngIf="selectedPart.specDetails.images && selectedPart.specDetails.images.length === 0">
                    <mat-tab *ngFor="let image of selectedPart.specDetails.images">
                        <div class="center">
                            <a [href]="image" target="_blank">
                                <img [src]="image" style="max-height: 200px">
                            </a>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="center img-selector-btns">
                    <button *ngFor="let img of selectedPart.specDetails.images; let i = index" class="toggle-button"
                        [class.selected]="i === imageSelectedIndex" (click)="imageSelectedIndex = i"
                        mat-button></button>
                </div>

                <h1 topmargin30>{{selectedPart.specDetails.productNumber}}</h1>
                <p>{{selectedPart.specDetails.description}}</p>
                <div aflex>
                    <div>
                        <div class="data-label">Manufacturer</div>
                        {{selectedPart.specDetails.manufacturer}}
                    </div>
                </div>

                <div topmargin40>
                    <button mat-button mat-raised-button (click)="addSelectedPart()" color="primary">
                        <mat-icon>add</mat-icon> Add Part
                    </button>
                    <button mat-button (click)="selectedPart = null">
                        <mat-icon>restart_alt</mat-icon> Restart
                    </button>
                </div>
            </div>

            <ng-container *ngIf="selectedModel && !selectedPart">
                <div class="container" style="overflow: hidden;">
                    <div class="navigation-features">
                        <div aflex>
                            <div nogrow>
                                <div class="data-label">Model #</div>
                                <h2 class="sub-heading">{{selectedModel.manufacturer}} {{selectedModel.modelNumber}}
                                </h2>
                            </div>
                            <div style="text-align: left;">

                                <button mat-button (click)="selectedModel = null">
                                    <mat-icon>restart_alt</mat-icon> Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="part-categories-container-2" [class.collapsed]="selectedPartCategory">
                    <button *ngFor="let partCategory of selectedModel.diagrams" mat-button
                        class="part-category-button toggle-button" style="text-transform: none;"
                        [class.selected]="selectedPartCategory == partCategory" (click)="showCategory(partCategory)">
                        <img src="{{partCategory.urlDiagram}}"><br>
                        {{partCategory.diagramName}}</button>

                    <button mat-button style="min-height: 100px;" class="part-category-button toggle-button"
                        [class.selected]="allPartsVisible" (click)="showAllParts1()">
                        <mat-icon>search</mat-icon><br>
                        Show All Parts
                    </button>
                </div>
                <div *ngIf="selectedPartCategory">
                    <div *ngIf="diagramDetails">
                        <div class="center">
                            <button style="line-height: 26px;" mat-button class="toggle-button"
                                (click)="showAllParts = false" [class.selected]="!showAllParts">
                                <mat-icon>account_tree</mat-icon>
                                <br>
                                Diagram
                            </button>
                            <button mat-button style="line-height: 26px;" class="toggle-button"
                                (click)="showAllParts = true" [class.selected]="showAllParts">
                                <mat-icon>list</mat-icon><br>
                                List
                            </button>
                        </div>
                        <div class="diagram-viewer-container-2 " *ngIf="!showAllParts">
                            <img [src]="selectedPartCategory.urlDiagram" usemap="#assemblyMap"
                                [class.fit-height]="diagramSizeMode === 'height'"
                                [class.fit-width]="diagramSizeMode === 'width'">
                            <map name="assemblyMap" *ngIf="hotspot">
                                <area class="part" href="#" (click)="spotClicked2(spot, $event)"
                                    *ngFor="let spot of hotspot" shape="rect"
                                    coords="{{spot.TopLeft.X}},{{spot.TopLeft.Y}},{{spot.BottomRight.X}},{{spot.BottomRight.Y}}">
                            </map>
                        </div>
                        <div *ngIf="showAllParts" class="fade-in-up">
                            <mat-form-field style="max-width: 300px" appearance="outline">
                                <mat-label><mat-icon>filter_alt</mat-icon> Filter</mat-label>
                                <input matInput [(ngModel)]="partFilter">
                            </mat-form-field>

                            <div class=" part-category-parts-container " *ngIf="showAllParts" topmargin40>
                                <button mat-button class="toggle-button" style="position: relative"
                                    [class.selected]="part.selected" (click)="spotClicked2(null, null, part)"
                                    *ngFor="let part of filteredDiagramDetails; let i = index">
                                    <span class="badge part-number" *ngIf="!useEncompass">{{i + 1}}</span>
                                    <span class="badge part-number" *ngIf="useEncompass">{{part.tag}}</span>
                                    <div class="center">
                                        <img *ngIf="part.imageUrls && part.imageUrls.length > 0"
                                            [src]="part.imageUrls[0]" class="part-image">
                                        <img *ngIf="!part.imageUrls || part.imageUrls.length === 0 " class="part-image"
                                            src="../../../assets/images/no-image.png">
                                        <div>
                                            <h2 style="white-space: normal;">{{part.productNumber}}</h2>
                                            <p style="white-space: normal;line-height: normal;">
                                                {{part.description}}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="allPartsVisible && !loadingPartDetail " class="fade-in-up">
                    <mat-form-field style="max-width: 300px" appearance="outline">
                        <mat-label>Filter</mat-label>
                        <input matInput [(ngModel)]="allPartsFilter">
                    </mat-form-field>
                    <div class=" part-category-parts-container " topmargin40>
                        <button mat-button class="toggle-button" style="position: relative"
                            [class.selected]="part.selected" (click)="spotClicked2(null, null, part)"
                            *ngFor="let part of selectedModelAllParts; let i = index">
                            <div class="center">
                                <img style="max-height: 60px" [src]="part.smallImageURL" *ngIf="part.smallImageURL">
                                <div>
                                    <h2 style="white-space: normal;">{{part.partNumber}}</h2>
                                    <p style="white-space: normal;line-height: normal;">
                                        {{part.partDescription}}
                                    </p>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="showWorkOrderSummary" id="object-detail-panel">
        <button mat-button (click)="showWorkOrderSummary = false" class="close-detail-button">
            <mat-icon>close</mat-icon>
        </button>

        <div style="margin-top: -56px; padding: 10px;">
            <my-claim-summary [noCards]="true" [noCollapse]="true" [firstEffectiveDate]="firstEffectiveDate"
                [workOrderSummary]="workOrderSummary">
            </my-claim-summary>
        </div>
    </div>
</div>