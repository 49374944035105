import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PolicyOption {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() planItemId: string;
    @column() price: number;
    @column() priceRecurring: number;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;




    salesItemName: string;


    // Client Only
    name: string;
    quantity: number;
    total: number;
    totalRecurring: number;
    salesItemId: string;

    constructor(id: string = null, policyId: string = null, planItemId: string = null, price = 0, priceRecurring = 0) {
        this.id = id;
        this.policyId = policyId;
        this.planItemId = planItemId;
        this.price = price;
        this.priceRecurring = priceRecurring;
    }
}