import { Component, OnInit, Inject } from '@angular/core';
import { TaskApiService } from '@cogent/client/shared/services/api/task-api.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AccountingApiService } from '@cogent/client/shared/services/api/accounting-api.service';
import { Tag } from '@upkeeplabs/models/cogent';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { PurchasingApiService } from '@cogent/client/shared/services/api/purchasing-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TagChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/tag-chip-selection/tag-chip-selection.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

export class EditObjectTagsDialogData {
    tags: Tag[];
    objectType: string;
    objectId: string;
    inMemoryOnly = false;
}

@Component({
    selector: 'app-edit-object-tags',
    templateUrl: './edit-object-tags.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DragDropModule,
        MatDialogModule,
        TagChipSelectionModule,
        MatButtonModule, 
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    styleUrls: ['./edit-object-tags.component.css']
})
export class EditObjectTagsComponent implements OnInit {

    saving = false;
    tags: Tag[];

    constructor(private taskApi: TaskApiService,
        private serviceApi: ServiceApiService,
        private policyApi: PolicyApiService,
        private entityApi: EntityApiService,
        private accountingApi: AccountingApiService,
        private maintApi: MaintenanceServiceApiService,
        private purchasingApi: PurchasingApiService,
        public dialogRef: MatDialogRef<EditObjectTagsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditObjectTagsDialogData) {

        this.tags = data.tags.slice(0);
    }

    ngOnInit() { }

    get viewTaskTags() { return this.data.objectType === 'Task'; }
    get viewWorkOrderTags() { return this.data.objectType === 'WorkOrder'; }
    get viewPolicyTags() { return this.data.objectType === 'Policy'; }
    get viewEntityTags() { return this.data.objectType === 'Entity'; }
    get viewContractorInvoiceTags() { return this.data.objectType === 'ContractorInvoice'; }
    get viewPurchaseOrderTags() { return this.data.objectType === 'PurchaseOrder'; }
    get viewInvoiceTags() { return this.data.objectType === 'Invoice'; }
    get viewMaintenanceServiceTags() { return this.data.objectType === 'MaintenanceService' };

    async save() {
        if (!this.data.inMemoryOnly) {
            this.saving = true;
            if (this.data.objectType === 'Task') {
                await this.taskApi.replaceTags(this.data.objectId, this.tags);
            }
            if (this.data.objectType === 'WorkOrder') {
                await this.serviceApi.replaceWorkOrderTags(this.data.objectId, this.tags);
            }
            if (this.data.objectType === 'Policy') {
                await this.policyApi.replaceTags(this.data.objectId, this.tags);
            }
            if (this.data.objectType === 'Entity') {
                await this.entityApi.replaceTags(this.data.objectId, this.tags);
            }
            if (this.data.objectType === 'ContractorInvoice') {
                await this.accountingApi.replaceContractorInvoiceTags(this.data.objectId, this.tags);
            }
            if (this.data.objectType === 'PurchaseOrder') {
                await this.purchasingApi.replacePurchaseOrderTags(this.data.objectId, this.tags);
            }
            if (this.data.objectType === 'Invoice') {
                await this.accountingApi.replaceInvoiceTags(this.data.objectId, this.tags);
            }
            if(this.data.objectType === 'MaintenanceService') {
                await this.maintApi.replaceMaintenanceServiceTags(this.data.objectId, this.tags);
            }
            this.saving = false;
        }
        this.dialogRef.close(this.tags);
    }

}
