<a (click)="dialNumber()" style="display: inline-block">
  <div aflex>
    <div nogrow>
      <i class="material-icons" [class.large]="alwaysShowNumber && phoneNumberDescription" *ngIf="icon">
        {{icon}}
      </i>
    </div>
    <div style="margin-left: 5px"> {{display}}
        <div class="description" *ngIf="alwaysShowNumber && phoneNumberDescription">
            {{phoneNumberDescription}}
        </div>
    </div>
    
  </div>

</a>
