import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource()
export class QuickbooksToken {
    @keyColumn() id: string;
    @column() areaId: string;
    @column() realmId: string;
    @column() accessToken: string;
    @column() accessTokenExpires: Date;
    @column() refreshToken: string;
    @column() refreshTokenExpires: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() stripeId: string;
    @column() stripePercentFee: number;
    @column() stripeTransactionFee: number;
}