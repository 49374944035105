import { Injectable } from "@angular/core";
import { IterationBug, IterationSkill, NoteSummary } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { TaskSummaryClient } from '@cogent/client/shared/models/tasks/task-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { Iteration, IterationSummary, Tag, Task, TaskMessage, TaskParticipant, TaskQueue, TaskWorkDetail } from "@upkeeplabs/models/cogent";
import { EventArguments } from "@cogent/client/shared/services/mission-service-args";

export class EventNames {
    public static POLICY_TASKS_CHANGED = 'POLICY_TASKS_CHANGED';
}

@Injectable({ providedIn: 'root' })
export class TaskApiService {
    constructor(
        private api: ApiService,
        private missionService: MissionService,
        private entityApi: EntityApiService
    ) { }

    getUserTasks(take = 5): Promise<Task[]> {
        return this.api.getArrayDotNet("Task", {
            ownerId_eq: "{{currentUser}}",
            orderBy: "Priority descending, CreatedDate",
            take,
            completedDate_eq: "{{null}}"
        }, () => new Task());
    }

    async getUserTaskCount() {
        return await this.api.getSingleDotNet("Task/Count", {
            ownerId_eq: "{{currentUser}}",
            completedDate_eq: "{{null}}"
        });
    }

    getEngineeringPushes() {
        return this.api.getSingleNode('greg-test/git-pushes');
    }

    getEngineeringPullRequests() {
        return this.api.getSingleNode('greg-test/git-pull-requests');
    }

    getBuildMetrics() {
        return this.api.getSingleNode('greg-test/build-metrics');
    }

    getGitCommits() {
        return this.api.getSingleNode('greg-test/git-commits');
    }


    getCompleteEngineeringTasks(startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`TaskSummary`, { ownerId_neq: '{{null}}', completedDate_gte: startDate, completedDate_lte: endDate, taskQueueId_eq: '6be2b9c8-658d-4b69-ae85-42d42186975b' }, () => new TaskSummaryClient());
    }

    getOverdueTasks(): Promise<Task[]> {
        const date = UtilitiesService.dayBegin(new Date());
        return this.api.getArrayDotNet("Task", {
            ownerId_eq: '{{currentUser}}',
            completedDate_eq: '{{null}}',
            followUpDate_lt: date.toISOString()
        }, () => new Task());
    }

    getTaskParticipants(taskId: string): Promise<TaskParticipant[]> {
        return this.api.getArrayDotNet('TaskParticipantSummary', { taskId_eq: taskId }, () => new TaskParticipant());
    }

    saveTaskParticipant(taskParticipant: TaskParticipant) {
        return this.api.postSingleDotNet(`TaskParticipant`, taskParticipant);
    }

    startWorkflow(workflowId: string, objectId: string, objectType: string, expirationDate: Date = null) {
        if (!expirationDate) {
            expirationDate = new Date();
            expirationDate.setMonth(expirationDate.getMonth() + 1);
        }
        this.api.postSingleDotNet(`WorkflowInstance`, {
            id: UtilitiesService.newid(),
            workflowId,
            objectId,
            objectType,
            expirationDate
        });
    }

    deleteTaskParticipant(taskParticipantId: string) {
        return this.api.deleteDotNet(`TaskParticipant/${taskParticipantId}`);
    }

    getTaskNotes(taskId: string): Promise<NoteSummary[]> {
        return this.api.getArrayDotNet(`NoteSummary`, { taskId_eq: taskId, orderby: 'CreatedDate desc' }, () => new NoteSummary());
    }

    updateTaskSort(task: Task): Promise<any> {
        return this.api.patchSingleDotNet(`Task/${task.id}`, { sort: task.sort });
    }

    replaceTags(taskId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Task/${taskId}/replace-tags`, tags);
    }

    getTaskQueues(): Promise<TaskQueue[]> {
        return this.api.getArrayDotNet(`TaskQueue`, { orderby: 'Name' });
    }

    getTags(taskId: string): Promise<Tag[]> {
        return this.api.getArrayDotNet(`Task/${taskId}/tags`);
    }

    getTaskWork(taskId: string) {
        return this.api.getArrayNode(`TaskWorkDetail`, { taskId_eq: taskId, orderby: 'CreatedDate desc' }, () => new TaskWorkDetail());
    }

    deleteTaskWork(id: string) {
        return this.api.deleteNode(`TaskWorkDetail/${id}`)
    }

    undeleteTaskWork(id: string) {
        return this.api.unDeleteNode(`TaskWorkDetail/${id}`);
    }

    get(taskId: string) {
        return this.api.getSingleDotNet("Task/" + taskId, null, () => new Task());
    }

    getPolicyTasks(policyId: string) {
        return this.api.getArrayDotNet("Task", { policyId_eq: policyId, orderBy: 'CreatedDate descending' }, () => new Task());
    }

    addTags(taskId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Task/${taskId}/add-tags`, tags);
    }

    removeTags(taskId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Task/${taskId}/remove-tags`, tags);
    }



    async saveTask(task: Task, setOwner = true): Promise<Task> {

        if (setOwner) {
            const user = await this.entityApi.getLoggedInUser();
            task.ownerId = user.id;
        }

        task.id = await this.api.postIdDotNet("Task", task);
        this.missionService.raiseEvent(new EventArguments(EventNames.POLICY_TASKS_CHANGED, { policyId: task.policyId }));
        return task;
    }


    getPolicyOpenTaskCount(policyId: string) {
        return this.api.getSingleDotNet<number>('Task/Count', { policyId_eq: policyId, completedDate_eq: '{{null}}' });
    }

    async reactivateTask(taskId: string) {
        await this.api.patchDotNet('task/' + taskId, { completedDate: null });
        const result = await this.api.getSingleDotNet("Task/" + taskId, null, () => new Task());
        this.missionService.raiseEvent(new EventArguments(EventNames.POLICY_TASKS_CHANGED, { policyId: result.policyId }));
        return result;
    }

    getWorkOrderOpenTaskCount(workOrderId: string): Promise<number> {
        return this.api.getSingleDotNet<number>('Task/Count', { workOrderId_eq: workOrderId, completedDate_eq: '{{null}}' });
    }

    getWorkOrderTasks(workOrderId: string): Promise<Task[]> {
        return this.api.getArrayDotNet("Task", { workOrderId_eq: workOrderId, orderBy: 'CreatedDate descending' }, () => new Task());
    }

    async completeTask(taskId: string): Promise<any> {
        await this.api.patchDotNet('Task/' + taskId, { completedDate: new Date() });
        const result = await this.api.getSingleDotNet("Task/" + taskId, null, () => new Task());
        this.missionService.raiseEvent(new EventArguments(EventNames.POLICY_TASKS_CHANGED, { policyId: result.policyId }));
        return result;
    }

    transferTask(taskId: string, newOwnerId: string, sendNotification: boolean) {
        return this.api.patchDotNet('Task/' + taskId, { OwnerId: newOwnerId, SendNotification: sendNotification });
    }

    getTaskMessages(taskId: string): Promise<TaskMessage[]> {
        return this.api.getArrayDotNet('Task/' + taskId + '/messages', null, () => new TaskMessage());
    }

    sendTaskMessage(taskId: string, taskMessage: TaskMessage) {
        return this.api.postVoidDotNet('Task/' + taskId + '/messages', taskMessage);
    }

    getTaskSummaryById(id: string): Promise<TaskSummaryClient> {
        return this.api.getSingleDotNet(`TaskSummary/${id}`, null, () => new TaskSummaryClient());
    }

    updateIterationSkill(skill: IterationSkill) {
        return this.api.postNode(`IterationSkill`, skill);
    }

    saveIterationBug(bug: IterationBug) {
        return this.api.postNode(`IterationBug`, bug);
    }

    getIterationBugsByIterationId(id: string) {
        return this.api.getArrayNode(`IterationBug`, { iterationId_eq: id });
    }

    saveIterationSkill(skill: IterationSkill) {
        return this.api.postNode(`IterationSkill`, skill);
    }

    getIterationSkills() {
        return this.api.getArrayNode(`IterationSkill`, { deletedDate_eq: null });
    }

    getIterationSkillsByIterationId(id: string) {
        return this.api.getArrayNode(`IterationSkill`, { iterationId_eq: id });
    }

    saveIteration(i: Iteration) {
        return this.api.postNode(`Iteration`, i);
    }

    getIterationById(id: string) {
        return this.api.getSingleNode(`Iteration`, { id_eq: id }, () => new Iteration());
    }

    getIterationCurrent() {
        return this.api.getArrayNode(`Iteration`, { newSkills_eq: '{{null}}', completedDate_eq: '{{null}}' }, () => new Iteration());
    }

    async getIterations() {
        let results = await this.api.getArrayNode(`Iteration`, { deletedDate_eq: '{{null}}' }, () => new Iteration());
        return results;
    }

    async getIterationSummaries() {
        let results = await this.api.getArrayNode(`IterationSummary`, null, () => new IterationSummary());
        results = results.sort((a, b) => a.monday < b.monday ? 1 : -1);
        return results;
    }

    getIterationsByDate(startDate: Date, endDate: Date) {
        return this.api.getArrayNode(`IterationSummary`, { completedDate_gte: startDate, completedDate_lte: endDate }, () => new IterationSummary());
    }

}