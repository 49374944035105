import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class OidcProvider {
    @keyColumn() id: string;
    @column() name: string;
    @column() uri: string;
    @column() clientId: string;
    @column() clientSecret: string;
    @column() scopes: string;
    @column() deletedDate?: Date;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
}