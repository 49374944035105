import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorAuthorizationRepairItem {
    @keyColumn() id: string;
    @column() authorizationRepairItemId: string;
    @column() entityId: string;
    @column() cost: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() approvedDate: Date;
    @column() approvedBy: string;
}