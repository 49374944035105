import { RepairItem, SalesItemCoverage, SalesItemCoverageApplianceCoverageTerm, SalesItemCoverageExtension } from '@upkeeplabs/models/cogent';

import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { SalesItemCoverageRepairItem } from '@cogent/shared/models/plans/sales-item-coverage-repair-item.model';
import { SalesItemCoverageWorkOrderItemClient } from '@cogent/client/shared/models/plans/sales-item-coverage-work-order-item-client.model';
import { PlanClient } from './plan-client.model';

export class SalesItemCoverageClient extends SalesItemCoverage {


    // Client Side Only
    jobItems: SalesItemCoverageWorkOrderItemClient[];
    unselectedJobItems: WorkOrderItemClient[];
    unselectedRepairItems: RepairItem[];
    deletedJobItems: SalesItemCoverageWorkOrderItemClient[] = [];
    deletedRepairItems: SalesItemCoverageRepairItem[] = [];
    repairItems: SalesItemCoverageRepairItem[];
    // selectedRepairItems: RepairItem[];

    extensions: SalesItemCoverageExtensionClient[];

    applianceCoverageTerms: SalesItemCoverageApplianceCoverageTerm[] = [];
    deletedApplianceCoverageTerms: SalesItemCoverageApplianceCoverageTerm[] = [];

    get deniedApplianceTerms() {
        if (this.applianceCoverageTerms) {
            return this.applianceCoverageTerms.filter(i => !i.isCovered);
        }
        return [];
    }

    get approvedApplianceTerms() {
        if (this.applianceCoverageTerms) {
            return this.applianceCoverageTerms.filter(i => i.isCovered);
        }
        return [];
    }

}

export class SalesItemCoverageExtensionClient extends SalesItemCoverageExtension {
    plans: PlanClient[]
}