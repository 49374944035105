import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";
import { Address } from "./address.model";

@dataSource()
export class CashOut {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() amount: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() submittedDate: Date;
    @column() approvedDate: Date;
    @column() addressId: string;
    @column() nameOnCheck: string;
    @column() exportDate: Date;
    @column() quickBooksId: string;
    @column() type: string;
    @column() policyId: string;
    @column() postDate: Date;
    // @column() version: timestamp;
    @readOnlyColumn() number: number;
    @column() appliedLines: string;
    @column() comparableUrl: string;
    @column() cashOutEmail: string;
    @column() cashOutMobileNumber: string;
    @column() sendMethod: string;
    @column() checkNumber: string;
    @column() quickBooksPaymentId: string;
    @column() recipientId: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() isPartial?: boolean;

    workOrderLineId: string;    
    address: Address;
}