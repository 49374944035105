import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TypeAheadApiService {
    constructor(private api: ApiService) { }

    getSearchResults(endpoint: string, endpointParameter: string, token: string): Observable<any> {
        let url = endpoint;
        url += (url.indexOf("?") > -1 ? "&" : "?") + endpointParameter + "=" + encodeURIComponent(token);
        return from(this.api.getArrayDotNet(url));
    }

}