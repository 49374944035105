import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorVisit {
    @keyColumn() id: string;
    @column() contractorId: string;
    @column() contractorVisitTypeId: string;
    @column() visitDate: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() calendarAppointmentId: string;
    @column() notes: string;
    @column() completionDate: Date;
}