import { AuthConfig } from '@cogent/client/shared/types/auth-config';

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sha256 } from 'js-sha256';
import { Router } from '@angular/router';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { OpenidConfiguration } from '@cogent/client/shared/types/openid-configuration';

@Injectable({ providedIn: 'root' })
export class AuthService {

    config: OpenidConfiguration;
    private refreshTimeout: any;

    lastAuthGuardUrl: string;
    authority: string;
    static localLoginUrl: string;

    constructor(
        private httpClient: HttpClient,
        @Inject("authConfig") public authConfig: AuthConfig,
        private missionService: MissionService,
        private router: Router) {
        window.addEventListener("storage", data => {
            if (data.key === "auth" && this.isLoggedIn) {
                this.setupRefresh();
            }
        });

        if (this.isLoggedIn) {
            this.setupRefresh();
        }
    }


    async getAuthority(): Promise<string> {
        if (!this.authority) {
            try {
                const result = await this.httpClient.get<any>("/accounts/hostname").toPromise();
                this.authority = `https://${result.hostname}/`;
            } catch (err) {
                this.authority = "https://local.upkeeplabs.com:5001/";
            }
        }

        return this.authority;
    }



    async localAuthenticate(args) {
        const host = await this.getAuthority();
        return await this.httpClient.post(`${host}login/local-login`, args).toPromise();
    }


    private get user(): User {
        let newJWT = false;
        let userJson = localStorage.getItem('auth');
        if (!userJson) {
            userJson = localStorage.getItem('bs:auth.tokens');
            newJWT = true;
        }
        if (!userJson) {
            return null;
        }
        const user = JSON.parse(userJson);
        if (newJWT) {
            user.expires *= 1000;
        }

        return user;
    }
    private set user(value: User) {
        if (value) {
            localStorage.setItem("auth", JSON.stringify(value));
            localStorage.setItem('bs:auth.tokens', JSON.stringify(value));
        } else {
            localStorage.removeItem("auth");
            localStorage.removeItem('bs:auth.tokens');
        }
    }

    get authorizationHeaderWebsocket() {
        if (!this.user) {
            return null;
        }

        this.checkExpires();

        return [this.user.token_type, this.user.access_token];
    }

    get authorizationHeader() {
        if (!this.user) {
            return null;
        }

        this.checkExpires();

        return this.user.token_type + ' ' + this.user.access_token;
    }


    // Before we return the auth token for APIs, we check to see if it is expired or expiring soon.  If so, we take appropriate actions.
    private async checkExpires() {

        // If we aren't on the last page auth guarded, then do not check if authenticated.
        if (this.router.url !== this.lastAuthGuardUrl) {
            return;
        }

        if (!this.user) {
            await this.logIn();
        } else {
            const expires = new Date(this.user.expires);
            const now = new Date();
            const expiresIn = (expires.getTime() - now.getTime()) / 1000;

            if (expiresIn < 60) {
                this.setupRefresh();
            }

            if (expiresIn < 0) {
                await this.logIn();
            }
        }
    }

    private async setupRefresh() {
        if (this.refreshTimeout) clearTimeout(this.refreshTimeout);

        const expires = new Date(this.user.expires);
        const now = new Date();
        const expiresIn = (expires.getTime() - now.getTime()) / 1000;

        // Renew 1 minute before half way to expiration but at least every 90 minutes.
        // Renewing early should help with issues related to getting signed out.
        let secondsUntilRefresh = (expiresIn / 2) - 60;

        if (secondsUntilRefresh > 90 * 60) {
            secondsUntilRefresh = 90 * 60;
        }

        // Subtract up to 2 minutes to randomize when it will renew to keep multiple tabs from renewing simultaneously.

        secondsUntilRefresh = Math.round(secondsUntilRefresh - (Math.random() * 120));

        if (secondsUntilRefresh < 0) {
            secondsUntilRefresh = 1;
        }

        var testingAuthRefresh = false;
        if (testingAuthRefresh && (await this.getAuthority()) == "https://local.upkeeplabs.com:5001/") {
            secondsUntilRefresh = 10;
        }

        this.refreshTimeout = setTimeout(() => {
            this.processRefresh();
        }, secondsUntilRefresh * 1000);
    }


    async getConfig() {
        if (!this.config) {
            const configUrl = (await this.getAuthority()) + ".well-known/openid-configuration";
            this.config = await this.httpClient.get(configUrl).toPromise() as OpenidConfiguration;
        }
        return this.config;
    }

    async impersonate(uri: string, entityId: string, redirectTo: string = null) {

        if (redirectTo) {
            window.open(uri + "?auth=" + encodeURIComponent(this.authorizationHeader) + "&entityId=" + encodeURIComponent(entityId) + "&redirectTo=" + encodeURIComponent(redirectTo), "_blank");
        } else {
            window.open(uri + "?auth=" + encodeURIComponent(this.authorizationHeader) + "&entityId=" + encodeURIComponent(entityId), "_blank");
        }
    }

    async logIn(anonymousEntityId?: string, auth?: string, entityId?: string, anonymousType?: string, isNewApp = false) {
        if (this.user && !isNewApp) {
            const refreshExpires = new Date(this.user.expires);
            refreshExpires.setDate(refreshExpires.getDate() + 6);
            if (refreshExpires > new Date()) {
                await this.processRefresh();
                return true;
            }
        }
        const config = await this.getConfig();
        const state = this.generateRandom(40);
        const codeVerifier = this.generateRandom(40);
        const codeChallenge = this.base64urlEncode(this.hexToByteString(sha256(codeVerifier)));

        for (const key of Object.keys(localStorage)) {
            if (key.indexOf("oidc:") === 0) {
                localStorage.removeItem(key);
            }
        }

        localStorage.setItem("oidc:" + state, JSON.stringify({ codeVerifier }));

        let redirectUri = this.authConfig.redirectUri;
        if (isNewApp) {
            redirectUri = '/';
        }
        if (redirectUri.substr(0, 1) === '/' || isNewApp) {
            redirectUri = window.location.href.split('/').slice(0, 3).join('/') + this.authConfig.redirectUri;
        }
        // if(isNewApp) {
        //     this.authConfig.clientId = 'customers';
        // }

        let redirectLocation = config.authorization_endpoint +
            "?client_id=" + (isNewApp ? 'customers' : this.authConfig.clientId) +
            "&redirect_uri=" + redirectUri +
            "&response_type=code" +
            "&scope=" + this.authConfig.scope +
            "&state=" + state +
            "&code_challenge=" + codeChallenge +
            "&code_challenge_method=S256" +
            "&anonymous_type=" + anonymousType;


        if (anonymousEntityId) {
            redirectLocation = redirectLocation + "&anonymous_entity_id=" + anonymousEntityId;
        }
        if (auth) {
            redirectLocation = redirectLocation + "&impersonation_entity_id=" + entityId + "&AuthorizationHeader=" + encodeURIComponent(auth);
        }

        window.location.href = redirectLocation;

        // probably not needed
        return false;
    }


    async processRefresh() {
        if (!this.user.refresh_token) { return; }
        const config = await this.getConfig();
        const body = "client_id=" + this.authConfig.clientId +
            "&client_secret=" + this.user.access_token +
            "&refresh_token=" + encodeURIComponent(this.user.refresh_token) +
            "&grant_type=refresh_token";

        try {
            const result = await this.httpClient.post(config.token_endpoint, body,
                { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).toPromise() as User;

            if (result) {
                const expires = new Date();
                expires.setSeconds(expires.getSeconds() + result.expires_in);
                result.expires = expires.toISOString();
                this.user = result;
                this.missionService.publish({ type: 'user-changed', messageBody: result });
            }
            this.setupRefresh();
        } catch (error: any) {
            if (error.status == 401) {
                this.user = null;
                this.missionService.publish({ type: 'user-changed', messageBody: null });
                if (AuthService.localLoginUrl) {
                    this.router.navigateByUrl(AuthService.localLoginUrl);
                } else {
                    await this.logIn();
                }
            } else {
                console.error(error);
                this.setupRefresh();
            }
        }
    }
    private delay(milliseconds: number) { return new Promise<void>(completeAction => setTimeout(completeAction, milliseconds)); }

    async processCode(state: string, code: string) {
        const config = await this.getConfig();
        const oidc = JSON.parse(localStorage.getItem("oidc:" + state));
        localStorage.removeItem("oidc:" + state);

        for (const key of Object.keys(localStorage)) {
            if (key.indexOf("oidc:") === 0) {
                localStorage.removeItem(key);
            }
        }

        if (oidc == null) {
            this.router.navigateByUrl("/");
            return;
        }
        const body = "client_id=" + this.authConfig.clientId +
            "&code=" + code +
            "&redirect_uri=" + this.authConfig.redirectUri +
            "&code_verifier=" + oidc.codeVerifier +
            "&grant_type=authorization_code";

        const result = await this.httpClient.post(config.token_endpoint, body,
            { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).toPromise() as User;

        const expires = new Date(); 
        expires.setSeconds(expires.getSeconds() + result.expires_in);
        result.expires = expires.toISOString();
        this.user = result;
        this.missionService.publish({ type: 'user-changed', messageBody: result });
        const urlParams = new URLSearchParams(window.location.search); 
        const embedded = urlParams.get('embedded');
        this.setupRefresh();

        const redirectTo = window.localStorage.getItem("redirectTo");
        window.localStorage.removeItem("redirectTo");

        if (redirectTo) {
            this.router.navigateByUrl(redirectTo);
        } else {
            this.router.navigateByUrl("/");
        }
    }

    logOut() {
        this.user = null;
        localStorage.removeItem('rememberme');
        localStorage.removeItem('username');
        localStorage.removeItem('password');

        this.missionService.publish({ type: 'user-changed', messageBody: null });
        this.router.navigateByUrl("/auth/log-out");
    }

    public base64urlEncode(value: string): string {
        return btoa(value).replace(/[+\/]/g, m0 => m0 === '+' ? '-' : '_').replace(/=/g, '');
    }

    public hexToByteString(hex: string) {
        let byteString = '';
        for (let c = 0; c < hex.length; c += 2) {
            byteString += String.fromCharCode(parseInt(hex.substr(c, 2), 16));
        }
        return byteString;
    }
    //1721431355676
    get isLoggedIn(): boolean {
        const user = this.user;
        if (user) {
            if (!user.expires) {
                return false;
            }
            if (this.parsedJwt && this.parsedJwt.roles === 'Anonymous') {
                return false;
            }
            
            let exp = user.expires.toString();
            if (exp.includes("T")){
                exp = (new Date(exp) as any / 1).toString();
            }
            const i = parseInt(exp);
            if (i.toString().length === 10) {
                exp = (parseInt(exp) * 1000) as any;
            } else {
                exp = parseInt(exp) as any;
            }
            const expires = new Date(exp);
            const now = new Date();

            return expires > now;
        } 
        return false;
    }

    get parsedJwt() {
        const base64Url = this.user.access_token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    public generateRandom(length: number = 40) {
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let array = new Uint8Array(length);
        const cryptoObj = window.crypto || (window as any).msCrypto;
        cryptoObj.getRandomValues(array);
        array = array.map(x => validChars.charCodeAt(x % validChars.length));
        return String.fromCharCode.apply(null, array as any);
    }
}
interface User {
    token_type: string;
    access_token: string;
    id_token: string;
    expires: string;
    expires_in: number;
    refresh_token: string;
}
