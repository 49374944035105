import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebSocketHandlerComponent } from './web-socket-handler.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [WebSocketHandlerComponent],
    exports: [WebSocketHandlerComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ]
})
export class WebSocketHandlerModule { }
