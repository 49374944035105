import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityConfiguration } from '@upkeeplabs/models/cogent';

@Injectable({
    providedIn: 'root'
})
export class RemoteStorageService {

    constructor(private entityApi: EntityApiService, private apiService: ApiService) { }

    async getItem(key: string): Promise<string> {
        const configuration = await this.entityApi.getEntityConfiguration(key);
        if (configuration) {
            return configuration.configuration;
        }


        return null;
    }

    async deleteItem(key: string) {
        const configuration = await this.entityApi.getEntityConfiguration(key);
        if (configuration) {
            await this.entityApi.deleteEntityConfiguration(configuration);
        }
    }

    async setItem(key: string, value: string) {
        const baseConfigurationId = await this.entityApi.getEntityConfigurationId(key);
        const baseConfiguration = new EntityConfiguration();
        if (!baseConfiguration) {

            baseConfiguration.id = UtilitiesService.newid();
        } else {
            baseConfiguration.id = baseConfigurationId;
        }
        const user = await this.entityApi.getLoggedInUser(false);
        baseConfiguration.entityId = user.id;
        baseConfiguration.configurationType = key;
        baseConfiguration.configuration = value;

        await this.entityApi.saveEntityConfiguration(baseConfiguration);
    }

    async setObject<T>(key: string, value: T) {
        await this.setItem(key, JSON.stringify(value));
    }

    async getObject<T>(key: string, typeCreator: () => T = null, defaultValue: any = null): Promise<T> {
        const configuration = await this.entityApi.getEntityConfiguration(key);
        if (configuration) {
            let result = JSON.parse(configuration.configuration);
            if (typeCreator) {
                result = this.apiService.convertToType(result, typeCreator);
            }
            return result;
        }

        return defaultValue;
    }
}
