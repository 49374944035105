<div aflex>
    <div>
        <h2 class="sub-heading">Questionnaire</h2>
    </div>
    <div nogrow>
        <button mat-button [mat-dialog-close]>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <app-question-wizard #questionWizardComponent  userType="Employee" [hideItemPicture]="true" [questionnaireId]="data.action.questionnaireId" [(isComplete)]="isComplete"></app-question-wizard>
</div>

<div mat-dialog-actions flex>
    <div></div>
    <div nogrow>
        <button mat-button mat-raised-button color="primary" [disabled]="!isComplete" (click)="saveQuestionnaire()">Complete</button>
    </div>
</div>