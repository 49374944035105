import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesReport {
    @keyColumn() id: string;
    @column() salesRegionName: string;
    @column() salesAreaName: string;
    @column() salesTerritoryName: string;
    @column() accountExecutiveName: string;
    @column() salesRegionId: string;
    @column() salesAreaId: string;
    @column() salesTerritoryId: string;
    @column() accountExecutiveId: string;
    @column() policyCount: number;
    @column() totalPrice: number;
    @column() basePrice: number;
    @column() coverageType: string;
    @column() month: Date;
    @column() salesTarget: number;
    @column() salesClosingTarget: number;
}

export class SalesReportInputParameters {
    useEffectiveDate: boolean;
    startDate: Date;
    endDate: Date;
    areaIds: string;
    showBuyersCoverage: boolean;
    showSellersCoverage: boolean;
    showHomeownerCoverage: boolean;
    showRenewalCoverage: boolean;
    showPaidOnly: boolean;
    showRealEstateRunOff: boolean;
    showPropertyManagementCoverage: boolean;
}