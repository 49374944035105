import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class IterationSummary {
    @keyColumn() iterationId: string;
    @column() monday?: Date;
    @column() mondayStr: string;
    @column() mondayStrLong: string;
    @column() name: string;
    @column() employeeId: string;
    @column() totalCount?: number;
    @column() completedCount: number;
    @column() newSkills?: number;
    @column() bugCount?: number;
    @column() wigCount?: number;
    @column() wigCompleted?: number;
    @column() completedDate?: Date;
    @column() wigCompletedDate?: Date;

    get isCurrent() {
        return (this.completedDate) ? true : false;
    }

    get completionRate() {
        if (this.totalCount == 0) return 0;
        return this.completedCount / this.totalCount;
    }

    get bugRate() {
        if (this.completedCount == 0) return 0;
        return this.bugCount / this.completedCount;
    }

    get isAllComplete() {
        if (this.completedDate && (!this.wigCount || this.wigCount && this.wigCompletedDate)) return true;
        return false;
    }
}