import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesItemCoverageExtension {
    @keyColumn() id: string;
    @column() salesItemCoverageId: string;
    @column() action: string;
    @column() salesItemId: string;
    @column() extensionSalesItemCoverageId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() planId: string;
    @column() coveredQuantityDelta: number;
    @column() previousSalesItemCoverageId: string;
}