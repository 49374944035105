<div class="container">
    <h2 class="sub-heading" topmargin20 bottommargin40>Incomplete Authorizations</h2>

    <div aflex *ngFor="let item of items" class="incomplete-autho-item">
        <div nogrow>
            <img style="height: 90px;" [src]="item.itemUrl">
        </div>
        <div>
            <h3 style="margin: 0">
                {{item.workOrderLine.workOrderNumber}}</h3>
            <div>
                {{item.workOrderLine.propertyAddress}}</div>
<br>
            <a mat-button mat-raised-button [queryParams]="{lineId: item.workOrderLine.id}" routerLink="/appliance-autho/{{item.workOrderLine.workOrderId}}">
                Resume Authorization
            </a>
        </div>

        <div nogrow>
            <button mat-button (click)="delete(item)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>