import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceInvoiceSummary {
    @keyColumn() id: string;
    @column() date: Date;
    @column() amount: number;
    @column() dueDate: Date;
    @column() invoiceType: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerPhone: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() loginId: string;
    @column() orderedServices: string;
    @column() linesJson: string;
    @column() amountPaid: number;
    @column() invoiceNumber: number;
    @column() mailingAddress1: string;
    @column() mailingAddress2: string;
    @column() mailingCity: string;
    @column() mailingState: string;
    @column() mailingPostalCode: string;
    @column() status: string;
    @column() exportDate?: Date;
    @column() quickBooksId?: string;
    @column() payeeId?: string;
    @column() payerId?: string;
    @column() contractorName: string;
    @column() memo: string;
    @column() creditsJson: string;
    @column() stripeCardId: string;
    @column() stripeCustomerId: string;
    @column() customerId: string;
    @column() customerPropertyStatus: string;
    @column() accountingAreaId: string;
    @column() vendorNumber: string;
    @column() accountsPayableAccountId: string;
    @column() costAccountId: string;
    @column() payablesPaymentMethod: string;
    @column() paymentAccountId: string;
    @column() contractorInvoicePaymentId: string;
    @column() contractorInvoicePaymentDate: Date;


    private _items: MaintenanceServiceInvoiceSummaryItem[];
    get items(): MaintenanceServiceInvoiceSummaryItem[] {
        if (!this._items) {
            try {
                this._items = JSON.parse(this.linesJson);
            } catch { }
        }

        return this._items;
    }

    get isFreeForm() {
        return this.items?.find(i => i.description) ? true : false;
    }

    private _creditItems: MaintenanceServiceInvoiceCreditSummary[];
    get creditItems(): MaintenanceServiceInvoiceCreditSummary[] {
        if (!this._creditItems) {
            try {
                if (this.creditsJson) {
                    this._creditItems = JSON.parse(this.creditsJson);
                    this._creditItems = [
                        {
                            amount: this._creditItems.map(i => i.amount).reduce((a, b) => a + b, 0),
                            id: '',
                            memo: 'Applied Credits'
                        }
                    ];
                } else {
                    this._creditItems = [];
                }

                this.creditTotal = this._creditItems ? this._creditItems.map(i => i.amount).reduce((a, b) => a + b, 0) : 0;
                this.amountPaidLessCredits = this.amountPaid + this.creditTotal;
            } catch { }
        }

        return this._creditItems;
    }

    get due() {
        const num = this.amount - (this.amountPaid ?? 0);
        return Math.round(num * 100) / 100;
    }
    creditTotal: number;
    amountPaidLessCredits: number;

    // get creditTotal() {
    //     return this.creditItems.map(i => i.amount).reduce((a, b) => a + b, 0);
    // }

    // get amountPaidLessCredits() {
    //     return this.amountPaid - this.creditTotal;
    // }

    selected: boolean;
    paymentAmount: number;

    get selectedCalc() {
        if (this.paymentAmount > this.due) {
            return false;
        }

        return this.selected;
    }

    set selectedCalc(value: boolean) {
        this.selected = true;
    }

    get calculatedTotal() {
        if (!this.items) {
            return 0;
        }
        return this.items?.map(i => i.amount).reduce((a, b) => a + b);
    }

    get calculatedDue() {
        return this.calculatedTotal - this.amountPaid;
    }
}

export class MaintenanceServiceInvoiceSummaryItem {
    id: string;
    maintenanceServiceOfferingId: string;
    name: string;
    amount: number;
    frequencyName: string;
    description: string;
}

export class MaintenanceServiceInvoiceCreditSummary {
    id: string;
    amount: number;
    memo: string;
}