import { column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DispatchJobLog {
    @column() logid: string;
    @column() dispatchNoteId: string;
    @column() woid: string;
    @column() woNumber: number;
    @column() woStatus: string;
    @column() updatedDateStr: string;
    @column() updatedDate?: Date;
    @column() noteText: string;
    @column() processedDate: string;
    @column() assignedTo: string;
    @column() assignedToId: string;
    @column() acknowledged?: boolean;
    @column() dispatchNoteLogId: string;

    deleting = false;
}