import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorDocumentStatus {
    @keyColumn() id: string;
    @column() contractorName: string;
    @column() documentName: string;
    @column() status: string;
    @column() expirationDate: Date;
    @column() contractorDocumentTypeId: string;
    @column() contractorEmail: string;
}