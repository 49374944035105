import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { EntityApiService } from '@cogent/client/shared/services/api/entity-api.service';
import { CommonModule } from '@angular/common';
import { TimePickerModule } from '@cogent/client/shared/components/misc/time-picker/time-picker.module';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';


export class DateSelection {
    constructor(public date: Date, daysToAdd: number = 0) {
        date.setDate(date.getDate() + daysToAdd);
    }

    get dayOfWeek(): string {
        if (this.date.getDay() === 0) { return 'Sun'; }
        if (this.date.getDay() === 1) { return 'Mon'; }
        if (this.date.getDay() === 2) { return 'Tue'; }
        if (this.date.getDay() === 3) { return 'Wed'; }
        if (this.date.getDay() === 4) { return 'Thu'; }
        if (this.date.getDay() === 5) { return 'Fri'; }
        if (this.date.getDay() === 6) { return 'Sat'; }
    }

    get heading(): string {
        let result = '';
        if (this.date.getMonth() === 0) { result = 'Jan'; }
        if (this.date.getMonth() === 1) { result = 'Feb'; }
        if (this.date.getMonth() === 2) { result = 'Mar'; }
        if (this.date.getMonth() === 3) { result = 'Apr'; }
        if (this.date.getMonth() === 4) { result = 'May'; }
        if (this.date.getMonth() === 5) { result = 'Jun'; }
        if (this.date.getMonth() === 6) { result = 'Jul'; }
        if (this.date.getMonth() === 7) { result = 'Aug'; }
        if (this.date.getMonth() === 8) { result = 'Sep'; }
        if (this.date.getMonth() === 9) { result = 'Oct'; }
        if (this.date.getMonth() === 10) { result = 'Nov'; }
        if (this.date.getMonth() === 11) { result = 'Dec'; }

        result += ' ' + this.date.getDate();

        return result;
    }
}

@Component({
    selector: 'app-appointment-scheduler',
    templateUrl: './appointment-scheduler.component.html',
    styleUrls: ['./appointment-scheduler.component.css'],
    standalone: true,
    imports: [CommonModule, TimePickerModule, DatePickerWrapperComponent, MaterialSharedModule]
})
export class AppointmentSchedulerComponent implements OnInit {
    appointmentStartTime: Date;
    appointmentEndTime: Date;
    savingAppointment: boolean;
    apptDate: Date = new Date();
    workOrderId: string;
    workOrderSummary: WorkOrderSummaryClient;
    dispatchContractor = false;

    preSelectDates = [
        new DateSelection(new Date()),
        new DateSelection(new Date(), 1),
        new DateSelection(new Date(), 2),
        new DateSelection(new Date(), 3),
        new DateSelection(new Date(), 4),
        new DateSelection(new Date(), 5),
    ];

    constructor(public dialogRef: MatDialogRef<AppointmentSchedulerComponent>,
        private serviceApi: ServiceApiService,
        private entityApi: EntityApiService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {

        this.workOrderId = this.data.queueObjects ? this.data.queueObjects[0].workOrderId : this.data.workOrder?.id;

        this.serviceApi.getWorkOrderSummary(this.workOrderId).then(ws => {
            this.workOrderSummary = ws;
            this.entityApi.getEntitySummary(this.workOrderSummary.contractorId).then(contractor => {
                if (contractor?.dispatchActivatedDate)
                    this.dispatchContractor = true;
            });
        });

        this.appointmentStartTime = new Date();
        this.appointmentEndTime = new Date();
        this.appointmentStartTime.setHours(8, 0);
        this.appointmentEndTime.setHours(13, 0);
    }

    get canSaveAppointment() {
        return this.apptDate && (this.appointmentEndTime.getHours() > this.appointmentStartTime.getHours()
            || (this.appointmentEndTime.getHours() === this.appointmentStartTime.getHours()
                && this.appointmentEndTime.getMinutes() >= this.appointmentStartTime.getMinutes())
        );
    }

    setDate(date: DateSelection) {
        this.apptDate = date.date;
    }

    saveAppointment() {
        this.savingAppointment = true;
        const datePipe = new DatePipe('en-US');
        const startWindow = datePipe.transform(this.appointmentStartTime, 'shortTime');
        const endWindow = datePipe.transform(this.appointmentEndTime, 'shortTime');
        this.serviceApi.setWorkOrderAppointmentDateNoAuth(this.workOrderId, this.apptDate, startWindow, endWindow).then(() => {

            this.savingAppointment = false;
            this.dialogRef.close(true);
        });
    }

}
