import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

export class MentionSummary {
    id: string;
    mentionId: string;
    mentionedName : string;
    mentionedEmail: string;
    noteText: string;
    createdDate: Date;
    noteCreatedDate: Date;
    policyId: string;
    entityId: string;
    taskId: string;
    workOrderId: string;
    createdByName: string;
    createdByEmail: string;
    noteType: string;
    documentNumber: string;
    documentId: string;
    url: string;
    createdById: string;
    mentionedId: string;
    mentionedEntityId: string;
    noteId: string;
    acknowledged: boolean;

    get mentionsPlainText() {
        return UtilitiesService.stripeHTML(this.noteText);
    }

    //client-side only
    incoming: boolean;
}