import { Component, OnInit } from '@angular/core';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { ApiService } from '@cogent/client/api';
import { IndexedDbWrapperService } from '@cogent/client/shared/services/indexed-db-wrapper.service';

@Component({
    selector: 'app-incomplete-authos',
    templateUrl: './incomplete-authos.component.html',
    styleUrls: ['./incomplete-authos.component.css']
})
export class IncompleteAuthosComponent implements OnInit {

    items: any[];
    constructor(private indexedDbService: IndexedDbWrapperService,
        private missionService: MissionService) { }

    ngOnInit(): void {
        this.indexedDbService.getAll().then(items => {
            this.items = items;
            for (const item of this.items) {
                item.itemUrl = `${ApiService.endPointNode}scheduled-maintenance/item-photo/${item.workOrderLine.itemId}`;
            }
        });
    }

    async delete(item) {

        const index = this.items.indexOf(item);

        await this.indexedDbService.delete(item.id);
        this.items.splice(index, 1);
        this.missionService.publish({ type: 'UPDATE-INCOMPLETE-AUTHO-COUNT', messageBody: '' });
        this.missionService.showUndo(new UndoArguments(async () => {
            await this.indexedDbService.put(item);
            this.items.splice(index, 0, item);
            this.missionService.publish({ type: 'UPDATE-INCOMPLETE-AUTHO-COUNT', messageBody: '' });

        }, 'Item Deleted'));

    }

}
