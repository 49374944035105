import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesItemCoverageRepairItem {
    @keyColumn() id: string;
    @column() salesItemCoverageId: string;
    @column() repairItemId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() limit: number;
    @column() limitPerOccurance: number;
    @column() limitPerPlanPeriod: number;
}