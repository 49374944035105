import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AccountingItem {
    @keyColumn() id: string;
    @column() amount: number;
    @column() date: Date;
    @column() policyId: string;
    @column() customerId: string;
    @column() memo: string;
    @column() type: string;
    @column() amountDue: number;
    @column() number: bigint;
    @column() altKey: string;
    @column() voidDate: Date;
    @column() creditAmount: number;
    @column() subType: string;
    @column() brand: string;
    @column() last4: string;
    @column() addressId: string;
    @column() dueDate: Date;
    @column() parentTransactionId: string;
    @column() scheduledDate: Date;
    @column() invoiceId: string;
    @column() creditId: string;
    @column() transferPolicyId: string;
    @column() refundProcessorId: string;

    childItems: AccountingItem[];
    selected: boolean;
    appliedAmount: number;
    deleting = false;
    childrenOpen = false;
}