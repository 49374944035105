import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PaymentSummary {
    @keyColumn() id: string;
    @column() paymentNumber: bigint;
    @column() customerName: string;
    @column() customerAddress1: string;
    @column() customerAddressCity: string;
    @column() customerAddressState: string;
    @column() customerAddressPostalCode: string;
    @column() customerEmail: string;
    @column() amount: number;
    @column() companyName: string;
    @column() companyId: string;
    @column() companyAddress1: string;
    @column() companyAddressCity: string;
    @column() companyAddressState: string;
    @column() companyAddressPostalCode: string;
    @column() createdDate: Date;
    @column() voidDate: Date;
    @column() type: string;
    @column() brand: string;
    @column() last4: string;
    @column() memo: string;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() checkNumber: string;
    @column() firstInvoiceType: string;
    @column() exportDate: Date;
    @column() deletedDate: Date;
    @column() quickBooksJournalId: string;
    @column() paymentProcessorId: string;
    @column() voidedByName: string;
    @column() loginId: string;
}