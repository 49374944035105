import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService } from '@cogent/client/shared/services/mission-service';
declare var connect: any;

@Component({
    selector: 'app-online-chat',
    templateUrl: './online-chat.component.html',
    styleUrls: ['./online-chat.component.css']
})
export class OnlineChatComponent implements OnInit {
    connecting = false;
    showChat = false;
    hideChatButton = false;

    constructor(private missionService: MissionService,
        private activatedRoute: ActivatedRoute,
        private zone: NgZone) { }

    ngOnInit(): void {
        this.missionService.publish({ type: 'HIDE-MENU-BAR', messageBody: true });

        connect.ChatInterface.init({
            containerId: 'chat-client', // This is the id of the container where you want the widget to reside                    
            headerConfig: {
                isHTML: true,
                render: () => {
                    return (`
                    <div class="chat-header-wrapper">
                        <h2 class="welcome-text">Chat With Us</h2>
                    </div>
                `);
                }
            }
        });

        this.activatedRoute.params.subscribe(params => {


            const name = params.name;
            const username = `${UtilitiesService.newid()}_workorderid_${params.id}`;


            const apiId = 'sg0u1cx8ab';
            const region = 'us-west-2';
            const url = `https://${apiId}.execute-api.${region}.amazonaws.com/Prod`;

            connect.ChatInterface.initiateChat({
                name,
                username,
                customerId: username,
                region,
                apiGatewayEndpoint: url,
                contactAttributes: JSON.stringify({
                    customerName: name,
                    name,
                    customerId: username,
                }),
                contactFlowId: "2b498b7f-75a3-447c-8afa-6046cee328ee",
                instanceId: "56a34e5f-8e7d-42f8-ba87-45e3e6693abe"
            }, chatSession => {
                this.zone.run(() => {
                    this.connecting = false;
                });

                chatSession.onChatDisconnected(data => {
                    this.zone.run(() => {
                        this.showChat = false;
                        this.hideChatButton = false;
                    });
                });
                this.hideChatButton = true;
            }, error => {
                console.error('error');
                console.error(error);
                this.zone.run(() => {
                    this.connecting = false;
                });
            });
        });


    }

}
