import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ContractorTrade, ContractorTradeRegion, County, State, ZipCode } from '@upkeeplabs/models/cogent';
import { ZipCodeSelectorComponent } from '@cogent/client/shared/components/misc/zip-code-selector/zip-code-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { AddPostalCodesComponent } from '@cogent/client/shared/components/contractors/add-postal-codes/add-postal-codes.component';

@Component({
    selector: 'app-configure-areas',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule, ZipCodeSelectorComponent],
    templateUrl: './configure-areas.component.html',
    styleUrls: ['./configure-areas.component.css']
})
export class ConfigureAreasComponent implements OnInit {

    contractorTrades: any[];
    selectedContractorTrade: any;
    zipCodes: ZipCode[];
    states: State[];
    counties: County[];
    allContractorTradeRegions: ContractorTradeRegion[];
    contractorTradeRegions: ContractorTradeRegion[];
    loading = false;

    constructor(private entityApi: EntityApiService,
        private dialog: MatDialog) {

    }
    ngOnInit(): void {
        this.load();
    }

    async load() {
        this.loading = true;
        const user = await this.entityApi.getLoggedInUser();
        this.contractorTrades = await this.entityApi.getContractorTrades(user.id);

        this.zipCodes = await this.entityApi.getZipCodes();
        this.states = await this.entityApi.getStates(this.zipCodes);
        this.counties = await this.entityApi.getCounties(this.states);
        this.allContractorTradeRegions = (await this.entityApi.getContractorTradeRegions(user.id)) as any;
        this.loading = false;
    }

    async selectTrade(trade: ContractorTrade) {
        this.selectedContractorTrade = trade;
        this.contractorTradeRegions = this.allContractorTradeRegions.filter(i => i.contractorTradeId === trade.id && i.serviceProTerritoryId) as any;

        //this.selectedContractorTrade.zipCodes = [];

        //this.selectedContractorTrade.availableZipCodes = [];
        let zips = [];
        for (const region of this.contractorTradeRegions) {
            const zipCodes = await this.entityApi.getZipCodesForEntity(region.serviceProTerritoryId);
            if (region.excludedPostalCodes) {
                const excludedPostalCodes = JSON.parse(region.excludedPostalCodes);
                for (const epc of excludedPostalCodes) {
                    const zp = zipCodes.find(i => i.zip === epc.postalCode);
                    if (zp) {
                        zipCodes.splice(zipCodes.indexOf(zp), 1);
                    }
                }
            }
            zips = zips.concat(zipCodes);
        }

        zips = zips.sort((a, b) => a.zip > b.zip ? 1 : -1);
        this.selectedContractorTrade.zipCodes = zips;


        // this.selectedContractorTrade.zipCodes = this.zipCodes;
        // this.selectedContractorTrade.availableZipCodes = this.zipCodes.map(i=>i.zip);
        this.selectedContractorTrade.availableZipCodes = this.selectedContractorTrade.zipCodes.map(i => i.zip);
    }

    addPostalCodes() {

        const ref = this.dialog.open(AddPostalCodesComponent);
        ref.afterClosed().subscribe(result => {
            if (result) {
                if (result.replaceList) {
                    this.selectedContractorTrade.zipCodes = [];
                }
                const results = result.postalCodes;
                const zipCodes = results.split('\n');
                for (const code of zipCodes) {
                    const foundZip = this.zipCodes.find(i => i.zip === code);
                    if (foundZip) {
                        const selectedFound = this.selectedContractorTrade.zipCodes.find(i => i.zip === foundZip.zip);
                        if (!selectedFound) {
                            this.selectedContractorTrade.zipCodes.push(foundZip);
                        }
                    }


                }
                const zips = this.selectedContractorTrade.zipCodes;
                this.selectedContractorTrade.zipCodes = null;
                setTimeout(() => this.selectedContractorTrade.zipCodes = zips, 100);
            }
        });
    }

    removeZip(zip: ZipCode) {
        this.selectedContractorTrade.zipCodes.splice(this.selectedContractorTrade.zipCodes.indexOf(zip), 1);
        const zips = this.selectedContractorTrade.zipCodes;
        this.selectedContractorTrade.zipCodes = null;
        setTimeout(() => this.selectedContractorTrade.zipCodes = zips, 100);
    }

    saving = false;
    async save() {
        this.saving = true;

        await this.entityApi.updateContractorTradeRegion(this.selectedContractorTrade.id, this.selectedContractorTrade.zipCodes);
        this.saving = false;
    }


}
