import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { NoteAttachmentModel } from '@cogent/shared/models/common/history';
import { Address, CommissionItem, PlanArea, PlanPricingAdjustment, PlanPricingAdjustmentItem, PlanRenewalPlan, RepairItem, TermsAndLimitationItem } from '@upkeeplabs/models/cogent';
import { PolicyCoverageResult } from '@cogent/shared/models/sales/policy-coverage-result.model';
import { PlanSelectionItemModel } from '@cogent/shared/models/sales/plan-selection-item.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { SalesItemCoverageRepairItem } from '@cogent/shared/models/plans/sales-item-coverage-repair-item.model';
import { FileModel } from '@cogent/shared/models/common/file.model';
import { PromiseObserverService } from '@cogent/client/shared/services/promise-observer-service'
import { SalesItemCoverageApplianceCoverageTerm, PromotionCode, PlanStyle, TermsAndLimitation } from '@upkeeplabs/models/cogent';
import { SalesItemCoverageWorkOrderItemClient } from '@cogent/client/shared/models/plans/sales-item-coverage-work-order-item-client.model';
import { CoverageType, DwellingType, PlanClient } from '@cogent/client/shared/models/plans/plan-client.model';
import { PlanItemSummaryClient } from '@cogent/client/shared/models/plans/plan-item-summary-client.model';
import { SalesItemCoverageClient, SalesItemCoverageExtensionClient } from '@cogent/client/shared/models/plans/sales-item-coverage-client.model';
import { SalesItemClient } from '@cogent/client/shared/models/items/sales-item-client.model';
import { SalesItemSummaryClient } from '@cogent/client/shared/models/items/sales-item-summary-client.model';

@Injectable({ providedIn: 'root' })
export class PlanApiService {

    constructor(private api: ApiService) { }

    getPlansForPolicy(policyId: string) {
        return this.api.getArrayDotNet('Plan/Selection/' + policyId, null, () => new PlanClient());
    }
    getPlan(planId: string) {
        return this.api.getArrayDotNet('Plan', { id_eq: planId }, () => new PlanClient());
    }

    saveStaticContract(planId: string, attachment: NoteAttachmentModel) {
        return this.api.postVoidDotNet("Plan/Attachment/" + planId, attachment);
    }
    async getStaticContractExists(planId: string) {
        return (await this.api.getSingleDotNet("Plan/Attachment/" + planId + "/exists")) as boolean;
    }
    removePlanStaticContract(planId: string) {
        return this.api.deleteDotNet("Plan/Attachment/" + planId);
    }

    saveSalesItemCoverageApplianceCoverageTerm(term: SalesItemCoverageApplianceCoverageTerm) {
        return this.api.postNode(`SalesItemCoverageApplianceCoverageTerm`, term);
    }

    deleteSalesItemCoverageApplianceCoverageTerm(term: SalesItemCoverageApplianceCoverageTerm) {
        return this.api.deleteNode(`SalesItemCoverageApplianceCoverageTerm/${term.id}`);
    }

    getApplianceTerms(salesItemCoverageId: string) {
        return this.api.getArrayNode(`SalesItemCoverageApplianceCoverageTerm`, { salesItemCoverageId_eq: salesItemCoverageId, orderby: 'term' })
    }

    public getSalesItems(): Promise<SalesItemClient[]> {
        return this.api.getArrayDotNet('SalesItem', { orderby: 'Name' }, () => new SalesItemClient());
    }

    public getSalesItemSummaries(): Promise<SalesItemSummaryClient[]> {
        return this.api.getArrayDotNet('SalesItemSummary', { orderBy: 'Name' }, () => new SalesItemSummaryClient());
    }

    public saveSalesItem(salesItem: SalesItemClient): Promise<string> {
        return this.api.postIdDotNet('SalesItem', salesItem);
    }

    getPromotionAdjustments(): Promise<PlanPricingAdjustment[]> {
        return this.api.getArrayDotNet('PlanPricingAdjustment', { adjustmentType_eq: 'PromotionAdjustment' });
    }

    checkForCoverage(address: Address): Promise<PolicyCoverageResult> {
        return this.api.postSingleDotNet('Address/IsCovered', address, null, () => new PolicyCoverageResult());
    }

    getPlanStyles(): Promise<PlanStyle[]> {
        return this.api.getArrayNode(`PlanStyle`, null, () => new PlanStyle());
    }

    savePlanStyle(planStyle: PlanStyle): Promise<any> {
        return this.api.postSingleNode(`PlanStyle`, planStyle);
    }

    deletePlanStyle(planStyleId: string): Promise<any> {
        return this.api.deleteNode(`PlanStyle/${planStyleId}`);
    }

    unDeletePlanStyle(planStyleId: string): Promise<any> {
        return this.api.unDeleteNode(`PlanStyle/${planStyleId}`);
    }

    getUniqueSortedPlans(plans: PlanClient[]): PlanClient[] {
        const uniquePlans: PlanClient[] = [];
        for (const plan of plans) {
            const found = uniquePlans.find(p => p.id == plan.id);
            if (!found) {
                uniquePlans.push(plan);
            }
        }

        uniquePlans.sort((a, b) => {
            if (!a.name && b.name) return -1;
            if (!b.name && a.name) return 1;
            if (a.name && b.name) {
                if (a.name.trim() < b.name.trim()) return -1;
                if (a.name.trim() > b.name.trim()) return 1;
            }
            if (!a.versionId && b.versionId) return -1;
            if (!b.versionId && a.versionId) return 1;
            if (a.versionId && b.versionId) {
                if (a.versionId.trim() < b.versionId.trim()) return -1;
                if (a.versionId.trim() > b.versionId.trim()) return 1;
            }
            return 0;
        });

        return uniquePlans;
    }

    async findActivePlansByNamePostalCode(searchFor: string, postalCode: string) {
        const plans1 = await this.api.getArrayNode(`PlanZipCodeSummary`, {
            name_like: searchFor,
            activedatestart_lt: new Date(),
            activedateend_gt: new Date(),
            zipCode_eq: postalCode,
            properties: 'Id,Name,Summary,VersionId'
        }, () => new PlanClient());

        const plans2 = await this.api.getArrayNode(`PlanZipCodeSummary`, {
            versionId_like: searchFor,
            activedatestart_lt: new Date(),
            activedateend_gt: new Date(),
            zipCode_eq: postalCode,
            properties: 'Id,Name,Summary,VersionId'
        }, () => new PlanClient());

        const plans = plans1.concat(plans2);
        return this.getUniqueSortedPlans(plans);
    }


    async findPlansByName(searchFor: string): Promise<PlanClient[]> {
        const plans1 = await this.api.getArrayNode(`Plan`, {
            name_like: searchFor,
            properties: 'Id,Name,Summary,VersionId'
        }, () => new PlanClient());

        const plans2 = await this.api.getArrayNode(`Plan`, {
            versionId_like: searchFor,
            properties: 'Id,Name,Summary,VersionId'
        }, () => new PlanClient());
        const plans = plans1.concat(plans2);

        return this.getUniqueSortedPlans(plans);
    }

    async getActivePlans(coverageType?: CoverageType, dwellingType?: DwellingType, showInactive = false): Promise<PlanClient[]> {

        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();

        let queryParams: any = {};
        if (!showInactive) {
            queryParams = {
                where: '(ActiveDateEnd == null || ActiveDateEnd >= DateTime(' + year + ',' + month + ',' + day + '))'
            };
        } else {
            queryParams = {
                where: 'DeletedDate == null'
            };
        }

        if (coverageType !== null) {
            if (coverageType === CoverageType.Homeowner) {
                queryParams.where += ' && IsHomeownerCoverage == true';
            } else if (coverageType === CoverageType.RealEstate) {
                queryParams.where += ' && IsRealEstateCoverage == true';
            } else if (coverageType === CoverageType.Renewal) {
                queryParams.where += ' && IsRenewalCoverage == true';
            }
        }
        if (dwellingType !== null) {
            if (dwellingType === DwellingType.Condominium) {
                queryParams.where += ' && IsCondo == true';
            }
            if (dwellingType === DwellingType.Condo) {
                queryParams.where += ' && IsCondo == true';
            }
            if (dwellingType === DwellingType.SingleFamily) {
                queryParams.where += ' && IsSingleFamily == true';
            }
            if (dwellingType === DwellingType.Duplex) {
                queryParams.where += ' && IsDuplex == true';
            }
            if (dwellingType === DwellingType.Triplex) {
                queryParams.where += ' && IsTriplex == true';
            }
            if (dwellingType === DwellingType.Quadruplex) {
                queryParams.where += ' && IsFourplex == true';
            }
            if (dwellingType === DwellingType.Fourplex) {

            }
        }
        queryParams.orderby = 'name';
        const plans = await this.api.getArrayDotNet('plan', queryParams, () => new PlanClient());

        return this.getUniqueSortedPlans(plans);
    }

    async getOfferedPlans(
        coverageType: CoverageType, dwellingType: DwellingType, postalCode: string,
        squareFootage: number, ageOfHome: number, originalPolicyId: string = null, latitude: number = null,
        longitude: number = null, entityId: string = null, newConstruction: boolean = null
    ): Promise<PlanClient[]> {
        const plans = await this.api.getArrayDotNet('Plan/Selection', {
            coverageType, dwellingType, postalCode, squareFootage, ageOfHome, originalPolicyId, latitude, longitude, entityId, newConstruction
        }, () => new PlanClient());

        plans.forEach(p => p.coverageType = coverageType);
        return plans;
    }

    getPlanOfferingByPlanId(planId: string, coverageType: CoverageType, dwellingType: DwellingType, postalCode: string,
        squareFootage: number, ageOfHome: number, originalPolicyId: string = null, latitude: number = null,
        longitude: number = null,
    ) {
        return this.api.getSingleDotNet(`plan/selection-by-plan-id`, {
            planId, coverageType, dwellingType, postalCode, squareFootage, ageOfHome, originalPolicyId, latitude, longitude
        });
    }

    getPlanItemSummaries(planId: string): Promise<PlanItemSummaryClient[]> {
        return this.api.getArrayDotNet('PlanItemSummary',
            { planId_eq: planId, orderby: 'sort,name' }, () => new PlanItemSummaryClient());
    }

    async saveCommissionItem(item: CommissionItem): Promise<CommissionItem> {
        return this.api.postSingleNode(`CommissionItem`, item);
    }

    savePlan(plan: PlanClient): Promise<string> {
        if (plan.proRataRefundPct > 1) {
            plan.proRataRefundPct /= 100;
        }
        return this.api.postIdDotNet('Plan', plan);
    }

    deletePlan(plan: PlanClient): Promise<any> {
        return this.api.deleteDotNet('Plan/' + plan.id);
    }

    savePlanItem(planItem: PlanItemSummaryClient) {
        return this.api.postIdDotNet('PlanItem', planItem);
    }

    deletePlanItem(planItem: PlanItemSummaryClient) {
        return this.api.deleteDotNet('PlanItem/' + planItem.id);
    }

    getPlanPricingAdjustments(): Promise<PlanPricingAdjustment[]> {
        return this.api.getArrayDotNet('PlanPricingAdjustment', { orderBy: 'adjustmenttype, name' }, () => new PlanPricingAdjustment());
    }

    getMiscellaneousPricingAdjustments(): Promise<PlanPricingAdjustment[]> {
        return this.api.getArrayDotNet('PlanPricingAdjustment', { adjustmentType_eq: 'Miscellaneous' }, () => new PlanPricingAdjustment());
    }

    getPromotionCodes(): Promise<PromotionCode[]> {
        return this.api.getArrayNode('PromotionCode', { oneTimeUse_eq: false, orderBy: 'code' }, () => new PromotionCode());
    }

    async getUnexpiredPromotionCodes(): Promise<PromotionCode[]> {

        const dt  = new Date();
        const nonExpiring = await this.api.getArrayNode('PromotionCode', {oneTimeUse_eq: false, expirationDate_eq: '{{null}}'}, ()=> new PromotionCode());
        const nonExpired = await this.api.getArrayNode('PromotionCode', {oneTimeUse_eq: false, expirationDate_gte: dt}, ()=> new PromotionCode());

        const result =  nonExpired.concat(nonExpiring);
        result.sort((a,b)=>a.code > b.code ? 1 : -1);
        return result;
    }

    savePromotionCode(promotionCode: PromotionCode): Promise<string> {
        return this.api.postIdNode('PromotionCode', promotionCode);
    }

    getPlanPricingAdjustmentItems(planPricingAdjustmentId: string): Promise<PlanPricingAdjustmentItem[]> {
        return this.api.getArrayDotNet('PlanPricingAdjustmentItem',
            { PlanPricingAdjustmentId_eq: planPricingAdjustmentId, orderby: 'MinValue,AppliesToBuyer' },
            () => new PlanPricingAdjustmentItem());
    }

    savePlanPricingAdjustment(adjustment: PlanPricingAdjustment): Promise<string> {
        return this.api.postIdDotNet('PlanPricingAdjustment', adjustment);
    }

    savePlanPricingAdjustmentItem(item: PlanPricingAdjustmentItem): Promise<string> {
        return this.api.postIdDotNet('PlanPricingAdjustmentItem', item);
    }

    deletePlanPricingAdjustment(adjustment: PlanPricingAdjustment) {
        return this.api.deleteDotNet('PlanPricingAdjustment/' + adjustment.id);
    }

    deletePlanPricingAdjustmentItem(adjustmentItem: PlanPricingAdjustmentItem) {
        return this.api.deleteDotNet('PlanPricingAdjustmentItem/' + adjustmentItem.id);
    }

    undoPlanPricingAdjustmentDelete(adjustment: PlanPricingAdjustment) {
        return this.api.unDeleteDotNet('PlanPricingAdjustment/' + adjustment.id);
    }

    undoPlanDelete(plan: PlanClient): Promise<any> {
        return this.api.unDeleteDotNet('Plan/' + plan.id);
    }

    getSalesItemCoverages(salesItemId: string): Promise<SalesItemCoverageClient[]> {
        return this.api.getArrayDotNet('SalesItemCoverage',
            { salesItemId_eq: salesItemId, orderby: 'name' }, () => new SalesItemCoverageClient());
    }

    getSalesItemCoverage(id: string): Promise<SalesItemCoverageClient> {
        return this.api.getSingleDotNet(`SalesItemCoverage/${id}`, null, () => new SalesItemCoverageClient());
    }

    getPreviewContractHtml(plan: PlanClient, options: any[]): Promise<string> {
        return this.api.postTextDotNet(`Plan/test-contract/html`, {
            plan,
            coverages: options
        });
    }

    getPreviewContractHtmlNode(planId: string) {
        return this.api.getTextNode(`plan/contract/html/${planId}`);
    }

    getSalesItemCoverageWorkOrderItems(salesItemCoverageId: string): Promise<SalesItemCoverageWorkOrderItemClient[]> {
        return this.api.getArrayDotNet('SalesItemCoverageWorkOrderItem',
            { salesItemCoverageId_eq: salesItemCoverageId }, () => new SalesItemCoverageWorkOrderItemClient());
    }

    getSalesItemCoverageRepairItems(salesItemCoverageId: string): Promise<SalesItemCoverageRepairItem[]> {
        return this.api.getArrayDotNet(`SalesItemCoverageRepairItemSummary`, {
            salesItemCoverageId_eq: salesItemCoverageId,
            orderBy: 'repairItemName'
        }, () => new SalesItemCoverageRepairItem());
    }

    getRepairItems(salesItemCoverageId: string): Promise<RepairItem[]> {
        return this.api.getArrayDotNet(`SalesItemCoverage/${salesItemCoverageId}/Repair-Items`, { orderBy: 'name' }, () => new RepairItem());
    }

    getSalesItemCoverageExtension(salesItemCoverageId: string): Promise<SalesItemCoverageExtensionClient[]> {
        return this.api.getArrayDotNet('SalesItemCoverageExtension', {
            salesItemCoverageId_eq: salesItemCoverageId
        }, () => new SalesItemCoverageExtensionClient());
    }

    savePlanSalesItemCoverage(salesItemCoverage: SalesItemCoverageClient): Promise<string> {
        return this.api.postIdDotNet('SalesItemCoverage', salesItemCoverage);
    }

    savePlanSalesItemCoverageWorkOrderItem(salesItemCoverageWorkOrderItem: SalesItemCoverageWorkOrderItemClient): Promise<string> {
        return this.api.postIdDotNet('SalesItemCoverageWorkOrderItem',
            salesItemCoverageWorkOrderItem);
    }

    saveSalesItemCoverageExtension(extension: SalesItemCoverageExtensionClient) {
        return this.api.postIdDotNet('SalesItemCoverageExtension', extension);
    }

    saveSalesItemCoverageRepairItem(salesItemCoverageRepairItem: SalesItemCoverageRepairItem) {
        return this.api.postIdDotNet(`SalesItemCoverageRepairItem`, salesItemCoverageRepairItem);
    }

    deletePlanSalesIemCoverageWorkOrderItem(salesItemCoverageWorkOrderItemId: string) {
        return this.api.deleteDotNet('SalesItemCoverageWorkOrderItem/' + salesItemCoverageWorkOrderItemId);
    }

    deleteSalesItemCoverageRepairItem(id: string) {
        return this.api.deleteDotNet(`SalesItemCoverageRepairItem/${id}`);
    }

    deleteSalesItemCoverage(salesItemCoverage: SalesItemCoverageClient) {
        return this.api.deleteDotNet('SalesItemCoverage/' + salesItemCoverage.id);
    }

    deleteSalesItemCoverageWorkOrderItem(salesItemCoverageWorkOrderItem: SalesItemCoverageWorkOrderItemClient) {
        return this.api.deleteDotNet('SalesItemCoverageWorkOrderItem/' + salesItemCoverageWorkOrderItem.id);
    }

    getPlanItemDetail(id: string) {
        return this.api.getSingleDotNet('PlanItem/' + id + '/detail');
    }

    getPlanOptionalItems(planId: string) {
        return this.api.getArrayDotNet('Plan/' + planId + '/options', null, () => new PlanSelectionItemModel());
    }

    getTermsAndLimitations(): Promise<TermsAndLimitation[]> {
        return this.api.getArrayNode('TermsAndLimitation', null, () => new TermsAndLimitation());
    }

    getActiveTermsAndLimitations(): Promise<TermsAndLimitation[]> {
        return this.api.getArrayDotNet('TermsAndLimitation',
            { inactive_eq: false }, () => new TermsAndLimitation());
    }

    getContractBase64(planId: string): Promise<string> {
        return this.api.getTextDotNet(`Plan/${planId}/contract/base64`);
    }

    getTermsAndLimitationItems(termsAndLimitationId: string): Promise<TermsAndLimitationItem[]> {
        return this.api.getArrayNode('TermsAndLimitationItem',
            { TermsAndLimitationId_eq: termsAndLimitationId, orderby: 'SortOrder' }, () => new TermsAndLimitationItem());
    }

    saveTermsAndLimitation(termsAndLimitation: TermsAndLimitation, items: TermsAndLimitationItem[], itemsToDelete: TermsAndLimitationItem[]): Promise<any> {
        return new Promise(resolve => {
            if (!termsAndLimitation.id) {
                termsAndLimitation.id = UtilitiesService.newid();
            }
            const observer = new PromiseObserverService(() => resolve(null));
            observer.addPromise(this.api.postIdNode('TermsAndLimitation', termsAndLimitation));

            if (items) {
                items.forEach(item => {
                    item.termsAndLimitationId = termsAndLimitation.id;
                    this.api.postIdNode('TermsAndLimitationItem', item);
                });
            }

            if (itemsToDelete) {
                itemsToDelete.forEach(item => this.api.deleteNode('TermsAndLimitationItem/' + item.id));
            }
        });
    }

    getPlanTemplates(): Promise<FileModel[]> {
        return this.api.getArrayDotNet('plan/templates', null, () => new FileModel());
    }

    getPlanRenewalPlans(planId: string): Promise<PlanRenewalPlan[]> {
        return this.api.getArrayDotNet('PlanRenewalPlan', { planId_eq: planId }, () => new PlanRenewalPlan());
    }

    savePlanRenewalPlan(planRenewalPlan: PlanRenewalPlan): Promise<string> {
        return this.api.postIdDotNet('PlanRenewalPlan', planRenewalPlan);
    }

    deletePlanRenewalPlan(planRenewalPlan: PlanRenewalPlan) {
        return this.api.deleteDotNet('PlanRenewalPlan/' + planRenewalPlan.id);
    }

    getRenewalPlanOptions(planId: string, originalPolicyId: string): Promise<PlanSelectionItemModel[]> {
        return this.api.getArrayDotNet('Plan/' + planId + '/' + originalPolicyId + '/RenewalOptions', null, () => new PlanSelectionItemModel());
    }

    getPlanAreas(planId: string): Promise<PlanArea[]> {
        return this.api.getArrayDotNet('PlanArea', { planId_eq: planId }, () => new PlanArea());
    }

    savePlanArea(planArea: PlanArea): Promise<string> {
        return this.api.postIdDotNet('PlanArea', planArea);
    }

    deletePlanArea(planArea: PlanArea) {
        return this.api.deleteDotNet('PlanArea/' + planArea.id);
    }
}