import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { Template } from "./template.model";

@dataSource()
export class CommunicationBatch {
    @keyColumn() id: string;
    @column() name: string;
    @column() configuration: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() currentStatus: string;
    @column() archivedDate: Date;
    @column() lastQueueTime: Date;
    @column() nextQueueTime: Date;



    status: BatchJobStatus;

    private _args: BatchCommunicationArgs;
    get args(): BatchCommunicationArgs {
        if (!this._args) {
            try {
                this._args = JSON.parse(this.configuration);
            } catch { }
        }

        return this._args;
    }


    get emailPending() {
        return this.status?.statuses?.find(i => i.type === 'Email')?.pending;
    }

    get emailQueued() {
        return this.status?.statuses?.find(i => i.type === 'Email')?.queued;
    }

    get emailInProcess() {
        return this.status?.statuses?.find(i => i.type === 'Email')?.inProcess;
    }

    get emailSucceeded() {
        return this.status?.statuses?.find(i => i.type === 'Email')?.succeeded;
    }

    get emailFailed() {
        return this.status?.statuses?.find(i => i.type === 'Email')?.failed;
    }


    get smsPending() {
        return this.status?.statuses?.find(i => i.type === 'SMS')?.pending;
    }

    get smsQueued() {
        return this.status?.statuses?.find(i => i.type === 'SMS')?.queued;
    }

    get smsInProcess() {
        return this.status?.statuses?.find(i => i.type === 'SMS')?.inProcess;
    }

    get smsSucceeded() {
        return this.status?.statuses?.find(i => i.type === 'SMS')?.succeeded;
    }

    get smsFailed() {
        return this.status?.statuses?.find(i => i.type === 'SMS')?.failed;
    }


    get functionPending() {
        return this.status?.statuses?.find(i => i.type === 'Function')?.pending;
    }

    get functionQueued() {
        return this.status?.statuses?.find(i => i.type === 'Function')?.queued;
    }

    get functionInProcess() {
        return this.status?.statuses?.find(i => i.type === 'Function')?.inProcess;
    }

    get functionSucceeded() {
        return this.status?.statuses?.find(i => i.type === 'Function')?.succeeded;
    }

    get functionFailed() {
        return this.status?.statuses?.find(i => i.type === 'Function')?.failed;
    }



}

export function doJsonMapping(item: any, args: BatchCommunicationArgs) {
    if (args.jsonMappings) {
        for (const mapping of args.jsonMappings) {
            if (item[mapping.sourceField.toLocaleLowerCase()]) {
                try {
                    item[mapping.destinationName] = JSON.parse(item[mapping.sourceField.toLocaleLowerCase()]);
                } catch { }
            }
        }
    }
}

export class BatchCommunicationArgs {
    sql: string;
    schemaItems: SchemaItem[];
    mappings: BatchCommunicationMapping[];
    sendEmail: boolean;
    sendSMS: boolean;
    runFunction: boolean;
    emailConfig: BatchCommunicationEmailConfig = new BatchCommunicationEmailConfig();
    sampleData: any[];
    companyInfo: any;
    singleton: any;
    sampleDataIndex = 0;
    smsConfig: BatchCommunicationSMSConfig = new BatchCommunicationSMSConfig();
    schedule: BatchCommunicationSchedule = new BatchCommunicationSchedule();
    function: BatchCommunicationFunctionConfig = new BatchCommunicationFunctionConfig();
    name: string;
    jsonMappings: JsonFieldMapping[];
    dataSourceType: 'QUERY' | 'CSV';

    attachToEntityType: 'Policy' | 'WorkOrder' | 'Task' | 'MaintenanceServiceProperty' | 'Entity';

    attachToEntityId: string;
    id: string;

}

export class SchemaItem {
    name: string;
    dataType: string;
    maxLength: number;
    mapToField?: string;
    nonMappable?: boolean;
}

export class BatchCommunicationMapping {
    knownFieldType: string;
    schemaItem?: SchemaItem;
}

export class BatchCommunicationSMSConfig {
    message: string;
    from: string;
}

export class BatchCommunicationFunctionConfig {
    functionDefinition: string;
}

export class BatchCommunicationEmailConfig {
    subject: string;
    message: string;
    bcc: string;
    cc: string;
    from: string;
    layoutTemplate: FileModel;
    ipPool: any;
    unsubscribeGroup: any;
    template: Template;


}

export class JsonFieldMapping {
    sourceField: string;
    destinationName: string;
}

export class FileModel {
    path: string;
    name: string;
    fileUri: string;
    file: string;

    //client-side only
    templateText: string;
}


export class BatchCommunicationSchedule {
    startDate: Date = new Date();
    endDate?: Date;

    maxPerDay: number = 2000;
    minHourOfDay: number = 9;
    maxHourOfDay: number = 20;
    maxPerHour: number;


}


export class BatchJobStatus {
    name: string;
    createdDate: Date;
    statuses: BatchJobStatusType[];

    // emailStatus: BatchJobStatusType;
    // smsStatus: BatchJobStatusType;
    // functionStatus: BatchJobStatusType;
}

export class BatchJobStatusType {

    type: 'Email' | 'SMS' | 'Function';
    pending: number;
    queued: number;
    inProcess: number;
    succeeded: number;
    failed: number;
}
