import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FollowUpStat {
    @keyColumn() id: string;
    @column() statDate: Date;
    @column() assignedTo: string;
    @column() assignedToUserId: string;
    @column() jobsActive: number;
    @column() jobsInRed: number;
    @column() jobsInYellow: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}