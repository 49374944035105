import { DatePipe } from '@angular/common';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
import { PurchaseOrderItem, PurchaseOrderItemSummary, PurchaseOrderSummary } from '@upkeeplabs/models/cogent';

export class PurchaseOrderSummaryClient extends PurchaseOrderSummary {


    get workOrderItemThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}WorkOrderItem/${this.workOrderItemId}/Photo`;
    }

    get createdByThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.createdById}`;
    }

    get approvedByThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.approvedById}`;
    }


    get sentByThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.sentById}`;
    }

    get receivedByThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.receivedById}`;
    }

    get formattedContractorPhoneNumber() {
        return UtilitiesService.formatPhoneNumber(this.contractorPhone);
    }

    // Client-side only
    selected: boolean;
    items: PurchaseOrderItem[];
    summaryItems: PurchaseOrderItemSummary[];

    private _formattedEstimatedDeliveryDate: string;
    get formattedEstimatedDeliveryDate(): string {
        if (!this._formattedEstimatedDeliveryDate && this.estimatedDeliveryDate) {
            const datePipe = new DatePipe('en-US');
            this._formattedEstimatedDeliveryDate = datePipe.transform(this.estimatedDeliveryDate, 'shortDate');
        }

        return this._formattedEstimatedDeliveryDate;
    }
}
