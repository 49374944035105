import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class PurchaseOrder {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() vendorId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() sentDate: Date;
    @column() approvedDate: Date;
    @column() notes: string;
    @column() receivedDate: Date;
    @column() approvedById: string;
    @column() sentById: string;
    @column() receivedById: string;
    @readOnlyColumn() purchaseOrderNumber: bigint;
    @column() exportDate: Date;
    @column() quickBooksId: string;
    // @column() version: timestamp;
    @column() invoiceDate: Date;
    @column() purchaseOrderStatusId: string;
    @column() trackingNumber: string;
    @column() vendorNumber: string;
    @column() lastHomeownerNotificationDate: Date;
    @column() lastServiceProNotificationDate: Date;
    @column() estimatedDeliveryDate: Date;
    @column() shipping: number;
    @column() tax: number;
    @column() shipperId: string;
    @column() deliveryDate: Date;
    @column() lastTrackingData: string;
    @column() lastTrackingDate: Date;
    @column() note: string;
    @column() followUpAppointmentRequestSentDate: Date;
    @column() followUpAppointmentRequestAcceptedDate: Date;
    @column() isReturn: boolean;
    @column() isEquipmentReplacement: boolean;
    @column() restockingFee: number;
}