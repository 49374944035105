import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { AppliancePartDetail, AppliancePartDetailWarehouseAvailability } from ".";

@dataSource()
export class PartToOrderSummary {
    @keyColumn() id: string;
    @column() workOrderLineId: string;
    @column() partNumber: string;
    @column() description: string;
    @column() cost: number;
    @column() quantity: number;
    @column() dateOrdered: Date;
    @column() workOrderId: string;
    @column() workOrderNumber: bigint;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() createdDate: Date;
    @column() workOrderDate: Date;
    @column() manufacturer: string;
    @column() manufacturerCode: string;
    @column() modelNumber: string;
    @column() encompassMfgCode: string;
    @column() reliableMfgCode: string;
    @column() marconeMake: string;
    @column() encompassBasePN: string;
    @column() contractorAddress1: string;
    @column() contractorAddress2: string;
    @column() contractorCity: string;
    @column() contractorState: string;
    @column() contractorPostalCode: string;
    @column() propertyPostalCode: string;
    @column() reliablePartId: string;

    orderDetails: AppliancePartDetail[];
    expanded = false;
    orderLocation: AppliancePartDetailWarehouseAvailability;
    appliancePartDetail: AppliancePartDetail;
    selectedOption: any;
    selectedBackorder: any;
    selected: boolean;

    get showAll() {
        if (!this.orderDetails) {
            return false;
        }

        return this.orderDetails.filter(i => i.showAll).length === this.orderDetails.length;
    }
    set showAll(value: boolean) {
        for (const detail of this.orderDetails) {
            detail.showAll = value;
        }
    }
}
