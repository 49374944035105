import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class WebSocketMessage {
    @keyColumn() id: string;
    @column() message: string;
    @column() userId: string;
    @column() roleId: string;
    @column() serviceId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
}
