import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AddressStandard {
    @keyColumn() id: string;
    @column() rawAddress: string;
    @column() rawCity: string;
    @column() rawState: string;
    @column() rawZip5: string;
    @column() address: string;
    @column() city: string;
    @column() state: string;
    @column() zip5: string;
    @column() zip4: string;
    @column() uspsMetadata: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}