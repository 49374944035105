import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Tag {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() appliesToWorkOrder: boolean;
    @column() appliesToPolicy: boolean;
    @column() appliesToEntity: boolean;
    @column() appliesToTask: boolean;
    @column() appliesToContractorInvoice: boolean;
    @column() appliesToPurchaseOrder: boolean;
    @column() appliesToInvoice: boolean;
    @column() isHighlighted: boolean;
    @column() appliesToCustomTable: boolean;
    @column() appliesToRenewal: boolean;
    @column() expiresInHours: number;
    @column() hideOnBoardsAndQueues?: boolean;
    @column() showInHistory?: boolean;
    @column() AppliesToMaintenanceService?: boolean;

    exists?: boolean;
    save?: boolean;
    editing = false;
    saving = false;
}

