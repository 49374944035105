import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

export class HistoryItem {
    type: string;
    url: string;
    descriptionLine1: string;
    descriptionLine2: string;
    descriptionLine3: string;
    descriptionLine4: string;
    id: string;
    deletedDate?: Date;
    createdById?: string;
    lastModifiedById?: string;
    createdDate?: Date;
    lastModifiedDate?: Date;

    constructor(descriptionLine1 = '', descriptionLine2 = '', descriptionLine3 = '', url = '', type = '', createdDate: Date = null) {
        this.descriptionLine1 = descriptionLine1;
        this.descriptionLine2 = descriptionLine2;
        this.descriptionLine3 = descriptionLine3;
        this.url = url;
        this.type = type;
        this.createdDate = createdDate;
    }


    get routerLink() {
        if (this.type === 'function') {
            return `/settings/function-editor/${this.url}`;
        }
        if (this.url.indexOf(";") >= 0) {
            const array = [];
            const sections = this.url.split(";");
            array.push(sections[0]);
            const options: any = {};
            for (let i = 1; i < sections.length; i++) {
                const parts = sections[i].split('=');
                options[parts[0]] = parts[1];
            }
            array.push(options);
            return array;
        }
        return this.url;
    }
    public get iconClass(): string {

        if (this.type === 'Policy') {
            return 'folder';
        }

        if (this.type === 'Claim') {
            return 'build';
        }

        if (this.type === 'WO') {
            return 'build';
        }

        if (this.type === 'Entity') {
            return 'person';
        }
        if (this.type === 'Contractor-View') {
            return 'local_shipping';
        }
        if (this.type === 'RENEWAL_CENTER_CALL') {
            return 'refresh';
        }
        if (this.type === 'WELCOME_CENTER_CALL') {
            return 'call_made';
        }
        if (this.type === 'MaintServiceProperty') {
            return 'cleaning_services';
        }
        if(this.type === 'function') {
            if(this.descriptionLine3) {
                return this.descriptionLine3
            } else {
                return 'psychology'
            }
        }

        return 'folder';
    }

    public get grouping(): string {
        return UtilitiesService.getDateGrouping(new Date(this.createdDate));
    }
}
