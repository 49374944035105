<svg id="Layer_1" data-name="Layer 1" [style.height]="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 113">
    <defs>
      <style>
      @keyframes fadeIn {
          from {
              opacity: 0;
              transform: translateY(-10px) scale(.5);
          }
          90% {
              transform: translateY(8px) scale(1.05);
          }
          to {
              opacity: 1;
              transform: translateY(6px) scale(1);
          }
        }
        .cls-1 {
          fill: #39b54a;
      animation-name: fadeIn;
      animation-duration: 1.5s;
      animation-timing-function: ease;
      transform-box: fill-box;
      transform-origin: center;
      transform: translateY(6px) scale(1);
        }
  
        .cls-1, .cls-2, .cls-3 {
          stroke: #29abe2;
          stroke-miterlimit: 10;
        }
  
        .cls-2 {
          fill: #fff;
        }
  
        .cls-3 {
          fill: #f2f2f2;
        }
  
      </style>
    </defs>
    <g>
      <path class="cls-3" d="M120.5,83.34c0-.14-.03-.26-.04-.4l-.09-13.91-5.91-40.96c-.26-1.8-2.64-3.18-5.49-3.18H12.03c-2.85,0-5.23,1.38-5.49,3.18L.66,68.86l-.12,14.06c-.01,.14-.04,.27-.04,.41v5.09c0,2.19,1.77,3.96,3.96,3.96H116.54c2.03,0,3.68-1.53,3.91-3.5h.05s0-.43,0-.43c0,0,0-.02,0-.03v-5.09Z"/>
      <path class="cls-2" d="M112.46,60.98l-4.03-27.18c-.25-1.66-2.16-2.92-4.44-2.92H16.01c-2.28,0-4.2,1.26-4.44,2.92l-4.01,27.07c-.3,2,1.84,3.74,4.57,3.74h29.64c2.28,0,4.21,1.14,4.88,2.74,1.81,4.36,7.32,7.53,13.85,7.53s11.89-3.08,13.78-7.34c.78-1.76,3.04-2.93,5.56-2.93h28.18c2.66,0,4.73-1.69,4.44-3.63Z"/>
    </g>
    <path class="cls-1" d="M95.05,2.26L62.12,42.79c-1.65,2.02-4.59,2.35-6.59,.73l-15.81-12.85c-1.99-1.62-4.94-1.29-6.59,.73l-3.55,4.37c-1.65,2.02-1.36,4.98,.63,6.6l19.42,15.78,7.91,6.43c1.99,1.62,4.94,1.29,6.59-.73l6.53-8.03L106.57,11.62c1.65-2.02,1.36-4.98-.63-6.6l-4.3-3.49c-1.99-1.62-4.94-1.29-6.59,.73Z"/>
    <!-- <rect class="cls-1-1" x="4.5" y=".5" width="14" height="9"/> -->
  </svg>