import { StripeCard } from "./stripe-card.model";

export class StripeCustomerModel {
    account_balance: number;
    created: string;
    defaultSourceId: string;
    description: string;
    email: string;
    id: string;
    sources: StripeSourceModel;
}

export class StripeSourceModel {
    total_count: number;
    data: StripeSourceItem[];
    has_more: boolean;
    url: string;
}

export class StripeSourceItem {
    bankAccount: StripeBankAccount;
    card: StripeCard;
    id: string;

}

// export class StripeCard {
//     accountId: string;
//     address_city: string;
//     address_country: string;
//     address_line1: string;
//     address_line1_check: string;
//     address_line2: string;
//     address_state: string;
//     address_zip: string;
//     address_zip_check: string;
//     available_payout_methods: string;
//     brand: string;
//     country: string;
//     currency: string;
//     cvc_check: string;
//     default_for_currency: boolean;
//     dynamic_last4: string;
//     exp_month: number;
//     exp_year: number;
//     fingerprint: string;
//     funding: string;
//     id: string;
//     last4: string;
//     name: string;
//     object: string;
//     recipientId: string;
//     stripeResponse: string;
//     three_d_secure: string;
//     tokenization_method: string;
//     customer: string;
// }

export class StripeBankAccount {

}
