import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceJobOfferSummary {
    @keyColumn() id: string;
    @column() sentDate: Date;
    @column() preferredDay: number;
    @column() preferredDayName: string;
    @column() shortSummary: string;
    @column() name: string;
    @column() contractLanguage: string;
    @column() color: string;
    @column() frequencyName: string;
    @column() visitsPerYear: number;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() contractorName: string;
    @column() officeNumber: string;
    @column() contractorEmail: string;
    @column() contactViaEmail: boolean;
    @column() contactViaSMSText: boolean;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerPhoneNumber: string;
    @column() latitude: number;
    @column() longitude: number;
    @column() maintenanceServiceId: string;
    @column() maintenanceServiceOfferingId: string;
    @column() accepted: Date;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() contractorId: string;
    @column() cost: number;
    @column() rejectedDate: Date;
    @column() createdDate: Date;
    @column() schedulingBufferDays: number;
    @column() costPerMonth: number;
    @column() pricePerMonth: number;
    @column() initialMonthPrice: number;
    @column() bedroomCount?:number;
    @column() bathroomCount?:number;
}