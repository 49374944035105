import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FavorFundWeightItem {
    @keyColumn() id: string;
    @column() month: number;
    @column() weight: number;
    @column() favorFundWeightId: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate: Date;
    
}