import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CallCampaignItem {
    @keyColumn() id: string;
    @column() callCampaignId: string;
    @column() policyId: string;
    @column() completedDate: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() callDate: Date;
    @column() dateLocked: Date;
    @column() lockedById: string;
}