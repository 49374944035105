import { keyColumn, column, dataSource } from "@upkeeplabs/linq"; @dataSource()

export class PlanGroup {
    @keyColumn() id: string;
    @column() name: string;
    @column() summary: string;
    @column() plansJson: string;
    @column() standardItemsJson: string;
    @column() optionalItemsJson: string;
    @column() onDemandServicesJson: string;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;

    get plans(): readonly string[] {
        if (this.plansJson)
            return JSON.parse(this.plansJson);
        return [];
    }
    addPlan(planId) {
        const plans = this.plans as string[];
        plans.push(planId);
        this.plansJson = JSON.stringify(plans);
    }
    removePlan(planId) {
        this.plansJson = JSON.stringify(this.plans.filter(p => p != planId));
    }

    get standardItems(): readonly string[] {
        if (this.standardItemsJson)
            return JSON.parse(this.standardItemsJson);
        return [];
    }
    addStandardItem(itemId) {
        const items = this.standardItems as string[];
        items.push(itemId);
        this.standardItemsJson = JSON.stringify(items);
    }
    removeStandardItem(itemId) {
        this.standardItemsJson = JSON.stringify(this.standardItems.filter(i => i != itemId));
    }

    get optionalItems(): readonly string[] {
        if (this.optionalItemsJson)
            return JSON.parse(this.optionalItemsJson);
        return [];
    }
    addOptionalItem(itemId) {
        const items = this.optionalItems as string[];
        items.push(itemId);
        this.optionalItemsJson = JSON.stringify(items);
    }
    removeOptionalItem(itemId) {
        this.optionalItemsJson = JSON.stringify(this.optionalItems.filter(i => i != itemId));
    }


    get onDemandServices(): readonly string[] {
        if (this.onDemandServicesJson)
            return JSON.parse(this.onDemandServicesJson);
        return [];
    }
    addOnDemandService(itemId) {
        const items = this.onDemandServices as string[];
        items.push(itemId);
        this.onDemandServicesJson = JSON.stringify(items);
    }
    removeOnDemandService(itemId) {
        this.onDemandServicesJson = JSON.stringify(this.onDemandServices.filter(i => i != itemId));
    }
}