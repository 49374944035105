<div id="background-image-container" *ngIf="selectedIndex !== 22"></div>
<div class="container " style="padding-bottom: 100px;">
    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab>
            <div class="center" bottommargin30>
                <h1>Pending Offers</h1>
            </div>

            <div class="overview-boxes">
                <mat-card>
                    <div class="data-label" *ngIf="pending && pending.length">New Job Requests</div>
                    <a mat-button routerLink="/accept-maintenance-job/{{customer.id}}" class="list-button"
                        *ngFor="let customer of pending">
                        <div aflex>
                            <div nogrow>
                                <mat-icon>schedule</mat-icon>
                            </div>
                            <div>
                                <h3>{{customer.name}}</h3>
                                <!-- <div *ngIf="customer.customerEmail">{{customer.customerEmail}}</div> -->
                                <div>
                                    <div>
                                        <span class="data-label">Preferred Day:</span> {{customer.preferredDayName}}
                                    </div>
                                    <div>
                                        <span class="data-label">Frequency: </span> {{customer.frequencyName}}
                                    </div>
                                    <div>
                                        <span class="data-label">Location: </span> {{customer.propertyCity}}
                                        {{customer.propertyState}} {{customer.propertyPostalCode}}
                                    </div>
                                    <div>
                                        <span class="data-label">Amount/Visit:</span> {{customer.cost | currency}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </a>

                    <div *ngIf="pendingAddOns && pendingAddOns.length">Add On Requests</div>
                    <a mat-button *ngFor="let addOn of pendingAddOns" routerLink="/pending-add-on/{{addOn.id}}"
                        class="list-button">
                        <div aflex>
                            <div nogrow>
                                <mat-icon>add_task</mat-icon>
                            </div>
                            <div>
                                <h3>{{addOn.serviceName}}</h3>
                                <div>
                                    <span class="data-label">Location: </span> {{addOn.propertyCity}}
                                    {{addOn.propertyState}} {{addOn.propertyPostalCode}}
                                </div>
                            </div>
                        </div>
                    </a>

                    <div topmargin30 class="center" *ngIf="pendingCount === 0">

                        <app-empty-inbox-icon></app-empty-inbox-icon>



                        <h2 class="sub-heading" topmargin30>No Pending Offers</h2>
                    </div>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="First">

            <div class="center" bottommargin30>
                <h1>My Customers</h1>
                <button mat-button (click)="exportCustomers()">
                    <mat-icon>save_alt</mat-icon> Download
                </button>
            </div>

            <div class="overview-boxes">
                <mat-card>


                    <a mat-button
                        routerLink="/maintenance-service-customer-view/{{customer.maintenanceServiceCustomerPropertyId}}"
                        class="list-button" *ngFor="let customer of groupedCustomers">

                        <div aflex>
                            <div nogrow class="center">
                                <mat-icon>account_circle</mat-icon>
                                <div *ngIf="customer.status === 'Suspended'">Suspended</div>
                            </div>
                            <div>
                                <h3>{{customer.name}}</h3>
                                <div *ngIf="customer.email">{{customer.customerEmail}}</div>
                                <div>
                                    {{customer.propertyAddress1}}<br>
                                    <div *ngIf="customer.propertyAddress2">{{customer.propertyAddress2}}</div>
                                    {{customer.propertyCity}} {{customer.propertyState}} {{customer.propertyPostalCode}}
                                </div>
                                <div>
                                    <div *ngFor="let serviceSummary of customer.serviceSummaries"
                                        class="service-summary-img">
                                        <img [src]="getServiceUrl(serviceSummary)">
                                    </div>
                                    {{customer.services}}
                                </div>
                            </div>
                        </div>

                    </a>

                    <div style="display:none" id="reportArea">
                        <table>
                            <tr>
                                <td>Name</td>
                                <td>Email</td>
                                <td>Phone</td>
                                <td>Service</td>
                                <td>Address 1</td>
                                <td>Address 2</td>
                                <td>City</td>
                                <td>State</td>
                                <td>Zip</td>
                                <td>Frequency</td>
                                <td>Amount</td>
                                <td>Status</td>
                            </tr>
                            <tr *ngFor="let customer of customers">
                                <td>{{customer.customerName}}</td>
                                <td>{{customer.customerEmail}}</td>
                                <td>{{customer.customerPhoneNumber}}</td>
                                <td>{{customer.name}}</td>
                                <td>{{customer.propertyAddress1}}</td>
                                <td>{{customer.propertyAddress2}}</td>
                                <td>{{customer.propertyCity}}</td>
                                <td>{{customer.propertyState}}</td>
                                <td>{{customer.propertyPostalCode}}</td>
                                <td>{{customer.frequencyName}}</td>
                                <td>{{customer.cost}}</td>
                                <td>{{customer.status}}</td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
            </div>



        </mat-tab>
        <mat-tab label="Second">
            <div class="center">
                <img style="filter: brightness(0) invert(1);height: 75px" [src]="companyUrl"
                    alt="Elevate Homescriptions" title="Elevate Homescriptions">
            </div>
            <div flex class="overview-boxes">

                <mat-card flex1 class="center">
                    <h2 class="sub-heading">Pending Offers</h2>
                    <h1 class="big-data">
                        <span *ngIf="pending">{{pendingCount}}</span>
                        <span *ngIf="!pending">&nbsp;</span>
                    </h1>

                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <button mat-button (click)="selectedIndex = 0">
                                <div>More <mat-icon>chevron_right</mat-icon></div>
                            </button>
                        </div>
                    </div>
                </mat-card>

                <mat-card flex1 class="center">
                    <h2 class="sub-heading">Total Customers</h2>
                    <h1 class="big-data">
                        <span *ngIf="groupedCustomers">{{groupedCustomers.length}}</span>
                        <span *ngIf="!customers">&nbsp;</span>
                    </h1>

                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <button mat-button (click)="selectedIndex = 1">
                                <div>More
                                    <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                </mat-card>

                <mat-card flex1 class="center">

                    <h2 class="sub-heading" bottommargin30>Next Appointment</h2>
                    <app-empty-inbox-icon height="112px" *ngIf="upcommingJobs && !nextJob"></app-empty-inbox-icon>

                    <button (click)="showCompleteDialog(nextJob)" class="next-job-btn" mat-button *ngIf="nextJob">

                        <div aflex class="upcomming-job">
                            <div nogrow>
                                <h2 style="color: rgba(255, 255, 255, .7)">{{nextJob.scheduledDate | date: 'd'}}</h2>
                                <div>{{nextJob.scheduledDate | date: 'MMM'}}</div>
                            </div>
                            <div>
                                <div>{{nextJob.propertyAddress1}}</div>
                                <div>{{nextJob.maintenanceServiceName}}</div>
                            </div>
                        </div>

                    </button>

                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <button mat-button (click)="selectedIndex = 3">
                                <div>More <mat-icon>chevron_right</mat-icon></div>
                            </button>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div flex class="overview-boxes">
                <mat-card *ngIf="jobsToComplete && jobsToComplete.length" topmargin40>

                    <h2 class="sub-heading center" bottommargin20>Uncompleted Past Appointments</h2>
                    <div class="apt-list-container">
                        <button mat-button *ngFor="let job of jobsToComplete" (click)="showCompleteDialog(job)"
                            class="list-button">

                            <div aflex>
                                <div nogrow>
                                    <div class="thumbnail-container">
                                        <img [src]="getServiceUrl(job)">
                                    </div>
                                </div>
                                <div>
                                    {{job.propertyAddress1}}<br>
                                    {{job.propertyCity}} {{job.propertyState}} {{job.propertyPostalCode}}<br>
                                    {{job.maintenanceServiceName}}<br>
                                    {{job.scheduledDate | date: 'shortDate'}}
                                </div>
                                <div nogrow *ngIf="job.completedDate">

                                    <mat-icon class="complete-check">check</mat-icon>
                                </div>
                            </div>

                        </button>
                    </div>
                </mat-card>
            </div>

            <h2 class="sub-heading" style="color: rgba(255, 255, 255, .7)" topmargin40 bottommargin20>Other Options</h2>
            <div class="other-menu-items-container">

                <a mat-button mat-raised-button routerLink="/maintenance-service-billing">
                    <div>
                        <mat-icon>paid</mat-icon><br> Billing
                    </div>
                </a>
                <a mat-button mat-raised-button routerLink="/maintenance-service-report-dashboard">
                    <div>
                        <mat-icon>find_in_page</mat-icon><br> Reports
                    </div>
                </a>
                <a mat-button mat-raised-button routerLink="/maintenance-service-cancellations">
                    <div>
                        <mat-icon>event_busy</mat-icon><br> Recent Cancellations
                    </div>
                </a>
                <a mat-button mat-raised-button routerLink="/maintenance-service-calendar">
                    <div>
                        <mat-icon>calendar_month</mat-icon><br> Calendar
                    </div>
                </a>
                <a mat-button mat-raised-button routerLink="/settings">
                    <div>
                        <mat-icon>settings</mat-icon><br> Settings
                    </div>
                </a>
            </div>
        </mat-tab>
        <mat-tab label="Third">
            <div class="overview-boxes">
                <mat-card>
                    <h2 *ngIf="upcommingJobs && upcommingJobs.length" class="sub-heading">Upcoming Jobs</h2>
                    <div *ngIf="upcommingJobs && upcommingJobs.length === 0 && selectedIndex === 3" class="center">
                        <app-empty-inbox-icon></app-empty-inbox-icon>
                        <!-- <img style="max-height: 150px;" src="../../../assets/images/empty-inbox.svg"><br> -->
                        <br>
                        <h1>No Upcoming Jobs</h1>
                    </div>
                    <button *ngIf="upcommingJobs && upcommingJobs.length" mat-button (click)="downloadAppointments()">
                        <mat-icon>save_alt</mat-icon> Download
                    </button>

                    <table style="display: none;" id="appointmentsTable">
                        <tr>
                            <td>Customer Name</td>
                            <td>Service</td>
                            <td>Address 1</td>
                            <td>Address 2</td>
                            <td>City</td>
                            <td>Zip</td>
                            <td>Date</td>
                        </tr>
                        <tr *ngFor="let job of upcommingJobs">
                            <td>{{job.customerName}}</td>
                            <td>{{job.maintenanceServiceName}}</td>
                            <td>{{job.propertyAddress1}}</td>
                            <td>{{job.propertyAddress2}}</td>
                            <td>{{job.propertyCity}}</td>
                            <td>{{job.propertyState}}</td>
                            <td>{{job.propertyPostalCode}}</td>
                            <td>{{job.scheduledDate | date: 'shortDate'}}</td>
                        </tr>
                    </table>

                    <button mat-button (click)="showCompleteDialog(job)" *ngFor="let job of upcommingJobs"
                        class="list-button">

                        <div aflex>
                            <div nogrow>
                                <div class="thumbnail-container">
                                    <img [src]="getServiceUrl(job)">
                                </div>
                            </div>
                            <div>
                                {{job.propertyAddress1}}<br>
                                {{job.propertyCity}} {{job.propertyState}} {{job.propertyPostalCode}}<br>
                                {{job.maintenanceServiceName}}<br>
                                {{job.scheduledDate | date: 'shortDate'}}
                            </div>
                            <div nogrow *ngIf="job.completedDate">

                                <mat-icon class="complete-check">check</mat-icon>
                            </div>
                        </div>
                    </button>

                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<app-maint-services-nav-bar [(pending)]="pending" (selectedIndexChange)="selectedIndexChange($event)"
    [(selectedIndex)]="selectedIndex" [pending]="pending" [isHome]="true"></app-maint-services-nav-bar>