import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { EntityApiService } from '@cogent/client/shared/services/api/entity-api.service';
import {  AuthorizationRepairItemSummary, ContractorAuthorizationRepairItem, WorkOrderItem } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-enter-contractor-authorization-repair-items',
    standalone: true,
    imports: [CommonModule, MatInputModule, FormsModule, MatFormFieldModule, MatButtonModule, MatSelectModule, MatProgressSpinnerModule],
    templateUrl: './enter-contractor-authorization-repair-items.component.html',
    styleUrl: './enter-contractor-authorization-repair-items.component.scss'
})
export class EnterContractorAuthorizationRepairItemsComponent implements OnChanges {

    @Input() contractorId: string;
    @Input() internalView: boolean;
    itemId: string;

    authorizationRepairItems: AuthorizationRepairItemSummary[];
    contractorAuthorizationRepairItems: ContractorAuthorizationRepairItem[];
    selectors: ContractorAuthorizationRepairItemSelector[] = [];
    workOrderItems: WorkOrderItem[];
    loading = false;

    constructor(private authoApi: AuthorizationsApiService,
        private entityApi: EntityApiService,
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contractorId?.currentValue) {
            this.getItems();
        }
    }

    async getItems() {
        this.workOrderItems = await this.authoApi.getContractorCoveredItems(this.contractorId);
    }

    async rejectIt(item: ContractorAuthorizationRepairItem) {
        delete item.approvedBy;
        delete item.approvedDate;

        await this.authoApi.updateContractorAuthorizationRepairItem(item);
    }

    async approveIt(item: ContractorAuthorizationRepairItem) {
        item.approvedBy = (await this.entityApi.getLoggedInUser()).id;
        item.approvedDate = new Date();
        await this.authoApi.updateContractorAuthorizationRepairItem(item);
    }

    async load() {
        this.selectors = [];
        if (!this.contractorId) {
            return;
        }
        this.loading = true;

        this.authorizationRepairItems = await this.authoApi.getAuthorizationRepairItems(this.itemId);
        this.contractorAuthorizationRepairItems = await this.authoApi.getContractorAuthorizationRepairItems(this.contractorId, this.itemId);

        for (const repairItem of this.authorizationRepairItems) {
            const selector = new ContractorAuthorizationRepairItemSelector();
            selector.authorizationRepairItem = repairItem;
            selector.contractorAuthorizationRepairItem = this.contractorAuthorizationRepairItems.find(i => i.authorizationRepairItemId === repairItem.id);
            if (!selector.contractorAuthorizationRepairItem) {
                selector.contractorAuthorizationRepairItem = new ContractorAuthorizationRepairItem();
                selector.contractorAuthorizationRepairItem.id = UtilitiesService.newid();
                selector.contractorAuthorizationRepairItem.entityId = this.contractorId;
                selector.contractorAuthorizationRepairItem.authorizationRepairItemId = repairItem.id;
                selector.isNew = true;
            }
            this.selectors.push(selector);
        }

        this.loading = false;

    }

    async save() {
        if (this.selectors) {
            for (const selector of this.selectors.filter(i=>i.contractorAuthorizationRepairItem.cost)) {
                await this.authoApi.saveContractorAuthorizationRepairItem(selector.contractorAuthorizationRepairItem);
            }
            for(const selector of this.selectors.filter(i=>!i.isNew && !i.contractorAuthorizationRepairItem.cost)) {
                await this.authoApi.deleteContractorAuthorizationRepairItem(selector.contractorAuthorizationRepairItem.id);
            }
        }
    }
}

class ContractorAuthorizationRepairItemSelector {
    authorizationRepairItem: AuthorizationRepairItemSummary;
    contractorAuthorizationRepairItem: ContractorAuthorizationRepairItem;
    isNew = false;

}
