import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorInvoiceAccounting {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() number: bigint;
    @column() item: string;
    @column() approvedDate: Date;
    @column() exportDate: Date;
    @column() postDate: Date;
    @column() isService: boolean;
    @column() partsAndLabor: number;
    @column() tax: number;
    @column() oneTimeService: number;
    @column() partsAndLaborAccountId: number;
    @column() taxAccountId: number;
    @column() ontTimeServiceAccountId: number;
    @column() accountsPayableAccountId: number;
    @column() accountingAreaId: string;
    @column() contractorId: string;
    @column() payablesPaymentMethod: string;
    @column() contractorName: string;
    @column() paymentAccountId: number;
    selected: boolean;
    get total(){
        return this.oneTimeService + this.partsAndLabor + this.tax;
    }

}