<div flex>

    <div>

        <div flex>
            <div>
                <button mat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay($event)">
                    <mat-icon>chevron_left</mat-icon>
                    Previous
                </button>
                <button mat-button mwlCalendarToday (viewDateChange)="closeOpenMonthViewDay($event)"
                    [(viewDate)]="viewDate">
                    Today
                </button>
                <button mat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay($event)">
                    Next
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <div id="search-container">
                    <mat-icon>search</mat-icon><input type="text" name="searchField" [formControl]="searchField"
                        id="searchField" placeholder="Filter">
                </div>
                <mat-spinner *ngIf="loadingCalendar" class="thirty" style="margin-left: 20px;position: absolute;margin-top: 5px;"></mat-spinner>
            </div>
            <div nogrow>
                <mat-checkbox [(ngModel)]="showWeekends">Show Weekends</mat-checkbox>
                <button mat-button (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                    Month
                </button>
                <button mat-button (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                    Week
                </button>
                <button mat-button (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                    Day
                </button>
            </div>
        </div> 

        <mat-tab-group [(selectedIndex)]="selectedIndex">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon style="margin-right: 10px;">people_alt</mat-icon> All
                </ng-template>
                <h1 class="month-and-year">{{viewDate | date: 'MMMM yyyy'}}</h1>
                <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                        (eventClicked)="handleEvent('Clicked', $event.event)" [excludeDays]="excludeDays"
                        (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                        [dayStartHour]="startHour" [dayEndHour]="endHour" [excludeDays]="excludeDays"
                        (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                        [dayStartHour]="startHour" [dayEndHour]="endHour"
                        (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-day-view>
                </div>
            </mat-tab>
            <mat-tab *ngFor="let techAndWorkOrders of techsAndWorkOrders">
                <ng-template mat-tab-label>
                    <img [src]="getEntityThumbnailUrl(techAndWorkOrders.technician.id)" class="tab-avatar">
                    {{techAndWorkOrders.technician.name}}
                    <span class="badge"
                        *ngIf="techAndWorkOrders && techAndWorkOrders.events">{{techAndWorkOrders.events.length}}</span>
                </ng-template>
                <div *ngIf="selectedIndex !== 0" [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                        [events]="techAndWorkOrders.events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                        (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)"
                        [excludeDays]="excludeDays" (eventTimesChanged)="eventTimesChanged($event, techAndWorkOrders)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
                        [events]="techAndWorkOrders.events" [refresh]="refresh"
                        (eventClicked)="handleEvent('Clicked', $event.event)" [dayStartHour]="startHour"
                        [dayEndHour]="endHour" [excludeDays]="excludeDays"
                        (eventTimesChanged)="eventTimesChanged($event, techAndWorkOrders)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate"
                        [events]="techAndWorkOrders.events" [refresh]="refresh"
                        (eventClicked)="handleEvent('Clicked', $event.event)" [dayStartHour]="startHour"
                        [dayEndHour]="endHour" (eventTimesChanged)="eventTimesChanged($event, techAndWorkOrders)">
                    </mwl-calendar-day-view>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>


<div id="" *ngIf="selectedItem">


</div>

<div id="detail-screen" *ngIf="selectedItem">

    <div aflex>
        <div nogrow> 
            <button class="close-detail-button" mat-button (click)="selectedItem = null">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div style="overflow-x: hidden;overflow-y: auto;height: calc(100vh - 53px)">
            <app-work-order-detail (workOrderSummaryChange)="detailPageRefreshed($event)" [selectedIndex]="selectedDetailIndex"
            [workOrderId]="selectedItem.id"></app-work-order-detail>
        </div>
    </div>
</div>