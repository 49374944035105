import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorDocument {
    @keyColumn() id: string;
    @column() contractorDocumentTypeId: string;
    @column() entityId: string;
    @column() documentPath: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() expirationDate: Date;

    modified?: boolean;
    file: string;
    hasDocument = false;
    documentTypeName: string;
}