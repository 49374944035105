<mat-card *ngIf="workOrderSummary" [class.no-cards]="noCards">
    <mat-card-content style="overflow-x: hidden;">
        <div aflex>
            <div>
                <div class="data-label">Request #:</div>
                <h1>{{workOrderSummary.number}}
                    <span class="badge">{{workOrderSummary.type}}</span>
                </h1>
            </div>
            <div nogrow>
                <button id="toggle-more-claim-summary-detail" mat-button *ngIf="!noCollapse"
                    (click)="claimSummaryExpanded = !claimSummaryExpanded">
                    <mat-icon class="rotate-icon" [class.rotated]="claimSummaryExpanded">chevron_right</mat-icon>
                </button>
            </div>
        </div>
        <div topmargin20 *ngIf="!hideCustomerName">
            <div class="data-label">Scheduling Contact Information</div>
            <div *ngIf="!hideCustomerName" style="text-overflow:ellipsis;" title="{{workOrderSummary.contactName}}">
                {{workOrderSummary.contactName}}</div>
            <div *ngIf="workOrderSummary.contactEmail && !hideCustomerName">{{workOrderSummary.contactEmail}}</div>
            <div *ngIf="!hideCustomerName">
                <div *ngIf="workOrderSummary.contactPhone">
                    {{workOrderSummary.contactPhone | formatPhoneNumber}}</div>
                <div *ngIf="workOrderSummary.contactMobile">
                    {{workOrderSummary.contactMobile | formatPhoneNumber}}</div>
                <div *ngIf="workOrderSummary.preferredContactMethod">
                    <span class="data-label">
                        Preferred Method:
                    </span>
                    <div class="toggle-container">

                        <span [class.badge]="workOrderSummary.preferredContactMethod === 'Phone'">Phone</span>
                        <span [class.badge]="workOrderSummary.preferredContactMethod === 'SMS'">SMS</span>
                    </div>
                </div>

            </div>
            <div>{{workOrderSummary.additionalNotes}}</div>
        </div>
        <div topmargin20 *ngIf="workOrderSummary.propertyManagerId">
            <div class="data-label">Property Manager Information</div>
            <div *ngIf="propertyManager?.name">{{propertyManager.name}}</div>
            <div *ngIf="propertyManager?.workNumber">{{propertyManager.workNumber |
                formatPhoneNumber}}</div>
            <div *ngIf="propertyManager?.mobileNumber">{{propertyManager.mobileNumber |
                formatPhoneNumber}}</div>
            <div *ngIf="propertyManager?.email">{{propertyManager.email}}</div>
        </div>

        <div topmargin20>
            <div class="data-label">Property Information</div>
            <a id="google-maps-button" mat-button target="_blank" mat-button
                href="https://maps.google.com/?q={{workOrderSummary.propertyAddress}} {{workOrderSummary.propertyCity}} {{workOrderSummary.propertyState}} {{workOrderSummary.propertyPostalCode}}">
                <div aflex>
                    <div>
                        <div>{{workOrderSummary.propertyAddress}}</div>
                        <div *ngIf="workOrderSummary.propertyAddress2">{{workOrderSummary.propertyAddress2}}</div>
                        <div>{{workOrderSummary.propertyCity}}, {{workOrderSummary.propertyState}}
                            {{workOrderSummary.propertyPostalCode}}</div>
                    </div>
                    <div nogrow>
                        <i class="material-icons">navigation</i>
                    </div>
                </div>
            </a>
        </div>

        <div topmargin20 *ngIf="notes">
            <div class="data-label">Notes</div>
            <div *ngFor="let note of notes; let i = index">
                <span *ngIf="i < 1" [innerHtml]="note.noteText"></span>
            </div>
        </div>

        <div topmargin20>
            <div class="data-label">Effective</div>
            {{workOrderSummary.effectiveDate | date: 'shortDate'}} - {{workOrderSummary.expirationDate | date:
            'shortDate'}}

            <div *ngIf="firstEffectiveDate">
                Customer Since {{firstEffectiveDate | date: 'shortDate'}}
            </div>
        </div>

        <div topmargin20>
            <div class="data-label">Service Provider</div>
            <div aflex>
                <div nogrow>

                    <img [src]="getEntityThumbnail(workOrderSummary.contractorId)" style="width: 60px">
                </div>
                <div>
                    {{workOrderSummary.contractorName}}<br> {{workOrderSummary.contractorEmail}}
                    <br> {{workOrderSummary.contractorPhone | formatPhoneNumber}}
                </div>
            </div>
        </div>

        <div topmargin20>
            <div class="data-label">Service Item</div>
            <div aflex>
                <div nogrow>
                    <img [src]="getItemThumbnail(workOrderSummary.itemId)" style="width: 60px">
                </div>
                <div>
                    {{workOrderSummary.itemName}}
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>