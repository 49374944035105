import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent, ImpersonateComponent } from './auth-callback';
import { LogOutComponent } from './log-out';


const routes: Routes = [
    { path: "call-back", component: AuthCallbackComponent },
    { path: 'log-out', component: LogOutComponent },
    { path: 'impersonate', component: ImpersonateComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
