
import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
    standalone: true,
    selector: 'app-empty-inbox-icon',
    templateUrl: 'empty-inbox-icon.component.html',
    styleUrls: ['empty-inbox-icon.component.css']
})
export class EmptyInboxIconComponent {

    @Input() height = '200px';
 

}