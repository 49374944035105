import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewCircleAnimatedComponent } from './review-circle-animated/review-circle-animated.component';



@NgModule({
  declarations: [
    ReviewCircleAnimatedComponent
  ],
  exports: [
    ReviewCircleAnimatedComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ReviewCircleAnimatedModule { }
