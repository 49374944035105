<!-- <button mat-button mat-raised-button class="toggle-button" style="margin-right: 20px;margin-bottom: 20px;" *ngFor="let workOrderItem of workOrderItems" [class.selected]="workOrderItem.id === itemId" (click)="itemId = workOrderItem.id;load()">
    {{workOrderItem.name}}
</button> -->

<mat-form-field appearance="outline" style="max-width: 350px;">
    <mat-label>Job item</mat-label>
    <mat-select [(ngModel)]="itemId" (ngModelChange)="load()">
        <mat-option *ngFor="let item of workOrderItems" [value]="item.id">{{item.name}}</mat-option>
    </mat-select>
    <mat-spinner *ngIf="loading" class="twenty" matSuffix></mat-spinner>
</mat-form-field>

<table class="table">
    <thead>
        <tr>
            <th>Item</th>
            <th>Price</th>
            @if(internalView) {
                <th>Approved</th>
            }
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let selector of selectors">
            <td>
                {{selector.authorizationRepairItem.name}}
            </td>
            <td>
                <mat-form-field appearance="outline" style="max-width: 250px;">
                    <input matInput type="number" style="text-align: right;" [(ngModel)]="selector.contractorAuthorizationRepairItem.cost">
                </mat-form-field>
            </td>
            @if(internalView) {
                <td>
                @if(selector.contractorAuthorizationRepairItem) {
                    @if(selector.contractorAuthorizationRepairItem.approvedDate) {
                        <button mat-button mat-raised-button (click)="rejectIt(selector.contractorAuthorizationRepairItem)">Reject</button>
                    } @else {
                        <button mat-button mat-raised-button (click)="approveIt(selector.contractorAuthorizationRepairItem)">Approve</button>
                    }
                }
            </td>
            }
        </tr>
    </tbody>
</table>
