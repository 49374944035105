import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PremiumAccountBalance {
    @keyColumn() id: string;
    @column() date: Date;
    @column() policyId: string;
    @column() revenue: number;
    @column() deferredRevenue: number;
    @column() accountsReceivable: number;
}