import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorInvoicePayment {
    @keyColumn() id: string;
    @column() contractorId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() quickBooksId: string;
    @column() quickBooksPaymentId: string;
    @column() checkNumber: string;
    @column() type: string;
}