<svg id="calendar" [style.max-height]="maxHeight" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 328 349">
    <defs>
        <style>
            .cls-1-calendar,
            .cls-3-calendar,
            .cls-4-calendar {
                fill: #fff;
            }

            .cls-2-calendar,
            .cls-6-calendar {
                fill: #77cb50;
            }

            .cls-3-calendar {
                stroke: #77cb50;
                stroke-miterlimit: 10;
                stroke-width: 9px;
            }

            .cls-4-calendar {
                font-size: 36px;
                letter-spacing: -0.01em;
            }

            .cls-4-calendar,
            .cls-6-calendar,
            .cls-7-calendar {
                font-family: "Open Sans";
            }

            .cls-5-calendar {
                letter-spacing: 0em;
            }

            .cls-6-calendar {
                font-size: 165px;
            }

            .cls-7-calendar {
                font-size: 50px;
                fill: #4d4d4d;
            }

            .cls-8-calendar {
                letter-spacing: -0.03em;
            }

            .cls-9-calendar {
                letter-spacing: 0em;
            }

            .cls-10-calendar {
                letter-spacing: 0em;
            }

            .cls-11-calendar {
                letter-spacing: -0.01em;
            }
        </style>
    </defs>
    <title>{{date | date: 'shortDate'}}</title>
    <g>
        <rect class="cls-1-calendar" x="5" y="26" width="318" height="318" rx="18.5" />
        <path class="cls-2-calendar"
            d="M789.34,238a13.52,13.52,0,0,1,13.5,13.5v281a13.52,13.52,0,0,1-13.5,13.5h-281a13.51,13.51,0,0,1-13.5-13.5v-281a13.51,13.51,0,0,1,13.5-13.5h281m0-10h-281a23.5,23.5,0,0,0-23.5,23.5v281a23.5,23.5,0,0,0,23.5,23.5h281a23.5,23.5,0,0,0,23.5-23.5v-281a23.5,23.5,0,0,0-23.5-23.5Z"
            transform="translate(-484.84 -207)" />
    </g>
    <rect class="cls-2-calendar" y="21" width="328" height="86" rx="20.52" />
    <rect class="cls-3-calendar" x="29.66" y="4.5" width="23" height="34" rx="10.7" />
    <text class="cls-4-calendar" x="159" y="85" text-anchor="middle">{{date | date: 'MMMM'}}
        {{date | date: 'yyyy'}}</text>
    <text class="cls-6-calendar" text-anchor="middle" x="159" y="253">{{date | date:
        'd'}}</text>
    <text class="cls-7-calendar" text-anchor="middle" x="159" y="320">{{date | date:
        'EEEE'}}</text>
    <rect class="cls-3-calendar" x="277.66" y="4.5" width="23" height="34" rx="10.7" />
    <rect class="cls-3-calendar" x="153.66" y="4.5" width="23" height="34" rx="10.7" />
</svg>