import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource() export class Chargeback {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() amount: number;
    @column() quickBooksJournalId: string;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() memo: string;
    @column() chargebackNumber: bigint;
    @column() voidDate: Date;
    @column() reistatementDate: Date;
    @column() exportDate: Date;
    @column() voidExportDate: Date;
    @column() quickBooksVoidedJournalId: string;
    @column() reinstatedAmount: number;
    @column() postDate: Date;
    @column() voidPostDate: Date;
}