import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';

export class ContractorSelection {
    id: string;
    name: string;
    phoneNumber: string;
    mobileNumber: string;
    callsToday: number;
    email: string;
    rating?: number;
    rank: number;
    contactViaEmail: boolean;
    contactViaSMSText: boolean;
    onHoldSort: number;
    tierName: string;

    get logoUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.id}`;
    }
}
