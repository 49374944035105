import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class JsonObject {
    @keyColumn() id: string;
    @column() type: string;
    @column() parentId: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;

    @column() json: string;
    private _instance: any;

    get instance() {
        if (!this._instance && this.json) {
            try {
                this._instance = JSON.parse(this.json);
            } catch {

            }
        }

        return this._instance;
    }
    set instance(value: any) {
        if (value) {
            this.json = JSON.stringify(value);
        } else {
            delete this.json;
        }
    }
}