export class AfterCallSurveyResult {
    agentUserName: string;
    surveyPotential: number;
    surveyScore: number;

    get totalSurveys() {
        return this.surveyPotential / 5;
    }

    get average() {
        if(this.surveyPotential === 0) {
            return 0;
        }

        return this.surveyScore / this.surveyPotential;
    }

    get averageStars() {
        return this.average * 5;
    }
}