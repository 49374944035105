import { PlanItemSummary } from "@upkeeplabs/models/cogent";
import { SalesItemCoverageClient } from "./sales-item-coverage-client.model";

export class PlanItemSummaryClient extends PlanItemSummary {
    coverages: SalesItemCoverageClient[];
    constructor(id: string = null, optional: boolean = null, name: string = null, allowMultiples: boolean = null, salesItemId: string = null, planId: string = null) {
        super();
        this.id = id;
        this.optional = optional;
        this.name = name;
        this.allowMultiples = allowMultiples;
        this.salesItemId = salesItemId;
        this.planId = planId;
    }
    

}
