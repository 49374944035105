import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceServiceAddOnRequestSummary } from '@upkeeplabs/models/cogent';


@Component({
    selector: 'app-add-on-request-args-viewer',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './add-on-request-args-viewer.component.html',
    styleUrls: ['./add-on-request-args-viewer.component.css']
})
export class AddOnRequestArgsViewerComponent implements OnInit {

    @Input() addOn: MaintenanceServiceAddOnRequestSummary;
    constructor() { }

    ngOnInit(): void {
    }

}
