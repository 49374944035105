import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';



@NgModule({
  declarations: [TimePickerComponent],
  imports: [
    CommonModule,
    MaterialSharedModule
  ],
  exports: [
      TimePickerComponent
  ]
})
export class TimePickerModule { }
