<div *ngIf="workOrderSummary" myMinAutoHeight padding (swipeleft)="goForward()" (swiperight)="goBack()">
    <div class="container">
        <div aflex *ngIf="selectedIndex < 4">
            <div>
                <h1 class="page-title">
                    <span class="big">Authorize</span><br>
                    <span class="little">Service Request</span>

                </h1>
            </div>
            <div nogrow>
                <button mat-button mat-raised-button class="rubberBand" *ngIf="questionsComplete && selectedIndex === 0"
                    color="primary" (click)="selectedIndex = 1">Next

                    <mat-icon>chevron_right</mat-icon>
                </button>
                <button mat-button mat-raised-button class="rubberBand"
                    *ngIf="lines && lines.length > 0 && selectedIndex === 1" color="primary"
                    (click)="selectedIndex = 2">Next

                    <mat-icon>chevron_right</mat-icon>
                </button>
                <button mat-button mat-raised-button class="rubberBand" *ngIf="selectedIndex === 2" color="primary"
                    (click)="selectedIndex = 3">Next

                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div id="auth-progress-indicator" *ngIf="selectedIndex < 4">

        <div aflex>
            <div class="autho-step" [class.current]="selectedIndex === 0" [class.completed]="selectedIndex > 0">
                <span class="autho-step-description">Questionnaire</span>
                <mat-icon>check</mat-icon>
            </div>
            <div class="autho-step-link" [class.completed]="selectedIndex > 0"></div>

            <div class="autho-step" [class.current]="selectedIndex === 1" [class.completed]="selectedIndex > 1">
                <span style="margin-left: -2px" class="autho-step-description">Items</span>
                <mat-icon>check</mat-icon>
            </div>
            <div class="autho-step-link" [class.completed]="selectedIndex > 1"></div>

            <div class="autho-step" [class.current]="selectedIndex === 2" [class.completed]="selectedIndex > 2">
                <span class="autho-step-description" style="margin-left: -6px">Photos</span>
                <mat-icon>check</mat-icon>
            </div>
            <div class="autho-step-link" [class.completed]="selectedIndex > 2"></div>



            <div class="autho-step" [class.current]="selectedIndex === 3" [class.completed]="selectedIndex > 3">
                <span class="autho-step-description">Confirmation</span>
                <mat-icon>check</mat-icon>
            </div>

        </div>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onStepChange()" class="hide-tabs">
        <mat-tab label="Questionnaire">
            <div class="container">
                <p class="info">Please fill out this brief questionnaire to give us a little more detail about the job
                </p>
                <app-question-wizard-v2 #questionWizard isAuthoProcess="true" [(isComplete)]="questionsComplete"
                    [objectInScope]="workOrderSummary" [objectTags]="tags" userType="Contractor"
                    (isCompleteChange)="onQuestionnaireComplete($event)" [workOrderItemId]="workOrderSummary.itemId"
                    (questionAnswered)="setQuestionChanged($event)">
                </app-question-wizard-v2>
            </div>
        </mat-tab>
        <mat-tab label="Line Items">
            <div class="container">
                <p class="info">Enter the line items to give an estimated cost.</p>
                <div topmargin20 *ngIf="!lines">
                    <mat-spinner class="thirty"></mat-spinner> Loading Authrorization Lines
                </div>
                <ng-container *ngIf="lines">

                    <div *ngIf="newLine">
                        <div topmargin20>
                            <div class="data-label">Item</div>

                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="newLine.repairItemId"
                                    (selectionChange)="itemChanged(newLine); newLine.isChanged = true">
                                    <mat-option>
                                        <ngx-mat-select-search ngModel (ngModelChange)="filterItems($event)"
                                            placeholderLabel="Find Item ..." noEntriesFoundLabel="no matches found.">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let item of filteredItems ? filteredItems : availableItems"
                                        [value]="item.id">
                                        {{item.name}}</mat-option>
                                    <mat-option [value]="temporaryRepairItemId">Other</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div>
                            <div class="data-label"
                                *ngIf="newLine.repairItemId === temporaryRepairItemId || (newLine.attributes && newLine.attributes.length > 0)">
                                Attributes</div>
                            <div *ngIf="newLine.repairItemId === temporaryRepairItemId">
                                <mat-form-field appearance="outline">
                                    <input type="text" matInput [(ngModel)]="newLine.temporaryItemName" name="name"
                                        placeholder="other item name" (change)="lineChanged(newLine)">
                                </mat-form-field>
                            </div>
                            <div *ngFor="let attribute of newLine.attributes" class="attribute-value">
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="attribute.valueId"
                                        (selectionChange)="lineChanged(newLine, attribute)"
                                        [placeholder]="attributeNames[attribute.attributeId]">
                                        <ng-container *ngFor="let value of attribute.availableValues">
                                            <mat-option *ngIf="!value.hidden" [value]="value.id">
                                                {{value.value}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div>
                            <div class="data-label">Quantity</div>
                            <div aflex style="    border: 1px solid rgb(138,139,141);
                                  background-color: rgba(0, 0, 255, .03); max-width: 187px">

                                <div nogrow style="margin: 0">
                                    <button mat-button small (click)="newLine.quantity = newLine.quantity - 1">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div style="margin: 0">
                                    <input
                                        style="border: none; width: 100%;outline: none;                                      margin-top: 5px;                                      background-color: transparent;"
                                        type="number" [(ngModel)]="newLine.quantity">
                                </div>
                                <div nogrow style="margin: 0">
                                    <button mat-button small (click)="newLine.quantity = newLine.quantity + 1">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div>

                                <div class="data-label" topmargin20>Amount</div>
                                <input style="border: 1px solid rgb(138,139,141);
            background-color: rgba(0, 0, 255, .03);outline: none;    padding: 8px 10px;" type="number"
                                    [(ngModel)]="newLine.unitPrice">



                            </div>

                            <mat-checkbox [(ngModel)]="newLine.isPurchaseOrderNeeded" (change)="lineChanged(newLine)">
                                Purchase
                                For Us
                            </mat-checkbox>

                            <button mat-button mat-raised-button color="primary" topmargin20 [disabled]="!canAddItem"
                                (click)="addItem()" block>Add
                                Item</button>
                        </div>
                    </div>

                    <div class="sub-heading" topmargin40 *ngIf="lines && lines.length > 0">Current
                        Line
                        Items ({{authorizationTotal | currency}})
                    </div>
                    <ul topmargin10 style="list-style: none; counter-reset: stepper;padding: 0">
                        <li *ngFor="let line of lines" style="text-align: left;" [class.fadeOutLeft]="line.deleting"
                            class="authorization-item slide-in-left">
                            <div aflex>
                                <div style="padding-top: 10px;"><span
                                        style="font-weight: bold">{{line.description}}</span> -

                                    <div aflex>
                                        <div>Qty: {{line.quantity}} &#64;{{line.unitPrice | currency}}</div>
                                        <div nogrow>{{line.extendedPrice | currency}}</div>
                                    </div>

                                </div>
                                <div nogrow>

                                    <button mat-button (click)="removeAuthoLine(line)">
                                        <i class="material-icons">
                                            close
                                        </i>
                                    </button>
                                </div>
                            </div>

                        </li>
                    </ul>

                    <!-- <div flex topmargin40>

                        <div>
                        </div>
                        <div nogrow>
                            <div id="purchase-order-total" [class.over-acc]="amountOverAcc > 0"
                                [class.under-acc]="amountOverAcc< 0">
                                <div class="data-label">Total</div>
                                <h1 class="big-data">{{total | currency:'USD':'symbol':'1.2-2'}}</h1>
                            </div>

                            <div class="small" *ngIf="amountOverAcc > 0" style="font-style: italic" topmargin5>

                                {{amountOverAcc | currency}} over the average claim cost
                            </div>

                            <div class="small" *ngIf="amountOverAcc < 0" style="font-style: italic" topmargin5>

                                {{-amountOverAcc | currency}} below the average claim cost
                            </div>
                            <div class="small" *ngIf="totalOutOfPocket > 0" style="font-style: italic" topmargin5>

                                Out of Pocket: {{totalOutOfPocket | currency:'USD':'symbol':'1.2-2'}}
                            </div>
                        </div>
                    </div> -->

                </ng-container>

                <!-- <div id="new-line-item-container">

                    <div topmargin20>
                        <div class="data-label">Item</div>
                        <mat-form-field class="custom-style">
                            <mat-select [(ngModel)]="selectedItem" (ngModelChange)="setItem()">
                                <mat-option class="auto" *ngFor="let item of items" [value]="item">
                                    <h3>{{item.number}}</h3>
                                    <p>{{item.description}}</p>
                                </mat-option>
                                <mat-option [value]="userDefinedItem">
                                    <h3>User Defined</h3>
                                    <p>Enter an item not on the list</p>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="selectedItem && !selectedItem.id">
                        <mat-form-field>
                            <mat-label>Item Number</mat-label>
                            <input matInput [(ngModel)]="newItem.number">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Description</mat-label>
                            <input matInput [(ngModel)]="newItem.description">
                        </mat-form-field>

                    </ng-container>

                    <div class="data-label">Quantity</div>
                    <div aflex style="    border: 1px solid rgb(138,139,141);
      background-color: rgba(0, 0, 255, .03); max-width: 187px">

                        <div nogrow style="margin: 0">
                            <button mat-button small (click)="quantityDown()">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>
                        <div style="margin: 0">
                            <input style=" 
          border: none;
          width: 100%;
          outline: none;
          margin-top: 5px;
          background-color: transparent;" type="number" [(ngModel)]="newItem.quantity">
                        </div>
                        <div nogrow style="margin: 0">
                            <button mat-button small (click)="newItem.quantity = newItem.quantity + 1">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div>

                        <div class="data-label" topmargin20>Amount</div>
                        <input style="border: 1px solid rgb(138,139,141);
            background-color: rgba(0, 0, 255, .03);outline: none;    padding: 8px 10px;" type="number"
                            [(ngModel)]="newItem.unitPrice">

                    </div>

                    <button mat-button mat-raised-button color="primary" topmargin20 [disabled]="!canAddItem"
                        (click)="addItem()" block>Add
                        Item</button>
                </div>


                <div class="sub-heading" topmargin40 *ngIf="authorizationItems && authorizationItems.length > 0">Current
                    Line
                    Items ({{authorizationTotal | currency}})
                </div>
                <ul topmargin10 style="list-style: none; counter-reset: stepper;padding: 0">
                    <li *ngFor="let item of authorizationItems" style="text-align: left;"
                        [class.fadeOutLeft]="item.deleting" class="authorization-item slide-in-left">
                        <div aflex>
                            <div style="padding-top: 10px;"><span style="font-weight: bold">{{item.number}}</span> -
                                <span style="font-style: italic">{{item.description}}</span>
                                <div aflex>
                                    <div>Qty: {{item.quantity}} @{{item.unitPrice | currency}}</div>
                                    <div nogrow>{{item.extendedPrice | currency}}</div>
                                </div>

                            </div>
                            <div nogrow>

                                <button mat-button (click)="removeAuthoItem(item)">
                                    <i class="material-icons">
                                        close
                                    </i>
                                </button>
                            </div>
                        </div>

                    </li>
                </ul> -->
            </div>
        </mat-tab>
        <mat-tab label="Photos">

            <div class="container">

                <div class="info">
                    Upload any photos that may be useful in the diagnosis.
                </div>

                <div *ngIf="!attachments || !attachments.length" style="text-align: center" topmargin30>
                    <img src="./assets/images/circle-icons-camera.png" style="max-width: 150px">

                </div>
                <div *ngIf="attachments && attachments.length" topmargin30>
                    <div class="data-label">My Attachments</div>
                    <div *ngFor="let attachment of attachments" class="attachment-item" aflex>
                        <div nogrow>
                            <img [src]="attachment.base64">
                        </div>
                        <div style="overflow-x: hidden">
                            <div class="data-label"
                                style="max-width: 100%; overflow-x: hidden; text-overflow: ellipsis">{{attachment.name}}
                            </div>
                        </div>
                        <div nogrow style="margin-left: 0">
                            <button mat-button class="remove-button" (click)="removeAttachment(attachment)">
                                <i class="material-icons">close</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="top-margin-30">
                    <button topmargin20 style="color: rgba(0,0,0,.6) !important" (click)="showFileUpload()"
                        mat-raised-button mat-button block large>
                        <i class="material-icons" style="color: rgba(0,0,0,.6) !important">add</i> Add File</button>
                </div>
                <div style="text-align: center" topmargin20>-OR-</div>

                <div topmargin20>
                    <button class="top-margin-20" (click)="tabIndex=3" mat-button block>
                        I Don't Have Any</button>
                </div>

                <input type="file" style="display: none" name="fileToUpload1" (change)="handleFileUpload($event)"
                    accept="image/*" id="fileToUpload1" />

            </div>
        </mat-tab>
        <mat-tab label="Confirmation">

            <div class="container">
                <div style="text-align: left">
                    <div class="data-label">Total Authorization</div>
                    <h1 class="big-data">{{authorizationTotal | currency}}</h1>
                </div>

                <div class="data-label" topmargin30>Your Contact Information</div>

                <mat-form-field topmargin20>
                    <input matInput placeholder="Name" [(ngModel)]="technicianContact.name">
                </mat-form-field>

                <div aflex topmargin20>
                    <div nogrow class="checkbox-container">

                        <mat-checkbox [(ngModel)]="technicianContact.contactViaSMS">SMS Text</mat-checkbox>
                    </div>
                    <div>
                        <mat-form-field style="margin-top: -20px">
                            <input matInput [disabled]="!technicianContact.contactViaSMS" mask="(000) 000-0000"
                                placeholder="SMS Number" type="tel" [(ngModel)]="technicianContact.smsNumber">
                        </mat-form-field>
                    </div>
                </div>

                <div aflex topmargin20>
                    <div nogrow class="checkbox-container">

                        <mat-checkbox [(ngModel)]="technicianContact.contactViaEmail">Email</mat-checkbox>
                    </div>
                    <div>

                        <mat-form-field style="margin-top: -20px">
                            <input matInput placeholder="Email" [disabled]="!technicianContact.contactViaEmail"
                                type="email" [(ngModel)]="technicianContact.email">
                        </mat-form-field>
                    </div>
                </div>

                <div aflex topmargin20>
                    <div nogrow class="checkbox-container">

                        <mat-checkbox [(ngModel)]="technicianContact.contactViaPhone">Phone</mat-checkbox>
                    </div>
                    <div>
                        <mat-form-field>
                            <input matInput style="margin-top: -20px" placeholder="Telephone Number"
                                mask="(000) 000-0000" [disabled]="!technicianContact.contactViaPhone" type="tel"
                                [(ngModel)]="technicianContact.phoneNumber">
                        </mat-form-field>
                    </div>
                </div>

                <button mat-button mat-raised-button color="primary" [disabled]="authorizing || !canAuthorize" large
                    block (click)="saveAuthorization()">
                    <mat-spinner thirty *ngIf="authorizing"></mat-spinner>
                    Request Authorization
                </button>
            </div>
        </mat-tab>

        <mat-tab>
            <div class="container">

                <div *ngIf="wasAutoAuthorized">
                    <div style="display: table; margin: 0 auto; text-align: center">
                        <img src="assets/images/thumbs-up.png" style="height: 150px">
                        <br>

                        <h1>Great News!</h1>
                        <h3>Your service request has been automatically authorized</h3>
                    </div>

                    <div topmargin30>
                        <div id="next-steps">
                            Next Steps
                        </div>
                        <ul class="enumerator">
                            <li>Proceed with the repairs.</li>
                            <li>You will be able to bill out up to the amount of the authorization:
                                {{authorizationTotal | currency}}.
                            </li>
                        </ul>
                    </div>
                    <div topmargin30 aflex>
                        <div></div>
                        <div nogrow>
                            <a mat-button mat-raised-button color="primary"
                                routerLink="/work-order-detail/{{workOrderId}}">Back To
                                Request
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>

                <div *ngIf="!wasAutoAuthorized">
                    <div style="display: table; margin: 0 auto; text-align: center">
                        <img src="assets/images/review.png" style="height: 150px">
                        <br>

                        <h1>Under Review</h1>
                    </div>

                    <div topmargin30>
                        <div id="next-steps">
                            Next Steps
                        </div>
                        <ul class="enumerator">
                            <li>The claim will be reviewed by the authorizations department.</li>
                            <li>An authorization decision will be made based on the information provided.</li>
                            <li>Someone from the authorizations department will contact you regarding the decision and
                                any further
                                steps
                            </li>
                        </ul>
                    </div>
                    <div topmargin30 aflex>
                        <div></div>
                        <div nogrow>
                            <a *ngIf="!isEmbedded" mat-button mat-raised-button color="primary"
                                routerLink="/work-order-detail/{{workOrderId}}">Back To
                                Request
                                <mat-icon>chevron_right</mat-icon>
                            </a>

                            <button (click)="closeAuthorizationEmbedded()" *ngIf="isEmbedded" mat-button
                                mat-raised-button color="primary">Close Authorization</button>
                        </div>
                    </div>
                </div>

            </div>
        </mat-tab>
    </mat-tab-group>
</div>
