import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource() export class WorkOrderSummary {
    @keyColumn() id: string;
    @column() number: number;
    @column() qandA: string;
    @column() itemName: string;
    @column() linesJson: string;
    @column() propertyAddress: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() homeownerName: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactEmail: string;
    @column() contactMobile: string;
    @column() contractorEmail: string;
    @column() additionalNotes: string;
    @column() contractorName: string;
    @column() contractorPhone: string;
    @column() policyId: string;
    @column() contractorId: string;
    @column() dateClosed: Date;
    @column() status: string;
    @column() createdDate?: Date;
    @column() authorizationLimit: number;
    @column() itemId: string;
    @column() type: string;
    @column() scheduledDate: Date;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;
    @column() whiteGlove: boolean;
    @column() dateCompleted: Date;
    @column() dispatchedDate: Date;
    @column() sentDate: Date;
    @column() propertyId: string;
    @column() holderId: string;
    @column() planItemId: string;
    @column() cancelledDate: Date;
    @column() tradeId: string;
    @column() claimId: string;
    @column() sendEmail: string;
    @column() tradeName: string;
    @column() createdById?: string;
    @column() createdByName: string;
    @column() policyAddressId: string;
    @column() policyNumber: number;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() workPerformed: string;
    @column() regionId: string;
    @column() stateId: string;
    @column() statusDate: Date;
    @column() workOrderStatusId: string;
    @column() homeownerEmail: string;
    @column() createdByEmail: string;
    @column() contractorStatus: string;
    @column() customerStatus: string;
    @column() marketAreaName: string;
    @column() marketStateName: string;
    @column() contractorPreferredContactMethod: string;
    @column() itemPriority: number;
    @column() sLABasedFromTime: Date;
    @column() sLAStatusRedHours: number;
    @column() userDefined: string;
    @column() sLAStatusYellowHours: number;
    @column() sLAHoursType: string;
    @column() timeZoneId: string;
    @column() loginId: string;
    @column() technicianId: string;
    @column() onMyWayNotificationSentDate: Date;
    @column() resolution: string;
    @column() areaId: string;
    @column() isBilledClaim: number;
    @column() isClaim: number;
    @column() jobCount: number;
    @column() reportDate: Date;
    @column() surveyPossible: number;
    @column() surveyScore: number;
    @column() updateCount: number;
    @column() propertyManagerId: string;
    @column() assignedToUserId: string;
    @column() assignedToName: string;
    @column() canRecall: boolean;
    @column() redDisplayColor: string;
    @column() internalNote: string;
    @column() statusSort: bigint;
    @column() effectiveDate: Date;
    @column() expirationDate: Date;
    @column() propertyAddress2: string;
    @column() homeownerMobileNumber: string;
    @column() homeownerHomeNumber: string;
    @column() surveyComments: string;
    @column() agentId: string;
    @column() sellerAgentId: string;
    @column() agentEmailSent?: Date;
    @column() applicationSource: string;
    @column() calculatedType: string;
    @column() latitude: number;
    @column() longitude: number;
    @column() coverageType: string;
    @column() planName: string;
    @column() planVersionId: string;
    @column() planId: string;
    @column() firstItemId: string;
    @column() alternateTimeSlots: string;
    @column() cashOutOfferAmount: number;
    @column() sLARedTime: Date;
    @column() sLAYellowTime: Date;
    @column() sLANoneTime: Date;
    @column() sLAStatus: string;
    @column() minutesToNone: number;
    @column() minutesToYellow: number;
    @column() minutesToRed: number;
    @column() contactCustomerViaText: boolean;
    @column() isSubjectToPreExistingConditions: boolean;
    @column() preferredContactMethod: string;
    @column() limitPerOccurance: number;
    @column() limitPerPlanPeriod: number;
    @column() useAuthorizationV4: boolean;

}