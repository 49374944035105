import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AppointmentTimeSlot, GroupedAppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-appointment-selection',
    templateUrl: './appointment-selection.component.html',
    styleUrls: ['./appointment-selection.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule]
})
export class AppointmentSelectionComponent implements OnInit, OnChanges {

    @Input() timeSlots: GroupedAppointmentTimeSlot[];
    @Input() selectedTimeSlots: AppointmentTimeSlot[];
    @Output() selectedTimeSlotsChange: EventEmitter<AppointmentTimeSlot[]> = new EventEmitter();
    @Input() selectedItemId: string;
    @Input() timeSlotDayBuffer: number;
    @Input() loadDefaults: boolean;
    @Input() hideThird: boolean;
    @Input() timeZoneId: string;

    selectedTimeSlot1: GroupedAppointmentTimeSlot;
    selectedTimeSlot2: GroupedAppointmentTimeSlot;
    selectedTimeSlot3: GroupedAppointmentTimeSlot;
    hasScrollers = false;
    groupSelector1Id = UtilitiesService.newid();
    groupSelector2Id = UtilitiesService.newid();
    groupSelector3Id = UtilitiesService.newid();

    constructor(private serviceApi: ServiceApiService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedItemId && changes.selectedItemId.currentValue) {
            this.serviceApi.getAppointmentTimeSlots(this.selectedItemId).then(timeSlots => {
                const groupedTimeSlots = GroupedAppointmentTimeSlot.fromAppointmentTimeSlots(timeSlots as any);
                this.timeSlots = groupedTimeSlots;

                setTimeout(() => this.setScrollers());
            });
        }
        if (changes.timeSlots && changes.timeSlots.currentValue) {
            setTimeout(() => this.setScrollers());

        }
    }

    getOrdinalSuffix(number: number) {
        var j = number % 10,
            k = number % 100;
        if (j == 1 && k != 11) {
            return "st";
        }
        if (j == 2 && k != 12) {
            return "nd";
        }
        if (j == 3 && k != 13) {
            return "rd";
        }
        return "th";
    }

    getDateSuffix(date: Date) {
        if(!date) {
            return null;
        }
        return this.getOrdinalSuffix(date.getDate());
    }

    isTimeSlotSelected(slotNumber: number, slotType: string) {


        // const other = slotNumber === 0 ? this.selectedTimeSlot2 : this.selectedTimeSlot1;
        const others = slotNumber === 0 ? [this.selectedTimeSlot2, this.selectedTimeSlot3] : slotNumber === 1 ? [this.selectedTimeSlot1, this.selectedTimeSlot3] : [this.selectedTimeSlot1, this.selectedTimeSlot2];
        if (!others[0] && !others[1]) {
            return false;
        }

        const thisSlot = slotNumber === 0 ? this.selectedTimeSlot1 : slotNumber === 1 ? this.selectedTimeSlot2 : this.selectedTimeSlot3;

        if (!thisSlot) {
            return false;
        }

        const otherSelectedSlots = this.selectedTimeSlots.filter(i => this.selectedTimeSlots.indexOf(i) !== slotNumber);
        // const otherSelectedSlots = slotNumber === 0 ? [this.selectedTimeSlots[1], this.selectedTimeSlots[2]] : slotNumber === 1 ? [] : [this.selectedTimeSlots[0]];

        // if (!otherSelectedSlots) {
        //     return false;
        // }
        const found = otherSelectedSlots.find(i => i && i.dayString == (thisSlot ? thisSlot.dayString : ''));
        if (found) {
            return true;
        }

        return false;
        // return other.dayString === (thisSlot ? thisSlot.dayString : '');

    }

    selectTimeSlot1(timeSlot: GroupedAppointmentTimeSlot) {

        this.selectedTimeSlotsChange.emit(this.selectedTimeSlots);
        if (!this.selectedTimeSlots) {
            this.selectedTimeSlots = [];
        }
        this.selectedTimeSlot1 = timeSlot;
        this.selectedTimeSlots[0] = null;
    }

    selectTimeSlot2(timeSlot: GroupedAppointmentTimeSlot) {
        this.selectedTimeSlotsChange.emit(this.selectedTimeSlots);
        if (!this.selectedTimeSlots) {
            this.selectedTimeSlots = [];
        }
        this.selectedTimeSlot2 = timeSlot;
        this.selectedTimeSlots[1] = null;
    }

    selectTimeSlot3(timeSlot: GroupedAppointmentTimeSlot) {
        this.selectedTimeSlotsChange.emit(this.selectedTimeSlots);
        if (!this.selectedTimeSlots) {
            this.selectedTimeSlots = [];
        }
        this.selectedTimeSlot3 = timeSlot;
        this.selectedTimeSlots[2] = null;
    }

    isSelected(item: AppointmentTimeSlot) {
        if (!this.selectedTimeSlots) {
            return false;
        }

        return this.selectedTimeSlots.indexOf(item) > -1;
    }

    setTimeSlotTime1(slot: string) {
        this.setTimeSlot(slot, 0);

    }

    setTimeSlotTime2(slot: string) {
        this.setTimeSlot(slot, 1);
    }

    setTimeSlotTime3(slot: string) {
        this.setTimeSlot(slot, 2);
    }

    @HostListener('window:resize') onWindowResize() {
        this.setScrollers();
    }

    setScrollers() {
        const container = document.getElementById(this.groupSelector1Id);
        if (!container) {
            setTimeout(() => this.setScrollers(), 1000);
            return;
        }

        this.hasScrollers = container.clientWidth < container.scrollWidth;
    }

    scrollLeft(containerId: string) {
        const el = document.getElementById(containerId);
        el.scrollTo({ left: el.scrollLeft - 70, top: 0, behavior: 'smooth' });

    }

    scrollRight(containerId: string) {
        const el = document.getElementById(containerId);
        el.scrollTo({ left: el.scrollLeft + 70, top: 0, behavior: 'smooth' });
    }

    private setTimeSlot(slot: string, position: number) {
        const timeSlot = new AppointmentTimeSlot();
        if (position === 0) {
            timeSlot.date = this.selectedTimeSlot1.date;
        } else if (position === 1) {
            timeSlot.date = this.selectedTimeSlot2.date;
        } else if (position === 2) {
            timeSlot.date = this.selectedTimeSlot3.date;
        }
        if (slot === 'morning') {
            timeSlot.startTime = 480;
            timeSlot.endTime = 720;
        } else if (slot === 'afternoon') {
            timeSlot.startTime = 720;
            timeSlot.endTime = 1020;
        } else if (slot === 'all-day') {
            timeSlot.startTime = 480;
            timeSlot.endTime = 1020;
        } else if (slot === 'mid-day') {
            timeSlot.startTime = 600;
            timeSlot.endTime = 840;
        } else {
            timeSlot.startTime = 0;
            timeSlot.endTime = 0;
        }

        if (!this.selectedTimeSlots) {
            this.selectedTimeSlots = [];
        }
        this.selectedTimeSlots[position] = timeSlot;
        this.selectedTimeSlotsChange.emit(this.selectedTimeSlots);
    }

    slotCount(timeSlot: GroupedAppointmentTimeSlot) {
        if (!this.selectedTimeSlots) {
            return 0;
        }

        return timeSlot.appointmentTimeSlots.filter(i => this.selectedTimeSlots.indexOf(i) > -1).length;
    }

    isActiveSlot(slotNumber, slot) {
        if (!this.selectedTimeSlots || !this.selectedTimeSlots[slotNumber]) {
            return false;
        }

        const ts = this.selectedTimeSlots[slotNumber];
        if (ts.startTime === 480 && ts.endTime === 1020 && slot === 'all-day') {
            return true;
        }
        if (ts.startTime === 480 && ts.endTime === 720 && slot === 'morning') {
            return true;
        }
        if (ts.startTime === 720 && ts.endTime === 1020 && slot === 'afternoon') {
            return true;
        }
        if (ts.startTime === 600 && ts.endTime === 840 && slot === 'mid-day') {
            return true;
        }

        return false;
    }

    addTimeSlot(item: AppointmentTimeSlot) {
        if (!this.selectedTimeSlots) {
            this.selectedTimeSlots = [];
        }

        const index = this.selectedTimeSlots.indexOf(item);
        if (index === -1) {

            this.selectedTimeSlots.unshift(item);
            while (this.selectedTimeSlots.length > 2) {
                this.selectedTimeSlots.pop();
            }
        } else {
            this.selectedTimeSlots.splice(index, 1);
        }



        this.selectedTimeSlotsChange.emit(this.selectedTimeSlots);

    }
}
