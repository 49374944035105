import { Component, OnInit } from "@angular/core";
import { AuthService } from '@cogent/client/auth';
import { Router } from '@angular/router';

@Component({
    template: ''
})
export class LogOutComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router) { }
    ngOnInit() {
        if (this.authService.isLoggedIn) {

        }
        this.authService.logOut();
        this.router.navigateByUrl("/");
    }
}