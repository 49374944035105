import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class UserNotification {
    @keyColumn() id: string;
    @column() userId: string;
    @column() notificationReceivedDate: Date;
    @column() description: string;
    @column() url: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() wasRead: boolean;



    // Client Side Only
    handled = false;
    deleting = false;
    expanded = false;
    removing = false;
    selected = false;
    number: number;

    constructor(id: string = null, userId: string = null, notificationReceivedDate: Date = null, description: string = null, url: string = null) {
        this.id = id;
        this.userId = userId;
        this.notificationReceivedDate = notificationReceivedDate;
        this.description = description;
        this.url = url;
    }
}