import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PlanItemSummary {
    @keyColumn() id: string;
    @column() optional: boolean;
    @column() name: string;
    @column() allowMultiples: boolean;
    @column() salesItemId: string;
    @column() planId: string;
    @column() salesItemCoverageId: string;
    @column() price: number;
    @column() priceRecurring: number;
    @column() isDefault: boolean;
    @column() sort: number;
    @column() showOnWeb: boolean;
    @column() isPrepaidOption: boolean;
    @column() allowServiceBeforeActive: boolean;
    @column() serviceFee: number;
    @column() otherFee: number;
}