<input type="file" style="display: none" *ngIf="!loadingPhoto" name="fileToUpload1" (change)="handleFileUpload($event)" accept="image/*"
    [id]="fileUploadId" />


<div class="center">
    <button mat-button mat-raised-button class="upload-photo-button" (click)="takePhoto()">
        <mat-icon>photo_camera</mat-icon>
    </button>
</div>
<div *ngIf="filteredPhotos && filteredPhotos.length && !hideList" topmargin30 class="soft-light"
    style="margin-left: 20px;margin-right: 20px;">
    <h3 class="sub-heading" topmargin20>{{label}}</h3>
    <div *ngFor="let attachment of filteredPhotos" class="attachment-item" aflex>
        <div nogrow>
            <img [src]="attachment.base64">
        </div>
        <div style="overflow: hidden; ">
            <div class="data-label" style="overflow: hidden; text-overflow: ellipsis">
                {{attachment.name}}</div>
        </div>
        <div nogrow>
            <button mat-button class="remove-button" (click)="removeAttachment(attachment)">
                <i class="material-icons">close</i>
            </button>
        </div>
    </div>
</div>