import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { MatDialogRef } from '@angular/material/dialog';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Entity } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-add-postal-codes',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule],
    templateUrl: './add-postal-codes.component.html',
    styleUrls: ['./add-postal-codes.component.css']
})
export class AddPostalCodesComponent {
    postalCodes: string;
    replaceList: boolean;
    serviceProTerritories: Entity[];
    parents: Entity[] = [];
    grandparents: Entity[] = [];

    constructor(public dialogRef: MatDialogRef<AddPostalCodesComponent>,
        private entityApi: EntityApiService) { }

    async ngOnInit() {
        this.serviceProTerritories = await this.entityApi.getEntityWithZipCodes('ServiceProTerritory');

        for (const item of this.serviceProTerritories) {
            if (item.parentId && !this.parents.find(i => i.id === item.parentId)) {
                this.parents.push(await this.entityApi.getBasicEntity(item.parentId));
            }
        }
        for (const item of this.parents) {
            if (item.parentId && !this.grandparents.find(i => i.id === item.parentId)) {
                this.grandparents.push(await this.entityApi.getBasicEntity(item.parentId));
            }
        }
        for (const gp of this.grandparents) {
            gp.children = this.parents.filter(i => i.parentId === gp.id);
            for (const parent of gp.children) {
                parent.children = this.serviceProTerritories.filter(i => i.parentId === parent.id);
            }
        }
    }

    addTerritory(territory: Entity) {
        if(!this.postalCodes) {
            this.postalCodes = '';
        }

        const zipCodesToAdd = territory.zipCodes.filter(i => this.postalCodes.indexOf(i) === -1);
        if (!this.postalCodes) {
            this.postalCodes = zipCodesToAdd.join('\n');
        } else {
            if (this.postalCodes[this.postalCodes.length - 1] != '\n') {
                this.postalCodes += '\n';
            }
            this.postalCodes += zipCodesToAdd.join('\n');
        }
    }

    save() {
        let list = this.postalCodes.split('\n');
        list = list.sort((a,b)=>a > b ? 1 : -1);
        this.dialogRef.close({ postalCodes: list.join('\n'), replaceList: this.replaceList });
    }
}
