import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TriggerExecutionLog {
    @keyColumn() id: string;
    @column() triggerId: string;
    @column() startTime: Date;
    @column() endTime: Date;
    @column() status: string;
    @column() detail: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;


    private _detailObject: TriggerExecutionLogDetail[];
    get detailObjects(): TriggerExecutionLogDetail[] {
        if (!this._detailObject) {
            this._detailObject = [];
            let thisStatus = '';
            try {
                thisStatus = (this.detail as any).replaceAll('<br/>', '');
            } catch {
                thisStatus = this.detail;
            }
            let iIndex = thisStatus.indexOf('<i>');
            while (iIndex > -1) {
                const endIIndex = thisStatus.indexOf('</i>', iIndex);
                if (endIIndex === -1) {
                    break;
                }
                const datePart = thisStatus.substring(iIndex + 3, endIIndex);
                const detail = new TriggerExecutionLogDetail();
                detail.date = new Date(datePart);
                thisStatus = thisStatus.substring(endIIndex + 4, thisStatus.length);


                const bStartIndex = thisStatus.indexOf('<b>');
                const bEndIndex = thisStatus.indexOf('</b>');
                detail.additionalInfo = thisStatus.substring(0, bStartIndex);
                detail.status = thisStatus.substring(bStartIndex + 3, bEndIndex);

                thisStatus = thisStatus.substring(bEndIndex, thisStatus.length);
                this._detailObject.push(detail);
                iIndex = thisStatus.indexOf('<i>');
            }
        }

        return this._detailObject;
    }
}

export class TriggerExecutionLogDetail {
    date: Date;
    status: string;
    additionalInfo: string;
}