import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-gears',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './gears.component.html',
    styleUrls: ['./gears.component.css']
})
export class GearsComponent implements OnInit {

    height = '200px';
    constructor() { }

    ngOnInit(): void {
    }

}
