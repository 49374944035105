import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { RealEstatePortalResource } from '@upkeeplabs/models/cogent';
export class RealEstatePortalResourceClient extends RealEstatePortalResource {
    salesRepEntityId: string;
    salesRep: SearchResultModel[];
}

export class RealEstatePortalResourceContainer {
    file: string;
    thumbnail: string;
    name: string;
    documentExtension: string;
}