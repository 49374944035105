import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WebQuoteSummary {
    @keyColumn() id: string;
    @column() name: string;
    @column() email: string;
    @column() addressId: string;
    @column() selectedPlanId: string;
    @column() promotionCode: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() planName: string;
    @column() basePrice: number;
    @column() basePriceRecurring: number;
    @column() phone: string;
    @column() firstName: string;
    @column() lastName: string;
    @column() quoteDetails: string;
    @column() source: string;
    @column() coverageType: string;

     _planSelection: any[];
    get planSelection() {
        if (!this._planSelection && this.quoteDetails) {
            try {
                this._planSelection = JSON.parse(this.quoteDetails);
            } catch { }
        }

        return this._planSelection;
    }
}