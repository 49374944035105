<div (dragenter)="dragenter($event)" (dragover)="dragover($event)" (drop)="drop($event)">
    <div flex>
        <div nogrow>
            <button (click)="send()" [disabled]="sending || !canSend" mat-button mat-raised-button style="height:80px;"
                color="primary">
                <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
                <mat-icon *ngIf="!sending">mail_outline</mat-icon><br />
                Send
            </button>
        </div>
        <div id="message-header">

            <div flex>
                <div nogrow class="data-label">
                    To
                </div>
                <div class="emailAddressList">
                    <mat-chip-grid #toChipList>
                        <mat-chip-option *ngFor="let toEmailAddress of toEmailAddresses" [selectable]="true"
                            [removable]="true" (removed)="remove(toEmailAddress, toEmailAddresses)">
                            {{toEmailAddress}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                        <input #toInput type="text" style="background-color: transparent !important"
                            [matAutocomplete]="toAutoComplete" [matChipInputFor]="toChipList"
                            [matChipInputAddOnBlur]="true" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add(toEmailAddresses, $event)" />

                    </mat-chip-grid>
                    <mat-autocomplete #toAutoComplete="matAutocomplete">
                        <mat-option *ngFor="let email of filterEmails(toInput.value)" [value]="email.Email">
                            {{email.Name}}&nbsp;< {{email.Email}}><span
                                    *ngIf="email.Title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{email.Title}})</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <div flex>
                <div nogrow class="data-label">CC</div>
                <div class="emailAddressList">
                    <mat-chip-grid #ccChipList>
                        <mat-chip-option *ngFor="let ccEmailAddress of ccEmailAddresses" [selectable]="true"
                            [removable]="true" (removed)="remove(ccEmailAddress, ccEmailAddresses)">
                            {{ccEmailAddress}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                        <input #ccInput type="text" style="background-color: transparent !important; width: 100%"
                            [matAutocomplete]="ccAutoComplete" [matChipInputFor]="ccChipList"
                            [matChipInputAddOnBlur]="true" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add(ccEmailAddresses, $event)" />

                    </mat-chip-grid>
                    <mat-autocomplete #ccAutoComplete="matAutocomplete">
                        <mat-option *ngFor="let email of filterEmails(ccInput.value)" [value]="email.Email">
                            {{email.Name}}&nbsp;< {{email.Email}}><span
                                    *ngIf="email.Title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{email.Title}})</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <div flex>
                <div nogrow class="data-label">BCC</div>
                <div class="emailAddressList">
                    <mat-chip-grid #bccChipList>
                        <mat-chip-option *ngFor="let bccEmailAddress of bccEmailAddresses" [selectable]="true"
                            [removable]="true" (removed)="remove(bccEmailAddress, bccEmailAddresses)">
                            {{bccEmailAddress}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                        <input #bccInput type="text" style="background-color: transparent !important; width: 100%"
                            [matAutocomplete]="bccAutoComplete" [matChipInputFor]="bccChipList"
                            [matChipInputAddOnBlur]="true" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="add(bccEmailAddresses, $event)" />
                    </mat-chip-grid>
                    <mat-autocomplete #bccAutoComplete="matAutocomplete">
                        <mat-option *ngFor="let email of filterEmails(bccInput.value)" [value]="email.Email">
                            {{email.Name}}&nbsp;< {{email.Email}}><span
                                    *ngIf="email.Title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({{email.Title}})</span>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>

            <div flex>
                <div nogrow class="data-label">Subject</div>

                <div id="subject-container">
                    <input type="text" style="width:100%" [(ngModel)]="subject" />
                </div>
            </div>
            <div flex>
                <div nogrow class="data-label">Attachments</div>

                <div>
                    <input #fileInput type="file" style="display:none" multiple
                        (change)="handleFiles(fileInput.files)" />
                    <button mat-button (click)="fileInput.click()" style="margin-right:5px; margin-bottom:2px;">Choose
                        Files</button>
                    <div *ngFor="let attachment of attachments" class="attachment-item">
                        <mat-icon style="cursor:pointer; position:absolute; right:5px; top:0;"
                            (click)="deleteAttachment(attachment)">delete</mat-icon>
                        {{attachment.name}}
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="compose-email">
        <app-rich-text-and-snippet [height]="'300px'" [(ngModel)]="body">
        </app-rich-text-and-snippet>
    </div>
</div>