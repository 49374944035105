import { RepairItem, RepairItemAttributeValue } from '@upkeeplabs/models/cogent';

export class AuthorizationLine {
    id: string;
    workOrderId: string;
    workOrderLineId: string;
    repairItemId: string;
    quantity: number;
    unitPrice: number;
    guideline: number;
    yellowRangePercent: number;
    isOutOfPocket: boolean;
    isPurchaseOrderNeeded: boolean;
    authorizationQuestions: string;
    temporaryItemName: string;
    limit: number;
    unitLimit: number;
    overallLimit: number;
    labor: number;
    lastModifiedById: string;
    lastModifiedDate: Date;

    attributes: AuthorizationLineAttribute[];
    filteredItems: RepairItem[];
    repairItem: RepairItem;
    repairItemDisplay: string;
    createdDate: Date;
    isChanged: boolean;
    pricingType: string;

    description: string;
    deleting: boolean;
    needsReview: boolean;
    get complete(): boolean {
        return !!this.repairItemId;
    }

    get coveredTotal(): number {
        if (this.isOutOfPocket) return 0;
        let limit = this.limit;
        const perItemTotal = this.total / this.quantity;
        if (this.limit === undefined || this.limit === null || this.limit === 0) {
            limit = 1000000000;
        }
        if (perItemTotal > limit) {
            return this.quantity * limit
        }
        return this.total;
    }

    get outOfPocketTotal(): number {
        if (this.isOutOfPocket) { return this.total; }
        return this.total - this.coveredTotal;
    }

    get isGreen(): boolean {
        let unitPrice = (this.total - this.laborSubTotal) / this.quantity;
        if (this.limit && this.limit < unitPrice) {
            unitPrice = this.limit;
        }
        return (this.guideline && this.guideline > 0 && (unitPrice) <= this.guideline) || (!this.guideline && this.unitPrice < 25);
    }

    get redValue() {
        return this.guideline * (1 + this.yellowRangePercent);
    }

    get isRed(): boolean {
        let unitPrice = (this.total - this.laborSubTotal) / this.quantity;
        if (this.limit && this.limit < unitPrice) {
            unitPrice = this.limit;
        }

        return (this.guideline && this.guideline > 0 && (unitPrice) >= this.redValue) || (!this.guideline && unitPrice >= 100);
    }

    get isYellow(): boolean {
        return !this.isGreen && !this.isRed;
    }

    get indicatorPercent() {
        let inPocketTotal = (this.total - this.laborSubTotal) / this.quantity;
        if (this.limit && this.limit < inPocketTotal) {
            inPocketTotal = this.limit;
        }
        inPocketTotal = inPocketTotal * this.quantity;

        if (inPocketTotal < this.beginningGreen) {
            return "5%";
        }
        if (inPocketTotal > this.endRed) {
            return "95%";
        }

        let range = this.endRed - this.beginningGreen;
        let value = inPocketTotal - this.beginningGreen;

        let percent = (value / range * 90) + 5;
        return percent + '%';
    }

    get beginningGreen() {

        if (this.guideline) {
            return this.guideline * .90 * this.quantity;
        }
        if (this.total < 25) {
            return 0;
        }
        if (this.total < 100) {
            return this.total * .9
        }
        return this.total * .5;
    }

    get endRed() {
        if (this.guideline) {
            return this.redValue * this.quantity * 1.10;
        }
        if (this.total < 25) {
            return 110;
        }
        if (this.total < 100) {
            return this.total * 1.1;
        }
        return this.total * 1.1;

    }

    get pct(): number {
        if (!this.guideline) {
            return 0;
        }

        const span = this.endRed - this.beginningGreen;
        let adjustedUnitPrice = this.unitPrice - this.beginningGreen;
        if (adjustedUnitPrice < 0) {
            adjustedUnitPrice = 0;
        }

        let pct = adjustedUnitPrice / span;
        if (pct > 1) {
            pct = 1;
        }

        return pct;
    }

    get subTotal(): number {
        return this.unitPrice * this.quantity;
    }
    set subTotal(value: number) {
        if (this.quantity === 0) {
            this.quantity = 1;
        }
        if (this.quantity !== 0) {
            this.unitPrice = value / this.quantity;
        }
    }

    laborHours: number;
    laborRate: number;


    get laborSubTotal(): number {
        if(this.laborHours == undefined) {
            this.laborHours = 0;
        }
        if(this.laborRate == undefined) {
            this.laborRate = 0;
        }
        return this.laborHours * this.laborRate;
    }
    set laborSubTotal(value: number) {
        if (!this.laborHours || this.laborHours === 0) {
            this.laborHours = 1;
        }
        if (this.laborHours !== 0) {
            if (this.lastEdited == "laborRate") {
                this.laborHours = value / this.laborRate;
            } else {
                this.laborRate = value / this.laborHours;
            }
        }
    }

    resetAttributeVisibility() {
        for (const attr of this.attributes) {
            attr.resetAttributeVisibility();
        }
    }

    lastEdited: string;

    get total(): number {
        if (this.laborSubTotal && this.subTotal) {
            return this.laborSubTotal + this.subTotal;
        }
        if (this.laborSubTotal) {
            return this.laborSubTotal;
        }
        if (this.subTotal) {
            return this.subTotal;
        }
        return 0;
    }


}

export class AuthorizationLineAttribute {
    id: string;
    authorizationLineId: string;
    attributeId: string;
    valueId: string;
    textValue: string;
    availableValues: RepairItemAttributeValue[];
    createdDate: Date;
    isChanged: boolean;
    nonHiddenAvailableValues: any;
    name: string;

    // Client-side only
    selectedValueIndex: number;
    get calculatedValue() {
        const attributeValue = this.availableValues.find(i => i.id === this.valueId);
        if (attributeValue) {
            return attributeValue.value;
        }
    }
    resetAttributeVisibility() {
        this.nonHiddenAvailableValues = this.availableValues.filter(i => !i.hidden);
    }

    get available() {
        if(this.nonHiddenAvailableValues) {
            return this.nonHiddenAvailableValues;
        }

        return this.availableValues;
    }
}