import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-work-order-search-page',
    templateUrl: './work-order-search-page.component.html',
    styleUrls: ['./work-order-search-page.component.css']
})
export class WorkOrderSearchPageComponent implements OnInit {

    search: string;
    constructor(private route: ActivatedRoute) { }


    ngOnInit() {
        this.route.paramMap.subscribe(pm => {
            const search = pm.get('searchFor');
            this.search = search;
        });
    }

}
