import { Injectable } from '@angular/core';
import { QueueQuery } from '@cogent/client/shared/models/object-queue.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CustomTableApi } from '@cogent/client/shared/services/api/custom-table-api.service';
import { ApiService } from '@cogent/client/api';
import { ObjectSummaryField } from '@cogent/shared/models/common/object-summary-field.model';

@Injectable({ providedIn: 'root' })
export class ObjectQueuesApiService {
    private objectTypeUrls: any = {
        Service: { objectUrl: 'WorkOrderSummary/queue', countUrl: 'WorkOrderSummary/queue/count' },
        Policy: { objectUrl: 'PolicySummary/queue', countUrl: 'PolicySummary/queue/count' },
        Entity: { objectUrl: 'EntitySummary/queue', countUrl: 'EntitySummary/queue/count' },
        Task: { objectUrl: 'TaskSummary/queue', countUrl: 'TaskSummary/queue/count' },
        Quote: { objectUrl: 'WebQuote/queue', countUrl: 'WebQuote/queue/count' },
        MaintenanceService: { objectUrl: 'scheduled-maintenance/queue', countUrl: 'scheduled-maintenance/queue/count', useNode: true },
        ContractorInvoice: { objectUrl: 'ContractorInvoiceSummary/queue', countUrl: 'ContractorInvoiceSummary/queue/count' },
        PurchaseOrder: { objectUrl: 'PurchaseOrderSummary/queue', countUrl: 'PurchaseOrderSummary/queue/count' },
        Invoice: { objectUrl: 'InvoiceSummary/queue', countUrl: 'InvoiceSummary/queue/count' },
        CustomTable: { objectUrl: 'UserDefinedTable/', countUrl: 'UserDefinedTable/' },
        WorkOrderLine: { objectUrl: 'WorkOrderLineSummary/queue', countUrl: 'WorkOrderLineSummary/queue/count' },
    };

    constructor(private api: ApiService,
        private customTableApi: CustomTableApi) { }

    async getQueueObjects(objectType: string, queueQuery: QueueQuery, fieldsToInclude: string[], typeCreator: () => any = null, skip: number, take = 50): Promise<any> {
        let properties = '';
        if (fieldsToInclude.indexOf('id') === -1) {
            fieldsToInclude.push('id');
        }
        // if (fieldsToInclude.indexOf('policyId') === -1) {
        //     fieldsToInclude.push('policyId');
        // }



        // Get the distinct fields values
        fieldsToInclude = Array.from(new Set(fieldsToInclude));
        if (fieldsToInclude) {
            fieldsToInclude.forEach(field => {
                if (properties !== '') {
                    properties += ',';
                }
                properties += field;
            });
        }

        const params = await this.buildQueueQueryParams(objectType, queueQuery, properties, skip, take);

        let url = this.objectTypeUrls[objectType].objectUrl;
        const useNode = this.objectTypeUrls[objectType].useNode;
        if (objectType === 'CustomTable') {
            url += queueQuery.customTableName;
        }

        return useNode ? this.api.getArrayNode(url, params, typeCreator ? () => typeCreator() : null)
            : this.api.getArrayDotNet(url, params, typeCreator ? () => typeCreator() : null);
    }

    async getQueueItemCount(objectType: string, queueQuery: QueueQuery) {

        const params = await this.buildQueueQueryParams(objectType, queueQuery, '', 0, 1000);

        delete params.take;
        delete params.skip;
        delete params.properties;
        delete params.orderby;

        let url = this.objectTypeUrls[objectType].countUrl;
        const useNode = this.objectTypeUrls[objectType].useNode;
        if (objectType === 'CustomTable') {
            url += `${queueQuery.customTableName}/count`;
        }

        return useNode ? this.api.getSingleNode(url, params)
            : this.api.getSingleDotNet(url, params);
    }

    private async getCustomTableFields(queueQuery: QueueQuery): Promise<ObjectSummaryField[]> {
        const table = await this.customTableApi.getCustomTableByName(queueQuery.customTableName);

        return table.fields;
    }

    private async buildQueueQueryParams(objectType: string, queueQueryOriginal: QueueQuery, properties: string, skip: number, take: number) {


        const queueQuery: QueueQuery = UtilitiesService.copyObject(queueQueryOriginal, null, () => new QueueQuery());

        const params = {
            properties
        } as any;



        if (queueQuery.selectedTags && queueQuery.selectedTags.length > 0) {
            let qString = '';
            queueQuery.selectedTags.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += objectType === 'CustomTable' ? item.name : item.id;
            });
            params.tags = qString;
        }

        if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
            let qString = '';
            queueQuery.selectedTagsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += objectType === 'CustomTable' ? item.name : item.id;
            });
            params.excludedTags = qString;
        }
        if (objectType === 'Service') {
            if (queueQuery.selectedSLAStatuses && queueQuery.selectedSLAStatuses.length > 0) {
                let qString = '';
                queueQuery.selectedSLAStatuses.forEach(item => {
                    if (qString) {
                        qString += ',';
                    }
                    qString += item;
                });
                params.SLAStatus_contains = qString;
            }


            if (queueQuery.selectedSLAStatusesExcluded && queueQuery.selectedSLAStatusesExcluded.length > 0) {
                let qString = '';
                queueQuery.selectedSLAStatusesExcluded.forEach(item => {
                    if (qString) {
                        qString += ',';
                    }
                    qString += item;
                });
                params.SLAStatus_ncontains = qString;
            }

            // Converting from single item to multiple items
            if (queueQuery.selectedItems && queueQuery.selectedItems.length > 0) {
                let qString = '';
                queueQuery.selectedItems.forEach(item => {
                    if (qString) {
                        qString += ',';
                    }
                    qString += item.id;
                });

                delete queueQuery.selectedItems;
                params.itemIds = qString;
            }

            if (queueQuery.selectedItemsExcluded && queueQuery.selectedItemsExcluded.length > 0) {
                let qString = '';
                queueQuery.selectedItemsExcluded.forEach(item => {
                    if (qString) {
                        qString += ',';
                    }
                    qString += item.id;
                });

                delete queueQuery.selectedItemsExcluded;
                params.excludedItemIds = qString;
            }
        }

        if (queueQuery.whereClause) {
            params.where = queueQuery.whereClause;
        }
        else {
            const fields = objectType === 'CustomTable' ? await this.getCustomTableFields(queueQuery) : ObjectSummaryField.getFieldsByQueueType(objectType);
            for (const field of fields.filter(i => i.filterCapabilities)) {
                if (queueQuery[field.filterCapabilities.queueQueryField] || queueQuery[field.filterCapabilities.queueQueryField] === false) {
                    if (field.filterCapabilities.editorType === 'DATE') {
                        const dates = UtilitiesService.getDatesFromDateRange(queueQuery[field.filterCapabilities.queueQueryField]);
                        if (dates[0]) {
                            params[`${field.filterCapabilities.filterFieldName}_gte`] = dates[0];
                        }
                        if (dates[1]) {
                            params[`${field.filterCapabilities.filterFieldName}_lte`] = dates[1];
                        }
                        if (objectType === 'CustomTable') {
                            if (dates[0]) {
                                params[`${field.filterCapabilities.filterFieldName}_gte`] = dates[0].getTime();
                            }
                            if (dates[1]) {
                                params[`${field.filterCapabilities.filterFieldName}_lte`] = dates[1].getTime();
                            }
                        } else {
                            if (dates[0]) {
                                params[`${field.filterCapabilities.filterFieldName}_gte`] = dates[0];
                            }
                            if (dates[1]) {
                                params[`${field.filterCapabilities.filterFieldName}_lte`] = dates[1];
                            }
                        }
                    } else if (field.filterCapabilities.editorType === 'BOOL') {
                        params[`${field.filterCapabilities.filterFieldName}_eq`] = queueQuery[field.filterCapabilities.queueQueryField];
                    } else if (field.dataType === 'select') {
                        let queryString = '';
                        queueQuery[field.filterCapabilities.queueQueryField].forEach(value => {
                            if (queryString) {
                                queryString += ',';
                            }
                            if (value.id) {
                                queryString += value.id;
                            } else if (value.key && value.value) {
                                queryString += value.key;
                            } else if (value.name) {
                                queryString += value.name;
                            } else {
                                queryString += value;
                            }
                        });

                        if (queryString) {
                            params[`${field.filterCapabilities.filterFieldName}_contains`] = queryString;
                        }
                    } else {
                        let queryString = '';
                        queueQuery[field.filterCapabilities.queueQueryField].forEach(value => {
                            if (queryString) {
                                queryString += ',';
                            }
                            if (value.id) {
                                queryString += value.id;
                            } else if (value.key && value.value) {
                                queryString += value.key;
                            } else if (value.name) {
                                queryString += value.name;
                            } else {
                                queryString += value;
                            }
                        });

                        if (queryString) {
                            params[`${field.filterCapabilities.filterFieldName}_contains`] = queryString;
                        }
                    }
                } else {
                    if (field.filterCapabilities.editorType === 'DATE') {
                        if (queueQuery[field.filterCapabilities.queueQueryField + '_StartDate']) {
                            const startDate = UtilitiesService.dayBegin(queueQuery[field.filterCapabilities.queueQueryField + '_StartDate']);
                            params[`${field.filterCapabilities.filterFieldName}_gte`] = startDate;
                        }
                        if (queueQuery[field.filterCapabilities.queueQueryField + '_EndDate']) {
                            const endDate = UtilitiesService.dayEnd(queueQuery[field.filterCapabilities.queueQueryField + '_EndDate']);
                            params[`${field.filterCapabilities.filterFieldName}_lte`] = endDate;
                        }
                    }
                }
                if (queueQuery[field.filterCapabilities.notInQueryField]) {
                    let queryString = '';
                    queueQuery[field.filterCapabilities.notInQueryField].forEach(value => {
                        if (queryString) {
                            queryString += ',';
                        }
                        if (value.id) {
                            queryString += value.id;
                        } else if (value.key && value.value) {
                            queryString += value.key;
                        } else if (value.name) {
                            queryString += value.name;
                        } else {
                            queryString += value;
                        }
                    });

                    if (queryString) {
                        params[`${field.filterCapabilities.filterFieldName}_ncontains`] = queryString;
                    }
                }
                if (queueQuery[field.filterCapabilities.isSetField]) {
                    params[`${field.filterCapabilities.filterFieldName}_neq`] = '{{null}}';
                }
                if (queueQuery[field.filterCapabilities.notSetField]) {
                    params[`${field.filterCapabilities.filterFieldName}_eq`] = '{{null}}';
                }
            }
        }

        params.skip = skip;
        params.take = take;
        params.orderby = '';

        if (queueQuery.groupByField) {
            params.orderby = `${queueQuery.groupByField},`;
        }

        if (queueQuery.sortByField && objectType !== 'CustomTable') {
            params.orderby += queueQuery.sortByField + (queueQuery.sortDescending ? ' descending' : '') + ',id';
        } else if (queueQuery.sortByField && objectType === 'CustomTable') {
            params.orderby += queueQuery.sortByField + (queueQuery.sortDescending ? ' desc' : '')
        } else {
            if (objectType === 'WorkOrder') {
                params.orderby += 'number';
            } else if (objectType === 'Policy') {
                params.orderby = 'policyNumber';
            } else if (objectType === 'Task') {
                params.orderby = 'TaskNumber';
            } else if (objectType === 'ContractorInvoice') {
                params.orderby = 'contractorInvoiceNumber';
            } else if (objectType === 'CustomTable') {
                delete params.orderby;
            } else if (objectType === 'Quote') {
                params.orderby = 'CreatedDate';
            } else if(objectType === 'MaintenanceService') {
                params.orderby = 'CreatedDate';
            } else {
                params.orderby = 'id';
            }
        }

        return params;
    }
}