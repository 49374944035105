import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderItemTypeDiagram {
    @keyColumn() id: string;
    @column() name: string;
    @column() config: string;
    @column() primary: boolean;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() workOrderItemTypeId: string;
    @column() sort: number;

    isNew = false;
    edited = false;
    url: string;
}