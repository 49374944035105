import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-circle-animated',
  templateUrl: './review-circle-animated.component.html',
  styleUrls: ['./review-circle-animated.component.css']
})
export class ReviewCircleAnimatedComponent implements OnInit {
    @Input() height = '200px';
    
  constructor() { }

  ngOnInit(): void {
  }

}
