<div [id]="instanceId" class="root-container" [class.condensed]="condensedMode">
    <div bottommargin20 id="filter-form-field-container">
        <mat-form-field id="filter-form-field" style="max-width: 300px;margin-top:5px" appearance="outline">
            <mat-label>
                <mat-icon>filter_alt</mat-icon>
                Filter</mat-label>
            <input matInput (ngModelChange)="updateFilteredItems($event)" [(ngModel)]="filter">
        </mat-form-field>
    </div>

    <div class="" *ngIf="!filter">
        <div class="items-grid" *ngIf="!filter">
            <ng-container *ngFor="let group of groups">
                <ng-container *ngFor="let category of group.items">
                    <button  mat-button [id]="category.id + '_btn'" [class.group-expanded]="group.selectedItem"
                         [class.bottom-button]="category.isLastRow"
                        [class.right-button]="category.isRight" class="grouped-item"
                        [class.selected]="group.selectedItem === category" style="overflow: hidden;"
                        (click)="showGroup(group, category)">
                        <h2 style="font-size: 18px;margin-bottom: 10px;" data-cy="work-order-category">
                            {{category.category}}
                        </h2>
                        <lottie-player *ngIf="category.lottieUrl" [id]="category.id" [src]="category.lottieUrl"
                            background="transparent" speed="1" loop hover>
                        </lottie-player>
                        <img *ngIf="!category.lottieUrl" [src]="category.iconUrl">
                        <div class="data-label" *ngIf="category.workOrderItems?.length > 1">
                            {{category.workOrderItems.length}} Items</div>
                        <p>
                            {{category.sampleList}}
                        </p>
                    </button>
                </ng-container>

                <div class="item-detail" [id]="group.id" *ngIf="group.selectedItem">
                    <ul class="low-padding work-order-item-list" style="margin-bottom: 0;padding: 10px">
                        <li class="work-order-item" *ngFor="let item of group.selectedItem.workOrderItems">
                            <div class="flex-container">
                                <a mat-button (click)="selectWorkOrderItem(item)" style="text-align: left;" data-cy="work-order-item">
                                    <div class="flex-container">
                                        <div class="no-grow">
                                            <div class="thumbnail-wrapper">
                                                <img loading="lazy" [src]="getItemUrl(item)" class="item-thumbnail"
                                                    isCollapsed>
                                            </div>
                                        </div>
                                        <div style="padding-top: 15px">
                                            {{item.name}}
                                        </div>
                                    </div>
                                </a>

                                <div class="no-grow">
                                    <a mat-button (click)="showContractLanguage(item)"
                                        style="text-align: center; opacity: .3; max-width: 50px">
                                        <i class="material-icons">more_horiz</i>
                                    </a>
                                </div>
                            </div>
                        </li>

                        <ng-container *ngIf="group.selectedItem.maintOfferings?.length">
                            <li class="work-order-item" *ngFor="let item of group.selectedItem.maintOfferings">
                                <div aflex>
                                    <a mat-button style="text-align: left;line-height: 20px;margin-bottom: 10px;"
                                        (click)="selectedMaintOffering=item; addMaintServiceClicked.emit(true)" data-cy="work-order-item">
                                        <div aflex>
                                            <div nogrow>
                                                <div class="thumbnail-wrapper">
                                                    <img loading="lazy" class="item-thumbnail"
                                                        [src]="getAddOnPicture(item)">
                                                </div>
                                            </div>
                                            <div>
                                                {{item.maintenanceServiceName}}<br>
                                                Starting at: {{item.startingAt | currency}}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <div class="center">
                                <button mat-button mat-raised-button color="primary" style="    line-height: 44px;
                                border-radius: 15px" class="small-primary"
                                    (click)="addMaintServiceClicked.emit(true)">Order Now</button>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="group.selectedItem.maintenanceServiceCustomerPropertyServiceSummary">

                            <li class="work-order-item"
                                *ngFor="let addOn of group.selectedItem.maintenanceServiceCustomerPropertyServiceSummary.addOns">
                                <div aflex>
                                    <a mat-button style="text-align: left;"
                                        (click)="invokeAddOn(addOn, group.selectedItem.maintenanceServiceCustomerPropertyServiceSummary)">
                                        <div aflex>
                                            <div nogrow>
                                                <div class="thumbnail-wrapper">
                                                    <img loading="lazy" class="item-thumbnail"
                                                        [src]="getAddOnPicture(addOn)">
                                                </div>
                                            </div>
                                            <div style="padding-top: 15px">
                                                {{addOn.name}}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </li>

                        </ng-container>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="filter">
        <div>
            <div>
                <div *ngFor="let item of filteredItems" aflex>
                    <div>
                        <a mat-button style="text-align: left; min-width: 350px" (click)="selectWorkOrderItem(item)">
                            <i class="material-icons">keyboard_arrow_right</i> {{item.name}}
                        </a>
                    </div>
                    <div nogrow>
                        <a mat-button (click)="showContractLanguage(item)" style="text-align: center">
                            <i class="material-icons">search</i>
                        </a>
                    </div>
                </div>

                <button mat-button mat-raised-button (click)="clearFilter()">Clear</button>
            </div>
        </div>
    </div>
</div>
