import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TaskParticipantNotification {
    @keyColumn() id: string;
    @column() taskParticipantId: string;
    @column() notificationType: string;
    @column() notificationMessage: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}