<div aflex>
    <h2 class="sub-heading">Add Postal Codes</h2>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>

    <div aflex>
        <div nogrow style="width: 300px">
            <div class="data-label">Territories</div>
            <div *ngFor="let gp of grandparents">
                <div aflex>
                    <div nogrow><button mat-button (click)="gp.expanded = !gp.expanded">
                            <mat-icon class="rotate" [class.rotated]="gp.expanded">chevron_right</mat-icon>
                        </button></div>
                    <div style="margin-left: 0;padding-top: 5px;">{{gp.name}}</div>
                </div>
                <div style="padding-left: 20px;" *ngIf="gp.expanded" class="fade-in-left">

                    <div *ngFor="let parent of gp.children">
                        <div aflex>
                            <div nogrow><button mat-button (click)="parent.expanded = !parent.expanded">
                                    <mat-icon class="rotate" [class.rotated]="parent.expanded">chevron_right</mat-icon>
                                </button></div>
                            <div style="margin-left: 0;padding-top: 5px;">{{parent.name}}</div>
                        </div>
                        <div style="padding-left: 20px;" *ngIf="parent.expanded" class="fade-in-left">
                            <div *ngFor="let territory of parent.children" style="padding-left: 60px;">
                                <div aflex>
                                    <div>{{territory.name}}</div>
                                    <div nogrow><button mat-button
                                            (click)="addTerritory(territory)"><mat-icon>add</mat-icon></button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Postal Codes</mat-label>
                <textarea cdkFocusInitial matInput [(ngModel)]="postalCodes" rows="7"></textarea>
                <mat-hint>One per line</mat-hint>
            </mat-form-field>

            <mat-slide-toggle [(ngModel)]="replaceList">Replace My Current List with This One</mat-slide-toggle>
        </div>
    </div>



</div>
<div mat-dialog-actions>
    <button mat-button [disabled]="!postalCodes" mat-raised-button color="primary" (click)="save()">Save</button>
</div>