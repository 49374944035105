import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource() export class DispatchExportSummary {
    @keyColumn() id: string;
    @column() number: bigint;
    @column() type: string;
    @column() contractorId: string;
    @column() effectiveDate: Date;
    @column() expirationDate: Date;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() preferredContactMethod: string;
    @column() additionalNotes: string;
    @column() status: string;
    @column() isSubjectToPreExistingConditions: boolean;
    @column() linesJson: string;
    @column() holderId: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactEmail: string;
    @column() contactMobile: string;
    @column() itemName: string;
    @column() propertyAddress: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() tradeName: string;
    @column() scheduledDate?: Date;
    @column() createdDate?: Date;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;
    @column() sendText: string;
    @column() contractorEmail: string;
}