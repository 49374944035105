import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource("BI_TopAgents")
export class BiTopAgent {
    @column() agentName: string;
    @column() agentPhone: string;
    @column() address1: string;
    @column() city: string;
    @column() zip: string;
    @column() officeName: string;
    @column() officePhone: string;
    @column() mls: string;
    @column() territoryId: string;
    @column() territoryName: string;
    @column() buyerCount: number;
    @column() sellerCount: number;
    @column() rank: number;
    @column() sellers: boolean;

    // @column() salesTerritory: string;
    // @column() agent: string;
    // @column() agentPhone: string;
    // @column() branchName: string;
    // @column() rank: number;
    // @column() elevate12: number;
    // @column() elevate4: number;
    // @column() mls12: number;
    // @column() mls4: number;

    // get percent12() {
    //     return this.elevate12 / this.mls12;
    // }
    // get percent4() {
    //     return this.elevate4 / this.mls4;
    // }

}