import { DatePipe } from '@angular/common';

export class AppointmentTimeSlot {
    id: string;
    date: Date;
    startTime: number;
    endTime: number;
    type: string;
    timeSet = false;

    get dayString() {
        let dateToCompare = new Date();
        if (dateToCompare.getDate() === this.date.getDate() && dateToCompare.getMonth() === this.date.getMonth() && dateToCompare.getFullYear() === this.date.getFullYear()) {
            return 'Today';
        }
        dateToCompare.setDate(dateToCompare.getDate() + 1);
        if (dateToCompare.getDate() === this.date.getDate() && dateToCompare.getMonth() === this.date.getMonth() && dateToCompare.getFullYear() === this.date.getFullYear()) {
            return 'Tomorrow';
        }

        const datePipe = new DatePipe('en-US');
        const dayDifference = (this.date.getTime() - dateToCompare.getTime()) / (1000 * 3600 * 24);
        if (dayDifference > 5) {
            return datePipe.transform(this.date, 'M/d');
        }

        return datePipe.transform(this.date, 'EEE, M/d');
    }

    get dt() {
        return new Date(this.date);
    }

    get summary() {
        return `${this.dayString} ${this.startHour}${this.startMinute ? ':' + this.startMinute : ''} ${this.startHourAMPM} 
        - ${this.endHour}${this.endMinute ? ':' + this.endMinute : ''} ${this.endHourAMPM} `;
    }

    get startWindow() {
        return `${this.startHour}${this.startMinute ? ':' + this.startMinute : ''} ${this.startHourAMPM}`;
    }

    get endWindow() {
        return `${this.endHour}${this.endMinute ? ':' + this.endMinute : ''} ${this.endHourAMPM} `
    }

    get startHour() {
        if (this.startTime < 60) {
            return 12;
        }
        let startHour = this.startTime / 60;
        if (startHour > 12) {
            startHour -= 12;
        }
        return startHour;
    }

    get startMinute() {
        return this.startTime % 60;
    }

    get endMinute() {
        return this.endTime % 60;
    }

    get startHourAMPM() {
        return (this.startTime / 60) >= 12 ? 'PM' : 'AM';
    }

    get endHourAMPM() {
        return (this.endTime / 60) >= 12 ? 'PM' : 'AM';
    }

    get rangeDescription() {
        if (this.startTime === 480 && this.endTime === 1020) {
            return 'All Day';
        }
        if (this.startTime === 480 && this.endTime === 720) {
            return 'Morning';
        }
        if (this.startTime === 720 && this.endTime === 1020) {
            return 'Afternoon';
        }
    }

    get endHour() {
        if (this.endTime < 60) {
            return 12;
        }
        let endHour = this.endTime / 60;
        if (endHour > 12) {
            endHour -= 12;
        }
        return endHour;
    }

    // client-side only 
    accepting = false;
}

export class GroupedAppointmentTimeSlot {
    static fromAppointmentTimeSlots(timeSlots: AppointmentTimeSlot[]): GroupedAppointmentTimeSlot[] {
        const results: GroupedAppointmentTimeSlot[] = [];

        timeSlots.forEach(timeSlot => {
            timeSlot.date = new Date(timeSlot.date);
            let foundGroup = results.filter(i => i.date.getDate() === timeSlot.date.getDate() && i.date.getMonth() === timeSlot.date.getMonth()
                && i.date.getFullYear() === timeSlot.date.getFullYear())[0];
            if (!foundGroup) {
                foundGroup = new GroupedAppointmentTimeSlot();
                foundGroup.date = timeSlot.date;
                foundGroup.appointmentTimeSlots = [];
                results.push(foundGroup);
            }
            foundGroup.appointmentTimeSlots.push(timeSlot);
        });

        return results;
    }

    date: Date;
    appointmentTimeSlots: AppointmentTimeSlot[];

    get dayString() {
        let dateToCompare = new Date();
        if (dateToCompare.getDate() === this.date.getDate() && dateToCompare.getMonth() === this.date.getMonth() && dateToCompare.getFullYear() === this.date.getFullYear()) {
            return 'Today';
        }
        dateToCompare.setDate(dateToCompare.getDate() + 1);
        if (dateToCompare.getDate() === this.date.getDate() && dateToCompare.getMonth() === this.date.getMonth() && dateToCompare.getFullYear() === this.date.getFullYear()) {
            return 'Tomorrow';
        }

        const datePipe = new DatePipe('en-US');
        const dayDifference = (this.date.getTime() - dateToCompare.getTime()) / (1000 * 3600 * 24);
        if (dayDifference > 5) {
            return datePipe.transform(this.date, 'M/d');
        }

        return datePipe.transform(this.date, 'EEE, M/d');
    }
}
