import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { Brand } from "@upkeeplabs/models/cogent";

@dataSource()
export class ContractorBrand {
    @keyColumn() id: string;
    @column() contractorId: string;
    @column() brandId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
}

export class ContractorBrandSelector {
    public selected = false;


    public static fromBrands(brands: Brand[], contractorBrands: ContractorBrand[]) {
        const result = [];

        brands.forEach(brand => {
            const selector = new ContractorBrandSelector(brand);

            selector.selected =
                contractorBrands.filter(i => i.brandId === brand.id).length > 0;

            result.push(selector);
        });

        return result;
    }

    public static toContractorBrands(selectors: ContractorBrandSelector[], contractorId: string) {
        const result = [];

        selectors.forEach(selector => {
            if (selector.selected) {
                const contractorBrand = new ContractorBrand();
                contractorBrand.brandId = selector.brand.id;
                contractorBrand.contractorId = contractorId;

                result.push(contractorBrand);
            }
        });
        return result;
    }

    constructor(public brand: Brand) { }
}