import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditObjectTagsComponent } from '@cogent/client/shared/components/misc/common-dialogs/edit-object-tags/edit-object-tags.component';
import { Observable } from 'rxjs';
import { ChangePasswordComponent } from '@cogent/client/shared/components/misc/common-dialogs/change-password/change-password.component';
import { Entity, Tag } from '@upkeeplabs/models/cogent';

@Injectable({
    providedIn: 'root'
})
export class UtilityDialogService {

    constructor(private dialog: MatDialog) { }

    public showTagsDialog(tags: Tag[], objectType: string, objectId: string): Observable<Tag[]> {
        const config = new MatDialogConfig();
        config.data = {
            tags,
            objectType,
            objectId,
        };
        const dialogRef = this.dialog.open(EditObjectTagsComponent, config);

        return dialogRef.afterClosed();
    }

    public showChangePasswordDialog(entityId: string): Observable<any> {
        const config = new MatDialogConfig();
        config.data = {
            entityId
        };

        const dialogRef = this.dialog.open(ChangePasswordComponent, config);
        return dialogRef.afterClosed();
    }

    public showChangePasswordDialogForLoginId(loginId: string): Observable<any> {
        const config = new MatDialogConfig();
        config.data = {
            loginId
        };

        const dialogRef = this.dialog.open(ChangePasswordComponent, config);
        return dialogRef.afterClosed();
    }
}
