<div *ngIf="currentStep === 'request-code'">
    <form>
        <div aflex style="max-width: 450px">
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Phone Or Email</mat-label>
                    <input matInput name="phoneOrEmail" [(ngModel)]="phoneOrEmail">
                </mat-form-field>
            </div>
            <div nogrow style="margin-left: 0">
                <button style="margin-left: -117px; margin-top: 4px" mat-button mat-raised-button color="primary" class="small-primary"
                    [disabled]="!phoneOrEmail || gettingCode" (click)="requestVerificationCode()">
                    <mat-spinner style="position: absolute" *ngIf="gettingCode" class="thirty"></mat-spinner>
                    Get
                    Code</button>
            </div>
        </div>
    </form>
</div>

<div class="fade-in-up" *ngIf="currentStep === 'enter-code'">
    <form>
        <div aflex style="max-width: 300px;">
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>Code</mat-label>
                    <input name="code" matInput [(ngModel)]="code" type="number">
                </mat-form-field>
            </div>
            <div nogrow style="margin-left: 0">
                <button mat-button mat-raised-button style="margin-left: -106px; margin-top: 4px" color="primary" class="small-primary"
                    [disabled]="!code || validating" (click)="validateVerificationCode()">
                    <mat-spinner style="position: absolute;" *ngIf="validating" class="thirty"></mat-spinner>
                    Validate    
                </button>
            </div>
        </div>
    </form>
    <button mat-button (click)="currentStep = 'request-code'">
        <mat-icon>undo</mat-icon> Request Another Code
    </button>
</div>

<div class="fade-in-up outer-container" *ngIf="currentStep === 'choose-policy'">

    <div class="property-container">
        <a mat-button class="property-button" (click)="selectPolicy(result)" *ngFor="let result of policies">
            <div aflex>
                <div nogrow>
                    <img [src]="getPictureUrl(result)" class="big-icon">
                </div>
                <div>
                    <h4 matLine>{{result.address1}}</h4>
                    <p matLine>{{result.policyNumber}} - {{result.planName}} </p>
                    <p matLine><span
                        class="badge">{{result.status}}</span></p>
                </div>
            </div>
        </a>
    </div>

    <button mat-button (click)="restart()">
        <mat-icon>undo</mat-icon> Restart
    </button>
</div>

<!-- <div class="fade-in-up" *ngIf="currentStep === 'policy-chosen'">
    <div class="data-label">Selected Subscription</div>
    <h2 class="sub-heading">{{selectedPolicy.address1}}</h2>
    <p matLine>{{selectedPolicy.policyNumber}} - {{selectedPolicy.planName}} <span
            class="badge">{{selectedPolicy.status}}</span></p>
    <button mat-button (click)="change()">
        Change
    </button>
</div> -->