<mat-form-field class="example-chip-list" appearance="outline">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let tag of selectedTags" 
            (removed)="remove(tag)">
            {{tag.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input autocomplete="off" #tagInput matInput [formControl]="tagCtrl"
            [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
            {{tag.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

