import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorTradeAreaTarget {
    @keyColumn() id: string;
    @column() tradeId: string;
    @column() areaId: string;
    @column() month: Date;
    @column() aCCTarget: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() recallTarget: number;
    @column() firstCallCompleteTarget: number;
    @column() surveyTarget: number;
    @column() scoreTarget: number;




    // accTarget: number;
    // Client Side Only
    isDirty = false;
    get MonthUtc(){
        return new Date(this.month.toUTCString());
    }

    get recallTargetDisplay() {
        return parseFloat((this.recallTarget * 100).toFixed(0));
    }
    set recallTargetDisplay(value) {
        this.recallTarget = value / 100;
    }

    get firstCallCompleteTargetDisplay() {
        return parseFloat((this.firstCallCompleteTarget * 100).toFixed(0));
    }
    set firstCallCompleteTargetDisplay(value) {
        this.firstCallCompleteTarget = value / 100;
    }
}