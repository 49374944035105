import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ObjectCommand {
    @keyColumn() id: string;
    @column() query: string;
    @column() roles: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() name: string;
    @column() availableToAllRoles: boolean;
    @column() commandType: string;
    @column() availableOnCustomerPortal: boolean;
    @column() availableOnContractorPortal: boolean;
    @column() availableOnCogent: boolean;
    @column() availableToQuestionnaires: boolean;
    @column() availableOnJobDetail: boolean;
    @column() availableOnAuthorization: boolean;
}