import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Singleton {
    @keyColumn() id: string;
    @column() nextPolicyNumber: bigint;
    @column() companyId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() stripePublicToken: string;
    @column() stripePriveToken: string;
    @column() weatherAPIKey: string;
    @column() googleMapsKey: string;
    @column() portalBaseUrl: string;
    @column() azureSearchBaseUrl: string;
    @column() azureSearchKey: string;
    @column() convertAPISecret: string;
    @column() inQueueContractorId: string;
    @column() azureStorageConnectionString: string;
    @column() quickBooksCompanyId: bigint;
    @column() quickBooksAPIBaseURL: string;
    @column() twilioAccountSid: string;
    @column() twilioAuthToken: string;
    @column() customerPortalBaseUrl: string;
    @column() contractorPortalBaseUrl: string;
    @column() processesAPIKey: string;
    @column() aPIBaseUrl: string;
    @column() defaultTwilioNumber: string;
    @column() useIntegratedPhoneSystem: boolean;
    @column() realtorPortalBaseUrl: string;
    @column() slyBroadcastUserName: string;
    @column() slyBroadcastPassword: string;
    @column() sMTPServer: string;
    @column() sMTPPort: number;
    @column() sMTPUserName: string;
    @column() sMTPPassword: string;
    @column() useMetric: boolean;
    @column() quickbooksClientId: string;
    @column() quickbooksClientSecret: string;
    @column() quickbooksDiscoveryEndpoint: string;
    @column() quickbooksRefreshToken: string;
    @column() quickbooksRefreshTokenExpires: Date;
    @column() firebaseAppKey: string;
    @column() firebaseSender: string;
    @column() firebaseUrl: string;
    @column() cosmosDbConnectionString: string;
    @column() zillowWebServiceId: string;
    @column() stripeSecretKey: string;
    @column() stripePublicKey: string;
    @column() now: Date;
    @column() azureSearchIndex: string;
    @column() enableCustomerPortal: boolean;
    @column() amazonConnectCppUrl: string;
    @column() amazonConnectApiId: string;
    @column() amazonConnectRegion: string;
    @column() amazonConnectContactFlowId: string;
    @column() amazonConnectInstanceId: string;
    @column() amazonConnectLoginUrl: string;
    @column() enableContractorPortal: boolean;
    @column() aWSAccessKey: string;
    @column() aWSSecretKey: string;
    @column() aWSUsersDynamoTableName: string;
    @column() aWSAgentS3Bucket: string;
    @column() aWSConnectInstanceId: string;
    @column() disablePhoneOnDev: boolean;
    @column() weeklyProgressReportDistributionList: string;
    @column() orderConfirmationBCCDistributionList: string;
    @column() userDefinedTableName: string;
    @column() defaultReplyTo: string;
    @column() cogentBaseUrl: string;
    @column() reliablePartsUserName: string;
    @column() reliablePartsPassword: string;
    @column() reliablePartsBaseUrl: string;
    @column() rPPartSearchApiKey: string;
    @column() rPPartSearchUrl: string;
    @column() rPCreateOrderApiKey: string;
    @column() rPCreateOrderUrl: string;
    @column() rPOrderStatusApiKey: string;
    @column() rPOrderStatusUrl: string;
    @column() rPUPSTrackingApiKey: string;
    @column() rPUPSTrackingUrl: string;
    @column() rPInvoiceLookupApiKey: string;
    @column() rPInvoiceLookupUrl: string;
    @column() rPModelSearchApiKey: string;
    @column() rPModelSearchUrl: string;
    @column() rPModelToPartsApiKey: string;
    @column() rPModelToPartsUrl: string;
    @column() rPManufacturerListingApiKey: string;
    @column() rPManufacturerListingUrl: string;
    @column() rPWarehouseListingApiKey: string;
    @column() rPWarehouseListingUrl: string;
    @column() rPReturnsApiKey: string;
    @column() rPReturnsUrl: string;
    @column() rPCancelOrderApiKey: string;
    @column() rPCancelOrderUrl: string;
    @column() sMSBank: string;
    @column() allowOnlineAutho: boolean;
    @column() payPalApiBaseUrl: string;
    @column() payPalUserName: string;
    @column() payPalPassword: string;
    @column() nodeApiBaseUrl: string;
    @column() accountClientSecret: string;
    @column() accountClientId: string;
    @column() accountUrl: string;
    @column() nodePhoneBank: string;
    @column() azureComputerVisionKey: string;
    @column() azureComputerVisionEndpoint: string;
    @column() microsoftGraphClientId: string;
    @column() microsoftGraphClientSecret: string;
    @column() microsoftGraphTenantId: string;
    @column() defaultNodeSMSNumber: string;
    @column() chatStreamingEndpointArn: string;
    @column() sandboxEmails: boolean;
    @column() dispatchSecret: string;
    @column() dispatchKey: string;
    @column() dispatchConnectUrl: string;
    @column() dispatchFilesUrl: string;
    @column() enableDispatchSync: boolean;
    @column() openAIEndpoint: string;
    @column() openAIAccessKey: string;
    @column() openAIDeploymentId: string;
    @column() sandboxSMSMessages: boolean;
    smsBank: string;

    private smsBankLocal: string[];
    get smsBankList() {

        if (!this.smsBankLocal) {
            if (this.smsBank) {
                this.smsBankLocal = JSON.parse(this.smsBank);
            } else {
                this.smsBankLocal = [];
            }
        }
        return this.smsBankLocal;

    }

    private nodeSMSBankLocal: string[];

    get nodeSMSBankList() {
        if (!this.nodeSMSBankLocal) {
            if (this.smsBank) {
                this.nodeSMSBankLocal = JSON.parse(this.nodePhoneBank);
            } else {
                this.nodeSMSBankLocal = [];
            }
        }
        return this.nodeSMSBankLocal;
    }
}