import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { AfterCallSurveyResult } from '@cogent/shared/models/service/after-call-survey-result.model';
import { ChatConfig, ConnectConfig } from '@cogent/shared/models/communication/chat-config.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AmazonConnectCTR, AmazonConnectCTRSummary, AmazonContact, AuthoCallsAnsweredByAuthoAgents, NewEmployeeArgs, PhoneNumberCustomFunctionMapping } from '@upkeeplabs/models/cogent';

export class ContactTraceSearchArgs {
    connectedToSystem_gte: Date;
    connectedToSystem_lte: Date;
    select = 'id, contactId,channel,connectedToSystem,disconnected,queueName,agentUserName,systemEndpointAddress,systemEndpointType,surveyScore,recordingLocation,customerEndpointAddress';
    orderby = 'connectedToSystem DESC';
    customerEndpointAddress_like: string;
    channel_eq: string;
    initiationMethod_eq: string;
    agentUserName_like: string;
    queueName_eq: string;
}

@Injectable({ providedIn: 'root' })
export class AmazonContactApiService {
    createQuickConnects(args: NewEmployeeArgs) {
        return this.api.postNode('amazonconnect/create-quick-connects', args);
    }

    constructor(
        private api: ApiService
    ) { }

    getOldestContactInQueue() {
        return this.api.getSingleNode(`amazonconnect/oldest-queue-item`);
    }

    getOldestAuthoContactInQueue() {
        return this.api.getSingleNode(`amazonconnect/oldest-autho-queue-item`);
    }

    getContactsInQueue() {
        return this.api.getSingleNode(`amazonconnect/contacts-in-queue`);
    }

    getSalesContactsInQueue() {
        return this.api.getSingleNode(`amazonconnect/sales-contacts-in-queue`);
    }

    getAuthoContactsInQueue() {
        return this.api.getSingleNode(`amazonconnect/autho-contacts-in-queue`);

    }

    getAgentRealTimeStats() {
        return this.api.getSingleNode(`amazonconnect/agent-real-time-stats`);
    }

    getPhoneNumberCustomFunctionMappings() {
        return this.api.getArrayNode(`PhoneNumberCustomFunctionMapping`, null, () => new PhoneNumberCustomFunctionMapping());
    }

    savePhoneNumberCustomFunctionMapping(item: PhoneNumberCustomFunctionMapping) {
        return this.api.postNode(`PhoneNumberCustomFunctionMapping`, item);
    }

    deletePhoneNumberCustomFunctionMapping(id: string) {
        return this.api.deleteNode(`PhoneNumberCustomFunctionMapping/${id}`);
    }

    undeletePhoneNumberCustomFunctionMapping(id: string) {
        return this.api.unDeleteNode(`PhoneNumberCustomFunctionMapping/${id}`);
    }

    getSLA() {
        return this.api.getArrayNode(`amazonconnect/sla`);
    }

    getAuthoSLA() {
        return this.api.getArrayNode(`amazonconnect/autho-sla`);
    }

    getAuthoSLALast30() {
        return this.api.getArrayNode(`amazonconnect/autho-sla-last-30`);
    }

    getAuthoCallsAnsweredByAuthoAgentsForWeek() {
        const dt = UtilitiesService.dayBegin(new Date());

        dt.setDate(dt.getDate() - 8);

        return this.api.getArrayNode('AuthoCallsAnsweredByAuthoAgents', { date_gte: dt, orderby: 'date' }, () => new AuthoCallsAnsweredByAuthoAgents());
    }

    getAuthoCallsAnsweredByAuthoAgentsForMonth() {
        const dt = UtilitiesService.dayBegin(new Date());
        dt.setDate(1);

        return this.api.getArrayNode('AuthoCallsAnsweredByAuthoAgents', { date_gte: dt, orderby: 'date' }, () => new AuthoCallsAnsweredByAuthoAgents());
    }

    async autoAttachPhoneCall(policyId: string) {

        const dt = new Date();
        dt.setMinutes(dt.getMinutes() - 5);

        const amazonContacts = await this.api.getArrayDotNet(
            "AmazonContact", {
            createdById_eq: "{{currentUser}}",
            createdDate_gt: dt.toISOString(),
            orderBy: "CreatedDate descending"
        }, () => new AmazonContact());

        if (amazonContacts.length > 0 && amazonContacts[0].policyId == null) {
            const amazonContact = amazonContacts[0];
            amazonContact.policyId = policyId;
            await this.api.patchDotNet("AmazonContact/" + amazonContact.id, { policyId });
            return amazonContact;
        }

        return null;
    }

    getTodaysContacts() {
        const todayStart = UtilitiesService.dayBegin(new Date());
        return this.api.getArrayNode(`AmazonConnectCTRSummary`, { connectedToSystem_gte: todayStart }, () => new AmazonConnectCTRSummary());
    }

    saveAmazonContact(contact: AmazonContact) {
        return this.api.postIdDotNet('AmazonContact', contact);
    }

    getAmazonContactSummaries(startDate: Date, endDate: Date, take: number) {
        return this.api.getArrayDotNet('AmazonContactSummary', {

            createdById_eq: "{{currentUser}}",
            createdDate_gte: startDate,
            createdDate_lte: endDate,
            take,
            orderby: 'CreatedDate desc',
        });
    }

    undoAttachPhoneCall(amazonContact: AmazonContact) {
        amazonContact.policyId = null;
        return this.api.patchDotNet('AmazonContact/' + amazonContact.id, { policyId: null });
    }

    getUserSurveyScore(startDate: Date, endDate: Date) {
        return this.api.getSingleDotNet(`AmazonConnect/user-survey-score`, { startDate, endDate });
    }

    getUserSurveyScoreDetails(startDate: Date, endDate: Date) {
        return this.api.getArrayDotNet(`AmazonConnect/user-survey-score-details`, { startDate, endDate });
    }

    getAgentAfterCallSurveyResultsDetail(startDate: Date, endDate: Date, agentUserName: string) {
        return this.api.getArrayDotNet(`AmazonConnect/agent-after-call-survey-results-detail`, { startDate, endDate, agentUserName }, () => new AmazonConnectCTR());
    }

    getChatConversation(s3Key: string) {
        return this.api.getSingleDotNet(`AmazonConnect/chat-conversation`, { id: s3Key });
    }

    // getConnectCppUrl() {
    //     return this.api.getSingle(`singleton/amazon-connect-cpp-url`);
    // }
    getConnectConfig() {
        return this.api.getSingleDotNet(`singleton/amazon-connect-config`, null, () => new ConnectConfig());
    }

    getChatConfig(): Promise<ChatConfig> {
        return this.api.getSingleDotNet(`singleton/amazon-connect-chat-config`, null, () => new ChatConfig());
    }

    getConnectUsers() {
        return this.api.getArrayDotNet(`AmazonConnect/connect-users`);
    }

    getAgentAfterCallSurveyResults(startDate: Date, endDate: Date) {
        return this.api.getArrayDotNet('AmazonConnect/agent-after-call-survey-results', { startDate, endDate }, () => new AfterCallSurveyResult());
    }

    addConnectUser(user: NewEmployeeArgs) {
        //add-connect-user
        return this.api.postSingleNode(`amazonconnect/add-connect-user`, user);
    }



    searchContractTraceRecords(args: ContactTraceSearchArgs): Promise<AmazonConnectCTR[]> {
        if (args.connectedToSystem_lte) {
            args.connectedToSystem_lte = UtilitiesService.dayEnd(args.connectedToSystem_lte);
        }
        if (!args.customerEndpointAddress_like) {
            delete args.customerEndpointAddress_like;
        }
        if (!args.connectedToSystem_gte) {
            delete args.connectedToSystem_gte;
        }
        if (!args.connectedToSystem_lte) {
            delete args.connectedToSystem_lte;
        }
        if (args.customerEndpointAddress_like) {
            args.customerEndpointAddress_like = UtilitiesService.stripPhoneFormatting(args.customerEndpointAddress_like);
        }
        if (!args.channel_eq) {
            delete args.channel_eq;
        }
        if (!args.initiationMethod_eq) {
            delete args.initiationMethod_eq;
        }
        if (!args.agentUserName_like) {
            delete args.agentUserName_like;
        }
        if (!args.queueName_eq) {
            delete args.queueName_eq;
        }

        return this.api.getArrayDotNet(`AmazonConnectCTR`, args, () => new AmazonConnectCTR());
    }

    getQueues() {
        return this.api.getArrayDotNet('AmazonConnect/list-queues');
    }

    getMetric(queueId: string, metric: string) {
        return this.api.getSingleDotNet(`AmazonConnect/get-real-time-metric/${queueId}/${metric}`,null, null, true);
    }

    getContactFlows() {
        return this.api.getArrayDotNet(`AmazonConnect/get-contact-flows`);
    }

    getSecurityProfiles() {
        return this.api.getArrayNode(`amazonconnect/security-profiles`);
    }

    getRoutingProfiles() {
        return this.api.getArrayNode(`amazonconnect/routing-profiles`);
    }

    getConnectUser(userId: string) {
        return this.api.getSingleNode(`amazonconnect/get-connect-user`, { userId });
    }

    updateUserRoutingProfile(userId: string, routingProfileId: string) {
        return this.api.patchNode(`amazonconnect/update-user-routing-profile`, { userId, routingProfileId });
    }
}