import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AuthorizationRule {
    @keyColumn() id: string;
    @column() name: string;
    @column() action: string;
    @column() description: string;
    @column() rule: string;
    @column() active: boolean = false;
    @column() successMessageExpression: string;
    @column() authorizationAmountExpression: string;
    @column() sort: number = 0;
    @column() willAutoAuthorize: boolean = false;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() appliesToItemId: string;
    @column() conditions: string;
}