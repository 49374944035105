
export class FieldComparison {
    fieldName: string;
    originalValue: any;
    currentValue: any;

    get wasChanged() {
        return this.originalValue !== this.currentValue;
    }
}

export class ObjectChange {
    id: string;
    objectType: string;
    current: any;
    original: any;
    objectId: string;
    ticks: number;
    private fieldComparisonsInternal: FieldComparison[];

    get changeDate() {
        if (this.current) {
            return new Date(this.current.LastModifiedDate);
        }
    }

    get changedById() {
        if (this.current) {
            return this.current.LastModifiedById;
        }
    }

    get fieldComparisons() {
        if (!this.fieldComparisonsInternal) {
            this.fieldComparisonsInternal = [];
            for (const key of Object.keys(this.current)) {
                const fieldComparison = new FieldComparison();
                fieldComparison.fieldName = key;
                fieldComparison.originalValue = this.original[key];
                fieldComparison.currentValue = this.current[key];
                this.fieldComparisonsInternal.push(fieldComparison);
            }
        }

        return this.fieldComparisonsInternal;

    }
}
