import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '@cogent/client/shared/services/api/contractors.service';
import { ContractorKPIModel } from '@cogent/client/shared/models/contractor/contractor-kpi.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { Router } from '@angular/router';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Entity, PromotionCode } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-contractors-home',
    templateUrl: './contractors-home.component.html',
    styleUrls: ['./contractors-home.component.css']
})
export class ContractorsHomeComponent implements OnInit {
    contractorTrades: any[];
    selectedTradeId: string;
    startDate: Date = UtilitiesService.monthStart(new Date());
    endDate: Date = UtilitiesService.monthEnd(new Date());
    kpis: ContractorKPIModel[];
    entity: Entity;
    callsToday: number;
    callsNeedingUpdate: number;
    unacceptedCount = 0;
    searchFor: string;
    upcommingJobs: WorkOrderSummaryClient[];
    loaded = false;
    promotionCodes: PromotionCode[];

    constructor(
        private contractorsService: ContractorsService,
        private entityApi: EntityApiService,
        public missionService: MissionService,
        private router: Router,
    ) { }

    getEntityThumbnail(entityId) {
        return this.entityApi.getThumbnailUri(entityId);
    }

    ngOnInit() {
        this.entityApi.getLoggedInUser().then(user => {
            if (user) {
                this.entity = user;
                this.contractorsService.getContractorTrades(user.id).then(contractorTrades => {
                    this.contractorTrades = contractorTrades;
                    if (this.contractorTrades.length > 0) {
                        this.selectedTradeId = this.contractorTrades[0].tradeId;
                    }
                    this.refreshAll();
                });

                this.contractorsService.getCallsToday(user.id).then(callsToday => this.callsToday = callsToday);
                this.contractorsService.getCallsNeedingUpdate(user.id).then(callsNeedingUpdate => this.callsNeedingUpdate = callsNeedingUpdate);
                this.contractorsService.getUnacceptedJobCount(user.id).then(count => this.unacceptedCount = count);

                // this.entityApi.getFullEntity(user.id).then(entity => {
                //     this.entity = entity;
                // });

                this.contractorsService.getUpcommingJobs(user.id).then(upcomming => this.upcommingJobs = upcomming);
                window.scrollTo(0, 0);

                setTimeout(() => this.loaded = true, 500);
                this.entityApi.getPromotionCodeForEntity(user.id).then(promotionCodes => this.promotionCodes = promotionCodes);
            }
        });
    }

    get isMaintContractor() {
        return this.missionService.stateValues['isMaintContractor'];
    }

    refreshAll() {
        this.entityApi.getLoggedInUser().then(user => {
            const startDate = new Date(this.startDate);
            startDate.setFullYear(startDate.getFullYear() - 1);
            this.contractorsService.getContractorKPIs(user.id, this.selectedTradeId, startDate, this.endDate).then(kpis => {
                this.kpis = kpis;
            });
        });
    }



    doSearch() {
        this.router.navigateByUrl(`/search/${this.searchFor}`);
    }

    searchKeyHandler($event) {
        if ($event.key === 'Enter') {
            this.doSearch();
        }
    }
}
