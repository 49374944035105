import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesItemCoverageWorkOrderItemType {
    @keyColumn() id: string;
    @column() salesItemCoverageId: string;
    @column() workOrderItemTypeId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
}