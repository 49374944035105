import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

export class ContractLimits {
    @keyColumn() id: string;
    @column() name: string;
    @column() optional : boolean;
    @column() isService: boolean;
    @column() serviceFee: number;
    @column() category: string;
    @column() limitPerOccurrence?: number;
    @column() limitPerPlanPeriod?: number;
    @column() coveredQuantity: number;
    @column() coveredQuantityQuestion: string;
}