
import { AuthorizationInputArgs, AuthorizationReviewTrigger, CostLine, WorkOrderLineAuthorizationRepairItem } from '@upkeeplabs/models/cogent';
import { SubFunctionStack } from '../function-runner.logic';
import { CustomFormQuestions, ExecutorInputParams, ExecutorOutputParams, IStepExecutor, typeMap } from '../function-runner.model';




export class StartFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = true;
        return output;
    }
    type = typeMap.start;
}

export class EqualsFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = inputParams.inputs[0] === inputParams.inputs[1] ? 'True' : 'False';
        return output;
    }
    type = typeMap.equals;
}

export class IsNullFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = inputParams.inputs[0] == null || inputParams.inputs[0] == undefined || inputParams.inputs[0] === '' ? 'True' : 'False';
        return output;
    }
    type = typeMap.isNull;
}

export class GetDateFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = new Date();
        return output;
    }
    type = typeMap.getDate;
}

export class DateAddFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        const inputDate: Date = inputParams.inputs[0];
        const interval: string = inputParams.inputs[1];
        const number: number = inputParams.inputs[2];


        const newDate: Date = new Date(inputDate);

        switch (interval) {
            case 'millisecond':
                newDate.setMilliseconds(newDate.getMilliseconds() + number);
                break;
            case 'second':
                newDate.setSeconds(newDate.getSeconds() + number);
                break;
            case 'minute':
                newDate.setMinutes(newDate.getMinutes() + number);
                break;
            case 'hour':
                newDate.setHours(newDate.getHours() + number);
                break;
            case 'day':
                newDate.setDate(newDate.getDate() + number);
                break;
            case 'month':
                newDate.setMonth(newDate.getMonth() + number);
                break;
            case 'year':
                newDate.setFullYear(newDate.getFullYear() + number);
                break;
        }

        output.result = newDate;
        return output;
    }
    type = typeMap.dateAdd;
}

export class GreaterThanFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const item1 = inputParams.inputs[0];
        const item2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = item1 > item2 ? 'True' : 'False';

        return output;
    }
    type = typeMap.greaterThan;
}

export class GreaterThanOrEqualFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const item1 = inputParams.inputs[0];
        const item2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = item1 >= item2 ? 'True' : 'False';

        return output;
    }
    type = typeMap.greaterThanEqual;
}

export class OrFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const item1 = inputParams.inputs[0];
        const item2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = item1 || item2 ? 'True' : 'False';

        return output;
    }
    type = typeMap.or;
}
export class IfElseFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const item1 = inputParams.inputs[0];

        const output = new ExecutorOutputParams();
        output.result = item1 ? 'True' : 'False';

        return output;
    }
    type = typeMap.ifElse;
}

export class ObjectInScopeFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = inputParams.objectInScope;
        return output;
    }
    type = typeMap.getObjectInScope;
}

// export class ObjectValueFunction implements IStepExecutor {
//     async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
//         const output = new ExecutorOutputParams();
//         output.result = inputParams.objectInScope[inputParams.inputs[0]];

//         return output;
//     }
//     type = typeMap.getObjectInScope;
// }

export class LoopFunction implements IStepExecutor {
    loopIndex = {};
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        if (!this.loopIndex[inputParams.currentCell.id]) {
            this.loopIndex[inputParams.currentCell.id] = 0;
        }
        const output = new ExecutorOutputParams();
        let arrayToIterate = inputParams.inputs[0];
        if (!arrayToIterate) {
            arrayToIterate = inputParams.currentRunner.objectInScope;
        }
        if (this.loopIndex[inputParams.currentCell.id] < arrayToIterate.length) {
            output.result = arrayToIterate[this.loopIndex[inputParams.currentCell.id]];
            output.outputPort = inputParams.currentCell.ports.items.find(i => i.group === 'transmissionOut' && i.attrs?.label?.text === 'Loop');
            this.loopIndex[inputParams.currentCell.id]++;
        } else {
            if (!inputParams.currentCell.noResetLoop) {
                this.loopIndex[inputParams.currentCell.id] = 0;
            }
            output.outputPort = inputParams.currentCell.ports.items.find(i => i.group === 'transmissionOut' && i.attrs?.label?.text === 'Completion');
        }

        return output;
    }

    type = typeMap.loop;
}

export class FirstOrDefaultFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        const output = new ExecutorOutputParams();
        output.result = inputParams.inputs[0][0];

        return output;
    }
    type = typeMap.first;
}
export function getSharedExecutors() {
    return [
        new StartFunction(),
        new EqualsFunction(),
        new IsNullFunction(),
        new ObjectInScopeFunction(),
        //new ObjectValueFunction(),
        new LoopFunction(),
        new FirstOrDefaultFunction(),
        // new WaitFunction(),
        new SwitchFunction(),
        new EndProcessFunction(),
        new HardStopProcessFunction(),
        new BeginFormFunction(),
        new GetObjectValueFunction(),
        new CreateObjectFunction(),
        new EndFormFunction(),
        new GreaterThanFunction(),
        new GreaterThanOrEqualFunction(),
        new OrFunction(),
        new IfElseFunction(),
        new ShowActivityFunction(),
        new HideActivityFunction(),
        new ParseFunction(),
        new StringifyFunction(),
        new ConsoleLogFunction(),
        new ArrayAccessorFunction(),
        new ArrayConcatFunction(),
        new ArrayLengthFunction(),
        new ArrayMapFunction(),
        new ArrayMaxFunction(),
        new ArrayMinFunction(),
        new ArrayAvgFunction(),
        new ArrayIndexOfFunction(),
        new ArrayJoinFunction(),
        new ArrayPopFunction(),
        new ArrayPushFunction(),
        new ToStringFunction(),
        new AddFunction(),
        new SubtractFunction(),
        new MultiplyFunction(),
        new DivideFunction(),
        new GetDateFunction(),
        new StringConcatFunction(),
        new DateAddFunction(),
        new GetEnvVariableFunction(),
        new ExecutionContextFunction(),
        new SaveEnvVariableFunction(),
        new SetPropertyValueFunction(),
        new ParseIntFunction(),
        new ParseFloatFunction(),
        new SubFunctionFunction(),
        new SubFunctionEndFunction(),
        new GetSubFunctionInputsFunction(),
        new EvaluateFunction(),
        new GetAllVariablesFunction(),
        new NewArrayFunction(),
        new TernaryOperatorFunction(),
        new DatesEqualFunction(),
        new SetObjectInScopeFunction(),
        new AddAuthoWarningFunction(),
        new AddLaborFunction(),
        new GetDatePartFunction(),
    ];
}


export class TernaryOperatorFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let exp = inputParams.inputs[0];
        const trueValue = inputParams.inputs[1];
        const falseValue = inputParams.inputs[2];

        if (exp?.toLowerCase && exp?.toLowerCase() === 'true') {
            exp = true;
        }

        if (exp?.toLowerCase && exp?.toLowerCase() === 'false') {
            exp = false;
        }

        const output = new ExecutorOutputParams();
        output.result = exp ? trueValue : falseValue;

        return output;
    }
    type = typeMap.ternaryOperator;
}

export class AddLaborFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let destination: AuthorizationInputArgs = inputParams.inputs[1];

        if (!destination) {
            destination = inputParams.objectInScope;
        }
        if (destination) {
            if (!destination.costLines) {
                destination.costLines = [];
            }
            let laborLine = destination.costLines.find(i => i.repairItemId === 'labor');
            if (!laborLine) {
                const missionService: any = inputParams.dependencies.missionService;
                laborLine = new CostLine();
                laborLine.amount = destination.laborRate;
                laborLine.defaultItem = true;
                laborLine.qty = 0;
                laborLine.description = 'Labor';
                laborLine.id = missionService.newid();
                laborLine.repairItemId = 'labor';
                laborLine.automaticallyAdded = true;
                laborLine.allowQuantityEntry = true;
                laborLine.addedByStepId = 'initial';
                laborLine.calcLaborAsHourlyRate = true;
                destination.costLines.push(laborLine);
                laborLine.companyProvidesPart = false;
                laborLine.authorizationRepairItemSelector = new WorkOrderLineAuthorizationRepairItem();
                laborLine.authorizationRepairItemSelector.companyNeverProvides = true;
                laborLine.authorizationRepairItemSelector.id = 'labor';
                laborLine.authorizationRepairItemSelector.priceRangeMax = laborLine.amount;
                laborLine.authorizationRepairItemSelector.name = 'Labor';
                laborLine.authorizationRepairItemSelector.companyNeverProvides = true;
                laborLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'Labor';
            }
            if (laborLine.calcLaborAsHourlyRate) {
                laborLine.qty += parseFloat(inputParams.inputs[0]);
            } else {
                laborLine.amount += parseFloat(inputParams.inputs[0]);
            }
        }

        const output = new ExecutorOutputParams();
        output.result = true;

        return output;
    }
    type = typeMap.addLabor;

}

export class AddAuthoWarningFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const trigger = new AuthorizationReviewTrigger();
        trigger.stepId = inputParams.currentCell.id;
        trigger.description = inputParams.inputs[1];
        trigger.type = inputParams.inputs[0];
        trigger.value = inputParams.inputs[2];
        trigger.showWarningExternally = inputParams.inputs[3];
        trigger.overwritesPreviousValues = inputParams.inputs[4];

        let destination = inputParams.inputs[5];
        if (!destination) {

            if (inputParams.objectInScope && !inputParams.objectInScope.authorizationWarnings) {
                inputParams.objectInScope.authorizationWarnings = [];
            }
            destination = inputParams.objectInScope.authorizationWarnings;
        }
        const matches = destination.filter(i => i.stepId === inputParams.currentCell.id);
        for (const match of matches) {
            destination.splice(destination.indexOf(match), 1);
        }
        if (trigger.overwritesPreviousValues) {
            const typeMatches = destination.filter(i => i.type === trigger.type);
            for (const match of typeMatches) {
                destination.splice(destination.indexOf(match), 1);
            }
        }
        destination.push(trigger);
        const output = new ExecutorOutputParams();
        output.result = trigger;

        return output;
    }
    type = typeMap.addAuthoWarning;
}

export class StringConcatFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const string1: string = inputParams.inputs[0];
        const string2: string = inputParams.inputs[1];
        const result = new ExecutorOutputParams();

        result.result = (string1 ?? '') + (string2 ?? '');

        return result;
    }
    type = typeMap.stringConcat;
}

export class ConsoleLogFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const value = inputParams.inputs[0];
        const output = new ExecutorOutputParams();
        // DO NOT REMOVE
        console.log(value);

        return output;
    }
    type = typeMap.log;
}

export class GetSubFunctionInputsFunction implements IStepExecutor {
    type = typeMap.getSubFunctionInputs;

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();


        output.result = inputParams.currentRunner.subFunctionStackTrace[inputParams.currentRunner.subFunctionStackTrace.length - 1].inputArgs;

        return output;
    }
}

export class ExecutionContextFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = inputParams.currentRunner.envVariables.executionContext;

        return output;
    }
    type = typeMap.executionContext;
}

export class SubFunctionFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        // inputParams.currentRunner.functionStackTrace.push(inputParams.currentRunner.lastExecutedStep.id);
        const output = new ExecutorOutputParams();
        output.result = true;

        const transmissionOutPorts = inputParams.currentCell.ports?.items?.filter(i => i.group === 'transmissionOut');
        const transmissionOut = transmissionOutPorts.find(i => i.attrs?.label?.text === 'Sub Function');
        if (transmissionOut) {
            const connectionLine = inputParams.currentRunner.cells.find(i => i.type === 'standard.Link' && i.source?.port === transmissionOut.id);
            if (connectionLine) {
                output.nextFunction = inputParams.currentRunner.cells.find(i => i.id === connectionLine.target.id);
            }
        }

        const completionOut = transmissionOutPorts.find(i => i.attrs?.label?.text === 'Resume');
        if (completionOut) {
            const connectionLine = inputParams.currentRunner.cells.find(i => i.type === 'standard.Link' && i.source?.port === completionOut.id);
            if (connectionLine) {
                const functionStack = new SubFunctionStack();
                functionStack.firstSubFunctionBlockId = output.nextFunction?.id;
                functionStack.inputArgs = inputParams.inputs[0];
                functionStack.callingBlockId = inputParams.currentCell.id;
                functionStack.resumeBlockId = connectionLine.target.id;
                if (!inputParams.currentRunner.subFunctionStackTrace) {
                    inputParams.currentRunner.subFunctionStackTrace = [];
                }

                inputParams.currentRunner.subFunctionStackTrace.push(functionStack);
            }
        }
        const args = inputParams.inputs[0];


        return output;
    }
    type = typeMap.subFunction;
}

export class SubFunctionEndFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const lastStack = inputParams.currentRunner.subFunctionStackTrace[inputParams.currentRunner.subFunctionStackTrace.length - 1];
        const id = lastStack.resumeBlockId;
        const output = new ExecutorOutputParams();
        output.nextFunction = inputParams.currentRunner.cells.find(i => i.id === id);

        inputParams.currentRunner.subFunctionStackTrace.pop();
        return output;
    }
    type = typeMap.subFunctionEnd;
}

export class GetDatePartFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let date: Date = inputParams.inputs[0] ? new Date(inputParams.inputs[0]) : new Date();

        const output = new ExecutorOutputParams();

        switch (inputParams.inputs[1]) {
            case 'millisecond':
                output.result = date.getMilliseconds();
                break;
            case 'second':
                output.result = date.getSeconds();
                break;
            case 'minute':
                output.result = date.getMinutes();
                break;
            case 'hour':
                output.result = date.getHours();
                break;
            case 'day':
                output.result = date.getDate();
                break;
            case 'month':
                output.result = date.getMonth();
                break;
            case 'year':
                output.result = date.getFullYear();
                break;
            case 'dayofweek':
                output.result = date.getDay();
                break;

        }

        return output;
    }
    type = typeMap.datePart;
}

export class SaveEnvVariableFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const key = inputParams.inputs[0];
        const value = inputParams.inputs[1];
        inputParams.currentRunner.envVariables[key] = value;
        const output = new ExecutorOutputParams();
        output.result = value;
        return output;
    }
    type = typeMap.saveEnvVariable;
}

export class SetPropertyValueFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let object = inputParams.inputs[0];
        const key = inputParams.inputs[1];
        const value = inputParams.inputs[2];
        if (!object) {
            object = inputParams.objectInScope;
        }

        object[key] = value;

        const result = new ExecutorOutputParams();
        result.result = object;
        return result;
    }
    type = typeMap.setPropertyValue;
}

export class EvaluateFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        let expression: string = inputParams.inputs[0];
        const inputArg = inputParams.inputs[1];

        // if(!expression.startsWith('return ')) {
        //     expression = 'return ' + expression;
        // }

        const f = new Function('value', expression);

        const result = f(inputArg);

        const output = new ExecutorOutputParams();
        output.result = result;

        return output;
    }
    type = typeMap.evaluate;
}

export class DatesEqualFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let date1: Date = inputParams.inputs[0];
        let date2: Date = inputParams.inputs[1];

        const output = new ExecutorOutputParams();

        if (!date1 || !date2) {
            output.result = 'False';
        } else {
            if (!date1.getDate) {
                date1 = new Date(date1);
            }
            if (!date2.getDate) {
                date2 = new Date(date2);
            }
            output.result = date1.getFullYear() == date2.getFullYear()
                && date1.getMonth() == date2.getMonth()
                && date1.getDate() == date2.getDate() ? 'True' : 'False';
        }

        return output;
    }
    type = typeMap.datesEqual;
}

export class ParseFloatFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let value = inputParams.inputs[0];
        const result = new ExecutorOutputParams();
        result.result = parseFloat(value);

        return result;
    }
    type = typeMap.parseFloat;
}

export class ParseIntFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let value = inputParams.inputs[0];
        const result = new ExecutorOutputParams();
        result.result = parseInt(value);

        return result;
    }
    type = typeMap.parseInt;
}

export class ParseFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const json = inputParams.inputs[0];

        const output = new ExecutorOutputParams();
        output.result = JSON.parse(json);

        return output;
    }
    type = typeMap.parse;
}

export class StringifyFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const obj = inputParams.inputs[0];
        const output = new ExecutorOutputParams();
        output.result = JSON.stringify(obj);

        return output;
    }
    type = typeMap.stringify;
}

export class ArrayAccessorFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array = inputParams.inputs[0];
        const index = inputParams.inputs[1];
        const output = new ExecutorOutputParams();

        output.result = array[index];

        return output;
    }
    type = typeMap.arrayAccessor;
}

export class ArrayJoinFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array = inputParams.inputs[0];
        const separator = inputParams.inputs[1];
        const output = new ExecutorOutputParams();

        output.result = array.join(separator);

        return output;
    }
    type = typeMap.join;
}

export class ArrayPopFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array = inputParams.inputs[0];
        const output = new ExecutorOutputParams();

        output.result = array.pop()

        return output;
    }
    type = typeMap.pop;
}

export class ArrayLengthFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array = inputParams.inputs[0];

        const output = new ExecutorOutputParams();
        output.result = array.length;

        return output;
    }
    type = typeMap.length;
}



export class ArrayConcatFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];
        const output = new ExecutorOutputParams();
        const array2 = inputParams.inputs[1];
        if(array1 && !array2) {
            output.result = array1;
        } else if(!array1 && array2) {
            output.result = array2;
        } else if(array1 && array2) {
            output.result = array1.concat(array2);
        }


        return output;
    }
    type = typeMap.contact;
}


export class ArrayPushFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];
        const newValue = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = array1.push(newValue);

        return output;
    }
    type = typeMap.push;
}

export class ToStringFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const obj = inputParams.inputs[0];
        const output = new ExecutorOutputParams();
        output.result = obj?.toString();

        return output;
    }

    type = typeMap.toString;
}

export class ArrayMapFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];
        const field = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = array1.map(i => i[field]);

        return output;
    }
    type = typeMap.map;
}

export class ArrayMaxFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];

        let maxValue = array1[0];
        for (const value of array1) {
            if (value > maxValue) {
                maxValue = value;
            }
        }

        const output = new ExecutorOutputParams();
        output.result = maxValue;

        return output;
    }
    type = typeMap.max;
}

export class ArrayMinFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];

        let maxValue = array1[0];
        for (const value of array1) {
            if (value < maxValue) {
                maxValue = value;
            }
        }

        const output = new ExecutorOutputParams();
        output.result = maxValue;

        return output;
    }
    type = typeMap.min;
}

export class ArrayAvgFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];

        let sum = array1.reduce((a, b) => a + b);

        const output = new ExecutorOutputParams();
        if (array1.length) {
            output.result = sum / array1.length;
        }

        return output;
    }
    type = typeMap.avg;
}

export class ArrayIndexOfFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const array1 = inputParams.inputs[0];
        const value = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = array1.indexOf(value);
        return output;
    }
    type = typeMap.indexOf;
}

export class ShowActivityFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = true;
        inputParams.currentRunner.activityMessage = inputParams.inputs[0];
        inputParams.currentRunner.showActivityIndicator = true;

        if (inputParams.currentRunner.changeWorkingMessage) {
            inputParams.currentRunner.changeWorkingMessage(inputParams.currentRunner.activityMessage ?? 'Running');
        }

        return output;
    }
    type = typeMap.showActivityIndicator;
}

export class SetObjectInScopeFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        const value = inputParams.inputs[0];
        inputParams.currentRunner.objectInScope = value;
        output.result = value;

        return output;
    }
    type = typeMap.setObjectInScope;
}

export class HideActivityFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = true;
        inputParams.currentRunner.showActivityIndicator = false;
        if (inputParams.currentRunner.changeWorkingMessage) {
            inputParams.currentRunner.changeWorkingMessage(null);
        }

        return output;
    }
    type = typeMap.hideActivityIndicator;
}

export class GetObjectValueFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        let objectToCheck = inputParams.inputs[0];
        if (!objectToCheck) {
            objectToCheck = inputParams.objectInScope;
        }
        if ((inputParams.inputs[1] as string)?.endsWith("()")) {
            output.result = objectToCheck[inputParams.inputs[1].replace("()", "")]();
        } else {
            output.result = objectToCheck[inputParams.inputs[1]];
        }

        return output;
    }
    type = typeMap.getObjectValue;
}

export class CreateObjectFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        if (inputParams.currentRunner.outputValues[inputParams.currentCell.id]) {

            const output = new ExecutorOutputParams();
            output.result = inputParams.currentRunner.outputValues[inputParams.currentCell.id]
            return output;
        }
        const result = {};

        const inputPorts = inputParams.currentCell.ports.items.filter(i => i.group === 'in');
        let index = 0;
        for (const port of inputPorts) {

            const key = port.attrs?.label?.text;
            const value = inputParams.inputs[index];

            result[key] = value;
            index++;
        }
        const output = new ExecutorOutputParams();
        output.result = result;
        inputParams.currentRunner.outputValues[inputParams.currentCell.id] = result;

        return output;
    }
    type = typeMap.createObject;
}

export class SwitchFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        const output = new ExecutorOutputParams();
        output.result = inputParams.inputs[0];

        return output;
    }
    type = typeMap.switch;
}

export class EndProcessFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const callbackResult = inputParams.inputs[0];


        console.log({inputParams})
        inputParams.currentRunner.callback(callbackResult, true);

        const output = new ExecutorOutputParams();
        output.result = callbackResult;
        if (inputParams.currentRunner.dependencies?.currentHttpResponse) {
            const response = inputParams.currentRunner.dependencies?.currentHttpResponse;
            response.writeHead(200, { 'Content-Type': 'application/xml' });
            response.write(
                `
        <Response>
          <Hangup/>
        </Response>
        `
            );

            response.end();
        }

        return output;
    }
    type = typeMap.endProcess;
}

export class AddFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const value1 = inputParams.inputs[0];
        const value2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = value1 + value2;

        return output;
    }
    type = typeMap.add;
}
export class SubtractFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const value1 = inputParams.inputs[0];
        const value2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = value1 - value2;

        return output;
    }
    type = typeMap.subtract;
}
export class MultiplyFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const value1 = inputParams.inputs[0];
        const value2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = value1 * value2;

        return output;
    }
    type = typeMap.multiply;
}
export class DivideFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const value1 = inputParams.inputs[0];
        const value2 = inputParams.inputs[1];

        const output = new ExecutorOutputParams();
        output.result = value2 === 0 ? 0 : value1 / value2;

        return output;
    }
    type = typeMap.divide;
}

export class HardStopProcessFunction implements IStepExecutor {
    // TODO: figure out how to hard stop this
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const callbackResult = inputParams.inputs[0];
        inputParams.currentRunner.callback(callbackResult, true);

        const output = new ExecutorOutputParams();
        output.result = callbackResult;

        return output;
    }
    type = typeMap.stopProcess;
}



export class GetEnvVariableFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        const key = inputParams.inputs[0];
        output.result = inputParams.currentRunner.envVariables[key];

        return output;
    }
    type = typeMap.getEnvVariable;
}

export class EndFormFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        output.result = inputParams.currentRunner.lastCustomFormResult ? 'Complete' : 'Incomplete';
        return output;
    }
    type = typeMap.endForm;
}

export class GetAllVariablesFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        const result = [];
        for (const outputValueKey in inputParams.currentRunner.outputValues) {
            const cell = inputParams.currentRunner.cells.find(i => i.id === outputValueKey);
            if (cell) {
                const newItem = {
                    id: cell.id,
                    label: cell.attrs?.label?.text,
                    value: inputParams.currentRunner.outputValues[outputValueKey],
                    type: cell.type,
                };
                const labelPort = cell.custom?.inputs?.find(i => i.label === 'Label');
                if (labelPort && labelPort.value) {
                    newItem.label = labelPort.value;
                }
                result.push(newItem);
            }
        }
        output.result = result;
        return output;
    }
    type = typeMap.getAllVariables;
}

export class NewArrayFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();

        output.result = [];
        for (let i = 0; i < 10; i++) {
            if (inputParams.inputs[i]) {
                output.result.push(inputParams.inputs[i]);
            }
        }

        return output;
    }
    type = typeMap.newArray;
}

export class BeginFormFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        return new Promise((resolve, reject) => {
            const questions = new CustomFormQuestions();

            questions.questions = [];
            questions.executorInputParams = inputParams;
            const cells = inputParams.currentRunner.cells;
            let currentCell = inputParams.currentCell;

            while (currentCell) {
                const transmissionOutPorts = currentCell.ports?.items?.filter(i => i.group === 'transmissionOut');
                if (transmissionOutPorts.length > 0) {
                    const outPort = transmissionOutPorts[0];
                    const connectionLine = cells.find(i => i.type === 'standard.Link' && i.source?.port === outPort.id);

                    currentCell = cells.find(i => i.id === connectionLine.target.id);
                    if (currentCell && currentCell.type !== typeMap.endForm) {
                        questions.questions.push(currentCell);
                    } else {
                        if (currentCell) {
                            questions.endFormFunction = currentCell;
                        }
                        currentCell = null;
                    }
                } else {
                    currentCell = null;
                }
            }

            inputParams.currentRunner.showCustomForm(questions);
        });

    }
    type = typeMap.beginForm;
}