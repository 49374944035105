import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { ContractorDocumentStatus, ContractorDocumentType, ContractorTradeAreaTarget, ContractorTradeAreaTargetSummary } from '@upkeeplabs/models/cogent';
import { ContractorKPI } from '@cogent/shared/models/contractor-relations/contractor-k-p-i.model';
import { ContractorHistoryItem } from '@cogent/shared/models/contractor-relations/contractor-history-item.model';
import { ClaimCostSummary, ContractorReportItem } from '@cogent/shared/models/contractor-relations/contractor-report-item.model';
import { BecomeAContractorArgs, Brand, ContractorInvoicePaymentSummary, ContractorTrade, ContractorTradeRegionCommitmentSummary, EntityRegionPostalCode, FirstCallsByTradeAndServiceProTerritory, PolicyCountsInServiceProTerritory, ServiceProTerritorySummary, Trade, WorkOrderSurveySummary } from '@upkeeplabs/models/cogent';
import { ContractorAccSummary } from '@cogent/shared/models/contractor-relations/contractor-acc-summary.model';
import { ServiceProReportCard } from "@upkeeplabs/models/cogent";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ContractorInvoiceSummaryClient } from "@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model";

@Injectable({ providedIn: 'root' })
export class ContractorApiService {

    public getContractorAccSummaries(): Promise<ContractorAccSummary[]> {
        return this.api.getArrayDotNet("ContractorAccSummary", {
            AccMonth_gt: '12/31/2019',
        }, () => new ContractorAccSummary());
    }




    constructor(private api: ApiService) { }

    async getContractorTerritories() {
        return this.api.getArrayNode(`contractor/territories`, null, () => new ServiceProTerritorySummary());
    }



    getContractorDocumentTypes(): Promise<ContractorDocumentType[]> {
        return this.api.getArrayNode(`ContractorDocumentType`, { orderby: 'name' }, () => new ContractorDocumentType());
    }

    public getContractorTradeRegionCommitmentSummaries(startDate: Date, endDate: Date, areaId: string, tradeId: string): Promise<ContractorTradeRegionCommitmentSummary[]> {
        return this.api.getArrayNode("contractor-trade-region-commitment/get-summaries-for-area/" + areaId,
            { tradeId: tradeId, startDate: startDate, endDate: endDate },
            () => new ContractorTradeRegionCommitmentSummary());
    }

    getBrands() {
        return this.api.getArrayNode(`contractor/brands`, null, () => new Brand());
    }

    apply(args: BecomeAContractorArgs) {
        return this.api.postNode(`contractor/apply`, args);
    }

    getTrades() {
        return this.api.getArrayNode(`contractor/trades`, null, () => new Trade());
    }

    public getFirstCallsByTradeAndServiceProTerritory(startDate: Date, endDate: Date, areaId: string, tradeId: string): Promise<FirstCallsByTradeAndServiceProTerritory[]> {
        return this.api.getArrayNode('contractor-trade-region-commitment/get-first-calls-by-trade-for-area/' + areaId,
            { tradeId, startDate, endDate },
            () => new FirstCallsByTradeAndServiceProTerritory());
    }

    getNonActiveContractorDocumentStatus() {
        return this.api.getArrayNode(`ContractorDocumentStatus`, { status_neq: 'ACTIVE', orderby: 'contractorName' }, () => new ContractorDocumentStatus());
    }


    async getContractorInvoices(startDate: Date, endDate: Date, contractorId: string) {

        return this.api.getArrayNode('ContractorInvoiceSummary', { contractorId_eq: contractorId, CreatedDate_gte: startDate, CreatedDate_lte: endDate, orderby: 'CreatedDate' }, () => new ContractorInvoiceSummaryClient());
    }

    public getPolicyCountsByServiceProTerritory(startDate: Date, endDate: Date, areaId: string): Promise<PolicyCountsInServiceProTerritory[]> {
        return this.api.getArrayNode('contractor-trade-region-commitment/get-policy-counts-for-area/' + areaId,
            { startDate, endDate },
            () => new PolicyCountsInServiceProTerritory());
    }

    deleteContractorInvoicePayment(id: string) {
        return this.api.deleteNode(`service-pro-invoice/payment/${id}`);
    }

    public getContractorReportItems(startDate: Date, endDate: Date, tradeId: string, areaId: string, contractorId: string): Promise<ContractorReportItem[]> {
        return this.api.getArrayDotNet('ContractorReportItem', {
            month_gte: startDate,
            month_lte: endDate,
            tradeId_eq: tradeId,
            areaId_eq: areaId,
            contractorId_eq: contractorId,
            orderBy: "Month"
        }, () => new ContractorReportItem());
    }

    public getContractorStats(startDate: Date, endDate: Date, tradeId: string, contractorId: string = null) {
        return this.api.getArrayNode(`contractor/stats`, { startDate: startDate, endDate: endDate, tradeId: tradeId, contractorId: contractorId });
    }

    public getServiceProReportCards(startDate: Date, endDate: Date, tradeId: string, areaId: string, contractorId: string): Promise<ServiceProReportCard[]> {
        return this.api.getArrayNode('ServiceProReportCard', {
            month_gte: startDate,
            month_lte: endDate,
            tradeId_eq: tradeId,
            areaId_eq: areaId,
            serviceProId_eq: contractorId,
            orderBy: "Month"
        }, () => new ServiceProReportCard());
    }

    public getReportCards(areaId: string, tradeId: string) {
        return this.api.getArrayNode(`service-pro/report-card/${areaId}/${tradeId}`, {}, () => new ServiceProReportCard);
    }

    public getAccDetail(start: Date, end: Date, tradeId: string, areaId: string, contractorId: string): Promise<ClaimCostSummary[]> {
        return this.api.getArrayDotNet('ClaimCostSummary', {
            accMonth_gte: start,
            accMonth_lte: end,
            tradeId_eq: tradeId,
            areaId_eq: areaId,
            contractorId_eq: contractorId,
            orderBy: "AccDate"
        }, () => new ClaimCostSummary());
    }

    public sendRegistrationCode(id: string) {
        return this.api.patchDotNet(`Registration/send-verification-code-to-contractor/${id}`, null);
    }

    public completeContractorRegistration(args) {
        return this.api.postSingleDotNet(`Registration/complete-contractor-registration`, args);
    }

    public getContractorAreaSummaries(tradeId: string, areaId: string): Promise<any[]> {
        return this.api.getArrayDotNet("ContractorTradeAreaSummary", {
            tradeId_eq: tradeId,
            areaId_eq: areaId,
            orderBy: 'contractor'
        });
    }

    public async getContractorTradeTargets(startDate: Date, endDate: Date, tradeId: string) {
        startDate.setDate(startDate.getDate() - 1);
        const items = await this.api.getArrayNode('ContractorTradeAreaTarget',
            { month_gte: startDate, month_lte: endDate },
            () => new ContractorTradeAreaTarget());

        return items;
    }

    public async getContractorTradeTargetSummaries(startDate: Date, endDate: Date, tradeId: string) {
        startDate.setDate(startDate.getDate() - 1);
        const items = await this.api.getArrayNode('ContractorTradeAreaTargetSummary',
            { month_gte: startDate, month_lte: endDate },
            () => new ContractorTradeAreaTargetSummary());

        return items;
    }

    public async getContractorTradeAreaTargets(startDate: Date, endDate: Date, tradeId: string, areaId: string): Promise<ContractorTradeAreaTarget[]> {

        const items = await this.api.getArrayDotNet('ContractorTradeAreaTarget', {
            month_gte: startDate,
            month_lte: endDate,
            tradeId_eq: tradeId,
            areaId_eq: areaId
        }, () => new ContractorTradeAreaTarget());

        const dateToCheck = new Date(startDate);
        while (dateToCheck <= endDate) {

            let target: ContractorTradeAreaTarget = items.filter(i => i.month.getMonth() === dateToCheck.getMonth()
                && i.month.getFullYear() === dateToCheck.getFullYear()
                && i.tradeId === tradeId
                && i.areaId === areaId)[0];
            if (!target) {
                target = new ContractorTradeAreaTarget();
                target.month = new Date(dateToCheck);
                target.areaId = areaId;
                target.tradeId = tradeId;
                target.isDirty = true;
                items.push(target);
            }

            dateToCheck.setMonth(dateToCheck.getMonth() + 1);
        }
        return items;
    }

    getContractorInvoicePaymentStatementHtml(contractorInvoicePaymentId: string): Promise<string> {
        return this.api.getTextDotNet(`ContractorInvoicePayment/${contractorInvoicePaymentId}/statement/html`);
    }

    getContractorPaymentSummaries(contractorId: string, startDate: Date = null, take: number = 10): Promise<ContractorInvoicePaymentSummary[]> {
        const queryParameters: any = {
            contractorId_eq: contractorId,
            take,
            orderBy: 'CreatedDate descending',
        };
        if (startDate) {
            queryParameters.createdDate_lt = startDate;
        }

        return this.api.getArrayDotNet("ContractorInvoicePaymentSummary", queryParameters, () => new ContractorInvoicePaymentSummary());
    }



    saveContractorTrades(contractorTrades: ContractorTrade[]): Promise<any> {
        return this.postItems('ContractorTrade', contractorTrades);
    }

    private postItems(endpointUrl: string, itemsToPost: any[]) {
        return this.api.postVoidDotNet(endpointUrl + '/PostList', itemsToPost);
    }

    saveContractorTrade(contractorTrade: ContractorTrade) {
        return this.api.postIdDotNet('ContractorTrade',
            contractorTrade);
    }

    getContractorTrades(contractorId: string): Promise<ContractorTrade[]> {
        return this.api.getArrayDotNet('ContractorTrade', { entityId_eq: contractorId }, () => new ContractorTrade());
    }

    public saveList(itemsToSave: ContractorTradeAreaTarget[]) {
        return this.api.postVoidDotNet('ContractorTradeAreaTarget/PostList', itemsToSave);
    }

    public saveCommitmentList(itemsToSave: ContractorTradeRegionCommitmentSummary[]) {
        return this.api.postVoidDotNet('ContractorTradeRegionCommitment/PostList', itemsToSave);
    }

    getRecentWorkOrders(contractorId: string, tradeId: string, count = 10): Promise<WorkOrderSummaryClient[]> {
        return this.api.getArrayDotNet(
            'WorkOrderSummary', { contractorId_eq: contractorId, tradeId_eq: tradeId, orderBy: 'CreatedDate descending', take: count },
            () => new WorkOrderSummaryClient());
    }

    getContractorRecentWorkOrders(contractorId: string, count = 100): Promise<WorkOrderSummaryClient[]> {
        return this.api.getArrayDotNet(
            'WorkOrderSummary', { contractorId_eq: contractorId, select: 'Id,Number,ItemName,PropertyAddress,CreatedDate,Status', orderBy: 'CreatedDate descending', take: count },
            () => new WorkOrderSummaryClient());
    }

    getContractorRecentWorkOrders2(contractorId: string, count: number): Promise<WorkOrderSummaryClient[]> {
        return this.api.getArrayDotNet('WorkOrderSummary', { contractorId_eq: contractorId, orderBy: 'CreatedDate descending', take: count }, () => new WorkOrderSummaryClient());
    }

    getSurveysByContractor(contractorId: string): Promise<WorkOrderSurveySummary[]> {
        return this.api.getArrayDotNet('WorkOrderSurveySummary', { contractorId_eq: contractorId }, () => new WorkOrderSurveySummary());
    }

    getRegionMapData(regionId) {
        return this.api.getSingleDotNet(`PostalCodeGeography/geo-json/${regionId}`);
    }

    getRegionParentMapData(regionId: string) {
        return this.api.getSingleDotNet(`PostalCodeGeography/geo-json-parent/${regionId}`);
    }

    getPostalCodeMapDataForArea(areaId: string, regionId: string) {
        return this.api.getSingleDotNet(`PostalCodeGeography/postal-codes-geo-locations/${areaId}/${regionId}`);
    }

    getPostalCodesForRegion(regionId: string) {
        return this.api.getArrayDotNet('entity/EntityRegionPostalCode', { entityId_eq: regionId }, () => new EntityRegionPostalCode());
    }

    async getContractorKPIsByContratorTradeAndArea(contractorId: string, tradeId: string, startDate: Date, endDate: Date, areaId: string): Promise<ContractorKPI[]> {
        return this.api.getArrayDotNet(
            'ContractorKPISummary',
            {
                contractorId_eq: contractorId,
                tradeId_eq: tradeId,
                reportPeriod_gte: startDate,
                reportPeriod_lte: endDate,
                orderby: 'ReportPeriod',
                areaId_eq: areaId,
            },
            () => new ContractorKPI());


    }

    async getCostsByContractor(contractorId: string, tradeId: string = null) {
        const results = await this.api.getArrayNode(`contractor/costs/${contractorId}/${tradeId}`);
        return results;
    }

    async getCostsByTrade(contractorId: string, tradeId: string = null) {
        const results = await this.api.getArrayNode(`contractor/costs-by-trade/${contractorId}/${tradeId}`);
        return results;
    }

    async getSurveysByTrade(contractorId: string, tradeId: string = null) {
        const results = await this.api.getArrayNode(`contractor/surveys-by-trade/${contractorId}/${tradeId}`);
        return results;
    }

    async getCostsByType(serviceProAreaId: string, tradeId: string = null) {
        const results = await this.api.getArrayNode(`contractor/costs-by-type/${serviceProAreaId}/${tradeId}`);
        return results;
    }

    async getContractorKPIs(contractorId: string, tradeId: string, startDate: Date, endDate: Date): Promise<ContractorKPI[]> {
        console.log(startDate, endDate);
        const kpis = await this.api.getArrayDotNet(
            'ContractorKPISummary',
            {
                contractorId_eq: contractorId,
                tradeId_eq: tradeId,
                reportPeriod_gte: startDate,
                reportPeriod_lte: endDate,
                orderby: 'ReportPeriod'
            },
            () => new ContractorKPI());

        const results: ContractorKPI[] = [];

        for (const item of kpis) {
            let result = results.find(i => i.reportPeriod === item.reportPeriod);
            if (!result) {
                result = item;
                results.push(item);
            } else {
                result.claimCount += item.claimCount;
                result.claimCost += item.claimCost;
                result.ratingStarsTotal += item.ratingStarsTotal;
                result.ratingStarsEarned += item.ratingStarsEarned;
                result.recalls += item.recalls;
                result.secondOpinions += item.secondOpinions;
                result.firstCalls += item.firstCalls;
                result.contractorCost += item.contractorCost;
                result.purchaseOrderCost += item.purchaseOrderCost;
                result.cashOutCost += item.cashOutCost;
                result.firstVisitResolvedCount += item.firstVisitResolvedCount;
                result.firstCallsCompleted += item.firstCallsCompleted;
            }
        }


        return results;
    }

    getContractorKPIsForTradesAndAreas(startDate: Date, endDate: Date, tradeIds: string[], areaIds: string[], excludedTradeIds: string[], excludedAreaIds: string[]) {
        return this.api.postArrayDotNet('ContractorKPI/by-areas-and-trades', {
            areaIds,
            tradeIds,
            startDate,
            endDate,
            excludedTradeIds,
            excludedAreaIds,
        }, null, () => new ContractorKPI());

    }

    getContractorKPIsGroupedByTrade(startDate: Date, endDate: Date, tradeIds: string[], areaIds: string[], excludedTradeIds: string[], excludedAreaIds: string[]) {
        return this.api.postArrayDotNet('ContractorKPI/group-by-trade', {
            areaIds,
            tradeIds,
            startDate,
            endDate,
            excludedTradeIds,
            excludedAreaIds,
        }, null, () => new ContractorKPI());
    }

    async getContractorKPIsGroupedByMonth(startDate: Date, endDate: Date, tradeIds: string[], areaIds: string[], excludedTradeIds: string[], excludedAreaIds: string[]) {
        return this.api.postArrayDotNet('ContractorKPI/group-by-month', {
            areaIds,
            tradeIds,
            startDate,
            endDate,
            excludedTradeIds,
            excludedAreaIds,
        }, null, () => new ContractorKPI());
    }

    getSurveys(contractorId: string, tradeId: string, startDate: Date = null, resultCount = 10) {
        if (!startDate) {
            startDate = new Date();
        }

        return this.api.getArrayDotNet(
            'WorkOrderSurveySummary', {
            contractorId_eq: contractorId,
            orderBy: 'CreatedDate descending',
            take: resultCount,
            tradeId_eq: tradeId,
            createdDate_lt: startDate,
        }, () => new WorkOrderSurveySummary());
    }

    getHistoryItems(contractorId: string, tradeId: string, startDate: Date = null, resultCount = 10) {
        if (!startDate) {
            startDate = new Date();
        }

        return this.api.getArrayDotNet(
            'ContractorHistoryItem', {
            contractorId_eq: contractorId,
            orderBy: 'StatusDate descending',
            take: resultCount,
            tradeId_eq: tradeId,
            statusDate_lt: startDate,
        }, () => new ContractorHistoryItem());
    }
}