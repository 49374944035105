import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TriggerRestLog {
    @keyColumn() id: string;
    @column() url: string;
    @column() method: string;
    @column() content: string;
    @column() resultCode: string;
    @column() result: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}