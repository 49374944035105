export class ContractorHistoryItem {
    id: string;
    contractorId: string;
    statusDate: Date;
    status: string;
    url: string;
    number: number;
    type: string;
    tradeId: string;
}



export class GroupedContractorHistoryItem {
    group: string;
    historyItems: ContractorHistoryItem[];
    expanded = true;
    collapsing = false;
    expanding = false;

    private static datesEqual(date1: Date, date2: Date) {
        return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()
            && date1.getFullYear() === date2.getFullYear();
    }

    private static getGroup(historyItem: ContractorHistoryItem): string {
        if (!historyItem.statusDate) {
            return 'Unknown';
        }

        let dateToCheck = new Date();

        if (this.datesEqual(historyItem.statusDate, dateToCheck)) {
            return 'Today';
        }

        dateToCheck.setDate(dateToCheck.getDate() - 1);

        if (this.datesEqual(historyItem.statusDate, dateToCheck)) {
            return 'Yesterday';
        }

        const week = new Date(dateToCheck);
        while (week.getDay() > 0) {
            week.setDate(week.getDate() - 1);
        }

        if (historyItem.statusDate >= week) {
            return 'This week';
        }

        week.setDate(week.getDate() - 7);
        if (historyItem.statusDate >= week) {
            return 'Last week';
        }

        if (historyItem.statusDate.getMonth() === new Date().getMonth()) {
            return 'This Month';
        }

        dateToCheck = new Date();
        dateToCheck.setMonth(new Date().getMonth() - 1);

        if (historyItem.statusDate.getMonth() === dateToCheck.getMonth()) {
            return 'Last Month';
        }

        if (historyItem.statusDate.getFullYear() === new Date().getFullYear()) {
            return 'This Year';
        }

        dateToCheck = new Date();
        dateToCheck.setFullYear(dateToCheck.getFullYear() - 1);
        if (historyItem.statusDate.getFullYear() === dateToCheck.getFullYear()) {
            return 'Last Year';
        }

        return 'Older';

    }

    public static fromHistoryItems(historyItems: ContractorHistoryItem[]): GroupedContractorHistoryItem[] {
        let groupedHistoryItems: GroupedContractorHistoryItem[] = [];

        historyItems.forEach(historyItem => {
            const group = GroupedContractorHistoryItem.getGroup(historyItem);

            let groupedHistoryItem = groupedHistoryItems.filter(i => i.group === group)[0];
            if (!groupedHistoryItem) {
                groupedHistoryItem = new GroupedContractorHistoryItem();
                groupedHistoryItem.group = group;
                groupedHistoryItem.historyItems = [];
                groupedHistoryItem.expanded = true;

                groupedHistoryItems.push(groupedHistoryItem);
            }


            groupedHistoryItem.historyItems.push(historyItem);
        });

        groupedHistoryItems = groupedHistoryItems.sort((a, b) => a.historyItems[0].statusDate > b.historyItems[0].statusDate ? 0 : 1);
        groupedHistoryItems.forEach(item => {
            item.historyItems = item.historyItems.sort((a, b) => a.statusDate > b.statusDate ? 0 : 1);
        });

        return groupedHistoryItems;
    }

    toggleGroup() {
        this.expanding = false;
        this.collapsing = false;



        if (this.expanded) {
            this.collapsing = true;
            setTimeout(() => { this.expanded = false; this.collapsing = false; }, 300);
        } else {
            this.expanding = true;
            this.expanded = true;
            setTimeout(() => { this.expanded = true; this.expanding = false; }, 300);
        }
    }
}
