<div class="container fancy-container bottom-right-rounded">

    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">

            <div *ngIf="offerNotAvailable" class="fade-in-up" style="margin-top: 50px; margin-left:20px;">
                <div class="container">
                    <div class="center" style="display: table;margin: 0 auto">
                        <div>

                            <svg style="height: 180px;" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 581.27 562.55">
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: #f15a24;
                                        }

                                        .cls-2 {
                                            fill: none;
                                            stroke: #f15a24;
                                            stroke-linecap: round;
                                            stroke-miterlimit: 10;
                                            stroke-width: 22px;
                                        }

                                        @keyframes animate-mouth {
                                            from {
                                                d: path('M119.31,238.13c23.22-1.06,51.9-1.68,82.93-1.68,27.36,0,52.88,.49,74.44,1.33');
                                            }

                                            to {
                                                d: path('M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79')
                                            }
                                        }


                                        #mouth {
                                            animation: animate-mouth 5s ease;
                                        }
                                    </style>
                                </defs>
                                <path class="cls-1"
                                    d="M198,34c43.81,0,84.99,17.06,115.97,48.03,30.98,30.98,48.03,72.16,48.03,115.97s-17.06,84.99-48.03,115.97c-30.98,30.98-72.16,48.03-115.97,48.03s-84.99-17.06-115.97-48.03c-30.98-30.98-48.03-72.16-48.03-115.97s17.06-84.99,48.03-115.97c30.98-30.98,72.16-48.03,115.97-48.03m0-34C88.65,0,0,88.65,0,198s88.65,198,198,198,198-88.65,198-198S307.35,0,198,0h0Z" />
                                <rect class="cls-1" x="340.96" y="324.25" width="26" height="66"
                                    transform="translate(-148.16 385.4) rotate(-48.47)" />
                                <rect class="cls-1" x="438.1" y="336.61" width="69" height="250.05" rx="18.95"
                                    ry="18.95" transform="translate(-186.32 509.41) rotate(-48.47)" />
                                <g id="face">
                                    <g>
                                        <circle class="cls-1" cx="147.7" cy="167.79" r="23.79" />
                                        <circle class="cls-1" cx="248.3" cy="167.79" r="23.79" />
                                    </g>
                                    <path id="mouth" class="cls-2"
                                        d="M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79" />
                                </g>
                            </svg>
                        </div>
                        <div>
                            <h1 class="big-data" topmargin40>Sorry</h1>
                            <div topmargin30 class="fade-in-later">
                                <p bottommargni20>Another service pro has been assigned this job</p>
                                <a routerLink="/" mat-button mat-raised-button color="primary">
                                    Home
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="offerWasAccepted" class="fade-in-up" style="margin-top: 50px; margin-left:20px;text-align: center">

                <lottie-player style="height: 200px;"
                    src="https://lottie.host/fccee11d-e1a8-491e-9d05-276e0324faa6/rjywfpUeJj.json"
                    background="transparent" speed="1" autoplay loop [intermission]="20000">
                </lottie-player>
                <h1>Job Accepted</h1>
                <p class="info">The job has already been accepted</p>

                <a mat-button mat-raised-button color="primary" routerLink="/maintenance-service-customer-view/{{acceptedService.maintenanceServiceCustomerPropertyId}}">View Job</a>
            </div>


            <div class="center" bottommargin30 *ngIf="offering">
                <div class="job-offer-img">
                    <img [src]="serviceUrl" *ngIf="offering">
                </div>
                <h1>New Job Offer</h1>
            </div>
            <div #gmap style="width:100%;height:200px"></div>



            <div *ngIf="offering" topmargin20>


                <div class="job-details">
                    <div aflex>
                        <div flex1>

                            <div class="data-label">Service</div>
                            <div>{{offering.name}}</div>
                        </div>
                        <div flex1>
                            <div class="data-label">Frequency</div>
                            <div>{{offering.frequencyName}}</div>
                        </div>
                    </div>
                    <div aflex topmargin20>
                        <div flex1>
                            <div class="data-label">Address</div>
                            <address>
                                {{offering.propertyAddress1}}<br>
                                {{offering.propertyAddress2}}<br *ngIf="offering.propertyAddress2">
                                {{offering.propertyCity}}, {{offering.propertyState}} {{offering.propertyPostalCode}}
                            </address>
                            <div></div>
                        </div>
                        <div flex1>
                            <div class="data-label">Visits/Year</div>
                            <div>{{offering.visitsPerYear}}</div>
                        </div>
                    </div>
                    <div aflex topmargin20>
                        <div flex1 *ngIf="!offering.costPerMonth">
                            <div class="data-label">Amount/Visit</div>
                            <div>{{offering.cost | currency}}</div>
                        </div>
                        <div flex1 *ngIf="offering.costPerMonth">
                            <div class="data-label">Amount/Month</div>
                            <div>{{offering.costPerMonth | currency}}</div>
                        </div>
                        <div flex1>
                            <div class="data-label">Days Before Service Begins</div>
                            <div>{{offering.schedulingBufferDays}}</div>
                        </div>

                    </div>
                    <div aflex topmargin20>
                        <div flex1>

                            <div class="data-label">Bedrooms</div>
                            <div>{{offering.bedroomCount}}</div>
                        </div>
                        <div flex1>

                            <div class="data-label">Bathrooms</div>
                            <div>{{offering.bathroomCount}}</div>
                        </div>
                    </div>
                </div>

                <div class="center" topmargin20>
                    <h3 class="sub-heading">Select the day of the week</h3>
                    <div bottommargin20>
                        <button mat-button mat-raised-button title="Sunday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 0" [class.selected]="offering.preferredDay === 0">
                            <img src="../../../assets/images/sunday.svg">
                        </button>
                        <button mat-button mat-raised-button title="Monday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 1" [class.selected]="offering.preferredDay === 1">
                            <img src="../../../assets/images/monday.svg">
                        </button>

                        <button mat-button mat-raised-button title="Tuesday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 2" [class.selected]="offering.preferredDay === 2">
                            <img src="../../../assets/images/tuesday.svg">
                        </button>


                        <button mat-button mat-raised-button title="Wednesday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 3" [class.selected]="offering.preferredDay === 3">
                            <img src="../../../assets/images/wednesday.svg">
                        </button>

                        <button mat-button mat-raised-button title="Thursday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 4" [class.selected]="offering.preferredDay === 4">
                            <img src="../../../assets/images/thursday.svg">
                        </button>


                        <button mat-button mat-raised-button title="Friday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 5" [class.selected]="offering.preferredDay === 5">
                            <img src="../../../assets/images/friday.svg">
                        </button>


                        <button mat-button mat-raised-button title="Saturday" class="toggle-button day-button"
                            (click)="offering.preferredDay = 6" [class.selected]="offering.preferredDay === 6">
                            <img src="../../../assets/images/saturday.svg">
                        </button>
                    </div>

                    <div class="data-label">Service Begins On</div>
                    <h1 *ngIf="firstServiceDate">{{firstServiceDate | date: 'shortDate'}}</h1>
                    <h1 *ngIf="!firstServiceDate">&nbsp;</h1>

                    <button [disabled]="!canSave" (click)="accept()" large mat-button mat-raised-button color="primary">
                        <mat-spinner *ngIf="accepting" class="thirty"></mat-spinner>
                        Accept
                    </button>


                    <div topmargin40>
                        <button mat-button mat-raised-button [disabled]="saving" (click)="decline()">
                            <mat-spinner *ngIf="declining" class="thirty"></mat-spinner>
                            Decline
                        </button>

                        <!-- <button style="margin-left: 20px;" (click)="showAlernate()" [disabled]="saving" mat-button
                            mat-raised-button>
                            Suggest Alternate
                        </button> -->
                    </div>

                    <h2 topmargin40>Service Terms</h2>
                </div>

                <div class="contract-language"  topmargin10 [innerHTML]="offering.contractLanguage">

                </div>
            </div>
        </mat-tab>
        <mat-tab label="Second">

            <div class="center">

                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin40 bottommargin40>Job Accepted</h1>

                <a mat-button large mat-raised-button color="primary" routerLink="/">
                    Home
                </a>
            </div>
        </mat-tab>
        <mat-tab label="Third">
            <div class="center" topmargin40 *ngIf="selectedIndex === 2">
                <!-- <img src="../../../assets/images/thumb-down-rotate.svg" style="height: 200px;"> -->

                <div style="height: 200px;">
                    <svg id="Layer_1" style="height: 200px;" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 700 700">
                        <defs>
                            <style>
                                @keyframes rotate {
                                    from {
                                        transform: rotate(0deg) translateY(-16px);
                                    }

                                    to {
                                        transform: rotate(180deg) translateY(-16px);
                                    }
                                }

                                @keyframes changeColor {
                                    from {
                                        fill: #006837;
                                    }

                                    to {
                                        fill: #eb4034;
                                    }
                                }

                                @keyframes changeStroke {
                                    from {
                                        stroke: #006837;
                                    }

                                    to {
                                        stroke: #eb4034;
                                    }
                                }

                                .thum-cls-1 {
                                    fill: none;
                                    stroke: #eb4034;
                                    stroke-miterlimit: 10;
                                    stroke-width: 40px;
                                    animation: changeStroke 2s ease;

                                }

                                .thum-cls-2 {
                                    fill: #eb4034;
                                    animation: changeColor 2s ease;

                                }

                                .thumb-group {
                                    animation: rotate 2s ease;
                                    transform-box: fill-box;
                                    transform-origin: center;
                                    transform: rotate(180deg) translateY(-16px);
                                }
                            </style>
                        </defs>
                        <g class="thumb-group">
                            <path class="thum-cls-2"
                                d="M423.31,256.12h6.82c23.01,0,46.03,.35,69.03-.1,24.37-.48,42.56,9.89,53.14,31.32,10.69,21.64,6.71,42.84-7.01,62.2-2.22,3.14-2.61,5.51-1.57,9.01,5.55,18.77,3.36,36.51-7.39,53.1-1.02,1.57-1.46,4.04-1.11,5.88,3.95,20.62-.58,38.91-14.15,55.06-1.45,1.73-1.96,4.77-1.91,7.17,.48,20.22-4.39,38.37-19.98,52.35-12.23,10.97-27.19,15.02-43.15,16.19-54.28,3.99-106.44-5.22-157.27-24.21-14.55-5.43-30.02-8.44-45.18-12.15-9.5-2.32-15.57-9.06-15.72-19.14-.15-10.17-.04-20.34-.04-30.5,0-49.18,0-98.35,0-147.53,0-13.97,4.52-19.29,18.08-22.72,3.53-.89,7.3-3,9.76-5.66,12.44-13.43,24.66-27.08,36.63-40.94,11.33-13.11,22.19-26.62,33.4-39.82,6.54-7.7,10.28-16.76,12.72-26.3,2.76-10.8,4.63-21.82,7.15-32.67,2.52-10.81,6.77-20.88,13.98-29.51,4.23-5.07,9.5-7.93,16.17-7.23,7.43,.78,14.97,1.29,22.19,3.05,23.51,5.74,34.74,22.33,38.64,45.1,5,29.15-.44,56.26-15.33,81.69-2.88,4.93-5.01,10.3-7.92,16.37Z" />
                            <path class="thum-cls-2"
                                d="M219.53,402.51c0,29.67,.01,59.34,0,89.02,0,13.33-7.35,20.62-20.78,20.64-25.84,.02-51.69,.03-77.53,0-12.97-.02-20.6-7.54-20.61-20.42-.03-59.51-.03-119.02,0-178.53,0-13.03,7.39-20.59,20.4-20.64,26.18-.1,52.35-.12,78.53,0,12.75,.06,19.98,7.6,19.99,20.42,.02,29.84,0,59.68,0,89.52Zm-82.18,54.85c.03,10.27,7.74,17.99,18.01,18.05,10.46,.06,18.43-7.85,18.39-18.23-.04-10.06-8.02-18.06-18.07-18.12-10.38-.06-18.35,7.89-18.33,18.3Z" />
                        </g>
                        <circle class="thum-cls-1" cx="345" cy="345" r="321" />
                    </svg>
                </div>
                <h1 topmargin20>Thank You</h1>

                <p topmargin20 class="fade-in-up" *ngIf="findAnother">We'll find another service provider</p>
            </div>
        </mat-tab>

        <mat-tab *ngIf="offering">
            <h1>Suggest Alternate</h1>


            <button mat-button mat-raised-button title="Monday" class="toggle-button day-button"
                (click)="offering.preferredDay = 1" [class.selected]="offering.preferredDay === 1">
                <img src="../../../assets/images/monday.svg">
            </button>

            <button mat-button mat-raised-button title="Tuesday" class="toggle-button day-button"
                (click)="offering.preferredDay = 2" [class.selected]="offering.preferredDay === 2">
                <img src="../../../assets/images/tuesday.svg">
            </button>


            <button mat-button mat-raised-button title="Wednesday" class="toggle-button day-button"
                (click)="offering.preferredDay = 3" [class.selected]="offering.preferredDay === 3">
                <img src="../../../assets/images/wednesday.svg">
            </button>

            <button mat-button mat-raised-button title="Thursday" class="toggle-button day-button"
                (click)="offering.preferredDay = 4" [class.selected]="offering.preferredDay === 4">
                <img src="../../../assets/images/thursday.svg">
            </button>


            <button mat-button mat-raised-button title="Friday" class="toggle-button day-button"
                (click)="offering.preferredDay = 5" [class.selected]="offering.preferredDay === 5">
                <img src="../../../assets/images/friday.svg">
            </button>

            <div topmargin30 class="center">
                <button mat-button mat-raised-button large [disabled]="!canSendAlternate" color="primary">
                    Send Suggestion
                </button>
            </div>
            <div topmargin40>
                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon>
                </button>
            </div>
        </mat-tab>

        <mat-tab>
            <div class="center">

                <app-check-circle-animated height="150px"></app-check-circle-animated>
                <h1 topmargin40 bottommargin40>Job already accepted</h1>
                <a mat-button large mat-raised-button color="primary" routerLink="/">
                    Home
                </a>
            </div>
        </mat-tab>

        <mat-tab>
            <div class="center" *ngIf="selectedIndex === 5">

                <img src="../../../assets/images/thumb-down-rotate.svg" style="height: 150px;">
                <h1 topmargin40 bottommargin40>Job Rejected</h1>
                <p>This job has previously been rejected</p>
                <a mat-button large mat-raised-button color="primary" routerLink="/">
                    Home
                </a>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>

<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>

<!--
<pre>
    {{offering | json}}
</pre> -->