import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DispatchLog {
    @keyColumn() id: string;
    @column() payloadJson: string;
    @column() createdById: string;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;
    @column() processedDate?: Date;

    private _logItem: DispatchLogLineItem;
    get logItem(): DispatchLogLineItem {
        if (!this._logItem) {
            try {
                this._logItem = JSON.parse(this.payloadJson);
            } catch { }
        }

        return this._logItem;
    }

}

export class DispatchLogLineItem {
    Actions?: DispatchLogAction[];
}
export class DispatchLogAction {
    ID?: string;
    Put?: DispatchLogPut;
}
export class DispatchLogPut {
    appointment?: any;
    job?: any;
    customform?: any;
    attachment?: any;
    type = "";

    public get ptype(): string {
        if (this.appointment) return "appointment";
        if (this.job) return "job";
        if (this.customform) return "customform";
        if (this.attachment) return "attachment";
        return "";
    }
}