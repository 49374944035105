export class AppliancePartDetail {
    vendor: string;
    partNumber: string;
    manufacturer: string;
    listPrice: number;
    corePrice: number;
    partPrice: number;
    totalPrice: number;
    imageUrl: string;
    description: string;
    shippingRate: number;
    availability: AppliancePartDetailWarehouseAvailability[];
    encompassBasePN: string;
    reliablePartId: string;
    showAll = false;

    get availabilityDisplay() {
        if (!this.availability) {
            return [];
        }
        if (!this.showAll) {
            return this.availability.filter(i => i.available > 0);
        }

        return this.availability;
    }

    get qtyAvailable() {
        if (!this.availability || this.availability.length === 0) {
            return 0;
        }

        return this.availability.map(i => i.available).reduce((a, b) => a + b);
    }
    expanded = false;
}

export class AppliancePartDetailWarehouseAvailability {
    available: number;
    name: string;
    postalCode: string;
    selected = false;
}

export class SpecAndAppliancePartDetail {
    specDetails: any;
    vendorDetails: AppliancePartDetail[];
}