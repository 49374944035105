<div style="display: relative">
        <button mat-button id="close-button" (click)="dismiss()"><i class="material-icons">close</i></button>
    <div id="check-div">
            <i class="material-icons">warning</i>
    </div>
    <div>
            <h1>Warning</h1>
            <p>{{data}}</p>
    </div>
</div>
