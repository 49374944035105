import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
export class ExtendedPolicyServiceOffering {
    @keyColumn() id: string;
    @column() name: string;
    @column() category: string;
    @column() categorySort: number;
    @column() itemSort: number;
    @column() tradeId: string;
    @column() title: string;
    @column() coveredLanguage: string;
    @column() notCoveredLanguage: string;
    @column() limitsLanguage: string;
    @column() allowTimeSlotSelection: boolean;
    @column() timeSlotDayBuffer: number;
    @column() notCovered: boolean;
    @column() allowWithTradeId: string;
    @column() allowServiceBeforeActive: boolean;
    @column() limitPerOccurance: number;
    @column() limitPerPlanPeriod: number;
    @column() coveredTypes: string;
    @column() coveredLocations: string;
    @column() coveredProblems: string;
    @column() coveredCauses: string;
    @column() salesItemCoverageLimitPerPlan: number;
    @column() salesItemCoverageLimitPerOccurance: number;
    @column() coveredQuantityQuestion: string;
    @column() coveredQuantity: number;
    @column() singleItemJobItem: boolean;
    @column() salesItemCoverageId: string;
}