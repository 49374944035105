import { column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PromoCodeSub {
    @column() code: string;
    @column() adjustmentName: string;
    @column() priceAdjustment: string;
    @column() policyId: string;
    @column() policyNumber: number;
    @column() holderName: string;
    @column() propertyAddress1: string;
    @column() effectiveDate: string;
    @column() createdDate: string;
    @column() coverageType: string;
    @column() status: string;
}