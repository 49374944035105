import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class EntityPhoneNumber {
    @keyColumn() id: string;
    @column() faxNumber: string;
    @column() officeNumber: string;
    @column() mobileNumber: string;
    @column() homeNumber: string;
    @column() homePhoneId: string;
    @column() mobilePhoneId: string;
    @column() entityType: string;
    @column() strippedHomeNumber: string;
    @column() strippedMobileNumber: string;
    @column() createdDate?: Date;
}
