import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SearchIndex {
    @column() recordType: string;
    @column() recordSubType: string;
    @keyColumn() id: string;
    @column() lastModifiedDate: Date;
    @column() name: string;
    @column() email: string;
    @column() number: string;
    @column() address1: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() homeNumber: string;
    @column() mobileNumber: string;
    @column() deletedDate: Date;
    @column() deleted: string;
    @column() inactiveDate: Date;
    @column() inactive: string;
    @column() additionalInfo: string;
    @column() status: string;
    @column() weight: number;
    @column() workNumber: string;
    @column() originationDate: Date;
    @column() key1: string;
    @column() key2: string;
    @column() key3: string;
    @column() address2: string;
}
