<div style="padding: 30px">
    <div aflex>
        <h2 class="sub-heading">Add Address</h2>

        <button mat-button (click)="close()" nogrow>
            <mat-icon>close</mat-icon>
        </button>
    </div>


    <div topmargin30>
        <mat-form-field appearance="outline">
            <mat-label>Address 1  {{addressIsRequired ? '*' : ''}}</mat-label>
          <input autocomplete="do-not-auto-complete"  autocomplete="false" matInput [(ngModel)]="address.address1" type="search">
          <mat-hint align="end">123 W Oak</mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
            <mat-label>Address 2</mat-label>
          <input matInput autocomplete="0" [(ngModel)]="address.address2">
          <mat-hint align="end">Unit 1</mat-hint>
        </mat-form-field>
      </div>
      <div flex>
    
        <mat-form-field flex3 class="city" appearance="outline">
            <mat-label>City  {{addressIsRequired ? '*' : ''}}</mat-label>
          <input matInput [(ngModel)]="address.city">
          <mat-hint align="end">My Town</mat-hint>
        </mat-form-field>
        <mat-form-field class="state" flex1 appearance="outline">
            <mat-label>State  {{addressIsRequired ? '*' : ''}}</mat-label>
          <input matInput  [(ngModel)]="address.state">
          <mat-hint align="end">NY</mat-hint>
        </mat-form-field>
        <mat-form-field class="postal-code" flex2 appearance="outline">
            <mat-label>Postal Code {{addressIsRequired ? '*' : ''}}</mat-label>
          <input matInput inputmode="numeric" [(ngModel)]="address.postalCode" >
          <mat-hint align="end">11111</mat-hint>
        </mat-form-field>
      </div>

      <div aflex topmargin20>
        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">Save</button>
        </div>
      </div>

</div>