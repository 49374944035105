import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceOfferingAddOn {
    @keyColumn() id: string;
    @column() name: string;
    @column() processDefinition: string;
    @column() iconClass: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() maintenanceServiceOfferingId: string;
    @column() price: number;
    @column() cost: number;
    @column() lottieAnimationUrl: string;

    pictureUrl: string;
}