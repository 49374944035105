import { Injectable } from '@angular/core';
declare var window: any;
@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {

    constructor() { }

    sendData(eventName: string, eventData: any = null) {
        const eventPackage = {
            event: eventName,

        };
        if (eventData) {
            for (const key in eventData) {
                eventPackage[key] = eventData[key];
            }
        }
        window.dataLayer.push(eventPackage);
    }
}
