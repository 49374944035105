import { DatePipe } from "@angular/common";
import { UtilitiesService } from "@cogent/client/shared/logic/utilities";
import { WorkOrderLineSummary, WorkOrderSummary } from "@upkeeplabs/models/cogent";
import { ApiEndpoints } from "@cogent/shared/models/common/api-endpoints.model";
import { AlternateTimeSlotSuggestion } from "@cogent/shared/models/service/alternate-time-slot-suggestion.model";
import { WorkOrderLineSummaryClient } from "@cogent/shared/models/service/work-order-line-summary.model";

export class WorkOrderSummaryClient extends WorkOrderSummary {


    dispatchExportDate?: Date;
    formattedAppointmentDate: string;
    private parsedQAndA: any;
    tripFeeAllowance: number;
    tagsJson: string;
    alternateTimeSlotSuggestion: AlternateTimeSlotSuggestion;
    mailingAddressId: string;
    chargeServiceFeePerItem: boolean;


    private datePipe: DatePipe = new DatePipe('en-US');

    overlapCount: number;

    get isWithin60Days() {
        if (!this.effectiveDate) {
            return true;
        }
        const start = Date.UTC(this.createdDate.getFullYear(), this.createdDate.getMonth(), this.createdDate.getDate())
        const end = Date.UTC(this.effectiveDate.getFullYear(), this.effectiveDate.getMonth(), this.effectiveDate.getDate())
        const oneDay = 1000 * 60 * 60 * 24;
        const days = (start - end) / oneDay;
        if (days <= 60)
            return true;
        else
            return false;
    }

    get effectiveDescription() {
        if (!this.effectiveDate) {
            return null;
        }

        return `${this.datePipe.transform(this.effectiveDate, 'MM/dd/yyyy')} - ${this.datePipe.transform(this.expirationDate, 'MM/dd/yyyy')}`;
    }

    private _userDefinedValue: any;
    get userDefinedValue() {
        if (!this._userDefinedValue) {
            try {
                this._userDefinedValue = JSON.parse(this.userDefined);
            } catch { }
            if (!this._userDefinedValue) {
                this._userDefinedValue = {};
            }
        }

        return this._userDefinedValue;
    }

    private _lines;
    get lines(): WorkOrderLineSummaryClient[] {
        if (!this._lines) {
            if (this.linesJson) {
                this._lines = JSON.parse(this.linesJson);
                this._lines = UtilitiesService.copyArrayToTypedArray(this._lines, () => new WorkOrderLineSummaryClient());
                for (const line of this._lines) {
                    if (line.qandA) {
                        line.questionsAndAnswers = JSON.parse(line.qandA);
                    }
                    line.lineId = line.id;
                }
            }
            else {
                this._lines = [];
            }
        }
        return this._lines;
    }
    set lines(value: any[]) {
        this._lines = value;
    }

    private _tags;
    get tags(): any[] {
        if (!this._tags) {
            if (this.tagsJson) {
                this._tags = JSON.parse(this.tagsJson);
            } else {
                this._tags = [];
            }
        }

        return this._tags;
    }

    get appointmentDescription(): string {
        if (!this.scheduledDate) { return null; }

        return this.datePipe.transform(this.scheduledDate, 'MM/dd/yyyy') + ' '
            + this.scheduledStartWindow + ' - ' + this.scheduledEndWindow;
    }

    get completedDescription(): string {
        if (!this.dateCompleted) { return null; }

        return this.datePipe.transform(this.dateCompleted, 'MM/dd/yyyy');
    }

    get dispatchDescription(): string {
        if (!this.dispatchedDate) { return null; }

        return this.datePipe.transform(this.dispatchedDate, 'MM/dd/yyyy');
    }

    get sentDescription(): string {
        if (!this.sentDate) { return null; }

        return this.datePipe.transform(this.sentDate, 'MM/dd/yyyy');
    }


    get questionsAndAnswers(): any {
        if (!this.qandA) {
            return null;
        }
        if (!this.parsedQAndA) {
            this.parsedQAndA = JSON.parse(this.qandA);

        }
        return this.parsedQAndA;
    }

    get canCancel() {
        if (this.cancelledDate) {
            return false;
        }

        if (this.dateCompleted) {
            return false;
        }

        if (this.formattedAppointmentDate) {
            const appDate = new Date(this.formattedAppointmentDate);
            if (appDate < new Date() || UtilitiesService.datesEqual(appDate, new Date())) {
                return false;
            }
        }

        return true;
    }

    get propertyThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}policy/${this.policyId}/StreetView`;
    }

    get contractorThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/photo/${this.contractorId}`;
    }

    get workOrderItemThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}WorkOrderItem/${this.itemId}/Photo`;
    }

    get createdByThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.createdById}`;
    }

    get assignedToThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.assignedToUserId}`;
    }

    get zillowUrlFragment() {
        return `${UtilitiesService.replaceAll(this.propertyAddress, ' ', '-')},-${UtilitiesService.replaceAll(this.propertyCity, ' ', '-')},-${this.propertyState},-${this.propertyPostalCode}_rb`;
    }

    get appointmentStartHour() {
        if (!this.scheduledStartWindow) {
            return null;
        }

        let hour = parseInt(this.scheduledStartWindow, 10);
        if (hour !== 12 && this.scheduledStartWindow.toUpperCase().indexOf('PM') > -1) {
            hour += 12;
        }

        return hour;
    }

    get appointmentEndHour() {
        if (!this.scheduledEndWindow) {
            return null;
        }

        let hour = parseInt(this.scheduledEndWindow, 10);
        if (hour !== 12 && this.scheduledEndWindow.toUpperCase().indexOf('PM') > -1) {
            hour += 12;
        }

        return hour;
    }

    private timeInStatusPrivate: string;

    get timeInStatus() {
        if (!this.timeInStatusPrivate) {
            if (!this.statusDate) {
                return null;
            }

            let diff = ((new Date()).getTime() - this.statusDate.getTime()) / 1000;

            let days = 0;
            let hours = 0;
            let minutes = 0;
            let seconds = 0;

            if (diff >= 86400) {

                days = Math.floor(diff / 86400);
                diff -= days * 86400;
            }
            if (diff >= 3600) {
                hours = Math.floor(diff / 3600);
                diff -= hours * 3600;
            }

            if (diff >= 60) {
                minutes = Math.floor(diff / 60);
                diff -= minutes * 60;
            }


            seconds = Math.floor(diff);

            let result = '';

            if (days > 0) {
                result += days + (days > 1 ? ' days ' : ' day ');
            }

            if (hours > 0) {
                result += hours + (hours > 1 ? ' hours ' : ' hour ');
            }

            if (minutes > 0) {
                result += minutes + (minutes > 1 ? ' minutes ' : ' minute ');
            }
            this.timeInStatusPrivate = result.trim();
            if (!this.timeInStatusPrivate) {
                this.timeInStatusPrivate = '0 minutes';
            }
        }

        return this.timeInStatusPrivate;
    }
}

