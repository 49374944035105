import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalSettingsRepositoryService {

    private storagePrefix = 'local_setting';
    getSetting(key: string, defaultValue: string = null): string {
        const item = localStorage.getItem(this.storagePrefix + key);
        if (!item) {
            return defaultValue;
        }
        return item;
    }

    setSetting(key: string, value: string) {
        localStorage.setItem(this.storagePrefix + key, value);
    }
}