import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCustomerPropertyServiceSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
  selector: 'app-maint-services-change-preferred-day',
  templateUrl: './maint-services-change-preferred-day.component.html',
  styleUrls: ['./maint-services-change-preferred-day.component.css']
})
export class MaintServicesChangePreferredDayComponent implements OnInit {
    originalPreferredDay: number;
    preferredDay: number;
    saving = false;

    constructor(public dialogRef: MatDialogRef<MaintServicesChangePreferredDayComponent>,
        private maintApi: MaintenanceServiceApiService,
        private missionService: MissionService,
        @Inject(MAT_DIALOG_DATA) public service: MaintenanceServiceCustomerPropertyServiceSummary,) { }

    ngOnInit(): void {
        this.originalPreferredDay = this.service.preferredDay;
        this.preferredDay = this.service.preferredDay;
    }


    get canSave() {
        return this.originalPreferredDay !== this.preferredDay && !this.saving;
    }

    async save() {
        this.saving = true;
        await this.maintApi.updatePreferredDay(this.service.id, this.preferredDay);
        this.saving = false;
        this.dialogRef.close(true);
        this.missionService.showSuccessToast('Preferred date was updated.')
    }

}
