import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { Trade } from "./trade.model";
import { WorkOrderItem } from "./work-order-item.model";

@dataSource()
export class RepairItemGroup {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() availableToAll: boolean;

    selectedTrades: Trade[] = [];
    selectedWorkOrderItems: WorkOrderItem[] = [];
}