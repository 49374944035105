import { ApiService } from '@cogent/client/api';
import { Injectable } from '@angular/core';
import { AuthService } from '@cogent/client/auth';
import { CustomerRegistrationArgs, EntitySummary } from '@upkeeplabs/models/cogent';
import { Login } from '@cogent/shared/models/user/login.model';

@Injectable({ providedIn: 'root' })
export class LoginApiService {
    completeCustomerRegistration(args: CustomerRegistrationArgs) {
        return this.api.postNode(`entity/complete-customer-registration`, args);
    }
    completeCustomerRegistrationWithCreate(args: CustomerRegistrationArgs) {
        return this.api.postNode(`entity/complete-customer-registration-and-create`, args);
    }
    constructor(
        private api: ApiService,
        private authService: AuthService
    ) { }

    getLogin(id: string): Promise<Login> {
        return this.api.getSingleDotNet('Login/' + id, null, () => new Login());
    }

    async loginExists(userName: string, type: string = null): Promise<boolean> {
        const q = { properties: 'id', userName_eq: userName};
        if(type) {
            q['entityType_eq'] = type;
        }
        const result = await this.api.getArrayDotNet("Login", q);
        return result.length > 0;
    }

    async getLoginByUserName(userName: string) {
        let login = await this.api.getSingleDotNet(`Login`, {userName_eq: userName, entityType_eq: 'Customer'});
        if(!login) {
            login = await this.api.getSingleDotNet(`Login`, {userName_eq: userName, entityType_eq: 'Agent'});
        }

        return login;
    }

    findLogins(loginEmail: string, types: string[] = null) {
        if (!types) {
            types = [];
            types.push('Agent');
            types.push('Customer');
        }

        return this.api.getArrayNode(`Login`, { userName_eq: loginEmail, entityType_contains: types.join(',') }, () => new Login());
    }

    deleteLogin(loginId: string): Promise<any> {
        return this.api.deleteDotNet(`Login/${loginId}`);
    }

    unDeleteLogin(loginId: string): Promise<any> {
        return this.api.unDeleteDotNet(`Login/${loginId}`);
    }

    removeLoginFromEntity(entityId: string) {
        return this.api.patchDotNet(`Entity/${entityId}`, { loginId: null });
    }

    addLoginToEntity(entityId: string, loginId: string) {
        return this.api.patchDotNet(`Entity/${entityId}`, { loginId });
    }

    getEntitiesForLoginId(loginId: string, type: string): Promise<EntitySummary[]> {
        return this.api.getArrayDotNet(`EntitySummary`, { loginId_eq: loginId, type_eq: type, orderby: 'name' }, () => new EntitySummary());
    }

    getEntityLogin(loginId: string): Promise<Login> {
        if (!loginId) {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
        return this.api.getSingleDotNet(`Login`, { id_eq: loginId }, () => new Login());
    }

    createTemporaryLogin(id: string): Promise<any> {
        return this.api.postSingleDotNet(`TemporaryPassword`, { id });
    }

    async saveLogin(login: Login) {
        if(!ApiService.isLegacy) {
            return await this.saveLogin2(login);
        }

        const authority = await this.authService.getAuthority();
        await this.api.postSingleDotNet(`${authority}login/create`, login);
        if (login.entityId) {
            await this.api.patchDotNet('entity/' + login.entityId, { loginId: login.id });
        }
        return;
    }

    async saveLogin2(login: Login) {
        await this.api.postSingleAccountsDotNet('login/create',login);
        if (login.entityId) {
            await this.api.patchDotNet('entity/' + login.entityId, { loginId: login.id });
        }
        return;
    }

    updateEntityLogin(entityId: string, loginId: string) {
        return this.api.patchSingleDotNet(`Entity/${entityId}`, { loginId });
    }

    getCustomerRegistrationArgs(args: CustomerRegistrationArgs): Promise<CustomerRegistrationArgs> {
        return this.api.postSingleNode('entity/get-customer-registration-args', args);
    }

    verifyVerificationCode(args: CustomerRegistrationArgs) {
        return this.api.postSingleNode(`entity/verify-verification-code`, args, () => new CustomerRegistrationArgs());
    }
}