import { PartToOrder, WorkOrderItemProblem } from "@upkeeplabs/models/cogent";

import { TechnicianContact } from '@cogent/shared/models/authorizations/technician-contact.model';
import { AuthorizationRequestResult } from '@cogent/shared/models/authorizations/authorization-request-result.model';
import { WorkOrderAttachment } from '@cogent/shared/models/service/work-order-attachment.model';
import { AuthorizationLine } from "@cogent/shared/models/authorizations/authorization-line.model";

export class AuthorizationRequestArgs {
    itemTypeId: string;
    locationId: string;
    photos: WorkOrderAttachment[];
    problemIds: string[];
    causeId: string;
    causeDescription: string;
    preExistingConditions: string; //--(No, Yes, Unknown)
    coveredByMfgWarranty: string; //--(No, Yes, Unknown)
    manufacturer: string;
    ageOfUnit: number;
    modelNumber: string;
    serialNumber: string;
    recommendation: string; //--(Repair, Replace)
    canBeRepairedToday: boolean;
    estimatedRepairDate: Date;
    reasonForTheReplacementId: string;
    authorizationLines: AuthorizationLine[];
    technicianContact: TechnicianContact;
    createdById: string;
    lastModifiedById: string;
    createdDate: Date;
    lastModifiedDate: Date;
    doComparables: boolean;
    id: string;
    favorFundAmount: number;

    typeName: string;
    locationName: string;
    problems: WorkOrderItemProblem[];
    causeName: string;
    result: AuthorizationRequestResult;
    complete: boolean;

    validateType: boolean;
    validateProblem: boolean;
    validateCause: boolean;
    validatePreExisting: boolean;
    validateMfgWarranty: boolean;
    validateRepairItems: boolean;
    validatePlanTotals: boolean;
    overrideAuthoAmount: number;
    coveredQuantity: number;
    saveAutho: boolean;
    partsToOrder: PartToOrder[];

    laborHours: number;
    laborRate: number;

    authorizationSource: string;

    laborTotal: number;
    additionalNotes: string;
    applianceInstallAmount = 0;

    get calculatedLabor() {
        return this.laborHours * this.laborRate;
    }
}