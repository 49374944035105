import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UserNotification } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class UserNotificationApiService {

    constructor(private api: ApiService, private entityApi: EntityApiService) { }

    async getNewNotifications(take: number = 20): Promise<UserNotification[]> {

        const user = await this.entityApi.getLoggedInUser(false);

        if (user) {
            return this.api.getArrayDotNet('UserNotification',
                { wasRead_eq: false, userid_eq: '{{currentUser}}', orderby: 'CreatedDate desc', take },
                () => new UserNotification(), true);
        }
    }

    async getAllUserNotifications(): Promise<UserNotification[]> {

        const user = await this.entityApi.getLoggedInUser(false);
        if (user) {
            return this.api.getArrayDotNet('UserNotification',
                { userid_eq: '{{currentUser}}', orderby: 'CreatedDate desc' },
                () => new UserNotification(), true);
        }
    }

    async getUnreadNotificationCount(): Promise<number> {
        const user = await this.entityApi.getLoggedInUser(false);
        if (user) {
            return this.api.getSingleDotNet('UserNotification/count',
                { userid_eq: '{{currentUser}}', wasRead_eq: false });
        }
    }

    sendUserNotificationByParams(userId: string, description: string, url: string) {
        return this.sendUserNotification(new UserNotification(null, userId, null, description, url));
    }

    sendUserNotification(notification: UserNotification) {
        return this.api.postIdDotNet('UserNotification', notification);
    }

    updateUserNotification(notification: UserNotification) {
        return this.api.postSingleDotNet('UserNotification', notification);
    }

    markUserNotificationRead(id: string) {
        return this.api.patchSingleDotNet(`UserNotification/${id}`, { wasRead: true });
    }


}