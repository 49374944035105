import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PartToOrder {
    @keyColumn() id: string;
    @column() workOrderLineId: string;
    @column() partNumber: string;
    @column() description: string;
    @column() cost: number;
    @column() quantity: number;
    @column() dateOrdered: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() manufacturer: string;
    @column() manufacturerCode: string;
    @column() imageUrl: string;
    @column() modelNumber: string;
    @column() encompassMfgCode: string;
    @column() reliableMfgCode: string;
    @column() marconeMake: string;
    @column() encompassBasePN: string;
    @column() reliablePartId: string;
    @column() purchaseOrderItemIds: string;

    images: string[];
    notCovered: boolean;
    notCoveredTerm: string;
    companyProvides  = false;
    companyPrice: number;


    get subtotal() {
        return this.quantity * this.cost;
    }

    private _lineIds: string[];
    get lineIds(): string[] {
        if(!this.purchaseOrderItemIds) {
            return [];
        }
        try {
            this._lineIds = JSON.parse(this.purchaseOrderItemIds);
        }catch{}

        if(!this._lineIds) {
            this._lineIds = [];
        }

        return this._lineIds;
    }
}