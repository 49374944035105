import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceCustomerPropertyServiceAppointmentSummary } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-maint-services-cancel-appointment',
    templateUrl: './maint-services-cancel-appointment.component.html',
    styleUrls: ['./maint-services-cancel-appointment.component.css']
})
export class MaintServicesCancelAppointmentComponent implements OnInit {

    saving = false;
    constructor(public dialogRef: MatDialogRef<MaintServicesCancelAppointmentComponent>,
        private maintApi: MaintenanceServiceApiService,
        @Inject(MAT_DIALOG_DATA) public data: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) { }

    ngOnInit(): void {
    }

    get canSave() {
        return !this.saving;
    }

    async save() {
        this.saving = true;

        await this.maintApi.cancelAppointment(this.data.id, true);

        this.saving = false;
        this.dialogRef.close(true);
    }
}
