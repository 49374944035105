<div (drop)="drop($event)" [class.drag-target-active]="dragTargetActive" (dragenter)="dragEnter($event)"
    (dragover)="dragover($event)" (dragleave)="dragleave($event)">
    <button (click)="showFileUpload()"  *ngIf="!files || files.length === 0" topmargin30 mat-button block large><mat-icon>file_upload</mat-icon> Add</button>

    <div *ngFor="let attachment of files" class="attachment-container">
        <button mat-button class="remove-button"  (click)="removeFile(attachment)"><i
                class="material-icons">delete</i></button>
        <img *ngIf="attachment.isImage" [src]="attachment.base64" class="attachment-thumb">
        <mat-icon style="height: 80px;width: 80px;font-size: 80px" *ngIf="!attachment.isImage">
            description
        </mat-icon>
        <br>
        {{attachment.name}}

    </div>

    <div class="attachment-container" *ngIf="files?.length > 0">
        <button (click)="showFileUpload()" mat-button block large>
            <div>
                <mat-icon  style="height: 60px;width: 60px;font-size: 60px">file_upload</mat-icon><br>Add
            </div>
        </button>

</div>


<input type="file" style="display: none" name="fileToUpload1" (change)="handleFileUpload($event)" [id]="id" />
</div>