import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AccountingMapping {
    @keyColumn() id: string;
    @column() areaId: string;
    @column() accountType: string;
    @column() quickBooksAccountId: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;

    // client side only:
    isDirty = false;
}