import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource()
export class PositivePayReportItem {
    @column() amount: number;
    @column() createdDate: Date;
    @column() name: string;
    @keyColumn() id: string;
    @column() checkNumber: string;
    @column() type: string;
    @column() url: string;
    @column() accountingAreaId: string;
    @column() accountNumber: string;
    @column() realmId: string;
    @column() quickBooksPaymentId: string;
    @column() formattedDate: string;
}
