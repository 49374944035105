import { Role } from '@upkeeplabs/models/cogent';
import { QueueQuery } from '@cogent/client/shared/models/object-queue.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';

export class LineGrouping {
    workOrderId: string;
    items: WorkOrderLineSummaryClient[];
    propertyAddress: string;
    number: number;

    get hasMultipleItems() {
        return this.items && this.items.length > 1
    }

    public static fromItems(items: WorkOrderLineSummaryClient[]): LineGrouping[] {
        const results: LineGrouping[] = [];

        for (const item of items) {
            let grouping = results.find(i => i.workOrderId === item.workOrderId);
            if (!grouping) {
                grouping = new LineGrouping();
                grouping.workOrderId = item.workOrderId;
                grouping.propertyAddress = item.propertyAddress;
                grouping.number = item.number;
                grouping.items = [];
                results.push(grouping);
            }
            item.parent = grouping;
            grouping.items.push(item);
        }

        return results;
    }
}

export class ContractorSwimLane {
    id: string;
    name: string;
    sort: number;
    configuration: string;
    roles: string;

    // Client Side only
    items: WorkOrderLineSummaryClient[];
    filteredItems: WorkOrderLineSummaryClient[];
    groupings: LineGrouping[];

    private queryObject: QueueQuery;
    private roleObjectInternal: Role[];

    get queueQuery(): QueueQuery {
        if (this.queryObject) {
            return this.queryObject;
        }
        if (!this.configuration) {
            this.queryObject = new QueueQuery();
        } else {
            this.queryObject = JSON.parse(this.configuration);
        }

        return this.queryObject;
    }

    setItems(items: WorkOrderLineSummaryClient[]) {
        this.items = items;
        this.filteredItems = items;
        this.groupings = LineGrouping.fromItems(items);
    }

    set queueQuery(query: QueueQuery) {
        this.queryObject = null;
        this.configuration = JSON.stringify(query);
    }

    get rolesObject(): Role[] {
        if (this.roleObjectInternal) {
            return this.roleObjectInternal;
        }
        if (!this.roles) {
            this.roleObjectInternal = [];
        } else {
            this.roleObjectInternal = JSON.parse(this.roles);
        }

        return this.roleObjectInternal;
    }
    set rolesObject(roles: Role[]) {
        this.roleObjectInternal = null;
        this.roles = JSON.stringify(roles);
    }
}