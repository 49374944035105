import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource("BI_DSA")
export class BiDsa {
    @column() holderName: string;
    @column() holderEmail: string;
    @column() number: string;
    @column() squareFeet: number;
    @column() address1: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() employeeId: string;
    @column() employeeName: string;
    @column() teamId: string;
    @column() teamName: string;
    @column() policyId: string;
    @column() policyNumber: number;
    @column() effectiveDate: Date;
}