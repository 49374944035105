import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Mention {
    @keyColumn() id: string;
    @column() noteId: string;
    @column() entityId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() acknowledged: boolean;
}


@dataSource()
export class MentionSummary {
    @keyColumn() id: string;
    @column() mentionedId: string;
    @column() mentionedName: string;
    @column() mentionedEmail: string;
    @column() mentionedType: string;
    @column() noteText: string;
    @column() createdDate?: Date;
    @column() noteCreatedDate: Date;
    @column() policyId: string;
    @column() entityId: string;
    @column() taskId: string;
    @column() workOrderId: string;
    @column() noteId: string;
    @column() createdByName: string;
    @column() createdByEmail: string;
    @column() noteType: string;
    @column() documentNumber: bigint;
    @column() documentId: string;
    @column() url: string;
    @column() createdById?: string;
    @column() mentionedEntityId: string;
    @column() acknowledged: boolean;
    @column() sendEmail: boolean;
}