import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { CustomTableApi } from '@cogent/client/shared/services/api/custom-table-api.service';
import { debounceTime } from 'rxjs/operators';
import { KeyValuePair} from '@cogent/shared/models/common/key-value-pair.model';

@Component({
    selector: 'app-custom-table-chip-selection',
    templateUrl: './custom-table-chip-selection.component.html',
    styleUrls: ['./custom-table-chip-selection.component.css']
})
export class CustomTableChipSelectionComponent implements OnInit, OnChanges {

    @Input() selectedResults: KeyValuePair[] = [];
    @Input() type: string;
    @Input() placeholder: string;
    @Input() searchForField: string;
    @Input() idField: string = 'id';
    @Input() singleSelect: boolean;
    @Input() additionalParams: string;

    @Output() selectedResultsChange: EventEmitter<KeyValuePair[]> = new EventEmitter();


    allItems: KeyValuePair[] = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    searching = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchCtrl = new UntypedFormControl();
    searchFor: string;
    filteredResults;
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @Input('selectMode') selectMode: boolean;
    selectedItem: KeyValuePair;


    constructor(private customTableApi: CustomTableApi) {
        this.filteredResults = this.searchCtrl.valueChanges.pipe(debounceTime(500))
            .subscribe(value => {
                this.searchFor = value;
                if (value && !value.id) {
                    this.getData();
                }
            });
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        this.setupSelectMode();
    }

    async setupSelectMode() {
        if (this.selectMode && this.type && this.idField && this.searchForField) {
            const results = await this.customTableApi.searchCustomTableObject(this.type, '', this.searchForField, this.idField, this.additionalParams);
            this.allItems = (results).map(i => {
                return { key: i[this.idField], value: i[this.searchForField] };
            });
        }
    }

    selectChange(value: KeyValuePair) {
        this.selectedResults = [value];
        this.selectedResultsChange.emit(this.selectedResults);
    }

    async getData() {
        this.searching = true;
        const results = await this.customTableApi.searchCustomTableObject(this.type, this.searchFor, this.searchForField, this.idField, this.additionalParams);
        this.allItems = (results).map(i => {
            return { key: i[this.idField], value: i[this.searchForField] };
        });
        this.searching = false;
    }


    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.searchCtrl.setValue(null);
        }
    }

    remove(result: KeyValuePair): void {
        const index = this.selectedResults.indexOf(result);

        if (index >= 0) {
            this.selectedResults.splice(index, 1);
        }

        this.selectedResultsChange.emit(this.selectedResults);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.selectedResults || this.singleSelect) {
            this.selectedResults = [];
        }
        this.selectedResults.push(event.option.value);
        this.allItems = [];
        this.searchInput.nativeElement.value = '';
        this.searchCtrl.setValue(null);

        this.selectedResultsChange.emit(this.selectedResults);

    }

}
