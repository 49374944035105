<div class="container">
    <h1 class="page-title"><span class="big">Pending</span><br ><span class="little">Offers</span></h1>
    <ul id="offers-list">
        <li *ngFor="let offer of offers">
            <a mat-button routerLink="/work-order-detail/{{offer.id}}">
                <div aflex>
                    <div nogrow>
                        <img [src]="getWorkOrderItemUrl(offer)">
                    </div>
                    <div>
                        <div>{{offer.itemName}}</div>
                        <div>{{offer.propertyAddress}}</div>
                        <div>{{offer.propertyCity}}</div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>