import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FunctionTriggerItem {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() iconClass: string;
    @column() description: string;
    @column() tableName: string;
    @column() onInsert: boolean;
    @column() onUpdate: boolean;
    @column() onDelete: boolean;
}