import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PlanPricingAdjustmentItem {
    @keyColumn() id: string;
    @column() planPricingAdjustmentId: string;
    @column() minValue: number;
    @column() priceAdjustment: number;
    @column() deletedDate?: Date;
    @column() priceAdjustmentType: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() referenceId?: string;
    @column() appliesToHomeowner: boolean;
    @column() appliesToBuyer: boolean;
    @column() appliesToRenewal: boolean;
    @column() maxIncreasePercent: number;
    @column() maxDecreasePercent: number;
    @column() numberOfMonthsValid?: number;



    constructor(id: string = null, planPricingAdjustmentId: string = null, minValue: number = null, priceAdjustment: number = null, priceAdjustmentType: string = null, referenceId: string = null) {
        this.id = id;
        this.planPricingAdjustmentId = planPricingAdjustmentId;
        this.minValue = minValue;
        this.priceAdjustment = priceAdjustment;
        this.priceAdjustmentType = priceAdjustmentType;
        this.referenceId = referenceId;
    }
}