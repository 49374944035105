import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesItemCoverageApplianceCoverageTerm {
    @keyColumn() id: string;
    @column() term: string;
    @column() isCovered: boolean;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;
    @column() salesItemCoverageId: string;

    isNew = false;
}