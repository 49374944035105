export class ContractorAccSummary {
    accMonth: Date;
    year: number;
    month: number;
    quarter: number;
    contractor: string;
    trade: string;
    jobItem: string;
    stateRegion: string;
    contractorRegion: string;
    claims: number;
    costs: number;
}