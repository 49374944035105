import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorKPI {
    @keyColumn() id: string;
    @column() contractorId: string;
    @column() tradeId: string;
    @column() reportPeriod: Date;
    @column() claimCount: number;
    @column() claimCost: number;
    @column() ratingStarsTotal: number;
    @column() ratingStarsEarned: number;
    @column() recalls: number;
    @column() secondOpinions: number;
    @column() firstCalls: number;
    @column() contractorCost: number;
    @column() purchaseOrderCost: number;
    @column() cashOutCost: number;
    @column() reportMonth: number;
    @column() reportYear: number;
    @column() tradeName: string;
    @column() firstVisitResolvedCount: number;
    @column() areaId: string;
    @column() contractorRating: number;
    @column() stateId: string;
    @column() firstCallsCompleted: number;
}