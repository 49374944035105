import { Injectable } from '@angular/core';
import { AppointmentTimeSlot } from '@upkeeplabs/service-pros/app/model/claims/appointment-time-slot.model';
import { ItemModel } from '@upkeeplabs/service-pros/app/model/claims/item.model';
import { TechnicianContact } from '@upkeeplabs/service-pros/app/model/contractors/technician-contact.model';
import { Note } from '@upkeeplabs/service-pros/app/model/common/note.model';
import { ApiService } from '@cogent/client/api';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Address, Entity } from '@upkeeplabs/models/cogent';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { AuthorizationLine } from '@cogent/shared/models/authorizations/authorization-line.model';
import { NoteAttachment } from '@cogent/client/shared/models/common/note-client.model';


@Injectable({ providedIn: 'root' })
export class ServiceRepositoryService {

    constructor(
        private api: ApiService,
        private planApi: PlanApiService,
        private noteApi: NoteApiService,
        private dataApi: DataApiService,
        private serviceApi: ServiceApiService
    ) { }

    getWorkOrderSummary(workOrderId: string): Promise<WorkOrderSummaryClient> {
        return this.api.getSingleDotNet<WorkOrderSummaryClient>("WorkOrderSummary/" + workOrderId, null, () => new WorkOrderSummaryClient());
    }

    getAuthoProcess(workOrderItemId: string): Promise<string> {
        return this.api.getTextDotNet('AuthoProcess/' + workOrderItemId);
    }

    async saveAuthorizationAttachments(note: Note, attachments: NoteAttachment[]): Promise<any> {
        note.id = UtilitiesService.newid();
        await this.api.postVoidDotNet("Note", note);
        const promises = [];
        attachments.forEach(attachment => {
            attachment.noteId = note.id;
            promises.push(this.noteApi.saveNoteAttachment(note.id, attachment as any));
        });
        await Promise.all(promises);
    }

    getWorkOrderProcess(workOrderItemId: string): Promise<string> {
        return this.dataApi.getWorkOrderProcess(workOrderItemId);
    }

    getWorkOrderHistory(workOrderId: string) {
        return this.serviceApi.getWorkOrderStatusHistory(workOrderId);
    }

    getAssignedAppointmentsForDay(day: Date, contractorId: string) {
        const startDate = UtilitiesService.dayBegin(day);
        const endDate = UtilitiesService.dayEnd(day);
        return this.api.getArrayDotNet(`WorkOrderSummary`, {
            scheduledDate_gte: startDate,
            scheduledDate_lte: endDate,
            technicianId_neq: '{{null}}',
            contractorId_eq: contractorId,
            select: 'id,technicianId,propertyAddress,propertyCity,propertyPostalCode,scheduledDate,scheduledStartWindow,scheduledEndWindow'
        }, () => new WorkOrderSummaryClient());
    }

    getAddress(addressId: string): Promise<Address> {
        return this.api.getSingleDotNet(`Address/${addressId}`, null, () => new Address());
    }

    findItemsByType(type: string): Promise<ItemModel[]> {
        return this.api.getArrayDotNet('item/find/' + type, { searchFor: '', orderby: 'number' });
    }

    findItemsByTypePolicyAndWorkOrderItem(type: string, policyId: string, workOrderItemId: string): Promise<ItemModel[]> {
        return this.api.getArrayDotNet(`item/find/${type}/${policyId}/${workOrderItemId}`, { searchFor: '', orderby: 'number' });
    }

    getWorkOrderAppointmentTimeSlots(workOrderId: string): Promise<AppointmentTimeSlot[]> {
        return this.api.getArrayDotNet('WorkOrderPreferredTime', { workOrderId_eq: workOrderId }, () => new AppointmentTimeSlot());
    }

    setWorkOrderAppointmentDate(workOrderId: string, scheduledDate: Date, startWindow: string, endWindow: string): Promise<any> {
        return this.api.patchDotNet('WorkOrder/' + workOrderId, {
            ScheduledDate: UtilitiesService.dayBegin(scheduledDate),
            Status: 'Scheduled',
            ScheduledStartWindow: startWindow,
            ScheduledEndWindow: endWindow,
            WorkOrderStatusId: 'B849F529-6C42-4BAB-9C6E-039BA5D123DC',
        });
    }

    acceptTimeSlot(workOrderId: string, timeSlotId: string) {
        return this.api.patchDotNet(`WorkOrder/${workOrderId}/assign-time-slot/${timeSlotId}`, null);
    }

    saveAuthorization(workOrderId: string, authorizationForm: any, lineItems: AuthorizationLine[], technicianContact: TechnicianContact): Promise<any> {

        const copyOfForm = JSON.parse(JSON.stringify(authorizationForm));
        for (const item of copyOfForm) {
            delete item.childLines;
        }

        return this.api.postSingleDotNet('WorkOrder/' + workOrderId + '/AuthorizationForm', {
            json: JSON.stringify(copyOfForm),
            authorizationItems: lineItems,
            technicianContact
        });
    }

    // getAuthorizationItems(workOrderId: string): Promise<AuthorizationItem[]> {
    //     return this.api.getArray('AuthorizationItem', { workOrderId_eq: workOrderId }, () => new AuthorizationItem());
    // }

    getContractorTechnicians(contractorId: string) {
        return this.api.getArrayDotNet('Entity', {
            parentId_eq: contractorId,
            type_eq: 'Technician',
            orderby: 'Name'
        }, () => new Entity());
    }

    completeWorkOrder(workOrderId: string, resolution: string, dateCompleted: Date): Promise<any> {
        return this.api.patchDotNet('WorkOrder/' + workOrderId, {
            status: 'Complete',
            resolution,
            dateCompleted,
        });
    }

    dispatchWorkOrder(workOrderId: string, sendNotificationToCustomer: boolean, technicianId: string = null): Promise<any> {
        return this.api.patchDotNet('WorkOrder/' + workOrderId, {
            workOrderStatusId: '6D5AF43F-BC84-457A-A5AC-A02F7A7F8210',
            dispatchedDate: new Date(),
            sendCustomerDispatchNotification: sendNotificationToCustomer,
            technicianId,
        });
    }

    // sendOnMyWay(onMyWay: OnMyWayModel): Promise<any> {
    //     return this.api.patch('WorkOrder/' + onMyWay.workOrderId + '/onMyWay', onMyWay);
    // }

    getCoverageLanguage(planItemId: string): Promise<any> {
        return this.planApi.getPlanItemDetail(planItemId);
    }

    updateWorkOrderStatus(workOrderId: string, status: string): Promise<any> {
        return this.api.patchDotNet('WorkOrder/' + workOrderId, {
            status
        });
    }

}