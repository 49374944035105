import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { QueueAction } from '@cogent/client/shared/models/object-queue.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { QuestionWizardModule } from '../question-wizard/question-wizard.module';
import { QuestionWizardComponent } from '../question-wizard/question-wizard.component';

export class TakeQuestionnaireComponentArgs {
    workOrderSummary: WorkOrderSummaryClient;
    action: QueueAction;
}

@Component({
    selector: 'app-take-questionnaire',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        QuestionWizardModule,
        MatButtonModule,
        MatIconModule,
    ],
    templateUrl: './take-questionnaire.component.html',
    styleUrls: ['./take-questionnaire.component.css']
})
export class TakeQuestionnaireComponent implements OnInit {
    isComplete: boolean;
    @ViewChild('questionWizardComponent') questionWizardComponent: QuestionWizardComponent;

    constructor(public dialogRef: MatDialogRef<TakeQuestionnaireComponent>, @Inject(MAT_DIALOG_DATA) public data: TakeQuestionnaireComponentArgs) { }

    ngOnInit() {
    }

    saveQuestionnaire() {
        const questionStack = this.questionWizardComponent.questionStack;
        this.dialogRef.close(questionStack);
    }
}
