import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppliancePartSearchComponent } from './appliance-part-search/appliance-part-search.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';



@NgModule({
  declarations: [
    AppliancePartSearchComponent
  ],
  imports: [
    CommonModule,
    MaterialSharedModule
  ],
  exports: [
    AppliancePartSearchComponent
  ]
})
export class AppliancePartSearchModule { }
