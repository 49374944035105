import { Component, Inject } from '@angular/core';

import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { HttpHeaders } from '@angular/common/http';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export enum PacketExtractionType {
    EXTRACT_ARRAY,
    EXTRACT_FIRST_ITEM,
    NONE
}

export class ApiAccessParameters {
    constructor(
        public url: string = '', public parameters: any = null, public typeCreator: () => any = null, public extractionType: PacketExtractionType = PacketExtractionType.NONE
    ) { }

    surpressApiError: boolean;

    headers: HttpHeaders;
}

@Component({
    templateUrl: 'api-failure.component.html',
    styleUrls: ['api-failure.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class ApiFailureComponent {
    showDetailButton = true;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ApiAccessParameters, public dialog: DialogsService) {
        if (!data) {
            this.showDetailButton = false;
        }
    }

    showErrorDetail() {
        this.dialog.alert('Error', 'URL: ' + this.data.url);
    }
}
