<div id="outside-container">
    <div id="schedule-container" [style.width]="containerWidth">
        <div id="time-slot-container">

            <div class="name-container"></div>
            <div *ngFor="let timeSlot of timeSlots" class="time-slot-header time-slot-item">
                <span class="time-slot-item-display">
                    {{timeSlot.display}}
                </span>
            </div>
        </div>
        <div id="body-container">
            <div id="grid-lines">
                <div class="name-container"></div>
                <div *ngFor="let timeSlot of timeSlots" class="time-slot-item time-slot-grid-item">
                </div>
            </div>
            <div *ngFor="let group of groups" class="group-container" [style.minHeight]="getContainerHeight(group)">
                <div class="name-container"> <img mat-list-avatar class="avatar"
                        [src]="getTechThumbnail(group.technician)">{{group.technician.name}}</div>
                <div class="jobs-container">


                    <div *ngFor="let job of group.jobs" routerLink="/work-order-detail/{{job.id}}" [class.highlighted]="highlightedAppointment && highlightedAppointment.id === job.id" class="job" [style.top]="getTop(job, group)"
                        [style.width]="getWidth(job)" [style.left]="getLeft(job)">
                        {{job.propertyAddress}} 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>