import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class RealEstatePortalResource {
    @keyColumn() id: string;
    @column() documentName: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() fileExtension: string;
    @column() sort: number;
}