import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderItemType {
    @keyColumn() id: string;
    @column() workOrderItemId: string;
    @column() name: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() sort: number;
    @column() cashOutThresholdAmount?: number;
    @column() coveredType: string;
    @column() salesItemCoverageIds: string;


    private _salesItemCoverages: string[];
    get salesItemCoverages(): string[] {
        if (!this._salesItemCoverages) {
            try {
                this._salesItemCoverages = JSON.parse(this.salesItemCoverageIds);
            } catch { }
        }
        if (!this._salesItemCoverages) {
            this._salesItemCoverages = [];
        }

        return this._salesItemCoverages;
    }


    isNew: boolean;
    isEdited: boolean;
}