import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MarketingSource {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() promptForAgent: boolean;
    @column() allowManualPick: boolean;
    @column() internalHMS: boolean;
    @column() customerPortalHMS: boolean;

    deleting = false;
}