import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderSummaryCached {
    @keyColumn() id: string;
    @column() number: bigint;
    @column() qandA: string;
    @column() itemName: string;
    @column() propertyAddress: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() homeownerName: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactEmail: string;
    @column() contactMobile: string;
    @column() contractorEmail: string;
    @column() additionalNotes: string;
    @column() contractorName: string;
    @column() contractorPhone: string;
    @column() policyId: string;
    @column() contractorId: string;
    @column() dateClosed: Date;
    @column() status: string;
    @column() createdDate?: Date;
    @column() authorizationLimit: number;
    @column() itemId: string;
    @column() type: string;
    @column() scheduledDate: Date;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;
    @column() dateCompleted: Date;
    @column() dispatchedDate: Date;
    @column() sentDate: Date;
    @column() propertyId: string;
    @column() holderId: string;
    @column() planItemId: string;
    @column() cancelledDate: Date;
    @column() tradeId: string;
    @column() claimId: string;
    @column() sendEmail: string;
    @column() tradeName: string;
    @column() createdById?: string;
    @column() createdByName: string;
    @column() policyAddressId: string;
    @column() policyNumber: bigint;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() workPerformed: string;
    @column() regionId: string;
    @column() stateId: string;
    @column() statusDate: Date;
    @column() workOrderStatusId: string;
    @column() homeownerEmail: string;
    @column() createdByEmail: string;
    @column() contractorStatus: string;
    @column() customerStatus: string;
    @column() marketAreaName: string;
    @column() marketStateName: string;
    @column() contractorPreferredContactMethod: string;
    @column() itemPriority: number;
    @column() sLABasedFromTime: Date;
    @column() sLAStatusRedHours: number;
    @column() sLAStatusYellowHours: number;
    @column() sLAHoursType: string;
    @column() timeZoneId: string;
    @column() loginId: string;
    @column() technicianId: string;
    @column() onMyWayNotificationSentDate: Date;
    @column() resolution: string;
    @column() areaId: string;
    @column() isBilledClaim: number;
    @column() isClaim: number;
    @column() jobCount: number;
    @column() reportDate: Date;
    @column() surveyPossible: number;
    @column() surveyScore: number;
    @column() updateCount: number;
    @column() propertyManagerId: string;
    @column() sLARedTime: Date;
    @column() sLAYellowTime: Date;
    @column() sLANoneTime: Date;
    @column() sLAStatus: string;
    @column() minutesToNone: number;
    @column() minutesToYellow: number;
    @column() minutesToRed: number;
    @column() reportYear: number;
    @column() reportMonth: string;
    @column() recordCount: number;
}