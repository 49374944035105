import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { InvoiceSummary } from "./invoice-summary.model";

@dataSource()
export class CashOutAppliedToInvoice {
    @keyColumn() id: string;
    @column() cashOutId: string;
    @column() invoiceId: string;
    @column() appliedAmount: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;



    // client-side only
    selected: boolean;
    invoiceSummary: InvoiceSummary;

    get isValid() {
        if (this.appliedAmount < 0) {
            return false;
        }
        if (this.invoiceSummary && this.appliedAmount > this.invoiceSummary.amountDue) {
            return false;
        }
        return true;
    }

    get isSelectedAndValid() {
        if(!this.isValid) {
            return false;
        }

        return this.selected;
    }
    set isSelectedAndValid(value: boolean) {
        this.selected = value;
    }
}