
<div class="plan-offering-container">
    <div class="plan-offering-grid" id="main-plan-offering-grid" [style.grid-template-columns]="getColumns()"
        [style.max-width]="getMaxWidth()">
        <div class="information-info" *ngIf="!newConfig">
            <div>
                <h2>Your Personalize Home Warranty Pricing</h2>
                <p style="line-height: 20px;">Join 12,000+ current homeowners that have elevated their lives!</p>
                <div class="plan-toggle {{paymentType == 'monthly' ? 'monthly' : 'yearly'}}">
                    <span class="{{paymentType == 'monthly' ? 'selected' : ''}}" (click)="paymentType = 'monthly'">Pay
                        By Month</span>
                    <span class="{{paymentType == 'yearly' ? 'selected' : ''}}" (click)="paymentType = 'yearly'">Pay By
                        Year</span>
                </div>
            </div>
            <h3>Compare Plans</h3>
        </div>
        <div class="information" [class.info-adjust]="newConfig" style="">
            <!--            <div class="plan-info mobile-view-item"></div>-->
            <div class="plan-category" *ngFor="let category of planGrid.categories">
                <h5 class="plan-category-header">{{category.categoryName == 'Heating/Cooling Systems' ? 'Heating and
                    Cooling Systems' : category.categoryName}}:</h5>
                <div class="plan-category-item" *ngFor="let item of category.items">
                    <p style="cursor: pointer" (click)="showCoverage(item)">{{item.salesItemName}}</p>
                </div>
            </div>
            <div class="category-footer desktop-view-item">
                Service Fee
            </div>
        </div>
        <div class="mobile-view-item plan-card-container" *ngIf="!newConfig">
            <div class="plan-card" *ngFor="let plan of planGrid.plans; index as i">
                <div class="plan-header">
                    <div class="plan-info" [class.new-app]="newApp">
                        <header>
                            <h2 class="plan-name">{{plan.name}}</h2>
                            @if(!newConfig){
                                <h1>{{newConfig}}</h1>
                            @if(plan.name == 'Essential') {
                            <p>Our most affordable plan is perfect for safeguarding your home's basic systems.</p>
                            } @else if (plan.name == 'Elevated') {
                            <p>Elevated coverages and advanced protection for your home’s vital systems and appliances.
                            </p>
                            } @else if (plan.name == 'Totally Elevated') {
                            <p>Our highest level of coverage and most popular plan ensures your comfort and security.
                            </p>
                            }
                            }
                        </header>
                        <hr class="plan-name-underline">
                        <p>Starting At: </p>
                        <h5 class="plan-price"><span>{{displayPrice((paymentType == 'monthly' ? plan.priceRecurring :
                                plan.price) | currency)}}</span><br> {{paymentType == 'monthly' ? ' / Month' : ' /
                            Year'}}</h5>
                    </div>
                </div>

                <div class="plan-select-button-container">
                    <button class="plan-select-button" id="plan-card-{{i}}" (click)="planSelected(plan)">
                        
                        Select <mat-icon class="plan-select-button-icon">navigate_next</mat-icon></button>
                </div>
            </div>
        </div>
        <div class="plan-breakdown-header mobile-view-item">
            <h2 class="mobile-view-item">Plan Breakdown</h2>
            <hr class="plan-name-underline">
        </div>
        <div class="plan" *ngFor="let plan of planGrid.plans; index as i">
            <div class="slanted-header hide-over-second-breakpoint">
                <h2 class="plan-name">{{plan.name}}</h2>
            </div>
            <div class="plan-info plan-header-info hide-under-second-breakpoint" [class.new-app]="newApp">
                <header>
                    <h2 class="plan-name desktop-view-item">{{plan.name}}</h2>
                </header>
                <h2 class="plan-name mobile-view-item">{{plan.name}}</h2>
                @if(!newConfig){
                @if(plan.name == 'Essential') {
                <p class="desktop-view-item">Our most affordable plan is perfect for safeguarding your home's basic
                    systems.</p>
                } @else if (plan.name == 'Elevated') {
                <p class="desktop-view-item">Elevated coverages and advanced protection for your home’s vital systems
                    and appliances.</p>
                } @else if (plan.name == 'Totally Elevated') {
                <p class="desktop-view-item">Our highest level of coverage and most popular plan ensures your comfort
                    and security.</p>
                }}
                <hr class="plan-name-underline hide-under-second-breakpoint">
                <h4 class="desktop-view-item">Starting At: </h4>
                <h5 class="plan-price desktop-view-item"><span>{{displayPrice((paymentType == 'monthly' ?
                        plan.priceRecurring : plan.price) | currency)}}</span> {{paymentType == 'monthly' ? ' / Month' :
                    ' / Year'}}</h5>
                <button class="plan-select-button desktop-view-item" id="plan-{{i}}" (click)="planSelected(plan)">Select
                    This Plan <mat-icon class="plan-select-button-icon">navigate_next</mat-icon></button>
            </div>
            <div class="plan-category" *ngFor="let category of planGrid.categories; index as ii">
                <h5 class="plan-category-header"><span class="desktop-view-item">Coverage</span></h5>
                <div class="plan-category-item" *ngFor="let item of category.items">
                    <div>
                        @if (item.planAvailability[i].available && item.planAvailability[i].limitPerOccurence &&
                        category.categoryName != 'Services') {
                        @if(item.planAvailability[i].limitPerOccurence) {
                        <span>{{(item.planAvailability[i].limitPerOccurence | currency)?.split('.')[0]}}</span>
                        } @else {
                        <mat-icon
                            class="{{item.planAvailability[i].available ? 'covered' : ''}} plan-category-item-icon">check_circle</mat-icon>
                        <br>

                        }
                        } @else {
                        <mat-icon
                            class="{{item.planAvailability[i].available ? 'covered' : ''}} plan-category-item-icon">{{item.planAvailability[i].available
                            ? 'check_circle' : 'cancel'}}</mat-icon>
                        }
                    </div>
                </div>
            </div>
            <div class="plan-footer">
                <p>${{plan.serviceFee}} / per service</p>
                <button class="plan-select-button" (click)="planSelected(plan)">Select <mat-icon
                        class="plan-select-button-icon">navigate_next</mat-icon></button>
                <a target="_blank" mat-menu-item [href]="getSampleContractUrl(plan)">Sample Contract</a>
            </div>
        </div>
    </div>
    <div class="quote-coverage-container" *ngIf="!newConfig">
        <div class="quote-coverage-image-container">
            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/comprehensive-coverage2.jpg">
        </div>
        <div class="quote-coverage-information">
            <h3>We understand that investing in a Homescription is a big decision.</h3>
            <p>But with a Homescription, you're not just purchasing a service; you're gaining peace of mind and
                simplifying homeownership in ways you never imagined. Say goodbye to the stress of unexpected repairs
                and hello to a seamless, worry-free experience.</p>
            <p>Discover why a Homescription is worth every penny – click below to learn more.</p>
            <div style="text-align: center">
                <a mat-raised-button mat-button color="primary" href="https://elevatehomescriptions.com/"
                    class="build-quote-button">Learn More <mat-icon iconPositionEnd
                        class="plan-select-button-icon">navigate_next</mat-icon></a>
                <p class="quote-coverage-phone">or call us at 855-349-2388</p>
            </div>
        </div>
    </div>
</div>