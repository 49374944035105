import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

@Component({
    templateUrl: './date-picker-wrapper.component.html',
    styleUrls: ['./date-picker-wrapper.component.css'],
    selector: 'app-date-picker',
    standalone: true,
    imports: [CommonModule, FormsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule]
})
export class DatePickerWrapperComponent {
    @Input() placeholder: string;
    @Input() date: Date;
    @Output() dateChange: EventEmitter<Date>;
    @Input() disabled: boolean;
    @Input() outline = true;
    @Input() min: Date;
    @Input() max: Date;

    constructor() {
        this.dateChange = new EventEmitter();
    }

    dateUpdated() {
        this.dateChange.emit(this.date);
    }
}
