import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TermsAndLimitationItem {
    @keyColumn() id: string;
    @column() termsAndLimitationId: string;
    @column() heading: string;
    @column() text: string;
    @column() sortOrder: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() isItemsPlaceholder: boolean;
    @column() isOptionsPlaceholder: boolean;
    @column() isWarrantyCoveredItems: boolean;
    @column() isOnDemandServiceItems: boolean;
    @column() isBuyerCoverage: boolean;
    @column() isSellerCoverage: boolean;
    @column() isNewConstructionCoverage: boolean;
    @column() isHomeownerCoverage: boolean;
    @column() isRenewalCoverage: boolean;
    @column() tag: string;

    // client-side only
    dragging: boolean;
}