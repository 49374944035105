<div style="width: 800px; max-width: 100%">
    <div flex>
        <div>
            <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="sub-heading">Edit Tags</h2>
        </div>
        <div nogrow>
            <button mat-button [mat-dialog-close]>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div mat-dialog-content style="overflow-x: hidden;">
        <app-tag-chip-selection [viewTaskTags]="viewTaskTags" [viewWorkOrderTags]="viewWorkOrderTags"
            [viewPolicyTags]="viewPolicyTags" [viewEntityTags]="viewEntityTags"
            [viewContractorInvoiceTags]="viewContractorInvoiceTags" [viewPurchaseOrderTags]="viewPurchaseOrderTags"
            [viewInvoiceTags]="viewInvoiceTags"
            [viewMaintenanceServiceTags]="viewMaintenanceServiceTags"
            placeholders="Tags" [selectedTags]="tags"></app-tag-chip-selection>
    </div>


    <div mat-dialog-actions flex>
        <div></div>
        <div nogrow>
            <button mat-button color="primary" (click)="save()" mat-raised-button [disabled]="saving">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Save
            </button>
        </div>
    </div>
</div>