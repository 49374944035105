import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { Entity } from '@upkeeplabs/models/cogent';

export interface EntitySelector {
    id: string;
    name: string;
    parentId?: string;
    selected?: boolean;
    children?: EntitySelector[];
}

@Component({
    standalone: true,
    selector: 'service-pro-territory-select',
    imports: [MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule, MatIconModule, MatCheckboxModule, CommonModule],
    templateUrl: 'service-pro-territory-select.component.html',
    styleUrls: ['service-pro-territory-select.component.css']
})
export class ServiceProTerritorySelectComponent {

    async ngOnInit(){
        await this.loadServiceProManagerAreasWithChildren();
    }
    
    showDropDown = false;
    areas: EntitySelector[];
    #regions: EntitySelector[];
    #territories: EntitySelector[];

    @Output() selectedTerritoriesChange: EventEmitter<EntitySelector[]> = new EventEmitter();
    #selectedTerritories: EntitySelector[];
    get selectedTerritories() {
        return this.#selectedTerritories;
    }
    @Input() set selectedTerritories(territories) {
        let existingIds = "[]";
        if (this.#selectedTerritories) {
            existingIds = JSON.stringify(this.#selectedTerritories.map(t => t.id).sort((a, b) => a < b ? -1 : 1));
        }
        this.#selectedTerritories = territories;
        let newIds = "[]";
        if (this.#selectedTerritories) {
            newIds = JSON.stringify(this.#selectedTerritories.map(t => t.id).sort((a, b) => a < b ? -1 : 1));
        }
        if (existingIds != newIds) {
            this.selectedTerritoriesChange.emit(this.#selectedTerritories);
        }
    }




    constructor(private entityApi: EntityApiService) {
    }

    async loadServiceProManagerAreasWithChildren() {
        this.areas = await this.entityApi.getEntitiesByType("ServiceProManagerArea");
        this.#regions = await this.entityApi.getEntitiesByType("ServiceProRegion");
        this.#territories = await this.entityApi.getEntitiesByType("ServiceProTerritory");

        for (const area of this.areas) {
            area.children = this.#regions.filter(r => r.parentId === area.id);
        }

        for (const region of this.#regions) {
            region.children = this.#territories.filter(t => t.parentId === region.id);
        }

        for (const territory of this.#territories) {
            territory.selected = false;
        }
        if (this.#selectedTerritories) {
            for (const territory of this.#selectedTerritories) {
                const match = this.#territories.find(t => t.id === territory.id);
                match.selected = true;
            }
        }

        this.updateAreasAndRegionsSelected();
    }

    selectChildren(entity: EntitySelector, selected: boolean) {
        if (entity.children) {
            for (const child of entity.children) {
                child.selected = selected;
                if (child.children) {
                    for (const child2 of child.children) {
                        child2.selected = selected;
                    }
                }
            }
        }

        this.updateAreasAndRegionsSelected();
    }

    selectedName: string;
    updateAreasAndRegionsSelected() {
        for (const region of this.#regions) {
            region.selected = false;
            if (region.children && region.children.length > 0) {
                const selectedCount = region.children.filter(t => t.selected).length;
                if (selectedCount == region.children.length) {
                    region.selected = true;
                }
            }
        }

        for (const area of this.areas) {
            area.selected = false;
            if (area.children) {
                const selectedCount = area.children.filter(t => t.selected).length;
                if (selectedCount == area.children.length) {
                    area.selected = true;
                }
            }
        }
        let name = "";
        for (const area of this.areas) {
            if (area.selected) {
                if (name.length > 0) { name += ", "; }
                name += area.name;
            }
            else {
                for (const region of area.children) {
                    if (region.selected) {
                        if (name.length > 0) { name += ", "; }
                        name += region.name;
                    } else {
                        for (const territory of region.children) {
                            if (territory.selected) {
                                if (name.length > 0) { name += ", "; }
                                name += territory.name;
                            }
                        }
                    }
                }
            }
            this.selectedName = name;
        }
        this.selectedTerritories = this.#territories.filter(t => t.selected);

    }

}