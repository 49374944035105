import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, HostListener, Input, IterableDiffer, IterableDiffers, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { GroupedProductOfferings, MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceOfferingAddOn, PolicyServiceOffering } from '@upkeeplabs/models/cogent';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/plans-and-coverage/view-coverage/view-coverage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

export class GroupedWorkOrderItemModel {
    id = UtilitiesService.newid();
    isLastRow = false;
    index: number;
    isRight = false;

    constructor(public category: string,
        public workOrderItems: PolicyServiceOffering[],
        public maintenanceServiceCustomerPropertyServiceSummary: MaintenanceServiceCustomerPropertyServiceSummary = null,
        public maintOfferings: GroupedProductOfferings[] = null) {


        this.summarizedWorkOrderItems = [];
        if (workOrderItems) {
            for (let i = 0; i < 4; i++) {
                if (workOrderItems[i]) {
                    this.summarizedWorkOrderItems.push(workOrderItems[i]);
                }
            }
            this.hasMoreItems = workOrderItems.length > 4;
        }

    }

    summarizedWorkOrderItems: PolicyServiceOffering[];
    hasMoreItems: boolean;
    itemsExpanded: boolean;
    color: string;
    overrideLottieUrl: string;


    get lottieUrl() {
        if (this.overrideLottieUrl) {
            return this.overrideLottieUrl;
        }
        return UtilitiesService.getCategoryLottieUrl(this.category);
    }


    get iconUrl(): string {
        if (this.maintenanceServiceCustomerPropertyServiceSummary) {
            return `${ApiService.endPointDotNet}WorkOrderItem/${this.maintenanceServiceCustomerPropertyServiceSummary.maintenanceServiceOfferingId}/Photo`;
        }
        return UtilitiesService.getCategoryUrl(this.category);
    }

    get getBgUrl(): string {
        if (this.maintenanceServiceCustomerPropertyServiceSummary) {
            return `${ApiService.endPointDotNet}WorkOrderItem/${this.maintenanceServiceCustomerPropertyServiceSummary.maintenanceServiceOfferingId}/Photo`;
        }
        return UtilitiesService.getCategoryBgUrl(this.category);
    }

    get sampleList() {
        if (this.maintOfferings) {
            return this.maintOfferings.map(i => i.maintenanceServiceName).join(',');
        }
        if (this.maintenanceServiceCustomerPropertyServiceSummary) {
            return this.maintenanceServiceCustomerPropertyServiceSummary.shortSummary;
        }
        return this.workOrderItems?.map(i => i.name).join(', ');
    }

    static colorArray = [
        '#32a852',
        '#32a8a2',
        '#325fa8',
        '#6819a8',
        '#9a19a8',
        '#a8193d',
        '#a85e19'
    ];

    public static fromWorkOrderItems(workOrderItems: PolicyServiceOffering[]): GroupedWorkOrderItemModel[] {
        const results: GroupedWorkOrderItemModel[] = [];



        workOrderItems = workOrderItems.sort((a, b) => a.categorySort - b.categorySort);
        let index = 0;
        workOrderItems.forEach(workOrderItem => {
            let result = results.filter(i => i.category === workOrderItem.category)[0];
            if (!result) {
                result = new GroupedWorkOrderItemModel(workOrderItem.category,
                    workOrderItems.filter(i => i.category === workOrderItem.category).sort((a, b) => a.itemSort - b.itemSort));
                result.color = this.colorArray[index];
                index++;
                results.push(result);
            }
        });

        return results;
    }
}

class GroupOfGroups {
    id = UtilitiesService.newid();
    items: GroupedWorkOrderItemModel[];

    selectedItem: GroupedWorkOrderItemModel;
    groupMax: number;
}


@Component({
    selector: 'app-job-item-selection',
    standalone: true,
    imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatIconModule, MatButtonModule],
    templateUrl: './job-item-selection.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    styleUrls: ['./job-item-selection.component.css']
})
export class JobItemSelectionComponent implements OnChanges {

    instanceId = UtilitiesService.newid();

    constructor(private dialog: MatDialog,
        private policyApi: PolicyApiService,
        private iterableDiffers: IterableDiffers) {


    }
    ngDoCheck(): void {
        if (this.diffs) {
            const changes = this.diffs.diff(this.maintOfferings);

            changes?.forEachRemovedItem(record => {
                this.setupGroups();
            });
        }
    }

    diffs: IterableDiffer<GroupedProductOfferings>;
    groupedItems: GroupedWorkOrderItemModel[];
    groups: GroupOfGroups[];
    maintenanceGroupedItems: GroupedWorkOrderItemModel[] = [];
    warrantyGroupedItems: GroupedWorkOrderItemModel[] = [];

    @Input() workOrderItems: PolicyServiceOffering[];
    @Input() hideHeader: boolean;
    @Input() availableMaintenanceServices: GroupedProductOfferings[];
    @Input() maintenanceServiceCustomerPropertyServiceSummaries: MaintenanceServiceCustomerPropertyServiceSummary[] = [];
    @Input() showAddMaintServicesButton: boolean;
    @Output() maintenanceServiceSpecialRequest: EventEmitter<MaintenanceServiceCustomerPropertyServiceSummary> = new EventEmitter();
    @Output() addMaintServiceClicked: EventEmitter<boolean> = new EventEmitter();
    @Output() addOnInvoked: EventEmitter<any> = new EventEmitter();
    @Input() maintOfferings: GroupedProductOfferings[];
    @Input() selectedItem: PolicyServiceOffering;
    @Output() selectedItemChange: EventEmitter<PolicyServiceOffering> = new EventEmitter();
    @Input() policyId: string;
    @Input() condensedMode = false;

    filteredItems: PolicyServiceOffering[];
    filter = '';
    selectedGroupIndex = 0;
    detailShown = false;
    selectedGroupItem: GroupedWorkOrderItemModel;
    selectedMaintOffering: GroupedProductOfferings;

    expandItem(groupedItem: GroupedWorkOrderItemModel) {
        groupedItem.itemsExpanded = true;
        return;
    }

    getContractorItem(maintItem: MaintenanceServiceCustomerPropertyServiceSummary) {
        return `${ApiService.endPointDotNet}entity/Photo/${maintItem.contractorId}/`;
    }

    getAddOnPicture(addOn: any) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${addOn.id}/Photo`;
    }

    getMaintPicture(maintOffering: GroupedProductOfferings) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${maintOffering.maintenanceServiceOfferingId}/Photo`;
    }

    customMaintenanceServiceRequest(maintItem: MaintenanceServiceCustomerPropertyServiceSummary) {
        this.maintenanceServiceSpecialRequest.emit(maintItem);
    }

    invokeAddOn(addOn: MaintenanceServiceOfferingAddOn, maintItem: MaintenanceServiceCustomerPropertyServiceSummary) {
        this.addOnInvoked.emit({ addOn, maintItem });
    }


    showGroup(group: GroupOfGroups, category: GroupedWorkOrderItemModel) {

        const isSame = group.selectedItem === category;

        for (const item of this.groups) {
            item.selectedItem = null;
            for (const groupItem of item.items) {
                const l: any = document.getElementById(groupItem.id);
                if (l) {
                    try {
                        l.stop();
                        l.hover = true;
                        l.autoplay = false;
                    } catch { }
                }
            }
        }
        if (isSame) {
            for (const item of this.groups) {
                for (const groupItem of item.items) {
                    const l: any = document.getElementById(groupItem.id);
                    if (l) {
                        l.hover = true;
                        l.autoplay = false;
                        l.stop();
                    }
                }
            }
            return;
        }

        setTimeout(() => {
            group.selectedItem = category;
            const lottie: any = document.getElementById(category.id);
            if (lottie) {
                lottie.play();
                lottie.hover = false;
                lottie.autoplay = true;
            }
            setTimeout(() => {
                const el = document.getElementById(group.id);
                if (el && el.offsetTop > window.innerHeight - 275) {
                    window.scrollTo({
                        top: el.offsetTop - 240,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            }, 600)
        });
    }

    get groupSize() {
        const container = document.getElementById(this.instanceId);

        return container?.clientWidth >= 950 ? 4 : container?.clientWidth >= 600 ? 3 : 2;
    }

    setFilter(value) {
        this.filter = value;
        this.updateFilteredItems(value);
    }

    clearFilter() {
        this.filter = '';
        this.updateFilteredItems('');

    }

    updateFilteredItems(value) {
        if (!value) {
            this.filteredItems = this.workOrderItems;
            return;
        }
        this.filteredItems = this.workOrderItems.filter(i => {
            return i.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                || i.category.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
    }

    get selectedGroup() {
        if (!this.groupedItems) {
            return null;
        }

        return this.groupedItems[this.selectedGroupIndex];
    }

    @HostListener('window:hashchange', ['$event'])
    watchUrlHash(event) {
        const hash = window.location.hash;

        if (hash !== '#ItemListSelection') {
            this.detailShown = false;
        }
    }

    setExpansion(item: GroupedWorkOrderItemModel, icon) {
        const nativeElement = icon._elementRef.nativeElement;
        this.animateTheIcon(nativeElement);
        setTimeout(() => (item as any).expanding = true, 10);
        setTimeout(() => {
            (item as any).expanding = false;
            window.location.hash = 'ItemListSelection';
            // this.selectedGroup = item;
        }, 400);

    }

    getItemUrl(item: PolicyServiceOffering) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${item.id}/photo`;
    }

    private cumulativeOffset(element) {
        let top = 0;
        let left = 0;
        do {
            top += element.offsetTop || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while (element);

        return { top, left };
    }

    private animateTheIcon(elementToCheck) {
        if (elementToCheck.classList && elementToCheck.classList.contains('category-icon')) {
            elementToCheck.classList.toggle('expanding');
            elementToCheck.style.position = 'fixed';
            elementToCheck.style.top = this.cumulativeOffset(elementToCheck).top + 'px';
            elementToCheck.style.left = '0%';
            setTimeout(() => {
                elementToCheck.classList.toggle('expanding');
                elementToCheck.style.top = '';
                elementToCheck.style.left = '';
                elementToCheck.style.position = 'absolute';
            }, 400);
        } else {
            elementToCheck.childNodes.forEach(item => this.animateTheIcon(item));
        }
    }

    selectWorkOrderItem(workOrderItem: PolicyServiceOffering) {
        this.selectedItemChange.emit(workOrderItem);
        setTimeout(() => this.detailShown = false, 500);
    }

    showContractLanguage(item: PolicyServiceOffering) {
        this.dialog.open(ViewCoverageDialogComponent, { data: item });
    }


    getBackgroundImage(groupedItem: GroupedWorkOrderItemModel) {
        return `url(${groupedItem.getBgUrl})`;
    }

    getMaintServiceBackgroundImage(service: MaintenanceServiceCustomerPropertyServiceSummary) {
        return `url(${ApiService.endPointDotNet}WorkorderItem/${service.maintenanceServiceOfferingId}/Photo)`;
    }

    private setupGroups() {
        if (!this.maintenanceServiceCustomerPropertyServiceSummaries) {
            this.maintenanceServiceCustomerPropertyServiceSummaries = [];
        }
        if (!this.workOrderItems) {
            return;
        }

        this.warrantyGroupedItems = GroupedWorkOrderItemModel.fromWorkOrderItems(this.workOrderItems);
        this.filter = '';
        this.maintenanceGroupedItems = [];


        this.groupedItems = this.maintenanceGroupedItems.concat(this.warrantyGroupedItems);
        for (const item of this.maintenanceServiceCustomerPropertyServiceSummaries) {
            const maintItem: MaintenanceServiceCustomerPropertyServiceSummary = item;
            const groupedItem = new GroupedWorkOrderItemModel(maintItem.name, null, item);
            groupedItem.overrideLottieUrl = item.lottieAnimationUrl;
            this.maintenanceGroupedItems.push(groupedItem);
            groupedItem.color = GroupedWorkOrderItemModel.colorArray[this.maintenanceGroupedItems.indexOf(groupedItem)];
        }

        this.groupedItems = this.maintenanceGroupedItems.concat(this.warrantyGroupedItems);
        if (this.maintOfferings) {
            const maintOfferingGroup = new GroupedWorkOrderItemModel('Recurring Maintenance', null, null, this.maintOfferings);
            if (this.maintOfferings.length > 0) {
                this.groupedItems.unshift(maintOfferingGroup);
            }
        }
        this.setupGroupOfGroups();
    }

    maintService(item) {
        // TODO: implement this
    }

    setupGroupOfGroups() {
        let selectedCategory = null;
        if (this.groups) {
            for (const group of this.groups) {
                if (group.selectedItem) {
                    selectedCategory = group.selectedItem;
                }
            }
        }

        this.groups = [];
        let currentGroup = new GroupOfGroups();
        currentGroup.items = [];

        this.groups.push(currentGroup);


        currentGroup.groupMax = this.groupSize;
        for (const item of this.groupedItems) {

            if (currentGroup.items.length === this.groupSize) {
                currentGroup = new GroupOfGroups();
                currentGroup.groupMax = this.groupSize;
                currentGroup.items = [];
                this.groups.push(currentGroup);
            }
            currentGroup.items.push(item);
        }
        for (const group of this.groups) {
            const lastRow = group === this.groups[this.groups.length - 1];
            for (const item of group.items) {
                item.isLastRow = lastRow;
                const idx = group.items.indexOf(item) + 1;
                item.index = idx;
                item.isRight = idx % this.groupSize === 0;
            }
        }
        for (const group of this.groups) {
            for (const item of group.items) {
                if (item === selectedCategory) {
                    this.showGroup(group, item);
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.workOrderItems && changes.workOrderItems) {
            this.setupGroups();
        }
        if (changes.maintenanceServiceCustomerPropertyServiceSummaries && changes.maintenanceServiceCustomerPropertyServiceSummaries.currentValue) {
            this.setupGroups();
        }
        if (changes.maintOfferings && changes.maintOfferings.currentValue) {
            this.diffs = this.iterableDiffers.find(this.maintOfferings).create();
            for (const item of this.maintOfferings) {
                (item as any).id = item.maintenanceServiceOfferingId;
            }
            this.setupGroups();
        }
        if (changes.policyId && changes.policyId.currentValue) {
            this.policyApi.getPolicyWorkOrderItems2(changes.policyId.currentValue).then(items => {
                this.workOrderItems = items as any;
                this.groupedItems = GroupedWorkOrderItemModel.fromWorkOrderItems(this.workOrderItems);
                this.setupGroups();
                this.filter = '';
            });
        }
    }

    sizeTimeout;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        clearTimeout(this.sizeTimeout);
        this.sizeTimeout = setTimeout(() => this.setupGroupOfGroups(), 500);
    }
}
