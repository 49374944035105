import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class OutgoingAutomatedPhoneMessage {
    @keyColumn() id: string;
    @column() message: string;
    @column() phoneNumber: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}