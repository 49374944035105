import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceCancellationMetric {
    @column() reportDate: Date;
    @column() month: number;
    @column() year: number;
    @column() quarter: number;
    @column() day: number;
    @column() monthName: string;
    @column() monthYear: string;
    @column() yearMonthNumAndName: string;
    @column() activeCount: number;
    @column() cancelledCount: number;

    get cancellationRate() {
        if (this.activeCount === 0) {
            return null;
        }

        return this.cancelledCount / this.activeCount;
    }

    get cancellationRate100() {
        return Math.round(this.cancellationRate * 100);
    }
}