<div style="min-width: 300px;">
    <div aflex>
        <div>
            <h2 class="sub-heading">Change Password</h2>
        </div>
        <div nogrow>

            <button mat-button [mat-dialog-close] tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div topmargin30>
        <mat-form-field appearance="outline">
            <mat-label>Current Password</mat-label>
            <input matInput [(ngModel)]="oldPassword" type="password">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput [(ngModel)]="newPassword" type="password">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Confirm New Password</mat-label>
            <input matInput [(ngModel)]="newPasswordConfirm" type="password">
        </mat-form-field>
    </div>

    <div aflex>
        <div></div>
        <div nogrow>

            <button mat-button mat-raised-button color="primary" [disabled]="changingPassword || !canChangePassword"
                (click)="changePassword()">
                <mat-spinner *ngIf="changingPassword" class="thirty"></mat-spinner>
                Change Password
            </button>
        </div>
    </div>

</div>