<div class="main-tool">
    <div aflex>
        <div></div>
        <div nogrow style="margin-left: 0;">
            <button mat-button class="toggle-button" [class.selected]="selectedIndex === 0 && isHome"
                (click)="raiseClicked(0);">
                <div>
                    <mat-icon>schedule</mat-icon><br>
                    Pending
                    <span *ngIf="pendingCount" class="badge pending-badge">{{pendingCount}}</span>
                </div>
            </button>
            <button mat-button class="toggle-button" [class.selected]="selectedIndex === 1 && isHome"
                (click)="raiseClicked(1);">
                <div>
                <mat-icon>account_circle</mat-icon><br>
                Customers</div>
            </button>
            <button mat-button class="toggle-button" [class.selected]="selectedIndex === 2 && isHome"
                (click)="raiseClicked(2);">
                <div>
                <mat-icon>home</mat-icon><br>
                Home</div>
            </button>
            <button mat-button class="toggle-button" [class.selected]="selectedIndex === 3 && isHome"
                (click)="raiseClicked(3);">
                <div>
                <mat-icon>today</mat-icon><br>
                Upcoming</div>
            </button>

            <button mat-button [matMenuTriggerFor]="menu">
                <div>
                <mat-icon>more_horiz</mat-icon><br>
                More</div>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/maintenance-service-billing">
                    <mat-icon>paid</mat-icon>
                    Billing
                </button>
                <button mat-menu-item routerLink="/maintenance-service-report-dashboard">
                    <mat-icon>find_in_page</mat-icon>
                    Reports
                </button>
                <a routerLink="/maintenance-service-cancellations" mat-menu-item>
                    <mat-icon>event_busy</mat-icon>
                    Recent Cancellations
                </a>
                <a routerLink="/maintenance-service-calendar" mat-menu-item>
                    <mat-icon>calendar_month</mat-icon>
                    Calendar
                </a>
                <a routerLink="/settings" mat-menu-item>
                    <mat-icon>settings</mat-icon>
                    Settings
                </a>
                <!-- <button mat-menu-item (click)="changeMaintVisible()" *ngIf="canToggle">
                    <mat-icon>swap_calls</mat-icon>
                    Switch To Warranty View
                </button> -->
                <a mat-menu-item routerLink="/auth/log-out">
                    <mat-icon>lock</mat-icon> Log Out
                </a>
            </mat-menu>
        </div>
        <div style="margin-left: 0;">

        </div>

    </div>


</div>