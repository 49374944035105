import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';


@Component({
    selector: 'app-phone-link',
    templateUrl: './phone-link.component.html',
    styleUrls: ['./phone-link.component.css']
})
export class PhoneLinkComponent implements OnInit {

    @Input() phoneNumber: string;
    @Input() phoneNumberDescription: string;
    @Input() icon: string;
    @Input() alwaysShowNumber = false;
    @Output() dialed: EventEmitter<string> = new EventEmitter();
    @Input() autoDial: boolean;

    constructor(private dialogService: DialogsService,
        private missionService: MissionService) { }

    ngOnInit() {
    }

    get display() {
        return this.phoneNumberDescription && !this.alwaysShowNumber ? this.phoneNumberDescription : UtilitiesService.formatPhoneNumber(this.phoneNumber);
    }

    dialNumber() {
        const formattedNumber = UtilitiesService.formatPhoneNumber(this.phoneNumber);
        if (this.autoDial) {
            this.doDial();
        } else {
            this.dialogService.confirm('Dial', 'Dial: ' + formattedNumber).subscribe(result => {
                if (result) {
                    this.doDial()
                }
            });
        }
    }

    private doDial() {
        const formattedNumber = UtilitiesService.formatPhoneNumber(this.phoneNumber);
        this.dialed.emit(formattedNumber);
        this.missionService.dialPhoneNumber(formattedNumber);
    }

}
