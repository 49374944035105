import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '@cogent/client/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuardService  {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const isLoggedIn = this.authService.isLoggedIn;
        this.authService.lastAuthGuardUrl = state.url;
        if (!isLoggedIn) {
            localStorage.setItem("redirectTo", state.url);
            return from(this.authService.logIn());
        }
        return isLoggedIn;
    }
}
@Injectable({ providedIn: 'root' })
export class AuthGuardLocalService  {

    constructor(private authService: AuthService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const isLoggedIn = this.authService.isLoggedIn;
        this.authService.lastAuthGuardUrl = state.url;
        if (!isLoggedIn) {
            localStorage.setItem("redirectTo", state.url);
            this.router.navigateByUrl('/login');
        }
        return isLoggedIn;
    }
}