import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PaymentExport {
    @keyColumn() id: string;
    @column() paymentId: string;
    @column() amount: number;
    @column() invoiceType: string;
    @column() invoiceNumber: bigint;
    @column() createdDate: Date;
    @column() voidDate: Date;
    @column() cashAccount: number;
    @column() revenueAccount: number;
    @column() exportDate: Date;
    @column() voidExportDate: Date;
    @column() areaId: string;
    @column() paymentType: string;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() paymentProcessorId: string;
    @column() refundProcessorId: string;
    @column() discountAmount: number;
    @column() discountAccount: number;
    @column() customerName: string;
    @column() checkNumber: string;
    @column() realmId: string;
    @column() accountingAreaId: string;

    // client-side only
    selected = false;
    reconciled = false;
    reconciledAmount: number;

    get reconciledDifference() {
        return this.amount - this.reconciledAmount;
    }
}

export class GroupedPaymentExport {
    paymentExports: PaymentExport[];

    get amount() {
        return this.paymentExports.map(i => i.amount).reduce((a, b) => a + b);
    }

    get reconciledAmount() {
        return this.paymentExports.map(i => i.reconciledAmount).reduce((a, b) => a + b);
    }

    get reconciledDifference() {
        return this.paymentExports.map(i => i.reconciledDifference).reduce((a, b) => a + b);
    }




    get customerName() {
        return this.paymentExports[0].customerName;
    }

    get paymentId() {
        return this.paymentExports[0].paymentId;
    }

    get type() {
        return this.paymentExports.map(i => i.invoiceType).join(',');
    }

    get invoiceType() {
        return this.paymentExports.map(i => i.invoiceType).join(',');
    }

    get createdDate() {
        return this.paymentExports[0].createdDate;
    }

    get policyId() {
        return this.paymentExports[0].policyId;
    }

    get policyNumber() {
        return this.paymentExports[0].policyNumber;
    }

    get checkNumber() {
        return this.paymentExports[0].checkNumber;
    }

    get selected() {
        return this.paymentExports.filter(i => i.selected).length === this.paymentExports.length;
    }

    get cashAccount() {
        return this.paymentExports.map(i => i.cashAccount).join(',');
    }

    get revenueAccount() {
        return this.paymentExports.map(i => i.revenueAccount).join(',');
    }

    set selected(value: boolean) {
        for (const item of this.paymentExports) {
            item.selected = value;
        }
    }
}
