import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceInvoiceItem {
    @keyColumn() id: string;
    @column() maintenanceServiceInvoiceId: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() amount: number;  
    @column() deletedDate: Date;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() maintenanceServiceCustomerPropertyServiceAppointmentId: string;
    @column() description: string;
}