import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServicePaymentSummary {
    @keyColumn() id: string;
    @column() amount: number;
    @column() createdDate: Date;
    @column() type: string;
    @column() voidDate: Date;
    @column() memo: string;
    @column() paymentNumber: bigint;
    @column() customerId: string;
    @column() paymentProcessorId: string;
    @column() refundProcessorId: string;
    @column() brand: string;
    @column() last4: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() linesJson: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerHomeNumber: string;
    @column() mailingAddress1: string;
    @column() mailingAddress2: string;
    @column() mailingCity: string;
    @column() mailingState: string;
    @column() mailingPostalCode: string;
    

    _items: MaintenanceServicePaymentItemShortSummary[];

    get items() {
        if(!this._items) {
            this._items = JSON.parse(this.linesJson);
        }

        return this._items;
    }
}


@dataSource()
export class MaintenanceServicePaymentWithoutLinesSummary {
    @keyColumn() id: string;
    @column() amount: number;
    @column() createdDate: Date;
    @column() type: string;
    @column() voidDate: Date;
    @column() memo: string;
    @column() paymentNumber: bigint;
    @column() customerId: string;
    @column() paymentProcessorId: string;
    @column() refundProcessorId: string;
    @column() brand: string;
    @column() last4: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerHomeNumber: string;
    @column() mailingAddress1: string;
    @column() mailingAddress2: string;
    @column() mailingCity: string;
    @column() mailingState: string;
    @column() mailingPostalCode: string;
    @column() deletedDate: Date;
}

export class MaintenanceServicePaymentItemShortSummary {
    id: string;
    date: Date;
    amount: number;
    dueDate: Date;
    invoiceType: string;
    propertyAddress1: string;
    propertyAddress2: string;
    propertyCity: string;
    propertyState: string;
    propertyPostalCode: string;
    customerName: string;
    customerEmail: string;
    customerPhone: string;
    maintenanceServiceCustomerPropertyId: string;
    itemAmount: number;
    loginId: string;
    amountPaid: number;
    invoiceNumber: number;
    orderedServices: string;
}