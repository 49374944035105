export class PlanSelectionItemModel {
    name: string;
    planItemId: string;
    price: number;
    priceRecurring: number;
    category: string;
    allowQuantity: boolean;
    selected: boolean;
    quantity: number;
    isPromotedUpgrade: boolean;
    iconClass: string;
    salesItemId: string;
    url: string;
    showOnWeb: boolean;
}
