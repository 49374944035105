import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { UserMessageApiService } from '@cogent/client/shared/services/api/user-message-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UserNotification } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-find-user-notifications',
    templateUrl: './find-user-notifications.component.html',
    styleUrls: ['./find-user-notifications.component.css']
})
export class FindUserNotificationsComponent implements OnInit {

    history: UserNotification[];

    startDate: Date;
    endDate: Date;
    displayType = 'list';
    loading = false;

    constructor(
        private historyItemApi: UserMessageApiService,
        private entityApi: EntityApiService,
    ) { }

    ngOnInit() {
        this.startDate = UtilitiesService.dayBegin(new Date());
        this.endDate = UtilitiesService.dayEnd(new Date());
    }

    async loadHistory() {
        this.loading = true;
        const user = await this.entityApi.getLoggedInUser(false);
        this.history = await this.historyItemApi.getUserNotifications(this.startDate, UtilitiesService.dayEnd(this.endDate), user.id);

        this.loading = false;
    }
}


