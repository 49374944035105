import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";
import { ZipCode } from "./zip-code.model";

@dataSource()
export class StateAbbreviation {
    @keyColumn() abbreviation: string;
    @column() name: string;
    @column() coordinates: string;
    coords: any;
}


@dataSource()
export class County {
    @keyColumn() id: string;
    @column() state: string;
    @column() county: string;
    @column() coordinates: string;
    zipCodes: ZipCode[];
    coords: Array<Array<Array<number>>>;
}

@dataSource()
export class State {
    @keyColumn() id: string;
    @column() state: string;
    @column() name: string;
    @column() coordinates: string;
    counties: County[];
    coords: Array<Array<Array<number>>>;
}