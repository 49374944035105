import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource() export class CashOutSummary {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() createdById?: string;
    @column() createdDate?: Date;
    @column() amount: number;
    @column() contractorId: string;
    @column() exportDate: Date;
    @column() areaId: string;
    @column() policyId: string;
    @column() workOrderItemName: string;
    @column() contractorName: string;
    @column() workOrderNumber: bigint;
    @column() cashOutAccountId: number;
    @column() cashOutCashAccountId: number;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() nameOnCheck: string;
    @column() number: number;
    @column() holderId: string;
    @column() type: string;
    @column() description: string;
    @column() invoiceAppliedAmount: number;
    @column() policyNumber: bigint;
    @column() contractorEmail: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() assignedToUserId: string;
    @column() assignedUserName: string;
    @column() comparableUrl: string;
    @column() cashOutEmail: string;
    @column() contactEmail: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactMobile: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() customerHomeNumber: string;
    @column() customerMobileNumber: string;
    @column() cashOutMobileNumber: string;
    @column() sendMethod: string;
    @column() quickBooksId: string;
    @column() recipientId: string;
    @column() approvedDate: Date;
    @column() isPartial?: boolean;

    cashOutAccount: string;

    // Client-side only 
    selected: boolean;

    get balance() {
        return this.amount - this.invoiceAppliedAmount;
    }
}