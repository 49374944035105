export class Login {
    userName: string;
    lastLogin?: Date;
    passwordHash: string;
    passwordSalt: string;
    entityType: string;
    entityId: string;
    id: string;
    deletedDate?: Date;
    createdById?: string;
    lastModifiedById?: string;
    createdDate?: Date;
    lastModifiedDate?: Date;
}
