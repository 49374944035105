import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { MaintenanceServiceCustomerPropertyServiceSummary } from "./maintenance-service-customer-property-service-summary.model";

@dataSource()
export class MaintenanceServiceOrderSummary {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() customerId: string;
    @column() loginId: string;
    @column() orderedServices: string;
    @column() propertyId: string;
    @column() customerPhoneNumber: string;
    @column() linesJson: string;

    private _lines: MaintenanceServiceCustomerPropertyServiceSummary[];
    get lines(): MaintenanceServiceCustomerPropertyServiceSummary[] {

        if (!this._lines) {
            try {
                this._lines = JSON.parse(this.linesJson);
            } catch { }
        }

        return this._lines;
    }
}