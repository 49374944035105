import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TagMetrics {
    @keyColumn() id: string;
    @column() name: string;
    @column() policyCount: number;
    @column() workOrderCount: number;
    @column() taskCount: number;
    @column() entityCount: number;
    @column() objectCount: number;
    @column() functionCount: number;
    @column() queueCount: number;
}