import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class OidcCode {
    @keyColumn() id: string;
    @column() clientId: string;
    @column() loginId: string;
    @column() provider: string;
    @column() code: string;
    @column() codeChallenge: string;
    @column() codeChallengeMethod: string;
    @column() idToken: string;
    @column() token: string;
    @column() expires: Date;
    @column() deletedDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() refreshToken: string;
    @column() refreshExpires: Date;
}