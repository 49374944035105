export class NonSharedWorker {
    private webSocket: WebSocket;
    port: NonSharedPort = new NonSharedPort();
    private stateLabel: string = '';
    private webSocketEndpoint: string;
    private authorizationHeaderWebsocket;
    private userId;

    constructor() {
        this.onconnect(null);
    }

    sendPortMessage(message, data) {
        const messageEvent = new MessageEvent('message', { data: { message, data } });
        this.port.postOutgoingMessage(messageEvent);
    }

    onconnect = (e) => {
        let forceConnect = false;
        this.port.incommingMessage = (e) => {
            e = { data: e };
            switch (e.data.message) {
                case 'Connect':
                    if (e.data.authorizationHeaderWebsocket && this.authorizationHeaderWebsocket != e.data.authorizationHeaderWebsocket && !this.authorizationHeaderWebsocket) {
                        forceConnect = true;
                    }
                    this.authorizationHeaderWebsocket = e.data.authorizationHeaderWebsocket;
                    this.webSocketEndpoint = e.data.webSocketEndpoint;
                    this.userId = e.data.userId;
                    if (!this.webSocket || forceConnect) {
                        this.doConnect();
                    } else {
                        this.updateState();
                    }
                    break;
                case 'BroadcastToTabs':
                    this.sendPortMessage('BroadcastToTabs', e.data);
                    break
            }
        }
    }

    updateState() {
        if (this.webSocket) {
            switch (this.webSocket.readyState) {
                case WebSocket.CLOSED:
                    this.stateLabel = 'Closed';
                    break;
                case WebSocket.CLOSING:
                    this.stateLabel = 'Closing...';
                    break;
                case WebSocket.CONNECTING:
                    this.stateLabel = 'Connecting...';
                    break;
                case WebSocket.OPEN:
                    this.stateLabel = 'Open';
                    break;
                default:
                    this.stateLabel = 'Unknown WebSocket State: ' + this.htmlEscape(this.webSocket.readyState);
                    break;
            }
            this.sendPortMessage('StateChange', this.stateLabel);
        }
    }

    doConnect() {
        this.webSocket = new WebSocket(this.webSocketEndpoint, this.authorizationHeaderWebsocket);

        this.webSocket.onopen = event => {
            this.updateState();
            this.webSocket.send(this.userId);
        };

        this.webSocket.onclose = event => {
            this.updateState();
            setTimeout(() => this.doConnect(), 10000);
        };
        this.webSocket.onerror = event => {
            console.error(event);
            this.updateState();
        };
        this.webSocket.onmessage = event => {
            this.sendPortMessage('WebSocketMessage', event.data);
        };
    }

    htmlEscape(str) {
        return str.toString()
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }
}


export class NonSharedPort {
    onmessage: (e: any) => void;
    incommingMessage: (e: any) => void;

    postMessage(message) {
        if (this.incommingMessage) {
            this.incommingMessage(message);
        }
    }

    postOutgoingMessage(message) {
        if (this.onmessage) {
            this.onmessage(message);
        }
    }
}



