import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Login {
    @keyColumn() id: string;
    @column() userName: string;
    @column() deletedDate?: Date;
    @column() lastLogin: Date;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() passwordHash: string;
    @column() passwordSalt: string;
    @column() entityType: string;
    @column() entityId: string;
    @column() pushNotificationToken: string;
}