import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({ name: 'formatPhoneNumber' })
export class FormatPhoneNumberPipe implements PipeTransform {
    constructor() { }
    transform(value) {
        return UtilitiesService.formatPhoneNumber(value);
    }
}

@Pipe({ name: 'highlight' })
export class HighlightSearch implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: any, args: any): any {
        if (!args) return value;
        // Match in a case insensitive maneer
        const re = new RegExp(args, 'gi');
        const match = value.match(re);
        // If there's no match, just return the original value.
        if (!match) {
            return value;
        }

        const result = value.replace(re, "<span style='background-color:#fff492'>" + match[0] + "</span>");
        return this.sanitizer.bypassSecurityTrustHtml(result);
    }
}