import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class ClaimStatistic {
    @column() date: Date;
    @column() tradeId: string;
    @column() trade: string;
    @column() itemId: string;
    @column() item: string;
    @column() contractorId: string;
    @column() contractor: string;
    @column() serviceProManagerAreaId: string;
    @column() serviceProManagerArea: string;
    @column() serviceProRegionId: string;
    @column() serviceProRegion: string;
    @column() serviceProTerritoryId: string;
    @column() serviceProTerritory: string;
    @column() planId: string;
    @column() plan: string;
    @column() costs: number;
    @column() claims: number;
}