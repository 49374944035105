import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, Route, NavigationEnd } from '@angular/router';
import { WebsocketApiService } from '@cogent/client/shared/services/api/websocket-api.service';
import { AuthService } from '@cogent/client/auth';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { Subscription } from 'rxjs';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { NonSharedWorker } from './non-shared-worker';

@Component({
    selector: 'app-web-socket-handler',
    templateUrl: './web-socket-handler.component.html',
    styleUrls: ['./web-socket-handler.component.css']
})
export class WebSocketHandlerComponent implements OnInit, OnDestroy {

    @Input() hideButton: boolean;
    stateLabel = '';
    // socket: any;
    sharedWorker: SharedWorker | NonSharedWorker;
    broadcastSubscription: Subscription;
    lastAuthTokenSent = null;
    constructor(
        private missionService: MissionService,
        private router: Router,
        private entityApi: EntityApiService,
        private websocketApi: WebsocketApiService,
        private auth: AuthService
    ) {
        this.checkAuthToken();
        router.events.subscribe(event => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.initialize();
        });

        this.missionService.subscription.subscribe(message => {
            if (message.type === 'user-changed') {
                this.checkAuthToken();
            }
        });
    }

    async checkAuthToken() {
        try {
            if (this.auth) {
                const loggedInUser = await this.entityApi.getLoggedInUser(false, true);
                const authToken = this.auth.authorizationHeaderWebsocket;
                try {
                    const response = await this.websocketApi.getEndpointAsync();
                    if (response?.webSocket) {
                        ApiService.endPointWebSocket = response.webSocket;
                    }

                } catch {

                }
                if (authToken && (!this.lastAuthTokenSent || authToken[1] != this.lastAuthTokenSent[1]) && this.sharedWorker && this.sharedWorker.port) {
                    this.sharedWorker.port.postMessage({ message: 'Connect', webSocketEndpoint: this.websocketApi.websocketEndpoint, authorizationHeaderWebsocket: this.auth.authorizationHeaderWebsocket, userId: loggedInUser.id });
                } else {
                    if (this.sharedWorker && this.sharedWorker.port) {
                        this.sharedWorker.port.postMessage({ message: 'Connect', webSocketEndpoint: this.websocketApi.websocketEndpoint + '-anonymous?userId=' + loggedInUser.id, authorizationHeaderWebsocket: this.auth.authorizationHeaderWebsocket, userId: loggedInUser.id });
                    }
                }
                this.lastAuthTokenSent = authToken;
            }
        } catch (error) {
            console.error(error);
        }
        setTimeout(() => { this.checkAuthToken(); }, 10000);
    }
    processedWebSocketMessages = [];

    initialize() {
        if (this.stateLabel !== 'Open') {
            this.entityApi.getLoggedInUser(false, true).then(async loggedInUser => {

                if (loggedInUser) {
                    if (typeof SharedWorker !== 'undefined') {
                        this.sharedWorker = new SharedWorker('/assets/js/worker.js?v=1');
                    } else {

                        this.sharedWorker = new NonSharedWorker();
                    }

                    this.sharedWorker.port.onmessage = e => {
                        switch (e.data.message) {
                            case 'WebSocketMessage':
                                const data = JSON.parse(e.data.data);
                                if (!this.processedWebSocketMessages.includes(data.id)) {
                                    this.processedWebSocketMessages.push(data.id);
                                    if (data.type === 'Global') {
                                        this.missionService.raiseGlobalMessage(data);
                                    } else {
                                        this.missionService.raiseUserNotificationReceived(data);
                                    }
                                } else {

                                }
                                break;
                            case 'StateChange':
                                this.stateLabel = e.data.data;
                                break;
                            case 'BroadcastToTabs':
                                this.missionService.raiseReceivedBroadcast(e.data.data.data);
                                break;
                        }
                    };

                    if (ApiService.endPointNode) {
                        const authToken = this.auth.authorizationHeaderWebsocket;
                        if (authToken) {
                            this.sharedWorker.port.postMessage({ message: 'Connect', webSocketEndpoint: this.websocketApi.websocketEndpoint, authorizationHeaderWebsocket: this.auth.authorizationHeaderWebsocket, userId: loggedInUser.id });
                        } else {
                            this.sharedWorker.port.postMessage({ message: 'Connect', webSocketEndpoint: this.websocketApi.websocketEndpoint + '-anonymous?userId=' + loggedInUser.id, authorizationHeaderWebsocket: this.auth.authorizationHeaderWebsocket, userId: loggedInUser.id });

                        }

                    } else {
                        await UtilitiesService.sleep(500);
                        this.initialize();
                    }

                }
            });
        }
    }

    ngOnInit() {
        this.initialize();
        this.broadcastSubscription = this.missionService.broadcastToTabs.subscribe(data => {
            this.sharedWorker.port.postMessage({ message: 'BroadcastToTabs', data });
        });
    }

    ngOnDestroy() {
        this.broadcastSubscription.unsubscribe();
    }

}
