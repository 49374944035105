import { Invoice } from '@upkeeplabs/models/cogent';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';

export class PaymentFileItem {
    date: string;
    policyNumber: string;
    amount: string;
    cardNumber: string;
    homeownerName: string;
    memo: string;
    policySummary: PolicySummary;
    unpaidInvoices: Invoice[];
    convertedDate: Date;
    convertedPolicyNumber: number;
    convertedAmount: number;
    isValid: boolean;
    selected: boolean;
    paymentType: string;

    detailVisible: boolean;

    get address() {
        if (this.policySummary) {
            return this.policySummary.propertyAddress.address1;
        }
    }

    get cogentHomeownerName() {
        if (this.policySummary) {
            return this.policySummary.holder.name;
        }
    }

    get planName() {
        if (this.policySummary) {
            return this.policySummary.planName;
        }
    }

    get amountDue() {
        if (this.unpaidInvoices && this.unpaidInvoices.length && this.unpaidInvoices.length > 0) {
            return this.unpaidInvoices.map(i => i.amountDue).reduce((a, b) => a + b);
        }
    }

    get policyId() {
        if(this.policySummary) {
            return this.policySummary.id;
        }
    }
}