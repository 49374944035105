import { column } from "@upkeeplabs/linq";


export class MaintenananceServiceWarrantyItem {
    @column() workOrderItemId: string;
    @column() workOrderItemName: string;
    @column() id: string;
    @column() policyId: string;
    @column() planItemId:string;
    @column() salesItemId: string;
    @column() salesItemCoverageId: string;
    @column() name: string;
    @column() category: string;
    @column() sort: number;
    @column() optional: boolean;
    @column() title: string;
    @column() coverageLanguage: string;
    @column() notCoveredLanguage: string;
    @column() limitsLanguage: string;
    @column() coveredQuantity: number;
    @column() isService: boolean;
    @column() serviceFee: number;
    @column() tripFee: number;
    @column() otherFee: number;
    @column() limitPerOccurrence: number;
}