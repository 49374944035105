export class WorkOrderStatsModel {

    public static getTodaysStats(stats: WorkOrderStatsModel[]): WorkOrderStatsModel[] {
        return stats.filter(item => item.date.getDate() === new Date().getDate()
            && item.date.getMonth() === new Date().getMonth()
            && item.date.getFullYear() === new Date().getFullYear());
    }

    constructor(public day: string = null, public type: string = null, public count: number = null, public date: Date = null) {

    }
}

export class WorkOrderStatsGroupedByDay {

    public static fromWorkOrderStats(stats: WorkOrderStatsModel[]): WorkOrderStatsGroupedByDay[] {
        const result: WorkOrderStatsGroupedByDay[] = [];

        stats.forEach(stat => {
            let resultStat = result.filter(i => i.date.getDate() === stat.date.getDate())[0];
            if (!resultStat) {
                resultStat = new WorkOrderStatsGroupedByDay(stat.day, stat.count, stat.date);
                result.push(resultStat);
            } else {
                resultStat.count += stat.count;
            }
        });

        result.sort((a, b) => a.date > b.date ? 1 : -1);

        return result;
    }

    constructor(public day: string, public count: number, public date: Date) { }
}

export class WorkOrderStatsGroupedByType {

    public static fromWorkOrderStats(stats: WorkOrderStatsModel[]): WorkOrderStatsGroupedByType[] {
        const result: WorkOrderStatsGroupedByType[] = [];

        stats.forEach(stat => {
            let resultStat = result.filter(i => i.type === stat.type)[0];
            if (!resultStat) {
                resultStat = new WorkOrderStatsGroupedByType(stat.type, stat.count);
                result.push(resultStat);
            } else {
                resultStat.count += stat.count;
            }
        });

        return result;
    }

    constructor(public type: string, public count: number) { }
}
