<div class="container fancy-container bottom-right-rounded" style="padding-bottom: 90px;">

    <div class="center" topmargin20 bottommargin20>
        <h1 class="month-and-year">{{viewDate | date: 'MMMM yyyy'}}</h1>
    </div>
    <div flex>
        <div>
            <button mat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay($event)">
                <mat-icon>chevron_left</mat-icon>
                Previous
            </button>
            <button mat-button mwlCalendarToday (viewDateChange)="closeOpenMonthViewDay($event)"
                [(viewDate)]="viewDate">
                Today
            </button>
            <button mat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay($event)">
                Next
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
    </div>
    <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)" [excludeDays]="excludeDays"
            (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [dayStartHour]="startHour"
            [dayEndHour]="endHour" [excludeDays]="excludeDays" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [dayStartHour]="startHour"
            [dayEndHour]="endHour" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-day-view>
    </div>
    <div *ngIf="calendarUrl">
        <div class="data-label" topmargin40>iCalendar URL</div>
        <div aflex style="white-space: nowrap;">
            <div class="url">
                {{calendarUrl}}
            </div>
            <div nogrow>
                <button title="copy URL" mat-button style="margin-left: -85px;" class="copy-button" (click)="copyUrl()">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>




<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>