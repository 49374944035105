import { Role } from '@upkeeplabs/models/cogent';
import { QueueQuery } from '@cogent/client/shared/models/object-queue.model';


export class ObjectCommand {
    id: string;
    query: string;
    name: string;
    roles: string;
    availableToAllRoles: boolean;
    commandType: string;
    availableOnCustomerPortal: boolean;
    availableOnContractorPortal: boolean;
    availableOnCogent: boolean;
    availableToQuestionnaires: boolean;
    queueType: string;

    private queueQueryLocal: QueueQuery;
    private roleObjectInternal: Role[];

    get queueQuery(): QueueQuery {
        if (this.queueQueryLocal) {
            return this.queueQueryLocal;
        }
        if (!this.query) {
            this.queueQueryLocal = new QueueQuery();
        } else {
            this.queueQueryLocal = JSON.parse(this.query);
        }

        return this.queueQueryLocal;
    }
    set queueQuery(query: QueueQuery) {
        this.queueQueryLocal = null;
        this.query = JSON.stringify(query);
    }

    get rolesObject(): Role[] {
        if (this.roleObjectInternal) {
            return this.roleObjectInternal;
        }
        if (!this.roles) {
            this.roleObjectInternal = [];
        } else {
            this.roleObjectInternal = JSON.parse(this.roles);
        }

        return this.roleObjectInternal;
    }
    set rolesObject(roles: Role[]) {
        this.roleObjectInternal = null;
        this.roles = JSON.stringify(roles);
    }
}