import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource() export class PaymentAccounting {
    @column() paymentId: string;
    @column() paymentType: string;
    @column() paymentItemId: string;
    @column() void: number;
    @column() accountingAreaId: string;
    @column() accountType: string;
    @column() quickbooksAccountId: number;
    @column() debitAmount: number;
    @column() creditAmount: number;
    @column() exportDate: Date; 
    @column() postDate: Date; 
    @column() quickbooksJournalId: string; 
    @column() createdDate: Date; 
    @column() memo: string; 
    @column() customer: string;
    @column() paymentProcessorId: string;
    @column() payout: string;
    @column() policyId: string;
}