import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TaskQueue {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    // client-side only
    nameEditOpen: boolean;
}