import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CashOutOfferSummary {
    @keyColumn() id: string;
    @column() createdDate: Date;
    @column() type: string;
    @column() amount: number;
    @column() workOrderId: string;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() itemId: string;
    @column() lineId: string;
    @column() itemName: string;
    @column() jobNumber: bigint;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() propertyAddress: string;
    @column() workOrderType: string;
    @column() email: string;
    @column() customerPhoneNumber: string;
    @column() comparableUrl: string;
    @column() nameOnCheck: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() cashOutEmail: string;
    @column() rejectedDate: Date;
    @column() customerName: string;
}
