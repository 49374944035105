import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { RepairTypeGuidelinePriceSummary } from "./repair-type-guideline-price.model";
import { AuthorizationRepairItemSummary } from "./authorization-repair-item-summary.model";

@dataSource()
export class RepairType {
    @keyColumn() id: string;
    @column() name: string;
    @column() description: string;
    @column() defaultGuidelinePriceId: string;
    @column() functionDefinition: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() defaultRepairItems: string;
    @column() sort: number;
    @column() warnIfDefaultItemsChange: boolean;
    @column() alwaysWarn: boolean;
    @column() alwaysWarnMessage: string;
    @column() laborHours: number;
    @column() autoCalculateGuideline: boolean;
    @column() autoCalulateGuidelineGrace: number;
    @column() coveredType: string;
    @column() calcLaborAsHourlyRate: boolean;
    @column() commonOutOfPocketRepairItems: string;
    @column() workOrderItems: string;
    @column() category: string;
    @column() workOrderItemTypes: string;
    @column() restrictByWorkOrderItemTypes: boolean;

    touched = false;

    private _workOrderItemsInstance: any[];
    get workOrderItemsInstance() {
        if (!this._workOrderItemsInstance && this.workOrderItems) {
            this._workOrderItemsInstance = JSON.parse(this.workOrderItems);
        } else if (!this._workOrderItemsInstance) {
            this._workOrderItemsInstance = [];
        }

        return this._workOrderItemsInstance;
    }

    set workOrderItemsInstance(value: any[]) {
        this._workOrderItemsInstance = value;
        this.workOrderItems = JSON.stringify(value);
    }

    private _repairItems: AuthorizationRepairItemSummary[];
    get repairItems() {
        if (!this._repairItems && this.defaultRepairItems) {
            this._repairItems = JSON.parse(this.defaultRepairItems);
        }
        if (!this._repairItems) {
            this._repairItems = [];
        }

        return this._repairItems;
    }
    set repairItems(value: AuthorizationRepairItemSummary[]) {
        this._repairItems = value;
    }

    private _commonOutOfPockets: AuthorizationRepairItemSummary[];
    get commonOutOfPockets() {
        if (!this._commonOutOfPockets && this.commonOutOfPocketRepairItems) {
            this._commonOutOfPockets = JSON.parse(this.commonOutOfPocketRepairItems);
        }
        if (!this._commonOutOfPockets) {
            this._commonOutOfPockets = [];
        }
        return this._commonOutOfPockets;
    }
    set commonOutOfPockets(value: AuthorizationRepairItemSummary[]) {
        this._commonOutOfPockets = value;
    }

    guidelinePrices?: RepairTypeGuidelinePriceSummary[];
}


export class RepairTypeRepairItem {
    repairItemId: string;
    repairTypeId: string;

}