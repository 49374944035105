import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Trigger {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedDate?: Date;
    @column() lastModifiedById?: string;
    @column() deletedDate?: Date;
    @column() name: string;
    @column() description: string;
    @column() type: string;
    @column() workOrderStatusId: string;
    @column() sLA: string;
    @column() sLAMinutes: number;
    @column() sLAMinutesMax: number;
    @column() parameters: string;
    @column() tableObject: string;
    @column() tableObjectCondition: string;
    @column() cron: string;
    @column() continueOnFailure: boolean;
    @column() inactive: boolean;
    @column() lastRunStatus: string;
    @column() lastRunDate: Date;
    @column() lastRunDetail: string;
    @column() lastRunStart: Date;
}