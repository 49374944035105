import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CreditItemSummary {
    @keyColumn() id: string;
    @column() creditId: string;
    @column() amount: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() maintInvoiceNumber: bigint;
    @column() maintInvoiceType: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() creditNumber: bigint;
    @column() maintInvoiceId: string;
    @column() invoiceId: string;
    @column() invoiceType: string;
    @column() invoiceNumber: bigint;
    @column() policyId: string;
    @column() cashOutId: string;
    @column() cashOutNumber: number;
    @column() cashOutType: string;
    @column() lineType: string;

    deleting = false;
}
