<div aflex>
    <div>

        <h2 class="sub-heading">Cancel {{data.maintenanceServiceName}}</h2>
    </div>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="center" topmargin40 bottommargin40>
    <app-calendar-day-viewer
    [date]="data.scheduledDate"></app-calendar-day-viewer>
</div>

<div aflex>
    <div></div>
    <div nogrow>
        <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
            Cancel Appointment</button>
    </div>
</div>