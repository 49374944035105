import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PurchaseOrderItem {
    @keyColumn() id: string;
    @column() purchaseOrderId: string;
    @column() itemId: string;
    @column() quantity: number;
    @column() unitPrice: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() number: string;
    @column() description: string;
    @column() workOrderLineId: string;
    @column() returnedQty: number;
    @column() originalPurchaseOrderItemId: string;
    @column() catalogNumber: string;


    formattedQuantity: string;
    formattedUnitPrice: string;
    formattedExtPrice: string;
    isEquipmentReplacement = false;

    // Client Side Only

    saveNewItem: boolean;
    saved = false;
    selected = false;
    returnQtyOriginal = 0;
    quantities: number[];

    constructor() {
        this.unitPrice = 0;
        this.quantity = 1;
    }

    get subTotal(): number {
        return this.unitPrice * this.quantity;
    }

    get complete(): any {
        return this.description && this.number && this.workOrderLineId;
    }

    get inventoryComplete() {
        return this.description && this.number;
    }

    get nonWorkOrderLineComplete(): any {
        return this.description && this.number;
    }

    get isNewItem(): boolean {
        return !this.itemId && this.complete;
    }
}