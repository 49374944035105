<div aflex>
    <div>
        <h2 class="sub-heading">Find Customer</h2>
    </div>
    <div nogrow>

    </div>
    <div nogrow>

        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<mat-form-field style="width: 275px !important;" appearance="outline">
    <mat-label>
        <mat-icon>search</mat-icon> Filter
    </mat-label>
    <input matInput [(ngModel)]="filter" (ngModelChange)="filterChange($event)">
</mat-form-field>

<div class="customer-list-container">
    <div class="button-grid" style="margin-top: 20px">
        <button *ngFor="let customer of groupedCustomers" mat-button class="toggle-button"
            (click)="selectedCustomer = customer" [class.selected]="selectedCustomer == customer">
            <mat-icon>account_circle</mat-icon><br>

            {{customer.name}}<br>
            {{customer.customerPhoneNumber | formatPhoneNumber}}
        </button>
    </div>
</div>

<div aflex topmargin20>
    <div></div>
    <div nogrow>
        <button mat-button mat-raised-button color="primary" [disabled]="!selectedCustomer" (click)="save()">
            Open
        </button>
    </div>
</div>