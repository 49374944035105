<mat-card>
  <div style="position: relative">
    <mat-card-header>
      <mat-card-title>
        <i class="material-icons" mat-card-avatar>description</i> Statements
      </mat-card-title>
    </mat-card-header>
  </div>
  <mat-card-content>
    <div topmargin20 style="max-height: 550px; overflow-y:auto; overflow-x: hidden">

      <button (click)="showStatement(contractorInvoicePayment)" mat-button class="statement-button" *ngFor="let contractorInvoicePayment of contractorInvoicePayments">
        <div aflex>
          <div nogrow>
            <mat-icon>description</mat-icon>
          </div>
          <div>
            {{contractorInvoicePayment.createdDate | date: 'shortDate'}}<br>
            {{contractorInvoicePayment.paymentAmount | currency}}

            <span class="badge">{{contractorInvoicePayment.paymentCount}}</span>
          </div>
        </div>
      </button>
    </div>
    <button *ngIf="hasMore" mat-button block [disabled]="loading" (click)="loadMore()">
      <mat-spinner class="thirty" *ngIf="loading"></mat-spinner> Load More
    </button>
  </mat-card-content>
</mat-card>