import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-date-picker-popup',
    templateUrl: './date-picker-popup.component.html',
    styleUrls: ['./date-picker-popup.component.css'],
    standalone: true,
      imports: [
        CommonModule,
        FormsModule,
        DatePickerWrapperComponent,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
      ]
})
export class DatePickerPopupComponent implements OnInit {

    placeholder: string;
    date: Date;

    constructor(
        public dialogRef: MatDialogRef<DatePickerPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        
        this.placeholder = this.data.title;
        this.date = new Date();
    }


    save() {
        this.dialogRef.close(this.date);
    }

}
