<mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
    <mat-tab label="First">
        <div class="form-group" *ngIf="!parsedCsv && !completeImport" id="find-div">

            <h1 class="page-title">Lets Get <br> Started</h1>
            <img class="step-icon" src="assets/images/find-csv-circle.png">
            <br>

            <input type="file" style="display: none" (change)="onFileSelect($event.target)" id="myFile" name="myFile">
            <br>


            <p>Locate the CSV file on your computer or device.</p>
            <button mat-button mat-raised-button mobile-block block topmargin30 color="primary" large
                (click)="showFileUpload()">Find CSV
                File</button>

            <div topmargin30>

                <a
                    href="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/csv-templates/sample-invoice-template.csv">
                    Download A Sample Template
                </a>
            </div>

        </div>

        <div *ngIf="completeImport" style="text-align: center;">
            <mat-spinner style="display: inline-block;"></mat-spinner><br> Loading...
        </div>

    </mat-tab>
    <mat-tab label="Second">

        <div *ngIf="parsedCsv" id="csv-container">

            <div id="csv-header">
                <h1 class="page-title">Field<br> Mappings</h1>
                <img class="step-icon" src="assets/images/map-fields-circle.png">
            </div>
            <div flex>
                <div flex1>
                    <h2 class="sub-heading" topmargin30>Field Mappings</h2>
                    <div class="table">
                        <div class="table-header">
                            <div>Our Field</div>
                            <div>Your Field</div>
                        </div>
                        <div class="table-row" *ngFor="let fieldDefinition of fieldDefinitions" flex>

                            <div>{{fieldDefinition.fieldDescription}} ({{fieldDefinition.dataType}})</div>
                            <div>

                                <mat-select [(ngModel)]="fieldDefinition.theirFieldName"
                                    (ngModelChange)="saveMappings()">
                                    <mat-option *ngFor="let theirFieldName of theirFieldNames" [value]="theirFieldName">
                                        {{theirFieldName}}
                                    </mat-option>
                                    <mat-option value="">Not Set</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div flex1>

                    <div *ngIf="selectedExampleRecord" topmargin30 id="example-record">
                        <h2 class="sub-heading">Mapping Result</h2>
                        <div aflex topmargin30>
                            <div class="data-label">Job #</div>
                            <div>{{selectedExampleRecord.workOrderNumber}}</div>
                        </div>
                        <div aflex>
                            <div class="data-label">Work Performed</div>
                            <div>{{selectedExampleRecord.workPerformed}}</div>
                        </div>
                        <div aflex>
                            <div class="data-label">Parts Cost</div>
                            <div>{{selectedExampleRecord.partsCost}}</div>
                        </div>
                        <div aflex>
                            <div class="data-label">Labor Cost</div>
                            <div>{{selectedExampleRecord.laborCost}}</div>
                        </div>
                        <div aflex>
                            <div class="data-label">Tax</div>
                            <div>{{selectedExampleRecord.tax}}</div>
                        </div>
                        <div aflex>
                            <div class="data-label">Your Invoice #</div>
                            <div>{{selectedExampleRecord.contractorInvoiceNumber}}</div>
                        </div>
                        <div aflex>
                            <div class="data-label">Date Completed</div>
                            <div>{{selectedExampleRecord.dateCompleted | date: 'shortDate'}}</div>
                        </div>
                    </div>
                    <button mat-button (click)="prevExampleRecord()" [disabled]="selectedRecordIndex <= 1">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    Record {{selectedRecordIndex}} of {{parsedCsv.length - 1}}
                    <button mat-button (click)="nextExampleRecord()"
                        [disabled]="selectedRecordIndex >= parsedCsv.length - 1">
                        <mat-icon>chevron_right</mat-icon>
                    </button>

                    <div class="info">
                        Now let's match your file with the transactions in our records. Once the mapping is complete,
                        press the
                        'Import Transactions' button below.
                    </div>

                    <button topmargin30 mat-button mat-raised-button color="primary"
                        [disabled]="doingMapping || !mappingComplete" (click)="doMapping()" large block>
                        <mat-spinner *ngIf="doingMapping" class="thirty"></mat-spinner>
                        Import Transactions
                    </button>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Third">
        <div padding>
            <div flex>
                <div id="bills-container">

                    <div style="text-align: center">
                        <h1 class="page-title">Invoices<br> To Pay</h1>
                        <img src="assets/images/pay-circle.png" class="step-icon">
                    </div>

                    <button mat-button (click)="selectedIndex = 1">
                        <mat-icon>chevron_left</mat-icon> Back
                    </button>
                    <div *ngFor="let summary of invoiceSummaries" class="imported-item"
                        [class.fadeOutLeft]="summary.removing" [class.not-found]="summary.notFound">
                        <div aflex>
                            <div nogrow class="icon-panel">
                                <div style="max-width: 60px;
                                overflow: hidden;">
                                    <img [src]="summary.itemUrl">
                                </div>
                                {{summary.workOrderItemName}}<br>
                                <mat-checkbox *ngIf="!summary.notFound && !summary.isOverAutho"
                                    [disabled]="summary.isOverAutho" [(ngModel)]="summary.selected"></mat-checkbox>
                                <mat-checkbox *ngIf="!summary.notFound && summary.isOverAutho" [disabled]="true">
                                </mat-checkbox>

                                <div *ngIf="summary.cancelledDate" style="color: orange;">
                                    <mat-icon>warning</mat-icon> Job was cancelled on
                                    {{summary.cancelledDate | date: 'shortDate'}}
                                </div>
                            </div>

                            <div flex>


                                <div flex1>
                                    <h2 *ngIf="!summary.notFound">Job #: 
                                        <a  routerLink="/work-order-detail/{{summary.workOrderId}}">
                                        {{summary.workOrderNumber}}</a> </h2>
                                    <h2 *ngIf="summary.notFound">Not Found</h2>
                                    <p>{{summary.propertyAddress1}}<br>
                                        <span class="data-label">Job Date: </span> {{summary.createdDate | date: 'shortDate'}}
                                        <ng-container *ngIf="!completeImport">
                                            <br>
                                            <span class="data-label">Your Invoice #: </span>
                                            {{summary.contractorInvoiceNumber}}
                                        </ng-container>
                                    </p>
                                    <mat-form-field appearance="outline"
                                        style="max-width: 200px; display: inline-block !important;">
                                        <input matInput [min]="summary.createdDate" [matDatepicker]="picker" [(ngModel)]="summary.dateCompleted"
                                            placeholder="Completion Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-icon class="warning" *ngIf="!summary.dateCompleted"
                                        title="Date Completed Required">warning</mat-icon>
                                </div>

                                <div flex1>
                                    <div class="data-label">Work Performed <mat-icon class="warning"
                                            *ngIf="!summary.workPerformed" title="Work Performed Required">warning
                                        </mat-icon>
                                    </div>
                                    <mat-form-field appearance="outline">
                                        <textarea matInput rows="5" [disabled]="summary.notFound"
                                            [(ngModel)]="summary.workPerformed"></textarea>
                                    </mat-form-field>
                                </div>
                                <div flex1 class="job-figures-container">
                                    <div aflex>
                                        <div class="data-label horizontal"><span *ngIf="completeImport">Invoice Amount</span>
                                            <span *ngIf="!completeImport">Labor Cost</span>
                                             <mat-icon class="warning"
                                                *ngIf="summary.laborCost < 0"
                                                title="Labor must be greater than or equal to 0">warning</mat-icon>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="number-box">
                                                <input matInput type="number" [disabled]="summary.notFound"
                                                    [(ngModel)]="summary.laborCost">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div aflex *ngIf="completeImport">
                                        <div class="data-label horizontal">Invoice #</div>
                                        <div>
                                            <mat-form-field appearance="outline" class="number-box">
                                            <input matInput [disabled]="summary.notFound"
                                                [(ngModel)]="summary.contractorInvoiceNumber">
                                                </mat-form-field>   
                                        </div>
                                    </div>
                                    <div aflex *ngIf="!completeImport">
                                        <div class="data-label horizontal">Parts Cost <mat-icon class="warning"
                                                *ngIf="summary.partsCost < 0"
                                                title="Parts must be greater than or equal to 0"></mat-icon>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="number-box">
                                            <input matInput type="number" [disabled]="summary.notFound"
                                                [(ngModel)]="summary.partsCost">
                                                </mat-form-field>
                                        </div>

                                    </div>
                                    <div aflex *ngIf="!completeImport">
                                        <div class="data-label horizontal">Tax <mat-icon class="warning"
                                                *ngIf="summary.tax < 0" title="Tax must be greater than or equal to 0">
                                            </mat-icon>
                                        </div>
                                        <div>
                                            
                                            <mat-form-field appearance="outline" class="number-box">
                                            <input matInput type="number" [disabled]="summary.notFound"
                                                [(ngModel)]="summary.tax">
                                                </mat-form-field>
                                        </div>

                                    </div>
                                    <div aflex>
                                        <div class="data-label horizontal">Total</div>
                                        <div>{{summary.totalAmount | currency}}</div>
                                    </div>
                                    <div aflex>
                                        <div class="data-label horizontal">Autho Limit</div>
                                        <div>{{summary.authorizationLimit | currency}}
                                            <span *ngIf="summary.isOverAutho" class="slide-in-up-small"
                                                class="warning-info">
                                                <mat-icon>warning</mat-icon> Over Autho
                                            </span>

                                        </div>
                                    </div>

                                </div>
                                <div nogrow>
                                    <button mat-button class="remove-button" (click)="removeItem(summary)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="right-panel" *ngIf="invoiceSummaries" [class.show]="showSubmitPanel">
                    <div id="right-panel-inner">
                        <button mat-button mat-raised-button color="primary" block large
                            [disabled]="savingContractorInvoices || !canSubmit" (click)="saveContractorInvoices()">
                            <mat-spinner *ngIf="savingContractorInvoices" class="thirty"></mat-spinner>
                            Submit For Payment
                        </button>
                        <div class="data-label" topmargin30>Total Amount</div>
                        <h1 class="big-data">{{amountToPayTotal | currency}}</h1>
                        <div class="data-label" topmargin30>Count</div>
                        <h2 class="sub-heading">{{count}}</h2>

                        <!-- <h3>How will the affect my ACC?</h3>
                        <ng-container *ngFor="let summary of groupedInvoiceSummaries">
                            <div class="data-label">{{summary.areaName}} - {{summary.tradeName}}</div>

                            <app-acc-widget [target]="summary.accTarget" [acc]="summary.newAcc"></app-acc-widget>
                        </ng-container>
                        <h3 topmargin30>ACC History</h3>
                        <canvas [id]="accChartId" style="width: 100%; height: 250px;"></canvas> -->
                    </div>
                </div>
            </div>

        </div>
    </mat-tab>

    <mat-tab>

        <div style="display: table; margin: 0 auto; margin-top: 50px; text-align: center">
            <div>
                <h1 class="page-title">Upload<br>Complete</h1>
                <img src="assets/images/check-circle.png" style="height: 200px; margin-left: 20px;">
            </div>

            <div class="info">
                Your invoices have been successfully uploaded. You will receive email notification when they have been
                approved
                and submitted for payment.<br>
                Thank you for providing great service to our homeowners.<br>
            </div>
            <a mat-button mat-raised-button color="primary" large routerLink="/" style="margin-top: 30px;
          display: inline-block;">
                Return Home
            </a>

        </div>
    </mat-tab>
</mat-tab-group>

<div id="mobile-summary-container" *ngIf="selectedIndex === 2">

    <div aflex>
        <div nogrow>
            <div class="data-label">Batch Total</div>
            <div>
                <h1 class="big-data">{{amountToPayTotal | currency}}</h1>
            </div>
        </div>
        <div nogrow>
            <div class="data-label">Count</div>
            <div>
                <h1 class="big-data">{{count}}</h1>
            </div>
        </div>

        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button large block [disabled]="savingContractorInvoices || !canSubmit"
                (click)="saveContractorInvoices()">
                <mat-spinner *ngIf="savingContractorInvoices" class="thirty"></mat-spinner>
                Submit
            </button>
        </div>
    </div>
</div>