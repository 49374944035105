import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-check-circle-animated',
    templateUrl: './check-circle-animated.component.html',
    styleUrls: ['./check-circle-animated.component.css'],
    standalone: true,
    imports: [CommonModule]
})
export class CheckCircleAnimatedComponent implements OnInit {

    @Input() height = '200px';
    constructor() { }

    ngOnInit(): void {
    }

}
