import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { LocalSettingsRepositoryService } from "@cogent/client/shared/services/local-settings.service";
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { isSameDay, isSameMonth } from 'date-fns';
import { ApiService } from '@cogent/client/api';
import { Subject } from 'rxjs';
import { Entity } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";

@Component({
    selector: 'app-maintenance-service-calendar',
    templateUrl: './maintenance-service-calendar.component.html',
    styleUrls: ['./maintenance-service-calendar.component.css']
})
export class MaintenanceServiceCalendarComponent implements OnInit {
    view: CalendarView = CalendarView.Month;
    activeDayIsOpen = true;
    loadingCalendar = false;
    events: CalendarEvent[] = [];
    CalendarView = CalendarView;
    refresh: Subject<any> = new Subject();
    startHour = 6;
    endHour = 19;
    contractor: Entity;

    viewDate: Date = new Date();
    constructor(
        private maintApi: MaintenanceServiceApiService,
        private entityApi: EntityApiService,
        private router: Router,
        private api: ApiService,
        private missionService: MissionService,
        private settings: LocalSettingsRepositoryService,) { }

    ngOnInit(): void {
        this.refreshAppointments();
        if (this.settings.getSetting('contractor-appt-calendar-view')) {
            this.view = JSON.parse(this.settings.getSetting('contractor-appt-calendar-view'));
        }

        this.entityApi.getLoggedInUser().then(user=> this.contractor = user);

    }

    closeOpenMonthViewDay(data = null) {
        this.activeDayIsOpen = false;
        setTimeout(() => this.refreshAppointments());
    }

    get excludeDays() {
        return [];
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
        }
    }

    handleEvent(action: string, event: CalendarEvent): void {
        if (action === 'Clicked') {
            this.selectItem({
                id: event.meta.id,
                maintenanceServiceCustomerPropertyId: event.meta.workOrderSummary.maintenanceServiceCustomerPropertyId,
            });
        }
    }

    selectItem(item) {
        this.router.navigate(['maintenance-service-customer-view', item.maintenanceServiceCustomerPropertyId], { queryParams: { serviceId: item.id } });
    }

    get calendarUrl() {
        if(!this.contractor) {
            return null;
        }
        return `${ApiService.endPointNode}scheduled-maintenance/contractor-ical/${this.contractor.id}`;
    }

    copyUrl() {
        navigator.clipboard.writeText(this.calendarUrl);
        this.missionService.showSuccessToast('URL Copied');
    }

    async eventTimesChanged({
        event,
        newStart,
        newEnd
    }: CalendarEventTimesChangedEvent, techAndWorkOrders?: any) {


    }

    async refreshAppointments() {
        this.loadingCalendar = true;

        const user = await this.entityApi.getLoggedInUser();

        const propertyAppointments = await this.maintApi.getAppointmentsForContractor(user.id);
        this.events = propertyAppointments.map(i => {
            const actions: CalendarEventAction[] = [];
            return {
                start: i.scheduledDate,
                end: i.scheduledDate,
                meta: {
                    id: i.id,
                    subject: i.maintenanceServiceName,
                    workOrderSummary: i,
                },
                title: `${i.maintenanceServiceName}`,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true
                },
                actions,
            };
        });
        this.loadingCalendar = false;

    }

}
