import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorInvoiceSummary {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() partsCost: number;
    @column() laborCost: number;
    @column() tax: number;
    @column() amount: number;
    @column() paidDate: Date;
    @column() contractorInvoiceNumber: string;
    @column() approvedDate: Date;
    @column() workPerformed: string;
    @column() contractorId: string;
    @column() exportDate: Date;
    @column() contractorInvoiceExportDate: Date;
    @column() areaId: string;
    @column() policyId: string;
    @column() policyNumber: number;
    @column() workOrderItemId: string;
    @column() workOrderItemName: string;
    @column() contractorName: string;
    @column() workOrderNumber: number;
    @column() contractorAddress1: string;
    @column() contractorCity: string;
    @column() contractorState: string;
    @column() contractorPostalCode: string;
    @column() receivedDate: Date;
    @column() tradeId: string;
    @column() authorizationLimit: number;
    @column() itemId: string;
    @column() dateCompleted: Date;
    @column() tradeName: string;
    @column() workOrderType: string;
    @column() status: string;
    @column() cancelledDate: Date;
    @column() contractorInvoicePaymentId: string;
    @column() areaName: string;
    @column() purchaseOrderId: string;
    @column() payablesPaymentMethod: string;
    @column() propertyAddress: string;
    @column() workOrderCreatedDate: Date;


    
}