

<div  id="contractor-home-banner" *ngIf="!isMaintContractor && entity && loaded">

    <div class="container">

        <div flex>
            <div nogrow style="min-width: 350px;max-width: 100%;">
                <div *ngIf="entity" aflex>
                    <div nogrow>
                        <img [src]="getEntityThumbnail(entity.id)">
                    </div>
                    <div>
                        <h1>Welcome</h1>
                        <h3 *ngIf="entity" style="margin-top: 0">{{entity.displayName}}</h3>
                    </div>
                </div>
            </div>
            <div></div>
            <div nogrow id="search-box-container">
                <form topmargin40>
                    <mat-form-field *ngIf="entity" appearance="outline" class="white-box" id="search-for-box">
                        <mat-label>
                            <mat-icon>search</mat-icon> Search For
                        </mat-label>
                        <input matInput name="searchFor" (keyup)="searchKeyHandler($event)" [(ngModel)]="searchFor">
                        <span matSuffix>
                            <button id="search-button" mat-button mat-raised-button (click)="doSearch()">
                                <mat-icon style="display: inline;">search</mat-icon>
                            </button>
                        </span>
                    </mat-form-field>
                </form>
            </div>
        </div>

    </div>
</div>

<div *ngIf="!isMaintContractor && !entity">
    <div class="center">
        <mat-spinner style="display: inline-block;"></mat-spinner>
    </div>
</div>

<div class="container" id="top-cards" *ngIf="!isMaintContractor && entity && loaded">

    <div flex>
        <mat-card flex1>
            <div>
                <div style="flex: 1">
                    <div aflex>
                        <div nogrow>
                            <div class="card-icon">
                                <span class="material-icons" style="color: #fff;height: 40px;width: 40px;font-size: 40px;">
                                    event
                                </span>
                            </div>
                        </div>
                        <div>
                            <h2 class="sub-heading" style="margin-left: 45px;">Upcoming Appointments</h2>
                        </div>
                    </div>


                    <div style="margin-top: 10px;"></div>
                    <a mat-button class="upcomming-job-link" *ngFor="let job of upcommingJobs"
                        routerLink="/work-order-detail/{{job.id}}">
                        <div aflex class="upcomming-job">
                            <div nogrow>

                                <h2>{{job.scheduledDate | date: 'd'}}</h2>
                                <div>{{job.scheduledDate | date: 'MMM'}}</div>
                            </div>
                            <div>
                                <div>{{job.number}} - {{job.itemName}}</div>
                                <div>{{job.propertyAddress}}</div>
                                <div>{{job.scheduledStartWindow}} - {{job.scheduledEndWindow}}</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div style="flex: none;padding-top: 20px;">
                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <a mat-button routerLink="/jobs">See More <mat-icon>chevron_right</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card flex1>
            <div>
                <div style="flex: 1">
                    <div class="card-icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <h1 class="big-data">{{callsToday}}</h1>
                    <div class="data-label">Calls Today</div>
                </div>
                <div style="flex: none">
                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <a mat-button routerLink="/search">See More <mat-icon>chevron_right</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card flex1>
            <div>
                <div style="flex: 1">
                    <div class="card-icon">
                        <i class="fa fa-exclamation" aria-hidden="true"></i>
                    </div>
                    <h1 class="big-data">
                        {{callsNeedingUpdate}}
                    </h1>
                    <div class="data-label">Calls Need Update</div>
                </div>
                <div style="flex: none">
                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <a mat-button routerLink="/jobs">See More <mat-icon>chevron_right</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card flex1>
            <div>
                <div style="flex: 1">
                    <div class="card-icon">
                        <!-- <i class="fa fa-handshake" aria-hidden="true"></i> -->
                        <i class="material-icons">
                            new_releases
                        </i>
                    </div>
                    <h1 class="big-data">{{unacceptedCount}}</h1>
                    <div class="data-label">Pending Offers</div>
                </div>
                <div style="flex: none">
                    <div aflex>
                        <div></div>
                        <div nogrow>
                            <a mat-button routerLink="/pending-offers">See More <mat-icon>chevron_right</mat-icon> </a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <div flex>
        <div routerLink="/jobs/report" class="feature-container">
            <div aflex>
                <div nogrow><img src="assets/images/document-circle.png"></div>
                <div>
                    <h2 class="sub-heading">Jobs Report</h2>
                    <br>
                    <p>Query for all your jobs</p>
                    <button mat-button routerLink="/jobs/report">View Report <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div routerLink="/jobs" class="feature-container">
            <div aflex>
                <div nogrow>
                    <img src="assets/images/lanes-circle.png">
                </div>
                <div>
                    <h2 class="sub-heading">Jobs Board</h2>
                    <br>
                    <p>View all your jobs in progress, and easily move jobs between different states.</p>
                    <button mat-button routerLink="/jobs">View Your Board <mat-icon>chevron_right</mat-icon> </button>
                </div>
            </div>
        </div>

        <div class="feature-container" routerLink="/billing">
            <div aflex>
                <div nogrow><img src="assets/images/billing-circle.png"></div>
                <div>
                    <h2 class="sub-heading">Billing</h2>
                    <br>
                    <p>Once the job is complete, you can submit your billing information and we will get it paid.</p>
                    <button mat-button routerLink="/billing">Create Bills <mat-icon>chevron_right</mat-icon> </button>
                </div>
            </div>
        </div>


    </div>



    <div flex topmargin30>
        <div class="feature-container" routerLink="/reports">
            <div aflex>
                <div nogrow><img src="assets/images/statistics-circle.png"></div>
                <div>
                    <h2 class="sub-heading">Statistics</h2>
                    <br>
                    <p>View all of your statistics and performance metrics.</p>
                    <button mat-button routerLink="/reports">See Your Statistics <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="feature-container" routerLink="/status-report">
            <div aflex>
                <div nogrow><img src="assets/images/status-report.png"></div>
                <div>
                    <h2 class="sub-heading">Status Report</h2>
                    <br>
                    <p>View trade and area based status report.</p>
                    <button mat-button routerLink="/status-report">See Your Status Report <mat-icon>chevron_right
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="feature-container" routerLink="/calendar">
            <div aflex>
                <div nogrow><img src="assets/images/calendar-circle.png"></div>
                <div>
                    <h2 class="sub-heading">Calendar</h2>
                    <br>
                    <p>View your scheduled jobs.</p>
                    <button mat-button routerLink="/calendar">View Your Calendar <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>

    </div>
    <div flex topmargin30>
        <div class="feature-container" routerLink="/settings">
            <div aflex>
                <div nogrow><img src="assets/images/gear-circle.png"></div>
                <div>
                    <h2 class="sub-heading">Settings</h2>
                    <br>
                    <p>View/Change your profile settings.</p>
                    <button mat-button routerLink="/settings">Edit Settings <mat-icon>chevron_right</mat-icon> </button>
                </div>
            </div>
        </div>
        <div *ngIf="!promotionCodes || promotionCodes.length === 0"></div>
        <div class="feature-container" *ngIf="promotionCodes && promotionCodes.length > 0">

            <div aflex>
                <div nowgrow><img src="../../../assets/images/promo-code.png"></div>
                <div>
                    <h2 class="sub-heading">Your Promotion Code</h2>
                    <br>
                    <h1 class="big-data">
                        {{promotionCodes[0].code}}
                    </h1>
                </div>
            </div>
        </div>
        <div></div>
    </div>


</div>

<div *ngIf="isMaintContractor">
    <app-maint-services-home></app-maint-services-home>
</div>