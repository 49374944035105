import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AddressMeta {
    @keyColumn() id: string;
    @column() address: string;
    @column() city: string;
    @column() state: string;
    @column() zip5: string;
    @column() zip4: string;
    @column() type: string;
    @column() yearBuilt: number;
    @column() squareFootage: number;
    @column() lotSquareFootage: number;
    @column() latitude: string;
    @column() longitude: string;
    @column() poolType: string;
    @column() attomMetadata: string;
    @column() zillowMetadata: string;
    @column() melissaMetadata: string;
    @column() uspsMetadata: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}