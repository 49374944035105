import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Plan {
    @keyColumn() id: string;
    @column() name: string;
    @column() summary: string;
    @column() deletedDate?: Date;
    @column() isRealEstateCoverage: boolean;
    @column() isHomeownerCoverage: boolean;
    @column() isRenewalCoverage: boolean;
    @column() isNewConstruction: boolean;
    @column() activeDateStart: Date;
    @column() activeDateEnd: Date;
    @column() isSingleFamily: boolean;
    @column() isCondo: boolean;
    @column() isDuplex: boolean;
    @column() isTriplex: boolean;
    @column() isFourplex: boolean;
    @column() showOnWeb: boolean;
    @column() price: number;
    @column() priceRecurring: number;
    @column() serviceFee: number;
    @column() squareFootageAdjustmentId: string;
    @column() ageOfHomeAdjustmentId: string;
    @column() renewalProfitabilityAdjustmentId: string;
    @column() coverageTypeAdjustmentId: string;
    @column() dwellingTypeAdjustmentId: string;
    @column() monthlyProcessingFee: number;
    @column() planColor: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() termsAndLimitationId: string;
    @column() contractTemplateName: string;
    @column() renewalPlanId: string;
    @column() recallPeriod: number;
    @column() cancelationFee: number;
    @column() areaAdjustmentId: string;
    @column() homeownerWaitingPeriod: number;
    @column() isSellersCoverage: boolean;
    @column() monthsOfCoverage: number;
    @column() planStyleId: string;
    @column() cancellationGracePeriod: number;
    @column() isPrivateLabelCoverage: boolean;
    @column() privateLabelConfig: string;
    @column() precollectMonthsOnMonthly: number;
    @column() prepaidServiceFeeCount: number;
    @column() versionId: string;
    @column() proRataRefundPct: number;
    @column() isFivePlex: boolean;
    @column() isSixPlex: boolean;
    @column() upgradeSuggestionPlanId: string;
    @column() limitPerOccurance: number;
    @column() limitPerPlanPeriod: number;
    @column() promotionalMonthsOfCoverage: number;
    @column() nodeJsContract: boolean;
    @column() chargeServiceFeePerItem : boolean;
    @column() autoRenewByDefault: boolean;
    @column() newConstructionWaitMonths?: number;
    @column() description: string;
    @column() isPropertyManagmentCoverage: boolean;
    @column() happyHomiversaryDiscount: number;
    @column() preferredCustomerDiscount: number;

    basePrice: number;
    basePriceRecurring: number;
}

@dataSource()
export class PlanZipCodeSummary {
    @keyColumn() id: string;
    @column() name: string;
    @column() summary: string;
    @column() versionId: string;
    @column() activeDateStart: Date;
    @column() activeDateEnd: Date;
    @column() zipCode: string;
}