<style>
    .arc-label {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "Open Sans";
    }

    .arc-number {
        font-size: 64px;
        text-align: center;
        color: #1C053A;
        fill: #1C053A;
        font-family: "Open Sans";
        font-weight: 300;
    }

    .circle {
        transform: rotate(-180deg);
        transform-origin: 50% 50%;
    }

    .path {
        stroke-dasharray: 314.1592;
        stroke-dashoffset: 314.1592;
        transition: stroke-dashoffset 1s ease-out;
        transform-origin: 50% 50%;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
</style>
<div style="position: relative;display: inline-block;" [style.width]="containerHeight">
<svg [style.height]="containerHeight" [style.width]="containerHeight">
    <defs>
        <filter id="glow">
            <feGaussianBlur [attr.stdDeviation]="glowAmount" result="coloredBlur" />
            <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>
        <linearGradient id="{{linearId}}" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" [attr.stop-color]="strokeColor"  />
            <stop offset="100%" [attr.stop-color]="strokeEndColor"  />
        </linearGradient>
    </defs>
    <circle [id]="outerCircleId" class="circle" fill="none" style="stroke:#afbaca; stroke-opacity: .2" cx="52" cy="52" r="50"
        [style.stroke-width]="strokeCircleWidth"></circle>
        <circle [id]="innerCircleId" class="path circle" [attr.stroke]="strokeUrl" filter="url(#glow)"
            [style.stroke-dasharray]="dashArray" [style.stroke-dashoffset]="archCircumferenceBound" cx="52" cy="52"
            r="50" [style.stroke-width]="strokeCircleWidth" fill="none" />
    <!-- <text sodipodi:linespacing="125%" [style.fill]="textColor" *ngIf="showNumber" xml:space="preserve" class="arc-number" id="text4146" x="50%"
        y="130" text-anchor="middle">{{number}}</text> 
        <text sodipodi:linespacing="125%" [style.fill]="textColor" xml:space="preserve" id="label" class="arc-label" x="50%" y="155"
        text-anchor="middle">{{label}}</text> -->
</svg>
<mat-icon *ngIf="iconClass" [style.color]="strokeColor">{{iconClass}}</mat-icon> 
</div>
