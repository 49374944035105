import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderAppointment {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() technicianId: string;
    @column() scheduledDate: Date;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}