<div class="container fancy-container bottom-right-rounded">
    <h1>Reports</h1>

    <div flex>
        <div flex1>
            <h3 style="color: rgba(0,0,0,.5); text-transform: lowercase; font-size: 24px; margin-top: 20px">Survey
                History</h3>
            <canvas id="survey-history-chart"></canvas>
        </div>
        <div flex1>
            <h3 style="color: rgba(0,0,0,.5); text-transform: lowercase; font-size: 24px; margin-top: 20px">Recent
                Responses</h3>
            <a class="survey-response-link" mat-button *ngFor="let response of surveys"
                routerLink="/maintenance-service-customer-view/{{response.maintenanceServiceCustomerPropertyId}}">
                <div aflex>
                    <div nogrow>
                        <img class="item-icon" [src]="getResponseIcon(response)">
                    </div>
                    <div>
                        <div aflex>
    
                            <div>
                                <div class="star-ratings-sprite-sm">
                                    <span id="rating" [style.width]="(response.serviceRating / 5 * 100) + '%'"
                                        class="star-ratings-sprite-rating-sm"></span>
                                </div>
                                <span>{{response.maintenanceServiceName}}</span>
        
                            </div>
                            <div nogrow>
                                {{response.surveyDate | date: 'shortDate'}}
                            </div>
                        </div>
        
                        <div *ngIf="response.comments">
                            <div class="conversation-bubble incoming">{{response.comments}}</div>
                            <div class="said"> ~ {{response.customerName}}</div>
                        </div>
                    </div>
                </div>


            </a>

            <button *ngIf="!hideShowMoreSurvyes" mat-button block [disabled]="loadingSurveys"
                (click)="loadMoreSurveys()">
                <mat-spinner class="thirty" *ngIf="loadingSurveys"></mat-spinner> Load More
            </button>
        </div>
    </div>
</div>

<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>