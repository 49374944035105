import { Injectable } from '@angular/core';
import { EmailAttachment } from '@cogent/shared/models/other/email-args.model';
import { ApiService } from '@cogent/client/api';

@Injectable({ providedIn: 'root' })
export class DocumentApiService {
    constructor(private api: ApiService) { }

    sendHtmlDocument(
        to: string, subject: string, message: string, workOrderId: string = null,
        taskId: string = null, policyId: string = null, saveAsNote = false, cc = null,
        attachments: EmailAttachment[] = null, entityId: string = null, replyToAddress: string = null, bcc = null): Promise<void> {
        return this.api.postVoidDotNet('Document/Email', {
            to,
            subject,
            documentHtml: message,
            workOrderId,
            taskId,
            policyId,
            saveAsNote,
            cc,
            attachments,
            entityId,
            replyToAddress,
            bcc,
        });
    }

    async convertToPdf(html: string) {
        return (await this.api.postSingleDotNet('Pdf/Convert', { html })).pdf;
    }

    async convertToPdfAndSave(html: string, templateKey: string) {
        return (await this.api.postSingleDotNet('pdf/convert-and-save', { html, templateKey })).pdf;
    }

    convertHtmlToPdf(html: string) {
        return this.api.postSingleNode(`document/convert-to-pdf`, {html});
    }

}