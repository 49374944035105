import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { PurchasingApiService } from '@cogent/client/shared/services/api/purchasing-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-appliance-part-search',
    templateUrl: './appliance-part-search.component.html',
    styleUrls: ['./appliance-part-search.component.css']
})
export class AppliancePartSearchComponent implements OnInit {
    searchResults: any;
    searchCtrl = new UntypedFormControl();
    searchFor: string;
    filteredSearches;
    searching = false;
    @Input() selectedItem: any;
    @Output() selectedItemChange: EventEmitter<any> = new EventEmitter();

    @Input() noMatch: boolean;
    @Output() noMatchChange: EventEmitter<boolean> = new EventEmitter();
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    id: string = UtilitiesService.newid();

    constructor(private purchasingApi: PurchasingApiService) { }

    ngOnInit(): void {
        this.filteredSearches = this.searchCtrl.valueChanges.pipe(debounceTime(500))
            .subscribe(value => {
                this.searchFor = value;

                if (value && !value.name && value !== 'add-new') {
                    this.getData();
                }
                if (value?.name) {
                    this.searchCtrl.reset();
                }
            });
    }

    doAutomaticSearch(searchFor: string) {
        this.searchCtrl.setValue(searchFor);
        this.searchFor = searchFor;
        (document.getElementById(this.id) as any).value = searchFor;
        document.getElementById(this.id).focus();
    }

    selected(value) {
        this.selectedItem = value.option.value;
        this.selectedItemChange.emit(this.selectedItem);
    }

    displayWith(value) {
        return value?.name;
    }

    getData() {
        this.searching = true;
        this.noMatch = false;
        this.noMatchChange.emit(false);
        this.purchasingApi.applianceSearch(this.searchFor).then(results => {
            this.searching = false;
            this.searchResults = results;
            if (results) {
                if (results.products.length === 0 && results.models.length === 0) {
                    this.noMatch = true;
                    this.noMatchChange.emit(true);
                    this.autocomplete.closePanel();
                }
            } else {
                this.autocomplete.openPanel();
            }
        });

    }

}
