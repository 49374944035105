<div class="container-container">
    <div id="plan-selection-container">

        <div class="padding" style="text-align: center;">
            <h1 class="bold-title" *ngIf="!hideTitle">{{titleBig}} <span class="orange">{{titleSmall}}</span></h1>
            <br *ngIf="!hideTitle">
            <mat-slide-toggle *ngIf="!hideMonthlySelection" [(ngModel)]="isMonthly"
                (ngModelChange)="updateIsMonthly($event)">Pay By Month
            </mat-slide-toggle>
        </div>

        <div class="scrollers-container">
            <button [disabled]="isLeftScrolled" (click)="scrollLeft()" mat-button class="scroller-btn left"
                *ngIf="hasScrollers">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button mat-button class="scroller-btn right" [disabled]="isRightScrolled" (click)="scrollRight()"
                *ngIf="hasScrollers">
                <mat-icon>chevron_right</mat-icon>
            </button>
            <div class="plan-selection-outer-container">
                <div class="plan-selection-container" (scroll)="updateScrollStatus()" [id]="id">

                    <div *ngFor="let plan of plans" [id]="plan.id" [class.best-value]="plan.bestValue"
                        [class.scale-small]="scaleSmall" class="plan">
                        <div class="flip-container" [class.flip-it]="plan.itemsExpanded">
                            <div class="flipper">
                                <div class="front">
                                    <div *ngIf="!plan.itemsExpanded">
                                        <div style="flex: 1;padding: 20px">
                                            <h1 [style.color]="plan.planColor">
                                                {{plan.name}}
                                            </h1>
                                            <div class="center">
                                                <h2 class="price" *ngIf="isMonthly"
                                                    [class.shift-up]="plan.happyHomiversaryDiscount < 0 || plan.preferredCustomerDiscount < 0">
                                                    <span
                                                        *ngIf="plan.happyHomiversaryDiscount < 0 || plan.preferredCustomerDiscount < 0">
                                                        <span
                                                            *ngIf="plan.happyHomiversaryDiscount < 0 || plan.preferredCustomerDiscount < 0"
                                                            style="font-weight: normal; font-size:40px; display:inline-block; padding-left:10px; padding-right:10px;"
                                                            class="strike-diagonal-price">
                                                            {{(plan.priceRecurring + (plan.promotionAmount ?? 0)) -
                                                            plan.happyHomiversaryDiscount -
                                                            plan.preferredCustomerDiscount | specialCurrency}}<br />
                                                        </span>
                                                    </span>
                                                    {{(plan.priceRecurring + (plan.promotionAmount ?? 0)) | specialCurrency}}
                                                </h2>
                                                <h2 class="price" *ngIf="!isMonthly">
                                                    <span
                                                        *ngIf="plan.happyHomiversaryDiscount < 0 || plan.preferredCustomerDiscount < 0">
                                                        <span
                                                            *ngIf="plan.happyHomiversaryDiscount < 0 || plan.preferredCustomerDiscount < 0"
                                                            style="font-weight: normal; font-size:40px; display:inline-block; padding-left:10px; padding-right:10px;"
                                                            class="strike-diagonal-price">
                                                            {{(plan.price + (plan.promotionAmount ?? 0)) -
                                                            (plan.happyHomiversaryDiscount * 12) -
                                                            (plan.preferredCustomerDiscount * 12) | specialCurrency}}<br />
                                                        </span>
                                                    </span>
                                                    {{(plan.price + (plan.promotionAmount ?? 0)) | specialCurrency}}
                                                </h2>
                                                <h3 *ngIf="isMonthly">Month</h3>
                                                <h3 *ngIf="!isMonthly && plan.monthsOfCoverage <= 13">Year</h3>
                                                <h3 *ngIf="!isMonthly && plan.monthsOfCoverage > 13">
                                                    {{plan.monthsOfCoverage / 12 | number: '1.0-0'}} Years
                                                </h3>

                                                <div bottommargin20 *ngIf="plan.promotionAmount" style="margin-top: -20px">
                                                    <div class="data-label">
                                                        Your savings:</div>
                                                    <h2 class="savings-amount">
                                                        {{plan.promotionAmount * -1 | specialCurrency }}
                                                        <span class="small" *ngIf="isMonthly">/Month</span>
                                                    </h2>
                                                </div>

                                                <!-- <div>
                                                    {{plan.serviceFee | specialCurrency}} service fee
                                                </div> -->

                                                <div *ngIf="plan.description" style="white-space: normal;"
                                                    [innerHTML]="plan.description">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="plan-footer">

                                            <button [style.background-color]="plan.planColor" class="select-button"
                                                (click)="orderPlan(plan, 0)" mat-button mat-raised-button>
                                                <mat-icon
                                                    *ngIf="callToActionIconClass">{{callToActionIconClass}}</mat-icon>
                                                {{callToActionText}}</button>
                                            <br>
                                            <button mat-button mat-raised-button class="details-button"
                                                (click)="plan.itemsExpanded = true">Details</button>

                                        </div>
                                    </div>
                                </div>
                                <div class="back">
                                    <div class="items-container">


                                        <button mat-button class="close-detail-button"
                                            (click)="plan.itemsExpanded = false">
                                            <mat-icon>close</mat-icon>
                                            <br>
                                            Close
                                        </button>

                                        <h3 style="text-align: center;border-bottom-width: 1px;border-bottom-style: solid;margin-bottom: 15px;padding-bottom: 4px;"
                                            [style.border-bottom-color]="plan.planColor" [style.color]="plan.planColor">
                                            {{plan.name}} Standard Items</h3>
                                        <ul>
                                            <li *ngFor="let item of plan.standardItems">
                                                {{item.name}}
                                            </li>
                                        </ul>

                                        <div class="center">
                                            <a target="_blank"
                                                style="text-align: center;margin-bottom: 30px;border-radius: 30px;"
                                                mat-button mat-raised-button [href]="getSampleContractUrl(plan)">
                                                <mat-icon>description</mat-icon>
                                                Sample Contract
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="center">
            <button mat-button mat-raised-button style="color: #888" id="show-compare"
                *ngIf="!comparePlansVisible && plans && plans.length > 0 && !showNewPlanGrid"
                (click)="showCompareClicked()">
                <mat-icon>done_all</mat-icon> Compare Plans
            </button>
        </div>
    </div>

    <div id="plan-details" *ngIf="comparePlansVisible && !showNewPlanGrid" class="fade-in-up"
        style="position: relative">
        <div id="plan-details-icon">
            <img src="../../../assets/images/hvac-icon.png">
        </div>
        <button mat-button id="close-button" style="position: absolute; top: 10px; right: 10px"
            (click)="closeButtonClicked()">
            <mat-icon>close</mat-icon> Close
        </button>


        <app-plan-selection [(useMonthly)]="isMonthly" [highlightedPlan]="highlightedPlan" [plans]="plans"
            [removeOneTimeServices]="removeOneTimeServices"
            (selectedPlanChange)="selectedPlanChanged($event)"></app-plan-selection>


    </div>

    <div *ngIf=" showNewPlanGrid">
        <app-new-plan-offerings [plans]="plans" [hideOneTimeServices]="removeOneTimeServices"
            (selectedPlanChange)="orderPlan($event, 0)" [inline]="true" [newConfig]="true"></app-new-plan-offerings>
    </div>
</div>