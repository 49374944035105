import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class SecurityFilter {
    @keyColumn() id: string;
    @column() deletedDate?: Date;
    @column() objectType: string;
    @column() entityType: string;
    @column() roleId: string;
    @column() canSelect: boolean;
    @column() canInsert: boolean;
    @column() canUpdate: boolean;
    @column() canUpsert: boolean;
    @column() canDelete: boolean;
    @column() selectFilter: string;
    @column() insertFilter: string;
    @column() updateFilter: string;
    @column() upsertFilter: string;
    @column() deleteFilter: string;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedDate?: Date;
    @column() lastModifiedById?: string;
}