import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { EntityCommunication, EntityCommunicationItem, EntityCommunicationSummary } from "@upkeeplabs/models/cogent";

@Injectable({ providedIn: 'root' })
export class SMSApiService {

    constructor(private api: ApiService) { }

    sendSMSProxyMessage(entityCommunication: EntityCommunication, entityCommunicationItem: EntityCommunicationItem) {
        return this.api.postSingleNode(`users/sms/send-proxy-message`, { entityCommunication, entityCommunicationItem });
    }

    getEntityCommunications(entityId: string) {
        return this.api.getArrayNode(`EntityCommunicationSummary`, { entityId_eq: entityId, orderby: 'LastMessageDate desc' }, () => new EntityCommunicationSummary());
    }

    getEntityCommunicationItems(entityCommunicationId: string) {
        return this.api.getArrayNode(`EntityCommunicationItem`, { entityCommunicationId_eq: entityCommunicationId, orderby: 'CreatedDate' }, () => new EntityCommunicationItem());
    }

    markCommunicationItemAsRead(entityCommunicationItem: EntityCommunicationItem) {
        entityCommunicationItem.wasRead = true;
        return this.api.patchNode(`EntityCommunicationItem/${entityCommunicationItem.id}`, { wasRead: true });
    }

    getUnreadCommunicationItems(entityId: string) {
        return this.api.getSingleNode(`users/sms/unread-proxy-messages/${entityId}`);
    }


}