import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { MaintenanceServiceAddOnRequestSummary, MaintenanceServiceJobOfferSummary } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
    selector: 'app-maint-services-nav-bar',
    templateUrl: './maint-services-nav-bar.component.html',
    styleUrls: ['./maint-services-nav-bar.component.css']
})
export class MaintServicesNavBarComponent implements OnInit {

    @Input() selectedIndex: number = 2;
    @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();
    @Input() isHome = false;
    @Input() pending: MaintenanceServiceJobOfferSummary[];
    @Output() pendingChange: EventEmitter<MaintenanceServiceJobOfferSummary[]> = new EventEmitter();
    pendingAddOns: MaintenanceServiceAddOnRequestSummary[];
    // maintVisible = false;


    constructor(private router: Router, 
        private missionService: MissionService,
        private maintServiceApi: MaintenanceServiceApiService) { }

    ngOnInit(): void {
        this.refresh();
       // this.maintVisible = this.missionService.stateValues['isMaintContractor'];
    }

    async refresh() {
        this.pending = await this.maintServiceApi.getPendingOffers();
        this.pendingAddOns = await this.maintServiceApi.getPendingAddOnRequestForLoggedInContractor();
        this.pendingChange.emit(this.pending);
    }

    // async changeMaintVisible() {
    //     this.missionService.stateValues['isMaintContractor'] = false;
    //     this.missionService.publish({
    //         messageBody: false,
    //         type: 'CHANGE-IS-MAINT-CONTRACTOR'
    //     });

    //     //await this.maintApi.toggleMaintenanceServiceView(this.contractor.id, $event);
    //     //this.contractor.isMaintenanceServiceContractor = $event;
    // }

    get pendingCount() {
        let count = 0;
        if(this.pending) {
            count+= this.pending.length;
        }
        if(this.pendingAddOns) {
            count += this.pendingAddOns.length;
        }

        return count;
    }

    get canToggle() {
        return this.missionService.stateValues['allowMaintToggle'];
    }

    raiseClicked(index: number) {
        if(index === 0) {

        }
        if (this.isHome) {
            this.selectedIndex = index;
            this.selectedIndexChange.emit(this.selectedIndex);
            if(index === 0) {
                this.refresh();
            }
        } else {
            this.router.navigateByUrl(`/?selectedIndex=${index}`);
        }
    }

    logout() {
        
    }

}
