import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { InvoiceItem } from "./invoice-item.model";

@dataSource()
export class InvoicePaymentSummary {
    @keyColumn() id: string;
    @column() invoiceNumber: bigint;
    @column() amount: number;
    @column() amountDue: number;
    @column() createdDate: Date;
    @column() voidDate: Date;
    @column() policyId: string;
    @column() invoiceType: string;
    @column() paymentId: string;
    @column() paymentNumber: bigint;
    @column() amtPaid: number;
    @column() claimId: string;

    get amountPaid() {
        if (this.amountDue > this.amount) {
            return 0;
        }
        return (this.amount ?? 0) - (this.amountDue ?? 0);
    }

}

