import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PolicyPayment {
    @keyColumn() id: string;
    @column() paymentNumber: bigint;
    @column() amount: number;
    @column() createdDate: Date;
    @column() memo: string;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() invoiceType: string;
    @column() maintenanceServiceInvoiceId: string;
}