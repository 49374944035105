import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class KeyMetricGoal {
    @keyColumn() id: string;
    @column() metric: string;
    @column() metricYear?: number;
    @column() jan: number;
    @column() feb: number;
    @column() mar: number;
    @column() apr: number;
    @column() may: number;
    @column() jun: number;
    @column() jul: number;
    @column() aug: number;
    @column() sep: number;
    @column() oct: number;
    @column() nov: number;
    @column() dec: number;
    @column() total: number;
    @column() createdDate: Date;
    @column() createdById: string;
    @column() lastModifiedDate: Date;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;
    
    // Client-side only
    isDirty = false;
    selected = false;
}