export class AuthorizationReviewTrigger {
    type: 'TOTAL_AUTHO' | 'TOTAL_PARTS' | 'TOTAL_OOP' | 'DEFAULT_PARTS_ALTERED' | 'DEFAULT_PARTS_VARIES' | 'MANUAL_REVIEW' | 'REPAIR_ITEM_OVER_GUIDELINE' | 'TOTAL_PARTS_AND_AUTHO' ;
    id: string;
    stepId: string;
    description: string;
    value: any;
    showWarningExternally: boolean;
    overwritesPreviousValues: boolean;
    override?: boolean;

    get displayDescription() {
        if (this.description) {
            return this.description;
        }

        switch (this.type) {
            case 'TOTAL_AUTHO':
                return `Authorization exceeds ${this.value}`;
            case 'TOTAL_PARTS':
                return `Parts total exceeds ${this.value}`;
            case 'TOTAL_OOP':
                return `Out of Pocket exceeds ${this.value}`;
            case 'DEFAULT_PARTS_ALTERED':
                return `Default repair items were altered`;
            case 'DEFAULT_PARTS_VARIES':
                return `repair items total deviates more than ${this.value}`;
            case 'MANUAL_REVIEW':
                return 'Manual review triggered';
            case 'REPAIR_ITEM_OVER_GUIDELINE':
                return 'A repair item was over the guideline price';
            case 'TOTAL_PARTS_AND_AUTHO':
                return `Parts & Labor exceeds ${this.value}`;
        }
    }
}