import { Component, OnInit } from '@angular/core';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { GroupedCustomer } from '../maint-services-home/maint-services-home.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-maint-services-open-customer',
    templateUrl: './maint-services-open-customer.component.html',
    styleUrls: ['./maint-services-open-customer.component.css']
})
export class MaintServicesOpenCustomerComponent implements OnInit {

    groupedCustomers: GroupedCustomer[];
    allGroupedCustomers: GroupedCustomer[];
    selectedCustomer: GroupedCustomer;
    filter: string;

    constructor(private maintServiceApi: MaintenanceServiceApiService,
        public dialogRef: MatDialogRef<MaintServicesOpenCustomerComponent>,) { }

    async ngOnInit() {
        const customers = await this.maintServiceApi.getCustomerPropertyServiceForLoggedInContractor();
        this.groupedCustomers = GroupedCustomer.fromCustomers(customers);
        this.allGroupedCustomers = GroupedCustomer.fromCustomers(customers);
    }

    save() {
        this.dialogRef.close(this.selectedCustomer);
    }

    filterChange(filter: string) {
        if (filter) {
            const lower = filter.toLowerCase();
            this.groupedCustomers =  this.allGroupedCustomers.filter(i => i.name?.toLowerCase().indexOf(lower) > -1 ||
                i.propertyAddress1?.toLowerCase().indexOf(lower) > -1 ||
                i.email?.toLowerCase().indexOf(lower) > -1 ||
                i.customerPhoneNumber?.toLowerCase().indexOf(lower) > -1);
        } else {
            this.groupedCustomers = this.allGroupedCustomers;
        }
    }
}
