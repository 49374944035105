import { DatePipe } from '@angular/common';
import { Question } from '@cogent/shared/models/common/question-parser.model';

import { ForecastModel } from '@cogent/shared/models/other/forecast.model';
import { Brand, Tag } from '@upkeeplabs/models/cogent';

export class Questionnaire {
    id: string;
    name: string;
    process: string;
}


export class QuestionnaireEnvironmentalVariables {

    sessionVariables: any = {};
    questions: Question[];
    objectInScope: any;
    objectTags: Tag[];
    userType: string;
    forecast: ForecastModel[];
    brand: Brand;

    hasTag(tagNameOrId: string): string {

        if (!this.objectTags) {
            return 'False';
        }

        const objectTag = this.objectTags.find(i => i.id === tagNameOrId || i.name === tagNameOrId);
        if (objectTag) {
            return 'True';
        }

        return 'False';
    }

    getSessionVariable(key: string) {
        return this.sessionVariables[key];
    }

    doesNotHaveTag(tagNameOrId: string) {
        return this.hasTag(tagNameOrId) === 'True' ? 'False' : 'True';
    }

    getMonthNumber() {
        return (new Date().getMonth() + 1).toString();
    }

    getMonthName() {
        const datePipe = new DatePipe('en-US');

        return datePipe.transform(new Date(), 'MMMM');
    }

    getMaxForecastedHigh() {
        if (!this.forecast) {
            return;
        }

        return Math.max(...this.forecast.map(i => i.high));
    }

    getMinForecastedHigh() {
        if (!this.forecast) {
            return;
        }

        return Math.min(...this.forecast.map(i => i.high));
    }

    getMinForecastedLow() {
        if (!this.forecast) {
            return;
        }

        return Math.min(...this.forecast.map(i => i.low));
    }

    getMaxForecastedLow() {
        if (!this.forecast) {
            return;
        }
        return Math.max(...this.forecast.map(i => i.low));
    }

     evaluateExpression(expression: string): any {
         const evaluationResult = new Function('environment', expression)(this);
        return evaluationResult;
    }

    questionValue(question: string) {
        if (!this.questions) {
            return null;
        }

        const q = this.questions.find(i => i.text.toLowerCase() === question.toLowerCase());
        if (q) {
            return q.answer;
        }

        return null;
    }

    dateDifferenceInDays(date1: Date, date2: Date) {
        return this.dateDifferenceInHours(date1, date2) / 24;
    }

    dateDifferenceInHours(date1: Date, date2: Date) {
        return this.dateDifferenceInMinutes(date1, date2) / 60;
    }

    dateDifferenceInMinutes(date1: Date, date2: Date) {
        return this.dateDifferenceInSeconds(date1, date2) / 60;
    }
    dateDifferenceInSeconds(date1: Date, date2: Date) {
        const differenceInTime = date2.getTime() - date1.getTime();
        return differenceInTime / (1000);
    }

    
}
