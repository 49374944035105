import { Injectable } from '@angular/core';
import { WorkOrderClient } from '@cogent/client/shared/models/service/work-order-client.model';
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { TimeZoneModel } from '@cogent/shared/models/other/time-zone.model';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { ClientMenuItem, Disposition, Trade } from '@upkeeplabs/models/cogent';
import { ObjectChange } from '@cogent/shared/models/common/object-change.model';
import { ApiService } from '@cogent/client/api';

@Injectable({ providedIn: 'root' })
export class DataApiService {
    constructor(private api: ApiService) { }

    getTrades(): Promise<Trade[]> {
        return this.api.getArrayDotNet('trade', { orderby: 'name' }, () => new Trade());
    }

    deleteContractorTrade(idsToDelete: string[]): Promise<any> {
        return this.deleteIds('contractortrade', idsToDelete);
    }

    getUnsubscribeGroups() {
        return this.api.getArrayNode(`document/unsubscribe-groups`);
    }

    getIPPools() {
        return this.api.getArrayNode('document/ip-pools');
    }

    private deleteIds(endpointUrl: string, idsToDelete: string[]) {
        return this.api.postVoidDotNet(endpointUrl + '/DeleteList', idsToDelete);
    }

    updateItemCost(itemId: string, newCost: number) {
        return this.api.patchDotNet(`Item/${itemId}`, { defaultAmount: newCost });
    }

    getChangeHistory(objectId: string) {
        return this.api.getArrayDotNet(`change-history/${objectId}`, null, () => new ObjectChange());
    }

    // @httpPost('/coms-query')
    // public async GetComsQuery() {
    //     const dc = new CogentDataContext();
    //     console.log(dc.getUserId());

    //     // TODO: Implement tight security on this one.
    //     const sql = this.request.body.sql;
    //     let resultCount = this.request.body.resultCount;
    //     if(!resultCount) {
    //         resultCount = 20;
    //     }




    getWorkOrderItemList(): Promise<any> {
        return this.api.getArrayDotNet('WorkOrderItem/select?properties=id,name&orderby=name');
    }

    getPlanItemDetail(id: string): Promise<any> {
        return this.api.getSingleDotNet('planitem/' + id + '/detail');
    }

    private postItems(endpointUrl: string, itemsToPost: any[]) {
        return this.api.postVoidDotNet(endpointUrl + '/PostList', itemsToPost);
    }

    getPropertyMeta(streetAddress: string, postalCode: string): Promise<PropertyMetaModel> {
        return this.api.getSingleDotNet('PropertyMeta', {
            address: streetAddress,
            zip: postalCode
        });
    }

    saveClientMenuItem(clientMenuItem: ClientMenuItem): Promise<string> {
        return this.api.postIdDotNet('ClientMenuItem', clientMenuItem);
    }

    getClientMenuItemByUrl(url: string): Promise<ClientMenuItem[]> {
        return this.api.getArrayDotNet('ClientMenuItem', { url_eq: url }, () => new ClientMenuItem());
    }

    submitWorkOrder(workOrder: WorkOrderClient): Promise<string> {
        return this.api.postIdDotNet('WorkOrder', workOrder);
    }

    searchGlobal(searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayNode("Search", { searchFor }, () => new SearchResultModel());
    }

    searchNotes(searchFor: string): Promise<any[]> {
        return this.api.getArrayNode(`Search/search-notes`, { searchFor });
    }

    searchForTypeAndSubtype(type: string, subtype: string, searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayDotNet('Search/' + type + '/' + subtype, { searchFor }, () => new SearchResultModel());
    }

    searchForTypeAndSubtypeAuthenticated(type: string, subtype: string, searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayNode('Search/authenticated/' + type + '/' + subtype, { searchFor }, () => new SearchResultModel());
    }

    searchForTypeAndSubtypeAuthenticated2(type: string, subtype: string, subtype2: string, searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayNode('Search/authenticated/' + type + '/' + subtype + '/' + subtype2, { searchFor }, () => new SearchResultModel());
    }

    searchForTypeSubtypeAndField(type: string, subtype: string, searchFor: string, field: string): Promise<SearchResultModel[]> {
        return this.api.getArrayDotNet('Search/' + type + '/' + subtype + '/' + field, { searchFor }, () => new SearchResultModel());
    }

    getSearchItemById(id: string) {
        return this.api.getSingleDotNet(`Search/${id}/find-by-id`);
    }

    getSearchItemById2(id: string) {
        return this.api.getSingleNode(`Search/${id}/find-by-id`);
    }

    searchForType(type: string, searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayNode(`Search/${type}`, { searchFor }, () => new SearchResultModel());
    }

    deleteSearchObject(id: string) {
        return this.api.deleteDotNet(`Search/${id}`);
    }

    getWorkOrderHtml(workOrderId: string): Promise<string> {
        return this.api.getTextDotNet('WorkOrderHtml/' + workOrderId);
    }

    getWorkOrderProcess(workOrderItemId: string): Promise<string> {
        return this.api.getSingleDotNet('WorkOrderProcess/' + workOrderItemId);
    }

    getWorkOrderProcessNoBrands(workOrderItemId: string): Promise<string> {

        return this.api.getSingleDotNet('WorkOrderProcess/' + workOrderItemId + '/no-brands');
    }

    updateWorkOrderItemPicture(workOrderItemId: string, base64: string, name = '') {
        if (!name) {
            name = workOrderItemId + '.jpg';
        }

        return this.api.postVoidDotNet('WorkOrderItem/' + workOrderItemId + '/Photo', { file: base64, name });
    }

    getWorkOrderItem(workOrderItemId: string): Promise<WorkOrderItemClient> {
        return this.api.getSingleDotNet('WorkOrderItem/' + workOrderItemId, null, () => new WorkOrderItemClient());
    }

    getHomeDepotProductInfo(homeDepotProductId: string) {
        return this.api.getSingleNode(`cash-out/home-depot-product/${homeDepotProductId}`);
    }

    getAuthoProcess(workOrderItemId: string): Promise<string> {
        return this.api.getTextDotNet('AuthoProcess/' + workOrderItemId);
    }

    getAllWorkOrderItems(): Promise<WorkOrderItemClient[]> {
        return this.api.getArrayDotNet('WorkOrderItem', { select: 'Id,Name,Sort,TradeId,Category,CategorySort,Priority,AllowTimeSlotSelection,Inactive,authorizationAmountOverride,allowWithTradeId,monthsToCheckPreExistingStatus,isService,singleItemJobItem,installationFee,CanBeReplaced,AllowTimeSlotSelectionOverride,CashOutThresholdAmount,AuthorizationFunctionId', orderby: 'Name' }, () => new WorkOrderItemClient());
    }

    getAllWorkOrderItems2(): Promise<WorkOrderItemClient[]> {
        return this.api.getArrayNode('WorkOrderItem', { select: 'Id,Name,Sort,TradeId,Category,CategorySort,Priority,AllowTimeSlotSelection,Inactive,authorizationAmountOverride,allowWithTradeId,monthsToCheckPreExistingStatus,isService,singleItemJobItem,installationFee,CanBeReplaced,AllowTimeSlotSelectionOverride,CashOutThresholdAmount', orderby: 'Name' }, () => new WorkOrderItemClient());
    }

    saveWorkOrderProcess(workOrderItemId: string, xml: string): Promise<any> {
        return this.api.putDotNet('WorkOrderProcess/' + workOrderItemId, { xml });
    }

    saveAuthoProcess(workOrderItemId: string, xml: string): Promise<any> {
        return this.api.putDotNet('AuthoProcess/' + workOrderItemId, { xml });
    }

    getDispositions(type: string): Promise<Disposition[]> {
        return this.api.getArrayDotNet('disposition', { type_eq: type, orderby: 'sort' }, () => new Disposition());
    }

    getTimeZones(): Promise<TimeZoneModel[]> {
        return this.api.getArrayDotNet('TimeZone', null, () => new TimeZoneModel());
    }


    async getScriptVersion(): Promise<string> {
        const version = await this.api.getTextDotNet(window.location.origin + "/" + '?version_check=' + new Date().getTime());
        return version;
    }
}