import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarDayViewerComponent } from './calendar-day-viewer/calendar-day-viewer.component';



@NgModule({
  declarations: [
    CalendarDayViewerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
      CalendarDayViewerComponent
  ]
})
export class CalendarDayViewerModule { }
 