import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailArgs } from '@cogent/shared/models/other/email-args.model';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module'
import { MatChipsModule } from '@angular/material/chips';
import { EmailComposeComponent } from '../email-compose/email-compose.component';


@Component({
    selector: 'app-email-compose-modal',
    templateUrl: './email-compose-modal.component.html',
    styleUrls: ['./email-compose-modal.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDialogModule,
        FormsModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        DragDropModule,
        RichTextAndSnippetModule,
        EmailComposeComponent
    ]
})
export class EmailComposeModalComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<EmailComposeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public emailArgs: EmailArgs) { }

    ngOnInit() {
    }

    messageSent() {
        this.dialogRef.close(true);
    }
}
