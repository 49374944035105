import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { ApiService } from '@cogent/client/api';

@Component({
    selector: 'app-find-user-polices-by-phone-or-email',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule],
    templateUrl: './find-user-polices-by-phone-or-email.component.html',
    styleUrls: ['./find-user-polices-by-phone-or-email.component.css']
})
export class FindUserPolicesByPhoneOrEmailComponent implements OnInit {

    phoneOrEmail: string;
    @Input() currentStep = 'request-code';
    @Output() currentStepChange: EventEmitter<string> = new EventEmitter();
    code: number;
    policies: any[];
    @Input() selectedPolicy: any;
    @Output() selectedPolicyChange: EventEmitter<any> = new EventEmitter();
    validating = false;
    gettingCode = false;

    constructor(private policyApi: PolicyApiService,
        private dialog: DialogsService) { }

    ngOnInit(): void {
    }

    change() {
        this.selectedPolicy = null;
        this.selectedPolicyChange.emit(null);
        this.currentStep = 'choose-policy';
    }

    restart() {
        this.currentStep = 'request-code';
        this.phoneOrEmail = '';
        delete this.code;
    }

    requestVerificationCode() {
        this.gettingCode = true;
        this.policyApi.requestVerificationCodeByEmailOrText(this.phoneOrEmail).then(result => {
            if (!result) {
                this.dialog.alert('Phone Or Email', `It looks like what you entered wasn't a phone number or an email`);
            } else {
                this.currentStep = 'enter-code';
                this.currentStepChange.emit(this.currentStep);
            }
            this.gettingCode = false;
        });

    }

    async validateVerificationCode() {
        this.validating = true;
        const result = await this.policyApi.validateVerificationCode(this.phoneOrEmail, this.code);
        if (result.success) {
            this.policies = result.data.filter(i=>i.status === 'Active' || i.status === 'Pending');
            this.currentStep = 'choose-policy';
            this.currentStepChange.emit(this.currentStep);
        } else {
            this.dialog.alert('No Match', `Sorry, it doesn't look like that code matched.`);
        }
        this.validating = false;
    }


    getPictureUrl(item) {
        return ApiService.endPointDotNet + 'address/' + item.addressId + '/StreetView';
    }

    selectPolicy(policy) {
        this.selectedPolicy = policy;
        this.selectedPolicyChange.emit(policy);
        // this.currentStep = 'policy-chosen';
        this.currentStepChange.emit(this.currentStep);
    }

}
