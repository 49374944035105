import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { Entity, MaintenanceServiceCustomerPropertySummary } from "@upkeeplabs/models/cogent";
import { HistoryItem } from "@cogent/shared/models/user/history-item.model";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';

@Injectable({ providedIn: 'root' })
export class HistoryItemApiService {

    constructor(
        private api: ApiService
    ) { }

    getCurrentUserRecentItems(take = 5): Promise<HistoryItem[]> {
        return this.api.getArrayDotNet("HistoryItem", { createdById_eq: '{{currentUser}}',  orderBy: 'CreatedDate descending', take }, () => new HistoryItem());
    }

    getCurrentUserHistoryItems(startDate: Date, endDate: Date, take = 5): Promise<HistoryItem[]> {
        return this.api.getArrayDotNet("HistoryItem", {
            createdById_eq: '{{currentUser}}',
            orderBy: 'CreatedDate descending',
            take,
            createdDate_gte: startDate,
            createdDate_lte: endDate
        }, () => new HistoryItem());
    }

    getCurrentUserHistoryItemsByType(type: string, take = 5): Promise<HistoryItem[]> {
        return this.api.getArrayDotNet("HistoryItem", {
            createdById_eq: '{{currentUser}}',
            orderBy: 'CreatedDate descending',
            take,
            type_eq: type
        }, () => new HistoryItem());
    }

    save(historyItem: HistoryItem): Promise<string> {
        return this.api.postIdDotNet("HistoryItem", historyItem);
    }


    savePolicyHistoryItem(policySummary: PolicySummary): Promise<any> {
        const recentItem = new HistoryItem();
        recentItem.descriptionLine1 = 'Subscription #' + policySummary.policyNumber;
        recentItem.descriptionLine2 = policySummary.holder.name;
        recentItem.descriptionLine3 = policySummary.propertyAddress.address1;
        recentItem.url = '/subscription/' + policySummary.id;
        recentItem.type = 'Policy';

        return this.save(recentItem);
    }

    saveMaintenanceServiceHistoryItem(property: MaintenanceServiceCustomerPropertySummary) {
        const recentItem = new HistoryItem();
        recentItem.descriptionLine1 = property.customerName;
        recentItem.descriptionLine2 = property.propertyAddress1;
        recentItem.descriptionLine3 = property.orderedServices;
        recentItem.url = `/subscription/maint-service-customer-property/${property.id}`;
        recentItem.type = 'MaintServiceProperty';

        return this.save(recentItem);
    }

    saveContractorHistoryItem(contractor: Entity) {
        const recentItem = new HistoryItem();
        recentItem.descriptionLine1 = 'Service Pro';
        recentItem.descriptionLine2 = contractor.displayName;
        recentItem.descriptionLine3 = contractor.officeNumber ? contractor.officeNumber.number : '';
        recentItem.url = '/service-pro-relations/' + contractor.id + '/overview';
        recentItem.type = 'Contractor-View';

        return this.save(recentItem);
    }

    saveWorkOrderHistoryItem(workOrderSummary: WorkOrderSummaryClient): Promise<any> {
        const recentItem = new HistoryItem();

        recentItem.descriptionLine1 = `Job # ${workOrderSummary.number}`;
        recentItem.descriptionLine2 = workOrderSummary.homeownerName;
        recentItem.descriptionLine3 = `${workOrderSummary.propertyAddress} (${workOrderSummary.propertyCity})`;
        //recentItem.descriptionLine4 = workOrderSummary.propertyCity;
        recentItem.url = '/service/claim-detail/' + workOrderSummary.id;
        recentItem.type = 'WO';

        return this.save(recentItem);
    }

}
