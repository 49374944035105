import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { FavorFundWeightItemSummary } from "./favor-fund-weight-item-summary.model";

@dataSource()
export class FavorFundWeight {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById: string;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;

    items: FavorFundWeightItemSummary[];
    selected = false;
    totalPercent = 0;

    get itemsTotal() {
        let sum = 0;
        this.items.forEach(i => {
            if (i.month >= i.startMonth) sum += i.monthlyBudget;
        });
        return sum;
    }
}