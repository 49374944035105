import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class BundleAndSave {
    @keyColumn() id: string;
    @column() name: string;
    @column() dateActive: Date;
    @column() configuration: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() archivedDate: Date;


    private _config: BundleAndSaveItem[];
    get config() {
        if (!this._config && this.configuration) {
            try {
                this._config = JSON.parse(this.configuration);
            } catch { }
        }
        if(!this._config) {
            this._config = [];
        }
        return this._config;
    }
    set config(value: BundleAndSaveItem[]) {
        this._config = value;
    }
}

export class BundleAndSaveItem {
    numberOfServices: number;
    discount: number;
}