import { CommonModule } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { RemoteStorageService } from '@cogent/client/shared/services/api/remote-storage.service';
import { DateRangeType } from '@cogent/shared/models/common/date-range-type.model';
import { DateRangeSelectionComponent } from '@cogent/client/shared/components/misc/date-range-selection/date-range-selection.component';
import { MatIconModule } from '@angular/material/icon';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';


class DateRangeDisplayValues {
    dateRange: DateRangeType;
}

@Component({
    selector: 'app-date-range-display-and-selector',
    standalone: true,
    imports: [CommonModule,
        MatButtonModule,
        DateRangeSelectionComponent,
        MatIconModule,
        DatePickerWrapperComponent],
    templateUrl: './date-range-display-and-selector.component.html',
    styleUrls: ['./date-range-display-and-selector.component.css']
})
export class DateRangeDisplayAndSelectorComponent implements OnInit, OnChanges {

    @Input() startDate: Date;
    @Input() endDate: Date;
    @Output() startDateChange: EventEmitter<Date> = new EventEmitter();
    @Output() endDateChange: EventEmitter<Date> = new EventEmitter();
    @Input() memoryKey: string;
    @Input() showFutureDates: boolean;
    @Input() location: string;


    @Input() selectedRange: DateRangeType;
    // @Input() selectedRangeChange: EventEmitter<DateRangeType> = new EventEmitter();
    showDateRange: boolean;

    constructor(private remoteStorage: RemoteStorageService) { }

    ngOnInit() {
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.memoryKey && changes.memoryKey.currentValue) {

            const data = await this.remoteStorage.getObject(changes.memoryKey.currentValue, () => new DateRangeDisplayValues());
            if (data) {
                const dates = UtilitiesService.getDatesFromDateRange(data.dateRange);
                if (dates[0]) {
                    this.startDate = dates[0];
                    this.startDateChange.emit(this.startDate);
                }
                if (dates[1]) {
                    this.endDate = dates[1];
                    this.endDateChange.emit(this.endDate);
                }
            }

        }
        if (changes && changes.startDate && changes.startDate.currentValue) {
            this.setDateRange();
            this.startDate = changes.startDate.currentValue;
        }
        if (changes && changes.endDate && changes.endDate.currentValue) {
            this.setDateRange();
            this.endDate = changes.endDate.currentValue;
        }
    }

    private setDateRange() {
        const range = UtilitiesService.getDateRangeFromDates(this.startDate, this.endDate);
        if (range) {
            this.selectedRange = range;
        } else {
            this.selectedRange = DateRangeType.None;
        }
    }

    async selectedRangeChange(range: DateRangeType) {
        const dates = UtilitiesService.getDatesFromDateRange(range);
        if (dates[0]) {
            this.startDate = dates[0];
            this.startDateChange.emit(this.startDate);
        }
        if (dates[1]) {
            this.endDate = dates[1];
            this.endDateChange.emit(this.endDate);
        }
        if (this.memoryKey) {
            const dateRangeDisplayValues = new DateRangeDisplayValues();
            dateRangeDisplayValues.dateRange = range;
            await this.remoteStorage.setObject(this.memoryKey, dateRangeDisplayValues);
        }
        this.showDateRange = false;
    }

    customStartDateChange(date: Date) {
        this.startDateChange.emit(date);
        this.startDate = date;
        this.setDateRange();
    }

    customEndDateChange(date: Date) {
        this.endDateChange.emit(date);
        this.endDate = date;
        this.setDateRange();
    }
}
