import { PaymentSummary } from "@upkeeplabs/models/cogent";

export class CancellationInfo {
    policyId: string;
    totalPremiumsPaid: number;
    cancelationFee: number;
    unpaidServiceFee: number;
    costOfClaims: number;
    purchaseOrderCost: number;
    effectiveDate: Date;
    cashOutCost: number;
    totalPlanPrice: number;
    cancellationDate: Date;
    monthsOfCoverage: number;
    costPerDay: number;
    totalPremiumUsed: number;
    totalCostOfClaims: number;
    canCollectForCostOfClaims: boolean;
    isMonthly: boolean;
    refundOrBalanceDue: number;
    cancelledReasonId: string;
    sendEmail: boolean;
    nameOnCheck: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingState: string;
    mailingPostalCode: string;
    refundOrBalanceDueOverride: number;
    cancelMode: string;
    creditPolicyId: string;
    creditCardPayments: PaymentSummary[];
    refundToCard: boolean;
    creditBalance: number;
    refundCreditBalance: boolean;

    get totalCreditCardPayments() {
        return this.creditCardPayments?.map(i => i.amount).reduce((a, b) => a + b, 0);
    }

    get canRefundToCreditCard() {
        return this.refundOrBalanceDueOverride <= this.totalCreditCardPayments;
    }
}