
export class NoteAttachmentModel {
    constructor(public name: string = null, public noteId: string = null, public base64: string = null) { }

    get isImage() {
        return this.base64 && this.base64.indexOf('data:image/') > -1;
    }

    isHomeInspection?: boolean;
}
