
export class CompanyStatesAndRegions {
    id: string;
    name: string;
    assignedEmployeeId: string;
    regions: CompanyRegion[];

    constructor(private loggedInUserId: string) { }

    get isMyState() {
        return this.assignedEmployeeId === this.loggedInUserId;
    }

    // client-side only 

    get selected() {
        return this.regions.filter(i => !i.selected).length === 0;
    }

    set selected(value: boolean) {
        this.regions.forEach(i => i.selected = value);
    }

    get childrenSelected() {
        if (!this.regions) {
            return false;
        }
        return this.regions.filter(i => !i.childrenSelected).length === 0;
    }

    set childrenSelected(value: boolean) {
        this.regions.forEach(i => {
            i.childrenSelected = value;
        });
    }
}

export class CompanyRegion {
    id: string;
    name: string;
    assignedEmployeeId: string;

    get isMyRegion() {
        // TODO: fix
        return true;
        // return this.assignedEmployeeId === ApiAccessService.authenticationInformation.id;
    }

    children: CompanyRegion[];

    // client-side only 
    selected;

    get childrenSelected() {
        if (!this.children) {
            return false;
        }
        return this.children.filter(i => !i.selected).length === 0;
    }

    set childrenSelected(value: boolean) {
        this.children.forEach(i => i.selected = value);
    }
}