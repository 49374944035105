import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import SignaturePad from 'signature_pad';


@Component({
    selector: 'app-signature',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule],
    templateUrl: './signature.component.html',
    styleUrl: './signature.component.scss'
})
export class SignatureComponent implements OnInit, OnDestroy {

    signatureCanvasId = UtilitiesService.newid();
    @Input() label = 'Customer Signature';
    pad: SignaturePad;
    @Input() dataUrl: string;
    @Output() dataUrlChange: EventEmitter<string> = new EventEmitter();
    @Input() height = '150px';

    constructor(private ngZone: NgZone) {

    }

    ngOnInit() {
        this.initialize();
    }
    ngOnDestroy(): void {

    }

    clear() {
        this.pad.clear();
        this.dataUrlChange.emit('');
    }

    initialize() {
        const canvas: HTMLCanvasElement = document.getElementById(this.signatureCanvasId) as HTMLCanvasElement;
        if (!canvas) {
            setTimeout(() => this.initialize(), 100);
            return;
        }

        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);


        this.pad = new SignaturePad(canvas, {
            maxWidth: 1.5,
        });
        this.pad.addEventListener('endStroke',()=> {
            this.ngZone.run(()=> {
                const dataUrl = this.pad.toDataURL();
                this.dataUrlChange.emit(dataUrl);
            });
        });
    }
}
