import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { WorkOrderAttachment } from '@cogent/shared/models/service/work-order-attachment.model';
import { MaintenanceServiceCustomerPropertyServiceAppointmentSummary } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-maint-services-complete-appointment',
    templateUrl: './maint-services-complete-appointment.component.html',
    styleUrls: ['./maint-services-complete-appointment.component.css']
})
export class MaintServicesCompleteAppointmentComponent implements OnInit {

    selectedIndex = 0;
    photos: WorkOrderAttachment[];
    saving = false;
    comments: string;
    completionDate: Date;
    editMode = false;

    constructor(public dialogRef: MatDialogRef<MaintServicesCompleteAppointmentComponent>,
        private maintApi: MaintenanceServiceApiService,
        @Inject(MAT_DIALOG_DATA) public data: MaintenanceServiceCustomerPropertyServiceAppointmentSummary,) { }

    ngOnInit(): void {
        if (this.data.scheduledDate > new Date()) {
            this.completionDate = new Date();
        } else {
            this.completionDate = new Date(this.data.scheduledDate);
        }
        if (window.innerWidth < 700) {
            this.dialogRef.updateSize('95%');
        } else {
            this.dialogRef.updateSize('500px');
        }
    }

    edit() {
        // this.data.completedDate = null;
        this.editMode = true;
    }

    doComplete() {
        this.saving = true;
        this.maintApi.completeAppointment({
            id: this.data.id,
            comments: this.comments,
            attachments: this.photos,
            completionDate: this.completionDate,
        }).then(() => {
            this.saving = false;
            this.selectedIndex = 1;
            setTimeout(() => {
                this.dialogRef.close(true);
            }, 2500);
        })

    }

}
