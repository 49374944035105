<div class="container">
    <h1 class="page-title">
        <span class="big">Reset</span><br>
        <span class="little">Password</span>
    </h1>

    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First">
            <form>
                <mat-form-field style="max-width: 450px;" appearance="outline" topmargin10>
                    <mat-label>Email Address</mat-label>
                    <input name="txtEmail" matInput type="email" [(ngModel)]="emailAddress">
                </mat-form-field>

                <button mat-button mat-raised-button color="primary" large [disabled]="!canSend || sending"
                    (click)="send()">
                    <mat-spinner *ngIf="sending" class="thirty"></mat-spinner>
                    Send</button>
            </form>
        </mat-tab>
        <mat-tab label="Second">
            <mat-form-field style="max-width: 450px;" appearance="outline">
                <mat-label>Verification Code</mat-label>
                <input matInput type="number" [(ngModel)]="code">
            </mat-form-field>

            <mat-form-field style="max-width: 450px" appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput type="password" [(ngModel)]="newPassword">
            </mat-form-field>

            <mat-form-field style="max-width: 450px" appearance="outline">
                <mat-label>Confirm New Password</mat-label>
                <input matInput type="password" [(ngModel)]="newPasswordConfirm">
            </mat-form-field>

            <button mat-button (click)="selectedIndex = 0"><mat-icon>chevron_left</mat-icon> Back</button>
            <button mat-button mat-raised-button color="primary" large [disabled]="!canReset || resetting"
                (click)="reset()">
                <mat-spinner *ngIf="resetting" class="thirty"></mat-spinner>
                Reset</button>
        </mat-tab>
        <mat-tab label="Third">
            <img class="success-icon" src="../../../assets/images/success-icon.png">

            <div style="text-align: center;">
                <h1 topmargin40>Password Reset</h1>
                <p style="font-style: italic;">You should now be able to log in with your new password.</p>


                <a topmargin30 mat-button mat-raised-button color="primary" large routerLink="/">Log In</a>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>