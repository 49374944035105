import { CustomFunction } from "./custom-function.model";
import { Tag } from "./tag.model";
import { Template } from "./template.model";

export class CustomFunctionExport {
    function: CustomFunction;
    tags: Tag[];
    templates: Template[];
    templateFiles: TemplateFile[];
    functionExists: boolean;
    saveFunction: boolean;
}

export class TemplateFile {
    id: string;
    contents: string;
    contentFile: string;
}