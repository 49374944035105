import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { WorkOrderItemProblemRepairItem } from "./work-order-item-problem-repair-item.model";
import { WorkOrderItemProblemType } from "./work-order-item-problem-type.model";

@dataSource()
export class WorkOrderItemProblem {
    @keyColumn() id: string;
    @column() workOrderItemId: string;
    @column() name: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() sort: number;

    isNew: boolean;
    isEdited: boolean;
    repairItems: WorkOrderItemProblemRepairItem[];
    types: WorkOrderItemProblemType[];
    selectedRepairItems: WorkOrderItemProblemRepairItemSelector[] = [];
    selectedReplacementItems: WorkOrderItemProblemRepairItemSelector[] = [];
    selectedTypes: WorkOrderItemProblemTypeSelector[] = [];
    selected: boolean;
}

export class WorkOrderItemProblemTypeSelector {
    workOrderItemTypeId: string;
    name: string;
    isChecked: boolean;
}
export class WorkOrderItemProblemRepairItemSelector {
    repairItemId: string;
    name: string;
    isChecked: boolean;
    isReplacement: boolean;
    types: WorkOrderItemProblemTypeSelector[];
}

// export class WorkOrderItemProblemRepairItem {
//     id: string;
//     createdById: string;
//     lastModifiedById: string;
//     createdDate: Date;
//     lastModifiedDate: Date;
//     deletedDate: Date;
//     workOrderItemProblemId: string;
//     repairItemId: string;
//     isReplacement: boolean;
//     workOrderItemTypes: string;
// // }
// export class WorkOrderItemProblemType {
//     id: string;
//     createdById: string;
//     lastModifiedById: string;
//     createdDate: Date;
//     lastModifiedDate: Date;
//     deletedDate: Date;
//     workOrderItemProblemId: string;
//     workOrderItemTypeId: string;
// }