import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class InvoiceToProcess {
    @column() id: string;
    @column() amountDue: number;
    @column() invoiceNumber: number;
    @column() policyNumber: number;
    @column() policyId: string;
    @column() scheduledDate: Date;
    @column() createdDate?: Date;
    @column() stripeCardId: string;
    @column() holderId: string;
    @column() stripeCustomerId: string;
    @column() customerName: string;
    @column() propertyAddress: string;
    @column() memo: string;
    @column() accountingAreaId: string; 
}
