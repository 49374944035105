import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class StripePayout {
    @keyColumn() id: string;
    @column() amount: number;
    @column() arrivalDate: Date;
    @column() balanceTransaction: string;
    @column() description: string;
    @column() destination: string;
    @column() stripeId: string;
    @column() status: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() stripeAccount: string;
    @column() exportDate: Date;

    static fromApi(c){
        const payout = new StripePayout();
        payout.amount = c.amount / 100;
        payout.arrivalDate = new Date(c.arrival_date * 1000);
        payout.balanceTransaction = c.balance_transaction as string;
        payout.createdDate = new Date(c.created * 1000);
        payout.description = c.description;
        payout.destination = c.destination as string;
        payout.stripeId = c.id;
        payout.status = c.status;
        return payout;
    }
}

@dataSource()
export class StripePayoutSummary {
    @column() name: string;
    @keyColumn() id: string;
    @column() amount: number;
    @column() arrivalDate: Date;
    @column() balanceTransaction: string;
    @column() description: string;
    @column() destination: string;
    @column() stripeId: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() stripeAccount: string;
    @column() status: string;
    @column() exportDate: Date;
}

@dataSource()
export class StripeCharge {
    @keyColumn() id: string;
    @column() amount: number;
    @column() applicationFee: string;
    @column() applicationFeeAmount: number;
    @column() balanceTransaction: string;
    @column() customer: string;
    @column() description: string;
    @column() destination: string;
    @column() stripeId: string;
    @column() outcome: string;
    @column() paid: boolean;
    @column() paymentMethod: string;
    @column() paymentMethodDetail: string;
    @column() receiptUrl: string;
    @column() status: string;
    @column() transfer: string;
    @column() transferGroup: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() stripeAccount: string;

    static fromApi(c){
        const charge = new StripeCharge();
        charge.amount = c.amount / 100;
        charge.applicationFee = c.application_fee as string;
        charge.applicationFeeAmount = c.application_fee_amount / 100;
        charge.balanceTransaction = c.balance_transaction as string;
        charge.createdDate = new Date(c.created * 1000);
        charge.customer = c.customer as string;
        charge.description = c.description;
        charge.destination = c.destination as string;
        charge.outcome = JSON.stringify(c.outcome);
        charge.paid = c.paid;
        charge.paymentMethod = c.payment_method;
        charge.paymentMethodDetail = JSON.stringify(c.payment_method_details);
        charge.receiptUrl = c.receipt_url;
        charge.status = c.status;
        charge.stripeId = c.id;
        charge.transfer = c.transfer as string;
        charge.transferGroup = c.transfer_group;
        return charge;
    }
}


@dataSource()
export class StripeRefund {
    @keyColumn() id: string;
    @column() amount: number;
    @column() balanceTransaction: string;
    @column() charge: string;
    @column() stripeId: string;
    @column() sourceTransferReversal: string;
    @column() status: string;
    @column() transferReversal: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;

    @column() stripeAccount: string;
    static fromApi(c){
        const refund = new StripeRefund();
        refund.amount = c.amount / 100;
        refund.balanceTransaction = c.balance_transaction as string;
        refund.charge = c.charge;
        refund.createdDate = new Date(c.created * 1000);
        refund.status = c.status;
        refund.sourceTransferReversal = c.source_transfer_reversal
        refund.stripeId = c.id;
        refund.transferReversal = c.transfer_reversal as string;
        return refund;
    }
}

@dataSource()
export class StripeTransfer {
    @keyColumn() id: string;
    @column() amount: number;
    @column() balanceTransaction: string;
    @column() description: string;
    @column() destination: string;
    @column() stripeId: string;
    @column() sourceTransaction: string;
    @column() transferGroup: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;

    @column() stripeAccount: string;

    static fromApi(t){
        const transfer = new StripeTransfer();
        transfer.amount = t.amount / 100;
        transfer.balanceTransaction = t.balance_transaction as string;
        transfer.createdDate = new Date(t.created * 1000);
        transfer.description = t.description;
        transfer.destination = t.destination as string;
        transfer.stripeId = t.id;
        transfer.sourceTransaction = t.source_transaction as string;
        transfer.transferGroup = t.transfer_group;
        return transfer;
    }
}

@dataSource()
export class StripeBalanceTransaction {
    @keyColumn() id: string;
    @column() amount: number;
    @column() availableOn: Date;
    @column() description: string;
    @column() destination: string;
    @column() fee: number;
    @column() feeDetails: string;
    @column() stripeId: string;
    @column() net: number;
    @column() reportingCategory: string;
    @column() source: string;
    @column() status: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() lastModifiedById: string;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;

    @column() stripeAccount: string;
    @column() payout: string;

    static fromApi(b){
        const balanceTransaction = new StripeBalanceTransaction();
        balanceTransaction.amount = b.amount / 100;
        balanceTransaction.availableOn = new Date(b.available_on * 1000);
        balanceTransaction.createdDate = new Date(b.created * 1000);
        balanceTransaction.description = b.description;
        balanceTransaction.destination = b.destination as string;
        balanceTransaction.fee = b.fee / 100;
        balanceTransaction.feeDetails = JSON.stringify(b.fee_details);
        balanceTransaction.stripeId = b.id;
        balanceTransaction.net = b.net / 100;
        balanceTransaction.reportingCategory = b.reporting_category;
        balanceTransaction.source = b.source as string;
        balanceTransaction.status = b.status;
        return balanceTransaction;
    }
}