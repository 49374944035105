<div aflex>
    <h2 class="sub-heading">{{data.name}}</h2>

    <button mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>


<mat-dialog-content>
    <form>
        <mat-form-field appearance="outline">
            <mat-label>Amount</mat-label>
            <input matInput name="item-amount-box" id="item-amount-box" type="number" [(ngModel)]="amount">
        </mat-form-field>
        <button mat-button mat-raised-button color="primary" (click)="save()">
            <mat-icon>add</mat-icon>
            Add</button>
    </form>
</mat-dialog-content>