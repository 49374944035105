import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesCommissionReport {
    @keyColumn() id: string;
    @column() accountExecutiveName: string;
    @column() acciyntExecutiveId: string;
    @column() policyCount: number;
    @column() totalPrice: number;
    @column() month: Date;
    @column() commissionPercent: number;
    @column() commissionAmount: number;
    @column() averageCommissionPerSale: number;
    @column() averagePrice: number;


}