<div aflex>
    <h2 class="sub-heading">Change Preferred Day</h2>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div topmargin30 class="data-label">Select the day of the week</div>
<div bottommargin20>
    <button mat-button mat-raised-button title="Sunday" class="toggle-button day-button"
        (click)="preferredDay = 0" [class.selected]="preferredDay === 0">
        <img src="../../../assets/images/sunday.svg">
    </button>
    <button mat-button mat-raised-button title="Monday" class="toggle-button day-button"
        (click)="preferredDay = 1" [class.selected]="preferredDay === 1">
        <img src="../../../assets/images/monday.svg">
    </button>

    <button mat-button mat-raised-button title="Tuesday" class="toggle-button day-button"
        (click)="preferredDay = 2" [class.selected]="preferredDay === 2">
        <img src="../../../assets/images/tuesday.svg">
    </button>


    <button mat-button mat-raised-button title="Wednesday" class="toggle-button day-button"
        (click)="preferredDay = 3" [class.selected]="preferredDay === 3">
        <img src="../../../assets/images/wednesday.svg">
    </button>

    <button mat-button mat-raised-button title="Thursday" class="toggle-button day-button"
        (click)="preferredDay = 4" [class.selected]="preferredDay === 4">
        <img src="../../../assets/images/thursday.svg">
    </button>


    <button mat-button mat-raised-button title="Friday" class="toggle-button day-button"
        (click)="preferredDay = 5" [class.selected]="preferredDay === 5">
        <img src="../../../assets/images/friday.svg">
    </button>


    <button mat-button mat-raised-button title="Saturday" class="toggle-button day-button"
        (click)="preferredDay = 6" [class.selected]="preferredDay === 6">
        <img src="../../../assets/images/saturday.svg">
    </button>
</div>


<div mat-dialog-actions>
    <div aflex style="width: 100%">
        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button color="primary" (click)="save()" [disabled]="!canSave">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner> Change
            </button>
        </div>
    </div>
</div>