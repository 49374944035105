<div class="container fancy-container">
    <div aflex *ngIf="selectedIndex !== 2">
        <div>

            <h2 class="sub-heading">Add Note</h2>
        </div>

    </div>

    <div mat-dialog-content>
        <!-- <mat-form-field class="note-box" appearance="outline" topmargin30 *ngIf="note">
        <mat-label>Note</mat-label> -->
        <!-- <textarea rows="5" matInput [(ngModel)]="note.noteText"></textarea> -->

        <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
            <mat-tab label="First">
                <div class="notes-container">
                    <app-rich-text-and-snippet #richText height="170px" style="width:400px"
                        [mentionables]="mentionables" [quickNotes]="[]"
                        [(ngModel)]="note.noteText"></app-rich-text-and-snippet>
                </div>
            </mat-tab>
            <mat-tab label="Second">
                <mat-form-field appearance="outline">
                    <mat-label>Filter</mat-label>
                    <input matInput [(ngModel)]="mentionableFilter">
                </mat-form-field>
                <ul class="mentionables-list">
                    <li *ngFor="let entity of filteredMentionables">
                        <mat-checkbox [(ngModel)]="entity.selected">{{entity.name}}</mat-checkbox>
                    </li>

                </ul>
            </mat-tab>
            <mat-tab>
                <lottie-player style="height: 200px"
                    src="https://lottie.host/ff2cd52a-cd7b-4605-b060-d49308185aee/iLnVfd0Pq9.json"
                    background="transparent" speed="1" autoplay></lottie-player>
                <div class="center">
                    <h1>Note Added</h1>
                </div>
            </mat-tab>
        </mat-tab-group>


        <!--/mat-form-field -->
        <!--[mentionables]="employees"-->
    </div>
    <div mat-dialog-actions>
        <div style="width: 100%" flex>
            <div></div>
            <div nogrow *ngIf="selectedIndex == 0">
                <button mat-button (click)="showTag()">

                    <mat-icon>tag</mat-icon> Tag
                </button>
                <button mat-button mobile-block mat-raised-button [disabled]="!canSave" (click)="save()"
                    color="primary">
                    <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                    Save
                </button>
            </div>
            <div nogrow *ngIf="selectedIndex === 1">

                <button mat-button (click)="selectedIndex = 0">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button mat-button mobile-block mat-raised-button (click)="applyTags()" color="primary">
                    Apply Tags
                </button>
            </div>
        </div>

    </div>
</div>