import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { Hotspot, Polygon } from '@cogent/shared/models/authorizations/work-order-item-type-diagrams.models';
import { AuthorizationRepairItemSummary, WorkOrderItemTypeDiagram } from '@upkeeplabs/models/cogent';
import { LottiePlayerComponent } from '../../visuals/lottie-player/lottie-player.component';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
    selector: 'app-work-order-item-type-diagram-viewer',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatCheckboxModule, FormsModule, MatFormFieldModule, LottiePlayerComponent],
    templateUrl: './work-order-item-type-diagram-viewer.component.html',
    styleUrl: './work-order-item-type-diagram-viewer.component.scss'
})
export class WorkOrderItemTypeDiagramViewerComponent implements OnChanges, OnInit, OnDestroy {

    @Input() workOrderItemTypeId: string;
    diagrams: WorkOrderItemTypeDiagram[];
    selectedDiagram: WorkOrderItemTypeDiagram;
    hotspots: Hotspot[];
    svgId = UtilitiesService.newid();
    imageId = UtilitiesService.newid();
    @Output() closeClicked: EventEmitter<boolean> = new EventEmitter();
    authorizationRepairItemSelectors: AuthorizationRepairItemSelector[];
    @Output() authorizationRepairItemsSelected: EventEmitter<AuthorizationRepairItemSummary[]> = new EventEmitter();
    @Input() top = 0;


    constructor(private authApi: AuthorizationsApiService,
        private missionService: MissionService,

    ) { 
        
    }


    close() {
        this.closeClicked.emit(true);
    }

    ngOnInit(): void {
        this.missionService.publish({
            messageBody: '',
            type: 'type-diagram-open'
        });
    }

    ngOnDestroy(): void {
        this.missionService.publish({
            messageBody: '',
            type: 'type-diagram-close'
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workOrderItemTypeId.currentValue) {
            this.load();
        }
    }

    get height() {
        return `calc(100vh) - ${this.top}px)`;
    }

    get containerTop() {
        return `${this.top}px`;
    }

    load() {
        this.authApi.getWorkOrderItemTypeDiagrams(this.workOrderItemTypeId).then(diagrams => {
            console.log(diagrams);
            this.diagrams = diagrams;
            for (const diagram of diagrams) {
                diagram.url = `${ApiService.endPointNode}authorization/work-order-item-type-diagram/${diagram.id}`;
            }
            //this.selectDiagram(diagrams[0]);
        });
    }

    get canSave() {
        return this.authorizationRepairItemSelectors?.filter(i => i.selected).length > 0;
    }


    selectDiagram(diagram: WorkOrderItemTypeDiagram) {
        this.selectedDiagram = diagram;
        this.hotspots = JSON.parse(diagram.config);
    }

    imageLoaded() {
        const img: HTMLImageElement = document.getElementById(this.imageId) as HTMLImageElement;

        const svg = document.getElementById(this.svgId);
        svg.style.height = `${img.clientHeight}px`;
        svg.style.width = `${img.clientWidth}px`;
    }

    selectHotspot(hotspot: Hotspot) {
        switch (hotspot.action) {
            case 'ADD_REPAIR_ITEMS':
                this.authApi.getAuthorizationRepairItemsFromList(hotspot.repairItemIds).then(repairItems => {
                    this.authorizationRepairItemSelectors = repairItems.map(i => ({ authorizationRepairItem: i, selected: repairItems.length === 1 }));
                    if(this.authorizationRepairItemSelectors.length === 1) {
                        this.save();
                    }
                });
                break;
            case 'NAVIGATE_TO_DIAGRAM':
                const diagram = this.diagrams.find(i => i.id === hotspot.navigateToDiagramId);
                if (diagram) {
                    this.selectDiagram(diagram);
                }
                break;
        }
    }

    getPolygonPoints(polygon: Polygon) {
        let pointsToString = '';
        for (const point of polygon.points) {
            pointsToString += `${point.x} ${point.y} `;
        }
        pointsToString = pointsToString.trim();
        return pointsToString;
    }

    getFill(hotSpot: Hotspot) {
        return 'rgba(0,0,0,.3)';
    }

    save() {
        const selectedItems = this.authorizationRepairItemSelectors.filter(i => i.selected).map(i => i.authorizationRepairItem);
        this.authorizationRepairItemsSelected.emit(selectedItems);
    }
}

class AuthorizationRepairItemSelector {
    authorizationRepairItem: AuthorizationRepairItemSummary;
    selected: boolean;
}
