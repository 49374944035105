import { Role } from '@upkeeplabs/models/cogent';
import { ObjectQueue } from '@cogent/client/shared/models/object-queue.model';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';

export class ObjectBoardQueueQueryItem {
    queueId: string;
    dropCommandId: string;
    id: string;
    queueDragHandleUrlField: string;
    titleField: string;
    line1Field: string;
    line2Field: string;
    line3Field: string;
    percentCompleteField: string;
}

export class ObjectBoardQueueQuery {
    queueItems: ObjectBoardQueueQueryItem[];
    iconClass: string;
    availableToEmployees: SearchResultModel[];
    hideExplorerBar: boolean;
    calendarDateField: string;
    completionDateField: string;
    effortField: string;
    ownerField: string;
    tagsField: string;
}
export class ObjectQueuesBoard {
    id: string;
    name: string;
    type: string;
    configuration: string;
    roles: string;
    availableToAllRoles: boolean;
    grouping: string;

    private queryObject: ObjectBoardQueueQuery;
    private roleObjectInternal: Role[];

    get queueQuery(): ObjectBoardQueueQuery {
        if (this.queryObject) {
            return this.queryObject;
        }
        if (!this.configuration) {
            this.queryObject = new ObjectBoardQueueQuery();
            this.queryObject.queueItems = [];
        } else {
            this.queryObject = JSON.parse(this.configuration);
        }

        return this.queryObject;
    }

    set queueQuery(query: ObjectBoardQueueQuery) {
        this.queryObject = null;
        this.configuration = JSON.stringify(query);
    }

    get rolesObject(): Role[] {
        if (this.roleObjectInternal) {
            return this.roleObjectInternal;
        }
        if (!this.roles) {
            this.roleObjectInternal = [];
        } else {
            this.roleObjectInternal = JSON.parse(this.roles);
        }

        return this.roleObjectInternal;
    }
    set rolesObject(roles: Role[]) {
        this.roleObjectInternal = null;
        this.roles = JSON.stringify(roles);
    }

    // client-side only
    queues: ObjectQueue[];
    isNew: boolean;
}