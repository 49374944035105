export class ExpensesByTradeForCustomer {
    tradeName: string;
    totalCashOuts: number;
    totalContractorInvoices: number;
    totalPurchaseOrders: number;
    totalExpenses: number;

    get retailTotalExpenses() {
        return this.totalExpenses * 1.25;
    }
}