import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class MlsAgent {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedDate?: Date;
    @column() lastModifiedById?: string;
    @column() deletedDate?: Date;
    @column() mls: string;
    @column() mlsId: number;
    @column() name: string;
    @column() email: string;
    @column() imageUrl: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() zip: string;
    @column() licenseNumber: string;
    @column() officePhone: string;
    @column() mobilePhone: string;
    @column() officeId: string;
}