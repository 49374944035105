import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DataSecurityRule {
    @keyColumn() id: string;
    @column() dataObject: string;
    @column() entityType: string;
    @column() roleId: string;
    @column() entityId: string;
    @column() createRule: string;
    @column() readRule: string;
    @column() readFilter: string;
    @column() modifyRule: string;
    @column() deleteRule: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
}

@dataSource()
export class DataSecurityRuleSummary {
    @keyColumn() id: string;
    @column() dataObject: string;
    @column() entityType: string;
    @column() roleId: string;
    @column() roleName: string;
    @column() entityId: string;
    @column() entityName: string;
    @column() createRule: string;
    @column() readFilter: string;
    @column() readRule: string;
    @column() modifyRule: string;
    @column() deleteRule: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
}

export type DataSecurityRuleReadFunction = (dataObject: any, dataObjectType: string, entityType: string, roleId: string, entityId: string, loginId: string) => boolean;
export type DataSecurityRuleCreateFunction = (dataObject: any, dataObjectType: string, entityType: string, roleId: string, entityId: string, loginId: string) => boolean;
export type DataSecurityRuleModifyFunction = (dataObject: any, previousValue: any, dataObjectType: string, entityType: string, roleId: string, entityId: string, loginId: string) => boolean;
export type DataSecurityRuleDeleteFunction = (dataObject: any, dataObjectType: string, entityType: string, roleId: string, entityId: string, loginId: string) => boolean;
