import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TaskSummary {
    @keyColumn() id: string;
    @column() createdDate: Date;
    @column() dueDate: Date;
    @column() title: string;
    @column() description: string;
    @column() completedDate: Date;
    @column() ownerId: string;
    @column() ownerName: string;
    @column() ownerEmail: string;
    @column() followUp: boolean;
    @column() followUpDate: Date;
    @column() isOverDue: boolean;
    @column() priority: number;
    @column() taskQueueId: string;
    @column() taskQueueName: string;
    @column() taskEntityId: string;
    @column() taskEntityName: string;
    @column() taskEntityEmail: string;
    @column() policyHolderId: string;
    @column() policyHolderName: string;
    @column() policyHolderMobile: string;
    @column() policyHolderHome: string;
    @column() workOrderItemId: string;
    @column() workOrderItemName: string;
    @column() workOrderType: string;
    @column() contactName: string;
    @column() contactPhone: string;
    @column() contactMobile: string;
    @column() contactEmail: string;
    @column() workOrderId: string;
    @column() workOrderNumber: number;
    @column() tradeId: string;
    @column() tradeName: string;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() contractorEmail: string;
    @column() contractorOffice: string;
    @column() contractorMobile: string;
    @column() policyId: string;
    @column() policyNumber: number;
    @column() status: string;
    @column() percentComplete: number;
    @column() createdByName: string;
    @column() createdByEmail: string;
    @column() createdById: string;
    @column() taskNumber: number;
    @column() createdByHomeNumber: string;
    @column() createdByMobileNumber: string;
    @column() createdByOfficeNumber: string;
    @column() effort: number;
    @column() tagIds: string;
    @column() workOrderLineId: string;
    @column() areaId: string;
    @column() areaName: string;
    @column() alternateCogentURL: string;
    @column() tagsJSON: string;
    @column() propertyAddress: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
}