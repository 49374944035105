import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderItem {
    @keyColumn() id: string;
    @column() name: string;
    @column() tradeId: string;
    @column() requestServiceProcess: string;
    @column() authoProcess: string;
    @column() deletedDate?: Date;
    @column() sort: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() priority: number;
    @column() allowTimeSlotSelection: boolean;
    @column() timeSlotDayBuffer: number;
    @column() inactive: boolean;
    @column() authorizationAmountOverride: number;
    @column() allowWithTradeId: string;
    @column() monthsToCheckPreExistingStatus: number;
    @column() isService: boolean;
    @column() singleItemJobItem: boolean;
    @column() cashOutThresholdAmount: number;
    @column() authorizationFunctionId: string;
}