import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { MaintenanceServiceCustomerPropertyServiceSummary } from "./maintenance-service-customer-property-service-summary.model";

@dataSource()
export class MaintenanceServiceCustomerPropertySummary {
    @column() id: string;
    @column() status: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() customerId: string;
    @column() loginId: string;
    @column() orderedServices: string;
    @column() propertyId: string;
    @column() customerPhoneNumber: string;
    @column() stripeCardId: string;
    @column() creditCardExpirationDate: Date;
    @column() monthlyPaymentDate: number;
    @column() servicesJson: string;
    @column() bedroomCount?: number;
    @column() bathroomCount?: number;
    @column() contractTemplateKey: string;

    get nextPaymentDate() {
        const dt = new Date();
        dt.setDate(this.monthlyPaymentDate);
        if (dt < new Date()) {
            dt.setMonth(dt.getMonth() + 1);
        }

        return dt;
    }

    private _services: MaintenanceServiceCustomerPropertyLineItem[];
    get services(): MaintenanceServiceCustomerPropertyLineItem[] {
        if (!this._services) {
            try {
                this._services = JSON.parse(this.servicesJson);
            } catch { }
        }

        return this._services;
    }

    cancelledServices: MaintenanceServiceCustomerPropertyServiceSummary[];

    get totalMonthlyPayment() {
        if (!this.services) {
            return 0;
        }

        return this.services.map(i => i.pricePerMonth).reduce((a, b) => a + b, 0);
    }
}

export class MaintenanceServiceCustomerPropertyLineItem {
    cost: number;
    price: number;
    pricePerMonth: number;
    serviceName: string;
    id: string;
    maintenanceServiceOfferingId: string;
    contractorId: string;
    costPerMonth: number;
    frequency: string;
    contractLanguage: string;
    cancellationFeeMonthsInEffect: number;
}