import { keyColumn, column, dataSource } from "@upkeeplabs/linq";  @dataSource() 
export class ShortLink {     
    @keyColumn() id: string;     
    @column() code: string;     
    @column() url: string;     
    @column() source: string;     
    @column() description: string;     
    @column() clicks: number;     
    @column() expires: Date;     
    @column() createdById: string;     
    @column() lastModifiedById: string;     
    @column() createdDate: Date;     
    @column() lastModifiedDate: Date;     
    @column() deletedDate: Date; 
}
