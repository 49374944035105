import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class FavorFundExpenseSummary {
    @column() workOrderNumber?: number;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() itemName: string;
    @column() invoiceNumber?: number;
    @column() premiumInvoiceNumber?: number;
    @column() createdYear?: number;
    @keyColumn() id: string;
    @column() employeeId: string;
    @column() premiumInvoiceId: string;
    @column() serviceFeeInvoiceId: string;
    @column() cashOutId: string;
    @column() purchaseOrderId: string;
    @column() workOrderLineId: string;
    @column() amount?: number;
    @column() exportDate?: Date;
    @column() postDate?: Date;
    @column() createdById: string;
    @column() createdDate?: Date;
    @column() notes: string;
    @column() cashOutNumber?: number;
    @column() employeeName: string;
    @column() claimId: string;
    @column() woNumber?: number;
}