export class EmailArgs {
    suggestedAddresses: EmailAddress[];
    subject: string;
    message: string;
    useSignature: boolean;
    toAddresses: EmailAddress[];
    ccAddresses: EmailAddress[];
    bccAddresses: EmailAddress[];
    policyId: string;
    workOrderId: string;
    taskId: string;
    attachments: EmailAttachment[];
    entityId: string;
    replyToAddress: string;
}

export class EmailAddress {
    constructor(
        public email: string = '',
        public name: string = '',
        public title: string = '') { }
}

export class EmailAttachment {
    contentType: string;
    fileName: string;
    fileContents: string;
    fileUrl: string;
}
