import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderStatus {
    @keyColumn() id: string;
    @column() name: string;
    @column() parentStatusId: string;
    @column() contractorStatusId: string;
    @column() customerStatusId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() isInternal: boolean;
    @column() isContractor: boolean;
    @column() isCustomer: boolean;
    @column() systemStatusType: string;
    @column() sort: number;
    @column() sLAStatusYellowHours: number;
    @column() sLAStatusRedHours: number;
    @column() sLAHoursType: string;
    @column() baseSLAOffOfDate: string;
    @column() iconClass: string;
    @column() isMilestone: boolean;
    @column() shortName: string;
    @column() canManuallySetStatus: boolean;
    @column() redDisplayColor: string;
    @column() percentComplete: number;
    @column() iconColor: string;


    slaStatusYellowHours?: number;
    slaStatusRedHours?: number;
    slaHoursType: string;


    //     id: string;
//     name: string;
//     parentStatusId: string;
//     contractorStatusId: string;
//     customerStatusId: string;
//     isInternal: boolean;
//     isContractor: boolean;
//     isCustomer: boolean;
//     systemStatusType: string;
//     
//     slaStatusYellowHours?: number;
//     slaStatusRedHours?: number;
//     slaHoursType: string;
//     baseSLAOffOfDate: string;
//     iconClass: string;
//     isMilestone: boolean;
//     shortName: string;
//     canManuallySetStatus: boolean;
//     iconColor: string;
}