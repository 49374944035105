import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderItemProblemRepairItem {
    @keyColumn() id: string;
    @column() workOrderItemProblemId: string;
    @column() repairItemId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() isReplacement: boolean;
    @column() workOrderItemTypes: string;
}