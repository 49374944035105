<div>


    <div flex>
      <div nogrow class="side-bar" style="min-height: calc(100vh - 60px);">
  
        <h2 class="sub-heading" bottommargin20>Report Selection</h2>
        <app-date-range-display-and-selector memoryKey="recent-items-search-dates"  [showFutureDates]="false" [(startDate)]="startDate" [(endDate)]="endDate"></app-date-range-display-and-selector>
  
        <button mat-button topmargin30 block color="primary" mat-raised-button (click)="loadHistory()" [disabled]="loading">
          <mat-spinner class="thirty" *ngIf="loading"></mat-spinner> Load
        </button>
      </div>
  
      <div *ngIf="history" id="#report-area">
  
        <div flex topmargin20>
          <div>
            <h1 class="page-title"><span class="big">User</span><br><span class="little"> Notifications</span></h1>
          </div>

        </div>
  
  
  
        <div style="overflow-x: hidden">
            <div class="table">
              <div class="table-header">
                <div nogrow style="width: 50px"></div>
                <div flex2>Message</div>
                <div flex1>Date</div>
              </div>
  
              <div class="table-row hover" *ngFor="let item of history" routerLink="{{item.url}}">
                <div nogrow style="width: 50px;">
                  <mat-icon mat-list-avatar>email</mat-icon>
                </div>
                <div flex2>{{item.description}}</div>
                <div flex1>{{item.createdDate | date: 'short'}}</div>
              </div>
  
            </div>

        </div>
      </div>
    </div>
  </div>
