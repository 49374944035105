import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class Task {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() createdDate?: Date;
    @column() deletedDate?: Date;
    @column() dueDate: Date;
    @column() title: string;
    @column() description: string;
    @column() completedDate: Date;
    @column() ownerId: string;
    @column() followUp: boolean;
    @column() followUpDate: Date;
    @readOnlyColumn() priority: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() lastModifiedDate?: Date;
    @column() taskQueueId: string;
    @column() entityId: string;
    @readOnlyColumn() sort: number;
    @column() percentComplete: number;
    @readOnlyColumn() taskNumber: bigint;
    @column() effort: number;
    @column() workOrderLineId: string;
    @column() alternateCogentURL: string;

    tagIds: string;
    workOrderNumber?: number;
    policyNumber?: number;
    imageData: string; 
    

    get isOverdue(): boolean {
        if (!this.followUpDate) {
            return false;
        }

        return !this.completedDate && this.followUpDate < new Date();
    }

    get timeOpen() {
        let milliseconds = new Date().getTime() - this.createdDate.getTime();

        const days = Math.floor(milliseconds / 86400000);
        milliseconds = milliseconds - days * 86400000;
        const hours = Math.floor(milliseconds / 3600000);
        milliseconds = milliseconds - hours * 3600000;

        const minutes = Math.floor(milliseconds / 60000);

        let result = '';
        if (days > 0) {
            if (days === 1) {
                result = days + ' day ';
            } else {
                result = days + ' days ';
            }
        }
        if (hours > 0) {
            if (hours === 1) {
                result += hours + ' hour ';
            } else {
                result += hours + ' hours ';
            }
        }
        if (minutes > 0) {
            if (minutes === 1) {
                result += minutes + ' minute';
            } else {
                result += minutes + ' minutes';
            }
        }
        return result;
    }
}