<div class="jobs-board-container">
    <div>
        <div style="display: inline-block; width: 140px">
            <button mat-button (click)="loadBoard()" [disabled]="loading">
                <mat-spinner *ngIf="loading" class="thirty"></mat-spinner>
                <mat-icon *ngIf="!loading">autorenew</mat-icon>
                Refresh
            </button>
        </div>
        <div id="search-container">
            <mat-icon>search</mat-icon><input type="text" name="searchField" [formControl]="searchField"
                id="searchField" placeholder="Filter">
        </div>

        <div *ngIf="working" style="display: inline-block; margin-left: 20px;padding-top: 5px">
            <mat-spinner class="twenty"></mat-spinner> {{workingMessage}}
        </div>
    </div>
    <div class="hide-xs">
        <div aflex id="header-container" [style.width]="headerContainerWidth">
            <div *ngFor="let swimLane of swimLanes" flex1>
                <div class="header">
                    <mat-icon
                        *ngIf="swimLane.queueQuery && swimLane.queueQuery.iconClass">{{swimLane.queueQuery.iconClass}}
                    </mat-icon>
                    <span style="vertical-align: middle;display: inline-block; margin-bottom: 10px;margin-left: 10px;">
                        {{swimLane.name}}
                        <span class="badge" *ngIf="swimLane.filteredItems && swimLane.filteredItems.length">
                            {{swimLane.filteredItems.length}}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div id="lane-container" class="hide-xs">
        <div aflex cdkDropListGroup style="min-height: 100%">
            <div *ngFor="let swimLane of swimLanes" cdkDropList class="swim-lane-list" flex1
                #[swimLane.id]="cdkDropList" [id]="swimLane.id" [cdkDropListData]="swimLane"
                (cdkDropListDropped)="drop($event)">
                <div class="column">
                    <ng-container *ngFor="let grouping of swimLane.groupings">
                        <ng-container *ngIf="grouping.hasMultipleItems">
                            <div class="grouping-container" cdkDrag [cdkDragData]="grouping">
                                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                <div class="grouping-header" cdkDragHandle>
                                    <a mat-button (click)="selectItemFromGroup(grouping)">{{grouping.number}}</a> -
                                    {{grouping.propertyAddress}}
                                </div>

                                <div *ngFor="let item of grouping.items" cdkDrag [cdkDragData]="item" aflex
                                    class="drag-list-item">
                                    <div nogrow class="linker" [class.linker-first]="item === grouping.items[0]"
                                        [class.linker-last]="item === grouping.items[grouping.items.length - 1]">
                                        <div class="linker-arrow"></div>
                                    </div>
                                    <div nogrow style="max-width: 60px; overflow:hidden;margin-left: 5px;">
                                        <img class="item-image" style="height: 35px;" cdkDragHandle
                                            [src]="getImageUrl(item)">
                                    </div>
                                    <div style="padding-top: 5px;margin-left: 5px;">
                                        {{item.itemName}}
                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                    </div>

                                    <div nogrow *ngIf="getUICommands(swimLane) && getUICommands(swimLane).length > 0">
                                        <button mat-button [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item *ngFor="let command of getUICommands(swimLane)"
                                                (click)="startDoCommand(command.actions, item, swimLane)">
                                                <mat-icon *ngIf="command.iconClass">{{command.iconClass}}</mat-icon>
                                                {{command.label}}
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div nogrow>
                                        <div [class.green]="item.slaStatus === 'GREEN'"
                                            [class.yellow]="item.slaStatus === 'YELLOW'"
                                            [class.red]="item.slaStatus === 'RED'"></div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!grouping.hasMultipleItems">
                            <div *ngFor="let item of grouping.items" cdkDrag [cdkDragData]="item" aflex
                                class="drag-list-item">
                                <div nogrow style="max-width: 60px; overflow:hidden">
                                    <img class="item-image" cdkDragHandle [src]="getImageUrl(item)">
                                </div>
                                <div>
                                    <div class="wo-num">
                                        <a mat-button (click)="selectItem(item)">{{item.number}}</a>
                                    </div>
                                    {{item.propertyAddress}}<br>
                                    {{item.itemName}}
                                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                </div>

                                <div nogrow *ngIf="getUICommands(swimLane) && getUICommands(swimLane).length > 0">
                                    <button mat-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item *ngFor="let command of getUICommands(swimLane)"
                                            (click)="startDoCommand(command.actions, item, swimLane)">
                                            <mat-icon *ngIf="command.iconClass">{{command.iconClass}}</mat-icon>
                                            {{command.label}}
                                        </button>
                                    </mat-menu>
                                </div>
                                <div nogrow>
                                    <div [class.green]="item.slaStatus === 'GREEN'"
                                        [class.yellow]="item.slaStatus === 'YELLOW'"
                                        [class.red]="item.slaStatus === 'RED'"></div>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>

                </div>
            </div>
        </div>
    </div>

    <div class="show-xs">
        <div *ngFor="let swimLane of swimLanes" class="swim-lane-list-mobile" [id]="swimLane.id">

            <h2 class="mobile-group-header">                     <mat-icon
                *ngIf="swimLane.queueQuery && swimLane.queueQuery.iconClass">{{swimLane.queueQuery.iconClass}}
            </mat-icon> {{swimLane.name}}</h2>
            <div style="padding: 20px;text-align: center" *ngIf="swimLane.groupings?.length === 0">
                <mat-icon>inbox</mat-icon><br>
                No Items
            </div>
            <div class="groupings-container" (click)="navigateToJob(grouping)" *ngFor="let grouping of swimLane.groupings">
                <ng-container *ngIf="grouping.hasMultipleItems">
                    <div class="grouping-container">
                        <div class="grouping-header">
                            <a mat-button >{{grouping.number}}</a> -
                            {{grouping.propertyAddress}}
                        </div>

                        <div *ngFor="let item of grouping.items" aflex class="drag-list-item">
                            <div nogrow class="linker" [class.linker-first]="item === grouping.items[0]"
                                [class.linker-last]="item === grouping.items[grouping.items.length - 1]">
                                <div class="linker-arrow"></div>
                            </div>
                            <div nogrow style="max-width: 60px; overflow:hidden;margin-left: 5px;">
                                <img class="item-image" style="height: 35px;" [src]="getImageUrl(item)">
                            </div>
                            <div style="padding-top: 5px;margin-left: 5px;">
                                {{item.itemName}}
                            </div>

                            <div nogrow *ngIf="getUICommands(swimLane) && getUICommands(swimLane).length > 0">
                                <button mat-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item *ngFor="let command of getUICommands(swimLane)"
                                        (click)="startDoCommand(command.actions, item, swimLane)">
                                        <mat-icon *ngIf="command.iconClass">{{command.iconClass}}</mat-icon>
                                        {{command.label}}
                                    </button>
                                </mat-menu>
                            </div>
                            <div nogrow>
                                <div [class.green]="item.slaStatus === 'GREEN'"
                                    [class.yellow]="item.slaStatus === 'YELLOW'" [class.red]="item.slaStatus === 'RED'">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!grouping.hasMultipleItems">
                    <div *ngFor="let item of grouping.items" aflex class="drag-list-item">
                        <div nogrow style="max-width: 60px; overflow:hidden">
                            <img class="item-image" [src]="getImageUrl(item)">
                        </div>
                        <div>
                            <div class="wo-num">
                                <a mat-button >{{item.number}}</a>
                            </div>
                            {{item.propertyAddress}}<br>
                            {{item.itemName}}
                            <div class="example-custom-placeholder" *></div>
                        </div>

                        <div nogrow *ngIf="getUICommands(swimLane) && getUICommands(swimLane).length > 0">
                            <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngFor="let command of getUICommands(swimLane)"
                                    (click)="startDoCommand(command.actions, item, swimLane)">
                                    <mat-icon *ngIf="command.iconClass">{{command.iconClass}}</mat-icon>
                                    {{command.label}}
                                </button>
                            </mat-menu>
                        </div>
                        <div nogrow>
                            <div [class.green]="item.slaStatus === 'GREEN'" [class.yellow]="item.slaStatus === 'YELLOW'"
                                [class.red]="item.slaStatus === 'RED'"></div>
                        </div>
                    </div>
                </ng-container>

            </div>


        </div>
    </div>
    <!-- <div *ngIf="selectedItem">
    <button class="close-detail-button" mat-button (click)="selectedItem = null">
        <mat-icon>close</mat-icon>
    </button>
    <div id="detail-screen" *ngIf="selectedItem">

        <app-work-order-detail *ngIf="!showAutho" (workOrderSummaryChange)="detailPageRefreshed()"
            [selectedIndex]="selectedDetailIndex" [workOrderId]="selectedItem.id"></app-work-order-detail>

        <app-enter-authorization *ngIf="showAutho" (authorizationComplete)="authorizationComplete()"
            [workOrderId]="selectedItem.id"></app-enter-authorization>
    </div>
</div> -->

    <div id="queue-config-panel" *ngIf="selectedItem">

        <div aflex>
            <div nogrow> <button mat-button class="close-detail-button" (click)="selectedItem = null">
                    <mat-icon>close</mat-icon>
                </button></div>
            <div style="overflow-x: hidden;overflow-y: auto;height: calc(100vh - 79px)">
                <app-work-order-detail *ngIf="!showAutho" (workOrderSummaryChange)="detailPageRefreshed()"
                    [selectedIndex]="selectedDetailIndex" [workOrderId]="selectedItem.id"></app-work-order-detail>

                <app-enter-authorization *ngIf="showAutho" (authorizationComplete)="authorizationComplete()"
                    [workOrderId]="selectedItem.id"></app-enter-authorization>
            </div>
        </div>
    </div>
</div>