import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Dictionary {
    @keyColumn() id: string;
    @column() itemKey: string;
    @column() value: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;

    private _jsonValue: any;
    get jsonValue():any {
        if (!this._jsonValue && this.value) {
            try {
                this._jsonValue = JSON.parse(this.value);
            } catch { }
        }

        return this._jsonValue;
    }
    set jsonValue(value: any) {
        this.value = JSON.stringify(value);
    }
}