import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesContact {
    @keyColumn() id: string;
    @column() contactedById: string;
    @column() contactedEntityId: string;
    @column() contactDate: Date;
    @column() contactMethodId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}