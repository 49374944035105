<div>
    <h2>{{data.message}}</h2>
    <app-payment-method-entry
        [customerId]="data.customerId"
        [deletingCardId]="data.deletingCardId"
        [allowDelete]="false"
        [(selectedCard)]="data.selectedCard"
        [hideManualCreditCardEntry]="true"
        (addingNewCardChange)="addingNewCardEvent($event)"
    ></app-payment-method-entry>
    @if (!addingNewCard) {
        <div class="manage-payment-options">
            <button mat-button  (click)="cancel()">Cancel</button>
            <button mat-button mat-raised-button color="primary" [disabled]="!canSubmit || saving || this.data.deletingCardId === data.selectedCard.Id || addingNewCard" (click)="replaceCard()">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>Replace Default Card</button>
        </div>
    }
</div>
