import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Quote {
    @keyColumn() id: string;
    @column() propertyAddress1: string;
    @column() planName: string;
    @column() total: number;
    @column() entityId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() quoteDetails: string;
    @column() addressId: string;
    @column() completionDate: Date;
    @column() policyId: string;

    private quoteDetailsObjectPrivate: any;
    get quoteDetailsObject(): any {
        if (!this.quoteDetailsObjectPrivate) {
            try {
                this.quoteDetailsObjectPrivate = JSON.parse(this.quoteDetails);
            } catch (e) { }
        }

        return this.quoteDetailsObjectPrivate;
    }
}