import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PurchaseOrderItemSummary {
    @keyColumn() id: string;
    @column() purchaseOrderId: string;
    @column() itemId: string;
    @column() quantity: number;
    @column() unitPrice: number;
    @column() partNumber: string;
    @column() description: string;
    @column() workOrderLineId: string;
    @column() vendorName: string;
    @column() purchaseOrderNumber: bigint;
    @column() vendorId: string;
    @column() vendorNumber: string;
    @column() itemName: string;
    @column() policyId: string;
    @column() policyNumber: bigint;
    @column() workOrderCreatedDate: Date;
    @column() workOrderItemId: string;
    @column() workOrderNumber: bigint;
    @column() contractorName: string;
    @column() workOrderId: string;
    @column() purchaseOrderCreatedDate: Date;
    @column() trackingNumber: string;
    @column() shipperId: string;
    @column() shipperName: string;
    @column() estimatedDeliveryDate: Date;
    @column() deliveryDate: Date;
    @column() tax: number;
    @column() totalTax: number;


    get subtotal() {
        return this.quantity * this.unitPrice;
    }
}


@dataSource()
export class InventoryPurchaseOrderItemSummary {
    @keyColumn() id: string;
    @column() purchaseOrderId: string;
    @column() itemId: string;
    @column() quantity: number;
    @column() unitPrice: number;
    @column() number: string;
    @column() description: string;
    @column() workOrderLineId: string;
    @column() vendorId: string;
    @column() vendorName: string;
    @column() purchaseOrderNumber: bigint;
    @column() vendorNumber: string;
    @column() workOrderId: string;
    @column() purchaseOrderCreatedDate: Date;
    @column() trackingNumber: string;
    @column() shipperId: string;
    @column() shipperName: string;
    @column() estimatedDeliveryDate: Date;
    @column() deliveryDate: Date;
    @column() tax: number;
    @column() totalTax: number;
}