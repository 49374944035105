import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { ContractorProposedWorkOrder } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { QuestionWizardComponentV2 } from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';

@Component({
    selector: 'app-start-service-request',
    templateUrl: './start-service-request.component.html',
    styleUrls: ['./start-service-request.component.css']
})
export class StartServiceRequestComponent implements OnInit {
    wizardComplete = false;
    proposedWorkOrder = new ContractorProposedWorkOrder();
    @ViewChild('questionWizard') questionWizard: QuestionWizardComponentV2;
    saving = false;

    constructor(public dialogRef: MatDialogRef<StartServiceRequestComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private maintApi: MaintenanceServiceApiService,
        private entityApi: EntityApiService,
        private missionService: MissionService,
        private dataApi: DataApiService) { }

    ngOnInit(): void {

    }

    get canSave() {
        return this.wizardComplete && !this.saving;
    }

    async save() {
        this.saving = true;
        const loggedInUser = await this.entityApi.getLoggedInUser();
        this.proposedWorkOrder.id = UtilitiesService.newid();
        this.proposedWorkOrder.contractorId = loggedInUser.id;
        this.proposedWorkOrder.maintenanceServiceCustomerPropertyServiceId = this.data.service.id;
        this.proposedWorkOrder.policyId = this.data.item.policyId;
        this.proposedWorkOrder.qandA = this.questionWizard.questionsJson;
        this.proposedWorkOrder.workOrderItemId = this.data.item.workOrderItemId;

        await this.maintApi.saveContractorProposedWorkOrder(this.proposedWorkOrder);
        this.saving = false;
        this.dialogRef.close(this.proposedWorkOrder);

        this.missionService.showSuccessToast('Request Sent For Customer Approval');
    }
}
