import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DeferredRevenue {
    @keyColumn() id: string;
    @column() deferredDate: Date;
    @column() policyId: string;
    @column() remainingDays: number;
    @column() premiumPaid: number;
    @column() premiumUsed: number;
    @column() premiumDeferred: number;
}


@dataSource()
export class DeferredRevenueBalanceByAccountingArea {
    @column() premiumDeferred: number;
    @column() deferredDate: Date;
    @column() areaId: string;
    @column() areaName: string;
    @column() deferredRevenueAccountId: number;
    @column() revenueAccountId: number;
}