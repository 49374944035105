<div class="intellisense-menu" *ngIf="intellisenseOpen" [style.width]="intellisenseWidthPx"
    [style.height]="intellisenseHeight" [style.max-height]="intellisenseMaxHeightPx" [style.left]="intellisenseLeft"
    [style.top]="intellisenseTop">

    <button mat-button id="{{field.name}}" *ngFor="let field of filteredQuickNotes"
        [class.selected]="selectedQuickNote === field" class="intellisense-button"
        (click)="setIntellisenseField(field)">
        {{field.key}} - {{field.note}}
    </button>
</div>



<div *ngIf="selectedForm" cdkDropList [cdkDropListDisabled]="previewMode" [id]="formId"
    (cdkDropListDropped)="handleFormItemResort($event)">

    <div flex class="form-item-container" [class.preview-mode]="previewMode"
        [class.selected]="!previewMode && formItem === selectedFormItem" *ngFor="let formItem of selectedForm.formItems"
        cdkDrag (click)="selectFormItem(formItem)" [class.thirty-three-pct]="formItem.containerWidth === '33'"
        [class.fifty-pct]="formItem.containerWidth === '50'" [class.twenty-five-pct]="formItem.containerWidth === '25'">
        <div nogrow class="drag-handle" cdkDragHandle *ngIf="!previewMode">

            <mat-icon *ngIf="formItem.type === 'TextBox'">short_text</mat-icon>
            <mat-icon *ngIf="formItem.type === 'NumberBox'">filter_1</mat-icon>
            <mat-icon *ngIf="formItem.type === 'EmailBox'">email</mat-icon>

            <mat-icon *ngIf="formItem.type === 'PhoneBox'">phone</mat-icon>
            <mat-icon *ngIf="formItem.type === 'MultiLineTextBox'">format_align_left</mat-icon>
            <mat-icon *ngIf="formItem.type === 'RadioButtonList'">radio_button_checked</mat-icon>
            <mat-icon *ngIf="formItem.type === 'Select'">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="formItem.type === 'CheckboxList'">check_box</mat-icon>
            <mat-icon *ngIf="formItem.type === 'DatePicker'">date_range</mat-icon>
            <mat-icon *ngIf="formItem.type === 'SlideToggle'">toggle_off</mat-icon>
            <mat-icon *ngIf="formItem.type === 'Message'">mode_comment</mat-icon>
            <mat-icon *ngIf="formItem.type === 'Heading'">title</mat-icon>
            <mat-icon *ngIf="formItem.type === 'Separator'">remove</mat-icon>
            <mat-icon *ngIf="formItem.type === 'TagEntry'">label</mat-icon>
            <mat-icon *ngIf="formItem.type === 'Slider'">maximize</mat-icon>
            <mat-icon *ngIf="formItem.type === 'RichText'">format_italic</mat-icon>
            <mat-icon *ngIf="formItem.type === 'PreferredTimeSlot'">date_range</mat-icon>
            <mat-icon *ngIf="formItem.type === 'WorkOrderSelection'">build</mat-icon>
            <mat-icon *ngIf="formItem.type === 'ImageUpload'">image</mat-icon>
            <mat-icon *ngIf="formItem.type === 'RichText2'">wysiwyg</mat-icon>
        </div>
        <div>

            <ng-container *ngIf="formItem.type === 'Heading'">
                <h1 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="sub-heading">{{formItem.displayPrompt}}</h1>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'Separator'">
                <hr>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'TextBox'">

                <mat-form-field appearance="outline" [style.max-width]="getMaxWidth(formItem)">
                    <mat-label>{{formItem.displayPrompt}}</mat-label>
                    <input matInput [disabled]="!previewMode" [(ngModel)]="formItem.answer"
                        (ngModelChange)="raiseIsFormComplete()">
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'NumberBox'">
                <mat-form-field appearance="outline" [style.max-width]="getMaxWidth(formItem)">
                    <mat-label>{{formItem.displayPrompt}}</mat-label>
                    <input matInput type="number" [disabled]="!previewMode" [(ngModel)]="formItem.answer"
                        (ngModelChange)="raiseIsFormComplete()">
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'PreferredTimeSlot'">
                <div class="data-label" bottommargin10>{{formItem.displayPrompt}}</div>
                <app-appointment-selection [(selectedTimeSlots)]="formItem.answer" [selectedItemId]="formItem.key1"
                    [loadDefaults]="true"></app-appointment-selection>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'EmailBox'">
                <mat-form-field appearance="outline" [style.max-width]="getMaxWidth(formItem)">

                    <mat-label>{{formItem.displayPrompt}}</mat-label>
                    <input matInput type="email" [disabled]="!previewMode" [(ngModel)]="formItem.answer"
                        (ngModelChange)="raiseIsFormComplete()">
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'PhoneBox'">
                <mat-form-field appearance="outline" [style.max-width]="getMaxWidth(formItem)">
                    <mat-label>{{formItem.displayPrompt}}</mat-label>
                    <input matInput type="tel" [disabled]="!previewMode" [(ngModel)]="formItem.answer"
                        (ngModelChange)="raiseIsFormComplete()">
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'MultiLineTextBox'">
                <mat-form-field appearance="outline" [style.max-width]="getMaxWidth(formItem)">

                    <mat-label>{{formItem.displayPrompt}}</mat-label>
                    <textarea matInput [disabled]="!previewMode" [(ngModel)]="formItem.answer"
                        (ngModelChange)="raiseIsFormComplete()" (keydown)="respondToKeyDownEvent($event, formItem)"
                        (blur)="inputBlurred($event)" (keyup)="respondToKeyEvent($event, formItem)"
                        [rows]="formItem.rowsOrDefault"></textarea>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'RadioButtonList'">
                
                <div class="data-label" bottommargin10>{{formItem.displayPrompt}}</div>
                <ng-container *ngIf="!formItem.answers || formItem.answers.length === 0">
                    <mat-radio-group aria-label="Select an option">
                        <mat-radio-button [disabled]="!previewMode" value="1">Option 1</mat-radio-button>
                        <mat-radio-button [disabled]="!previewMode" value="2">Option 2</mat-radio-button>
                    </mat-radio-group>
                </ng-container>

                <ng-container *ngIf="formItem.answers && formItem.answers.length >0">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="formItem.answer"
                        (ngModelChange)="raiseIsFormComplete()">
                        <mat-radio-button [disabled]="!previewMode" *ngFor="let answer of formItem.answers"
                            value="{{answer.answer}}">
                            {{answer.answer}}</mat-radio-button>
                    </mat-radio-group>
                </ng-container>
                <div bottommargin10></div>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'Select'">
                <ng-container *ngIf="!formItem.answers || formItem.answers.length === 0">
                    <mat-form-field appearance="outline">
                        <mat-label>{{formItem.displayPrompt}}</mat-label>
                        <mat-select [style.max-width]="getMaxWidth(formItem)">
                            <mat-option value="1">Option 1</mat-option>
                            <mat-option value="2">Option 2</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="formItem.answers && formItem.answers.length >0">
                    <mat-form-field style="margin-top: 0;" appearance="outline">
                        <mat-label>{{formItem.displayPrompt}}</mat-label>
                        <mat-select [(ngModel)]="formItem.answer" (ngModelChange)="raiseIsFormComplete()">
                            <mat-option [disabled]="!previewMode" value="{{answer.answer}}"
                                *ngFor="let answer of formItem.answers">
                                {{answer.answer}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'CheckboxList'" bottommargin10>
                <div class="data-label" bottommargin10>{{formItem.displayPrompt}}</div>
                <ng-container *ngIf="!formItem.answers || formItem.answers.length === 0">
                    <mat-checkbox [disabled]="!previewMode">Option 1</mat-checkbox>
                    <mat-checkbox [disabled]="!previewMode">Option 1</mat-checkbox>
                </ng-container>

                <ng-container *ngIf="formItem.answers && formItem.answers.length >0">

                    <mat-checkbox (ngModelChange)="setCheckboxAnswers(answer, formItem)" [(ngModel)]="answer.selected"
                        [disabled]="!previewMode" *ngFor="let answer of formItem.answers">{{answer.answer}}
                    </mat-checkbox>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="formItem.type === 'DatePicker'">

                <mat-form-field appearance="outline" style="margin-top: 0" [style.max-width]="getMaxWidth(formItem)">
                    <mat-label>{{formItem.displayPrompt}}</mat-label>
                    <input [disabled]="!previewMode" matInput [matDatepicker]="picker"
                        (ngModelChange)="raiseIsFormComplete()" [(ngModel)]="formItem.answer">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="formItem.type === 'SlideToggle'">
                <mat-slide-toggle [disabled]="!previewMode" (ngModelChange)="raiseIsFormComplete()"
                    [(ngModel)]="formItem.answer">{{formItem.displayPrompt}}
                </mat-slide-toggle>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'Message'">
                <p class="info">{{formItem.displayPrompt}}</p>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'Slider'">
                <div class="data-label" bottommargin10>{{formItem.displayPrompt}}</div>

                <mat-slider style="width: 300px;" [disabled]="!previewMode" [max]="formItem.max" [min]="formItem.min"
                    [step]="formItem.step" 
                    [(ngModel)]="formItem.answer"></mat-slider>
            </ng-container>
            <ng-container *ngIf="formItem.type === 'TagEntry'">
                <div *ngIf="previewMode">
                    <app-tag-chip-selection [placeholder]="formItem.displayPrompt" [(selectedTags)]="selectedTags"
                        (selectedTagsChange)="selectedTagsChange(formItem)" [defaultIds]="formItem.answer"
                        [viewWorkOrderTags]="formItem.viewWorkOrderTags" [viewPolicyTags]="formItem.viewPolicyTags"
                        [viewEntityTags]="formItem.viewEntityTags" [viewTaskTags]="formItem.viewTaskTags">
                    </app-tag-chip-selection>
                </div>
                <div *ngIf="!previewMode">
                    <span class="tag">Tag 1</span> <span class="tag">Tag 2</span>
                </div>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'WorkOrderSelection'">
                <app-search-chip-selection [placeholder]="formItem.displayPrompt" [singleSelect]="true"
                    [showWorkOrderView]="true" searchType="WorkOrder" searchSubType=""
                    (selectedResultsChange)="searchResultsChange($event, formItem)">

                </app-search-chip-selection>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'EmployeeSelection'">
                <app-search-chip-selection [placeholder]="formItem.displayPrompt" [singleSelect]="true"
                    searchType="Entity" searchSubType="Employee"
                    (selectedResultsChange)="searchResultsChange($event, formItem)">

                </app-search-chip-selection>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'CustomTableSearch'">
                <app-custom-table-chip-selection [selectMode]="formItem.selectMode"
                    [searchForField]="formItem.searchForFieldName" [type]="formItem.customTableName"
                    [placeholder]="formItem.displayPrompt" [singleSelect]="true"
                    [additionalParams]="formItem.additionalParams"
                    (selectedResultsChange)="searchResultsChangeKey($event,formItem)"></app-custom-table-chip-selection>
            </ng-container>

            <ng-container *ngIf="formItem.type === 'ImageUpload'">
                <app-swapable-image [pasteTargetId]="formId" [showClear]="true" [blockStyle]="true" [prompt]="formItem.displayPrompt" [(url)]="formItem.answer" iconClass="image" (urlChange)="raiseIsFormComplete()"></app-swapable-image>
            </ng-container>
            <ng-container *ngIf="formItem.type === 'RichText2' || formItem.type === 'RichText'">
                <div class="data-label" bottommargin10>{{formItem.displayPrompt}}</div>
                <app-rich-text-and-snippet [pasteIntoDocument]="true"  [disabled]="!previewMode" [quickNotes]="quickNotes" [(ngModel)]="formItem.answer" (ngModelChange)="raiseIsFormComplete()"></app-rich-text-and-snippet>
            </ng-container>
        </div>
        <div nogrow *ngIf="!previewMode">
            <button mat-button (click)="removeFormItem(formItem)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>