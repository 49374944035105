import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceContractorInvoicePaymentSummary {
    @keyColumn() id: string;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() createdDate: Date;
    @column() paymentAmount: number;
    @column() paymentCount: number;
    @column() email: string;
    @column() quickBooksId: string;
    @column() checkNumber: string;
    @column() payablesPaymentMethod: string;
    @column() contractorAddress1: string;
    @column() contractorAddress2: string;
    @column() contractorCity: string;
    @column() contractorState: string;
    @column() contractorPostalCode: string;
    @column() linesJson: string;

    _lines: MaintenanceServiceContractorInvoicePaymentItemSummary[];
    get lines(): MaintenanceServiceContractorInvoicePaymentItemSummary[] {
        if (!this._lines) {
            try {
                this._lines = JSON.parse(this.linesJson);
            } catch { }
        }

        return this._lines;
    }
}

export class MaintenanceServiceContractorInvoicePaymentItemSummary {
    id: string;
    amountPaid: number;
    amount: number;
    invoiceDate: Date;
    dueDate: Date;
    invoiceNumber: number;
    propertyAddress1: string;
    propertyAddress2: string;
    propertyCity: string;
    propertyState: string;
    propertyPostalCode: string;
    customerName: string;
    orderedServices: string;
    description: string;
}