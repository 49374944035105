<div class="group-outer-outer-container">
    <div class="group-outer-container">
        <div class="outer-container">
            <h2 class="sub-heading choice-heading">1st Choice
                <!-- <i class="material-icons" *ngIf="selectedTimeSlots && selectedTimeSlots[0]">
    check
    </i> -->
            </h2>
            <div class="choice-container">
                <div class="group-selector-outer-container" [class.scroller]="hasScrollers">
                    <button mat-button class="scroll-button left-btn" (click)="scrollLeft(groupSelector1Id)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <div class="group-selector" [id]="groupSelector1Id" bottommargin10>
                        <button mat-button class="toggle-button" [class.is-active]="timeSlot == selectedTimeSlot1"
                            *ngFor="let timeSlot of timeSlots" (click)="selectTimeSlot1(timeSlot)">
                            <div>{{timeSlot.date | date: 'EEE'}}</div>
                            <h2>{{timeSlot.date | date: 'dd'}}<span
                                    class="suffix">{{getDateSuffix(timeSlot.date)}}</span>
                            </h2>
                            <span class="count-indicator" *ngIf="slotCount(timeSlot)">{{slotCount(timeSlot)}}</span>
                        </button>
                    </div>
                    <button mat-button class="scroll-button right-btn" (click)="scrollRight(groupSelector1Id)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>

                <div class="time-container">
                    <div *ngIf="selectedTimeSlot1" class="fade-in-up">
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime1('morning')"
                            [disabled]="isTimeSlotSelected(0,'morning')" [class.is-active]="isActiveSlot(0,'morning')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/morning.png"><br>
                            Mornings<br><span class="little">
                                (~ 8am-12pm)</span>
                        </button>
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime1('mid-day')"
                            [disabled]="isTimeSlotSelected(0,'mid-day')" [class.is-active]="isActiveSlot(0,'mid-day')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mid-day.png"><br>
                            Mid-day
                            <br>
                            <span class="little">
                                (~ 10am-2pm)</span>
                        </button>
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime1('afternoon')"
                            [disabled]="isTimeSlotSelected(0,'afternoon')"
                            [class.is-active]="isActiveSlot(0,'afternoon')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/afternoon.png"><br>
                            Afternoon
                            <br>
                            <span class="little">
                                (~ 12pm-5pm)</span>
                        </button>

                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime1('all-day')"
                            [disabled]="isTimeSlotSelected(0,'all-day')" [class.is-active]="isActiveSlot(0,'all-day')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/all-day.png"><br>
                            All Day
                            <br><span class="little">
                                (~ 8am-5pm)</span>
                        </button>
                    </div>
                    <div *ngIf="!selectedTimeSlot1" class="select-container">
                        <span class="select-day-label">Select the day</span>
                        <mat-icon class="select-appt-icon">subdirectory_arrow_right</mat-icon>
                    </div>
                </div>

            </div>
        </div>

        <div class="outer-container">
            <h2 class="sub-heading choice-heading">2nd Choice
                <!-- <i class="material-icons" *ngIf="selectedTimeSlots && selectedTimeSlots[1]">
    check
    </i> -->
            </h2>
            <div class="choice-container">
                <div class="group-selector-outer-container" [class.scroller]="hasScrollers">
                    <button mat-button class="scroll-button left-btn" (click)="scrollLeft(groupSelector2Id)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <div class="group-selector" [id]="groupSelector2Id" bottommargin10>
                        <button mat-button class="toggle-button" [class.is-active]="timeSlot == selectedTimeSlot2"
                            *ngFor="let timeSlot of timeSlots" (click)="selectTimeSlot2(timeSlot)">
                            <div>{{timeSlot.date | date: 'EEE'}}</div>
                            <h2>{{timeSlot.date | date: 'dd'}}<span
                                    class="suffix">{{getDateSuffix(timeSlot.date)}}</span>
                            </h2>
                            <span class="count-indicator" *ngIf="slotCount(timeSlot)">{{slotCount(timeSlot)}}</span>
                        </button>
                    </div>

                    <button mat-button class="scroll-button right-btn" (click)="scrollRight(groupSelector2Id)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>

                <div class="time-container">
                    <div *ngIf="selectedTimeSlot2" class="fade-in-up">
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime2('morning')"
                            [disabled]="isTimeSlotSelected(1,'morning')" [class.is-active]="isActiveSlot(1,'morning')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/morning.png"><br>
                            Mornings<br><span class="little">
                                (~ 8am-12pm)</span>
                        </button>
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime2('mid-day')"
                            [disabled]="isTimeSlotSelected(1,'mid-day')" [class.is-active]="isActiveSlot(1,'mid-day')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mid-day.png"><br>
                            Mid-day
                            <br>
                            <span class="little">
                                (~ 10am-2pm)</span>
                        </button>
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime2('afternoon')"
                            [disabled]="isTimeSlotSelected(1,'afternoon')"
                            [class.is-active]="isActiveSlot(1,'afternoon')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/afternoon.png"><br>
                            Afternoon
                            <br>
                            <span class="little">
                                (~ 12pm-5pm)</span>
                        </button>

                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime2('all-day')"
                            [disabled]="isTimeSlotSelected(1,'all-day')" [class.is-active]="isActiveSlot(1,'all-day')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/all-day.png"><br>
                            All Day
                            <br><span class="little">
                                (~ 8am-5pm)</span>
                        </button>
                    </div>

                    <div *ngIf="!selectedTimeSlot2" class="select-container">
                        <span class="select-day-label">Select the day</span>
                        <mat-icon class="select-appt-icon">subdirectory_arrow_right</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <div class="outer-container" *ngIf="!hideThird">
            <h2 class="sub-heading choice-heading">3nd Choice
                <!-- <i class="material-icons" *ngIf="selectedTimeSlots && selectedTimeSlots[2]">
    check
    </i> -->
            </h2>
            <div class="choice-container">
                <div class="group-selector-outer-container" [class.scroller]="hasScrollers">
                    <button mat-button class="scroll-button left-btn" (click)="scrollLeft(groupSelector3Id)">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <div class="group-selector" [id]="groupSelector3Id" bottommargin10>
                        <button mat-button class="toggle-button" [class.is-active]="timeSlot == selectedTimeSlot3"
                            *ngFor="let timeSlot of timeSlots" (click)="selectTimeSlot3(timeSlot)">
                            <div>{{timeSlot.date | date: 'EEE'}}</div>
                            <h2>{{timeSlot.date | date: 'dd'}}<span
                                    class="suffix">{{getDateSuffix(timeSlot.date)}}</span>
                            </h2>
                            <span class="count-indicator" *ngIf="slotCount(timeSlot)">{{slotCount(timeSlot)}}</span>
                        </button>
                    </div>
                    <button mat-button class="scroll-button right-btn" (click)="scrollRight(groupSelector3Id)">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>


                <div class="time-container">
                    <div *ngIf="selectedTimeSlot3" class="fade-in-up">
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime3('morning')"
                            [disabled]="isTimeSlotSelected(2,'morning')" [class.is-active]="isActiveSlot(2,'morning')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/morning.png"><br>
                            Mornings<br><span class="little">
                                (~ 8am-12pm)</span>
                        </button>
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime3('mid-day')"
                            [disabled]="isTimeSlotSelected(2,'mid-day')" [class.is-active]="isActiveSlot(2,'mid-day')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mid-day.png"><br>
                            Mid-day
                            <br>
                            <span class="little">
                                (~ 10am-2pm)</span>
                        </button>
                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime3('afternoon')"
                            [disabled]="isTimeSlotSelected(2,'afternoon')"
                            [class.is-active]="isActiveSlot(2,'afternoon')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/afternoon.png"><br>
                            Afternoon
                            <br>
                            <span class="little">
                                (~ 12pm-5pm)</span>
                        </button>

                        <button mat-button class="time-slot-button" (click)="setTimeSlotTime3('all-day')"
                            [disabled]="isTimeSlotSelected(2,'all-day')" [class.is-active]="isActiveSlot(2,'all-day')">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/all-day.png"><br>
                            All Day
                            <br><span class="little">
                                (~ 8am-5pm)</span>
                        </button>
                    </div>

                    <div *ngIf="!selectedTimeSlot3" class="select-container">
                        <span class="select-day-label">Select the day</span>
                        <mat-icon class="select-appt-icon">subdirectory_arrow_right</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>