import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanOfferingsComponent } from './plan-offerings/plan-offerings.component';
import { NewPlanOfferingsComponent } from "./new-plan-offerings/new-plan-offerings.component";
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { PlanSelectionComponent } from './plan-selection/plan-selection.component';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/plans-and-coverage/view-coverage/view-coverage.component';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';



@NgModule({
    declarations: [
        PlanOfferingsComponent,
        PlanSelectionComponent,
        NewPlanOfferingsComponent
    ],
    imports: [
        CommonModule,
        MaterialSharedModule,
        ViewCoverageDialogComponent,
        UpkeepPipesModule,
    ],
    exports: [
        PlanOfferingsComponent,
        PlanSelectionComponent,
        NewPlanOfferingsComponent
    ]
})
export class PlanOfferingsModule { }
