import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module';
import { Entity, Note } from '@upkeeplabs/models/cogent';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { ActivatedRoute } from '@angular/router';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { RichTextAndSnippetComponent } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet/rich-text-and-snippet.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';

@Component({
    selector: 'app-tag-note',
    standalone: true,
    imports: [CommonModule, RichTextAndSnippetModule, MaterialSharedModule],
    templateUrl: './tag-note.component.html',
    styleUrls: ['./tag-note.component.css'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TagNoteComponent implements OnInit {
    note: Note;    
    entities: Entity[];    
    @ViewChild('richText') richText: RichTextAndSnippetComponent;
    saving = false;
    selectedIndex = 0;
    mentionableFilter = '';

    mentionables: Entity[] = [];

    constructor(private serviceApi: ServiceApiService,        
        private entityApi: EntityApiService,
        private missionService: MissionService,
        private activatedRoute: ActivatedRoute) {

    }

    ngOnInit(): void {

        this.activatedRoute.params.subscribe(async params=> {
            if(params.id) {
                this.note = new Note();
                this.note.id = UtilitiesService.newid();
                this.note.showOnContractorPortal = true;
                this.note.workOrderId = params.id;
                this.note.type = '';
                this.note.noteText = '';
                this.entities = (await this.serviceApi.getAssignedEmployees(params.id)) as any;
                await this.loadMentionables();
            }
        });

    }

    async loadMentionables(){
        for (const entity of this.entities) {
            const permissions = await this.entityApi.getUserPermissions(entity.id);
            for (const permission of permissions) {
                if (permission.functionalPermissionsObject.indexOf('CONTRACTOR-CAN-TAG') > -1) {
                    this.mentionables.push(entity);
                }
            }
        };
    }

    get canSave() {
        return this.note.noteText && !this.saving;
    }

    get filteredMentionables() { 
        if (!this.mentionableFilter) {
            return this.mentionables;
        }

        return this.mentionables.filter(i => i.name?.toLowerCase().indexOf(this.mentionableFilter.toLocaleLowerCase()) > -1);
    }

    showTag() {

        for (const entity of this.mentionables) {
            if (this.note.noteText.indexOf(`data-entity-id="${entity.id}"`) > -1) {
                entity.selected = true;
            } else {
                entity.selected = false;
            }
        }
        this.selectedIndex = 1;
        this.mentionableFilter = '';
    }    applyTags() {
        let noteText = this.note.noteText;
        for (const entity of this.mentionables.filter(i => i.selected)) {
            if (noteText.indexOf(`data-entity-id="${entity.id}"`) === -1) {
                noteText += ` <a data-entity-id="${entity.id}" class="mentioned-href">@${entity.name}</a>`;
            }
        }
        for (const entity of this.mentionables.filter(i => !i.selected)) {
            noteText = UtilitiesService.replaceAll(noteText, `<a data-entity-id="${entity.id}" class="mentioned-href">@${entity.name}</a>`, '');
        }
        this.selectedIndex = 0;
        setTimeout(() => {
            this.note.noteText = noteText;

        }, 500);
    }

    async save() {
        this.saving = true;
        this.note.type = "Contractor Generated";
        await this.serviceApi.saveWorkOrderNoteNoAuth(this.note as any);
        this.saving = false;
        this.selectedIndex = 2;
    }
}
