import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SMSQuestionnaire {
    @keyColumn() id: string;
    @column() from: string;
    @column() to: string;
    @column() questionnaireId: string;
    @column() questionnaireState: string;
    @column() completionDate: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() questionnaireJson: string;
    @column() environmentJson: string;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() completeMessage: string;
    @column() expirationDate: Date;
}