import { Component, NgZone, OnInit } from '@angular/core';
import { ContractorsService } from '@cogent/client/shared/services/api/contractors.service';
// import { ContractorInvoice } from '@upkeeplabs/service-pros/app/model/accounting/contractor-invoice.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ActivatedRoute } from '@angular/router';
import { ContractorInvoice, ContractorTradeAreaTarget } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';

declare var Chart: any;
import Papa from 'papaparse';
import { ContractorKPIModel } from '@cogent/client/shared/models/contractor/contractor-kpi.model';

export class FieldDefinition {
    constructor(fieldNameLocal: string, fieldDescriptionLocal: string, dataTypeLocal: string) {
        this.fieldName = fieldNameLocal;
        this.fieldDescription = fieldDescriptionLocal;
        this.dataType = dataTypeLocal;
    }
    fieldName: string;
    fieldDescription: string;

    theirFieldName: string;
    dataType: string;


}

class GroupedInvoiceSummaries {
    tradeId: string;
    areaId: string;
    invoiceSummaries: ContractorInvoiceSummaryClient[];
    kpis: ContractorKPIModel[];
    currentClaimCount: number;
    currentClaimCost: number;
    targets: ContractorTradeAreaTarget[];
    accTarget: number;
    tradeName: string;
    areaName: string;


    static fromInvoiceSummaries(invoiceSummaries: ContractorInvoiceSummaryClient[]): GroupedInvoiceSummaries[] {
        const results: GroupedInvoiceSummaries[] = [];
        for (const invoiceSummary of invoiceSummaries.filter(i => !i.isInvalid)) {
            let group = results.find(i => i.areaId === invoiceSummary.areaId && i.tradeId === invoiceSummary.tradeId);
            if (!group) {
                group = new GroupedInvoiceSummaries();
                group.areaId = invoiceSummary.areaId;
                group.tradeId = invoiceSummary.tradeId;
                group.areaName = invoiceSummary.areaName;
                group.tradeName = invoiceSummary.tradeName;
                group.invoiceSummaries = [];
                results.push(group);
            }
            group.invoiceSummaries.push(invoiceSummary);
        }
        return results;
    }

    get submittableInvoiceSummaries() {
        if (!this.invoiceSummaries) {
            return [];
        }

        return this.invoiceSummaries.filter(i => !i.notFound && !i.isOverAutho && i.selected);
    }

    get newClaimCount() {
        const filteredItems = this.submittableInvoiceSummaries;
        if (filteredItems.length === 0) {
            return this.currentClaimCount;
        }

        return filteredItems.filter(i => i.workOrderType === '1st Call' || i.workOrderType === '2nd Opinion').length + this.currentClaimCount;
    }

    get newClaimCost() {
        const filteredItems = this.submittableInvoiceSummaries;
        if (filteredItems.length === 0) {
            return this.currentClaimCost;
        }

        return filteredItems.map(i => i.totalAmount).reduce((a, b) => a + b) + this.currentClaimCost;
    }

    get newAcc() {
        const claimCount = this.newClaimCount;
        if (claimCount === 0) {
            return 0;
        }

        return this.newClaimCost / claimCount;
    }

    setupKPIsAndTargets(kpis: ContractorKPIModel[], targets: ContractorTradeAreaTarget[]) {
        this.kpis = kpis.filter(i => i.reportPeriod.getMonth() === new Date().getMonth() && i.reportPeriod.getFullYear() === new Date().getFullYear()
            && i.areaId === this.areaId && i.tradeId === this.tradeId);
        if (this.kpis.length > 0) {
            this.currentClaimCount = this.kpis.map(i => i.claimCount).reduce((a, b) => a + b);
            this.currentClaimCost = this.kpis.map(i => i.claimCost).reduce((a, b) => a + b);
        } else {
            this.currentClaimCost = 0;
            this.currentClaimCount = 0;
        }
        this.targets = targets.filter(i => i.tradeId === this.tradeId && this.areaId === this.areaId);
        if (this.targets.length > 0) {
            this.accTarget = this.targets.map(i => i.aCCTarget)[0];
        } else {
            this.accTarget = 0;
        }
    }
}

@Component({
    selector: 'app-csv-import',
    templateUrl: './csv-import.component.html',
    styleUrls: ['./csv-import.component.css']
})
export class CsvImportComponent implements OnInit {

    csvContent: string;
    parsedCsv: string[][];
    selectedRecordIndex = 1;
    selectedIndex = 0;
    doingMapping = false;
    invoiceSummaries: ContractorInvoiceSummaryClient[];
    groupedInvoiceSummaries: GroupedInvoiceSummaries[];
    hasWarnings = false;
    savingContractorInvoices = false;
    kpis: ContractorKPIModel[];
    currentClaimCount = 0;
    currentClaimCost = 0;
    accChartId = UtilitiesService.newid();
    accChart: any;
    completeImport = false;
    showSubmitPanel = false;
    targets: ContractorTradeAreaTarget[];
    accTarget = 0;


    fieldDefinitions: FieldDefinition[] = [
        new FieldDefinition('WorkOrderNumber', 'Job #', 'Number'),
        new FieldDefinition('WorkPerformed', 'Work Performed', 'Text'),
        new FieldDefinition('PartsCost', 'Parts Cost', 'Number'),
        new FieldDefinition('LaborCost', 'Labor Cost', 'Number'),
        new FieldDefinition('Tax', 'Tax', 'Number'),
        new FieldDefinition('ContractorInvoiceNumber', 'Invoice #', 'Text'),
        new FieldDefinition('DateCompleted', 'Date Completed', 'Date'),
    ];
    constructor(private contractorService: ContractorsService,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private entityApi: EntityApiService) { }

    ngOnInit() {
        if (localStorage.getItem('invoicing-file-mapping')) {
            const existingFieldDefinitions: FieldDefinition[] = JSON.parse(JSON.stringify(this.fieldDefinitions));
            this.fieldDefinitions = JSON.parse(localStorage.getItem('invoicing-file-mapping'));
            existingFieldDefinitions.forEach(eFieldDef => {
                const storedDef = this.fieldDefinitions.filter(i => i.fieldName === eFieldDef.fieldName)[0];
                if (!storedDef) {
                    this.fieldDefinitions.push(eFieldDef);
                } else {
                    storedDef.fieldDescription = eFieldDef.fieldDescription;
                    storedDef.dataType = eFieldDef.dataType;
                }
            });
        }
        this.activatedRoute.url.subscribe(async segments => {
            const importPath = segments.find(i => i.path === 'complete-import');
            if (importPath) {
                this.completeImport = true;
                const user = await this.entityApi.getLoggedInUser(false);
                const invoices = await this.contractorService.getUnbilledCompletedInvoices(user.id);
                this.invoiceSummaries = invoices;
                this.groupedInvoiceSummaries = GroupedInvoiceSummaries.fromInvoiceSummaries(this.invoiceSummaries);
                this.refreshContractorKpis();
                this.setGroupedInvoicesKPIs();
                this.invoiceSummaries.forEach(is => is.selected = true);

                this.selectedIndex = 2;
            }
        });
    }

    get selectedExampleRecord() {
        if (!this.csvContent) {
            return null;
        }
        if (!this.csvContent[this.selectedRecordIndex]) {
            return null;
        }

        return {
            workOrderNumber: this.getExampleFieldValue('WorkOrderNumber', this.selectedRecordIndex, 'int'),
            workPerformed: this.getExampleFieldValue('WorkPerformed', this.selectedRecordIndex, 'string'),
            partsCost: this.getExampleFieldValue('PartsCost', this.selectedRecordIndex, 'float'),
            laborCost: this.getExampleFieldValue('LaborCost', this.selectedRecordIndex, 'float'),
            tax: this.getExampleFieldValue('Tax', this.selectedRecordIndex, 'float'),
            contractorInvoiceNumber: this.getExampleFieldValue('ContractorInvoiceNumber', this.selectedRecordIndex, 'string'),
            dateCompleted: this.getExampleFieldValue('DateCompleted', this.selectedRecordIndex, 'date'),
        };
    }

    get hasNotFoundInvoices(): boolean {
        if (!this.invoiceSummaries) {
            return false;
        }

        return this.invoiceSummaries.filter(i => i.workOrderNumber === -1).length > 0;
    }

    get notFoundAmount() {
        if (!this.invoiceSummaries) {
            return 0;
        }

        const filtered = this.invoiceSummaries.filter(i => i.workOrderNumber === -1);
        if (filtered.length === 0) {
            return 0;
        }

        return filtered.map(i => i.totalAmount).reduce((a, b) => a + b);
    }

    get notFoundCount() {
        if (!this.invoiceSummaries) {
            return 0;
        }

        return this.invoiceSummaries.filter(i => i.workOrderNumber === -1).length;
    }

    async saveContractorInvoices() {
        this.savingContractorInvoices = true;
        const invoicesToSave = this.invoiceSummaries.filter(i => i.selected).map(summary => {
            const contractorInvoice = new ContractorInvoice();
            contractorInvoice.contractorInvoiceNumber = summary.contractorInvoiceNumber;
            contractorInvoice.id = UtilitiesService.newid();
            contractorInvoice.laborCost = summary.laborCost;
            contractorInvoice.partsCost = summary.partsCost;
            contractorInvoice.receivedDate = new Date();
            contractorInvoice.tax = summary.tax;
            contractorInvoice.workOrderId = summary.workOrderId;
            contractorInvoice.workPerformed = summary.workPerformed;
            contractorInvoice.completionDate = summary.dateCompleted;

            return contractorInvoice;
        });

        await this.contractorService.saveContractorInvoices(invoicesToSave);
        this.invoiceSummaries = [];
        this.groupedInvoiceSummaries = [];
        this.savingContractorInvoices = false;
        this.selectedIndex = 3;
    }

    setACCChart(groupedItems: ContractorKPIModel[]) {
        const canvas = document.getElementById(this.accChartId) as any;
        if (!canvas) {
            setTimeout(() => this.setACCChart(groupedItems), 500);
            return;
        }
        const backgroundColors = [
            'rgba(184, 89, 149, .3)',
            'rgba(110,124,185, .1)',
            'rgba(184,52,45, .3)'
        ];
        const borderColors = [
            'rgba(184, 89, 149, 1)',
            'rgba(110,124,185, .5)',
            'rgba(184,52,45, 1)'
        ];

        const MONTHS = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const config = {
            type: 'bar',
            data: {
                labels:
                    groupedItems.map(i => MONTHS[i.reportPeriod.getMonth()] + ' ' + i.reportPeriod.getFullYear()),
                datasets: [

                    {
                        data: groupedItems.map(i => i.accTarget),
                        type: 'line',
                        label: 'Target'
                    },
                    {
                        data: groupedItems.map(i => i.averageClaimCost),
                        backgroundColor: backgroundColors[2],
                        borderColor: borderColors[2],
                        label: 'Actual'
                    },
                ]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'bottom',
                    display: true,
                },
                title: { display: false, },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: { mode: 'nearest', intersect: true },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            scaleLabel: { display: true, labelString: 'Month' },
                            gridLines: { display: false }
                        }
                    ],
                    yAxes: [
                        {
                            display: true,
                            scaleLabel:
                                { display: true, labelString: 'ACC' }
                        }
                    ]
                }
            }
        };

        const ctx = canvas.getContext('2d');
        if (this.accChart) {
            this.accChart.destroy();
        }
        this.accChart = new Chart(ctx, config);
    }

    removeNotFound() {
        const items = this.invoiceSummaries.filter(i => i.workOrderNumber === -1);
        items.forEach(item => {
            this.invoiceSummaries.splice(this.invoiceSummaries.indexOf(item), 1);
        });
    }

    get submittableInvoiceSummaries() {
        if (!this.invoiceSummaries) {
            return [];
        }

        return this.invoiceSummaries.filter(i => !i.notFound && !i.isOverAutho && i.selected);
    }

    get amountToPayTotal() {
        const submittable = this.submittableInvoiceSummaries;
        if (submittable.length === 0) {
            return 0;
        }

        return submittable.map(i => i.totalAmount).reduce((a, b) => a + b);
    }

    get count() {
        return this.submittableInvoiceSummaries.length;
    }

    get canSubmit() {
        return this.count > 0
            && this.submittableInvoiceSummaries.filter(i => i.isInvalid).length === 0;
    }

    async doMapping() {
        this.doingMapping = true;
        const contractorInvoiceSummaries = await this.contractorService.getContractorInvoicesFromFile(this.mappedFields);
        this.doingMapping = false;
        this.selectedIndex = 2;
        this.invoiceSummaries = contractorInvoiceSummaries;
        this.groupedInvoiceSummaries = GroupedInvoiceSummaries.fromInvoiceSummaries(this.invoiceSummaries);
        this.setGroupedInvoicesKPIs();
        this.refreshContractorKpis();
        for (const invoiceSummary of this.invoiceSummaries) {
            invoiceSummary.selected = !invoiceSummary.notFound;
        }

        setTimeout(() => {
            this.hasWarnings = this.invoiceSummaries.filter(i => i.isOverAutho).length > 0;
        }, 1000);
    }

    private setGroupedInvoicesKPIs() {
        if (!this.groupedInvoiceSummaries || !this.kpis || !this.targets) {
            return;
        }
        for (const invoiceSummary of this.groupedInvoiceSummaries) {
            invoiceSummary.setupKPIsAndTargets(this.kpis, this.targets);
        }
    }

    private refreshContractorKpis() {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        const groupedKpis: ContractorKPIModel[] = [];
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorService.getAllTradesContractorKPIs(user.id, startDate, endDate).then(kpis => {
                this.kpis = kpis;
                this.setGroupedInvoicesKPIs();

                for (const kpi of kpis) {
                    let groupedKpi = groupedKpis.filter(i => i.reportPeriod.getMonth() === kpi.reportPeriod.getMonth() && i.reportPeriod.getFullYear() === kpi.reportPeriod.getFullYear())[0];
                    if (!groupedKpi) {
                        groupedKpi = new ContractorKPIModel();
                        groupedKpi.reportPeriod = kpi.reportPeriod;
                        groupedKpi.claimCost = 0;
                        groupedKpi.claimCount = 0;
                        groupedKpi.accTarget = 0;
                        groupedKpis.push(groupedKpi);
                    }

                    groupedKpi.claimCost += kpi.claimCost;
                    groupedKpi.claimCount += kpi.claimCount;
                    groupedKpi.accTarget += kpi.accTarget * kpi.claimCount;

                }

                for (const kpi of groupedKpis) {
                    if (kpi.claimCount > 0) {
                        kpi.accTarget = kpi.accTarget / kpi.claimCount;
                    }
                }

                this.setACCChart(groupedKpis);

                const thisMonthsKpis = kpis.filter(i => i.reportPeriod.getMonth() === endDate.getMonth() && i.reportPeriod.getFullYear() === endDate.getFullYear());
                if (thisMonthsKpis.length > 0) {
                    this.currentClaimCount = thisMonthsKpis.map(i => i.claimCount).reduce((a, b) => a + b);
                    this.currentClaimCost = thisMonthsKpis.map(i => i.claimCost).reduce((a, b) => a + b);
                }
            });
        });
    }

    get newClaimCost() {
        const filteredItems = this.submittableInvoiceSummaries;
        if (filteredItems.length === 0) {
            return this.currentClaimCost;
        }

        return filteredItems.map(i => i.totalAmount).reduce((a, b) => a + b) + this.currentClaimCost;
    }

    get newClaimCount() {
        const filteredItems = this.submittableInvoiceSummaries;
        if (filteredItems.length === 0) {
            return this.currentClaimCount;
        }

        return filteredItems.filter(i => i.workOrderType === '1st Call' || i.workOrderType === '2nd Opinion').length + this.currentClaimCount;
    }

    get newAcc() {
        const claimCount = this.newClaimCount;
        if (claimCount === 0) {
            return 0;
        }

        return this.newClaimCost / claimCount;
    }

    showFileUpload() {
        document.getElementById('myFile').click();
    }

    removeItem(item: ContractorInvoiceSummaryClient) {
        item.removing = true;
        setTimeout(() => {
            this.invoiceSummaries.splice(this.invoiceSummaries.indexOf(item), 1);
            this.groupedInvoiceSummaries = GroupedInvoiceSummaries.fromInvoiceSummaries(this.invoiceSummaries);
            this.setGroupedInvoicesKPIs();
        }, 400);
    }

    nextExampleRecord() {
        this.selectedRecordIndex++;
    }

    prevExampleRecord() {
        this.selectedRecordIndex--;
    }

    saveMappings() {
        localStorage.setItem('invoicing-file-mapping', JSON.stringify(this.fieldDefinitions));
    }

    getExampleFieldValue(fieldName, index, type) {
        const fieldDefinition = this.fieldDefinitions.filter(i => i.fieldName === fieldName)[0];
        if (!fieldDefinition || !fieldDefinition.theirFieldName) {
            return null;
        }

        const theirFieldIndex = this.theirFieldNames.indexOf(fieldDefinition.theirFieldName);
        if (type === 'int') {
            const parsedNumber = parseInt(this.parsedCsv[index][theirFieldIndex], 10);
            if (isNaN(parsedNumber)) {
                return 0;
            }
            return parsedNumber;
        }

        if (type === 'float') {
            const parsedNumber = parseFloat(UtilitiesService.replaceAll(UtilitiesService.replaceAll(this.parsedCsv[index][theirFieldIndex], '$', ''), ',', ''));
            if (isNaN(parsedNumber)) {
                return 0;
            }
            return parsedNumber;
        }

        if (type === 'date') {
            const convertedDate: any = new Date(this.parsedCsv[index][theirFieldIndex])
            if (convertedDate.toString() === 'Invalid Date') {
                return;
            }
            return convertedDate;
        }
        return this.parsedCsv[index][theirFieldIndex];
    }

    get mappingComplete() {
        return this.fieldDefinitions.filter(i => i.fieldName === 'WorkOrderNumber')[0].theirFieldName;
    }

    get mappedFields() {
        if (!this.parsedCsv) {
            return null;
        }

        return this.parsedCsv.filter(i => this.parsedCsv.indexOf(i) > 0).map(i => {
            const index = this.parsedCsv.indexOf(i);

            return {
                workOrderNumber: this.getExampleFieldValue('WorkOrderNumber', index, 'int'),
                workPerformed: this.getExampleFieldValue('WorkPerformed', index, 'string'),
                partsCost: this.getExampleFieldValue('PartsCost', index, 'float'),
                laborCost: this.getExampleFieldValue('LaborCost', index, 'float'),
                tax: this.getExampleFieldValue('Tax', index, 'float'),
                contractorInvoiceNumber: this.getExampleFieldValue('ContractorInvoiceNumber', index, 'string'),
                dateCompleted: this.getExampleFieldValue('DateCompleted', index, 'date'),
            };
        });
    }

    get theirFieldNames() {
        if (!this.parsedCsv) {
            return [];
        }

        return this.parsedCsv[0].filter(i => i);
    }

    onFileSelect(input: HTMLInputElement) {

        Papa.parse(input.files[0], {
            complete: results => {
                this.ngZone.run(() => {
                    this.parsedCsv = results.data.filter(i => i[0]);
                    this.selectedIndex = 1;
                });
            }
        });

        const files = input.files;
        if (files && files.length) {
            const fileToRead = files[0];

            const fileReader = new FileReader();
            fileReader.onload = (fileLoadedEvent: any) => {
                // const csvSeparator = ',';
                const textFromFileLoaded = fileLoadedEvent.target.result;
                this.csvContent = textFromFileLoaded;
            };


            fileReader.readAsText(fileToRead, "UTF-8");
        }

    }

}
