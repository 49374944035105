import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ConnectChatSession {
    @keyColumn() id: string;
    @column() vendorId: string;
    @column() channel: string;
    @column() contactId: string;
    @column() previousContactId: string;
    @column() nextContactId: string;
    @column() participantId: string;
    @column() participantToken: string;
    @column() connectionCredentialsToken: string;
    @column() connectionCredentialsExpiration: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() completionDate: Date;
    @column() toNumber: string;
    @column() initiator: string;
    @column() contactFlowId: string;
}