export class SalesItemCoverageWorkOrderItemSelector {
    salesItemCoverageId: string;
    name: string;
    title: string;
    workOrderItemName: string;
    salesItemCoverageWorkOrderItemId: string;
    coveredRepairTypes: string;

    repairTypeId: string;
    touched = false;
    salesItemCoverageAuthorizationRepairItemId: string;
    authorizationRepairItemId: string;
    limitPerClaim: number;
    limitPerUnit: number;

    private _selected2: boolean;

    get selected2() {
        return this._selected2;
    }
    set selected2(value: boolean) {
        this.touched = true;
        this._selected2 = value;
    }

    get selected() {
        if (!this.coveredRepairTypes) {
            return false;
        }
        return this.coveredRepairTypes.indexOf(this.repairTypeId) > -1;
    }

    set selected(value: boolean) {
        this.touched = true;
        let repairTypes: any[] = JSON.parse(this.coveredRepairTypes);
        if (!repairTypes) {
            repairTypes = [];
        }
        if (value) {
            if (repairTypes.indexOf(this.repairTypeId) === -1) {
                repairTypes.push({ id: this.repairTypeId });
            }
        } else {
            let foundRepairType = repairTypes.find(i => i.id === this.repairTypeId);
            if (foundRepairType) {
                repairTypes.splice(repairTypes.indexOf(foundRepairType), 1);
            } else {
                foundRepairType = repairTypes.find(i=>i === this.repairTypeId);
                if(foundRepairType) {
                    repairTypes.splice(repairTypes.indexOf(foundRepairType), 1);
                }
            }
            
        }

        this.coveredRepairTypes = JSON.stringify(repairTypes);
    }
}