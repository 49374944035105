import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PlanItemLanguage {
    @keyColumn() id: string;
    @column() salesItemId: string;
    @column() covered: string;
    @column() notCovered: string;
    @column() limits: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
}