<div aflex>
    <h2 class="sub-heading">Recommend a Repair</h2>
    <div nogrow>
        <button mat-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<app-question-wizard-v2 #questionWizard [showGenericProfilePic]="true" [hideItemPicture]="true"
    [inverseQuestionOrder]="false" [workOrderItemId]="data.item.workOrderItemId" [offset]="true"
    [(isComplete)]="wizardComplete"></app-question-wizard-v2>

<mat-form-field appearance="outline" *ngIf="wizardComplete">
    <mat-label>Additional Notes</mat-label>
    <textarea matInput rows="3"
        [(ngModel)]="proposedWorkOrder.additionalNotes"></textarea>
</mat-form-field>
<div aflex>
    <div></div>
    <div nogrow>
        <button mat-button mat-raised-button color="primary" [disabled]="!canSave" (click)="save()">
            <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
            Save</button>
    </div>
</div>