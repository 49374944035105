import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';

@Component({
  selector: 'app-contractor-contact-types',
  standalone: true,
  imports: [CommonModule, MaterialSharedModule],
  templateUrl: './contractor-contact-types.component.html',
  styleUrls: ['./contractor-contact-types.component.css']
})
export class ContractorContactTypesComponent {

    

    constructor(
        public dialogRef: MatDialogRef<ContractorContactTypesComponent>) { }
    contactType: string;

    save() {
        this.dialogRef.close(this.contactType);
    }
}
