import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceCustomerPropertyServiceAppointmentSummary {
    @keyColumn() id: string;
    @column() scheduledDate: Date;
    @column() comments: string;
    @column() cost: number;
    @column() serviceRating: number;
    @column() maintenanceServiceName: string;
    @column() contractorName: string;
    @column() customerName: string;
    @column() loginId: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() contractorId: string;
    @column() propertyAddress1: string;
    @column() propertyAddress2: string;
    @column() propertyCity: string;
    @column() propertyState: string;
    @column() propertyPostalCode: string;
    @column() cancellationDate: Date;
    @column() completedDate: Date;
    @column() contractorComments: string;
    @column() maintenanceServiceOfferingId: string;
    @column() customerEmail: string;
    @column() customerPhoneNumber: string;
    @column() contractorNumber: string;
    @column() contractorEmail: string;
    @column() appointmentInstructions: string;
    @column() frequency: string;
    @column() justAppointmentCancelled: boolean;
    @column() surveyDate?: Date;
    @column() appointmentCancellationGracePeriodDays: number;
    @column() price: number;
    @column() customerId: string;
    @column() customerPropertyStatus: string;
    @column() maintenanceServiceId: string;
    @column() sendAppointmentReminders: boolean;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;
    @column() contractorInvoiceId: string;
    @column() serviceCancellationDate: Date;


    get appointmentCancellationGraceDate() {
        const dt = new Date(this.scheduledDate);
        dt.setDate(dt.getDate() + this.appointmentCancellationGracePeriodDays);

        return dt;
    }

    get canCreditOnCancellation() {
        return this.appointmentCancellationGraceDate > new Date();
    }

    selected?: boolean;
}