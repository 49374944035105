import { Component, OnInit } from '@angular/core';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    searchFor: string;
    searching = false;
    searchResults: SearchResultModel[];
    selectedContractor: SearchResultModel;
    selectedIndex = 0;
    passwordConfirm: string;
    password: string;
    loginName: string;
    verificationCode: number;
    saving = false;

    constructor(private searchApi: DataApiService,
        private dialogService: DialogsService,
        private missionService: MissionService,
        private contractorApi: ContractorApiService) { }

    ngOnInit(): void {
    }

    doSearch() {
        this.searching = true;
        this.searchApi.searchForTypeAndSubtype('Entity', 'Contractor', this.searchFor).then(results => {
            this.searching = false;
            this.searchResults = results;
        });
    }


    select(contractor: SearchResultModel) {
        (contractor as any).sending = true;
        this.contractorApi.sendRegistrationCode(contractor.id).then(() => {

            this.selectedContractor = contractor;
            this.selectedIndex = 1;
            (contractor as any).sending = false;
            this.missionService.showSuccessToast(`Registration Code Sent<br>Please check your email account ${this.selectedContractor.email} for the registation code.`);
        });
    }

    selectedIndexChange(index: number) {
        this.searchResults = null;
    }

    get canSave() {
        return this.verificationCode
            && this.loginName
            && this.password
            && this.password == this.passwordConfirm
            && !this.saving;
    }

    get passwordMismatch() {
        return this.password && this.password !== this.passwordConfirm;
    }

    save() {
        //completeContractorRegistration
        this.saving = true;
        this.contractorApi.completeContractorRegistration({
            verificationCode: this.verificationCode,
            loginName: this.loginName,
            password: this.password,
            entityId: this.selectedContractor.id,
        }).then(results => {
            this.saving = false;
            if (results.success) {
                this.selectedIndex = 2;
            } else {
                this.dialogService.alert('Cannot Register', results.message);
            }
        });
    }

}
