import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesRegionTarget {
    @keyColumn() id: string;
    @column() salesRegionId: string;
    @column() month: Date;
    @column() salesTarget: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() channel: string;
    @column() salesClosingTarget: number;

    
    // Client-side only
    isDirty = false;
}