import { ContractorTradeRegionCommitmentSummary, MaintenanceServiceContractorRegionSummary } from "@upkeeplabs/models/cogent";

export class ContractorSummary {
    id: string;
    tradeId: string;
    tradeName: string;
    contractorId: string;
    contractorName: string;
    email: string;
    officeNumber: string;
    regionName: string;
    areaId: string;
    address1: string;
    city: string;
    state: string;
    postalCode: string;

    get fullAddress() {
        return `${this.address1} ${this.city}, ${this.state} ${this.postalCode}`;
    }
}

export class ContractorSummaryByTrade {
    public tradeId: string;
    public tradeName: string;
    public contractors: ContractorSummary[];

    public static fromMaintenanaceServices(contractors: MaintenanceServiceContractorRegionSummary[]) : ContractorSummaryByTrade[] {
        const results = [];

        for (const contractor of contractors) {
            let region: any = results.find(i => i.areaId === contractor.contractorRegionId);
            if (!region) {
                region = {
                    areaId: contractor.contractorRegionId,
                    regionName: contractor.contractorRegionName,
                    trades: [],
                    expanded: true,
                };
                results.push(region);
            }
            let trade = region.trades.find(i => i.tradeId === contractor.maintenanceServiceId);
            if (!trade) {
                trade = {
                    tradeId: contractor.maintenanceServiceId,
                    tradeName: contractor.maintenanceServiceName,
                    items: [],
                    expanded: true,
                };
                region.trades.push(trade);
            }

            trade.items.push(contractor);
        }

        for (const region of results) {
            region.trades = region.trades.sort((a, b) => a.tradeName > b.tradeName ? 1 : -1);
            for (const trade of region.trades) {
                trade.items = trade.items.sort((a, b) => a.contractorName > b.contractorName ? 1 : -1);
            }
        }

        return results.sort((a, b) => a.regionName > b.regionName ? 1 : -1);
    }

    public static fromContractors(contractors: ContractorSummary[]): ContractorSummaryByTrade[] {
        const results = [];

        for (const contractor of contractors) {
            let region: any = results.find(i => i.areaId === contractor.areaId);
            if (!region) {
                region = {
                    areaId: contractor.areaId,
                    regionName: contractor.regionName,
                    trades: [],
                    expanded: true,
                };
                results.push(region);
            }
            let trade = region.trades.find(i => i.tradeId === contractor.tradeId);
            if (!trade) {
                trade = {
                    tradeId: contractor.tradeId,
                    tradeName: contractor.tradeName,
                    items: [],
                    expanded: true,
                };
                region.trades.push(trade);
            }

            trade.items.push(contractor);
        }

        for (const region of results) {
            region.trades = region.trades.sort((a, b) => a.tradeName > b.tradeName ? 1 : -1);
            for (const trade of region.trades) {
                trade.items = trade.items.sort((a, b) => a.contractorName > b.contractorName ? 1 : -1);
            }
        }

        return results.sort((a, b) => a.regionName > b.regionName ? 1 : -1);
    }
}
