import { CommonModule } from '@angular/common';
import { Component, Input, ViewChildren, QueryList, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-star-rating',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './star-rating.component.html',
    styleUrl: './star-rating.component.css'
})
export class StarRatingComponent {
    onChange;
    @Input() value;
    @Output() valueChange: EventEmitter<number> = new EventEmitter();
    stars = [1, 2, 3, 4, 5];

    @Input() id: string;
    @Input() disabled: boolean;
    @ViewChildren('ngxCheckbox') ngxCheckbox: QueryList<ElementRef>;

    constructor() {
        if (!this.disabled) {
            this.disabled = false;
        }
    }

    ngOnInit() { }

    rate(rate) {
        if (!this.disabled) {
            this.propagateChange(rate);
            this.writeValue(rate);
        }
    }

    writeValue(value) {
        if (this.ngxCheckbox && value === null) {
            this.ngxCheckbox.forEach((checkbox: ElementRef) => {
                checkbox.nativeElement.checked = false;
            });
        }
        this.value = value;
        this.valueChange.emit(this.value);
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) { }

    private propagateChange = (_: any) => { };
}
