import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AuthorizationLineAttribute {
    @keyColumn() id: string;
    @column() authorizationLineId: string;
    @column() attributeId: string;
    @column() valueId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() textValue: string;
}