<div class="container">
    <div aflex>
        <div>
            <h1 class="page-title">Settings</h1>

        </div>
        <div nogrow>

            <button mat-button mat-raised-button large color="primary" (click)="save()" [disabled]="saving || !canSave">
                <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                Save
            </button>
        </div>
    </div>
    <mat-tab-group>
        <mat-tab label="Basic Information">
            <div flex>
                <div>
                    <app-basic-entity-entry [outline]="true" [(canSave)]="canEntitySave" [canEditName]="false"
                        [showFirstAndLastName]="false" [nameIsSearchBox]="false" [showDispatchButton]="false" [entity]="contractor" [showAlternateEmailEntry]="true" alternateEmailLabel="Accounting Email"
                        showPhoneNumberEntry="true" [hidePaperless]="true" [hideInactiveBox]="true" [hideTimezoneEntry]="true" [hidePreferreds]="true">
                    </app-basic-entity-entry>
                </div>
                <div nogrow style="width: 250px">
                    <div class="data-label">Options</div>

                    <button mat-button block style="text-align: left;" (click)="showChangePassword()">
                        <mat-icon>lock</mat-icon>
                        Change Password
                    </button>

                    <a mat-button block style="text-align: left;" routerLink="/configure-areas">
                        <mat-icon>map</mat-icon> Configure Areas
                    </a>

                    <div class="data-label" topmargin30>Your Logo</div>
                    <app-swapable-image *ngIf="imageUrl" #swapableImage [(url)]="imageUrl"></app-swapable-image>

                    <mat-slide-toggle [(ngModel)]="maintVisible" (ngModelChange)="changeMaintVisible($event)" *ngIf="isMaintEnabled">Scheduled Maintenance View</mat-slide-toggle>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Documents">
            <app-entity-documents #entityDocuments [entity]="contractor"></app-entity-documents>
        </mat-tab>

        <mat-tab label="Contacts">
            <app-contractor-contacts #contractorContacts [entity]="contractor">

            </app-contractor-contacts>
        </mat-tab>


        <!-- <mat-tab label="Payments">

            <ng-container *ngIf="entityACH && entityACH.maskedAccountNumber && !editingACH">

                <mat-card class="ach-info">

                    <div aflex>
                        <div nogrow>
                            <img style="height: 75px;" src="../../../../assets/images/ach.png">
                        </div>

                        <div>
                            <div bottommargin10 class="data-label">Routing Number</div>
                            <div>{{entityACH.maskedRoutingNumber}}</div>
                            <div topmargin30 bottommargin10 class="data-label">Account Number</div>
                            <div>{{entityACH.maskedAccountNumber}}</div>

                            <div aflex>
                                <div></div>
                                <div nogrow>
                                    <button mat-button (click)="editingACH = true">
                                        <mat-icon>edit</mat-icon> Edit
                                    </button>

                                    <button mat-button (click)="deleteACHInfo()">
                                        <mat-icon>delete</mat-icon> Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-card>
            </ng-container>

            <ng-container *ngIf="entityACH && (!entityACH.maskedAccountNumber || editingACH)">
                <mat-card class="ach-info">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Routing Number" [(ngModel)]="newRoutingNumber"
                            maxlength="9">
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput type="text" placeholder="Account Number" [(ngModel)]="newAccountNumber">
                    </mat-form-field>

                    <div aflex *ngIf="entityACH && entityACH.maskedAccountNumber">
                        <div></div>
                        <div nogrow>
                            <button mat-button (click)="editingACH = false">
                                <mat-icon>chevron_left</mat-icon> Back
                            </button>
                        </div>
                    </div>
                </mat-card>
            </ng-container>

        </mat-tab> -->
<!-- 
        <mat-tab label="Technicians">
            <div flex>
                <div nogrow  id="technician-list">
                  <button mat-button block (click)="addNewUser()"><i class="material-icons">add</i> Add</button>
                  <mat-list>
                    <button (click)="selectEmployee(employee)" [class.active]="selectedEmployee && employee.id == selectedEmployee.id"
                      mat-button class="left-button" *ngFor="let employee of employees">
                      <mat-list-item>
                        <img mat-list-avatar src="{{getEntityThumbnailUrl(employee.id)}}">
                        <h3 matLine *ngIf="employee.lastName || employee.firstName">{{employee.lastName}}, {{employee.firstName}}</h3>
                        <h3 matLine *ngIf="!employee.lastName && !employee.firstName">(New Technician)</h3>
                        <p matLine>{{employee.email}}</p>
              
                      </mat-list-item>
                    </button>
                  </mat-list>
                </div>
                <div padding>
                  <div *ngIf="selectedEmployee" class="slide-in-right">
                    <div pull-right>
                      <button mat-button large [matMenuTriggerFor]="menu"><i class="material-icons">more_vert</i></button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="deleteTechnician()">
                            <mat-icon>delete</mat-icon>
                            Delete</button>
                      </mat-menu>
                    </div>
                    <div clearfix></div>
                    <mat-card id="mainCard">
                      <div flex>
                        <div nogrow>
                          <app-swapable-image [width]="100" #swapableImage [(url)]="url[selectedEmployee.id]"></app-swapable-image>
                        </div>
                        <div>
                          <app-basic-entity-entry showFirstAndLastName="true" [hideAddressEntry]="true" [hideTimezoneEntry]="true"  [hideInactiveBox]="true" [hidePreferreds]="true" [hideContactMethodsEntry]="true" [hidePaperless]="true" [entity]="selectedEmployee" showPhoneNumberEntry="true">
                          </app-basic-entity-entry>
                        </div>
                      </div>
                    </mat-card>

                    <mat-card id="createLoginBox" topmargin30 *ngIf="selectedEmployee && !login">
                        <div flex>
                            <i nogrow class="material-icons page-icon">lock_open</i>
                            <div>
                                <h1>Create Login</h1>
    
                                <mat-form-field topmargin30 appearance="outline">
                                    <mat-label>Login</mat-label>
                                    <input matInput type="email" autocomplete="false"
                                        [(ngModel)]="newLogin.userName">
                                </mat-form-field>
    
                                <mat-form-field appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" autocomplete="false"
                                        [(ngModel)]="newLogin.passwordHash">
                                </mat-form-field>
                                <button mat-button mat-raised-button color="primary" (click)="createLogin()"
                                    [disabled]="!canCreateLogin || creatingLogin">
                                    <mat-spinner *ngIf="creatingLogin" class="thirty"></mat-spinner>
                                    Create Login
                                </button>
                            </div>
                        </div>
                    </mat-card>
    
                    <mat-card *ngIf="selectedEmployee && login" topmargin30 id="existingLoginInfoBox">
                        <div flex>
                            <div nogrow>
                                <i class="material-icons page-icon">lock_outline</i>
                            </div>
                            <div>
                                <h1>{{login.userName}}</h1>
    
                                <div topmargin30 id="optionsSeparator">
                                    Options
                                </div>
                                <button mat-button (click)="deleteLogin()" [disabled]="deleting">
                                    <mat-spinner *ngIf="deleting" class="thirty"></mat-spinner> <i class="material-icons"
                                        *ngIf="!deleting">delete</i> Delete Login
                                </button>
                            </div>
                        </div>
    
                    </mat-card>
                  </div>
                </div>
              </div>
        </mat-tab>
         -->
    </mat-tab-group>
</div>
<app-maint-services-nav-bar *ngIf="isMaintContractor"></app-maint-services-nav-bar>