import { TriggerParameters } from '@cogent/client/shared/models/object-queue.model';
import { Trigger } from '@upkeeplabs/models/cogent';

export class TriggerClient extends Trigger {
    sla: string;
    slaMinutes: number;
    slaMinutesMax: number;

    private parametersObjectLocal: TriggerParameters;
    get parametersObject(): TriggerParameters {
        if (!this.parametersObjectLocal) {
            if (this.parameters) {
                this.parametersObjectLocal = JSON.parse(this.parameters);
            } else {
                this.parametersObjectLocal = new TriggerParameters();
            }
        }

        return this.parametersObjectLocal;
    }

    setParametersObject() {
        if (!this.parametersObjectLocal) {
            if (this.parameters) {
                this.parametersObjectLocal = JSON.parse(this.parameters);
            } else {
                this.parametersObjectLocal = new TriggerParameters();
            }
        }
    }
}
