import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class GlobalCommand {
    @keyColumn() id: string;
    @column() name: string;
    @column() objectType: string;
    @column() actions: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
}