import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { MaintenanceServiceFilter } from "./maintenance-service-filter.model";

@dataSource()
export class MaintenanceService {
    @keyColumn() id: string;
    @column() name: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() filters: string;
    @column() color: string;
    @column() additionalPricers: string;
    @column() hasSecondaryUnits: boolean;
    @column() allowChangeFrequency: boolean;
    @column() baseType: string;

    filtersObj: MaintenanceServiceFilter[];

    private _additionalPricersObj: MaintenanceServiceAddtionalPricer[];

    get additionalPricersObj(): MaintenanceServiceAddtionalPricer[] {
        if (!this._additionalPricersObj && this.additionalPricers) {
            try {
                this._additionalPricersObj = JSON.parse(this.additionalPricers);
            } catch { }
        }

        return this._additionalPricersObj;
    }
    set additionalPricersObj(value: MaintenanceServiceAddtionalPricer[]) {
        this._additionalPricersObj = value;
    }

}

export class MaintenanceServiceAddtionalPricer {
    id: string;
    name: string;
}