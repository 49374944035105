import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AmazonContact {
    @keyColumn() id: string;
    @column() amazonContactId: string;
    @column() customerEndpoint: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() policyId: string;
    @column() workOrderId: string;
    @column() callJSON: string;
    @column() contactDirection: string;
    @column() recordingLocation: string;
}