export class ContractorKPI {
    id: string;
    contractorId: string;
    tradeId: string;
    reportPeriod: Date;
    claimCount: number;
    claimCost: number;
    ratingStarsTotal: number;
    ratingStarsEarned: number;
    recalls: number;
    secondOpinions: number;
    firstCalls: number;
    contractorCost: number;
    purchaseOrderCost: number;
    cashOutCost: number;
    reportMonth: number;
    reportYear: number;
    tradeName: string;
    firstVisitResolvedCount: number;
    areaId: string;
    contractorRating: number;
    stateId: string;
    firstCallsCompleted: number;
    accTarget: number;
    recallTarget: number;
    firstCallCompleteTarget: number;
    surveyTarget: number;
    scoreTarget: number;

    // Client Side Only


    get costPerClaim(): number {
        if (this.claimCount === 0) {
            return 0;
        }

        return this.claimCost / this.claimCount;
    }

    get surveyCompletionPercent() {
        if (this.claimCount === 0) {
            return 0;
        }

        return (this.ratingStarsTotal / 5) / this.claimCount;
    }

    get letterGrade() {
        if (this.contractorRating < .6) {
            return 'F';
        }
        if (this.contractorRating < .63) {
            return 'D-';
        }
        if (this.contractorRating < .67) {
            return 'D';
        }
        if (this.contractorRating < .7) {
            return 'D+';
        }
        if (this.contractorRating < .73) {
            return 'C-';
        }
        if (this.contractorRating < .77) {
            return 'C';
        }
        if (this.contractorRating < .8) {
            return 'C+';
        }
        if (this.contractorRating < .83) {
            return 'B-';
        }
        if (this.contractorRating < .87) {
            return 'B';
        }
        if (this.contractorRating < .9) {
            return 'B+';
        }
        if (this.contractorRating < .93) {
            return 'A-';
        }
        if (this.contractorRating < .97) {
            return 'A';
        }

        return 'A+';
    }

    get firstCallCompletionPercent(): number {
        if (this.firstCallsCompleted === 0) {
            return 0;
        }

        return this.firstVisitResolvedCount / this.firstCallsCompleted;
    }

    get recallPercent(): number {
        if (this.firstCalls === 0) {
            return 0;
        }

        return this.recalls / this.firstCalls;
    }

    get rating(): number {
        if (this.ratingStarsTotal === 0) {
            return 0;
        }

        return (this.ratingStarsEarned / this.ratingStarsTotal) * 5;
    }

    get averageClaimCost(): number {
        if (this.claimCount === 0) {
            return 0;
        }

        return this.claimCost / this.claimCount;
    }
}
