import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceInvoice {
    @keyColumn() id: string;
    // @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() date: Date;
    @column() amount: number;
    @column() dueDate: Date;
    @column() invoiceType: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() payeeId: string;
    @column() payerId: string;
    @column() maintenanceServiceCustomerPropertyId: string;
    @column() amountPaid: number;
    @readOnlyColumn() invoiceNumber: number;
    @column() exportDate?: Date;
    @column() quickBooksId?: string;
    @column() memo: string;
    @column() vendorNumber: string;
    @column() quickBooksPaymentId?: string;
    @column() subType: string;
    

    get amountDue() {
        return this.amount - this.amountPaid;
    }
}