import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LazyComponentProviderService {

    static componentFactory: (key: string) => Promise<any>;
    getKeys() {
        return ['prepaid-service-fees', 'assign-contractor-dialog', 'change-work-order-status', 'purchase-order', 'appointment-scheduler', 'welcome-call', 'out-of-pockets'];
    }

    constructor() { }


    async getDynamicComponent(key: string): Promise<any> {
        if (LazyComponentProviderService.componentFactory) {
            return LazyComponentProviderService.componentFactory(key);
        }

        return null;
    }
}
