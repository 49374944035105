import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleWidgetSmallComponent } from './circle-widget-small.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [CircleWidgetSmallComponent],
    imports: [
        CommonModule,
        MatIconModule,
    ],
    exports: [
        CircleWidgetSmallComponent
    ]
})
export class CircleWidgetSmallModule { }
