import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CreditSummary {
    @keyColumn() id: string;
    @column() creditNumber: bigint;
    @column() customerName: string;
    @column() customerAddress1: string;
    @column() customerAddressCity: string;
    @column() customerAddressState: string;
    @column() customerAddressPostalCode: string;
    @column() customerEmail: string;
    @column() amount: number;
    @column() companyName: string;
    @column() companyId: string;
    @column() companyAddress1: string;
    @column() companyAddressCity: string;
    @column() companyAddressState: string;
    @column() companyAddressPostalCode: string;
    @column() createdDate: Date;
    @column() voidDate: Date;
    @column() amountApplied: number;
    @column() policyId: string;
    @column() memo: string;
    @column() customerId: string;
    @column() isAvailable: boolean;

    get remaining() {
        return this.amount - this.amountApplied;
    }
}