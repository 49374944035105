import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ScrollAnimationService {

  constructor() { }

  scrollToElement(fragment: string) {
    $('html, body').stop().animate({ scrollTop: $(fragment).offset().top }, 1500);
  }
}
