import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MatDialog } from '@angular/material/dialog';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ContractorContactTypesComponent } from '@cogent/client/shared/components/contractors/contractor-contact-types/contractor-contact-types.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { CommonModule } from '@angular/common';
import { Entity } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';

@Component({
    selector: 'app-contractor-contacts',
    standalone: true,
    templateUrl: './contractor-contacts.component.html',
    styleUrls: ['./contractor-contacts.component.css'],
    imports: [MaterialSharedModule, SwapableImageModule, BasicEntityEntryModule, CommonModule]
})
export class ContractorContactsComponent implements OnInit {

    @Input() entity: Entity;

    constructor(
        private dialog: MatDialog,
        private entityApi: EntityApiService,
        private missionService: MissionService,
    ) { }

    groups: ContactGroup[] = [];
    selected: Entity;
    deletedTechnicians: Entity[] = [];
    accessedTechnicians: Entity[] = [];
    url: any = {};
    time = new Date().getTime();

    async ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.entity && changes.entity.currentValue) {
            this.entity = changes.entity.currentValue;
            this.groups = [];
            this.load();
        }
    }

    async load() {
        this.groups = [];

        const employees = await this.entityApi.getContractorContacts(this.entity.id);
        if (!employees) return;

        const types = [...new Set(employees.map(i => i.type))];
        for (const type of types) {
            const emps = employees.filter(i => i.type === type);

            let groupName = this.getGroupName(type);
            let group = this.groups.filter(i => i.name === groupName)[0];
            if (!group) {
                group = new ContactGroup(groupName, null);
                this.groups.push(group);
            }

            for (const emp of emps) {
                const fullEntity = await this.entityApi.getFullEntity(emp.id);
                fullEntity.createdByName = emp.createdByName;
                group.employees.push(fullEntity);
            }
        }
    }

    getGroupName(type: string) {
        switch (type) {
            case 'Technician':
                return 'Technicians';
                break;
            case 'Dispatcher':
                return 'Dispatchers';
                break;
            case 'Accounting':
                return 'Accounting';
                break;
            case 'Manager':
                return 'Managers';
                break;
            case 'Owner':
                return 'Owners';
                break;
        }

    }

    async loadTechnicians() {
        const group = this.groups.filter(i => i.name === 'Technicians')[0];
        group.employees = [];
        const employees = await this.entityApi.getContractorTechnicians(this.entity.id);
        for (const employee of employees) {
            const e = await this.entityApi.getFullEntity(employee.id);
            e.createdByName = employee.createdByName;
            group.employees.push(e);
        }
    }

    async selectEmployee(employee: Entity) {
        this.selected = employee;
        if (employee.inactive != undefined) {
            const url = this.getEntityThumbnailUrl(employee.id);
            if (url) this.url[employee.id] = url;
        }
        if (!this.accessedTechnicians.find(i => i.id === employee.id)) {
            this.accessedTechnicians.push(employee);
        }
    }

    async deleteTechnician() {
        let index = this.accessedTechnicians.findIndex(i => i.id === this.selected.id);
        if (index >= 0) {
            this.accessedTechnicians.splice(index, 1);
        }
        if (this.selected.inactive != undefined) {
            this.deletedTechnicians.push(this.selected);
        }

        for (const group of this.groups) {
            index = group.employees.findIndex(i => i.id === this.selected.id);
            if (index >= 0) {
                group.employees.splice(index, 1);
            }
        }

        this.selected = null;
    }

    addNewUser() {
        const ref = this.dialog.open(ContractorContactTypesComponent, { data: '' });
        ref.afterClosed().subscribe(contactType => {
            if (contactType) {
                const employee = new Entity();
                employee.type = contactType;
                employee.parentId = this.entity.id;
                employee.id = UtilitiesService.newid();

                const groupName = this.getGroupName(contactType);
                const group = this.groups.filter(i => i.name === groupName)[0];
                if (!group) {
                    this.groups.push(new ContactGroup(groupName, [employee]));
                } else {
                    group.employees.push(employee);
                }

                this.url = this.entityApi.getThumbnailUri(employee.id);
                this.accessedTechnicians.push(employee);

                this.selected = employee;
            }
        });

    }

    getEntityThumbnailUrl(entityId: string) {
        return `${this.entityApi.getThumbnailUri(entityId)}?tick=${this.time}`;
    }

}

class ContactGroup {
    name: string;
    newName: string;
    employees: Entity[] = [];

    constructor(name: string, employees: Entity[]) {
        this.name = name;
        if (employees) this.employees = employees;
        if (name.endsWith('s'))
            this.newName = name.substring(0, name.length - 1);
        else
            this.newName = name;
    }
}