import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class TriggerSummary {
    @keyColumn() id: string;
    @column() name: string;
    @column() type: string;
    @column() startTime: Date;
    @column() endTime: Date;
    @column() status: string;
    @column() detail: string;
}