import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DispatchToInitialize {
    @keyColumn() id: string;
    @column() name: string;
    @column() email: string;
    @column() address1: string;
    @column() workNumber: string;
    @column() dispatchActivatedDate?: Date;
    @column() dispatchInitializedDate?: Date;
    @column() lastJobCreated?: Date;

    selected = false;
    canInitialize = false;
}