import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BrandApiService } from '@cogent/client/shared/services/api/brand-api.service';
import { CustomFormQuestions, FunctionCell } from '../../../../../../../../node/logic/functions/function-runner.model';
import { typeMap } from '../../../../../../../../node/logic/functions/function-runner.model'
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { SearchChipSelectionModule} from '@cogent/client/shared/components/chip-selectors/search-chip-selection/search-chip-selection.module';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
import { TagChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/tag-chip-selection/tag-chip-selection.module';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { FileUploadComponent } from '../../../misc/file-upload/file-upload.component';
import { SingleAddressEntryComponent } from '../../../misc/single-address-entry/single-address-entry.component';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
    selector: 'app-function-custom-form',
    templateUrl: './function-custom-form.component.html',
    standalone: true,
    imports: [CommonModule, MatFormFieldModule, FormsModule, MatSlideToggleModule, MatSliderModule, NgxMaskDirective,
        MatDatepickerModule, RichTextAndSnippetModule, AppointmentSelectionComponent, MatSlideToggleModule, FileUploadComponent, SingleAddressEntryComponent,
        SwapableImageModule, TagChipSelectionModule, MatInputModule, ReactiveFormsModule, MatSelectModule, MatButtonModule, SearchChipSelectionModule, MatCheckboxModule],
    styleUrls: ['./function-custom-form.component.css']
})
export class FunctionCustomFormComponent implements OnInit, OnChanges {

    @Input() customFormQuestions: CustomFormQuestions;
    @Output() completed: EventEmitter<boolean> = new EventEmitter();
    formId = UtilitiesService.newid();

    constructor(private brandApi: BrandApiService) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        //currentRunner.outputValues[question.id]
        if (changes.customFormQuestions.currentValue) {
            for (const question of this.customFormQuestions.questions) {
                if (question.type === typeMap.checkList) {
                    question.custom.selectedItems = {};
                }
                const inPorts = question.ports.items.filter(i => i.group === 'in');
                const defaultPort = inPorts.find(i => i.attrs?.label?.text === 'Default Value');
                if (defaultPort) {
                    const index = inPorts.indexOf(defaultPort);
                    this.currentRunner.outputValues[question.id] = question.inputParams.inputs[index];
                    const cell = changes.customFormQuestions.currentValue.functionRunner.cells.find(i => i.id === question.id);
                }
            }
        }
    }

    private _brands: any[];
    private gettingBrands = false;
    get brands() {
        if (!this._brands && !this.gettingBrands) {
            this.gettingBrands = true;
            this.brandApi.getAllBrands().then(brands => this._brands = brands);
        }

        return this._brands;

    }

    get typeMap() {
        return typeMap;
    }

    getAnswers(question: FunctionCell) {
        return question.ports.items.filter(i => i.group === 'transmissionOut' && i.attrs?.label?.text !== '_Default').map(i => i.attrs?.label?.text);
    }

    isRequired(question: FunctionCell) {
        return question.custom?.inputs.find(i => i.label === 'Required' && i.value) ? true : false;
    }

    getLabel(question: FunctionCell) {
        const label = question.custom?.inputs.find(i => i.label === 'Label');

        return (label ? label.value : question.attrs?.label?.text) + (this.isRequired(question) ? ' *' : '');
    }

    getInputValue(question: FunctionCell, inputName: string) {
        return question?.custom?.inputs?.find(i => i.label === inputName).value as any;
    }

    formatLabel(value: number) {
        return value as any;
    }

    get canSave() {
        return !this.customFormQuestions.questions.find(i => this.isRequired(i) && !this.currentRunner.outputValues[i.id]);
    }

    get currentRunner() {
        return this.customFormQuestions.executorInputParams.currentRunner;

        //outputValues
    }

    updateListAnswers(question: FunctionCell) {
        setTimeout(() => {

            const result = [];
            for (const key in question.custom.selectedItems) {
                if (question.custom.selectedItems[key]) {
                    result.push(key);
                }
            }
            this.currentRunner.outputValues[question.id] = result;
        });
    }

    save() {
        for (const question of this.customFormQuestions.questions) {
            if (!this.currentRunner.outputValues[question.id]) {
                this.currentRunner.outputValues[question.id] = '___unset___';
            }
        }
        this.customFormQuestions.executorInputParams.currentRunner.executeAndAdvance(this.customFormQuestions.endFormFunction);
        this.customFormQuestions = null;

        this.completed.emit(true);
    }

}
