import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MaintenanceServiceCustomerPropertyServiceAppointment {
    @keyColumn() id: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() scheduledDate: Date;
    @column() approvedDate: Date;
    @column() cost: number;
    @column() serviceRating: number;
    @column() comments: string;
    @column() timeWindow: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() completedDate: Date;
    @column() contractorComments: string;
    @column() invoiceDate?: Date;
    @column() cancellationDate?: Date;
    @column() surveyDate?: Date;
    @column() creditId: string
    @column() contractorId: string;
    @column() scheduledStartWindow: string;
    @column() scheduledEndWindow: string;

}