import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FunctionRunner } from '../../../../../../../../node/logic/functions/function-runner.logic';
import { CustomFormQuestions } from '../../../../../../../../node/logic/functions/function-runner.model';
import { CommonModule } from '@angular/common';
import { FunctionCustomFormComponent } from '../function-custom-form/function-custom-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-function-custom-form-modal',
    templateUrl: './function-custom-form-modal.component.html',
    standalone: true,
    imports: [CommonModule, FunctionCustomFormComponent, MatDialogModule, MatIconModule, MatButtonModule],
    styleUrls: ['./function-custom-form-modal.component.css']
})
export class FunctionCustomFormModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<FunctionCustomFormModalComponent>,
        @Inject(MAT_DIALOG_DATA) public customFormQuestions: CustomFormQuestions,) { }

    ngOnInit(): void {
    }

    public static ShowModal(dialog: MatDialog, customFormQuestions: CustomFormQuestions, functionRunner: FunctionRunner) {

        customFormQuestions.functionRunner = functionRunner;
        const ref = dialog.open(FunctionCustomFormModalComponent, { data:  customFormQuestions});
        ref.afterClosed().subscribe(result=> {
            functionRunner.lastCustomFormResult = result;
            if(!result) {
                functionRunner.executeAndAdvance(customFormQuestions.endFormFunction);
            }
        });
    }

    formComplete(result) {
        this.customFormQuestions.functionRunner.lastCustomFormResult = true;
        this.dialogRef.close(true);
    }

}
