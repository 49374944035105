<div style="display: inline-block;">
    <div class="time-picker-grid">
        <div class="center">
            <button mat-button [disabled]="disabled" (click)="hourUp()">
                <mat-icon>expand_less</mat-icon>
            </button>
        </div>
        <div></div>
        <div class="center">
            <button mat-button [disabled]="disabled" (click)="minuteUp()">
                <mat-icon>expand_less</mat-icon>
            </button>
        </div>
        <div class="buttons">
            <button mat-button [disabled]="disabled" class="toggle-button" [class.selected]="isAM" (click)="setAM()">AM</button>
            <br>
            <button mat-button [disabled]="disabled" class="toggle-button" [class.selected]="!isAM" (click)="setPM()">PM</button>
        </div>

        <div>
            <mat-form-field appearance="outline">
                <input matInput [disabled]="disabled" autocomplete="off" (keydown)="onKeydown($event, false)" [(ngModel)]="hour"
                    (ngModelChange)="update()">
            </mat-form-field>

        </div>
        <div class="colon">:</div>
        <div>
            <mat-form-field appearance="outline">
                <input matInput [disabled]="disabled" autocomplete="off" (keydown)="onKeydown($event, true)" [(ngModel)]="minute"
                    (ngModelChange)="update()">
            </mat-form-field>
        </div>
        <div class="center">
            <button mat-button [disabled]="disabled" (click)="hourDown()">
                <mat-icon>expand_more</mat-icon>
            </button>
        </div>
        <div></div>
        <div class="center">
            <button mat-button [disabled]="disabled" (click)="minuteDown()">
                <mat-icon>expand_more</mat-icon>
            </button>
        </div>
    </div>
    <div class="center" *ngIf="showPreview">
        <div class="time-preview">
            {{selectedTime | date: 'shortTime'}}
        </div>
    </div>
</div>