import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class DispatchNoteLog {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() dispatchLogId?: string;
    @column() noteId?: string;
    @column() noteText?: string;
    @column() fileName?: string;
    @column() assignedToName?: string;
    @column() assignedToEmail?: string;
    @column() dateSent?: Date;
    @column() dispatchNoteId?: string;
    @column() acknowledged?: boolean;
}

export class LogAssignedTo {
    assignedTo: string;
    assignedToId: string;
}