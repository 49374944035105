<ng-container *ngFor="let item of addOn.args.env">
    <div *ngIf="item.label && item.value" class="conversation-item-container">
        <div *ngIf="item.type !== '/assets/images/function-icons/confirm-button.png'">
            <div class="conversation-bubble incoming">{{item.label}}</div>
        </div>
        <div aflex *ngIf="item.type !== '/assets/images/function-icons/message.png' && item.type !== '/assets/images/function-icons/confirm-button.png'">
            <div flex1></div>
            <div *ngIf="item.type !== '/assets/images/function-icons/upload-image.png'" style="flex: none;" class="conversation-bubble outgoing">{{item.value}}</div>
            <div *ngIf="item.type === '/assets/images/function-icons/upload-image.png' && item.value && item.value.length">
                <img [src]="item.value[0].base64" style="max-height: 100px">
            </div>
        </div>
    </div>
    <!-- </li> -->
</ng-container>