import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class MyMTA {
    @keyColumn() id: string;
    @column() number: number;
    @column() name: string;
    @column() image: string;
    @column() phone: string;
    @column() email: string;
    @column() dre: string;
    @column() nar: string;
    @column() officeName: string;
    @column() address: string;
    @column() city: string;
    @column() state: string;
    @column() zip: string;
    @column() officePhone: string;
    @column() association: string;
    @column() createdDate: Date;
    @column() createdById: string;
    @column() lastModifiedDate: Date;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;
    @column() standardized: boolean = false;
    @column() standardizeFailed: boolean = false;
}