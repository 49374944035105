import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorKPIBuild {
    @keyColumn() id: string;
    @column() reportDate: Date;
    @column() areaId: string;
    @column() tradeId: string;
    @column() contractorId: string;
    @column() claimCount: number;
    @column() claimCost: number;
    @column() ratingStarsTotal: number;
    @column() ratingStarsEarned: number;
    @column() recalls: number;
    @column() secondOpinions: number;
    @column() firstCalls: number;
    @column() contractorCost: number;
    @column() purchaseOrderCost: number;
    @column() cashOutCost: number;
    @column() firstVisitResolvedCount: number;
    @column() firstVisitCompletedCount: number;
    @column() contractorRating: number;
    @column() deletedDate?: Date;
}