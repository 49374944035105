import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SalesItemCoverage {
    @keyColumn() id: string;
    @column() name: string;
    @column() title: string;
    @column() coveredLanguage: string;
    @column() notCoveredLanguage: string;
    @column() limitsLanguage: string;
    @column() deletedDate?: Date;
    @column() salesItemId: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() limitPerPlanPeriod: number;
    @column() limitPerOccurrence: number;
    @column() inactive: boolean;
    @column() coveredQuantity: number;
    @column() coveredQuantityQuestion: string;
}