export class StripeTransactionCharge {
    stripeCardId: string;
    stripeCustomerId: string;
    amount: number;
    invoicesToPay: InvoiceToPay[];
    policyId: string;
    removeCardAfterProcessing?:boolean;  
    commissionable?:boolean;
}

export class InvoiceToPay {
    invoiceId?: string;
    amount?: number;

    totalInvoiceAmount?: number;
}
