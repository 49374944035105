import { column, dataSource, keyColumn } from "@upkeeplabs/linq";

@dataSource()
export class DispatchJobStatus {
    @column() id: string;
    @column() createdDate?: Date;
    @column() workOrderId?: string;
    @column() status: string;
    @column() statusMessage: string;
    @column() updatedDate?: Date;
}