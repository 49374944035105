import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class GeographicalRegion {
    @keyColumn() id: string;
    @column() entityId: string;
    // @column() geographicalCoordinates: hierarchyid;
    // @column() geographicalCoordinates: geometry;
    // @column() geographicalCoordinates: geography;
    @column() rawKML: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() geographicalCoordinatesText: string;
    // @column() centerLat: float;
    // @column() centerLon: float;
}