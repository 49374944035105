import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AmazonConnectHistoricalMetric {
    @keyColumn() id: string;
    @column() queueId: string;
    @column() reportDate: Date;
    @column() hour: number;
    @column() contactsQueued: number;
    @column() contactsHandled: number;
    @column() contactsAbandoned: number;
    @column() contactsConsulted: number;
    @column() contactsAgentHungUpFirst: number;
    @column() contactsHandledIncoming: number;
    @column() contactsHandledOutbound: number;
    @column() contactsHoldAbandons: number;
    @column() contactsTranferredIn: number;
    @column() contactsTransferredOut: number;
    @column() contactsTransferredInFromQueue: number;
    @column() contactsTranferredOutFromQueue: number;
    @column() contactsMissed: number;
    @column() abandonTime: number;
    @column() queueAnswerTime: number;
    @column() holdTime: number;
    @column() interationTime: number;
    @column() interactionAndHoldTime: number;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() handleTime: number;
}