import { AuthConfig } from '@cogent/client/shared/types/auth-config';
// import { ApiConfig } from '@upkeeplabs/api';

const AuthConfig: AuthConfig = {
    // authority: "https://accounts.upkeeplabs.com/",
    clientId: "service-pros",
    logoutUri: "/auth/logout",
    scope: "openid profile email",
    redirectUri: "/auth/call-back"
};


// const ApiConfig: ApiConfig = {
//     endpoint: "https://api.upkeeplabs.com/api/"
// };

export const environment = {
    production: true,
    apiBaseUrl: 'https://api.upkeeplabs.com/api/',
    authConfig: AuthConfig,
    // apiConfig: ApiConfig
};

