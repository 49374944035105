import { ObjectSummaryField } from "@cogent/shared/models/common/object-summary-field.model";

import { LayoutContainer } from '@cogent/client/shared/models/user-dash-board.model';



export class CustomTable {
    id: string;
    objectType = '__CustomTable';
    name: string;
    description: string;
    fields: ObjectSummaryField[];
    canEmployeeAccessType: boolean;
    canEmployeeUpsertType: boolean;
    canEmployeeDeleteType: boolean;
    canRealEstateAgentAccessType: boolean;
    canRealEstateAgentUpsertType: boolean;
    canRealEstateAgentDeleteType: boolean;
    canCustomerAccessType: boolean;
    canCustomerUpsertType: boolean;
    canCustomerDeleteType: boolean;
    canContractorAccessType: boolean;
    canContractorUpsertType: boolean;
    canContractorDeleteType: boolean;
    canTechnicianAccessType: boolean;
    canTechnicianUpsertType: boolean;
    canTechnicianDeleteType: boolean;
    layoutContainers: LayoutContainer[];
    recordDescription: string;
    iconClass: string;
    viewDynamicComponentId: string;
}