import {
    keyColumn, column, dataSource
} from "@upkeeplabs/linq";

@dataSource()
export class MlsListing {
    @keyColumn() id: string;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedDate?: Date;
    @column() lastModifiedById?: string;
    @column() deletedDate?: Date;
    @column() mls: string;
    @column() mlsNumber: number;
    @column() photoUrl: string;
    @column() soldPrice: number;
    @column() listPrice: number;
    @column() pricePerSqFt: number;
    @column() entryDate: Date;
    @column() status: string;
    @column() cdom: number;
    @column() dom: number;
    @column() ctdom: number;
    @column() contractDate: Date;
    @column() soldDate: Date;
    @column() address: string;
    @column() city: string;
    @column() state: string;
    @column() zip: string;
    @column() subdivision: string;
    @column() taxId: string;
    @column() hoa: boolean;
    @column() schoolDistrict: string;
    @column() elementarySchool: string;
    @column() juniorHighSchool: string;
    @column() seniorHighSchool: string;
    @column() otherSchool: string;
    @column() sqFtBasement: number;
    @column() sqFt1stFloor: number;
    @column() sqFt2ndFloor: number;
    @column() sqFt3rdFloor: number;
    @column() sqFt4thFloor: number;
    @column() sqFt: number;
    @column() type: string;
    @column() style: string;
    @column() yearBuilt: number;
    @column() acres: number;
    @column() deck: number;
    @column() patio: number;
    @column() garage: number;
    @column() pool: boolean;
    @column() poolFeatures: string;
    @column() spa: boolean;
    @column() solar: boolean;
    @column() zoning: string;
    @column() owner: string;
    @column() ownerType: string;
    @column() contact: string;
    @column() contactType: string;
    @column() contactPhone1: string;
    @column() contactPhone2: string;
    @column() listingAgentName: string;
    @column() listingAgentId: string;
    @column() listingAgentLicenseNumber: string;
    @column() listingAgentEmail: string;
    @column() listingAgentPhone: string;
    @column() listingAgentMobile: string;
    @column() coAgentName: string;
    @column() coAgentId: string;
    @column() coAgentLicenseNumber: string;
    @column() coAgentEmail: string;
    @column() coAgentPhone: string;
    @column() coAgentMobile: string;
    @column() listingOfficeName: string;
    @column() listingOfficeId: string;
    @column() listingOfficePhone: string;
    @column() buyerAgentName: string;
    @column() buyerAgentId: string;
    @column() buyerAgentLicenseNumber: string;
    @column() buyerAgentEmail: string;
    @column() buyerAgentPhone: string;
    @column() buyerAgentMobile: string;
    @column() buyerOfficeName: string;
    @column() buyerOfficeId: string;
    @column() buyerOfficePhone: string;
    /*@column()*/ listingHtml: string;
    @column() addressStandardized: boolean = false;
    @column() addressStandardizeFailed: boolean = false;
}