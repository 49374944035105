import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { Entity } from "./entity.model";

@dataSource()
export class UserTextSnippet {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() key: string;
    @column() note: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;

    // client-side only
    edit = false;
    saving = false;
    entity: Entity;
    searchResult: any;
    isSearchResult = false;
    isMention = false;
    setFindMode = false;
    url: string;
    name: any;
}