import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    templateUrl: './toast-success.component.html',
    standalone: true,
    imports: [CommonModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,],
    styleUrls: ['./toast-success.component.css']
})
export class ToastSuccessComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackRef: MatSnackBarRef<ToastSuccessComponent>) { }

    dismiss() {
        this.snackRef.dismiss();
    }
}


@Component({
    templateUrl: './lottie-info.component.html',
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,],
    styleUrls: ['./lottie-info.component.css']
})
export class LottieInfoComponent {
    show = false;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackRef: MatSnackBarRef<LottieInfoComponent>) {

        setTimeout(() => {
            this.show = true;
        }, 100);
    }

    dismiss() {
        this.snackRef.dismiss();
    }
}