import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { ZipCode } from "./zip-code.model";

@dataSource()
export class ContractorTradeRegion {
    @keyColumn() id: string;
    @column() contractorTradeId: string;
    @column() contractorRegionId?: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() capacity: number;
    @column() excludedPostalCodes: string;
    @column() excludedItems: string;
    @column() serviceProTerritoryId: string;

    zipCodes: ZipCode[];




    private _excludedPostalCodesObject: ContractorTradeRegionExcludedPostalCode[];
    private _excludedItemsObject: ContractorTradeRegionExcludedItem[];

    get excludedPostalCodesObject(): ContractorTradeRegionExcludedPostalCode[] {
        if (!this._excludedPostalCodesObject) {
            if (this.excludedPostalCodes) {
                try {
                    this._excludedPostalCodesObject = JSON.parse(this.excludedPostalCodes);
                } catch (e) { }
            } else {
                this._excludedPostalCodesObject = [];
            }
        }

        return this._excludedPostalCodesObject;
    }
    set excludedPostalCodesObject(value: ContractorTradeRegionExcludedPostalCode[]) {
        this._excludedPostalCodesObject = value;
        this.excludedPostalCodes = JSON.stringify(value);
    }

    get excludedItemsObject(): ContractorTradeRegionExcludedItem[] {
        if (!this._excludedItemsObject) {
            try {
                if (this.excludedItems) {
                    this._excludedItemsObject = JSON.parse(this.excludedItems);
                } else {
                    this._excludedItemsObject = [];
                }
            } catch (e) {
                this._excludedItemsObject = [];
            }
        }
        return this._excludedItemsObject;
    }
    set excludedItemsObject(value: ContractorTradeRegionExcludedItem[]) {
        this._excludedItemsObject = value;
        this.excludedItems = JSON.stringify(value);
    }
}



export class ContractorTradeRegionExcludedPostalCode {
    postalCode: string;
}

export class ContractorTradeRegionExcludedItem {
    itemId: string;
}

export class EntityNameAndId {
    name: string;
    id: string;
}

export class ContractorStateGrouping extends EntityNameAndId {
    areas: ContractorAreaGrouping[];
}

export class ContractorAreaGrouping extends EntityNameAndId {
    regions: EntityNameAndId[];
}