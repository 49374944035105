import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { DateRangeDisplayAndSelectorComponent } from '@cogent/client/shared/components/misc/date-range-display-and-selector/date-range-display-and-selector.component';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';
import { EntityApiService } from '@cogent/client/shared/services/api/entity-api.service';
import { ContractorTradeAreaTarget, ContractorTradeAreaTargetSummary, Entity, Trade } from '@upkeeplabs/models/cogent';

declare var document: any;
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-contractor-report-card',
    standalone: true,
    imports: [CommonModule, DateRangeDisplayAndSelectorComponent, MatButtonModule, MatSelectModule, FormsModule, MatFormFieldModule, MatCheckboxModule],
    templateUrl: './contractor-report-card.component.html',
    styleUrl: './contractor-report-card.component.scss'
})
export class ContractorReportCardComponent implements OnInit {

    constructor(private activatedRoute: ActivatedRoute,
        private contractorApi: ContractorApiService,
        private entityApi: EntityApiService
    ) {
    }

    today: Date = new Date();
    startDate: Date;
    endDate: Date;
    runningReport = false;
    originalTargets: ContractorTradeAreaTargetSummary[];
    // targets: ContractorTradeAreaTargetSummary[];
    contractor: Entity;
    originalTrades: Trade[];
    trades: Trade[] = [];
    selectedRegion: Entity;
    originalRegions: Entity[];
    regions: Entity[];
    selectedArea: Entity;
    originalAreas: Entity[];
    areas: Entity[] = [];
    originalItems: any[];
    filteredItems: any[];
    item: ContractorItem;
    selectedTradeName = "";
    accChart: any;

    async ngOnInit() {
        this.entityApi.getLoggedInUser().then(user => {
            this.contractor = user;
        });

        this.startDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
        this.endDate = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);

        this.originalTrades = await this.contractorApi.getTrades();

        this.originalAreas = await this.entityApi.getActiveEntitiesByType("ServiceProManagerArea");
        for (const area of this.originalAreas) area.selected = true;

        this.originalRegions = await this.entityApi.getActiveEntitiesByType("ServiceProRegion");
    }

    async runReport() {
        this.runningReport = true;

        //GET CONTRACTOR REPORT CARD ITEMS
        const start = this.startDate;
        start.setDate(start.getDate() + 1);
        const results = await this.contractorApi.getContractorStats(start, this.endDate, null, this.contractor.id);
        this.originalItems = results.filter(i => i.contractorId == this.contractor.id);

        this.filteredItems = [...this.originalItems];

        //FILTER TRADES
        this.trades = [];
        const tradeIds = [...new Set(this.filteredItems.map(i => i.tradeId))].sort((a, b) => a > b ? 1 : -1);
        for (const tradeId of tradeIds) {
            const trade = this.originalTrades.find(i => i.id == tradeId);
            if (trade) {
                trade.selected = true;
                this.trades.push(trade);
            }
        }

        this.originalTargets = await this.contractorApi.getContractorTradeTargetSummaries(this.startDate, this.endDate, null);

        this.refreshReport();

        this.runningReport = false;
    }

    async refreshReport() {
        // console.log([...new Set(this.filteredItems.map(i => i.tradeId))]);
        if (this.filteredItems[0]) {
            this.item = new ContractorItem();

            //FILTER AREAS
            this.areas = [];
            for (const area of this.originalAreas) {
                const item = this.filteredItems.find(i => i.areaId == area.id);
                if (item) this.areas.push(area);
            }

            //FILTER REGIONS
            this.regions = [];
            for (const region of this.originalRegions) {
                const item = this.filteredItems.find(i => i.regionId == region.id);
                if (item) this.regions.push(region);
            }

            //SET AREA TARGETS
            await this.updateAreaTargets();

            this.item.id = this.filteredItems[0].contractorId;
            this.item.name = this.filteredItems[0].contractorName;
            this.item.tierName = this.filteredItems[0].tierName;
            this.item.total_claims = this.totalClaims;

            this.item.accTarget = 0;
            this.item.surveyTarget = 0;
            this.item.recallTarget = 0;
            let areaTargetCount = 0;
            let areas = [];
            this.item.regions = [];

            for (const region of this.regions) {
                const regionItem = new RegionItem();
                regionItem.id = region.id;
                regionItem.name = region.name;
                //regionItem.total_claims = this.totalClaims;

                regionItem.items = this.filteredItems.filter(i => i.regionId == region.id).sort((a, b) => a.endOfMonth < b.endOfMonth ? -1 : 1);
                if (regionItem.items?.length > 0) {
                    const area = this.areas.find(i => i.id == region.parentId);

                    regionItem.areaTargets = area.areaTargets;

                    for (const item2 of regionItem.items) {
                        item2.tradeACCTarget = this.tradeACCTarget;
                        item2.tradeRatingTarget = this.tradeRatingTarget;
                        item2.tradeRecallTarget = this.tradeRecallTarget;
                        item2.recallPercent = item2.recallCount / item2.claims;
                        const itemTarget = regionItem.areaTargets.find(i => i.monthNo == item2.monthNo && i.monthYear == item2.monthYear && i.areaId == item2.areaId && i.tradeId == item2.tradeId);
                        if (itemTarget) item2.aCCTarget = itemTarget.aCCTarget;

                    }

                    if (areas.indexOf(area.name) < 0) {
                        if (area.areaTargets) {
                            this.item.accTarget += UtilitiesService.sum(area.areaTargets, i => Number(i.aCCTarget));
                            this.item.surveyTarget += UtilitiesService.sum(area.areaTargets, i => Number(i.surveyTarget));
                            this.item.recallTarget += UtilitiesService.sum(area.areaTargets, i => Number(i.recallTarget));

                            areaTargetCount += area.areaTargets.length;
                        }
                        areas.push(area.name);
                        this.item.areaCount += 1;
                    }
                    this.item.regions.push(regionItem);
                }
            }
            this.item.accTarget = this.item.accTarget / areaTargetCount;
            if (this.item.totalContractorACC == 0) this.item.contractorAccDiff = 1;
            else this.item.contractorAccDiff = Number(this.item.accTarget / this.item.totalContractorACC);

            this.item.surveyTarget = this.item.surveyTarget / areaTargetCount;
            if (this.item.totalContractorRating == 0) this.item.contractorRatingDiff = 1;
            else {
                if (this.item.surveyTarget)
                    this.item.contractorRatingDiff = Number(this.item.totalContractorRating / this.item.surveyTarget);
                else
                    this.item.contractorRatingDiff = null;
            }

            this.item.recallTarget = this.item.recallTarget / areaTargetCount;
            if (this.item.totalContractorRecall == 0) this.item.contractorRecallDiff = 1;
            else {
                if (this.item.recallTarget)
                    this.item.contractorRecallDiff = Number(this.item.recallTarget / this.item.totalContractorRecall);
                else
                    this.item.contractorRecallDiff = null;
            }


            this.loadChart();
        }
    }

    async updateAreaTargets() {
        this.item.targets = [];
        for (const area of this.areas) {
            area.areaTargets = [];
            for (const trade of this.trades) {
                if (trade.selected) {
                    const targets = this.originalTargets.filter(i => i.areaId == area.id && i.tradeId == trade.id);
                    area.areaTargets = area.areaTargets.concat(targets);
                    this.item.targets = this.item.targets.concat(targets);
                }
            }
        }
    }

    async tradeChange(trade: TradeItem) {
        this.filteredItems = [];
        for (const trade of this.trades) {
            if (trade.selected) {
                const items = [...this.originalItems];
                const tradeItems = items.filter(i => i.tradeId == trade.id);
                this.filteredItems = this.filteredItems.concat(tradeItems);
            }
        }

        this.refreshReport();
    }

    loadChart() {
        Chart.register(ChartDataLabels);
        const canvas = document.getElementById('accChart');
        if (!canvas) {
            setTimeout(() => this.loadChart(), 500);
            return;
        }

        const months = [];
        const datasets = [
            {
                type: 'line', label: 'Target', data: [], backgroundColor: '#e57e00',
            },
            {
                type: 'bar', label: 'ACC', data: [],
                backgroundColor: 'rgba(107, 122, 158, 0.364)',
                borderColor: 'rgb(0, 38, 128)',
            },
        ];

        let _date = new Date(this.startDate);
        while (_date <= this.endDate) {
            const _month = _date.getMonth();
            const _year = _date.getFullYear();
            const acc = Math.round(this.item.totalContractorCostsByMonth(_month, _year) / this.item.totalContractorClaimsByMonth(_month, _year));
            const accTarget = this.item.contractorAccTargetByMonth(_month, _year); 
            if (acc > -1) {
                months.push(`${_month + 1}/${_year.toString().slice(-2)}`);
                datasets[0].data.push(accTarget)
                datasets[1].data.push(acc)
            }

            _date.setMonth(_date.getMonth() + 1);
        }
        const data = { labels: months, datasets: datasets };
        const config = {
            type: 'scatter',
            plugins: [ChartDataLabels],
            data: data,
            options: {
                scales: {
                    y: {
                        display: true,
                        beginAtZero: true
                    }
                },
            },
        };

        const ctx = canvas.getContext('2d');
        if (this.accChart) {
            this.accChart.destroy();
        }
        this.accChart = new Chart(ctx, config as any);
    }

    get totalClaims() {
        if (!this.filteredItems) return 0;
        return UtilitiesService.sum(this.filteredItems, i => i.claims);
    }

    get tradeACCTarget() {
        if (!this.item.targets || this.item.targets.length == 0) return 0;
        return UtilitiesService.sum(this.item.targets, i => i.aCCTarget) / this.item.targets.length;
    }

    get tradeRatingTarget() {
        if (!this.item.targets || this.item.targets.length == 0) return 0;
        const targets = this.item.targets;
        return UtilitiesService.sum(targets, i => i.surveyTarget) / targets.length;
    }

    get tradeRecallTarget() {
        if (!this.item.targets || this.item.targets.length == 0) return 0;
        const targets = this.item.targets;
        return UtilitiesService.sum(targets, i => i.recallTarget) / targets.length;
    }

    get singleTrade() {
        if (!this.filteredItems || this.filteredItems.length === 0) return false;
        if ([...new Set(this.filteredItems.map(i => i.tradeId))].length == 1) return true;
        return false;
    }

}

class TradeItem {
    id: string;
    name: string;
    selected = false;
}


class ContractorItem {
    id: string;
    name: string;
    tierName: string;
    targets: any[];
    regions: RegionItem[];
    targetCount = 0;
    isOpen = false;
    contractorAccDiff = 0;
    contractorRatingDiff = 0;
    contractorRecallDiff = 0;
    total_claims = 0;
    areaCount = 0;
    accTarget = 0;
    surveyTarget = 0;
    recallTarget = 0;
    hideName = false;
    // trades: TradeItem[];

    targetToEdit(channel: string) {
        return this.targets.find(i => i.channel == channel);
    }

    letterGrade(pct: number) {
        pct = Math.round(pct * 100) / 100;
        if (pct >= .97) return 'A+';
        if (pct >= .93) return 'A';
        if (pct >= .90) return 'A-';
        if (pct >= .87) return 'B+';
        if (pct >= .83) return 'B';
        if (pct >= .80) return 'B-';
        if (pct >= .77) return 'C+';
        if (pct >= .73) return 'C';
        if (pct >= .70) return 'C-';
        if (pct >= .67) return 'D+';
        if (pct >= .63) return 'D';
        if (pct >= .60) return 'D-';
        return 'F';
    }

    contractorAccTargetByMonth(monthNo: number, year: number) {
        let acc = 0;
        let accSum = 0;
        const targets = this.targets.filter(i => i.monthNo == monthNo && i.monthYear == year);
        if (targets) accSum = UtilitiesService.sum(targets, j => j.aCCTarget);
        if (accSum > 0) acc = accSum / targets.length;
        return Math.round(acc);
    }

    get totalContractorDispatchUsage() {
        if (!this.regions) return 0;
        return (this.totalContractorScheduled + this.totalContractorEnroute + this.totalContractorCompletion) / 3;
    }

    totalContractorClaimsByMonth(monthNo: number, year: number) {
        return UtilitiesService.sum(this.regions, i => i.totalClaimsByMonth(monthNo, year));
    }

    totalContractorCostsByMonth(monthNo: number, year: number) {
        return UtilitiesService.sum(this.regions, i => i.totalCostsByMonth(monthNo, year));
    }

    get contractorAccJobPct() {
        return this.totalContractorACC * this.contractorJobPct;
    }

    get contractorJobPct() {
        if (this.total_claims == 0) return 0;
        return this.totalContractorClaims / this.total_claims;
    }

    get totalContractorClaims() {
        if (!this.regions || this.regions.length === 0) return 0;
        return UtilitiesService.sum(this.regions, i => i.totalClaims);
    }

    get totalContractorCommits() {
        if (!this.regions || this.regions.length === 0) return 0;
        return UtilitiesService.sum(this.regions, i => i.totalCommits);
    }

    get totalContractorScheduled() {
        if (!this.regions || this.regions.length === 0 || this.totalContractorClaims == 0) return 0;
        return UtilitiesService.sum(this.regions, i => i.totalScheduled) / this.totalContractorClaims;
    }

    get totalContractorEnroute() {
        if (!this.regions || this.regions.length === 0 || this.totalContractorClaims == 0) return 0;
        return UtilitiesService.sum(this.regions, i => i.totalEnroute) / this.totalContractorClaims;
    }

    get totalContractorCompletes() {
        if (!this.regions || this.regions.length === 0 || this.totalContractorClaims == 0) return 0;
        return UtilitiesService.sum(this.regions, i => i.totalCompletes);
    }

    get totalContractorCompletion() {
        if (!this.regions || this.regions.length === 0 || this.totalContractorClaims == 0) return 0;
        return this.totalContractorCompletes / this.totalContractorClaims;
    }

    get totalContractorCosts() {
        if (!this.regions || this.regions.length === 0) return 0;
        return UtilitiesService.sum(this.regions, i => i.totalCosts);
    }

    get totalContractorACC() {
        if (!this.regions || this.regions.length === 0 || this.totalContractorClaims == 0) return 0;
        return this.totalContractorCosts / this.totalContractorClaims;
    }

    get totalContractorRating() {
        if (!this.regions || this.regions.length === 0) return 0;
        const ratingRegions = this.regions.filter(i => i.totalRating);
        return UtilitiesService.sum(ratingRegions, i => i.totalRatingSum) / UtilitiesService.sum(ratingRegions, i => i.totalRatingLength);
    }

    get totalContractorRecall() {
        if (!this.regions || this.regions.length === 0) return 0;
        const pct = UtilitiesService.sum(this.regions, i => i.regionRecallCount) / UtilitiesService.sum(this.regions, i => i.totalClaims);
        return pct;
    }


}

class RegionItem {
    id: string;
    name: string;
    total_claims = 0;
    items: any[];
    accDiff = 0;
    recallDiff = 0;
    surveyDiff = 0;
    areaTargets: ContractorTradeAreaTargetSummary[];

    itemsByMonth(monthNo: number, year: number) {
        if (!this.items) return null;
        const results = this.items.filter(i => i.monthNo - 1 == monthNo && i.monthYear == year);
        return results;
    }

    targetsByMonth(monthNo: number, year: number) {
        return this.areaTargets?.filter(i => i.month.getMonth() == monthNo && i.month.getFullYear() == year);
    }

    totalClaimsByMonth(monthNo: number, year: number) {
        if (!this.items || this.items.length === 0) return 0;
        return UtilitiesService.sum(this.itemsByMonth(monthNo, year), i => i.claims);
    }

    totalCostsByMonth(monthNo: number, year: number) {
        if (!this.items || this.items.length === 0) return 0;
        const items = this.itemsByMonth(monthNo, year);
        const total = UtilitiesService.sum(items, i => i.amount);
        return total;
    }

    get singleTrade() {
        if (!this.items || this.items.length === 0) return false;
        if ([...new Set(this.items.map(i => i.tradeId))].length == 1) return true;
        return false;
    }

    get areaAccTarget() {
        if (!this.areaTargets) return null;
        const acc = UtilitiesService.sum(this.areaTargets, i => i.aCCTarget) / this.areaTargets.length;
        return Math.round(acc);
    }

    get areaTarget() {
        if (!this.areaTargets) return null;
        return this.areaTargets[0];
    }

    get accJobPct() {
        return this.totalACC * this.jobPct;
    }

    get jobPct() {
        if (this.total_claims == 0) return 0;
        return this.totalClaims / this.total_claims;
    }

    get totalACC() {
        if (!this.items || this.items.length === 0 || this.totalClaims == 0) return 0;
        return this.totalCosts / this.totalClaims;
    }

    get totalClaims() {
        if (!this.items || this.items.length === 0) return 0;
        return UtilitiesService.sum(this.items, i => i.claims);
    }

    get totalCommits() {
        if (!this.items || this.items.length === 0) return 0;
        return UtilitiesService.sum(this.items, i => i.commits);
    }

    get totalScheduled() {
        if (!this.items || this.items.length === 0) return 0;
        const cnt = UtilitiesService.sum(this.items, i => i.scheduledCount);
        return (cnt > this.totalClaims ? this.totalClaims : cnt);
    }

    get totalScheduledRate() {
        if (!this.items || this.items.length === 0 || this.totalClaims == 0) return 0;
        return this.totalScheduled / this.totalClaims;
    }

    get totalEnroute() {
        if (!this.items || this.items.length === 0) return 0;
        const cnt = UtilitiesService.sum(this.items, i => i.enrouteCount);
        return (cnt > this.totalClaims ? this.totalClaims : cnt);
    }

    get totalEnrouteRate() {
        if (!this.items || this.items.length === 0 || this.totalClaims == 0) return 0;
        return this.totalEnroute / this.totalClaims;
    }

    get totalCompletes() {
        if (!this.items || this.items.length === 0 || this.totalClaims == 0) return 0;
        const cnt = UtilitiesService.sum(this.items, i => i.completedCount);
        return (cnt > this.totalClaims ? this.totalClaims : cnt);
    }

    get totalCompletion() {
        if (!this.items || this.items.length === 0 || this.totalClaims == 0) return 0;
        return this.totalCompletes / this.totalClaims;
    }

    get totalCosts() {
        if (!this.items || this.items.length === 0) return 0;
        return UtilitiesService.sum(this.items, i => i.amount);
    }

    get totalRating() {
        if (!this.items || this.items.length === 0) return 0;
        const ratings = this.items.filter(i => i.rating);
        if (!ratings || ratings.length == 0) return null;
        return UtilitiesService.sum(ratings, i => i.rating) / ratings.length;
    }

    get totalRatingSum() {
        if (!this.items || this.items.length === 0) return 0;
        const ratings = this.items.filter(i => i.rating);
        if (!ratings || ratings.length == 0) return null;
        return UtilitiesService.sum(ratings, i => i.rating);
    }

    get totalRatingLength() {
        if (!this.items || this.items.length === 0) return 0;
        const ratings = this.items.filter(i => i.rating);
        if (!ratings || ratings.length == 0) return 0;
        return ratings.length;
    }

    get totalRecall() {
        if (!this.items || this.items.length === 0) return 0;
        return UtilitiesService.sum(this.items, i => i.recallCount) / this.totalClaims;
    }

    get regionRecallCount() {
        if (!this.items || this.items.length === 0) return 0;
        return UtilitiesService.sum(this.items, i => i.recallCount);
    }
}
