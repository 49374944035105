
import { QuestionWizardComponentV2 } from '@cogent/client/shared/components/functions/question-wizard-v2/question-wizard-v2.component';
import { PaymentMethodEntryModule } from '@cogent/client/shared/components/accounting/payment-method-entry/payment-method-entry.module';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { PhoneLinkModule } from '@cogent/client/shared/components/misc/phone-link/phone-link.module';
import { StarRatingComponent } from '@cogent/client/shared/components/misc/star-rating/star-rating.component';
import { PlanOfferingsModule } from '@cogent/client/shared/components/plans-and-coverage/plan-offerings1/plan-offerings.module';
import { ConfirmJobContactInfoComponent } from '@cogent/client/shared/components/service/confirm-job-contact-info/confirm-job-contact-info.component';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebChatApiService } from '@cogent/client/shared/services/api/web-chat-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { AppointmentTimeSlot,  AuthorizationRepairItemSummary,  Brand, ConnectChatSession, Entity } from '@upkeeplabs/models/cogent';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ApiService } from '@cogent/client/api';
import { ExecutorInputParams, ExecutorOutputParams, FunctionCell, IQuestionRenderer, QuestionRenderInputArgs, QuestionRenderOutputArgs, StartChatArgs, typeMap } from '../../../../../../../node/logic/functions/function-runner.model';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
import { SearchChipSelectionV2Component } from '@cogent/client/shared/components/chip-selectors/search-chip-selection-v2/search-chip-selection-v2.component';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module';
import { TagChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/tag-chip-selection/tag-chip-selection.module';
import { JobItemSelectionComponent } from '@cogent/client/shared/components/service/job-item-selection/job-item-selection.component';
import { FindUserPolicesByPhoneOrEmailComponent } from '@cogent/client/shared/components/policies/find-user-polices-by-phone-or-email/find-user-polices-by-phone-or-email.component';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { PaymentMethodEntryComponent } from '../../accounting/payment-method-entry/payment-method-entry/payment-method-entry.component';
import { CreateAuthorizationLineFunction } from '@cogent/client/shared/logic/client-functions.angular.logic';
import { SelectWorkOrderItemTypeComponent } from '../../autho/select-work-order-item-type/select-work-order-item-type.component';
import { SelectRepairTypeComponent } from '../../autho/select-repair-type/select-repair-type.component';
class ChatMessage {
    displayName: string;
    message: string;
    internal: boolean;
    date: Date;
    attachments?: NoteAttachmentModel[];
    newMessage = false;
    entityId: string;
    informational = false;
    currentCell: FunctionCell;
    running = false;

    setupTokens() {
        this._messageTokens = this.messageTokens;
    }
    private _messageTokens: any[];
    get messageTokens(): any[] {

        if (!this._messageTokens && this.currentCell && !this.running) {
            this.message = UtilitiesService.replaceAll(this.message, '\\n', '\n');
            this.running = true;
            let index = 0;
            const ports = this.currentCell.ports.items.filter(i => i.group === 'transmissionOut').map(i => i?.attrs?.label?.text).filter(i => i && i != 'Representative');
            this._messageTokens = [];
            for (const port of ports) {
                const searchFor = `"${port}"`;
                const foundIndex = this.message.indexOf(searchFor, index);

                if (foundIndex > -1) {
                    const plainTextToken = this.message.substring(index, foundIndex);

                    this._messageTokens.push(plainTextToken);
                    let intentMessage = this.message.substring(foundIndex, foundIndex + searchFor.length);
                    intentMessage = UtilitiesService.replaceAll(intentMessage, '"', '');
                    intentMessage = UtilitiesService.replaceAll(intentMessage, '\'', '');
                    const intentToken = { message: intentMessage };
                    this._messageTokens.push('"');
                    this._messageTokens.push(intentToken);
                    this._messageTokens.push('"');
                    index = foundIndex + searchFor.length;
                }
            }
            const lastToken = this.message.substring(index, this.message.length);
            this._messageTokens.push(lastToken);



            let currentTokenIndex = 0;
            for (let token of this._messageTokens) {
                if (!token.message && !token.url) {


                    const docRegex = /\[doc[0-9]\]/gm;

                    if (token.replaceAll) {
                        token = token.replaceAll(docRegex, '');
                    }
                    this._messageTokens[currentTokenIndex] = token;

                }
                currentTokenIndex++;
            }
            for (let token of this._messageTokens) {
                if (!token.message && !token.url && !token.email && !token.tel) {
                    const subTokens = [];
                    let tokenIndex = this._messageTokens.indexOf(token);
                    index = 0;

                    let tokenString = token;
                    while (true) {
                        const urlEx = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
                        const match = urlEx.exec(tokenString);
                        if (!match) {
                            break;
                        }

                        index = 0;

                        let value = match['0'];

                        const foundIndex = match.index;
                        const plainTextToken = tokenString.substring(index, foundIndex);

                        subTokens.push(plainTextToken);
                        subTokens.push({ url: value });
                        index = foundIndex + value.length;
                        tokenString = tokenString.substring(index, tokenString.length);

                    }

                    subTokens.push(tokenString);

                    this._messageTokens.splice(tokenIndex, 1);
                    this._messageTokens = this._messageTokens.slice(0, tokenIndex).concat(subTokens).concat(this._messageTokens.slice(tokenIndex));
                }
            }
            for (let token of this._messageTokens) {
                if (!token.message && !token.url && !token.email && !token.tel) {
                    const subTokens = [];
                    let tokenIndex = this._messageTokens.indexOf(token);
                    index = 0;

                    let tokenString = token;
                    while (true) {
                        const urlEx = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;

                        const match = urlEx.exec(tokenString);
                        if (!match) {
                            break;
                        }

                        index = 0;

                        let value = match['0'];

                        const foundIndex = match.index;
                        const plainTextToken = tokenString.substring(index, foundIndex);

                        subTokens.push(plainTextToken);
                        subTokens.push({ email: value });
                        index = foundIndex + value.length;
                        tokenString = tokenString.substring(index, tokenString.length);

                    }

                    subTokens.push(tokenString);

                    this._messageTokens.splice(tokenIndex, 1);
                    this._messageTokens = this._messageTokens.slice(0, tokenIndex).concat(subTokens).concat(this._messageTokens.slice(tokenIndex));
                }
            }
            for (let token of this._messageTokens) {
                if (!token.message && !token.url && !token.email && !token.tel) {
                    const subTokens = [];
                    let tokenIndex = this._messageTokens.indexOf(token);
                    index = 0;

                    let tokenString = token;
                    while (true) {
                        const urlEx = /([+]?[0-9]{1,2}[\s.-]?)?(\([0-9]{3}\)|[0-9]{3})[\s.-]?[0-9]{3}[\s.-]?[0-9]{4}/g;

                        const match = urlEx.exec(tokenString);
                        if (!match) {
                            break;
                        }

                        index = 0;

                        let value = match['0'];

                        const foundIndex = match.index;
                        const plainTextToken = tokenString.substring(index, foundIndex);

                        subTokens.push(plainTextToken);
                        subTokens.push({ tel: value });
                        index = foundIndex + value.length;
                        tokenString = tokenString.substring(index, tokenString.length);

                    }

                    subTokens.push(tokenString);

                    this._messageTokens.splice(tokenIndex, 1);
                    this._messageTokens = this._messageTokens.slice(0, tokenIndex).concat(subTokens).concat(this._messageTokens.slice(tokenIndex));
                }
            }


        }

        return this._messageTokens;
    }
}

class ClickToken {
    message: string;
}

class QuestionGroupOrChatMessages {
    chatMessages: ChatMessage[];
    questions: QuestionRenderInputArgs[];
}

export class NoteAttachmentModel {
    constructor(public name: string = null, public noteId: string = null, public base64: string = null) { }

    get isImage() {
        return this.base64 && this.base64.indexOf('data:image/') > -1;
    }
}

@Component({
    selector: 'app-function-question-renderer-v2',
    standalone: true,
    imports: [CommonModule,
        FormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatSelectModule,
        MatButtonModule,
        CheckCircleAnimatedComponent,
        SwapableImageModule,
        SearchChipSelectionV2Component,
        PaymentMethodEntryModule,
        PaymentMethodEntryComponent,
        PhoneLinkModule,
        QuestionWizardComponentV2,
        FindUserPolicesByPhoneOrEmailComponent,
        DatePickerWrapperComponent,
        AddressEditorComponent,
        PlanOfferingsModule,
        ConfirmJobContactInfoComponent,
        TagChipSelectionModule,
        AppointmentSelectionComponent,
        JobItemSelectionComponent,
        UpkeepPipesModule,
        SelectWorkOrderItemTypeComponent,
        SelectRepairTypeComponent,
        StarRatingComponent,
        RichTextAndSnippetModule,
        MatSliderModule,
        ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatSlideToggleModule],
    templateUrl: './function-question-renderer-v2.component.html',
    styleUrl: './function-question-renderer-v2.component.css',
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FunctionQuestionRendererV2Component implements OnInit {

    renderer: AngularFunctionQuestionRenderer = new AngularFunctionQuestionRenderer();
    typeMap = typeMap;
    cachedSantizedUrls = {};
    @Input() wizardStyle: boolean;
    @Input() working: boolean;
    @Input() offset: boolean;
    @Input() fixedSize: boolean;
    @Input() height: string;
    @Input() iconClass: string;
    @Input() description: string;
    @Input() name: string;

    dragTargetActive = false;
    scollId = UtilitiesService.newid();
    private interval;

    selectedIndex = 0;

    constructor(private sanitizer: DomSanitizer,
        private missionService: MissionService,
        private webChatApi: WebChatApiService,
        private entityApi: EntityApiService,
        private ngZone: NgZone,
        private apiService: ApiService) { }

    ngOnInit(): void {

        this.renderer.entityApi = this.entityApi;
        this.renderer.webChatApi = this.webChatApi;
        this.missionService.userNotificationReceived.subscribe(message => {
            const anyMessage: any = message;
            if (anyMessage.data?.messageType === 'WebchatMessage') {
                message.handled = true;
                const newMessage = new ChatMessage();
                newMessage.date = new Date();
                newMessage.displayName = anyMessage.data.displayName;
                newMessage.internal = false;
                newMessage.message = anyMessage.data.message;
                newMessage.newMessage = true;
                if (this.renderer.currentConnectedAgent && newMessage.displayName !== 'SYSTEM_MESSAGE') {
                    newMessage.entityId = this.renderer.currentConnectedAgent.id;
                }

                this.ngZone.run(() => {
                    this.renderer.messages.push(newMessage);
                    this.renderer.scrollToBottom();
                });
                this.renderer.agentIsTyping = false;
                this.renderer.scrollToBottom();
                const audio = new Audio('https://uklprodstorage.blob.core.windows.net/doc-public/cdn/audio/small-ding.mp3');
                audio.play();

            } else if (anyMessage.data?.messageType === 'WebchatMessageEvent') {
                message.handled = true;
                if (anyMessage.data.message.ContentType === 'application/vnd.amazonaws.connect.event.typing') {

                    this.ngZone.run(() => {
                        this.renderer.agentIsTyping = true;
                        this.renderer.scrollToBottom();
                    });
                }
                if (anyMessage.data.message.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined') {
                    const contactId = anyMessage.data.message.ContactId;

                    if (contactId) {
                        this.webChatApi.getContactConnectedAgent(contactId).then(connectedAgent => {
                            this.renderer.currentConnectedAgent = connectedAgent;
                            for (const msg of this.renderer.messages) {
                                if (!msg.internal && msg.displayName !== 'SYSTEM_MESSAGE' && !msg.entityId) {
                                    msg.entityId = connectedAgent.id;
                                }
                            }
                        });
                    }

                }
                if (anyMessage.data.message.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
                    && anyMessage.data.message.ParticipantRole !== 'CUSTOMER') {
                    const newMessage = new ChatMessage();
                    newMessage.date = new Date();
                    newMessage.internal = false;
                    newMessage.message = `${anyMessage.data.message.DisplayName} has joined the conversation`;
                    newMessage.newMessage = true;
                    newMessage.informational = true;
                    this.ngZone.run(() => {
                        this.renderer.messages.push(newMessage);
                        this.renderer.scrollToBottom();
                    });
                }
                if (anyMessage.data.message.ContentType === 'application/vnd.amazonaws.connect.event.participant.left'
                    && anyMessage.data.message.ParticipantRole !== 'CUSTOMER') {
                    const newMessage = new ChatMessage();
                    newMessage.date = new Date();
                    newMessage.internal = false;
                    newMessage.message = `${anyMessage.data.message.DisplayName} has left the conversation`;
                    newMessage.newMessage = true;
                    newMessage.informational = true;
                    this.ngZone.run(() => {
                        this.renderer.messages.push(newMessage);
                        this.renderer.scrollToBottom();
                    });
                }
            }
        });
    }

    getAvatarUrl(chatMessage: ChatMessage) {
        if (chatMessage.displayName === 'SYSTEM_MESSAGE') {
            return 'https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg';
        }
        if (!chatMessage.entityId && chatMessage.displayName !== 'SYSTEM_MESSAGE') {
            return 'https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/elevate-support-agent.svg';
        }

        return `${ApiService.endPointNode}entity/photo/${chatMessage.entityId}`;
    }

    isConfirmationButtonDisabled(question, btn) {
        if(this.confirmWasReset) {
            btn.disabled = false;
        }
        return (this.working || !this.renderer.isLastQuestion(question)) && !this.confirmWasReset;
    }

    get calcHeight() {
        if (this.fixedSize && this.height) {
            return this.height;
        }

        if (this.fixedSize) {
            return '600px';
        }

        return 'unset';
    }

    resetConfirmButton() {
        console.log('reset confirmation button')
        this.confirmWasReset = true;
        
        this.ngZone.run(() => {
            this.renderer.scrollToBottom();

        });
    }

    handleKeyPress(keyEvent: KeyboardEvent) {
        if (keyEvent.key === 'Enter' && !keyEvent.shiftKey && this.renderer.newChatMessage) {
            keyEvent.preventDefault();
            this.renderer.sendChatMessage();
        }
    }

    private _brands: Brand[];
    private gettingBrands;
    get brands() {

        if (!this._brands && !this.gettingBrands) {
            this.gettingBrands = true;
            this.apiService.getArrayDotNet('Brand', { orderby: 'name' }).then(brands => this._brands = brands);
        }

        return this._brands;

    }

    confirmWasReset = false;
    confirmClick(question: QuestionRenderInputArgs, btn) {
        this.confirmWasReset = false;
        this.renderer.setAnswer(true, question);
        btn.disabled = true;
    }

    reset() {
        this.renderer = new AngularFunctionQuestionRenderer();
        this.renderer.entityApi = this.entityApi;
        this.renderer.webChatApi = this.webChatApi;
        this.renderer.scrollId = this.scollId;

        const thisItem = this;
        this.renderer.autoAdvanceStepSelected = () => {
            if (this.wizardStyle) {
                thisItem.selectedIndex++;
            }
        };
    }

    getSafeUrl(question: QuestionRenderInputArgs) {
        const url = question.inputs.inputs[0];
        if (!this.cachedSantizedUrls[url]) {
            this.cachedSantizedUrls[url] = this.getSanitizedUrl(url);
        }

        return this.cachedSantizedUrls[url];
    }

    getSanitizedUrl(url: string) {
        url = url.replace(/<\/?[^>]+(>|$)/g, '');
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    advance(question: QuestionRenderInputArgs) {
        this.renderer.clearCurrentTimeout();
        if (!question.answer) {
            question.answer = '';
            this.renderer.answerQuestion(question.answer, question);
        }
        this.selectedIndex = this.selectedIndex + 1;
    }

    drop(e) {
        if (!this.renderer.chatting) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();

        const dt = e.dataTransfer;
        const files = dt.files;
        this.handleFiles(files);
        this.dragTargetActive = false;
        this.ngZone.run(() => {
            this.renderer.scrollToBottom();

        });
    }

    dragEnter(e) {
        if (!this.renderer.chatting) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
    }

    dragover(e) {
        if (!this.renderer.chatting) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        this.dragTargetActive = true;
        clearInterval(this.interval);
    }

    dragleave(e) {
        if (!this.renderer.chatting) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        clearInterval(this.interval);
        this.interval = setTimeout(() => { this.dragTargetActive = false; }, 500);
    }

    removeAttachment(attachment) {
        this.renderer.attachments.splice(this.renderer.attachments.indexOf(attachment), 1);
    }

    showFileUpload() {
        document.getElementById('fileToUpload1').click();
    }
    handleFileUpload(evt) {
        this.handleFiles(evt);
    }

    handleFiles(files) {
        if (files.srcElement) {
            files = files.srcElement.files;
        }
        for (const file of files) {
            const reader = new FileReader();

            const loadIt = (base64, fileName) => {
                const attachment = new NoteAttachmentModel(fileName, null, base64);
                this.renderer.attachments.push(attachment);
            };

            reader.onload = ((fileName) => {
                return (e) => {
                    this.dragTargetActive = false;
                    loadIt(e.target.result, fileName);

                };
            })(file.name);

            reader.readAsDataURL(file);
        }
    }

}

export class AngularFunctionQuestionRenderer implements IQuestionRenderer {
    groups: QuestionGroupOrChatMessages[] = [];
    //questionsGroups: QuestionRenderInputArgs[][] = [];
    //questions: QuestionRenderInputArgs[] = [];
    currentPromise: Promise<QuestionRenderOutputArgs>;
    autoAdvanceStepSelected: () => void;
    chatting = false;
    newChatMessage = '';
    sendingChatMessage = false;
    //messages: ChatMessage[] = [];
    entityApi: EntityApiService;
    webChatApi: WebChatApiService;
    chatStep: ExecutorInputParams;
    scrollId: string;
    contactFlowId: string;
    attachments: NoteAttachmentModel[] = [];
    currentSession: ConnectChatSession;
    currentFindPolicyStep: string = 'request-code';
    state = {};
    isAIChat: boolean;
    aiMessages: any[];
    agentIsTyping = false;

    currentConnectedAgent: Entity;

    isLastGroup(group: QuestionGroupOrChatMessages) {
        return this.groups.indexOf(group) === this.groups.length - 1;
    }


    clearCurrentTimeout() {
        if (!this.isDelayedAnswer) {
            clearTimeout(this.timeout);
        }
    }

    isFullWidthQuestion(question: QuestionRenderInputArgs) {
        return question.functionCell.type === typeMap.planSelection;
    }

    get messages(): ChatMessage[] {
        let lastGroup = this.groups[this.groups.length - 1];
        if (!lastGroup?.chatMessages) {
            lastGroup = null;
        }


        if (!lastGroup) {
            lastGroup = new QuestionGroupOrChatMessages();
            lastGroup.chatMessages = [];
            this.groups.push(lastGroup);
        }

        return lastGroup.chatMessages;
    }

    isQuestionInLastGroup(question: QuestionRenderInputArgs) {
        if (!this.groups || this.groups.length === 0) {
            return false;
        }

        return this.groups[this.groups.length - 1].questions?.indexOf(question) > -1;
    }

    get questions(): QuestionRenderInputArgs[] {
        let lastGroup = this.groups[this.groups.length - 1];
        if (!lastGroup?.questions) {
            lastGroup = null;
        }


        if (!lastGroup) {
            lastGroup = new QuestionGroupOrChatMessages();
            lastGroup.questions = [];
            this.groups.push(lastGroup);
        }

        return lastGroup.questions;
    }
    set questions(value) {
        let lastGroup = this.groups[this.groups.length - 1];
        if (!lastGroup.questions) {
            lastGroup = null;
        }


        if (!lastGroup) {
            lastGroup = new QuestionGroupOrChatMessages();
            lastGroup.questions = [];
            this.groups.push(lastGroup);
        }
        lastGroup.questions = value;
    }

    isLastQuestion(question: QuestionRenderInputArgs) {
        const lastGroup = this.groups[this.groups.length - 1];
        if (!lastGroup || !lastGroup.questions) {
            return false;
        }


        return lastGroup.questions[lastGroup.questions?.length - 1] === question;
    }

    aiIntentPrompt: string;

    async startChat(args: StartChatArgs) {
        const inputParams = args.inputParams;
        const contactFlowId = args.contactFlowId;
        const autStart = args.inputParams.inputs[8];

        this.chatting = true;
        this.chatStep = inputParams;
        this.contactFlowId = contactFlowId;
        this.isAIChat = args.isAI;
        this.aiMessages = args.aiMessages;
        this.aiIntentPrompt = args.intentPrompt;


        const sessionJson = localStorage.getItem('last-chat-session');
        if (sessionJson) {
            try {
                const session = JSON.parse(sessionJson);
                session.connectionCredentialsExpiration = new Date(session.connectionCredentialsExpiration);

                if (session?.connectionCredentialsToken && session.connectionCredentialsExpiration > new Date()) {
                    this.webChatApi.getChatTranscript(session.connectionCredentialsToken).then(async transcript => {
                        for (const item of transcript.Transcript) {
                            if (item.Type === 'MESSAGE') {
                                const msg = new ChatMessage();
                                msg.date = item.AbsoluteTime;
                                msg.displayName = item.DisplayName;
                                msg.internal = item.ParticipantRole === 'CUSTOMER';
                                msg.message = item.Content;

                                this.messages.unshift(msg);
                            }
                        }
                        this.scrollToBottom();
                        if (transcript.InitialContactId) {
                            const agent = await this.webChatApi.getContactConnectedAgent(transcript.InitialContactId);
                            if (agent) {
                                this.currentConnectedAgent = agent;
                                for (const msg of this.messages.filter(i => !i.internal && i.displayName !== 'SYSTEM_MESSAGE')) {
                                    msg.entityId = agent.id;
                                }
                            }
                        }
                    });
                }
            } catch { }
        }

        if (!this.isAIChat && autStart) {
            const entity = await this.entityApi.getLoggedInUser(false, true);
            const session = await this.webChatApi.sendMessage(entity.id, 'start-chat', this.contactFlowId, this.attachments);
            this.currentSession = session;
        }
    }

    scrollToBottom() {

        setTimeout(() => {
            if (this.scrollId) {
                const el = document.getElementById(this.scrollId);
                if (el) {
                    el.scrollTo({
                        behavior: 'smooth',
                        left: 0,
                        top: el.scrollHeight,
                    });
                }
            }
        }, 200);

        setTimeout(() => {
            if (this.scrollId) {
                const el = document.getElementById(this.scrollId);
                if (el) {
                    el.scrollTo({
                        behavior: 'smooth',
                        left: 0,
                        top: el.scrollHeight,
                    });
                }
            }
        }, 1000);
    }

    questionAnswered() {
        this.scrollToBottom();
    }

    endingChat = false;

    tokenClicked(token: ClickToken, e: MouseEvent, group: QuestionGroupOrChatMessages) {

        e.preventDefault();
        if (!this.isLastGroup(group)) {
            return;
        }

        this.newChatMessage = UtilitiesService.replaceAll(token.message, '"', '');
        this.sendChatMessage();
    }

    async sendChatMessage() {
        this.sendingChatMessage = true;

        const entity = await this.entityApi.getLoggedInUser(false, true);
        if (!this.isAIChat) {

            const session = await this.webChatApi.sendMessage(entity.id, this.newChatMessage, this.contactFlowId, this.attachments);
            this.currentSession = session;
            localStorage.setItem('last-chat-session', JSON.stringify(session));
            const newMessage = new ChatMessage();
            newMessage.displayName = entity.name;
            newMessage.internal = true;
            newMessage.message = this.newChatMessage;
            newMessage.date = new Date();
            newMessage.attachments = this.attachments;
            newMessage.newMessage = true;

            this.messages.push(newMessage);

            this.newChatMessage = '';
            this.sendingChatMessage = false;
            this.attachments = [];

            this.scrollToBottom();
        } else {
            const newMessage = new ChatMessage();
            newMessage.displayName = entity.name;
            newMessage.internal = true;
            newMessage.message = this.newChatMessage;
            newMessage.date = new Date();
            newMessage.attachments = this.attachments;
            newMessage.newMessage = true;
            this.messages.push(newMessage);
            this.scrollToBottom();

            if (this.chatStep.inputs[1] && this.newChatMessage?.toLowerCase() === this.chatStep.inputs[1]?.toLowerCase()) {
                const intentResult = new ExecutorOutputParams();
                intentResult.result = 'Representative';
                this.chatting = false;
                this.newChatMessage = '';
                this.sendingChatMessage = false;
                this.chatStep.currentRunner.processExecutedOutput(this.chatStep.currentCell, intentResult);
                return;
            }
            const outPorts = this.chatStep.currentCell.ports.items.filter(i => i.group === 'transmissionOut').map(i => i?.attrs?.label?.text);
            for (const outPort of outPorts) {
                if (outPort && this.newChatMessage && this.newChatMessage.toLowerCase() === outPort.toLowerCase()) {
                    this.chatting = false;
                    const intentResult = new ExecutorOutputParams();
                    intentResult.result = outPort;
                    setTimeout(() => {
                        this.agentIsTyping = true;
                        setTimeout(() => {
                            this.chatStep.currentRunner.processExecutedOutput(this.chatStep.currentCell, intentResult);
                            this.agentIsTyping = false;
                        }, 1500);

                    }, 1500);
                    return;
                }
            }
            this.aiMessages.push({
                role: 'user', content: this.newChatMessage
            });

            this.agentIsTyping = true;

            if (this.aiIntentPrompt) {
                const intentMessages = [
                    {
                        role: 'system',
                        content: this.aiIntentPrompt
                    },
                    {
                        role: 'user',
                        content: this.newChatMessage,
                    }
                ];

                const intentChatResult = await this.webChatApi.sendAIMessage(intentMessages);
                const answer = intentChatResult[0];
                const outPorts = this.chatStep.currentCell.ports.items.filter(i => i.group === 'transmissionOut').map(i => i?.attrs?.label?.text);

                const messsageContent = answer?.message?.content?.toLowerCase();
                for (const outPort of outPorts) {
                    if (outPort && messsageContent === outPort.toLowerCase()) {
                        this.chatting = false;
                        const intentResult = new ExecutorOutputParams();
                        intentResult.result = outPort;
                        this.newChatMessage = '';
                        this.sendingChatMessage = false;

                        setTimeout(() => {
                            this.agentIsTyping = true;
                            setTimeout(() => {
                                this.chatStep.currentRunner.processExecutedOutput(this.chatStep.currentCell, intentResult);
                                this.agentIsTyping = false;
                            }, 1500);

                        }, 1500);
                        return;
                    }
                }

            }

            this.newChatMessage = '';
            this.sendingChatMessage = false;
            const result = await this.webChatApi.sendAIMessage(this.aiMessages);

            setTimeout(() => {
                this.agentIsTyping = false;
                if (result?.length > 0) {
                    const firstResult = result[0];

                    if (firstResult.messages) {
                        const message = firstResult.messages.find(i => i.role === 'assistant');
                        for (const m of firstResult.messages) {
                            this.aiMessages.push(m);
                        }

                        const responseMessage = new ChatMessage();
                        responseMessage.displayName = 'Elevate';
                        responseMessage.date = new Date();
                        responseMessage.message = message.content;
                        responseMessage.newMessage = true;
                        responseMessage.entityId = 'bot';
                        responseMessage.currentCell = this.chatStep.currentCell;
                        responseMessage.setupTokens();
                        this.messages.push(responseMessage);
                    } else {
                        // This should be deprecated, but I'm leaving it while it is in preview
                        this.aiMessages.push(firstResult.message);

                        const responseMessage = new ChatMessage();
                        responseMessage.displayName = 'Elevate';
                        responseMessage.date = new Date();
                        responseMessage.message = firstResult.message.content;
                        responseMessage.newMessage = true;
                        responseMessage.entityId = 'bot';
                        responseMessage.currentCell = this.chatStep.currentCell;
                        this.messages.push(responseMessage);
                    }

                    this.scrollToBottom();
                    const audio = new Audio('https://uklprodstorage.blob.core.windows.net/doc-public/cdn/audio/small-ding.mp3');
                    audio.play();

                }
            }, 700);

        }

    }

    async endChat() {
        this.endingChat = true;
        if (this.currentSession) {
            await this.webChatApi.endChat(this.currentSession.contactId);
        }
        this.chatting = false;
        const output = new ExecutorOutputParams();
        output.result = true;
        this.chatStep.currentRunner.processExecutedOutput(this.chatStep.currentCell, output);
        localStorage.removeItem('last-chat-session');
        this.endingChat = false;
    }

    renderQuestion(args: QuestionRenderInputArgs): void {
        this.questions.push(args);

        const autoAdvanceTypes = [
            typeMap.showMessage,
            typeMap.showImage,
            typeMap.youTube,
            typeMap.checkCircleAnimated,
            typeMap.lottieFile,
        ];
        const instantAdvanceTypes = [
            typeMap.heading,
            typeMap.separator
        ];

        if (args.functionCell.type === typeMap.showImage && args.inputs.inputs[1]) {
            autoAdvanceTypes.splice(autoAdvanceTypes.indexOf(typeMap.showImage), 1);
            instantAdvanceTypes.push(typeMap.showImage);
        }
        if (args.functionCell.type == typeMap.lottieFile && args.inputs.inputs[4]) {
            autoAdvanceTypes.splice(autoAdvanceTypes.indexOf(typeMap.lottieFile), 1);
            instantAdvanceTypes.push(typeMap.lottieFile);
        }
        const doManualAutoAdvance = args.functionCell.type === typeMap.hyperlink && !args.inputs.inputs[2];

        if (autoAdvanceTypes.indexOf(args.functionCell.type) > -1 || doManualAutoAdvance) {
            this.isDelayedAnswer = false;
            this.timeout = setTimeout(() => {
                this.answerQuestion(true, args);
                args.answer = true;
                if (this.autoAdvanceStepSelected) {
                    this.autoAdvanceStepSelected();
                }

            }, 3000);
        }



        if (instantAdvanceTypes.indexOf(args.functionCell.type) > -1) {
            this.answerQuestion(true, args);
        }
        this.scrollToBottom();
    }

    getAnswers(question: QuestionRenderInputArgs) {
        return question.functionCell.ports.items.filter(i => i.group === 'transmissionOut' && i.attrs?.label?.text !== '_Default').map(i => i.attrs?.label?.text);
    }



    cachedSelectableAnswers = {};
    getSelectableAnswers(question: QuestionRenderInputArgs) {
        if (!this.cachedSelectableAnswers[question.functionCell.id]) {
            const selectableAnswers = question.functionCell.custom.answers.map(i => ({ answer: i, selected: false }));
            this.cachedSelectableAnswers[question.functionCell.id] = selectableAnswers;
        }

        return this.cachedSelectableAnswers[question.functionCell.id];
    }

    getAuthorizationItemSelectionAnswers(question: QuestionRenderInputArgs) {
        if (!this.cachedSelectableAnswers[question.functionCell.id]) {
            const authorizationRepairItems: AuthorizationRepairItemSummary[] = question.inputs.inputs[1];
            const selectableAnswers = authorizationRepairItems.map(i => ({ id: i.id, name: i.name, selected: false, coveredType: i.coveredType }));
            this.cachedSelectableAnswers[question.functionCell.id] = selectableAnswers;
        }

        return this.cachedSelectableAnswers[question.functionCell.id];
    }

    getShouldHideNextButton(question: QuestionRenderInputArgs) {
        return question.functionCell.type === typeMap.confirmButton;
    }

    async completeAuthoItemSelection(question: QuestionRenderInputArgs) {
        question.saving = true;
        const selectedAnswers = this.cachedSelectableAnswers[question.functionCell.id].filter(i => i.selected);
        const addClass = new CreateAuthorizationLineFunction();

        const params = new ExecutorInputParams();
        params.currentCell = question.inputs.currentCell;
        params.currentRunner =  question.inputs.currentRunner;
        params.dependencies = question.inputs.dependencies;
        params.objectInScope = question.inputs.objectInScope;
        params.inputs = [undefined, selectedAnswers];
        const result = await addClass.executeStep(params)
        question.saving = false;

        this.answerQuestion(result.result, question);
    }

    answerQuestion(value, question: QuestionRenderInputArgs, ignoreNull = false) {
        if (!value && ignoreNull) {
            const questionIndex = this.questions.indexOf(question);
            if (questionIndex < this.questions.length - 1) {
                clearTimeout(this.timeout);
                this.questions = this.questions.slice(0, questionIndex + 1);
            }
            return;
        }
        this.chatting = false;
        const questionIndex = this.questions.indexOf(question);
        if (questionIndex < this.questions.length - 1) {
            clearTimeout(this.timeout);
            this.questions = this.questions.slice(0, questionIndex + 1);
        }

        const output = new ExecutorOutputParams();
        output.result = value;

        question.inputs.currentRunner.processExecutedOutput(question.functionCell, output);
        this.scrollToBottom();
        question.answer = value;
    }

    answerTimeslotQuestion(value, question: QuestionRenderInputArgs, ignoreNull = false) {
        for (const i of value) {
            const ts: AppointmentTimeSlot = i;
            ts.summary2 = ts.summary;
        }
        this.answerQuestion(value, question, ignoreNull);
    }

    editQuestion(question: QuestionRenderInputArgs) {
        const questionIndex = this.questions.indexOf(question);
        if (questionIndex < this.questions.length - 1) {
            clearTimeout(this.timeout);
            this.questions = this.questions.slice(0, questionIndex + 1);
        }
    }

    answerUploadImageQuestion(value, question: QuestionRenderInputArgs) {
        const attachment = new NoteAttachmentModel();
        attachment.base64 = value;
        attachment.name = 'photo.jpg';
        if (value.indexOf('image/webp') > -1) {
            attachment.name = 'photo.webp';
        } else if (value.indexOf('image/png')) {
            attachment.name = 'photo.png';
        }

        this.answerQuestion([attachment], question);
    }

    setAnswer(answer, question: QuestionRenderInputArgs) {
        question.answer = answer;
        this.answerQuestion(answer, question);

    }

    updateListAnswers(question: QuestionRenderInputArgs) {
        const selectedAnswers = this.cachedSelectableAnswers[question.functionCell.id].filter(i => i.selected).map(i => i.answer);
        question.answer = selectedAnswers;
        clearTimeout(this.timeout);
        this.isDelayedAnswer = true;

        if (!question.inputs.inputs[1]) {
            this.timeout = setTimeout(() => this.answerQuestion(selectedAnswers, question), 1000);
        }

        //this.answerQuestion(selectedAnswers, question);
    }

    completeListAnswers(question: QuestionRenderInputArgs) {
        const selectedAnswers = this.cachedSelectableAnswers[question.functionCell.id].filter(i => i.selected).map(i => i.answer);
        question.answer = selectedAnswers;
        this.answerQuestion(selectedAnswers, question);
    }

    isDelayedAnswer = false;
    timeout: any;

    answerQuestionDelayed(value, question: QuestionRenderInputArgs, ignoreNull = false) {
        if (!value && ignoreNull) {
            const questionIndex = this.questions.indexOf(question);
            if (questionIndex < this.questions.length - 1) {
                clearTimeout(this.timeout);
                this.questions = this.questions.slice(0, questionIndex + 1);
            }
            return;
        }
        clearTimeout(this.timeout);
        this.isDelayedAnswer = true;
        this.timeout = setTimeout(() => {
            this.answerQuestion(value, question);
        }, 1000);
    }

    showHyperlink(question: QuestionRenderInputArgs) {
        window.open(question.inputs.inputs[0]);
        if (question.inputs.inputs[2]) {
            this.answerQuestion('', question);
        }
    }

    isTimeslotsFilledout(question: QuestionRenderInputArgs) {
        const length = question.inputs.inputs[3] ? 2 : 3;
        const value: AppointmentTimeSlot[] = this.state[question.functionCell.id];
        if (value && value.length === length && value.filter(i => i && i.date && i.startTime).length === length) {
            return true;
        }

        return false;
    }

    answerTimeslot(value, question: QuestionRenderInputArgs) {

        if (value?.length === question.inputs.inputs[3] ? 2 : 3) {
            this.answerQuestionDelayed(value, question);
        }
    }



    getItemUrl(item) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${item.id}/photo`;
    }

    getPropertyThumbnail(property) {
        return `${ApiService.endPointDotNet}Address/${property.addressId}/StreetView`;
    }

    getLabel(question: QuestionRenderInputArgs, defaultText = '') {

        const labelPort = question.functionCell.ports?.items?.find(i => i.group === 'in' && i.attrs?.label?.text === 'Label');
        let inputIndex = -1;
        if (labelPort) {
            inputIndex = question.functionCell.ports.items.filter(i => i.group === 'in').indexOf(labelPort);
        }
        let label = question.inputs.inputs[inputIndex];
        if (!label) {
            label = question.functionCell.attrs?.label?.text;
        }

        if(!label && defaultText) {
            label = defaultText;
        } 

        return label;
    }

    getImageUrl(question: QuestionRenderInputArgs) {
        return question.inputs.inputs[0];
    }

    getImageHeight(question: QuestionRenderInputArgs) {
        let height = question.inputs.inputs[2];

        if (!height) {
            height = 'unset';
        }

        return height;
    }

    getCircleHeight(question: QuestionRenderInputArgs) {
        let height = question.inputs.inputs[1];
        if (!height) {
            height = '200px';
        }
        return height;
    }

    getInput(question: QuestionRenderInputArgs, index: number) {
        return question.inputs.inputs[index];
    }
}

// class SelectableAnswer {
//     answer: string;
//     selected: boolean;
// }
