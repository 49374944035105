import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';



@Component({
    templateUrl: './view-coverage.component.html',
    styleUrls: ['./view-coverage.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
      ]
})
export class ViewCoverageDialogComponent {
    planDetail: any;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private dataAccess: DataApiService
    ) {
        if (typeof data === "string") {
            this.loading = true;
            this.dataAccess.getPlanItemDetail(data).then(detail => {
                this.planDetail = detail;
                this.loading = false;
            });
        } else {
            this.planDetail = { coverage: data };
        }
    }
}
