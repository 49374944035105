import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    templateUrl: './circle-widget.component.html',
    selector: 'app-circle-widget',
    imports: [CommonModule],
    standalone: true,
})
export class CircleWidgetComponent {
    @Input() percent: number;
    @Input() label: string;
    @Input() number: string;
    @Input() showNumber = true;
    @Input() strokeWidth = 7;
    @Input() strokeCircleWidth = 5;
    @Input() strokeColor = '#58bf74';
    circleConstant = 626.185;

    get archCircumferenceBound() {
        return this.circleConstant - ((360 / 100) * this.percent * (this.circleConstant / 360));
    }
}
 