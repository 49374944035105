<svg id="Layer_1" data-name="Layer 1" [style.height]="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424 424">
    <defs>
        <style>

        </style>
    </defs>
    <g id="circle-outline">
        <g>
            <circle class="circle-outline" cx="212" cy="212" r="202" />
        </g>
    </g>
    <g id="circle">
        <circle class="circle-fill" cx="212" cy="212" r="202" />
    </g>
    <g id="shadow">
        <path class="cls-2-1"
            d="M412.42871,186.72449,305.57465,96.44873l-4.34882,5.1474a73.93486,73.93486,0,1,1-95.40216,112.922L95.715,344.84729l77.4107,65.40045A202.97166,202.97166,0,0,0,212,414c111.56152,0,202-90.43848,202-202A203.96192,203.96192,0,0,0,412.42871,186.72449Z"
            transform="translate(6 6)" />
    </g>
    <g id="check">
        <path class="cls-3"
            d="M311.55989,203.518c28.25635-33.25489,24.49394-82.87349-8.40362-110.82622s-82.47257-23.65444-110.72893,9.60045c-26.91258,31.6734-24.77854,78.19045,3.9089,106.69463l-8.56816,10.08386-3.09924-2.63339a7.3606,7.3606,0,0,0-10.37024.89912L83.25809,324.482a7.36061,7.36061,0,0,0,.787,10.37935l15.49621,13.167a7.36056,7.36056,0,0,0,10.37024-.89912l91.0405-107.14551a7.36057,7.36057,0,0,0-.787-10.37935l-3.09924-2.6334,8.56813-10.08382C238.396,240.59592,284.6473,235.19145,311.55989,203.518ZM202.56068,110.90247c23.44941-27.5976,64.59084-31.16465,91.89186-7.96723s30.42339,64.375,6.974,91.97258-64.5908,31.16469-91.89186,7.96724S179.11123,138.50011,202.56068,110.90247Z"
            transform="translate(6 6)" />
    </g>
</svg>