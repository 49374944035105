import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";
@dataSource()
export class Invoice {
    @keyColumn() id: string;
    @column() customerId: string;
    @column() policyId: string;
    @column() amount: number;
    @column() amountDue: number;
    @column() voidDate: Date;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() dueDate: Date;
    @column() vendorId: string;
    @column() memo: string;
    @readOnlyColumn() invoiceNumber: bigint;
    @column() invoiceType: string;
    @column() claimId: string;
    @column() scheduledDate: Date;
    @column() scheduledCardId: string;
    @column() exportDate: Date;
    @column() voidExportDate: Date;
    @column() quickBooksJournalId: string;
    @column() quickBooksVoidedJournalId: string;
    @column() discountAmount: number;
    @column() isProratedInvoice: boolean;
    @column() lastAutoPaymentAttempt: Date;
    @column() autoPaymentAttempts: number;
    @column() payerId: string;
    @column() invoiceGroupdId: string;
    @column() workOrderLineId: string;

    // client only
    newScheduledDate?: Date;
    isNew = false;
}