import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';

@Injectable({ providedIn: 'root' })
export class WebsocketApiService {
    constructor(private api: ApiService, private http: HttpClient) { }
    get websocketEndpoint() {


        return ApiService.endPointWebSocket;
        // return ApiService.endPoint.replace("https:", "wss:") + "ws";
        //return ApiService.endPointNode.replace("https:", "wss:").replace("http:", "ws:") + "ws";
        //return "ws://localhost:3000/websocket/notification";

    }

    private endpoints: any;
    async getEndpointAsync() {
        if (!this.endpoints) {
            try {
                const result = await this.http.get('api-base-urls').toPromise();
                this.endpoints = result;
            } catch {
                this.endpoints = {};
            }
        }
        return this.endpoints;
    }
}