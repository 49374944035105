import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class CustomFunctionTableChangeTrigger {
    @keyColumn() id: string;
    @column() customFunctionId: string;
    @column() name: string;
    @column() tableName: string;
    @column() onInsert: boolean;
    @column() onUpdate: boolean;
    @column() onDelete: boolean;
}
