import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { FavorFundExpenseSummary } from "./favor-fund-expense-summary.model";

@dataSource()
export class FavorFundWeightItemSummary {
    @keyColumn() id: string;
    @column() month: number;
    @column() weight: number;
    @column() favorFundWeightId: string;
    @column() createdById: string;
    @column() createdDate: Date;
    @column() deletedDate: Date;
    @column() monthName: string;
    @column() totalUsed: number;
    @column() monthNameLong: string;

    startMonth: number;

    yearlyBudget = 0;
    amountUsed = 0;
    monthlyBudget = 0;
    totalLeft = 0;

    backgroundColor = 'transparent';
    monthExpenses: FavorFundExpenseSummary[] = [];

    // get monthlyBudget() {
    //     const total = Math.round((this.weight / 100) * this.yearlyBudget);
    //     return total;
    // }

    // get totalLeft() {
    //     return this.monthlyBudget - this.amountUsed;
    // }

    get fontWeight() {
        if (this.month >= this.startMonth) return "bold"; return "no-bold";
    }
    get highlightColor() {
        if (this.month >= this.startMonth) return "highlighted"; return "no-highlight";
    }
    get leftoverColor() {
        if (this.month >= this.startMonth) return "leftover-color"; return "";
    }
    get usedColor() {
        if (this.month >= this.startMonth) return "used-color"; return "";
    }
}