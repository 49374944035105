import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ClosedWorkOrdersSummary {
    @keyColumn() id: string;
    @column() number: number;
    @column() name: string;
    @column() statusDate?: Date;
    @column() type: string;
    @column() contractorName: string;

    // Client side Only

}