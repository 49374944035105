import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { AuthorizationReviewTrigger } from "./authorization-review-trigger.model";
import { WorkOrderLineSummary } from "./work-order-line-summary.model";
import { WorkOrderItemType } from "./work-order-item-type.model";
import { RepairType } from "./repair-type.model";
import { SalesItemCoverageWorkOrderItem } from "./sales-item-coverage-work-order-item.model";

@dataSource()
export class WorkOrderLineAuthorizationRepairItem {
    @column() workOrderLineId: string;
    @keyColumn() id: string;
    @column() workOrderItemId: string;
    @column() name: string;
    //@column() priceRangeMin: number;
    @column() priceRangeMax: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() deletedDate: Date;
    @column() companyNeverProvides: boolean;
    @column() salesItemCoverageAuthorizationRepairItemId: string;
    @column() limitPerClaim?: number;
    @column() limitPerUnit?: number;
    @column() allowQuantityEntry?: boolean;
    @column() attributesDefinition?: string;
    @column() aliases: string;
    @column() companyPrefersToProvide: boolean;
    @column() authorizationRepairItemGroupId: string;
    @column() priceRangeDeltaPct: number;
    @column() contractorCost: number;
    @column() workOrderItemTypes: string;
    @column() restrictByWorkOrderItemTypes: boolean;
    @column() hideFromManualSelection: boolean;

    selectedAttributes: any[];
    private _aliasList: string[];

    get priceRangeMin() {
        return this.priceRangeMax - (this.priceRangeMax * this.priceRangeDeltaPct);
    }

    get aliasList(): string[] {
        if (!this._aliasList) {
            if (this.aliases) {
                try {
                    this._aliasList = JSON.parse(this.aliases);
                } catch {
                    this._aliasList = [];
                }
            } else {
                this._aliasList = [];
            }
        }

        return this._aliasList;
    }

}

export class AuthorizationInputArgs {
    costLines: CostLine[] = [];
    workOrderLineId: string;
    authorizationWarnings: AuthorizationReviewTrigger[];
    laborRate: number;
    tripCharge: number;
    planLimit?: number;
    firstPolicyEffectiveDate?: Date;
    workOrderLine: WorkOrderLineSummary;
    // workOrderItemTypeId: string;
    workOrderItemType: WorkOrderItemType;
    repairType: RepairType;
    salesItemCoverageWorkOrderItem: SalesItemCoverageWorkOrderItem;

    get askPreExistingQuestion() {
        
        //return true;
        if (!this.firstPolicyEffectiveDate) {
            return true;
        }


        if (AuthorizationInputArgs.dateDiff().inDays(this.firstPolicyEffectiveDate, new Date()) < 60) {
            return true;
        }

        return false;
    }

    public static dateDiff() {
        return {

            inDays: (d1: Date, d2: Date) => {
                var t2 = d2.getTime();
                var t1 = d1.getTime();

                return Math.floor((t2 - t1) / (24 * 3600 * 1000));
            },

            // inWeeks: function(d1, d2) {
            //     var t2 = d2.getTime();
            //     var t1 = d1.getTime();

            //     return parseInt((t2-t1)/(24*3600*1000*7));
            // },

            inMonths: (d1: Date, d2: Date) => {
                var d1Y = d1.getFullYear();
                var d2Y = d2.getFullYear();
                var d1M = d1.getMonth();
                var d2M = d2.getMonth();

                return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
            },

            inYears: (d1: Date, d2: Date) => {
                return d2.getFullYear() - d1.getFullYear();
            }
        };

    }
}


export class CostLine {
    description: string;
    amount: number;
    repairItemId: string;
    id: string;
    authorizationRepairItemSelector?: WorkOrderLineAuthorizationRepairItem;
    qty: number;
    companyProvidedAvailable = true;
    companyProvidesPart = false;
    defaultItem = false;
    partNumber: string;
    disputeCoverage = false;
    disputeReason: string;
    forIncompatibility = false;
    allowQuantityEntry = true;
    automaticallyAdded? = false;
    addedByStepId?: string;
    calcLaborAsHourlyRate?: boolean;
    selectedAttributes: any[];
    authorizationRepairItemGroupId: string;

    constructor() {
        this.amount = 0;
        this.qty = 1;
    }

    get isQuestion() {
        return !this.authorizationRepairItemSelector;
    }

    get ext() {
        return this.qty * this.amount;
    }

    get isCovered() {
        return this.authorizationRepairItemSelector?.salesItemCoverageAuthorizationRepairItemId;
    }

    get notCovered() {
        return this.authorizationRepairItemSelector && !this.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId;
    }

    get isRed() {
        // if (!this.authorizationRepairItemSelector?.priceRangeMin) {
        //     return false;
        // }

        return this.pct > 70;
    }

    get isGreen() {
        // if (!this.authorizationRepairItemSelector?.priceRangeMin) {
        //     return false;
        // }
        if (this.pct < 40) {
            return true;
        }

        return false;
    }

    get isYellow() {
        // if (!this.authorizationRepairItemSelector?.priceRangeMin) {
        //     return false;
        // }

        if (this.pct >= 40 && this.pct < 70) {
            return true;
        }

        return false;
    }

    get pct() {
        if (!this.authorizationRepairItemSelector?.priceRangeMax) {
            return 0;
        }

        let maxValue = this.authorizationRepairItemSelector.priceRangeMax;
        let minValue = this.authorizationRepairItemSelector.priceRangeMax - (this.authorizationRepairItemSelector.priceRangeDeltaPct * this.authorizationRepairItemSelector.priceRangeMax);
        let value = this.amount;
        if (value > maxValue) {
            value = maxValue;
        }
        if (value < minValue) {
            value = minValue;
        }

        let range = maxValue - minValue;
        value = value - minValue;

        if (range === 0) {
            return 0;
        }

        return (value / range) * 100;
    }

    get indicatorPercent() {
        return this.pct.toString() + '%';
    }

}