import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Iteration {
    @keyColumn() id: string;
    @column() iterationDate?: Date;
    @column() employeeId: string;
    @column() totalCount: number;
    @column() completedCount: number;
    @column() newSkills: number;
    @column() bugCount: number;
    @column() wigCount: number;
    @column() wigCompleted: number;
    @column() completedDate?: Date;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedById?: string;
    @column() lastModifiedDate?: Date;
    @column() wigCompletedDate?: Date;
}