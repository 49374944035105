import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SmsComposeArgs } from '@cogent/shared/models/communication/sms-compose-args.model';
import { EmailApiService } from '@cogent/client/shared/services/api/email-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

class Recipient {
    constructor(public phoneNumber: string) {}
}

@Component({
    selector: 'app-sms-compose-modal',
    standalone: true,
    imports: [
        CommonModule,
        DragDropModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
      ],
    templateUrl: './sms-compose-modal.component.html',
    styleUrls: ['./sms-compose-modal.component.css']
})
export class SmsComposeModalComponent implements OnInit {

    sending = false;
    recipients: Recipient[] = [];

    public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(public dialogRef: MatDialogRef<SmsComposeModalComponent>, @Inject(MAT_DIALOG_DATA) public args: SmsComposeArgs, private emailApi: EmailApiService) {
        const recipientsString = args.to;
        if (recipientsString) {
            const recipients = recipientsString.split(',');
            recipients.forEach(r => {
                if (UtilitiesService.validatePhoneNumber(r)) {
                    this.recipients.push(new Recipient( r));
                }
            });
        }
        if (this.recipients.length === 0) {
            this.recipients.push(new Recipient(''));
        }
    }

    ngOnInit() {
    }

    removeRecipient(recipient: Recipient) {
        this.recipients.splice(this.recipients.indexOf(recipient), 1);
    }

    addRecipient() {
        this.recipients.push(new Recipient(''));
    }

    get canSend() {
        return this.recipients.filter(i => UtilitiesService.validatePhoneNumber(i.phoneNumber)).length > 0
            && this.args.message;
    }

    async send() {
        this.sending = true;
        for (const recipient of this.recipients) {

            if (UtilitiesService.validatePhoneNumber(recipient.phoneNumber)) {
                this.args.to = recipient.phoneNumber;

                await this.emailApi.sendSMSMessage(this.args);
            }
        }
        this.sending = false;

        this.dialogRef.close(this.args);
    }
}
