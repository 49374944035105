<div aflex>
    <h1>{{placeholder}}</h1>
    <div nogrow>
        <button style="margin-top:-7px; margin-right:-16px;" mat-button [mat-dialog-close]>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
 <div style="padding: 20px 20px 7px 20px">
    <div>
        <app-date-picker [(date)]="date">
        </app-date-picker>
    </div>
    <div class="center">
        <button mat-button mat-raised-button color="primary" (click)="save()" style="min-width: 100px;">
            save
        </button>
    </div>
</div>