import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { EmailSubject } from '@upkeeplabs/models/cogent';
import { SmsComposeArgs } from '@cogent/shared/models/communication/sms-compose-args.model';

@Injectable({ providedIn: 'root' })
export class EmailApiService {

    constructor(private api: ApiService) { }

    getEmployeeList() {
        return this.api.getArrayDotNet(
            'entity/select',
            {
                properties:
                    'Id,Name,Email',
                type_eq: 'Employee',
                orderby: 'Name'
            });
    }

    getEmailSubject(subjectKey: string): Promise<EmailSubject> {
        return this.api.getSingleDotNet('EmailSubject/' + subjectKey, null, () => new EmailSubject());
    }

    getAllEmailSubjects() {
        return this.api.getArrayDotNet('EmailSubject', null, () => new EmailSubject());
    }

    async saveEmailSubjects(subjects: EmailSubject[]) {
        if (subjects.length > 0) {
            const promises = [];
            subjects.forEach(subject => promises.push(this.api.postIdDotNet('EmailSubject', subject)));
            await Promise.all(promises);
        }
        return true;
    }

    sendSMSMessage(args: SmsComposeArgs) {
        return this.api.postSingleDotNet(`users/SMS/send-message`, args);
    }

    sendAutoPhoneCall(phoneNumber: string, message: string) {
        return this.api.postSingleDotNet(`Twilio/call?number=${phoneNumber}`, { message });
    }
}