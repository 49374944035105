import { QueueAction } from '@cogent/client/shared/models/object-queue.model';
import { GlobalCommand } from '@upkeeplabs/models/cogent';

export class GlobalCommandClient extends GlobalCommand {
    private actionsObjectLocal: QueueAction[];

    get actionsObject(): QueueAction[] {
        if (!this.actionsObjectLocal && this.actions) {
            try {
                this.actionsObjectLocal = JSON.parse(this.actions);
            } catch (e) { }
        }
        if (!this.actionsObjectLocal) {
            this.actionsObjectLocal = [];
        }

        return this.actionsObjectLocal;
    }
    set actionsObject(value: QueueAction[]) {
        this.actions = JSON.stringify(value);
    }
}