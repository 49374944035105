import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ConstantContactEntity {
    @keyColumn() id: string;
    @column() type: 'Homeowner' | 'Agent';
    @column() firstName: string;
    @column() lastName: string;
    @column() company: string;
    @column() email: string;
    @column() phone: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() county: string;
    @column() salesRegion: string;
    @column() salesRep: string;
    @column() freeMarketing: boolean;
    @column() lastOrder: Date;
}