import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    templateUrl: './toast-warning.component.html',
    styleUrls: ['./toast-warning.component.css']
  })
export class ToastWarningComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackRef: MatSnackBarRef<ToastWarningComponent>) {}

    dismiss() {
      this.snackRef.dismiss();
    }
}
