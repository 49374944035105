import { Component, OnInit } from '@angular/core';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ContractorsService } from '@cogent/client/shared/services/api/contractors.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { ApiService } from '@cogent/client/api';

@Component({
    selector: 'app-pending-offers',
    templateUrl: './pending-offers.component.html',
    styleUrls: ['./pending-offers.component.css']
})
export class PendingOffersComponent implements OnInit {

    offers: WorkOrderSummaryClient[];
    constructor(private contractorService: ContractorsService, private entityApi: EntityApiService, private api: ApiService) { }

    ngOnInit() {
        this.entityApi.getLoggedInUser(false).then(user => {
            this.contractorService.getUnacceptedOffers(user.id).then(offers => {
                this.offers = offers;
            });
        });
    }

    getWorkOrderItemUrl(offer: WorkOrderSummaryClient) {
        return ApiService.endPointDotNet + 'WorkOrderItem/' +
            offer.itemId + '/photo';
    }



}
