<div flex style="overflow-x: auto; max-width:1700px">
    <div id="left-pane">
        <app-date-range-display-and-selector memoryKey="contractor-statistics" [(startDate)]="startDate"
            [(endDate)]="endDate"></app-date-range-display-and-selector>

        <button mat-button mat-raised-button color="primary" style="margin-top:20px;" block (click)="runReport()" [disabled]="runningReport">
            Load
        </button>

    </div>
    <div *ngIf="item" style="margin-right:20px; margin-bottom: 30px;">


        <div *ngIf="trades">
            @for (trade of trades; track trade.id) {
            <mat-checkbox [(ngModel)]="trade.selected" (ngModelChange)="tradeChange(trade)">{{trade.name}}</mat-checkbox>
            }

        </div>

        <div topmargin20 style="display: flex; flex-direction: row; justify-content: stretch;">
            <div nogrow style="min-width:450px; margin-right:30px;">
                <canvas topmargin10 id="accChart"></canvas>
                <div class="sub-heading" style="text-align: center">ACC</div>
            </div>
            <div topmargin10 style="width:100%; padding-left:20px;">
                <table style="height:100%">
                    <tr>
                        <td>
                            <div class="col-total">
                                <h1>
                                    <div class="big total-value">{{item.totalContractorCosts | currency}}
                                    </div>
                                </h1>
                                <div class="sub-heading total-label">Costs</div>
                            </div>
                        </td>
                        <td>
                            <div class="col-total">
                                <h1>
                                    <div class="big total-value">{{item.totalContractorClaims}}</div>
                                </h1>
                                <div class="sub-heading total-label">Claims</div>
                            </div>
                        </td>
                        <td>
                            <div class="col-total">
                                <h1>
                                    <div class="big total-value">{{item.totalContractorRecall | percent}}
                                    </div>
                                </h1>
                                <div class="sub-heading total-label">Recall %</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="col-total">
                                <h1>
                                    <div class="big total-value">
                                        {{item.totalContractorDispatchUsage | percent}}</div>
                                </h1>
                                <div class="sub-heading total-label">Dispatch</div>
                            </div>
                        </td>
                        <td>
                            <div class="col-total">
                                <h1>
                                    <div class="big total-value">{{item.totalContractorACC | currency}}
                                    </div>
                                </h1>
                                <div class="sub-heading total-label">ACC</div>
                            </div>
                        </td>
                        <td>
                            <div class="col-total">
                                <h1>
                                    <div class="big total-value" *ngIf="item.totalContractorRating">
                                        {{item.totalContractorRating | number: '1.2-2'}}</div>

                                </h1>
                                <div class="sub-heading total-label">5-Star</div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div>
            <table *ngIf="item" margintop20>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th colspan="3"
                            style="background-color: rgb(214 214 214); text-align: center; border-left:solid 1px black; border-right:solid 1px black;">
                            Dispatch
                        </th>
                        <th colspan="4"
                            style="background-color: rgb(232, 232, 232); text-align: center; border-left:solid 1px black; border-right:solid 1px black;">
                            ACC
                        </th>
                        <th colspan="4"
                            style="background-color: rgb(214 214 214); text-align: center; border-left:solid 1px black; border-right:solid 1px black;">
                            5 Star
                        </th>
                        <th colspan="4"
                            style="background-color: rgb(232, 232, 232); text-align: center; border-left:solid 1px black; border-right:solid 1px black;">
                            Recall %
                        </th>
                    </tr>
                    <tr class="header-row2">
                        <th colspan="2">Region</th>
                        <th style="text-align: right">Costs</th>
                        <th style="text-align: right">Claims</th>
                        <th style="text-align: right; padding-right:20px;">Commits</th>
                        <th style="text-align: right; border-left: solid 1px black">Scheduled</th>
                        <th style="text-align: right">Enroute</th>
                        <th style="text-align: right">Completed</th>
                        <th style="text-align: right; width:75px; border-left: solid 1px black">ACC</th>
                        <th style="text-align: right; width:70px;">Target</th>
                        <th colspan="2" style="text-align: center; width:70px; border-right: solid 1px black">Grade</th>
                        <th style="text-align: right; width:70px; border-left: solid 1px black">5 Star</th>
                        <th style="text-align: right; width:70px;">Target</th>
                        <th colspan="2" style="text-align: center; width:70px; border-right: solid 1px black">Grade</th>
                        <th style="text-align: right; width:70px; border-left: solid 1px black">Recall %</th>
                        <th style="text-align: right; width:70px;">Target</th>
                        <th colspan="2" style="text-align: center; width:70px; border-right: solid 1px black">Grade</th>
                    </tr>
                </thead>
                <tbody>
                    @for (regionItem of item.regions; track regionItem.id) {
                    <tr *ngIf="regionItem.items?.length>0" class="hover-row region-row">
                        <td class="region-row" colspan="2">{{regionItem.name}}</td>
                        <td class="region-row" style="text-align: right">{{regionItem.totalCosts | currency }}</td>
                        <td class="region-row" style="text-align: right">{{regionItem.totalClaims }}</td>
                        <td class="region-row" style="text-align: right; padding-right:20px;">{{regionItem.totalCommits }}
                        </td>
                        <td class="region-row" style="text-align: right; border-left: solid 1px black">
                            <span *ngIf="regionItem.totalScheduled>=regionItem.totalClaims">100%</span>
                            <span *ngIf="regionItem.totalScheduled<regionItem.totalClaims">
                                {{regionItem.totalScheduled/regionItem.totalClaims | percent }}</span>
                        </td>
                        <td class="region-row" style="text-align: right">
                            <span *ngIf="regionItem.totalEnroute>=regionItem.totalClaims">100%</span>
                            <span *ngIf="regionItem.totalEnroute<regionItem.totalClaims">
                                {{regionItem.totalEnroute/regionItem.totalClaims | percent }}</span>
                        </td>
                        <td class="region-row" style="text-align: right">
                            <!-- {{item.totalCompletes}}/{{item.totalClaims}} -->
                            <span *ngIf="regionItem.totalCompletes>=regionItem.totalClaims">100%</span>
                            <span *ngIf="regionItem.totalCompletes<regionItem.totalClaims">
                                {{regionItem.totalCompletes/regionItem.totalClaims | percent }}
                            </span>
                        </td>
                        <td class="region-row acc-col region">
                            {{regionItem.totalACC | currency:'USD':'symbol':'1.0-0'}}
                        </td>
                        <td class="region-row" style="text-align: right; width:70px">
                            <span>
                            {{regionItem.areaAccTarget | currency:'USD':'symbol':'1.0-0'}}</span>
                        </td>
                        <td class="region-row" style="text-align: right; width:70px">
                            <span *ngIf="regionItem.totalACC>0">
                                {{regionItem.areaAccTarget/regionItem.totalACC | percent}}</span>
                        </td>

                        <td class="region-row" style="width: 40px; border-right:solid 1px black">
                            <span *ngIf="regionItem.totalACC>0">
                                {{item.letterGrade(regionItem.areaAccTarget/regionItem.totalACC)}}
                            </span>
                        </td>
                        <td class="region-row" style="text-align: right; width:80px; border-left:solid 1px black">
                            <span *ngIf="regionItem.totalRating">{{regionItem.totalRating | number:'1.2-2'}}</span>
                        </td>
                        <td class="region-row" style="text-align: right; width:70px">
                            {{regionItem.areaTarget?.surveyTarget | number:'1.2-2'}}
                        </td>
                        <td class="region-row" style="text-align: right; width:70px">
                            <span *ngIf="regionItem.totalRating">
                                {{regionItem.totalRating/regionItem.areaTarget?.surveyTarget | percent}}</span>
                        </td>
                        <td class="region-row" style="width: 40px; border-right:solid 1px black">
                            <span *ngIf="regionItem.totalRating">
                                {{item.letterGrade(regionItem.totalRating/regionItem.areaTarget?.surveyTarget)}}
                            </span>
                        </td>

                        <td class="region-row" style="text-align: right; width:80px; border-left:solid 1px black">
                            <span *ngIf="regionItem.totalRecall">{{regionItem.totalRecall | percent}}</span>
                        </td>
                        <td class="region-row" style="text-align: right; width:70px">
                            {{regionItem.areaTarget?.recallTarget | percent}}
                        </td>
                        <td class="region-row" style="text-align: right; width:70px">
                            <span *ngIf="regionItem.totalRecall">
                                {{regionItem.areaTarget?.recallTarget/regionItem.totalRecall | percent}}</span>
                        </td>
                        <td class="region-row" style="width: 40px; border-right:solid 1px black">
                            <span *ngIf="regionItem.totalRecall">
                                {{item.letterGrade(regionItem.areaTarget?.recallTarget/regionItem.totalRecall)}}
                            </span>
                        </td>
                    </tr>
                    @for (item2 of regionItem.items; track item2.key){
                    <tr class="hover-row-detail" *ngIf="item2.amount>0 || item2.claims>0">
                        <td style="padding-left:22px; width:85px">{{item2.endOfMonth | date: 'MM-yyyy' }}</td>
                        <td style="min-width:88px">{{item2.tradeName}}</td>
                        <td style="text-align: right">{{item2.amount | currency }}</td>
                        <td style="text-align: right">{{item2.claims }}</td>
                        <td style="text-align: right; padding-right:20px;">{{item2.commits }}</td>
                        <td style="text-align: right; border-left: solid 1px black">
                            <span *ngIf="item2.scheduledCount>=item2.claims">100%</span>
                            <span *ngIf="item2.scheduledCount<item2.claims">
                                {{item2.scheduledCount/item2.claims | percent }}</span>
                        </td>
                        <td style="text-align: right">
                            <span *ngIf="item2.enrouteCount>=item2.claims">100%</span>
                            <span *ngIf="item2.enrouteCount<item2.claims">
                                {{item2.enrouteCount/item2.claims | percent }}</span>
                        </td>
                        <td style="text-align: right">
                            <!-- {{item2.completedCount}}/{{item2.claims}} -->
                            <span *ngIf="item2.completedCount>=item2.claims">100%</span>
                            <span *ngIf="item2.completedCount<item2.claims">
                                {{item2.completedCount/item2.claims | percent }}</span>
                        </td>

                        <td class="acc-col">
                            {{item2.aCC | currency:'USD':'symbol':'1.0-0'}}</td>
                        <td style="text-align: right; width:70px">
                            {{item2.aCCTarget | currency:'USD':'symbol':'1.0-0'}}
                        </td>
                        <td style="text-align: right; width:70px">
                            <div *ngIf="item2.aCCTarget">
                                <span *ngIf="item2.aCC>0">{{item2.aCCTarget/item2.aCC | percent}}</span>
                                <span *ngIf="item2.aCC==0">{{1 | percent}}</span>
                            </div>
                        </td>
                        <td style="width:40px;border-right:solid 1px black">
                            <div *ngIf="item2.aCCTarget">
                                <span *ngIf="item2.aCC>0">
                                    {{item.letterGrade(item2.aCCTarget/item2.aCC)}}
                                </span>
                                <span *ngIf="item2.aCC==0">A+</span>
                            </div>
                        </td>

                        <td style="text-align: right; width:80px; border-left:solid 1px black">
                            {{item2.rating | number: '1.2-2'}}</td>
                        <td style="text-align: right; width:70px">
                            {{item2.surveyTarget | number:'1.2-2'}}
                        </td>
                        <td style="text-align: right; width:70px">
                            <div *ngIf="item2.surveyTarget && item2.rating">
                                <span *ngIf="item2.rating>0">{{item2.rating/item2.surveyTarget | percent}}</span>
                                <span *ngIf="item2.rating==0">{{1 | percent}}</span>
                            </div>
                        </td>
                        <td style="width:40px;border-right:solid 1px black">
                            <div *ngIf="item2.surveyTarget">
                                <span *ngIf="item2.rating>0">
                                    {{item.letterGrade(item2.rating/item2.surveyTarget)}}
                                </span>
                                <span *ngIf="item2.rating==0">A+</span>
                            </div>
                        </td>

                        <td style="text-align: right; width:80px; border-left:solid 1px black">
                            <span *ngIf="item2.recallPercent">{{item2.recallPercent | percent}}
                            </span>
                        </td>
                        <td style="text-align: right; width:70px">
                            {{item2.recallTarget | percent}}
                        </td>
                        <td style="text-align: center; width:70px;">
                            <div *ngIf="item2.recallTarget && item2.recallPercent">
                                <span *ngIf="item2.recallPercent>0">
                                    {{item2.recallTarget/item2.recallPercent | percent}}</span>
                                <span *ngIf="item2.recallPercent==0">A+</span>
                            </div>
                        </td>
                        <td style="width:40px;border-right:solid 1px black">
                            <div *ngIf="item2.recallTarget && item2.recallPercent">
                                <span *ngIf="item2.recallPercent>0">
                                    {{item.letterGrade(item2.recallTarget/item2.recallPercent)}}
                                </span>
                                <span *ngIf="item2.recallPercent==0">A+</span>
                            </div>
                        </td>
                    </tr>
                    }
                    }
                </tbody>
                <tfoot class="footer" style="font-size:12pt;">
                    <th colspan="2"></th>
                    <th style="text-align: right; padding-right:10px;">{{item.totalContractorCosts | currency}}
                    </th>
                    <th style="text-align: right; padding-right:10px;">{{item.totalContractorClaims}}</th>
                    <th style="text-align: right; padding-right:20px;">{{item.totalContractorCommits}}</th>
                    <th style="text-align: right; padding-right:10px; border-left: solid 1px black">
                        <span *ngIf="item.totalContractorScheduled>=1">100%</span>
                        <span *ngIf="item.totalContractorScheduled<1">
                            {{item.totalContractorScheduled | percent}}</span>
                    </th>
                    <th style="text-align: right; padding-right:10px;">
                        <span *ngIf="item.totalContractorEnroute>=1">100%</span>
                        <span *ngIf="item.totalContractorEnroute<1">
                            {{item.totalContractorEnroute | percent}}</span>
                    </th>
                    <th style="text-align: right; padding-right:10px;">
                        <span *ngIf="item.totalContractorCompletion>=1">100%</span>
                        <span *ngIf="item.totalContractorCompletion<1">
                            {{item.totalContractorCompletion | percent}}</span>
                    </th>
                    <th class="acc-col region" style="text-align: right; padding-right:8px; width:75px; border-left: solid 1px black">
                        {{item.totalContractorACC | currency:'USD':'symbol':'1.0-0'}}</th>
                    <th style="text-align: right; padding-right:8px; width:70px;">
                        {{item.accTarget | currency:'USD':'symbol':'1.0-0'}}</th>
                    <th style="text-align: right; padding-right:8px; width:70px">
                        {{item.contractorAccDiff | percent}}
                    </th>
                    <th style="padding-left:5px; width:40px; border-right: solid 1px black">
                        {{item.letterGrade(item.contractorAccDiff)}}</th>
                    <th style="text-align: right; padding-right:7px; width:70px; border-left: solid 1px black">
                        {{item.totalContractorRating | number: '1.2-2'}}</th>
                    <th style="text-align: right; padding-right:8px; width:70px;">{{item.surveyTarget | number:
                        '1.2-2'}}</th>
                    <th style="text-align: right; padding-right:8px; width:70px">
                        {{item.contractorRatingDiff | percent}}
                    </th>
                    <th style="padding-left:5px; width:40px; border-right: solid 1px black">
                        <span *ngIf="item.contractorRatingDiff">
                            {{item.letterGrade(item.contractorRatingDiff)}}
                        </span>
                    </th>
                    <th style="text-align: right; padding-right:10px; width:70px; border-left: solid 1px black">
                        {{item.totalContractorRecall | percent}}</th>
                    <th style="text-align: right; padding-right:8px; width:70px;">
                        {{item.recallTarget | percent}}</th>
                    <th style="text-align: right; padding-right:8px; width:70px">
                        {{item.contractorRecallDiff | percent}}
                    </th>
                    <th style="padding-left:5px; width:40px; border-right: solid 1px black">
                        <span *ngIf="item.contractorRatingDiff">
                            {{item.letterGrade(item.contractorRecallDiff)}}</span>
                    </th>
                </tfoot>
            </table>
        </div>
    </div>
</div>