import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class ContractorInvoice {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() workPerformed: string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() partsCost: number;
    @column() laborCost: number;
    @column() tax: number;
    @readOnlyColumn() amount: number;
    @column() paidDate: Date;
    @column() receivedDate: Date;
    @column() contractorInvoiceNumber: string;
    @column() approvedDate: Date;
    @column() exportDate: Date;
    @column() quickBooksId: string;
    // @column() version: timestamp;
    @column() purchaseOrderId: string;
    @column() appliesToLines: string;
    @column() postDate: Date;

    // Client side only
    appliedItemsJson: string;
    completionDate: Date;

    get totalAmount(): number {
        if (isNaN(this.partsCost)) {
            this.partsCost = 0;
        }
        if (isNaN(this.laborCost)) {
            this.laborCost = 0;
        }
        if (isNaN(this.tax)) {
            this.tax = 0;
        }
        return this.partsCost + this.laborCost + this.tax;
    }

    // get amount() {
    //     return this.partsCost + this.laborCost + this.tax;
    // }
    get canSave() {
        return this.contractorInvoiceNumber
            && this.workPerformed;
    }
}