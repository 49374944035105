import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Trade {
    @keyColumn() id: string;
    @column() name: string;
    @column() tradeCategoryId: string;
    @column() deletedDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() authorizationLimit: number = 0;
    @column() sundayAppointments: boolean = false;
    @column() mondayAppointments: boolean = false;
    @column() tuesdayAppointments: boolean = false;
    @column() wednesdayAppointments: boolean = false;
    @column() thursdayAppointments: boolean = false;
    @column() fridayAppointments: boolean = false;
    @column() saturdayAppointments: boolean = false;
    @column() holidayAppointments: boolean = false;
    @column() laborRate: number = 0;
    @column() multiItemDiscountAmount: number;
    @column() timeSlotDayBuffer: number = 0;
    @column() allowTimeSlotSelection: boolean = false;
    @column() timeslotDaysToOffer: number = 0;
    @column() alternateTimeslotDaysToOffer: number = 0;
    @column() useAuthorizationV4: boolean;
    @column() tripCharge: number;
    @column() authorizationV4Users: string;

    selected: boolean;
    categoryName: string;
    hide = false;
}