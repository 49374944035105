import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTableChipSelectionComponent } from './custom-table-chip-selection/custom-table-chip-selection.component';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [CustomTableChipSelectionComponent],
    imports: [
        CommonModule,
        MaterialSharedModule,
        FormsModule
    ],
    exports: [CustomTableChipSelectionComponent]
})
export class CustomTableChipSelectionModule { }
