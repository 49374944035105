<div *ngIf="workOrderInvalid" class="fade-in-up" style="margin-top: 50px; margin-left:20px;">
    <div class="container">
        <div class="center" style="display: table;margin: 0 auto">
            <div>

                <svg style="height: 180px;" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 581.27 562.55">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #f15a24;
                            }

                            .cls-2 {
                                fill: none;
                                stroke: #f15a24;
                                stroke-linecap: round;
                                stroke-miterlimit: 10;
                                stroke-width: 22px;
                            }

                            @keyframes animate-mouth {
                                from {
                                    d: path('M119.31,238.13c23.22-1.06,51.9-1.68,82.93-1.68,27.36,0,52.88,.49,74.44,1.33');
                                }

                                to {
                                    d: path('M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79')
                                }
                            }


                            #mouth {
                                animation: animate-mouth 5s ease;
                            }
                        </style>
                    </defs>
                    <path class="cls-1"
                        d="M198,34c43.81,0,84.99,17.06,115.97,48.03,30.98,30.98,48.03,72.16,48.03,115.97s-17.06,84.99-48.03,115.97c-30.98,30.98-72.16,48.03-115.97,48.03s-84.99-17.06-115.97-48.03c-30.98-30.98-48.03-72.16-48.03-115.97s17.06-84.99,48.03-115.97c30.98-30.98,72.16-48.03,115.97-48.03m0-34C88.65,0,0,88.65,0,198s88.65,198,198,198,198-88.65,198-198S307.35,0,198,0h0Z" />
                    <rect class="cls-1" x="340.96" y="324.25" width="26" height="66"
                        transform="translate(-148.16 385.4) rotate(-48.47)" />
                    <rect class="cls-1" x="438.1" y="336.61" width="69" height="250.05" rx="18.95" ry="18.95"
                        transform="translate(-186.32 509.41) rotate(-48.47)" />
                    <g id="face">
                        <g>
                            <circle class="cls-1" cx="147.7" cy="167.79" r="23.79" />
                            <circle class="cls-1" cx="248.3" cy="167.79" r="23.79" />
                        </g>
                        <path id="mouth" class="cls-2"
                            d="M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79" />
                    </g>
                </svg>
            </div>
            <div>
                <h1 class="big-data" topmargin40>Sorry</h1>
                <div topmargin30 class="fade-in-later">
                    <p bottommargni20>Another service pro has been assigned this job</p>
                    <a routerLink="/" mat-button mat-raised-button color="primary">
                        Home
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div flex *ngIf="workOrderSummary" padding>
    <div id="canceledStamp" *ngIf="workOrderSummary.cancelledDate && selectedIndex == 0">
        <h1>Canceled</h1>
        {{workOrderSummary.cancelledDate | date: 'shortDate'}}
    </div>

    <div id="time-slot-acceptance-backdrop" *ngIf="showTimeSlotAcceptance && !dispatchContractor">
        <div id="time-slot-acceptance" class="slide-in-down" *ngIf="showTimeSlotAcceptance">

            <mat-tab-group class="hide-tabs" [(selectedIndex)]="timeSlotSelectedIndex">
                <mat-tab label="First">
                    <div style="padding: 20px">
                        <h1 class="page-title" *ngIf="!isJumpBall"><span class="big">Appointment</span> <br><span
                                class="little">Selection</span></h1>

                        <div *ngIf="isJumpBall" bottommargni20>
                            <div class="center">
                                <img src="../../../assets/images/jump-ball.svg" style="height: 150px;">
                                <br>
                                <h1 topmargin20 bottommargin20>Jump Ball</h1>
                            </div>
                            <p>This is a first-come-first-serve job. If you are the first to accept one of the time
                                slots, the job is yours. If you can't
                                accept one of the preferred times, there's no need to do anything.
                            </p>
                        </div>
                        <p *ngIf="!schedulingFollowup">
                            The homeowner requested service on their {{workOrderSummary.itemName}} at
                            {{workOrderSummary.propertyAddress}} {{workOrderSummary.propertyCity}}
                            {{workOrderSummary.propertyPostalCode}}
                            has requested {{preferredTimeSlots.length}} time slots. Contact information will be
                            available after acceptance of a
                            time. Please select one of the available
                            times:
                        </p>
                        <p *ngIf="schedulingFollowup">
                            Our records show that all parts have arrived on this job, and the homeowner requested
                            service on their {{workOrderSummary.itemName}} at
                            {{workOrderSummary.propertyAddress}} {{workOrderSummary.propertyCity}}
                            {{workOrderSummary.propertyPostalCode}}
                            has requested {{preferredTimeSlots.length}} time slots. Please select one of the available
                            times:
                        </p>


                        <div id="time-slot-buttons-container" topmargin30 *ngIf="!dispatchContractor">
                            <button mblock [disabled]="timeSlot.accepting || confirmingAppointment || rejecting"
                                (click)="assignTimeSlot(timeSlot)" *ngFor="let timeSlot of preferredTimeSlots"
                                mat-button mat-raised-button color="primary">
                                <mat-spinner thirty *ngIf="timeSlot.accepting"></mat-spinner>
                                {{timeSlot.summary}}
                            </button>
                        </div>

                        <div #gmap style="width:100%;height:200px"></div>

                        <ng-container *ngIf="workOrderSummary && workOrderSummary.type !== 'Recall' && !isJumpBall">
                            <p topmargin30>
                                If you are not able to accommodate one of the preferred time slots, please suggest
                                alternatives or click the
                                'Re-Assign'
                                button
                                to
                                transfer the job to another provider.
                            </p>
                            <div>
                                <!-- <button mat-button mblock style="margin-bottom: 20px;margin-right: 20px;"
                                    mat-raised-button (click)="showAlternateTimeSlots()">
                                    Suggest Alternate Timeslot(s)
                                </button> -->
                                <button mat-button mblock style="margin-bottom: 20px;margin-right: 20px;"
                                    mat-raised-button (click)="callToSchedule()">
                                    Call To Schedule
                                </button>
                                <button mblock *ngIf="!schedulingFollowup"
                                    [disabled]="confirmingAppointment || rejecting" (click)="reassign()" mat-button
                                    mat-raised-button>
                                    <mat-spinner thirty *ngIf="rejecting"></mat-spinner>
                                    Re-Assign
                                </button>

                                <!-- <button mblock *ngIf="schedulingFollowup" [disabled]="confirmingAppointment" (click)="noParts()">
                                    The parts have not arrived
                                </button> -->
                                <button mblock mat-button mat-raised-button *ngFor="let command of rescheduleCommands"
                                    [disabled]="command.running" (click)="executeCommandServer(command)">
                                    <mat-icon *ngIf="!command.running">{{command.iconClass}}</mat-icon>
                                    <mat-spinner *ngIf="command.running" class="thirty"></mat-spinner>
                                    {{command.label}}
                                </button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="workOrderSummary && workOrderSummary.type === 'Recall'">
                            <p topmargin30>
                                If you are not able to accommodate one of the preferred time slots, please suggest some
                                alternate time slots.
                            </p>
                            <div>
                                <button mat-button mat-raised-button (click)="showAlternateTimeSlots()">
                                    Suggest Alternate Timeslot(s)
                                </button>
                            </div>
                        </ng-container>
                        <button mat-button (click)="showQAndA = true">View Questions &amp; Answers</button>
                        <div *ngIf="showQAndA">
                            <ng-container *ngFor="let line of workOrderSummary.lines">
                                {{line.itemName}} Q &amp; A
                                <ul *ngFor="let qa of line.questionsAndAnswers">
                                    <li *ngIf="qa.text !== 'Done'">
                                        <span class="data-label">{{qa.text}}</span>: {{qa.answer}}
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Second">
                    <div style="padding: 20px">
                        <h1 class="page-title"><span class="big">Suggest</span> <br><span
                                class="little">Alternate</span></h1>
                    </div>

                    <div class="desktop-padding" style="max-width: 600px;">
                        <app-appointment-selection [timeSlots]="timeSlots" [hideThird]="true"
                            [(selectedTimeSlots)]="alternateTimeSlotSuggestion.timeSlots">
                        </app-appointment-selection>
                    </div>

                    <div style="padding: 20px">
                        <mat-form-field class="no-fixed" appearance="outline">
                            <mat-label>Message</mat-label>
                            <textarea matInput rows="5" [(ngModel)]="alternateTimeSlotSuggestion.message"></textarea>
                        </mat-form-field>
                        <div aflex>
                            <div>
                                <button mat-button (click)="timeSlotSelectedIndex = 0">
                                    <mat-icon>chevron_left</mat-icon>
                                </button>
                            </div>
                            <div nogrow>
                                <button mat-button mat-raised-button color="primary" [disabled]="!canSuggestAlternate"
                                    (click)="requestAlternateTimes()">
                                    <mat-spinner *ngIf="savingAlternate" class="thirty"></mat-spinner>
                                    Send Request
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>


            </mat-tab-group>


        </div>
    </div>

    <div nogrow id="sidebar" [class.collapsed-it]="selectedIndex != 0" class="show-gt-sm">

        <my-claim-summary [firstEffectiveDate]="firstEffectiveDate"
            [hideCustomerName]="(showTimeSlotAcceptance && !schedulingFollowup) || hideCustomerName || hidePhoneNumber"
            [workOrderSummary]="workOrderSummary" [notes]="notes">
        </my-claim-summary>

        <mat-card topmargin20 id="additional-options">
            <div class="sub-heading">Additional Options</div>
            <button mat-button block (click)="back()">
                Overview
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button *ngIf="!workOrderSummary.cancelledDate" mat-button block (click)="updateStatus()">
                Update Status
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button *ngIf="!workOrderSummary.cancelledDate" mat-button block [disabled]="dispatchContractor" (click)="createAppointment()">
                Schedule Appointment
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button *ngIf="!workOrderSummary.cancelledDate && portalEnabled" mat-button block
                (click)="startDispatch()">Assign
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button *ngIf="!workOrderSummary.cancelledDate && authoEnabled" mat-button block (click)="createAutho()">
                Get Authorization
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button [disabled]="sendingOnMyWay" *ngIf="!workOrderSummary.cancelledDate && authoEnabled "
                [disabled]="sendingOnMyWay" mat-button block (click)="sendOnMyWay()">
                Send On My Way
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button *ngIf="!workOrderSummary.cancelledDate" mat-button block (click)="startComplete()">Complete Service
                Request
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
            <button *ngIf="!workOrderSummary.cancelledDate && portalEnabled" mat-button block
                (click)="startInvoicing()">Invoice
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>

            <button mat-button block (click)="showCoverageAndNotes()">Coverage &amp; Notes
                <i class="material-icons" pull-right>keyboard_arrow_right</i>
            </button>
        </mat-card>
    </div>
    <div>
        <mat-tab-group class="hide-tabs" [selectedIndex]="selectedIndex" (selectedIndexChange)="onStepChange()">
            <mat-tab label="Home">

                <div class="hide-gt-sm">
                    <my-claim-summary [workOrderSummary]="workOrderSummary"></my-claim-summary>
                </div>

                <mat-card id="whats-next-card" style="max-width: 1200px;margin-bottom: 20px;">

                    <div aflex>
                        <div>
                            <h1 class="sub-heading">What's Next?</h1>
                        </div>
                        <div nogrow>
                            <button id="other-options-mobile-button" mat-button [matMenuTriggerFor]="otherOptionsMenu">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div style="text-align: center;" topmargin20>
                        <div *ngIf="stepNumber < 1 && !workOrderSummary.cancelledDate">
                            <button mat-button mobile-block large mat-raised-button color="primary"
                                (click)="createAppointment()" [disabled]="dispatchContractor">
                                <i class="material-icons">event</i> Schedule Appointment</button>
                            <div *ngIf="showDispatchWarning" style="color: rgb(229, 149, 1); margin-top:10px">
                                Please use <a style="text-decoration: underline;" href="{{dispatchUrl}}">Dispatch</a> to schedule the
                                appointment.
                            </div>
                        </div>
                        <button mat-button mobile-block
                            *ngIf="stepNumber == 1 && !workOrderSummary.cancelledDate  && portalEnabled" large
                            mat-raised-button color="primary" (click)="startDispatch()">Assign</button>
                        <button mat-button mobile-block
                            *ngIf="stepNumber == 2 && !workOrderSummary.cancelledDate && authoEnabled && workOrderSummary.onMyWayNotificationSentDate != null"
                            large mat-raised-button color="primary" (click)="createAutho()">
                            <i class="material-icons">gavel</i> Authorize</button>
                        <button mat-button mobile-block
                            *ngIf="stepNumber == 2 && !workOrderSummary.cancelledDate && authoEnabled && workOrderSummary.onMyWayNotificationSentDate == null"
                            large mat-raised-button color="primary" (click)="sendOnMyWay()" [disabled]="sendingOnMyWay">
                            <i class="material-icons">local_shipping</i> On My Way</button>

                        <button mat-button mobile-block
                            *ngIf="stepNumber == 3 && !workOrderSummary.cancelledDate && portalEnabled" large
                            mat-raised-button color="primary" (click)="startComplete()">Complete Service
                            Request</button>
                        <button mat-button mobile-block
                            *ngIf="stepNumber == 4 && !workOrderSummary.cancelledDate && portalEnabled" large
                            mat-raised-button color="primary" (click)="startInvoicing()">Invoice</button>

                        <ng-container *ngIf="!portalEnabled && stepNumber === 1">
                            <button mat-button mobile-block large mat-raised-button color="primary"
                                (click)="startComplete()">Complete Service
                                Request</button>

                            <div topmargin20 bottommargin20>-OR-</div>



                            <div style="max-width: 500px;text-align: center;margin: 0 auto; display: table;">


                                <div flex>
                                    <div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Status</mat-label>
                                            <mat-select [(ngModel)]="newStatus">
                                                <mat-option value="36406607-89CB-4760-ABB2-79095FA7E28A">Ordering Parts
                                                </mat-option>
                                                <mat-option value="E0B5402C-9D34-4B15-B419-C72464E3B220">Back Ordered
                                                    Parts Delay
                                                </mat-option>
                                                <mat-option value="C57D321C-17EF-40C1-A70C-EED534C8AC6A">Customer No
                                                    Show</mat-option>
                                                <mat-option value="C3E32787-4886-40BC-BE8D-DE829DB7FDF1">Authorization
                                                    Requested</mat-option>
                                                <mat-option value="89225872-1323-41E8-85FC-50DAB7601F47">Parts Research
                                                </mat-option>
                                                <mat-option value="47A5716F-DB12-4D75-997A-F62697867567">Awaiting
                                                    Customer Approval</mat-option>
                                                <mat-option value="5D1157A4-E026-4F06-86D1-EB62DC89919E">Waiting For
                                                    Customer On Scheduling</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div nogrow>
                                        <button mat-button mat-raised-button style="margin-top: 5px;"
                                            [disabled]="!newStatus || updatingStatus" (click)="saveNewStatus()">
                                            <mat-spinner *ngIf="updatingStatus" class="thirty"></mat-spinner>
                                            Update Status
                                        </button>
                                    </div>
                                </div>




                            </div>

                        </ng-container>

                    </div>


                    <mat-menu #otherOptionsMenu="matMenu">
                        <!-- <button mat-menu-item>Item 1</button>
            <button mat-menu-item>Item 2</button> -->

                        <!-- <button mat-menu-item block (click)="back()">
                            Overview
                        </button> -->
                        <button *ngIf="!workOrderSummary.cancelledDate" mat-menu-item block (click)="updateStatus()">
                            Update Status
                        </button>
                        <button *ngIf="!workOrderSummary.cancelledDate" mat-menu-item block [disabled]="dispatchContractor"
                            (click)="createAppointment()">
                            Schedule Appointment
                        </button>

                        <button *ngIf="!workOrderSummary.cancelledDate" mat-menu-item block
                            (click)="startDispatch()">Dispatch
                        </button>
                        <button *ngIf="!workOrderSummary.cancelledDate && authoEnabled " mat-menu-item block
                            (click)="createAutho()">
                            Get Authorization
                        </button>
                        <button *ngIf="!workOrderSummary.cancelledDate && authoEnabled " mat-menu-item block
                            (click)="sendOnMyWay()" [disabled]="sendingOnMyWay">
                            Send On My Way
                        </button>
                        <button *ngIf="!workOrderSummary.cancelledDate" mat-menu-item block
                            (click)="startComplete()">Complete
                            Service
                            Request
                        </button>
                        <button *ngIf="!workOrderSummary.cancelledDate && portalEnabled" mat-menu-item block
                            (click)="startInvoicing()">Invoice
                        </button>

                        <button mat-menu-item block (click)="showCoverageAndNotes()">Coverage &amp; Notes
                        </button>
                    </mat-menu>
                </mat-card>

                <mat-card *ngIf="extraCommands && extraCommands.length > 0"
                    style="max-width: 1200px;margin-bottom: 20px;margin-top: 20px">
                    <div class="data-label">Other Options</div>
                    <button mat-button *ngFor="let command of extraCommands" (click)="executeCommand(command)">
                        <mat-icon>{{command.iconClass}}</mat-icon>
                        {{command.label}}
                    </button>
                </mat-card>

                <mat-card style="max-width: 1200px;margin-bottom: 20px;margin-top: 20px">
                    <!-- <div class="sub-heading">
                        <i class="material-icons">timeline</i> Status
                    </div>
                    <div flex id="workOrderStatusBar">
                        <div *ngFor="let step of steps" flex>
                            <div class="status-line-container">
                                <div class="status-line" [class.complete]="step.complete"></div>
                            </div>

                            <div nogrow [class.complete]="step.complete ">
                                <i class="material-icons step-number ">filter_{{steps.indexOf(step) + 1}}</i>
                                <i class="material-icons ">{{step.iconClass}}</i> {{step.description}}
                                <br>{{step.subStatus}}
                            </div>
                        </div>
                    </div>

                    <div clear></div> -->

                    <div style="position: relative" aflex>
                        <div>
                            <h2 class="sub-heading">
                                <i class="material-icons" mat-card-avatar>timeline</i> Job Item(s)
                            </h2>
                        </div>
                        <div nogrow>
                            <button mat-button (click)="updateStatusOnItem()">
                                <mat-icon>edit</mat-icon> Update Status (All Items)
                            </button>
                        </div>
                    </div>


                    <mat-card-content>
                        <div class="jobs-items-container">
                            <div *ngFor="let item of jobItems" class="job-item">
                                <div>
                                    <img [src]="getItemThumbnailUrl(item)">
                                </div>
                                <div *ngIf="item.limitPerOccurrence">
                                    <span class="data-label">limit: </span>{{item.limitPerOccurrence | currency}}
                                </div>
                                <div>
                                    <h2 class="sub-heading" style="white-space: nowrap;">{{item.itemName}}</h2>
                                    <app-circle-widget-small [percent]="item.percentComplete"
                                        [iconClass]="item.iconClass">
                                    </app-circle-widget-small>
                                    <div>
                                        <span class="badge">{{item.status}} </span>
                                    </div>
                                </div>
                                <div class="menu-bar">
                                    <button mat-button (click)="updateStatusOnItem(item)" title="Change Status">
                                        <mat-icon>edit</mat-icon> Update Status
                                    </button>
                                    <button mat-button (click)="showDetail(item)" title="More Details">
                                        <mat-icon>menu_open</mat-icon> Q&amp;A / Contract
                                    </button>
                                    <button mat-button *ngIf="!workOrderSummary.cancelledDate && authoEnabled "
                                        (click)="createAuthoForItem(item)">
                                        <mat-icon>gavel</mat-icon>
                                        Authorization</button>
                                </div>

                            </div>
                        </div>
                    </mat-card-content>

                </mat-card>

                <div flex style="max-width: 1200px; margin-bottom: 20px ">
                    <mat-card flex1>

                        <div aflex>

                            <div class="sub-heading">
                                Pictures &amp; Notes
                            </div>
                            <div nogrow>

                                <button mat-button (click)="showFileUpload()" title="Add Picture">
                                    <mat-icon>image</mat-icon>
                                </button>
                                <button mat-button (click)="addNote()" title="Add Note">
                                    <mat-icon>note_add</mat-icon>
                                </button>
                            </div>
                        </div>


                        <input type="file" style="display: none" name="fileToUpload1"
                            (change)="handleFileUpload($event)" accept="image/*" id="fileToUpload1" />
                        <div topmargin20>
                            <a style="height: auto;" (click)="showAttachment(attachment)" mat-button class="attachment"
                                *ngFor="let attachment of attachments" target="_blank">
                                <img *ngIf="!attachment.uploading && !attachment.failedToUpload"
                                    [src]="!attachment.base64 ? attachment.thumbnailUrl : attachment.base64"
                                    style="max-width: 128px" [title]="attachment.name">
                                <mat-spinner *ngIf="attachment.uploading && !attachment.failedToUpload"></mat-spinner>
                                <img src="../../../assets/images/upload-failed.svg" *ngIf="attachment.failedToUpload">
                                <br>
                                <span class="attachment-name">
                                    {{attachment.name}}</span>
                            </a>
                            <div *ngFor="let note of notes" class="work-order-note">
                                {{note.createdDate | date: 'shortDate'}} {{note.createdDate | date: 'shortTime'}} -
                                <span [innerHtml]="note.noteText"></span>
                            </div>
                        </div>
                    </mat-card>
                    <mat-card flex1 id="authoCard">
                        <div class="sub-heading">
                            <i class="material-icons">gavel</i> Authorization Information
                        </div>
                        <div topmargin30 style="max-width: 350px;">
                            <ng-container *ngIf="jobItems && jobItems.length > 1">
                                <div aflex class="authorization-line" *ngFor="let line of jobItems">
                                    <div>{{line.itemName}}</div>
                                    <div nogrow>
                                        {{line.authorizationLimit | currency: 'USD':true:'1.0-0'}}
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                        <h1 class="really-big">{{workOrderSummary.authorizationLimit | currency:'USD':true:'1.0-0'}}
                        </h1>

                        <div class="data-label" bottommargin30 style="text-align: center">Authorization Limit</div>

                        <div class="center" bottommargin30 style="text-align: center">{{preExistingConditions}}</div>

                        <div class="center" *ngIf="workOrderSummary.tripFeeAllowance">
                            + {{workOrderSummary.tripFeeAllowance | currency}} Trip Fee
                        </div>
                        <!-- <div *ngFor="let line of jobItems">
                            {{line.authorizationLimit | currency: 'USD':true:'1.0-0'}} - {{line.itemName}}
                        </div> -->
                        <div *ngIf="companyInfo && !authoEnabled">
                            <mat-icon>phone</mat-icon> Call For Authorization:<br>
                            <a href="tel:{{companyInfo.officeNumber}}">{{companyInfo.officeNumber |
                                formatPhoneNumber}}</a>
                        </div>
                        <div style="height: 36px;">
                            <button mat-button pull-right *ngIf="!workOrderSummary.cancelledDate && authoEnabled "
                                (click)="createAutho()">Get
                                Authorization</button>
                        </div>
                    </mat-card>
                </div>
                <div flex style="max-width: 1200px">
                    <mat-card flex1 style="max-width: 1200px; " bottommargin20>
                        <div class="sub-heading">
                            <i class="material-icons">sentiment_neutral</i> Survey
                        </div>

                        <ng-container *ngIf="survey">
                            <div flex>
                                <div>
                                    <div class="star-ratings-sprite">
                                        <span id="rating" [style.width]="(survey.rating / 5 * 100) + '%'"
                                            class="star-ratings-sprite-rating"></span>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="survey.comments">
                                <div class="conversation-bubble incoming">{{survey.comments}}</div>
                                <div class="said"> ~ {{survey.customerName}} {{survey.createdDate | date: 'shortDate'}}
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!survey">
                            <div class="center" topmargin40 style="margin-bottom: 40px;">
                                No survey yet.
                            </div>
                        </ng-container>
                    </mat-card>
                    <mat-card flex1>
                        <div class="sub-heading">
                            <i class="material-icons">event</i> Appointment
                        </div>

                        <ng-container *ngIf="workOrderSummary.scheduledDate">
                            <div class="center">
                                <h1 class="big-data">
                                    {{workOrderSummary.scheduledDate | date: 'shortDate'}}
                                </h1>
                                <div class="data-label">
                                    {{workOrderSummary.scheduledStartWindow}} - {{workOrderSummary.scheduledEndWindow}}
                                </div>
                            </div>
                        </ng-container>
                        <div aflex>
                            <div></div>
                            <div nogrow>
                                <button mat-button (click)="selectedIndex = 1">
                                    <mat-icon>edit</mat-icon>
                                    Change
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>



            </mat-tab>

            <mat-tab label="Appointment">

                <h1 class="page-title"><span class="big"> Schedule</span><br> <span class="little">Appointment</span>
                </h1>
                <div style="min-height: 550px;max-width: 950px">

                    <mat-card *ngIf="workOrderSummary.formattedAppointmentDate" bottommargin20>
                        There is an appointment scheduled for {{workOrderSummary.formattedAppointmentDate}}
                        {{workOrderSummary.scheduledStartWindow}}-{{workOrderSummary.scheduledEndWindow}}

                        <!-- <button mat-button [disabled]="resendingAppointmentEmail"
                            (click)="resendAppointmentNotification()">
                            <mat-spinner *ngIf="resendingAppointmentEmail" class="thirty"></mat-spinner>
                            Resend Appointment Notification
                        </button> -->
                    </mat-card>

                    <mat-card>
                        <div flex>
                            <div nogrow>

                                <mat-form-field appearance="outline">
                                    <mat-label>Appointment Date</mat-label>
                                    <input matInput [matDatepicker]="picker" [(ngModel)]="apptDate"
                                        [min]="appointmentMin" [max]="appointmentMax">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div>
                                <button *ngFor="let date of preSelectDates" (click)="setDate(date)"
                                    [class.selected]="date.date.getDate() == apptDate.getDate() && date.date.getMonth() == apptDate.getMonth() && date.date.getFullYear() == apptDate.getFullYear()"
                                    mat-button class="circular-button">
                                    <i class="material-icons">event</i>
                                    <div class="button-header">{{date.heading}}</div>
                                    <div>{{date.dayOfWeek}}</div>
                                </button>
                            </div>
                        </div>

                        <div topmargin40>
                            <div topmargin20 flex>
                                <div nogrow>
                                    <!-- <mat-form-field appearance="outline">
                                        <mat-label>Start Window</mat-label>
                                        <mat-select [(ngModel)]="startWindow">
                                            <mat-option value="5 AM">5 AM</mat-option>
                                            <mat-option value="6 AM">6 AM</mat-option>
                                            <mat-option value="7 AM">7 AM</mat-option>
                                            <mat-option value="8 AM">8 AM</mat-option>
                                            <mat-option value="9 AM">9 AM</mat-option>
                                            <mat-option value="10 AM">10 AM</mat-option>
                                            <mat-option value="11 AM">11 AM</mat-option>
                                            <mat-option value="12 PM">12 PM</mat-option>
                                            <mat-option value="1 PM">1 PM</mat-option>
                                            <mat-option value="2 PM">2 PM</mat-option>
                                            <mat-option value="3 PM">3 PM</mat-option>
                                            <mat-option value="4 PM">4 PM</mat-option>
                                            <mat-option value="5 PM">5 PM</mat-option>
                                            <mat-option value="6 PM">6 PM</mat-option>
                                            <mat-option value="7 PM">7 PM</mat-option>
                                            <mat-option value="8 PM">8 PM</mat-option>
                                            <mat-option value="9 PM">9 PM</mat-option>
                                            <mat-option value="10 PM">10 PM</mat-option>
                                            <mat-option value="11 PM">11 PM</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->

                                    <div class="data-label">Start Time</div>
                                    <app-time-picker [(selectedTime)]="appointmentStartTime"></app-time-picker>
                                </div>
                                <div nogrow id="end-time-container">
                                    <!-- <mat-form-field appearance="outline">
                                        <mat-label>End Window</mat-label>
                                        <mat-select [(ngModel)]="endWindow">
                                            <mat-option value="5 AM">5 AM</mat-option>
                                            <mat-option value="6 AM">6 AM</mat-option>
                                            <mat-option value="7 AM">7 AM</mat-option>
                                            <mat-option value="8 AM">8 AM</mat-option>
                                            <mat-option value="9 AM">9 AM</mat-option>
                                            <mat-option value="10 AM">10 AM</mat-option>
                                            <mat-option value="11 AM">11 AM</mat-option>
                                            <mat-option value="12 PM">12 PM</mat-option>
                                            <mat-option value="1 PM">1 PM</mat-option>
                                            <mat-option value="2 PM">2 PM</mat-option>
                                            <mat-option value="3 PM">3 PM</mat-option>
                                            <mat-option value="4 PM">4 PM</mat-option>
                                            <mat-option value="5 PM">5 PM</mat-option>
                                            <mat-option value="6 PM">6 PM</mat-option>
                                            <mat-option value="7 PM">7 PM</mat-option>
                                            <mat-option value="8 PM">8 PM</mat-option>
                                            <mat-option value="9 PM">9 PM</mat-option>
                                            <mat-option value="10 PM">10 PM</mat-option>
                                            <mat-option value="11 PM">11 PM</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->

                                    <div class="data-label">End Time</div>
                                    <app-time-picker [(selectedTime)]="appointmentEndTime"></app-time-picker>

                                </div>
                                <div>
                                    <div class="data-label">Appointment</div>
                                    <h2 class="sub-heading">
                                        {{apptDate | date: 'shortDate'}}
                                        {{appointmentStartTime | date: 'shortTime'}} - {{appointmentEndTime | date:
                                        'shortTime'}}

                                    </h2>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <div *ngIf="showDispatchWarning" style="color: #ec7c23; margin-top:10px; text-align: right;">
                        Please use <a style="text-decoration: underline;" href="{{dispatchUrl}}">Dispatch</a> to schedule the appointment.
                    </div>
                    <div topmargin20>
                        <button mat-button (click)="back()">
                            <i class="material-icons">keyboard_arrow_left</i> Back</button>
                        <button mat-button mat-raised-button large color="primary"
                            [disabled]="savingAppointment || !canSaveAppointment" pull-right
                            (click)="saveAppointment()">
                            <mat-spinner *ngIf="savingAppointment" class="thirty"></mat-spinner>
                            Schedule
                        </button>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Dispatch">

                <h1 class="page-title"><span class="big"> Assign</span><br> <span class="little">Technician</span>
                </h1>


                <div style="max-width: 700px">
                    <mat-card style="margin-bottom: 20px">
                        <!-- <div>
                            <mat-checkbox [(ngModel)]="sendNotificationToCustomer">Send on my way notification to
                                Homeowner
                            </mat-checkbox>
                        </div> -->
                        <mat-form-field appearance="outline">
                            <mat-label>Technician</mat-label>
                            <mat-select (ngModelChange)="updateTechApptDisplay()" [(ngModel)]="technicianId">
                                <mat-option *ngFor="let tech of technicians" [value]="tech.id">
                                    <img mat-list-avatar class="avatar" [src]="getTechThumbnail(tech)">
                                    {{ tech.displayName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card>

                    <div aflex>
                        <div>
                            <button mat-button (click)="back()">
                                <i class="material-icons">keyboard_arrow_left</i> Back</button>
                        </div>
                        <div nogrow>
                            <button mat-button large mat-raised-button [disabled]="dispatching"
                                (click)="sendDispatchNow()" color="primary">
                                <mat-spinner thirty *ngIf="dispatching"></mat-spinner>
                                Assign
                            </button>
                        </div>
                    </div>
                </div>



                <div style="margin-top: 30px" class="schedule-view-container">
                    <mat-card>

                        <h3 class="sub-heading">Appointment {{workOrderSummary.scheduledDate | date: 'shortDate'}}
                            {{workOrderSummary.scheduledStartWindow}}-{{workOrderSummary.scheduledEndWindow}}</h3>
                        <app-schedule-view #scheduleView [highlightedAppointment]="workOrderSummary"
                            [technicians]="technicians" [workOrders]="daysSchedule"></app-schedule-view>

                    </mat-card>
                </div>
            </mat-tab>

            <mat-tab label="Autho">
                <h1 class="page-title">
                    <span class="big">Authorize</span><br>
                    <span class="little">Service Request</span>

                </h1>
                <div>


                </div>
                <div>
                    <button mat-button (click)="back()">
                        <i class="material-icons">keyboard_arrow_left</i> Back</button>
                </div>
            </mat-tab>

            <mat-tab label="Complete Job">

                <div>
                    <h1 class="page-title">
                        <span class="big"> Complete</span><br>
                        <span class="little">Service Request</span>
                    </h1>
                </div>

                <div style="max-width: 700px">
                    <mat-card id="completeJobCard">

                        <div aflex>
                            <div nogrow>
                                <i class="material-icons page-icon">done</i>
                            </div>
                            <div>
                                <mat-form-field class="no-fixed" appearance="outline">
                                    <mat-label>Resolution</mat-label>
                                    <textarea matInput rows="5" [(ngModel)]="resolution"></textarea>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>Completion Date</mat-label>
                                    <input matInput [matDatepicker]="picker1" [max]="maxDate"
                                        [(ngModel)]="completedDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>



                            </div>
                        </div>

                    </mat-card>

                    <div aflex topmargin20>
                        <div>
                            <button mat-button (click)="back()">
                                <i class="material-icons">keyboard_arrow_left</i> Back</button>
                        </div>
                        <div *ngIf="showDispatchWarning && !completedDate" style="color: #ec7c23; margin-top:10px; text-align: right;">
                            Please use <a style="text-decoration: underline;" href="{{dispatchUrl}}">Dispatch</a> to complete the job.
                        </div>
                        <div nogrow>
                            <button mat-button mat-raised-button large pull-right color="primary"
                                (click)="completeWorkOrder()"
                                [disabled]="!canCompleteWorkOrder || completingWorkOrder">Complete</button>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Invoice" *ngIf="invoice">


                <ng-container *ngIf="contractorInvoices && contractorInvoices.length > 0">
                    <h1 class="page-title" *ngIf="!showNewInvoiceEntry"><span class="big">Job</span><br><span
                            class="little">Invoices</span></h1>

                    <div style="max-width: 800px;">
                        <mat-card *ngIf="!showNewInvoiceEntry">
                            <div class="show-xs">
                                <div class="invoice-item" *ngFor="let invoice of contractorInvoices">
                                    <h2>{{invoice.contractorInvoiceNumber}}</h2>
                                    <div aflex>
                                        <div>{{invoice.createdDate | date: 'shortDate'}}</div>
                                        <div>{{invoice.amount | currency}}</div>
                                    </div>
                                    <button mat-button block (click)="viewStatement(invoice)"
                                        *ngIf="invoice.contractorInvoicePaymentId">
                                        <mat-icon>description</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <table class="table" class="hide-xs">
                                <thead>
                                    <tr>
                                        <th>Invoice #</th>
                                        <th>Date</th>
                                        <th class="right">Parts</th>
                                        <th class="right">Labor</th>
                                        <th class="right">Tax</th>
                                        <th class="right">Total</th>
                                        <th>Statement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoice of contractorInvoices">
                                        <td>{{invoice.contractorInvoiceNumber}}</td>
                                        <td>{{invoice.createdDate | date: 'shortDate'}}</td>
                                        <td class="right">{{invoice.partsCost | currency}}</td>
                                        <td class="right">{{invoice.laborCost | currency}}</td>
                                        <td class="right">{{invoice.tax | currency}}</td>
                                        <td class="right">{{invoice.amount | currency}}</td>
                                        <td>
                                            <button mat-button (click)="viewStatement(invoice)"
                                                *ngIf="invoice.contractorInvoicePaymentId">
                                                <mat-icon>description</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card>
                        <div class="center" topmargin20>
                            <button mat-button (click)="back()">
                                <i class="material-icons">keyboard_arrow_left</i> Back</button>
                            <!-- <button mat-button (click)="showNewInvoiceEntry = !showNewInvoiceEntry">
                                <mat-icon *ngIf="!showNewInvoiceEntry">add</mat-icon>
                                <mat-icon *ngIf="showNewInvoiceEntry">close</mat-icon>
                                <span *ngIf="showNewInvoiceEntry">Hide</span>
                                <span *ngIf="!showNewInvoiceEntry">Create New Invoice</span>
                            </button> -->
                        </div>
                    </div>



                </ng-container>

                <ng-container *ngIf="(contractorInvoices && contractorInvoices.length === 0) || showNewInvoiceEntry">
                    <h1 class="page-title"><span class="big"> Create</span><br> <span class="little">Invoice</span></h1>
                    <div style="max-width: 800px">
                        <mat-card>
                            <div class="data-label">Payable To</div>
                            <div style="font-size: 24px; color: rgb(200,200,200)">{{workOrderSummary.contractorName}}
                            </div>
                            <div flex topmargin30>
                                <div>
                                    <div class="data-label">Received Date</div>
                                    {{invoice.receivedDate | date: 'shortDate'}}
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Job Completion Date</mat-label>
                                        <input matInput [min]="workOrderSummary.createdDate"
                                            [matDatepicker]="completionDatePicker" [(ngModel)]="completedDate">
                                        <mat-datepicker-toggle matSuffix [for]="completionDatePicker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #completionDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Invoice #</mat-label>
                                        <input matInput [(ngModel)]="invoice.contractorInvoiceNumber">
                                    </mat-form-field>
                                </div>
                            </div>

                            <div flex topmargin30>
                                <div>
                                    <mat-form-field class="no-fixed" appearance="outline">
                                        <mat-label>Work Performed</mat-label>
                                        <textarea rows="6" matInput [(ngModel)]="invoice.workPerformed"></textarea>
                                    </mat-form-field>
                                    <ng-container *ngIf="jobItemsCopy && jobItemsCopy.length > 1">
                                        <mat-checkbox style="margin-right: 20px" *ngFor="let line of jobItemsCopy"
                                            [(ngModel)]="line.selected">
                                            {{line.itemName}}</mat-checkbox>
                                    </ng-container>
                                </div>
                                <div nogrow style="width: 200px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Parts Cost</mat-label>
                                        <input matInput type="number" [(ngModel)]="invoice.partsCost">
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Labor Cost</mat-label>
                                        <input matInput type="number" [(ngModel)]="invoice.laborCost">
                                    </mat-form-field>


                                    <mat-form-field appearance="outline">
                                        <mat-label>Tax</mat-label>
                                        <input matInput type="number" [(ngModel)]="invoice.tax">
                                    </mat-form-field>

                                    <!-- <div id="invoice-total">
                                        <div class="data-label">Total</div>
                                        <h1>
                                            {{invoice.totalAmount | currency}}
                                        </h1>
                                    </div> -->
                                </div>
                            </div>
                            <div aflex>
                                <div></div>
                                <div nogrow>
                                    <app-display-total [total]="invoice.totalAmount" label="Invoice Total">
                                    </app-display-total>
                                </div>
                            </div>

                        </mat-card>

                        <div aflex topmargin20>
                            <div>
                                <button mat-button (click)="back()">
                                    <i class="material-icons">keyboard_arrow_left</i> Back</button>
                            </div>
                            <div nogrow>
                                <button mat-button large mat-raised-button color="primary" (click)="saveInvoice()"
                                    [disabled]="!invoice.canSave || savingInvoice || !completedDate || !jobItemsSelected">
                                    <mat-spinner *ngIf="savingInvoice" class="thirty"></mat-spinner>
                                    Save Invoice
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-tab>

            <mat-tab label="Status">

                <h1 class="page-title"><span class="big"> Update</span><br> <span class="little">Status</span></h1>
                <div style="max-width: 700px">
                    <mat-card>

                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <mat-select style="min-width: 300px" [(ngModel)]="newStatus">
                                <mat-option value="36406607-89CB-4760-ABB2-79095FA7E28A">Ordering Parts</mat-option>
                                <mat-option value="E0B5402C-9D34-4B15-B419-C72464E3B220">Back Ordered Parts Delay
                                </mat-option>
                                <mat-option value="C57D321C-17EF-40C1-A70C-EED534C8AC6A">Customer No Show</mat-option>
                                <mat-option value="C3E32787-4886-40BC-BE8D-DE829DB7FDF1">Authorization Requested
                                </mat-option>
                                <mat-option value="89225872-1323-41E8-85FC-50DAB7601F47">Parts Research</mat-option>
                                <mat-option value="47A5716F-DB12-4D75-997A-F62697867567">Awaiting Customer Approval
                                </mat-option>
                                <mat-option value="5D1157A4-E026-4F06-86D1-EB62DC89919E">Waiting For
                                    Customer On Scheduling</mat-option>
                                <mat-option value="COMPLETE">Complete</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card>


                    <div topmargin20>
                        <button mat-button (click)="back()">
                            <i class="material-icons">keyboard_arrow_left</i> Back</button>
                        <button mat-button large mat-raised-button color="primary"
                            [disabled]="!newStatus || updatingStatus" pull-right (click)="saveNewStatus()">
                            <mat-spinner *ngIf="updatingStatus" class="thirty"></mat-spinner>
                            Update Status
                        </button>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Coverage & Notes">
                <h1 class="page-title"><span class="big"> Coverage</span><br> <span class="little">Language</span></h1>
                <div *ngFor="let jobItem of jobItems">

                    <mat-card *ngIf="jobItem.contractLanguage">
                        <h2>{{jobItem.itemName}}</h2>


                        <div class="data-label">Covered</div>
                        <div [innerHTML]="jobItem.contractLanguage.coveredLanguage"></div>
                        <div class="data-label" topmargin30>Not Covered</div>
                        <div [innerHTML]="jobItem.contractLanguage.notCoveredLanguage"></div>
                        <div class="data-label" topmargin30>Limits</div>
                        <div [innerHTML]="jobItem.contractLanguage.limitsLanguage"></div>
                    </mat-card>
                </div>

                <h1 class="page-title"><span class="big"> Job</span><br> <span class="little">Notes</span></h1>
                <mat-card>
                    {{workOrderSummary.additionalNotes}}
                </mat-card>


                <div aflex topmargin20>
                    <div>
                        <button mat-button (click)="back()">
                            <i class="material-icons">keyboard_arrow_left</i> Back</button>
                    </div>
                    <div nogrow>

                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

    <button color="primary" class="add-item-button" mat-raised-button mat-button [matMenuTriggerFor]="addMenu">
        <mat-icon>add</mat-icon>
    </button>
    <mat-menu #addMenu="matMenu">
        <button (click)="addNote()" mat-menu-item>
            <mat-icon>description</mat-icon>
            <span>Note</span>
        </button>
        <button mat-menu-item (click)="showFileUpload()">
            <mat-icon>image</mat-icon>
            <span>Picture</span>
        </button>
    </mat-menu>
</div>




<div id="object-detail-panel" *ngIf="selectedObject">
    <div aflex>
        <div nogrow>
            <button mat-button (click)="selectedObject = null" class="close-detail-button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div style="overflow-x: hidden;overflow-y: auto;height: calc(100vh - 51px);margin-left: 0;padding: 10px">


            <div class="center" bottommargin30>
                <img [src]="getItemThumbnailUrl(selectedObject)" style="height: 150px;"><br>
                <h1>{{selectedObject.itemName}}</h1>
            </div>

            <mat-tab-group class="condensed-labels">
                <mat-tab label="Questionnaire">
                    <div *ngFor="let question of selectedObject.questionsAndAnswers">
                        <div *ngIf="question.text != 'Complete' && question.answer">
                            <h3 class="question" *ngIf="question.text != 'Complete'"><span *ngIf="question.answer">Q:
                                </span><span [innerHTML]="question.text"></span></h3>
                            <p class="answer" *ngIf="question.answer">A: {{question.answer}}</p>
                        </div>
                    </div>
                    <!-- <mat-accordion multi>
                        <mat-expansion-panel class="mat-elevation-z" [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Service Wizard Questions
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-container *ngIf="selectedObject.questionsAndAnswers">
                                <div *ngFor="let question of selectedObject.questionsAndAnswers">
                                    <div *ngIf="question.text != 'Complete' && question.answer">
                                        <h3 class="question" *ngIf="question.text != 'Complete'"><span
                                                *ngIf="question.answer">Q:
                                            </span><span [innerHTML]="question.text"></span></h3>
                                        <p class="answer" *ngIf="question.answer">A: {{question.answer}}</p>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-expansion-panel>
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Authorization Questions
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="selectedObject.authoQuestionsAndAnswers">
                                <div *ngFor="let question of selectedObject.authoQuestionsAndAnswers">
                                    <div *ngIf="question.text != 'Complete'">
                                        <h3 class="question" *ngIf="question.text != 'Complete'"><span
                                                *ngIf="question.answer">Q:
                                            </span><span [innerHTML]="question.text"></span></h3>
                                        <p class="answer" *ngIf="question.answer">A: {{question.answer}}</p>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion> -->
                </mat-tab>
                <mat-tab label="Contract">

                    <ng-container *ngIf="details">
                        <div class="data-label">Covered</div>
                        <div [innerHTML]="details.coveredLanguage" class="no-table-borders"></div>
                        <div class="data-label" topmargin30>Not Covered</div>
                        <div [innerHTML]="details.notCoveredLanguage" class="no-table-borders"></div>
                        <div class="data-label" topmargin30>Limits</div>
                        <div [innerHTML]="details.limitsLanguage" class="no-table-borders"></div>
                    </ng-container>
                </mat-tab>

                <mat-tab label="Status">
                    <div class="table">
                        <div class="table-header">
                            <div>Date</div>
                            <div>Old Status</div>
                            <div>New Status</div>
                        </div>
                        <div class="table-row" *ngFor="let statusChange of statusChanges">
                            <div>{{statusChange.createdDate | date: 'short'}}</div>
                            <div>{{statusChange.oldStatus}}</div>
                            <div>{{statusChange.newStatus}}</div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>


        </div>

    </div>
</div>