import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ContractorProposedWorkOrder {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() workOrderItemId: string;
    @column() workOrderId: string;
    @column() approvedDate: Date;
    @column() approvedById: string;
    @column() rejectedDate: Date;
    @column() rejectedById: string;
    @column() qandA: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() additionalNotes: string;
    @column() contractorId: string;
}


@dataSource()
export class ContractorProposedWorkOrderSummary {
    @keyColumn() id: string;
    @column() policyId: string;
    @column() maintenanceServiceCustomerPropertyServiceId: string;
    @column() workOrderItemId: string;
    @column() workOrderId: string;
    @column() approvedDate: Date;
    @column() approvedById: string;
    @column() rejectedDate: Date;
    @column() rejectedById: string;
    @column() qandA: string;
    @column() createdDate: Date;
    @column() policyNumber: bigint;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() customerName: string;
    @column() customerEmail: string;
    @column() workOrderItemName: string;
    @column() customerHomeNumber: string;
    @column() customerMobileNumber: string;
    @column() maintenanceServiceName: string;
    @column() stripeId: string;
    @column() customerId: string;
    @column() contractorId: string;
    @column() contractorName: string;
    @column() maintenanceServiceContractorId: string;
    @column() additionalNotes: string;
}