import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ContractorsService } from '@cogent/client/shared/services/api/contractors.service';
// import { ContractorInvoice } from '@upkeeplabs/service-pros/app/model/accounting/contractor-invoice.model';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { ContractorInvoice } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-bill-individual-work-order',
    templateUrl: './bill-individual-work-order.component.html',
    styleUrls: ['./bill-individual-work-order.component.css']
})
export class BillIndividualWorkOrderComponent implements OnInit, AfterViewInit {

    selectedIndex = 0;
    workOrderSummary: WorkOrderSummaryClient;
    notFound = false;
    @Output() contractorInvoiceSaved: EventEmitter<ContractorInvoice> = new EventEmitter();

    invoice: ContractorInvoice = new ContractorInvoice();
    savingInvoice = false;
    completedDate: Date = new Date();
    jobItems: WorkOrderLineSummaryClient[];

    constructor(
        private contractorRepository: ContractorsService,
        private serviceApi: ServiceApiService,
        private dialogService: DialogsService,
        private entityApi: EntityApiService
        // private userSession: UserSessionService
    ) {
        this.invoice.receivedDate = new Date();
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.setIntialFocus();
    }

    private setIntialFocus() {
        if (!document.getElementById('work-order-number')) {
            setTimeout(() => this.setIntialFocus(), 100);
            return;
        }

        document.getElementById('work-order-number').focus();
    }

    findWorkOrder(theForm) {
        this.notFound = false;
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorRepository.getWorkOrderSummaryByWorkOrderIdAndContractorId(theForm.value.workOrderNumber, user.id).then(summary => {
                if (summary && summary.length > 0) {
                    this.workOrderSummary = summary[0];
                    // this.contractorRepository.getWorkOrderContractorInvoice(this.workOrderSummary.id).then(invoices => {
                    //     if (invoices.length > 0) {
                    //         this.invoice = invoices[0];
                    //     }
                    // });
                    this.serviceApi.getWorkOrderLines(this.workOrderSummary.id).then(lines => {
                        this.jobItems = lines;
                        for (const item of this.jobItems) {
                            item.selected = true;
                        }
                    });
                    this.selectedIndex = 1;
                } else {
                    this.notFound = true;
                }
            });
        });
    }

    async saveInvoice() {
        const invoices = await this.contractorRepository.getWorkOrderContractorInvoice(this.workOrderSummary.id);
        if (invoices && invoices.length > 0) {
            this.dialogService.confirm('Confirm', 'There has already been an invoice submitted for this job.<br><br>Are you sure you want to continue?').subscribe(results => {
                if (results) {
                    this.doSave();
                }
            });
            return;
        }
        this.doSave();
    }

    private async doSave() {
        this.savingInvoice = true;
        this.invoice.workOrderId = this.workOrderSummary.id;
        this.invoice.appliedItemsJson = JSON.stringify(this.jobItems.filter(i => i.selected).map(i => i.id));
        await this.contractorRepository.saveInvoice(this.invoice);
        await this.contractorRepository.updateWorkOrder(this.workOrderSummary.id, { dateCompleted: this.completedDate });

        this.savingInvoice = false;
        this.selectedIndex = 0;
        this.contractorInvoiceSaved.emit(this.invoice);
    }


    get authoAmount() {
        if (!this.jobItems || this.jobItems.filter(i => i.selected).length === 0) {
            return 0;
        }

        return this.jobItems.filter(i => i.selected).map(i => i.authorizationApprovedDate || i.authorizationDeniedDate ? i.authorizationAmount : i.authorizationLimit).reduce((a, b) => a + b);
    }

}
