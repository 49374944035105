import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class RenewalGoal {
    @keyColumn() id: string;
    @column() date: Date;
    @column() coverageType: string;
    @column() goalPercent: number;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    // Client-side only
    isDirty = false;
}