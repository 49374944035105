import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SettingsApiService } from '@cogent/client/shared/services/api/settings-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { ObjectQueuesApiService } from '@cogent/client/shared/services/api/object-queues-api.service';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import { ContractorSwimLane, LineGrouping } from '@cogent/client/shared/models/service/contractor-swim-lane.model';
import { QueueAction, QueueActionType } from '@cogent/client/shared/models/object-queue.model';
import { ApiService } from '@cogent/client/api';
import { CommandRunnerService } from '@cogent/client/shared/services/command-runner.service';
import { SearchResultModel} from '@cogent/shared/models/common/search-result.model';
import { Router } from '@angular/router';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";


class JobItem {
    dateComplete: Date;
    id: string;
    itemName: string;
    number: number;
    propertyAddress: string;
    slaStatus: string;
    status: string;
    tradeId: string;
    itemId: string;
}


@Component({
    selector: 'app-jobs-board',
    templateUrl: './jobs-board.component.html',
    styleUrls: ['./jobs-board.component.css']
})
export class JobsBoardComponent implements OnInit, AfterViewInit {
    searchField: UntypedFormControl = new UntypedFormControl();
    loading = false;
    selectedItem: JobItem;
    selectedDetailIndex = 0;
    showAutho = false;
    swimLanes: ContractorSwimLane[];
    working: boolean;
    workingMessage: string;

    questionnaires: any;

    constructor(
        private entityApi: EntityApiService,
        private settingsApi: SettingsApiService,
        private serviceApi: ServiceApiService,
        private objectQueuesApi: ObjectQueuesApiService,
        private router: Router,
        private commandRunner: CommandRunnerService,
    ) { }

    ngOnInit() {
        this.loadBoard();
    }

    ngAfterViewInit() {
        this.searchField.valueChanges
            .pipe(debounceTime(1000))

            .subscribe(term => {
                this.filterItems(term);
            });
    }

    get headerContainerWidth() {
        const scrollContainer = document.getElementById('lane-container');
        if (!scrollContainer) {
            return;
        }

        return scrollContainer.scrollHeight > scrollContainer.clientHeight ? 'calc(100% - 16px)' : '100%';
    }

    async refreshSwimLaneItems(swimLane: ContractorSwimLane) {
        const contractor = await this.entityApi.getLoggedInUser(false);

        const searchResult = new SearchResultModel();
        searchResult.id = contractor.id;
        swimLane.queueQuery.selectedContractors = [searchResult];
        let fields: string[] = [];
        if (swimLane.queueQuery.resultsFields) {
            fields = swimLane.queueQuery.resultsFields.filter(i => !i.dependentFields || i.dependentFields.length === 0).map(i => i.name);
        }

        if (swimLane.queueQuery.groupByField && fields.indexOf(swimLane.queueQuery.groupByField) === -1) {
            fields.push(swimLane.queueQuery.groupByField);
        }

        if (!swimLane.queueQuery.resultsFields) {
            swimLane.queueQuery.resultsFields = [{ name: 'id', description: 'Task Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false } as any];
        }
        swimLane.queueQuery.resultsFields.forEach(field => {
            if (field.dependentFields) {
                field.dependentFields.forEach(dep => {
                    if (fields.indexOf(dep) === -1) {
                        fields.push(dep);
                    }
                });
            }
        });

        if (swimLane.queueQuery.showSLAIndicator) {
            fields.push('SLAStatus');

        }

        if (fields.indexOf('itemName') === -1) {
            fields.push('itemName');
        }
        if (fields.indexOf('propertyAddress') === -1) {
            fields.push('propertyAddress');
        }

        // if (swimLane.queueQuery.sortByField === 'number') {
        //     swimLane.queueQuery.sortByField = 'workOrderNumber'
        // }
        // if (fields.indexOf('number') > -1) {
        //     fields.splice(fields.indexOf('number', 1));
        //     fields.push('workOrderNumber');
        // }
        if (fields.indexOf('workOrderId') === -1) {
            fields.push('workOrderId');
        }

        // swimLane.items = await this.serviceApi.getWorkOrderQueue(swimLane.queueQuery, fields, 0);
        const items = await this.objectQueuesApi.getQueueObjects('WorkOrderLine', swimLane.queueQuery, fields, () => new WorkOrderLineSummaryClient(), 0, 500);
        swimLane.setItems(items);
        swimLane.filteredItems = swimLane.items;
    }

    selectItem(item: any, showAutho = false) {
        this.selectedDetailIndex = 0;
        this.showAutho = showAutho;
        const jobItem = new JobItem();
        jobItem.id = item.workOrderId;
        this.selectedItem = jobItem;
    }

    selectItemFromGroup(group: LineGrouping) {
        const jobItem = new JobItem();
        jobItem.id = group.workOrderId;
        this.selectedItem = jobItem;
    }

    navigateToJob(group: LineGrouping) {
        this.router.navigateByUrl(`/work-order-detail/${group.workOrderId}`);
    }

    authorizationComplete() {
        this.selectedItem = null;
        this.loadBoard();
    }

    filterItems(searchFor: string) {
        if (!this.swimLanes) {
            return;
        }

        if (!searchFor) {
            for (const swimLane of this.swimLanes) {
                swimLane.filteredItems = swimLane.items;
                swimLane.groupings = LineGrouping.fromItems(swimLane.filteredItems);
            }
        } else {
            searchFor = searchFor.toLocaleLowerCase();
            for (const swimLane of this.swimLanes) {
                swimLane.filteredItems = swimLane.items.filter(i => i.number.toString() === searchFor
                    || i.propertyAddress.toLowerCase().indexOf(searchFor) > -1
                    || i.itemName.toLowerCase().indexOf(searchFor) > -1
                    || (i.sLAStatus && i.sLAStatus.toLowerCase() === searchFor));
                swimLane.groupings = LineGrouping.fromItems(swimLane.filteredItems);
            }
        }
    }

    async loadBoard() {
        this.loading = true;
        this.settingsApi.getContractorSwimLanes().then(swimLanes => {

            for (const swimLane of swimLanes) {
                swimLane.items = [];
                this.refreshSwimLaneItems(swimLane);
            }
            this.swimLanes = swimLanes;
        });

        this.filterItems(null);
        this.loading = false;
    }

    detailPageRefreshed() {

    }

    getImageUrl(item: JobItem) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${item.itemId}/Photo`;
    }

    getUICommands(swimLane: ContractorSwimLane) {
        if (!swimLane.queueQuery.commands) {
            return null;
        }
        return swimLane.queueQuery.commands.filter(i => !i.hideFromUI);
    }

    async startDoCommand(actions: QueueAction[], source: any, swimLane: ContractorSwimLane) {
        this.questionnaires = {};
        const workOrder: WorkOrderLineSummaryClient = source;

        this.commandRunner.startDoCommand(actions, null, workOrder as any, null, swimLane.queueQuery, () => this.refreshSwimLaneItems(swimLane), () => this.loadBoard(), (show, message) => {
            this.working = show;
            this.workingMessage = message;
        }, null, null as any, null, null, () => { }, null, null, null, 'Service');
    }

    drop(event: CdkDragDrop<ContractorSwimLane>) {
        let items = [];

        const queue = event.container.data;
        const previousQueue = event.previousContainer.data;
        if (event.container === event.previousContainer) {
            return;
        }

        if (event.item.data.items) {
            // This is a grouping
            items = event.item.data.items;
        } else {
            items = [event.item.data];
        }



        const workOrder: WorkOrderLineSummaryClient = items[0];
        const swimLane: ContractorSwimLane = event.container.data;
        if (workOrder && swimLane && swimLane.items.indexOf(workOrder) > -1) {
            return;
        }

        if (swimLane.queueQuery.defaultCommandId && swimLane.queueQuery.commands) {
            const dropCommand = swimLane.queueQuery.commands.find(i => i.id === swimLane.queueQuery.defaultCommandId);
            if (dropCommand) {
                if (dropCommand.actions && dropCommand.actions.length === 1 && dropCommand.actions[0].queueAction === QueueActionType.NavigateToUrl
                    && dropCommand.actions[0].restEndpointUrl === 'work-order-detail/{{id}}') {
                    this.selectItem(workOrder as any, false);
                    return;
                }
                if (dropCommand.actions && dropCommand.actions.length === 1 && dropCommand.actions[0].queueAction === QueueActionType.NavigateToUrl
                    && dropCommand.actions[0].restEndpointUrl === '/authorize/{{id}}') {
                    this.selectItem(workOrder as any, true);
                    return;
                }

                this.commandRunner.startDoCommand(dropCommand.actions, null, null, null, swimLane.queueQuery, () => {
                    this.refreshSwimLaneItems(swimLane);
                }, () => {
                    this.loadBoard();
                }, (show, message) => {
                    this.working = show;
                    this.workingMessage = message;
                }, null, null, null, null, (dataItem) => {
                    previousQueue.items.splice(previousQueue.items.indexOf(dataItem), 1);
                    if (!queue.items) {
                        queue.items = [];
                    }
                    queue.items.push(dataItem);
                    queue.groupings = LineGrouping.fromItems(queue.items);
                    previousQueue.groupings = LineGrouping.fromItems(previousQueue.items);

                }, null, null, items, 'Service', () => { }, null);
            }

        }
    }
}
