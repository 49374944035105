import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class ARBalancesByAccountingArea {
    @column() balance: number;
    @column() type: string;
    @column() date: Date;
    @column() areaId: string;
    @column() areaName: string;
    @column() aRAccountId: number;
    @column() revenueAccountId: number;
}