import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class SmsSummary {
    @keyColumn() id: string;
    @column() from: string;
    @column() fromNumber: string;
    @column() entityId: string;
    @column() correspondent: string;
    @column() messageDate?: Date;
    @column() messageText: string;
    @column() policyId: string;
    @column() isArchived: boolean;

    numberName: string;
    holderId: string;
    isFirst = false;
    isChecked = false;

    get fromName() {
        if (this.from === 'none') return 'automated';
        return this.from;
    }
    get name() {
        if (this.numberName) return this.numberName;
        return this.correspondent;
    }

    get isFromElevate() {
        return Number(this.from) ? false : true;
    }

    get align() {
        if (this.isFromElevate) return 'text-right';
        return 'text-left';
    }

    get IsNone() {
        if (this.from === 'none') return true;
        return false;
    }
}