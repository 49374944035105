import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { ContractorDocumentType, ContractorInvoice, ContractorInvoicePaymentSummary, ContractorTradeAreaTarget, Trade, WorkOrderItem, WorkOrderStatus, WorkOrderSurveySummary } from '@upkeeplabs/models/cogent';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ContractorInvoiceSummaryClient } from '@cogent/client/shared/models/accounting/contractor-invoice-summary-client.model';
import { CompanyRegion } from '@cogent/shared/models/common/company-states-and-regions.model';
import { SearchResultModel } from '@cogent/shared/models/common/search-result.model';
import { ContractorKPIModel } from '@cogent/client/shared/models/contractor/contractor-kpi.model';

@Injectable({
    providedIn: 'root'
})
export class ContractorsService {



    constructor(private api: ApiService,
        private entityApi: EntityApiService) { }

    getContractorKPIs(contractorId: string, tradeId: string, startDate: Date, endDate: Date): Promise<ContractorKPIModel[]> {

        return this.api.getArrayDotNet(
            'ContractorKPISummary',
            {
                contractorId_eq: contractorId,
                tradeId_eq: tradeId,
                reportperiod_gte: startDate,
                reportperiod_lte: endDate,
                orderby: 'ReportPeriod'
            },
            () => new ContractorKPIModel());
    }

    getAllTradesContractorKPIs(contractorId: string, startDate: Date, endDate: Date): Promise<ContractorKPIModel[]> {

        return this.api.getArrayDotNet(
            'ContractorKPISummary',
            {
                contractorId_eq: contractorId,
                reportperiod_gte: startDate,
                reportperiod_lte: endDate,
                orderby: 'ReportPeriod'
            },
            () => new ContractorKPIModel());
    }

    resetPasswordFromCode(securityCode: number, password: string): Promise<boolean> {
        return this.api.patchSingleDotNet(`registration/reset-password-from-code`, { securityCode, password });
    }

    unDeleteContractorInvoice(id: string) {
        return this.api.unDeleteDotNet(`ContractorInvoice/${id}`);
    }
    deleteContractorInvoice(id: string) {
        return this.api.deleteDotNet(`ContractorInvoice/${id}`);
    }

    sendContractorPasswordResetEmail(emailAddress: string) {
        return this.api.patchSingleDotNet(`registration/send-contractor-password-reset-code/${emailAddress}`, null);
    }

    async getContractorTradeAreaTargetsForCurrentMonth(contractorId: string): Promise<ContractorTradeAreaTarget[]> {
        const contractorTrades = await this.api.getArrayDotNet(`ContractorTrade`, { entityId_eq: contractorId, select: 'tradeId,id' });
        const contractorTradeRegions = await this.api.getArrayDotNet('ContractorTradeRegion',
            { contractorTradeId_contains: contractorTrades.map(i => i.id).join(','), select: 'contractorRegionId' });
        const areaIds = await this.api.getArrayDotNet('Entity', { id_contains: contractorTradeRegions.join(','), select: 'parentId' });
        const tradeIds = contractorTrades.map(i => i.tradeId);
        const thisMonth = UtilitiesService.toShortDate(UtilitiesService.monthStart(new Date()));

        return this.api.getArrayDotNet(`ContractorTradeAreaTarget`, {
            tradeId_contains: tradeIds.join(','),
            areaId_contains: areaIds.filter((value, index, self) => self.indexOf(value) === index),
            month_eq: thisMonth,
        }, () => new ContractorTradeAreaTarget());

    }

    async getContractorTradeAreaTargetsForCurrentMonthAndCurrentUser(): Promise<ContractorTradeAreaTarget[]> {
        const contractorId = (await this.entityApi.getLoggedInUser()).id;

        return this.getContractorTradeAreaTargetsForCurrentMonth(contractorId);
    }

    getWorkOrderContractorInvoices(workOrderId: string): Promise<ContractorInvoiceSummaryClient[]> {
        return this.api.getArrayDotNet(`ContractorInvoiceSummary`, { workOrderId_eq: workOrderId, orderby: 'CreatedDate' }, () => new ContractorInvoiceSummaryClient());
    }

    getContractorInvoicePaymentSummaryById(contractorInvoicePaymentId: string): Promise<ContractorInvoicePaymentSummary> {
        return this.api.getSingleDotNet(`ContractorInvoicePaymentSummary/${contractorInvoicePaymentId}`, null, () => new ContractorInvoicePaymentSummary());
    }

    async getUnacceptedJobCount(contractorId: string) {
        return this.api.getSingleDotNet(`WorkOrderSummaryNotAccepted/count`, { contractorId_eq: contractorId });
    }

    async getUnbilledCompletedInvoices(contractorId: string): Promise<ContractorInvoiceSummaryClient[]> {
        
        const todaysDate = new Date()
        todaysDate.setMonth(todaysDate.getMonth() - 2)

        return this.api.getArrayDotNet(`UnbilledCompletedJob`, { contractorId_eq: contractorId, createdDate_gte: todaysDate, orderby: 'CreatedDate' }, () => new ContractorInvoiceSummaryClient());
    }

    async getUnacceptedOffers(contractorId: string) {
        return this.api.getArrayDotNet(`WorkOrderSummaryNotAccepted`, {
            contractorId_eq: contractorId,
            select: 'id,number,itemName,propertyAddress,propertyCity,propertyPostalCode,itemId',
        }, () => new WorkOrderSummaryClient());
    }

    updateAppointmentTime(workOrderId: string, date: Date, startTime: string, endTime: string, technicianId: string = null) {
        const appointmentUpdateArgs: any = {
            ScheduledDate: UtilitiesService.dayBegin(date),
            ScheduledStartWindow: startTime,
            ScheduledEndWindow: endTime,
        };
        if (technicianId) {
            appointmentUpdateArgs.TechnicianId = technicianId;
        }
        return this.api.patchDotNet(`WorkOrder/${workOrderId}`, appointmentUpdateArgs);
    }

    getContractorDocumentTypes(): Promise<ContractorDocumentType[]> {
        return this.api.getArrayDotNet(`ContractorDocumentType`, { orderby: 'name' }, () => new ContractorDocumentType());
    }

    updateWorkOrderTechnician(workOrderId: string, technicianId: string) {
        return this.api.patchDotNet(`WorkOrder/${workOrderId}`, { technicianId, workOrderStatusId: 'CD4BE0D3-777D-476A-930A-B32F2CCE50C1' });
    }

    resendAppointmentEmail(workOrderId: string) {
        return this.api.getSingleDotNet(`WorkOrder/${workOrderId}/resend-appointment-notification`);
    }

    getWorkOrderSummaryByWorkOrderIdAndContractorId(workOrderNumber: string, contractorId: string) {
        return this.api.getArrayDotNet('WorkOrderSummary/select', { Number_eq: workOrderNumber, ContractorId_eq: contractorId, properties: 'id,propertyAddress,itemName,itemId,status,authorizationLimit,contractorName,cancelledDate,number,createdDate' });
    }

    getContractorTrades(entityId: string): Promise<any[]> {
        return this.api.getArrayDotNet('contractorTrade', { entityid_eq: entityId, orderby: 'tradeName' });
    }

    getContractorPaymentsTotalByMonth(contractorId: string): Promise<any> {
        return this.api.getSingleDotNet(`ContractorInvoicePayment/total-paid-by-month/${contractorId}`);
    }

    getMaintServiceContractorPaymentsTotalByMonth(contractorId: string): Promise<any> {
        return this.api.getSingleDotNet(`ContractorInvoicePayment/maint-service-total-paid-by-month/${contractorId}`);
    }

    getWorkOrderItemList(): Promise<any[]> {
        return this.api.getArrayDotNet('WorkOrderItem/select?properties=id,name&orderby=name');
    }

    getWorkOrderAttachments(workOrderId: string): Promise<WorkOrderAttachmentModel[]> {
        return this.api.getArrayDotNet(`WorkOrder/${workOrderId}/attachments`, null, () => new WorkOrderAttachmentModel());
    }

    getWorkOrderReport(
        startDate: Date, endDate: Date,
        contractorId: string, regions: CompanyRegion[], trades: Trade[], statuses: WorkOrderStatus[],
        callTypes: string[], selectedItems: WorkOrderItem[],
        regionsExcluded: CompanyRegion[], tradesExcluded: Trade[], statusesExcluded: WorkOrderStatus[],
        callTypesExcluded: string[], selectedItemsExcluded: WorkOrderItem[],
        selectedSLA: string[], selectedSLAExcluded: string[]
    ): Promise<any> {
        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);
        const params: any = {
            CreatedDate_gte: startDate,
            CreatedDate_lte: endDate,
            contractorId_eq: contractorId,
            take: 2000,
            properties:
                'id,number,createdDate,itemName,propertyAddress,type,status,tradeName,contractorName,slaStatus,homeownerName,contractorStatus'
        };

        if (regions && regions.length > 0) {
            let regionsString = '';
            regions.forEach(region => {
                if (regionsString) {
                    regionsString += ',';
                }
                regionsString += region.id;
            });
            params.RegionId_contains = regionsString;
        }

        if (trades && trades.length > 0) {
            let tradesString = '';
            trades.forEach(trade => {
                if (tradesString) {
                    tradesString += ',';
                }
                tradesString += trade.id;
            });

            params.TradeId_contains = tradesString;
        }
        if (selectedItems && selectedItems.length > 0) {
            let qString = '';
            selectedItems.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.itemIds = qString;
        }
        if (statuses && statuses.length > 0) {
            let qString = '';
            statuses.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.WorkOrderStatusId_contains = qString;
        }
        if (selectedSLA && selectedSLA.length > 0) {
            let qString = '';
            selectedSLA.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.slaStatus_contains = qString;
        }

        if (selectedSLAExcluded && selectedSLAExcluded.length > 0) {
            let qString = '';
            selectedSLAExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.slaStatus_ncontains = qString;
        }
        if (callTypes && callTypes.length > 0) {
            let qString = '';
            callTypes.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.Type_contains = qString;
        }

        // Excluded
        if (regionsExcluded && regionsExcluded.length > 0) {
            let regionsString = '';
            regionsExcluded.forEach(region => {
                if (regionsString) {
                    regionsString += ',';
                }
                regionsString += region.id;
            });
            params.RegionId_ncontains = regionsString;
        }

        if (tradesExcluded && tradesExcluded.length > 0) {
            let tradesString = '';
            tradesExcluded.forEach(trade => {
                if (tradesString) {
                    tradesString += ',';
                }
                tradesString += trade.id;
            });

            params.TradeId_ncontains = tradesString;
        }
        if (selectedItemsExcluded && selectedItemsExcluded.length > 0) {
            let qString = '';
            selectedItemsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.excludedItemIds = qString;
        }
        if (statusesExcluded && statusesExcluded.length > 0) {
            let qString = '';
            statusesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.WorkOrderStatusId_ncontains = qString;
        }
        if (callTypesExcluded && callTypesExcluded.length > 0) {
            let qString = '';
            callTypesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.Type_ncontains = qString;
        }

        return this.api.getArrayDotNet('WorkOrderSummary/queue', params, () => new WorkOrderSummaryClient());
    }

    getContractorBoard(contractorId: string): Promise<any> {
        return this.api.getArrayDotNet('WorkOrderSummary/select', {
            contractorId_eq: contractorId,
            dateCompleted_eq: '{{null}}',
            cancelledDate_eq: '{{null}}',
            properties: 'Id,Number,ItemName,PropertyAddress,DateCompleted,TradeId,SLAStatus,Status,ItemId,ContractorId'
        });
    }

    getActiveAppointments(contractorId: string, baseDate: Date): Promise<any> {
        const startDate = UtilitiesService.monthStart(baseDate);
        const endDate = UtilitiesService.monthEnd(baseDate);
        startDate.setMonth(startDate.getMonth() - 1);
        endDate.setMonth(endDate.getMonth() + 1);

        return this.api.getArrayDotNet('WorkOrderSummary/select', {
            contractorId_eq: contractorId,
            cancelledDate_eq: '{{null}}',
            properties: 'Id,Number,ItemName,PropertyAddress,DateCompleted,TradeId,SLAStatus,Status,ItemId,ScheduledDate,ScheduledStartWindow,ScheduledEndWindow,ContractorId,TechnicianId,PropertyCity,PropertyPostalCode',
            scheduledDate_neq: '{{null}}',
            scheduledDate_gte: startDate,
            scheduledDate_lte: endDate,
        });
    }

    updateWorkOrderStatus(workOrderId: string, newStatus: string, statusId: string) {
        if (newStatus === 'Completed') {
            return this.api.patchDotNet(`WorkOrder/${workOrderId}`, { WorkOrderStatusId: statusId, DateCompleted: new Date() });

        } else {
            return this.api.patchDotNet(`WorkOrder/${workOrderId}`, { WorkOrderStatusId: statusId });
        }
    }

    getUnApprovedInvoiceCount(contractorId: string): Promise<any> {
        return this.api.getSingleDotNet(`ContractorInvoiceSummary/count`, {
            contractorid_eq: contractorId,
            approvedDate_eq: '{{null}}',
            ContractorInvoiceExportDate_eq: '{{null}}'
        });
    }

    getUnapprovedInvoiceList(contractorId: string): Promise<ContractorInvoiceSummaryClient[]> {
        return this.api.getArrayDotNet(`ContractorInvoiceSummary`, {
            contractorid_eq: contractorId,
            approvedDate_eq: '{{null}}',
            ContractorInvoiceExportDate_eq: '{{null}}',
            orderby: 'WorkOrderNumber',
        }, () => new ContractorInvoiceSummaryClient());
    }


    getUnPaidInvoiceList(contractorId: string): Promise<any> {
        return this.api.getArrayDotNet(`ContractorInvoiceSummary`, {
            contractorid_eq: contractorId,
            approvedDate_neq: '{{null}}',
            ContractorInvoiceExportDate_eq: '{{null}}',
            orderby: 'WorkOrderNumber'
        }, () => new ContractorInvoiceSummaryClient());
    }

    getUnApprovedInvoiceSum(contractorId: string): Promise<any> {
        return this.api.getSingleDotNet(`ContractorInvoiceSummary/sum/amount`, {
            contractorid_eq: contractorId,
            approvedDate_eq: '{{null}}',
            ContractorInvoiceExportDate_eq: '{{null}}'
        });
    }

    getUnPaidInvoiceCount(contractorId: string): Promise<any> {
        return this.api.getSingleDotNet(`ContractorInvoiceSummary/count`, {
            contractorid_eq: contractorId,
            approvedDate_neq: '{{null}}',
            ContractorInvoiceExportDate_eq: '{{null}}'
        });
    }

    getUnPaidInvoiceSum(contractorId: string): Promise<any> {
        return this.api.getSingleDotNet(`ContractorInvoiceSummary/sum/amount`, {
            contractorid_eq: contractorId,
            approvedDate_neq: '{{null}}',
            ContractorInvoiceExportDate_eq: '{{null}}'
        });
    }

    getWorkOrderContractorInvoice(workOrderId: string) {
        return this.api.getArrayDotNet('contractorinvoice', {
            workOrderId_eq: workOrderId,
        }, () => new ContractorInvoice());
    }

    saveContractorInvoices(invoices: ContractorInvoice[]) {
        return this.api.postVoidDotNet('contractorinvoice/PostList', invoices);
    }

    getContractorPaymentSummaries(contractorId: string, startDate: Date = null, take: number = 10): Promise<ContractorInvoicePaymentSummary[]> {
        const queryParameters: any = {
            contractorId_eq: contractorId,
            take,
            orderby: 'CreatedDate descending',
        };
        if (startDate) {
            queryParameters.createdDate_lt = startDate;
        }

        return this.api.getArrayDotNet('ContractorInvoicePaymentSummary', queryParameters, () => new ContractorInvoicePaymentSummary());
    }

    getContractorInvoicePaymentStatementHtml(contractorInvoicePaymentId: string): Promise<string> {
        return this.api.getTextDotNet(`ContractorInvoicePayment/${contractorInvoicePaymentId}/statement/html`);
    }

    saveInvoice(invoice: ContractorInvoice) {
        if (!invoice.id) {
            invoice.id = UtilitiesService.newid();
        }
        if (!invoice.receivedDate) {
            invoice.receivedDate = new Date();
        }
        invoice.approvedDate = null;

        return this.api.postVoidDotNet('contractorinvoice', invoice);
    }

    updateWorkOrder(workOrderId: string, values: any) {
        return this.api.patchDotNet(`WorkOrder/${workOrderId}`, values);
    }

    getCallsToday(entityId: string): Promise<number> {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        return this.api.getSingleDotNet('WorkOrderSummary/count', {
            createddate_gte: today,
            contractorid_eq: entityId,
        });
    }

    getUpcommingJobs(entityId: string, take = 3): Promise<WorkOrderSummaryClient[]> {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        return this.api.getArrayDotNet(`WorkOrderSummary`, {
            scheduledDate_gte: today,
            contratorId_eq: entityId,
            CancelledDate_eq: '{{null}}',
            orderby: 'scheduledDate',
            take,
            select: 'id,number,itemName,propertyAddress,homeownerName,scheduledDate,scheduledStartWindow,scheduledEndWindow',
        });
    }

    getContractorInvoicesFromFile(mappedRecords: any[]): Promise<ContractorInvoiceSummaryClient[]> {
        return this.api.postArrayDotNet('contractorinvoicesummary/FromMappedUpload', mappedRecords, null, () => new ContractorInvoiceSummaryClient());
    }

    reassignWorkOrder(workOrderId: string): Promise<boolean> {
        return this.api.patchSingleDotNet(`workorder/${workOrderId}/auto-reassign`, null);
    }

    getCallsNeedingUpdate(entityId: string): Promise<number> {
        return this.api.getSingleDotNet('WorkOrderSummary/count', {
            contractorid_eq: entityId,
            slaStatus_eq: 'RED',
            dateCompleted_eq: '{{null}}',
        });
    }

    getRecentWorkOrders(contractorId: string, tradeId: string, count = 10): Promise<WorkOrderSummaryClient[]> {
        return this.api.getArrayDotNet(
            'WorkOrderSummary', { contractorId_eq: contractorId, tradeId_eq: tradeId, orderby: 'CreatedDate descending', take: count },
            () => new WorkOrderSummaryClient());
    }

    getSurveys(contractorId: string, tradeId: string, startDate: Date = null, resultCount = 10) {
        if (!startDate) {
            startDate = new Date();
        }

        return this.api.getArrayDotNet(
            'WorkOrderSurveySummary', {
            contractorId_eq: contractorId,
            orderby: 'CreatedDate descending',
            take: resultCount,
            tradeId_eq: tradeId,
            createddate_lt: startDate,
        }, () => new WorkOrderSurveySummary());
    }

    searchContractorWorkOrders(contractorId: string, searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayDotNet('Search/WorkOrder', {
            searchFor,
            key3: contractorId,
        }, () => new SearchResultModel());
    }

    searchForTypeAndSubtype(type: string, subtype: string, searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayDotNet('Search/' + type + '/' + subtype,
            { searchFor }, () => new SearchResultModel());
    }

    getTrades(): Promise<Trade[]> {
        return this.api.getArrayDotNet('contractors/trade', { orderby: 'sort' }, () => new Trade());
    }
}
