import { MaintenanceServiceFilter } from "./maintenance-service-filter.model";
import { MaintenanceServiceOfferingSummaryWithPostalCode } from "./maintenance-service-offering-summary-with-postal-code.model";
import { Template } from "./template.model";

export class GroupedProductOfferings {
    maintenanceServiceOfferingId: string;
    startingAt: number;
    perVisitStartingAt: number;
    perMonthStartingAt: number;
    showMonthly: boolean;
    maintenanceServiceName: string;
    template: Template;
    shortSummary: string;
    offerings: MaintenanceServiceOfferingSummaryWithPostalCode[];
    filteredOfferings: MaintenanceServiceOfferingSummaryWithPostalCode[];
    marketingTemplateId: string;
    maintenanceServiceId: string;
    contractLanguage: string;
    color: string;

    selected: boolean;
    selectedOffering: MaintenanceServiceOfferingSummaryWithPostalCode;
    maintenanceServiceFilters: string;
    offeringFilters: string;
    readyToShowOfferings: boolean;
    flipped = false;
    startDate: Date;
    minStartDate: Date;
    priceDisclaimer: string;
    brochureUrl: string;
    lottieAnimationUrl: string;

    get displayPrice() {
        return this.perVisitStartingAt < this.perMonthStartingAt ? this.perVisitStartingAt : this.perMonthStartingAt;
    }

    get priceLabel() {
        return this.perMonthStartingAt < this.perVisitStartingAt ? 'month' : 'visit';
    }

    private _maintServiceFiltersObj: MaintenanceServiceFilter[];

    get maintenanceServiceFiltersObj(): MaintenanceServiceFilter[] {

        if (!this._maintServiceFiltersObj) {
            if (this.maintenanceServiceFilters) {
                try {
                    this._maintServiceFiltersObj = JSON.parse(this.maintenanceServiceFilters);
                } catch { }
            }
        }

        return this._maintServiceFiltersObj;
    }

    private _offeringFiltersObj: string[];
    get offeringFiltersObj(): string[] {

        if (!this._offeringFiltersObj) {
            if (this.offeringFilters) {
                try {
                    this._offeringFiltersObj = JSON.parse(this.offeringFilters);
                } catch { }
            }
        }

        return this._offeringFiltersObj;
    }

    private _filtersToShow: MaintenanceServiceFilter[]
    get filtersToShow(): MaintenanceServiceFilter[] {
        if (!this._filtersToShow) {
            if (this.maintenanceServiceFiltersObj && this.offeringFiltersObj) {
                this._filtersToShow = this.maintenanceServiceFiltersObj.filter(i => this.offeringFiltersObj.indexOf(i.id) > -1)
            }
        }

        return this._filtersToShow;
    }
}