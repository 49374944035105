/// <reference types="googlemaps" />

import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, Input } from '@angular/core';
import { ZipCode, State, County } from '@upkeeplabs/models/cogent';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { BrowserModule } from '@angular/platform-browser';

const slideInOutAnimation = trigger('slideInOut', [
    transition(':enter', [
        style({ height: '0px', transform: 'translateY(-100%)' }),
        animate('250ms ease-in', style({ height: '*', transform: 'translateY(0%)' }))
    ]),
    transition(':leave', [
        animate('250ms ease-out', style({ height: '0', transform: 'translateY(-100%)' }))
    ])
]);


@Component({
    selector: 'app-zip-code-selector-2',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule],
    templateUrl: './zip-code-selector.component.html',
    styleUrls: ['./zip-code-selector.component.css']
})
export class ZipCodeSelectorComponent implements AfterContentInit {

    ngAfterContentInit(): void {
        this.initMap();
    }

    @Input() drawStates = true;
    @Input() drawCounties = true;
    @Input() drawCities = true;
    @Input() hideSelector = false;


    hideUnselected = false;
    private _zipCodes: ZipCode[];
    states: StateSelector[];

    _allStates: State[];
    @Input() set allStates(value) {
        this._allStates = value;
    }
    get allStates() { return this._allStates; }

    _allCounties: County[];
    @Input() set allCounties(value) {
        this._allCounties = value;
    }
    get allCounties() { return this._allCounties; }

    _allZipCodes: ZipCode[];
    @Input() set allZipCodes(value) {
        this._allZipCodes = value;
    }
    get allZipCodes() { return this._allZipCodes; }


    @Input() set zipCodes(value) {
        this._zipCodes = value;
        this.states = [];
        let state: StateSelector = null;
        let county: CountySelector = null;
        let city: CitySelector = null;
        if (!value) {
            return;
        }
        for (const zip of value) {
            if (state?.name !== zip.stateFullName) {
                state = new StateSelector(zip.state, zip.stateFullName, JSON.parse(this.allStates.find(s => s.state === zip.state).coordinates), this.onSelectedZipCodesChange.bind(this), () => this.checkedBounds);
                county = null;
                city = null;
                this.states.push(state);
            }
            if (county?.name !== zip.county) {
                const coordinateJson = this.allCounties.find(c => c.state === zip.state && c.county === zip.county)?.coordinates;
                county = new CountySelector(zip.county, coordinateJson ? JSON.parse(coordinateJson) : null, state, () => this.checkedBounds);
                city = null;
                state.children.push(county);
            }
            if (city?.name !== zip.primaryCity) {
                city = new CitySelector(zip.primaryCity, county, () => this.checkedBounds);
                county.children.push(city);
            }
            if (city) {
                city.children.push(new ZipCodeSelector(zip.zip, JSON.parse(this.allZipCodes.find(z => z.zip == zip.zip).coordinates), city, () => this.checkedBounds));
            }
        }
        
        for(const state of this.states) {
            state.numberSelected = state.children?.length;
            for(const county of state.children) {
                county.numberSelected = county.children?.length;
                for(const city of county.children) {
                    city.numberSelected = city.children?.length;
                    for(const zip of city.children) {
                        zip.numberSelected = zip.children?.length;
                    }
                }
            }
        }
        this.initMap();
    }


    get zipCodes() {
        return this._zipCodes;
    }

    hover(selector: IGeographySelector, over: boolean) {
        selector.updateMap(this.map, over);
        if (!over) {
            this.map.fitBounds(this.checkedBounds);
        }
    }

    private _selectedZipCodes: string[];
    @Input() set selectedZipCodes(value: string[]) {
        this._selectedZipCodes = value;
        if (!value) {
            return;
        }

        if (value.length > 0) this.hideUnselected = true;
        if (this.states && this.states.length > 0) {
            for (const state of this.states) {
                state.numberSelected = 0;
                for (const county of state.children) {
                    county.numberSelected = 0;
                    for (const city of county.children) {
                        city.numberSelected = 0;
                        for (const zip of city.children) {
                            city.total++;
                            county.total++;
                            state.total++;
                            zip.isChecked = value.find(z => z == zip.name) ? true : false;
                        }
                    }
                }
            }
        }
    }
    get selectedZipCodes() {
        return this._selectedZipCodes;
    }

    onSelectedZipCodesChange() {
        if (this.selectedZipCodes) {
            const zipCodes = [];
            for (const state of this.states) {
                if (state.numberSelected > 0) {
                    for (const county of state.children) {
                        if (county.numberSelected > 0) {
                            for (const city of county.children) {
                                if (city.numberSelected > 0) {
                                    for (const zip of city.children) {
                                        if (zip.isChecked) {
                                            zipCodes.push(zip.id);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            for (const zip of this.selectedZipCodes.slice()) {
                if (!zipCodes.includes(zip)) {
                    this.selectedZipCodes.splice(this.selectedZipCodes.indexOf(zip), 1);
                }
            }
            for (const zip of zipCodes) {
                if (!this.selectedZipCodes.includes(zip)) {
                    this.selectedZipCodes.push(zip);
                }
            }
        }
    }


    map: google.maps.Map;

    get checkedBounds(): google.maps.LatLngBounds {
        const bounds = new google.maps.LatLngBounds();
        for (const state of this.states) {
            if (state.numberSelected > 0) {
                for (const county of state.children) {
                    if (county.numberSelected > 0) {
                        if (county.bounds) {
                            bounds.union(county.bounds);
                        } else {
                            for (const city of county.children) {
                                for (const zip of city.children) {
                                    if (zip.bounds) {
                                        bounds.union(zip.bounds);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return bounds;
    }

    initMap() {
        if (!this.states?.length) {
            return;
        }
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(40, -101),
            zoom: 4,
            mapTypeId: 'terrain'
        });

        
        for (const state of this.states) {
            if (state.numberSelected > 0) {
                for (const county of state.children) {
                    if (county.numberSelected > 0) {
                        for (const city of county.children) {
                            if (city.numberSelected > 0) {
                                for (const zip of city.children) {
                                    zip.updateMap(this.map, false);
                                }
                            }
                        }
                        if (this.drawCounties) {
                            //county.updateMap(this.map, false);
                        }
                    }
                }
                if (this.drawStates) {
                    //state.updateMap(this.map, false);
                }
            } else {
                
            }
        }
        this.map.fitBounds(this.checkedBounds);
    }
}

interface IGeographySelector {
    name: string;
    id: string;
    expanded: boolean;
    isChecked: boolean;
    numberSelected: number;
    total: number;
    parent?: IGeographySelector;
    children: (IGeographySelector)[];
    showMap(map: google.maps.Map, strokeWeight, strokeColor, strokeOpacity, fillColor, fillOpacity, zIndex);
    updateMap(map: google.maps.Map, over: boolean): void;
    updateChecked();
    map: google.maps.Map;
    over: boolean;
    type: "State" | "County" | "City" | "ZipCode";

    label: Popup;
}
class GeographySelector<TParent extends IGeographySelector, TChild extends IGeographySelector> implements IGeographySelector {
    expanded = false;
    total = 0;
    private _isChecked = false;
    get isChecked() {
        return this._isChecked;
    }
    set isChecked(value) {
        if (this._isChecked !== value) {
            this._isChecked = value;
            if (this.children) {
                for (const child of this.children) {
                    child.map = this.map;
                    child.isChecked = value;
                }
            }
            let parent: IGeographySelector = this.parent;
            while (parent) {
                parent.map = this.map;
                parent = parent.parent;
            }
            this.isCheckedChanged();
        }
    }

    map: google.maps.Map;
    protected isCheckedChanged() {
        let geo: IGeographySelector = this;

        this.updateMap(this.map, false);
        while (geo != null) {
            geo.updateMap(this.map, false);
            geo = geo.parent;
        }
        if (this.children) {
            for (const child of this.children) {
                child.updateMap(this.map, false);
            }
        }

    }

    updateChecked() {
        if (this.children) {
            this._isChecked = this.total === this.numberSelected;
        }
    }

    numberSelected: number = 0;
    children: TChild[];


    constructor(
        public id: string,
        public name: string,
        public coordinates: Array<Array<Array<number>>>,
        public parent: TParent,
        public onSelectionChanged: () => void,
        public type: "State" | "County" | "City" | "ZipCode",
        public checkedBounds: () => google.maps.LatLngBounds
    ) { }

    private _polygons: google.maps.Polygon[];
    private get polygons(): google.maps.Polygon[] {
        if (!this._polygons && this.coordinates) {
            this._polygons = [];
            this.bounds = new google.maps.LatLngBounds();
            for (const polyCoords of this.coordinates) {
                let coords = [];
                for (const coord of polyCoords) {
                    const point = new google.maps.LatLng(coord[1], coord[0]);
                    coords.push(point);
                    this.bounds.extend(point);
                }
                const polygon = new google.maps.Polygon({ paths: coords });
                this._polygons.push(polygon);
            }
            // if (this.type == "ZipCode") {
            const div = document.createElement("div");
            div.style.position = "absolute";
            div.innerHTML = this.name;
            div.style.fontSize = '24px';
            div.style.backgroundColor = '#fff';
            div.style.padding = '5px';
            
            let geo: IGeographySelector = this.parent;
            // while (geo) {
            //     div.innerHTML += ", " + geo.name;
            //     geo = geo.parent;
            // }
            this.label = new Popup(this.bounds.getCenter(), div);
            for (const polygon of this.polygons) {
                polygon.addListener('mouseover', () => {
                    this.label.setMap(this.map)
                    // this.updateMap(this.map, true, false);
                });


                polygon.addListener('mouseout', () => {
                    this.label.setMap(null);
                    // this.updateMap(this.map, false, false);
                });
            }
        }
        return this._polygons;
    }

    label: Popup;


    bounds: google.maps.LatLngBounds;

    showMap(map: google.maps.Map, strokeWeight, strokeColor, strokeOpacity, fillColor, fillOpacity, zIndex) {
        if (this.polygons && this.polygons.length > 0) {
            for (const polygon of this.polygons) {
                polygon.setOptions({ strokeWeight, strokeColor, strokeOpacity, fillColor, fillOpacity, zIndex });
                polygon.setMap(map);
            }
            // if (this.label) {
            //     this.label.setMap(map);
            // }
        }

    }
    private get defaultZIndex() {
        if (this.type === "ZipCode") return 100;
        if (this.type === "County") return 50;
        if (this.type === "State") return 25;
        return 0;
    }
    over = false;
    updateMap(map: google.maps.Map, over: boolean, fitBounds = true) {
        this.map = map;
        this.over = over;
        if (this.type === "ZipCode") {
            this.showMap(map, 3, "#2222ff", 1, "#2222ff", .25, this.defaultZIndex);
            if (this.bounds) {
                this.map.fitBounds(this.checkedBounds().union(this.bounds));
            } else {
                this.map.fitBounds(this.checkedBounds());
            }
            // if (over) {
            //     if (this.isChecked) {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .5, 200);
            //     } else {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .3, 200);
            //     }
            //     if (fitBounds) {
            //         if (this.bounds) {
            //             this.map.fitBounds(this.checkedBounds().union(this.bounds));
            //         } else {
            //             this.map.fitBounds(this.checkedBounds());
            //         }
            //     }
            // } else {
            //     if (this.isChecked) {
            //         this.showMap(map, 3, "#2222ff", 1, "#2222ff", .25, this.defaultZIndex);
            //     } else {
            //         this.showMap(map, 3, "#2222ff", 1, "#2222ff", 0, this.defaultZIndex);
            //     }
            // }
        } else if (this.type === "County") {
            // if (over) {
            //     if (this.numberSelected === 0) {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .3, this.defaultZIndex);
            //     } else if (this.numberSelected < this.total) {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .4, this.defaultZIndex);
            //     } else {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .55, this.defaultZIndex);
            //     }
            //     if (fitBounds) {
            //         if (this.bounds) {
            //             this.map.fitBounds(this.checkedBounds().union(this.bounds));
            //         } else {
            //             this.map.fitBounds(this.checkedBounds());
            //         }
            //     }
            // } else {
            //     if (this.numberSelected === 0) {
            //         this.showMap(null, 1, "#2222ff", .1, "#2222ff", 0, this.defaultZIndex);
            //     } else if (this.numberSelected < this.total) {
            //         this.showMap(map, 1, "#2222ff", 1, "#2222ff", .1, this.defaultZIndex);
            //     } else {
            //         this.showMap(map, 1, "#2222ff", 1, "#2222ff", .2, this.defaultZIndex);
            //     }
            // }
        } else if (this.type === "State") {
            // if (over) {
            //     if (this.numberSelected === 0) {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .3, this.defaultZIndex);
            //     } else if (this.numberSelected < this.total) {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .4, this.defaultZIndex);
            //     } else {
            //         this.showMap(map, 3, "#ef5623", 1, "#ff8c00", .55, this.defaultZIndex);
            //     }
            //     if (fitBounds) {
            //         if (this.bounds) {
            //             this.map.fitBounds(this.checkedBounds().union(this.bounds));
            //         } else {
            //             this.map.fitBounds(this.checkedBounds());
            //         }
            //     }
            // } else {
            //     if (this.numberSelected === 0) {
            //         this.showMap(null, 1, "#2222ff", .1, "#2222ff", 0, this.defaultZIndex);
            //     } else if (this.numberSelected < this.total) {
            //         this.showMap(map, 1, "#2222ff", 1, "#2222ff", 0, this.defaultZIndex);
            //     } else {
            //         this.showMap(map, 1, "#2222ff", 1, "#2222ff", 0, this.defaultZIndex);
            //     }
            // }
        }


    }
}

class StateSelector extends GeographySelector<IGeographySelector, CountySelector> {
    constructor(id: string, name: string, coordinates: Array<Array<Array<number>>>, onSelectionChanged: () => void,
        checkedBounds: () => google.maps.LatLngBounds) {
        super(id, name, coordinates, null, onSelectionChanged, "State", checkedBounds);
        this.children = [];
    }
}
class CountySelector extends GeographySelector<StateSelector, CitySelector> {
    constructor(name: string, coordinates: Array<Array<Array<number>>>, parent: StateSelector,
        checkedBounds: () => google.maps.LatLngBounds) {
        super(name, name, coordinates, parent, parent.onSelectionChanged, "County", checkedBounds);
        this.children = [];
    }
}

class CitySelector extends GeographySelector<CountySelector, ZipCodeSelector>{
    constructor(name: string, parent: CountySelector,
        checkedBounds: () => google.maps.LatLngBounds) {
        super(name, name, null, parent, parent.onSelectionChanged, "City", checkedBounds);
        this.children = [];
    }
}

class ZipCodeSelector extends GeographySelector<CitySelector, IGeographySelector> {
    constructor(name: string, coordinates: Array<Array<Array<number>>>, parent: CitySelector,
        checkedBounds: () => google.maps.LatLngBounds) {
        super(name, name, coordinates, parent, parent.onSelectionChanged, "ZipCode", checkedBounds);
    }

    isCheckedChanged() {
        let parent: IGeographySelector = this.parent;
        while (parent != null) {
            if (this.isChecked) {
                parent.numberSelected++;
            }
            else if (parent.numberSelected > 0) {
                parent.numberSelected--;
            }
            if (parent.numberSelected === parent.total) {
                parent.updateChecked();
            }
            parent = parent.parent;
        }
        super.isCheckedChanged();
    }
}

/**
   * A customized popup on the map.
   */
class Popup extends google.maps.OverlayView {
    position: google.maps.LatLng;
    containerDiv: HTMLDivElement;

    constructor(position: google.maps.LatLng, content: HTMLElement) {
        super();
        this.position = position;

        content.classList.add("popup-bubble");

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");

        bubbleAnchor.classList.add("popup-bubble-anchor");
        bubbleAnchor.appendChild(content);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("popup-container");
        this.containerDiv.appendChild(bubbleAnchor);

        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
        this.getPanes()!.floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
            this.position
        )!;

        // Hide the popup when it is far out of view.
        const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                ? "block"
                : "none";

        if (display === "block") {
            this.containerDiv.style.left = divPosition.x + "px";
            this.containerDiv.style.top = divPosition.y + "px";
        }

        if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
        }
    }
}


