import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

export class LifetimeGrossMargin {
    @keyColumn() id: string;
    @column() costs: number;
    @column() reducedCosts: number;
    @column() revenue: number;
    @column() grossMargin: number;
    @column() rrrGrossMargin: number;
    @column() years: number;
}