import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class WorkOrderSurveySummary {
    @keyColumn() id: string;
    @column() workOrderId: string;
    @column() rating: number;
    @column() contractorId: string;
    @column() comments: string;
    @column() createdDate: Date;
    @column() itemName: string;
    @column() customerName: string;
    @column() number: bigint;
    @column() tradeId: string;
    @column() customerEmail: string;
    @column() customerHomeNumber: string;
    @column() customerMobileNumber: string;
    @column() contactNumber: string;
    @column() customerId: string;
    @column() agentId: string;
    @column() sellerAgentId: string;
}