<div flex1 *ngIf="entity">
    <app-type-ahead-box #typeAheadBox *ngIf="nameIsSearchBox && !showFirstAndLastName" [apiEndpoint]="entitySearchUrl"
        placeholderLabel="{{nameLabel}}" (selectedItemChange)="selectedEntityChanged($event)"
        [(searchForText)]="entity.name" [(selectedItem)]="searchSelectedEntity" [itemTemplate]="customItemTemplate">
    </app-type-ahead-box>

    <div *ngIf="!showFirstAndLastName && !nameIsSearchBox">
        <mat-form-field appearance="outline">
            <mat-label>{{nameLabel}} {{showRequiredName ? '*' : ''}}</mat-label>
            <input matInput [disabled]="!canEditName" (ngModelChange)="updateCanSave();emitFieldChanged('name')"
                [(ngModel)]="entity.name">
        </mat-form-field>
    </div>

    <ng-container *ngIf="!showOnlyNameAndEmail">
        <div *ngIf="showFirstAndLastName">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput [(ngModel)]="entity.firstName" (ngModelChange)="emitFieldChanged('firstName')">
            </mat-form-field>
        </div>
        <div *ngIf="showFirstAndLastName">
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput [(ngModel)]="entity.lastName" (ngModelChange)="emitFieldChanged('lastName')">
            </mat-form-field>
        </div>
        <div *ngIf="showNameOnCheck">
            <mat-form-field appearance="outline">
                <mat-label>Name On Check</mat-label>
                <input matInput [(ngModel)]="entity.nameOnCheck" (ngModelChange)="emitFieldChanged('nameOnCheck')">
            </mat-form-field>
        </div>

        <div *ngIf="showAddressToggle" bottommargin20>


            <div *ngIf="defaultAddress && !overrideAddress">
                <div class="data-label">Mailing Address</div>
                <div>{{defaultAddress.address1}}</div>
                <div>{{defaultAddress.address2}}</div>
                <div>{{defaultAddress.city}}, {{defaultAddress.state}} {{defaultAddress.postalCode}}</div>
            </div>
            <mat-checkbox topmargin10 [(ngModel)]="overrideAddress">
                Enter a different mailing address
            </mat-checkbox>
        </div>
        <ng-container *ngIf="showAddress">
            <button *ngIf="defaultAddress && !showAddressToggle" mat-button (click)="useDefaultAddress()">
                <i class="material-icons">map</i> {{defaultAddressLabel}}</button>
            <div topmargin10 *ngIf="defaultAddress"></div>

            <app-address-editor [useOutlineFormField]="outline" [addressIsRequired]="addressRequired"
                [(addressComplete)]="addressComplete" (addressCompleteChange)="addressCompleteChange($event)"
                [address]="entity.address"></app-address-editor>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="showAlternateNames">

        <mat-form-field  appearance="outline">
            <mat-label>DBA Name</mat-label>
            <input matInput [(ngModel)]="entity.dBAName" (ngModelChange)="emitFieldChanged('dBAName')">
        </mat-form-field>

        <mat-form-field  appearance="outline">
            <mat-label>Legal Name</mat-label>
            <input matInput [(ngModel)]="entity.legalName" (ngModelChange)="emitFieldChanged('legalName')">
        </mat-form-field>

    </ng-container>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>
                <mat-icon>mail</mat-icon> Email {{showRequiredEmail ? '*' : ''}}
            </mat-label>
            <input matInput type="email" [(ngModel)]="entity.email" (ngModelChange)="emitFieldChanged('email')">
        </mat-form-field>

        <mat-form-field *ngIf="showAlternateEmailEntry" appearance="outline">
            <mat-label>{{alternateEmailLabel}}</mat-label>
            <input matInput type="email" [(ngModel)]="entity.alternateEmail"
                (ngModelChange)="emitFieldChanged('alternateEmail')">
        </mat-form-field>
    </div>

    <ng-container *ngIf="showOnlyNameAndEmail && showPhoneNumberEntry">
        <div *ngIf="showPhoneNumberEntry">

            <div flex *ngIf="entity.isIndividual && !entity.isProfessionalIndividual">
                <mat-form-field appearance="outline" *ngIf="entity.homeNumber">
                    <mat-label>
                        <mat-icon>phone</mat-icon> Home Number {{homePhoneRequired ? '*': ''}}
                    </mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.homeNumber.number"
                        (ngModelChange)="emitFieldChanged('homeNumber')">
                </mat-form-field>
                <mat-form-field  appearance="outline" *ngIf="entity.mobileNumber">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.mobileNumber.number"
                        (ngModelChange)="emitFieldChanged('mobileNumber')">
                </mat-form-field>
            </div>
            <div flex *ngIf="!entity.isIndividual || entity.isProfessionalIndividual">
                <mat-form-field  appearance="outline" *ngIf="entity.officeNumber">
                    <mat-label>
                        <mat-icon>phone</mat-icon> Office Number {{showRequiredOfficeNumber ? '*' : ''}}
                    </mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.officeNumber.number"
                        (ngModelChange)="emitFieldChanged('officeNumber')">
                </mat-form-field>
                <mat-form-field  appearance="outline" *ngIf="entity.mobileNumber">
                    <mat-label>Mobile Number {{showRequiredMobileNumber ? '*' : ''}}</mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.mobileNumber.number"
                        (ngModelChange)="emitFieldChanged('mobileNumber')">
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!showOnlyNameAndEmail">
        <div *ngIf="!entity.isIndividual && !hideWebsiteEntry">
            <mat-form-field  appearance="outline">
                <mat-label>Website</mat-label>
                <input matInput type="url" [(ngModel)]="entity.website" (ngModelChange)="emitFieldChanged('website')">
            </mat-form-field>
        </div>
        <div *ngIf="showAccountInputFields" aflex>
            <mat-form-field appearance="outline">
                <mat-label>Account # / User Name</mat-label>
                <input matInput [(ngModel)]="entity.accountUserName"
                    (ngModelChange)="emitFieldChanged('accountUserName')">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Account Password</mat-label>
                <input matInput [(ngModel)]="entity.accountPassword"
                    (ngModelChange)="emitFieldChanged('accountPassword')">
            </mat-form-field>
        </div>
        <div *ngIf="showSinglePhoneNumberEntry">
            <mat-form-field class="full-box" style="max-width: 300px;"  appearance="outline">
                <mat-label>
                    <mat-icon>phone_iphone</mat-icon> Phone Number {{homePhoneRequired ? '*': ''}}
                </mat-label>
                <input matInput inputmode="tel" (ngModelChange)="updateCanSave();emitFieldChanged('homeNumber')"
                    mask="(000) 000-0000" [(ngModel)]="entity.homeNumber.number">
                <mat-hint align="end">(123) 456-7890</mat-hint>

            </mat-form-field>
        </div>
        <div *ngIf="showPhoneNumberEntry">




            <div flex *ngIf="entity.isIndividual && !entity.isProfessionalIndividual">
                <mat-form-field  appearance="outline" *ngIf="entity.homeNumber">
                    <mat-label>
                        <mat-icon>phone</mat-icon> Home Number {{homePhoneRequired ? '*': ''}}
                    </mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.homeNumber.number"
                        (ngModelChange)="emitFieldChanged('homeNumber')">
                </mat-form-field>
                <mat-form-field  appearance="outline" *ngIf="entity.mobileNumber">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.mobileNumber.number"
                        (ngModelChange)="emitFieldChanged('mobileNumber')">
                </mat-form-field>
            </div>
            <div flex *ngIf="!entity.isIndividual || entity.isProfessionalIndividual">
                <mat-form-field  appearance="outline" *ngIf="entity.officeNumber">
                    <mat-label>
                        <mat-icon>phone</mat-icon> Office Number {{showRequiredOfficeNumber ? '*' : ''}}
                    </mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.officeNumber.number"
                        (ngModelChange)="emitFieldChanged('officeNumber')">
                </mat-form-field>
                <mat-form-field  appearance="outline" *ngIf="entity.mobileNumber">
                    <mat-label>Mobile Number {{showRequiredMobileNumber ? '*' : ''}}</mat-label>
                    <input matInput mask="(000) 000-0000" [(ngModel)]="entity.mobileNumber.number"
                        (ngModelChange)="emitFieldChanged('mobileNumber')">
                </mat-form-field>
            </div>

            <div *ngIf="!hideTimezoneEntry">
                <mat-form-field  appearance="outline">
                    <mat-label>Timezone</mat-label>
                    <mat-select style="display: block" [(ngModel)]="entity.timeZoneId">
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.Id">
                            {{ timezone.DisplayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <ng-container *ngIf="!hideContactMethodsEntry">
                <div flex>
                    <div *ngIf="showOptIn">
                        <mat-checkbox [(ngModel)]="entity.optIn">Opt-In (Email Notifications)</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="entity.contactViaEmail">Contact Via Email</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="entity.contactViaSMSText">Contact Via SMS Text</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="entity.dispatchActivatedDate" disabled="true">Contact Via Dispatch</mat-checkbox>
                    </div>
                    <!-- <div>
                        <mat-checkbox *ngIf="!hidePaperless" [(ngModel)]="entity.paperless">Paperless</mat-checkbox>
                    </div> -->
                </div>

                <div flex topmargin10 *ngIf="!hidePreferreds">
                    <div>
                        <mat-form-field  appearance="outline">
                            <mat-label>Preferred Contact Method</mat-label>
                            <mat-select [(ngModel)]="entity.preferredContactMethod">
                                <mat-option value="Phone">Phone</mat-option>
                                <mat-option value="SMS">SMS</mat-option>
                                <mat-option vlaue="">Not Set</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field  appearance="outline">
                            <mat-label>Preferred Language</mat-label>
                            <mat-select [(ngModel)]="entity.preferredLanguageId">
                                <mat-option *ngFor="let language of languages" [value]="language.id">{{language.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                @if (entity.type != 'Contractor') {
                  <div aflex>
                      <div flex1>
                          <app-date-picker [(date)]="entity.birthday" placeholder="Birthday"></app-date-picker>
                      </div>
                      <div flex1></div>
                  </div>
                }
                <mat-form-field *ngIf="showLicenseInput"  appearance="outline">
                    <mat-label>License Number</mat-label>
                    <input matInput [(ngModel)]="entity.licenseNumber">
                </mat-form-field>
                <div *ngIf="entity.type === 'Agent' && teams && teams.length > 0">
                    <mat-form-field  appearance="outline">
                        <mat-label>Team</mat-label>
                        <mat-select [(ngModel)]="entity.teamId">
                            <mat-option [value]="null">No Team</mat-option>
                            <ng-container *ngFor="let team of teams">
                                <mat-option [value]="team.id">{{team.name}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="entity.type === 'Employee'" flex>
                    <div flex1>
                        <mat-form-field  appearance="outline">
                            <mat-label>Phone Extension</mat-label>
                            <input matInput [(ngModel)]="entity.extension">
                        </mat-form-field>
                    </div>
                    <div flex1>
                        <mat-checkbox [(ngModel)]="entity.forwardCallsToMobile">Forward Calls To Mobile</mat-checkbox>
                    </div>
                </div>
                <div flex *ngIf="!hideInactiveBox">
                    <div>
                        <mat-checkbox [(ngModel)]="entity.inactive">Inactive</mat-checkbox>
                    </div>
                </div>

                <div topmargin30
                    *ngIf="showDispatchButton && entity.name?.trim().length>0 && entity.type === 'Contractor' && !entity.dispatchInitializedDate">
                    <button mat-button mat-raised-button color="primary" (click)="initializeInDispatch()">
                        <mat-spinner *ngIf="saving" thirty></mat-spinner> Initialize in Dispatch
                    </button>

                </div>
                <div *ngIf="entity.dispatchInitializedDate && showDispatchButton" topmargin30>
                    <div class="flex-row">
                        <div>
                            Initialized in Dispatch: {{entity.dispatchInitializedDate | date: 'shortDate'}}
                        </div>
                        <div style="margin-left: 30px">
                            <button mat-button mat-raised-button color="white" style="margin-top:-10px"
                                *ngIf="!entity.dispatchActivatedDate" (click)="dispatchActiveClick()">
                                select activation date</button>
                            <div *ngIf="entity.dispatchActivatedDate" class="flex-row">
                                <div style="cursor: pointer; margin-top:-2px; margin-right:5px;">
                                    <mat-icon color="primary" (click)="undoActivation()">undo</mat-icon>
                                </div>
                                <div>Activation Date: {{entity.dispatchActivatedDate | date: 'shortDate'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </ng-container>
    <div *ngIf="entity.type === 'Team'">
        <mat-form-field  appearance="outline">
            <mat-label>Team Maintainer</mat-label>
            <mat-select [(ngModel)]="entity.teamId">
                <ng-container *ngFor="let agent of teamAgents">
                    <mat-option [value]="agent.id">{{agent.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</div>
