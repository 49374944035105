import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class AuthorizationRepairItemGroup {
    @keyColumn() id: string;
    @column() name: string;
    @column() tradeId: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;

    deleting = false;
}

export class AuthorizationRepairItemGroupLimit {
    id: string;
    limitPerOccurance: number;
    limitPerPlanPeriod: number;
}